import { API_FETCH, API_POST } from "../General/Utility";
import { useEffect, useState } from "react";
import PrintScreen from "../Print/PrintScreen";
import $ from "jquery";
import PrinterSetting from "../Print/PrinterSetting";
import PrintInvoicDesign from "../General/PrintInvoice";
import WhatsApp from "../General/WhatsApp";
import EmailPopup from "../General/EmailPopup";
import { useDispatch, useSelector } from "react-redux";
import { setManualHT, setParty_Address } from "../features/ManualInvoice";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import ReactTable from "../General/ReactTable";

function ManualInvoiceView(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _HT = useSelector((state) => state.Invoice.HT[0]);
  const [strFromDate, setstrFromDate] = useState("");
  const [strToDate, setstrToDate] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [iVouchId, setiVouchId] = useState(0);
  const [bIsPrintOpen, setbIsPrintOpen] = useState(false);
  const [selected, setselected] = useState(0);
  const [VouchersList, setVouchersList] = useState([]);
  const [IsPrintSetting, setIsPrintSetting] = useState(false);
  const [IsOpenEmail, setIsOpenEmail] = useState(false);
  const [IsOpenwhatsapp, setIsOpenwhatsapp] = useState(false);

  useEffect(() => {
    $("[tabindex=52]").focus();
    Load_Voucher("", "Search");
  }, []);
  const TableKeyDow = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter")
      if (ActiveIndex === 52) {
        if (VouchersList.length > 0) {
          View_Voucher(VouchersList[selected].vouchId);
        }
      }
    if (e.key === "ArrowDown") {
      $(".Invoice_table .rt-tbody .rt-tr:eq(1)").focus();
    }
    if (e.key === "ArrowUp") {
      $(".Invoice_table .rt-tbody .rt-tr:eq(1)").focus();
    }
  };
  const Load_Voucher = async (val, type) => {
    let FromDate = "";
    let ToDate = "";
    let Search = "";
    switch (type) {
      case "Search":
        setstrSearch(val);
        Search = val;
        FromDate = strFromDate;
        ToDate = strToDate;
        break;
      case "FromDate":
        FromDate = val;
        ToDate = strToDate;
        Search = strSearch;
        break;
      case "ToDate":
        ToDate = val;
        FromDate = strFromDate;
        Search = strSearch;
        break;
      default:
        break;
    }
    const objSearch = {
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
      strFromDate: FromDate,
      strToDate: ToDate,
      strSearch: Search,
    };
    const Res = await API_POST(
      `ManualInvoice/Load_Voucher/`,
      objSearch,
      dispatch
    );
    if (Res) {
      setstrFromDate(Res.strFromDate);
      setstrToDate(Res.strToDate);
      setVouchersList(Res.EntInvoice);
    }
  };
  const columns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Voucher Number",
      accessor: "invNo",
      minWidth: 200,
    },
    {
      Header: "Date",
      accessor: "invDate",
      style: { justifyContent: "center" },
    },
    {
      Header: "Party Name",
      accessor: "customer",
      minWidth: 200,
    },
    {
      Header: "Total Qty",
      accessor: "qty",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Total Tax",
      accessor: "taxAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Net Amount",
      accessor: "netAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
  ];
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 1 && ActiveIndex <= 3)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 4 + "']").focus();
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    } else if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  const PrintPdfClick = async (PrintFor) => {
    try {
      if (iVouchId === 0)
        Show_Message(dispatch, "Please Select Voucher", "info");
      else {
        let VoucherId = iVouchId === undefined ? 0 : iVouchId;
        let TempId = 0;
        const parems = `PrintDesing/PrintPreView/${100}/${TempId}/${VoucherId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`;
        const res = await API_FETCH(parems, dispatch);
        if (res) {
          await PrintInvoicDesign(res, 100, PrintFor);
        } else Show_Message(res.strMessage, "info");
      }
    } catch (err) {
      Show_Message(err.strMessage, "info");
    }
  };
  const View_Voucher = async (iVouchId) => {
    try {
      props.Close();
      Pre_Loading(dispatch, true);
      const parems = `ManualInvoice/View_Invoice/${iVouchId}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`;
      const res = await API_FETCH(parems, dispatch);
      if (res) {
        let HT = res.arrEntHT[0];
        let HTA = res.arrEntHTA[0];
        let BillAdd = res.arrEntBillAdd[0];
        let ShipAdd = res.arrEntShipAdd[0];
        dispatch(
          setManualHT({
            arrEntDT: res.arrEntDT,
            arrEntDTTax: res.arrEntDTTax,
            arrEntAL: res.arrEntAL,
            arrEntALTax: res.arrEntALTax,
            arrEntTax: res.arrEntTax,
            InvId: HT.invId,
            PreFix: HT.preFix,
            NumSeqNo: HT.numSeqNo,
            ManualNo: _HT.ManualNo,
            InvNo: HT.invNo,
            InvDate: HT.invDate,
            PartyVouchers: HT.partyVouchers,
            Customer: HT.customer,
            BT_Id: HT.bT_Id,
            TaxRelId: HT.taxRelId,
            ItemCount: HT.itemCount,
            Qty: HT.qty,
            FQty: HT.fQty,
            GoodsValue: HT.goodsValue,
            ItemDiscVal: HT.itemDiscVal,
            CashDiscVal: HT.cashDiscVal,
            ALDiscPer: HT.alDiscPer,
            ALDiscAmt: HT.alDiscAmt,
            ALDiscVal: HT.alDiscVal,
            BillDiscPer: HT.billDiscPer,
            BillDiscAmt: HT.billDiscAmt,
            BillDiscVal: HT.billDiscVal,
            ALAmt: HT.alAmt,
            DiscAmt: HT.discAmt,
            TCSTaxAmt: HT.tcsTaxAmt,
            TaxAmt: HT.taxAmt,
            TaxableAmt: HT.taxableAmt,
            GrossAmt: HT.grossAmt,
            RoundOff: HT.roundOff,
            NetAmt: HT.netAmt,
            PaidAmt: 0,
            BalAmt: 0,
            BillStatus: HT.billStatus,
            CreatedBy: HT.createdBy,
            BranchId: HT.branchId,
            TaxCalcOnId: _HT.TaxCalcOnId,
            OtherCal: _HT.OtherCal,
            AutoRoundOff: _HT.AutoRoundOff,
            isOnchange: _HT.isOnchange,
            RefNum1: HTA.refNum1,
            RefDate1: HTA.refDate1,
            RefNum2: HTA.refNum2,
            RefDate2: HTA.refDate2,
            RefNum3: HTA.refNum3,
            RefDate3: HTA.refDate3,
            RefNum4: HTA.refNum4,
            RefDate4: HTA.refDate4,
            RefName: HTA.refName,
            DeliveryMode: HTA.deliveryMode,
            AgentName: HTA.agentName,
            EComGSTNo: HTA.eComGSTNo,
            ValidDays: HTA.validDays,
            ValidUpto: HTA.validUpto,
            PayTermDays: HTA.payTermDays,
            PayTermUpto: HTA.payTermUpto,
            DeliTermDays: HTA.deliTermDays,
            DeliTermUpto: HTA.deliTermUpto,
            Transport: HTA.transport,
            VehicleNumber: HTA.vehicleNumber,
            Despatch: HTA.despatch,
            DespatchDate: HTA.despatchDate,
            Destination: HTA.destination,
            LicenseNo: HTA.licenseNo,
            LicenseDate: HTA.licenseDate,
            Remark1: HTA.remark1,
            Remark2: HTA.remark2,
            Remark3: HTA.remark3,
            Remark4: HTA.remark4,
          })
        );
        dispatch(
          setParty_Address({
            AddId: BillAdd.addId,
            CustName: BillAdd.custName,
            AliasName: BillAdd.aliasName,
            Add1: BillAdd.add1,
            Add2: BillAdd.add2,
            Add3: BillAdd.add3,
            Add4: BillAdd.add4,
            Pincode: BillAdd.pincode,
            State: BillAdd.state,
            Country: BillAdd.country,
            Mobile: BillAdd.mobile,
            Email: BillAdd.email,
            Remarks: BillAdd.remarks,
            GST: BillAdd.gst,
            ShipAddId: ShipAdd.shipAddId,
            ShipCustName: ShipAdd.custName,
            ShipAliasName: ShipAdd.aliasName,
            ShipAdd1: ShipAdd.add1,
            ShipAdd2: ShipAdd.add2,
            ShipAdd3: ShipAdd.add3,
            ShipAdd4: ShipAdd.add4,
            ShipPincode: ShipAdd.pincode,
            ShipState: ShipAdd.state,
            ShipCountry: ShipAdd.country,
            ShipMobile: ShipAdd.mobile,
            ShipEmail: ShipAdd.email,
            ShipGST: ShipAdd.gst,
            ShipRemarks: ShipAdd.remarks,
            InvId: BillAdd.invId,
            BranchId: BillAdd.branchId,
          })
        );
      }
      Pre_Loading(dispatch, false);
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };

  //#endregion

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Manual Invoice</h5>
          <div className="icon-popup-btn-grp">
            <span
              className=" icon-btn"
              onClick={(e) => setIsPrintSetting(!IsPrintSetting)}
            >
              <i className="fa-solid fa-gear"></i>
            </span>
            <span className=" icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-1">
          <div className="row">
            <div className="col-md-6 col-sm-12 mt-2">
              <div className="m-hsearch">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  autoFocus
                  autoComplete="off"
                  spellCheck={false}
                  id="Invoice_Search"
                  style={{
                    marginRight: "10px",
                    borderRadius: "3px 0 0 3px !important",
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" ||
                      e.key === "ArrowUp" ||
                      e.key === "ArrowDown"
                    )
                      TableKeyDow(e);
                  }}
                  tabIndex={52}
                  name="strSearch"
                  value={strSearch}
                  onChange={(e) => Load_Voucher(e.target.value, "Search")}
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Load_Voucher(strSearch, "Search")}
                ></i>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mt-2">
              <input
                type="date"
                className="form-control "
                value={strFromDate}
                name="strFromDate"
                tabIndex={2}
                onKeyDown={(e) => TabIndex(e)}
                style={{
                  marginRight: "10px",
                  borderRadius: "3px 0 0 3px !important",
                }}
                onChange={(e) => Load_Voucher(e.target.value, "FromDate")}
              />{" "}
            </div>
            <div className="col-md-3 col-sm-12 mt-2">
              <input
                type="date"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={3}
                className="form-control"
                value={strToDate}
                name="strToDate"
                onChange={(e) => Load_Voucher(e.target.value, "ToDate")}
              />
            </div>
          </div>
          <ReactTable
            columns={columns}
            data={VouchersList}
            minRows={5}
            row_click={(VouchInfo, Index) => {}}
            row_doubleclick={(VouchInfo, Index) => {
              if (!props.isConversion) View_Voucher(VouchInfo.invId);
            }}
            background={true}
            className="full-table"
          />
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <div className="PTip" onClick={(e) => PrintPdfClick("Print")}>
              <a className="icon-btn" href="##">
                <i className="fa-solid fa-print" tabIndex="1"></i>
              </a>
            </div>
            <div className="PTip" onClick={(e) => PrintPdfClick("PDF")}>
              <a className="icon-btn" href="##">
                <i className="fa-solid fa-download"></i>
              </a>
            </div>
            <div
              className="PTip"
              onClick={(e) => setIsOpenwhatsapp(!IsOpenwhatsapp)}
            >
              <a className="icon-btn" href="##">
                <i className="fa-brands fa-whatsapp" tabIndex="3"></i>
              </a>
            </div>
            <div className="PTip" onClick={(e) => setIsOpenEmail(!IsOpenEmail)}>
              <a className="icon-btn" href="##">
                <i className="fa-solid fa-envelope" tabIndex="4"></i>
              </a>
            </div>
          </div>
        </div>
        {bIsPrintOpen && (
          <PrintScreen
            Close={(e) => setbIsPrintOpen(!bIsPrintOpen)}
            iScrId={100}
            iFormateId={0}
            iVouchId={iVouchId}
          />
        )}
        {IsPrintSetting && (
          <PrinterSetting
            Close={(e) => setIsPrintSetting(!IsPrintSetting)}
            iScrId={100}
          />
        )}
        {IsOpenEmail && (
          <EmailPopup
            Close={(e) => setIsOpenEmail(!IsOpenEmail)}
            iVouchId={iVouchId}
            VouchersList={VouchersList}
            iScrId={100}
            ScrName="Manual Invoice"
          />
        )}
        {IsOpenwhatsapp && (
          <WhatsApp
            Close={(e) => setIsOpenwhatsapp(!IsOpenwhatsapp)}
            iVouchId={iVouchId}
            iScrId={100}
            ScrName="Manual Invoice"
          />
        )}
      </div>
    </div>
  );
}
export default ManualInvoiceView;
