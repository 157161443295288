import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import {
  Add_TransHtparem,
  Add_TransSummary,
  Add_Transdata,
  Add_party,
  setothercharges,
} from "../features/POS";
import Calculation from "./Calculation";
import { API_POST } from "../General/Utility";

function ProductReturn(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [arrEntPermission, setarrEntPermission] = useState([]);
  const [arrEntProduct, setarrEntProduct] = useState([]);
  const [arrEntCust, setarrEntCust] = useState([]);
  const [arrEntService, setarrEntService] = useState([]);
  const tableRef = useRef(null);

  //#region  Redonly Parem from Global Redux Storage
  const _Pos = useSelector((state) => state.Pointofsales.Pos);
  const _HT = useSelector((state) => state.Pointofsales.CommonHTParem);
  const _DT = useSelector((state) => state.Pointofsales.CommonDT);
  const _Disc = useSelector((state) => state.Pointofsales.BillDiscount);
  const _Service = useSelector((state) => state.Pointofsales.OtherCharges);
  //#endregion

  useEffect(() => {
    Load_PosReturn(props.VouchId);
    short_cut_keypress();
  }, []);

  const Load_PosReturn = async (Vouchid) => {
    Pre_Loading(dispatch, true);
    const res = await API_POST(
      `POS/View_ReturnProduct/${Vouchid}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      setarrEntCust(res.EntReturn.entParty);
      setarrEntService(res.EntService._EntServiceTax);
      Assign_ReturnProduct(res.EntReturn.entProduct);
      let EntCol = res.EntTableCol.sort((a, b) => a.inOrderTO - b.inOrderTO);
      EntCol = EntCol.filter((dt) => dt.isShowInScr === true);
      setarrEntPermission(EntCol);
    }
  };
  const Assign_ReturnProduct = (Returnproduct) => {
    let TransDT = [];
    Returnproduct.forEach((item, Index) => {
      TransDT.push({
        VouchId: item.vouchId,
        VouchNo: item.vouchNo,
        VouchDate: item.vouchDate,
        SNo: item.sNo,
        ProId: item.proId,
        AttGrpId: item.attGrpId,
        ProCode: item.proCode,
        ProName: item.proName,
        CommodityCode: item.commodityCode,
        AttValues: item.attValues,
        UnitId: item.unitId,
        UnitName: item.unitName,
        RateWithoutTax: item.rateWithoutTax,
        RateWithTax: item.rateWithTax,
        dMinMargin: item.minMargin,
        ItemDiscPer: item.itemDiscPer,
        ItemDiscAmt: item.itemDiscAmt,
        ItemDiscValue: item.itemDiscValue,
        Qty: item.qty,
        FQty: 0,
        GoodsValue: item.qty * item.rateWithoutTax,
        TaxableAmt: 0,
        TaxAmt: 0,
        ItemAmt: 0,
        BatchNo: item.batchNo,
        ManufactureDate: item.manufactureDate,
        ExpiryDate: item.expiryDate,
        iTaxRelId: item.taxRelId,
        TaxPer: item.igstPer,
        iSGST: item.sgstId,
        iCGST: item.cgstId,
        iIGST: item.igstId,
        iTCS: item.tcsId,
        strSGST: item.sgstDesc,
        strCGST: item.cgstDesc,
        strIGST: item.igstDesc,
        strTCS: item.tcsDesc,
        strSGSTPer: item.sgstPer,
        strCGSTPer: item.cgstPer,
        strIGSTPer: item.igstPer,
        strTCSPer: item.tcstPer,
        TaxLvl: item.taxLvl,
        taxableOn: item.taxableOn,
        TcsTaxRelId: item.tcsTaxRelId,
        totalVal: item.netAmt,
        iIndex: Index,
        IsNewItem: 1,
        CashDiscPer: 0,
        CashDiscAmt: 0,
        CashDiscValue: 0,
        BillDiscValue: 0,
        Value1: 0,
        Value2: 0,
        Value3: 0,
        TotSqureValue: 0,
        ConstantValue: 0,
        FinalValue: 0,
        SACCode: "",
        SerId: 0,
        SerCode: "",
        SerName: "",
        Remark1: "",
        Remark2: "",
        Remark3: "",
        Remark4: "",
        RowStatus: 0,
        IsSerProduct: 0,
        InvDTId: item.invDTId,
        IsConvertable: false,
        ConvertedQty: 0,
        RowId: item.rowId,
        Reason: "",
        DecimalQty: item.decimalQty,
        StockMaintain: item.stockMaintain,
        isChecked: item.isChecked,
        YearId: Number(_AuthParems.Year),
        BranchId: Number(_AuthParems.Branch),
      });
    });
    setarrEntProduct(TransDT);
  };
  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        props.Close();
      }
    });
  };
  const View_ReturnProduct = async () => {
    try {
      Pre_Loading(dispatch, true);
      let TransDT = arrEntProduct.filter((DT) => DT.isChecked === true);
      let result = await Calculation(
        TransDT,
        _DT.EntTransAL,
        _Pos,
        _Disc,
        _Service.EntALTax
      );
      const Party = arrEntCust[0];
      let summary = result.Summary;
      dispatch(
        Add_Transdata({
          EntTransDT: result.EntItems,
          EntTransPenConDT: [],
          EntTransTax: result.TaxGroup,
          EntTransAL: result.EntAddLess,
          EntTransDTHelp: [],
        })
      );
      dispatch(
        Add_TransSummary({
          dnetAmt: summary.NetAmt,
          dqty: summary.Qty,
          dtotalDiscount: summary.TotalDiscount,
          dgoodsValue: summary.GoodsValue,
          dtotalTax: summary.TotalTax,
          dtotalAddLess: summary.TotalAddLess,
          dtotalTCSTax: summary.TotalTCSTax,
          droundOff: summary.RoundOff,
          dBillDisVal: result.BillDiscValue,
        })
      );
      dispatch(
        Add_TransHtparem({
          //#region  Voucher Tab Parem
          strTransNo: "",
          strPartyDCVouch: TransDT[0].VouchNo,
          strConvertDCVouch: "",
          //#endregion

          //#region  Reference Vouchers Tap Parem
          strRefNum1: _HT.strRefNum1,
          dpRefNum1Date: _HT.dpRefNum1Date,
          strRefNum2: _HT.strRefNum2,
          dpRefNum2Date: _HT.dpRefNum2Date,
          strRefNum3: _HT.strRefNum3,
          dpRefNum3Date: _HT.dpRefNum3Date,
          strRefNum4: _HT.strRefNum4,
          dpRefNum4Date: _HT.dpRefNum4Date,
          //#endregion

          //#region  Validity Tap Parem
          iValidity: _HT.iValidity,
          dpValDateUpto: _HT.dpValDateUpto,
          iPayTerm: _HT.iPayTerm,
          dpPayTermDateUpto: _HT.dpPayTermDateUpto,
          iDeliTerm: _HT.iDeliTerm,
          dpDelTermDateUpto: _HT.dpDelTermDateUpto,
          //#endregion

          //#region  Delivery Mode Parem
          iDMId: _HT.iDMId,
          strAgent: _HT.strAgent,
          strReference: _HT.strReference,
          strDestination: _HT.strDestination,
          strEComGstNo: _HT.strEComGstNo,
          //#endregion

          //#region  Transport Tap Parem
          strTransport: _HT.strTransport,
          strVehicle: _HT.strVehicle,
          strDespatch: _HT.strDespatch,
          dpDespatchDate: _HT.dpDespatchDate,
          strLRNNumber: _HT.strLRNNumber,
          dpLRNoDate: _HT.dpLRNoDate,
          //#endregion

          //#region  Remark Tap Parem
          strRemark1: _HT.strRemark1,
          strRemark2: _HT.strRemark2,
          strRemark3: _HT.strRemark3,
          strRemark4: _HT.strRemark4,
          //#endregion
        })
      );
      dispatch(
        Add_party({
          iPartyId: Party.iCustId,
          iTaxRelId: Party.taxRelId,
          strPartyName: Party.strCustName,
          strPartyCode: Party.strCustCode,
          strAliasName: Party.strAliasName,
          strAdd1: Party.strAdd1,
          strAdd2: Party.strAdd2,
          strAdd3: Party.strAdd3,
          strAdd4: Party.strAdd4,
          strPincode: Party.strPincode,
          iState: Party.iState,
          iCountry: Party.iCountry,
          strMobile1: Party.strMobile1,
          strMobile2: Party.strMobile2,
          strPhone1: Party.strPhone1,
          strPhone2: Party.strPhone2,
          strEmail: Party.strEmail,
          strGSTNo: Party.strGST,
          strTinNo: "",
          strShippAdd: "",
          strPartyAdd: "",
          dOutstandingAmt: 0,
          dRayaltyPoint: 0,
          RewardType: "",
        })
      );
      dispatch(
        setothercharges({
          SerId: 0,
          ServiceName: "",
          SerCode: "",
          SACcode: "",
          bUpdateStatus: false,
          iRowIndex: 0,
          RateWithoutTax: (0).toFixed(2),
          RateWithTax: (0).toFixed(2),
          dTaxPer: 0,
          EntALTax: arrEntService,
        })
      );
      Pre_Loading(dispatch, false);
      props.View_Return(arrEntPermission);
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const NumtoDecimal = (num) => {
    return Number(num).toFixed(2);
  };
  const converttodecimal = (value) => {
    return Number(value).toFixed(2);
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>POS Return</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-2">
          <div className="pos-view">
            <div className="pos-itemlist">
              <table className="product-table" ref={tableRef}>
                <thead>
                  <tr>
                    <th style={{ maxWidth: "50px" }}>Select</th>
                    <th>Vouch No</th>
                    <th>Vouch Date</th>
                    <th>Product Name</th>
                    <th>Qty</th>
                    <th>Net Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {arrEntProduct.map((product, trIndex) => (
                    <tr key={trIndex}>
                      <td style={{ maxWidth: "50px" }}>
                        <input
                          type="checkbox"
                          className="form-check"
                          value={product.isChecked}
                          onChange={(e) => {
                            let Entpro = arrEntProduct;
                            Entpro[trIndex].isChecked = !product.isChecked;
                            setarrEntProduct(Entpro);
                          }}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>{product.VouchNo}</td>
                      <td style={{ textAlign: "center" }}>
                        {product.VouchDate}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {product.ProName}
                        <sub style={{ marginLeft: "10px" }}>
                          {product.AttValues.replace(new RegExp("/", "g"), "-")}
                        </sub>
                        <br />
                        <span>
                          GST - {NumtoDecimal(product.TaxPer)} % | Unit :
                          {product.UnitName}
                        </span>
                      </td>
                      <td style={{ textAlign: "center" }}>{product.Qty}</td>
                      <td style={{ textAlign: "right" }}>
                        {converttodecimal(product.totalVal)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pos-btn-group">
          <div className="pos-tooltip" onClick={(e) => View_ReturnProduct()}>
            <a className="icon-btn" href="##">
              <i className="fa-solid fa-arrow-rotate-left"></i>
            </a>
            <span className="pos-tooltiptxt">Return</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductReturn;
