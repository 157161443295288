import React, { useEffect, useState } from "react";
import ExportImport from "./ExportImport";
import Button from "./MasterButton";
import MasterList from "./MasterListItem";
import PriceGroupPopup from "./PriceGroupPopup";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import AddressPopup from "../Transaction/AddressPopup";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import MasterCodeSetting from "./MasterCodeSetting";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import { Country_OnChange } from "./MasterTransaction";
function CustomerMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  //#region  Variables
  const iScrId = 2;
  const [strSearch, setstrSearch] = useState("");
  const strFilter = "";
  const strFilterColumn = "All";
  const [ManualCode, setManualCode] = useState(true);
  const strCustCodeTag = "Enter Customer Code";
  const [iCustId, setiCustId] = useState(0);
  const [strCustCode, setstrCustCode] = useState("");
  const [strAliesName, setstrAliesName] = useState("");
  const [strCustomerName, setstrCustomerName] = useState("");
  const [strOfficeNo, setstrOfficeNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strCity, setstrCity] = useState("");
  const [iPinCode, setiPinCode] = useState("");
  const [strCountryId, setstrCountryId] = useState(100);
  const [isShowPriceGroup, setisShowPriceGroup] = useState(false);
  const [strStateId, setstrStateId] = useState(32);
  const [iPhone, setiPhone] = useState("");
  const [iPhone1, setiPhone1] = useState("");
  const [iMobile, setiMobile] = useState("");
  const [iMobile1, setiMobile1] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strGst, setstrGst] = useState("");
  const [iPriceGrpId, setiPriceGrpId] = useState(1);
  const [iStatusId, setiStatusId] = useState(1);
  const [strRemarks, setstrRemarks] = useState("");
  const [iBalTypeId, setiBalTypeId] = useState(3);
  const [iCreditLimit, setiCreditLimit] = useState((0.0).toFixed());
  const [iOpeningBal, setiOpeningBal] = useState((0.0).toFixed());
  const [strCreditTerm, setstrCreditTerm] = useState(30);
  const [dRoyaltyPoint, setdRoyaltyPoint] = useState((0).toFixed(2));
  const [ExpImpOpen, setExpImpOpen] = useState(false);

  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntStatus, setlistEntStatus] = useState([]);
  const [listEntCountry, setlistEntCountry] = useState([]);
  const [listEntState, setlistEntState] = useState([]);
  const [listEntPriGrp, setlistEntPriGrp] = useState([]);
  const [listEntBalTyp, setlistEntBalTyp] = useState([]);
  const [listEntCusList, setlistEntCusList] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);

  const [bIsPhone1, setbIsPhone1] = useState(false);
  const [bIsMobile1, setbIsMobile1] = useState(false);
  const [bIsRemark, setbIsRemark] = useState(false);
  const [bIsStatus, setbIsStatus] = useState(false);
  const [bIsBalType, setbIsBalType] = useState(false);
  const [bIsOpenBal, setbIsOpenBal] = useState(false);
  const [bIsCreditLim, setbIsCreditLim] = useState(false);
  const [bIsCreditTerm, setbIsCreditTerm] = useState(false);
  const [bIsPriceGroup, setbIsPriceGroup] = useState(false);
  const [bIsNewAddress, setbIsNewAddress] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  //#endregion

  //#region Load_Event

  // SideEffect When Each Value Change
  useEffect(() => {
    Load_CustomerMaster();
  }, []);
  const Load_CustomerMaster = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `CustomerMaster/Load_CustomerMaster/${Number(_LocalParems.Branch)}/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        setlistEntStatus(res.objEntStatus);
        setlistEntCountry(res.objEntCountry);
        setlistEntState(res.objEntState);
        setlistEntPriGrp(res.objEntPriGrp);
        setlistEntBalTyp(res.objEntBalTyp);
        setlistEntCusList(res.objEntCusList);
        setlistCodeFormat(res.objCodeFormat);
        setstrCustCode(res.objCodeFormat[0].strCode);
        setManualCode(!res.objCodeFormat[0].isManualNo);
        var Fields = res.Fields;
        setbIsPhone1(Fields[0].bIsVissible);
        setbIsMobile1(Fields[1].bIsVissible);
        setbIsStatus(Fields[2].bIsVissible);
        setbIsRemark(Fields[3].bIsVissible);
        setbIsBalType(Fields[4].bIsVissible);
        setbIsOpenBal(Fields[5].bIsVissible);
        setbIsCreditLim(Fields[6].bIsVissible);
        setbIsCreditTerm(Fields[7].bIsVissible);
        setbIsPriceGroup(Fields[8].bIsVissible);
        TabOrderAssign(res.Transtab);
        setListValidateProperties(res.Transtab);
      }
    } catch (err) {
      await Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  //#endregion

  const Search_Coustomer = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        iBranchId: Number(_AuthParems.Branch),
        strSearch: strtxt,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `CustomerMaster/Search_Customer/`,
        objSearch,
        dispatch
      );
      if (res) {
        setlistEntCusList(res.objEntCust);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  //#endregion

  //#region Click Event

  // Save Or Update Customer
  const SaveClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const listEntCusInsUpd = {
        iCustId: iCustId,
        iBranchId: Number(_AuthParems.Branch),
        strCustCode: strCustCode,
        strCustName: strCustomerName,
        strAliasName: strAliesName,
        strAdd1: strOfficeNo,
        strAdd2: strStreet,
        strAdd3: strArea,
        strAdd4: strCity,
        strPincode: iPinCode,
        iCountry: Number(strCountryId),
        iState: Number(strStateId),
        strPhone1: iPhone,
        strPhone2: iPhone1,
        strMobile1: iMobile,
        strMobile2: iMobile1,
        strEmail: strEmail,
        strGST: strGst,
        strRemarks: strRemarks,
        iPGId: Number(iPriceGrpId),
        iStatusId: Number(iStatusId),
        dOpBal: Number(iOpeningBal),
        dCreLimit: Number(iCreditLimit),
        CreditTerm: strCreditTerm,
        iBalTypeId: Number(iBalTypeId),
        iTitleTag: iScrId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strCustCodeTag: strCustCodeTag,
        strFilterColumn: strFilterColumn,
        strFilter: strFilter,
        strSearch: strSearch,
        RoyaltyPoint: 0,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "CustomerMaster/InsUpd_Customer/",
        listEntCusInsUpd,
        dispatch
      );
      if (res) {
        await ClearClick();
        await Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      await Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  // Delete Customer
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_DELETE(
        `CustomerMaster/Delet_Customer/${iCustId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        await ClearClick();
        await Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      await Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  // Clear DefaultValues
  const ClearClick = async () => {
    try {
      setiCustId(0);
      setstrCustCode("");
      setstrAliesName("");
      setstrSearch("");
      setstrCustomerName("");
      setstrOfficeNo("");
      setstrStreet("");
      setstrArea("");
      setstrCity("");
      setstrArea("");
      setstrArea("");
      setiPinCode("");
      setstrCountryId(100);
      setstrStateId(32);
      setiPhone("");
      setiPhone1("");
      setiMobile("");
      setiMobile1("");
      setstrEmail("");
      setstrGst("");
      setiPriceGrpId(0);
      setiStatusId(1);
      setstrRemarks("");
      setiBalTypeId(3);
      setiCreditLimit(0);
      setiOpeningBal(0);
      setdRoyaltyPoint(0);
      setstrCreditTerm(30);
      await Load_CustomerMaster();
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };

  //#endregion

  //#region  Custome Validation
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtName":
          ErrorMsg = strCustomerName === "" ? false : true;
          break;
        case "txtAlias":
          ErrorMsg = strAliesName === "" ? false : true;
          break;
        case "txtAdd1":
          ErrorMsg = strOfficeNo === "" ? false : true;
          break;
        case "txtAdd2":
          ErrorMsg = strStreet === "" ? false : true;
          break;
        case "txtAdd3":
          ErrorMsg = strArea === "" ? false : true;
          break;
        case "txtAdd4":
          ErrorMsg = strCity === "" ? false : true;
          break;
        case "txtPinCode":
          ErrorMsg = iPinCode === "" ? false : true;
          break;
        case "txtPhone1":
          ErrorMsg = iPhone === "" ? false : true;
          break;
        case "txtPhone2":
          ErrorMsg = iPhone1 === "" ? false : true;
          break;
        case "txtMobile1":
          ErrorMsg = iMobile === "" ? false : true;
          break;
        case "txtMobile2":
          ErrorMsg = iMobile1 === "" ? false : true;
          break;
        case "txtEmail":
          ErrorMsg = strEmail === "" ? false : true;
          break;
        case "txtTin":
          ErrorMsg = true;
          break;
        case "txtGst":
          ErrorMsg = strGst === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "txtOpBalance":
          ErrorMsg = iOpeningBal === "" ? false : true;
          break;
        case "txtCreditLimit":
          ErrorMsg = iCreditLimit === "" ? false : true;
          break;
        case "txtCreditTerm ":
          ErrorMsg = strCreditTerm === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  //#endregion

  //#region General Methods

  const View_Customer = async (iCusId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `CustomerMaster/View_Customer/${iCusId}/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        let Cust = res.EntCust[0];
        setiCustId(Cust.iCustId);
        setstrCustCode(Cust.strCustCode);
        setstrCustomerName(Cust.strCustName);
        setstrAliesName(Cust.strAliasName);
        setstrOfficeNo(Cust.strAdd1);
        setstrStreet(Cust.strAdd2);
        setstrArea(Cust.strAdd3);
        setstrCity(Cust.strAdd4);
        setiPinCode(Cust.strPincode);
        setiPhone(Cust.strPhone1);
        setiPhone1(Cust.strPhone2);
        setiMobile(Cust.strMobile1);
        setiMobile1(Cust.strMobile2);
        setstrEmail(Cust.strEmail);
        setstrGst(Cust.strGST);
        setiPriceGrpId(Cust.iPGId);
        setiStatusId(Cust.iStatusId);
        setstrRemarks(Cust.strRemarks);
        setiBalTypeId(Cust.iBalTypeId);
        setiCreditLimit(Cust.dCreLimit);
        setiOpeningBal(Cust.dOpBal);
        setdRoyaltyPoint(Cust.royaltyPoint);
        setstrCreditTerm(Cust.creditTerm);
        setstrStateId(Cust.iState);
        setstrCountryId(Cust.iCountry);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  //#endregion

  //#region TabSetting
  const [ListTabOrder, setListTabOrder] = useState([
    {
      CusCodeTab: "",
      AliasTab: "",
      NameTab: "",
      OfficeNoTab: "",
      StreetTab: "",
      AreaTab: "",
      CityTab: "",
      PinCodeTab: "",
      CountryTab: "",
      StateTab: "",
      Phone1Tab: "",
      Phone2Tab: "",
      Mobile1Tab: "",
      Mobile2Tab: "",
      Emailtab: "",
      GstTab: "",
      TinNoTab: "",
      RemarksTab: "",
      CreditLimitTab: "",
      CreditTermTab: "",
      StatusTab: "",
      OpeningBalTab: "",
      BalTypeTab: "",
      PriceGrpTab: "",
      CustomerListTab: "",
      CustomerFIlterTab: "",
      CustomerSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
      btnCountry: "",
      btnPriceGrp: "",
      btnState: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtCode":
          TabOrder[0].CusCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtName":
          TabOrder[0].NameTab = EntProperties[i].iTabIndex;
          break;
        case "txtAlias":
          TabOrder[0].AliasTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd1":
          TabOrder[0].OfficeNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd2":
          TabOrder[0].StreetTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd3":
          TabOrder[0].AreaTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd4":
          TabOrder[0].CityTab = EntProperties[i].iTabIndex;
          break;
        case "cbCountry":
          TabOrder[0].CountryTab = EntProperties[i].iTabIndex;
          break;
        case "txtPinCode":
          TabOrder[0].PinCodeTab = EntProperties[i].iTabIndex;
          break;
        case "cbState":
          TabOrder[0].StateTab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone1":
          TabOrder[0].Phone1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone2":
          TabOrder[0].Phone2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile1":
          TabOrder[0].Mobile1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile2":
          TabOrder[0].Mobile2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtEmail":
          TabOrder[0].Emailtab = EntProperties[i].iTabIndex;
          break;
        case "txtTin":
          TabOrder[0].TinNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtGst":
          TabOrder[0].GstTab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].StatusTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarksTab = EntProperties[i].iTabIndex;
          break;
        case "txtOpBalance":
          TabOrder[0].OpeningBalTab = EntProperties[i].iTabIndex;
          break;
        case "cbBalType":
          TabOrder[0].BalTypeTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditLimit":
          TabOrder[0].CreditLimitTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditTerm":
          TabOrder[0].CreditTermTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        case "btnCountry":
          TabOrder[0].btnCountry = EntProperties[i].iTabIndex;
          break;
        case "btnPriceGrp":
          TabOrder[0].btnPriceGrp = EntProperties[i].iTabIndex;
          break;
        case "btnState":
          TabOrder[0].btnState = EntProperties[i].iTabIndex;
          break;
        case "cbPriceGroup":
          TabOrder[0].PriceGrpTab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].CustomerFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].CustomerSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbCustomerName":
          TabOrder[0].CustomerListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].CustomerListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].CustomerListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].CustomerListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].CustomerListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].CustomerListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].CustomerListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].CustomerListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  //#endregion

  return (
    <main className="main-container">
      <SideMenu ScrId={iScrId} />
      <div className="content">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          tabOrder={ListTabOrder[0].CustomerSearchTab}
          Search={(val) => Search_Coustomer(val)}
          arrMasterData={listEntCusList}
          isImg={false}
          displayName="strCustName"
          Parem1="iCustId"
          Parem2="iVenTypeId"
          View_Data={View_Customer}
          placeholder="Customer"
        />
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-2">
              <label>
                Name <span className="danger">*</span>
              </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={strCustomerName}
                spellCheck={false}
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].NameTab}
                autoFocus
                placeholder="Please Enter Customer Name"
                onChange={(e) => setstrCustomerName(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>
                Code <span className="danger">*</span>
              </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  id="txtProductCode"
                  value={strCustCode}
                  readOnly={ManualCode}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].CusCodeTab}
                  onChange={(e) => setstrCustCode(e.target.value)}
                  name="strProCode"
                />
                <span
                  className="inv-no"
                  onClick={(e) => setIsMasterCode(!IsMasterCode)}
                >
                  <i className="bx bx-cog"></i>
                </span>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Alias Name</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={strAliesName}
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].AliasTab}
                placeholder="Please Enter Alias Name"
                onChange={(e) => setstrAliesName(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Office /Door No</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].OfficeNoTab}
                value={strOfficeNo}
                placeholder="Please Enter Door No"
                onChange={(e) => setstrOfficeNo(e.target.value)}
              />
              <span
                className="btn-shipadd"
                onClick={(e) => {
                  if (iCustId === 0)
                    Show_Message(dispatch, "Please Select Party", "info");
                  else setbIsNewAddress(!bIsNewAddress);
                }}
              >
                <i className="fa-solid fa-location-dot ml-2"></i>
                Add Secondary Address
              </span>
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Street</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].StreetTab}
                value={strStreet}
                placeholder="Please Enter Street Address"
                onChange={(e) => setstrStreet(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Area </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].AreaTab}
                placeholder="Please Enter Area Address"
                value={strArea}
                onChange={(e) => setstrArea(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>City</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={strCity}
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].CityTab}
                placeholder="Please Enter City Name"
                onChange={(e) => setstrCity(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Pincode</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].PinCodeTab}
                placeholder="Please Enter PinCode"
                value={iPinCode}
                onChange={(e) => setiPinCode(e.target.value)}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>
                Country <span className="danger">*</span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selecrparem.isoptionShow}
                placeholder="Select Country"
                select_value={async (val) => {
                  await setstrCountryId(val);
                  await setlistEntState(
                    await Country_OnChange(val, _AuthParems.DB, dispatch)
                  );
                }}
                keydown={(e) => TabIndex(e)}
                taborder={ListTabOrder[0].CountryTab}
                btnname="Country"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={strCountryId}
                displayName="strCountryName"
                disvalue="iCountryId"
                arrydata={listEntCountry}
                disabled={false}
                EmptVal="Country "
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>
                State <span className="danger">*</span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_selecrparem.isoptionShow1}
                placeholder="Select State"
                select_value={(val) => setstrStateId(val)}
                keydown={(e) => TabIndex(e)}
                taborder={ListTabOrder[0].StateTab}
                btnname="State"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={strStateId}
                displayName="strSName"
                disvalue="iSId"
                arrydata={listEntState}
                disabled={false}
                EmptVal="State "
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Phone </label>
              <input
                type="number"
                aria-label="First name"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].Phone1Tab}
                className="form-control"
                placeholder="Please Enter Phone No"
                value={iPhone}
                onChange={(e) => setiPhone(e.target.value)}
              />
            </div>
            {bIsPhone1 && (
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Phone 1</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Please Enter Phone No"
                  value={iPhone1}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Phone2Tab}
                  onChange={(e) => setiPhone1(e.target.value)}
                />
              </div>
            )}
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Mobile</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                placeholder="Please Enter Mobile No"
                value={iMobile}
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].Mobile1Tab}
                onChange={(e) => setiMobile(e.target.value)}
              />
            </div>
            {bIsMobile1 && (
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Mobile 1</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Please Enter Mobile No"
                  value={iMobile1}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Mobile2Tab}
                  onChange={(e) => setiMobile1(e.target.value)}
                />
              </div>
            )}
            <div className="col-md-3 col-sm-12 mb-2">
              <label>E-Mail </label>
              <input
                type="email"
                aria-label="First name"
                className="form-control"
                placeholder="Please Enter Email"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].Emailtab}
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>GST</label>
              <input
                type="text"
                aria-label="First name"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].GstTab}
                className="form-control"
                placeholder="Please Enter GST No"
                value={strGst}
                onChange={(e) => setstrGst(e.target.value)}
                maxLength="15"
              />
            </div>
            {bIsStatus && (
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_selecrparem.isoptionShow2}
                  placeholder="Select Status"
                  select_value={(val) => setiStatusId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].StatusTab}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatusId}
                  displayName="strStatusName"
                  disvalue="iStatusId"
                  arrydata={listEntStatus}
                  disabled={false}
                  EmptVal="Status "
                />
              </div>
            )}
            {bIsRemark && (
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Remarks</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].RemarksTab}
                  placeholder="Please Enter Remark"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
            )}
            {bIsBalType && (
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Balance Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select Balance Type"
                  select_value={(val) => setiBalTypeId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].BalTypeTab}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iBalTypeId}
                  displayName="strBalType"
                  disvalue="iBalTypeId"
                  arrydata={listEntBalTyp}
                  disabled={false}
                  EmptVal="Balance Type "
                />
              </div>
            )}
            {bIsOpenBal && (
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Opening Balance</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].OpeningBalTab}
                  placeholder="Please Enter Opening Balance"
                  value={iOpeningBal}
                  onChange={(e) => setiOpeningBal(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
            )}
            {bIsCreditLim && (
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Credit Limit</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  setstrCreditTerm
                  name="iCreditLimit"
                  placeholder="Please Enter Credit Limit"
                  value={iCreditLimit}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].CreditLimitTab}
                  onChange={(e) => setiCreditLimit(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
            )}
            {bIsCreditTerm && (
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Credit Term Days</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].CreditTermTab}
                  value={strCreditTerm}
                  onChange={(e) => setstrCreditTerm(e.target.value)}
                  placeholder="Enter CreditTerm"
                  name="strCreditTerm"
                  onClick={(e) => e.target.select()}
                  onBlur={(e) => setstrCreditTerm(strCreditTerm)}
                />
              </div>
            )}
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Royalty Point</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={dRoyaltyPoint}
                onChange={(e) => setdRoyaltyPoint(e.target.value)}
                placeholder="RoyaltPoint"
                onClick={(e) => e.target.select()}
              />
            </div>
            {bIsPriceGroup && (
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Price Group</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow4"
                  showoption={_selecrparem.isoptionShow4}
                  placeholder="Select Price Group"
                  select_value={(val) => setiPriceGrpId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].PriceGrpTab}
                  btnname="Price Group"
                  btnshow={true}
                  show_popup={(e) => setisShowPriceGroup(!isShowPriceGroup)}
                  valueonly={true}
                  defaultval={iPriceGrpId}
                  displayName="strPGrpName"
                  disvalue="iPGrpId"
                  arrydata={listEntPriGrp}
                  disabled={false}
                  EmptVal="Price Group "
                />
              </div>
            )}
          </div>
          {IsMasterCode && (
            <MasterCodeSetting
              Close={(e) => setIsMasterCode(!IsMasterCode)}
              iScrId={iScrId}
            />
          )}
          <Messagedialogbox />
          {ExpImpOpen && (
            <ExportImport
              Close={(e) => {
                setExpImpOpen(!ExpImpOpen);
                ClearClick();
              }}
              iScrId={iScrId}
            />
          )}
          {isShowPriceGroup && (
            <PriceGroupPopup
              Close={(e) => {
                setisShowPriceGroup(!isShowPriceGroup);
                Load_CustomerMaster();
              }}
            />
          )}
          {bIsNewAddress && (
            <AddressPopup
              ScrId={iScrId}
              PartyId={iCustId}
              PartyName={strCustomerName}
              Close={(e) => setbIsNewAddress(!bIsNewAddress)}
            />
          )}
        </div>
        <Button
          Save={(e) => {
            if (PropertiesValidation()) {
              if (strGst.length > 0 && strGst.length < 15)
                Show_Message(dispatch, "Please Enter Valid GST Number", "info");
              else
                Ask_Question(
                  dispatch,
                  SaveClick,
                  "Do You Want to  Save Customer?"
                );
            }
          }}
          Export={(e) => setExpImpOpen(!ExpImpOpen)}
          Clear={ClearClick}
          Delete={(e) => {
            if (iCustId === 0) {
              Show_Message(dispatch, "Please Select Customer", "info");
            } else {
              Ask_Question(
                dispatch,
                DeleteClick,
                "Do You Want to  Delete Customer?"
              );
            }
          }}
          ScrId={iScrId}
          Iid={iCustId}
          SaveIndex={ListTabOrder[0].BtnSavtab}
          DeleteIndex={ListTabOrder[0].BtnDeletetab}
          CloseIndex={ListTabOrder[0].BtnClosetab}
          ClearIndex={ListTabOrder[0].BtnCleartab}
          ExportIndex={ListTabOrder[0].BtnExporttab}
          onKeyDown={(e) => TabIndex(e)}
        />
      </div>
    </main>
  );
}
// Export Default Function for ReUse in Other Components or Routing Component
export default CustomerMaster;
