import React, { useEffect } from "react";
import ReactTable from "../General/ReactTable";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import {
  Clear_Product,
  Modify_CompositeProduct,
  ProductInput_Onchange,
  Select_Unit,
  ShowHide_Popup,
} from "../features/Transactionstates";
import { API_FETCH, API_POST } from "../General/Utility";

function Compositeproduct(props) {
  const dispatch = useDispatch();
  const _Product = useSelector((store) => store.Transstates.Product);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _select = useSelector((state) => state.SelectOption.parems);

  const AddProductClick = async () => {
    if (Number(_Product.dQty) === 0)
      Show_Message(dispatch, "Please Ente Qty", "info");
    else {
      const objProduct = {
        iCProId: props.iProId,
        iCmpDTId: _Product.iCmpsDTId,
        iProId: _Product.iProId,
        iUnitId: Number(_Product.iUnitId),
        iAttGrpId: _Product.AttGrpId,
        strUnitName: _Product.strUnitName,
        strProName: _Product.strProName,
        dQty: Number(_Product.dQty),
      };
      const res = await API_POST(
        "ProductMaster/AddUpd_Product",
        {
          objProduct: objProduct,
          _EntProduct: props.arrEntProduct,
          UpdateStatus: _Product.bUpdateStatus,
          Index: _Product.iRowIndex,
        },
        dispatch
      );
      if (res) {
        await props.setarrProductDT(res.EntProduct);
        await dispatch(Clear_Product());
      }
    }
  };
  const Remove_Product = async (rowinfo, Index) => {
    const objProduct = {
      iCmpsId: props.iProId,
      iCmpDTId: rowinfo.iCmpDTId,
      iProId: rowinfo.iProId,
      iUnitId: Number(rowinfo.iUnitId),
      iAttGrpId: rowinfo.iAttGrpId,
      strUnitName: rowinfo.strUnitName,
      strProName: rowinfo.strProName,
      dQty: Number(rowinfo.dQty),
    };
    const res = await API_POST(
      "ProductMaster/Remove_Product",
      {
        objProduct: objProduct,
        _EntProduct: props.arrEntProduct,
        UpdateStatus: false,
        Index: Index,
      },
      dispatch
    );
    if (res) {
      await props.setarrProductDT(res.EntProduct);
      await dispatch(Clear_Product());
    }
  };
  const Input_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const ColumList = [
    {
      Header: "Product Name",
      accessor: "strProName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Qty",
      accessor: "dQty",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Unit",
      accessor: "strUnitName",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Delete",
      minWidth: 40,
      maxWidth: 70,
      style: { justifyContent: "center" },
      cell: ({ rowinfo, Index }) => (
        <i
          onClick={() => {
            Remove_Product(rowinfo, Index);
          }}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const Assign_CompositeProduct = async (product, Index) => {
    const res = await API_FETCH(
      `ProductTrans/View_ProductUnit/${product.iProId}/Sales/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      const objCmpPro = {
        Product: product,
        Unit: res.ProUnit,
        iRowIndex: Index,
      };
      dispatch(Modify_CompositeProduct(objCmpPro));
    }
  };
  return (
    <div className="mt-2">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <label>Disc Calculation Type</label>
          <CustomizedSelectoption
            optionparem="isoptionShow16"
            showoption={_select.isoptionShow16}
            placeholder="Select Disc Calculation Type"
            select_value={(val) => props.setiDisCal(val)}
            btnname=""
            btnshow={false}
            show_popup={""}
            valueonly={true}
            defaultval={props.iDisCal}
            displayName="strCalType"
            disvalue="iCalTypeId"
            arrydata={props.arrEntCalType}
            EmptVal="Disc Calculation Type"
            disabled={false}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label>Rate Calculation Type</label>
          <CustomizedSelectoption
            optionparem="isoptionShow17"
            showoption={_select.isoptionShow17}
            placeholder="Select Rate Calculation Type"
            select_value={(val) => props.setiRateCal(val)}
            btnname=""
            btnshow={false}
            show_popup={""}
            valueonly={true}
            defaultval={props.iRateCal}
            displayName="strCalType"
            disvalue="iCalTypeId"
            arrydata={props.arrEntCalType}
            EmptVal="Rate Calculation Type"
            disabled={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <label>Product</label>
          <div className="custom-select">
            <input
              type="text"
              className="form-control"
              defaultValue={_Product.strProName}
              onChange={(e) => dispatch(ShowHide_Popup("IsProSearch"))}
              onClick={(e) => dispatch(ShowHide_Popup("IsProSearch"))}
              placeholder="Select Product"
            />
            <i className="fa-solid fa-caret-down"></i>
          </div>
        </div>
        <div className="col-sm-6 col-md-2">
          <label>QTY</label>
          <input
            type="number"
            className="form-control"
            name="dQty"
            autoComplete="off"
            value={_Product.dQty}
            onChange={(e) => {
              Input_Onchange(e);
            }}
            onClick={(e) => e.target.select()}
            disabled={_Product.iProId === 0 ? true : false}
          />
        </div>
        <div className="col-sm-6 col-md-3">
          <label>Unit</label>
          <CustomizedSelectoption
            optionparem="isoptionShow19"
            showoption={_select.isoptionShow19}
            placeholder="Unit"
            select_value={(val) =>
              dispatch(
                Select_Unit({
                  dPriceRate: 0,
                  UnitId: val.iUnitId,
                  strUnitName: val.strUnitName,
                })
              )
            }
            btnname="Unit"
            btnshow={false}
            show_popup=""
            valueonly={false}
            defaultval={_Product.iUnitId}
            displayName="strUnitName"
            disvalue="iUnitId"
            arrydata={_Product.Unit}
            disabled={_Product.iProId === 0 ? true : false}
            EmptVal="Unit"
          />
        </div>
        <div className="col-sm-4 col-md-2">
          <div className="btn-sub-section mt-3">
            <button
              className="btn-fabgreen"
              onClick={AddProductClick}
              onKeyDown={(e) => {
                if (e.key === "Enter") AddProductClick();
              }}
            >
              Add&nbsp;<i className="bx bx-layer-plus"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() => dispatch(Clear_Product())}
            >
              Clear&nbsp;<i className="fas fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
      <ReactTable
        columns={ColumList}
        data={props.arrEntProduct}
        minRows={4}
        row_click={(ProInfo, Index) => {}}
        row_doubleclick={(ProInfo, Index) =>
          Assign_CompositeProduct(ProInfo, Index)
        }
        background={true}
        className="full-table"
        tabIndex={0}
        KeyDown={""}
      />
    </div>
  );
}

export default Compositeproduct;
