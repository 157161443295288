import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import {
  KeyUpDown,
  Load_Transaction,
  Validate_BtnPermission,
  Validate_Properties,
} from "./Transaction";
import {
  Clear_Transaction,
  ShowHide_Popup,
} from "../features/Transactionstates";
import { API_DELETE } from "../General/Utility";
function TransactionButton(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);

  useEffect(() => {
    Validate_BtnPermission(_Common, _HT, _DT, dispatch);
  }, [_Common.iScrId, _HT.iVouchId, _DT.arrEntPenCon]);

  const Delete_Voucher = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_DELETE(
        `Transaction/Delete_Voucher/${Number(_AuthParems.Branch)}/${
          _Common.iScrId
        }/${_HT.iVouchId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        await Show_Message(
          dispatch,
          `${_Common.strScrName} Successfully Deleted`,
          "success"
        );
        await Clear_Click();
      }
    } catch (e) {
      await Show_Message(dispatch(e.message, "error"));
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Clear_Click = async () => {
    try {
      await Pre_Loading(dispatch, true);
      await dispatch(Clear_Transaction());
      await Load_Transaction(dispatch, _Common, _Common.iScrId);
    } catch (e) {
      await Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  return (
    <div className="button-list">
      <button
        className="btn-fabgreen"
        onClick={() => dispatch(ShowHide_Popup("IsPendinCon"))}
        disabled={_Common.btnConvert}
        onKeyDown={(e) => {
          if (e.key === "Enter") dispatch(ShowHide_Popup("IsPendinCon"));
          else KeyUpDown(e);
        }}
      >
        Convert&nbsp;<i className="bx bx-transfer"></i>
      </button>
      <button
        disabled={_Common.btnSave}
        className="btn-fabgreen"
        tabIndex={_Common.arrtaborder.btnSave}
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            if (
              await Validate_Properties(_Common.arrValidation, _HT, dispatch)
            ) {
              Ask_Question(
                dispatch,
                props.Save,
                _HT.iVouchId > 0
                  ? `Do You Want to Update ${_Common.strScrName}?`
                  : `Do You Want to Save ${_Common.strScrName}?`
              );
            }
          } else KeyUpDown(e);
        }}
        onClick={async () => {
          if (await Validate_Properties(_Common.arrValidation, _HT, dispatch)) {
            Ask_Question(
              dispatch,
              props.Save,
              _HT.iVouchId > 0
                ? `Do You Want to Update ${_Common.strScrName}?`
                : `Do You Want to Save ${_Common.strScrName}?`
            );
          }
        }}
      >
        Save&nbsp;<i className="fas fa-save"></i>
      </button>
      <button
        disabled={_Common.btnView}
        className="btn-fabgreen"
        onClick={() => dispatch(ShowHide_Popup("IsTransView"))}
        tabIndex={_Common.arrtaborder.btnView}
        onKeyDown={(e) => {
          if (e.key === "Enter") dispatch(ShowHide_Popup("IsTransView"));
          else KeyUpDown(e);
        }}
      >
        View &nbsp;<i className="fas fa-eye"></i>
      </button>
      <button
        disabled={_Common.btnDelete}
        className="btn-fabgreen"
        tabIndex={_Common.arrtaborder.btnCancel}
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            if (_HT.iVouchId === 0) {
              Show_Message(
                dispatch,
                "Please Select Voucher to Delete!",
                "error"
              );
            } else {
              Ask_Question(
                dispatch,
                Delete_Voucher,
                `Do You Want to Delete ${_Common.strScrName}?`
              );
            }
          } else KeyUpDown(e);
        }}
        onClick={async () => {
          if (_HT.iVouchId === 0) {
            Show_Message(dispatch, "Please Select Voucher to Delete!", "error");
          } else {
            Ask_Question(
              dispatch,
              Delete_Voucher,
              `Do You Want to Delete ${_Common.strScrName}?`
            );
          }
        }}
      >
        Delete&nbsp;<i className="bx bx-trash-alt"></i>
      </button>
      <button
        className="btn-fabgreen"
        onClick={(e) => Clear_Click()}
        tabIndex={_Common.arrtaborder.btnClear}
        onKeyDown={(e) => {
          if (e.key === "Enter") Clear_Click();
          else KeyUpDown(e);
        }}
      >
        Clear&nbsp;<i className="fa-solid fa-broom"></i>
      </button>
      <button
        className="btn-fabgreen"
        tabIndex={_Common.arrtaborder.btnClose}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            sessionStorage.removeItem("VoucherId");
            sessionStorage.removeItem("VoucherType");
            window.location.href = "/JustBill/Dashboard";
          } else KeyUpDown(e);
        }}
        onClick={() => {
          sessionStorage.removeItem("VoucherId");
          sessionStorage.removeItem("VoucherType");
          window.location.href = "/JustBill/Dashboard";
        }}
      >
        Close&nbsp;<i className="fas fa-times"></i>
      </button>
    </div>
  );
}
export default TransactionButton;
