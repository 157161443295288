import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ask_Question } from "./Messagedialogbox";

function FinYearKeyMessage(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((state) => state.Common.Admin);
  const [isConfirmCreation, setisConfirmCreation] = useState(false);
  const [isRestore, setisRestore] = useState(false);

  useEffect(() => {
    _Common.CompanyPer.forEach((per) => {
      if (per.iCompId === _Common.CompId) setisRestore(per.isRestore);
    });
  }, []);

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Notice ! </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2 ">
          <div className="row p-2">
            {!isConfirmCreation && (
              <>
                <div className="Finkey">
                  <h5>Financial Update</h5>
                  <p>
                    Financial Year is Expired Please Creat New Financial Year !
                  </p>
                  <p>
                    Please Contact Customer Support
                    <strong>+91-9629325653</strong>
                  </p>
                </div>
              </>
            )}
            {isConfirmCreation && (
              <div className="Finkey">
                <h5>
                  Note<span className="danger">*</span>
                </h5>
                <p>1.Please Clear All Pending Invoices and Payments</p>
                <p>
                  2.Other Wise You Can't Modify Pending Invoices in New
                  Financial Year
                </p>
                <p>
                  3.All Available Stock Added Into Opening Stock of New
                  Financial Year
                </p>
                <p>
                  4.All Pending Payment Amount Added into Party Opening Balance
                  of New Financial Year
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {!isConfirmCreation && isRestore && (
              <button
                onClick={(e) => setisConfirmCreation(true)}
                className="btn-fabgreen"
              >
                Create Now&nbsp;<i className="bx bx-calendar-alt"></i>
              </button>
            )}
            {isConfirmCreation && (
              <button
                onClick={(e) =>
                  Ask_Question(
                    dispatch,
                    props.CreateFinYear,
                    "Do You Want to Create Financial Year ?"
                  )
                }
                className="btn-fabgreen"
              >
                Create Year&nbsp;<i className="bx bx-calendar-alt"></i>
              </button>
            )}

            <button onClick={props.Close} className="btn-fabgreen">
              Cancel&nbsp;<i className="bx bx-block"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinYearKeyMessage;
