import ReactTable from "../General/ReactTable";

function SearchOtherHeadLedger({ View_Ledger, Close, LoadLedger }) {
  const columns = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "Code",
      accessor: "ledCode",
      maxWidth: 100,
    },
    {
      Header: "Name",
      accessor: "ledName",
      id: "ledId",
    },
    {
      Header: "GroupName",
      accessor: "tGrpName",
    },
  ];
  return (
    <div className="UOMMaster">
      <div className="UOMMaster-Box">
        <div className="UOMcontent mt-1">
          <div className="Ledger">
            <div className="row">
              <ReactTable
                columns={columns}
                data={LoadLedger}
                minRows={5}
                row_click={(Ledger, Index) => {}}
                row_doubleclick={(Ledger, Index) => {
                  View_Ledger(Ledger);
                }}
                background={true}
                className="full-table"
              />
            </div>
          </div>
        </div>
        <span className="close-icon" onClick={Close}>
          x
        </span>
      </div>
    </div>
  );
}
export default SearchOtherHeadLedger;
