import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useEffect } from "react";
import { Show_Message } from "../General/Messagedialogbox";
import {
  Clear_DeiveryParems,
  Input_Onchange,
} from "../features/Transactionstates";
function DeliveryOption(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _parem = useSelector((state) => state.SelectOption.parems);

  useEffect(() => {
    short_cut_keypress();
  }, []);
  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        props.Close();
      }
    });
  };
  const Input_ValueChange = async (e) => {
    await dispatch(
      Input_Onchange({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Delivery Option</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pb-2">
          <div className="row">
            <div className="col-sm-12 col-md-12  mt-2">
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_parem.isoptionShow}
                placeholder="Select Delivery Mode"
                select_value={(val) =>
                  Input_ValueChange({
                    target: { name: "iDMId", value: val },
                  })
                }
                btnname="Employee"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={_HT.iDMId}
                displayName="strDeliveryMode"
                disvalue="iDM_Id"
                arrydata={_Common.DelMode}
                disabled={false}
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="strReference"
                value={_HT.strReference}
                tabIndex={2}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Reference"
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="strDestination"
                value={_HT.strDestination}
                tabIndex={3}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Destination"
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="strEComGstNo"
                value={_HT.strEComGstNo}
                tabIndex={4}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="E-Com GST"
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="strTransport"
                value={_HT.strTransport}
                tabIndex={5}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Transport"
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="strVehicle"
                value={_HT.strVehicle}
                tabIndex={6}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Vechicle No"
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="strDespatch"
                value={_HT.strDespatch}
                tabIndex={7}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Despatch Through"
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="date"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="dpDespatchDate"
                value={_HT.dpDespatchDate}
                tabIndex={8}
                onChange={(e) => Input_ValueChange(e)}
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="strLRNNumber"
                value={_HT.strLRNNumber}
                tabIndex={9}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Licence No"
              />
            </div>
            <div className="col-sm-12 col-md-12  mt-2">
              <input
                type="date"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="dpLRNoDate"
                value={_HT.dpLRNoDate}
                onChange={(e) => Input_ValueChange(e)}
                tabIndex={10}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => dispatch(Clear_DeiveryParems())}
              tabIndex={12}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryOption;
