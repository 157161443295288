import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "../General/Utility";

function Configuration() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Config = useSelector((state) => state.Config.Config);
  const [strSearch, setstrSearch] = useState("");
  const [Filter, setFilter] = useState(0);
  const [arrEntConf, setarrEntConf] = useState([]);
  useEffect(() => {
    Load_ConfigurationSetting(_Config.value);
  }, [_Config.value]);

  const Load_ConfigurationSetting = async (Value) => {
    try {
      const res = await API_FETCH(
        `ConfigurationSetting/Load_ConfigurationSetting/${Value}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setarrEntConf(res.EntSetting);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Save_ConfigurationSetting = async () => {
    if (arrEntConf.length === 0)
      Show_Message(dispatch, "Atleast One Change Required", "info");
    else {
      const res = await API_POST(
        "ConfigurationSetting/Update_Configuration/",
        {
          _objUpdSetting: arrEntConf,
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_ConfigurationSetting();
      }
    }
  };
  const Clear_ConfigurationSetting = () => {
    setstrSearch("");
    setarrEntConf([]);
    Load_ConfigurationSetting(_Config.value);
  };
  const Search_Configuration = async (Value, Searchfor) => {
    let FilterId = Number(Filter);
    let Searchtxt = strSearch;
    switch (Searchfor) {
      case "Filter":
        setFilter(Value);
        FilterId = Number(Value);
        break;
      default:
        setstrSearch(Value);
        Searchtxt = Value;
        break;
    }
    const objSearch = {
      iIndex: FilterId,
      iBranchId: Number(_AuthParems.Branch),
      strSearch: Searchtxt,
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      `ConfigurationSetting/Search_ConfigurationSetting/`,
      objSearch,
      dispatch
    );
    if (res) {
      setarrEntConf(res.EntSetting);
    }
  };
  const IsShowOption = (val, ctl) => {
    let EntSett = arrEntConf.map((dt) => {
      if (dt.ctrlType === ctl) {
        dt.isShow = val;
      } else dt.isShow = false;
      return dt;
    });
    setarrEntConf(EntSett);
  };
  const Input_OnChange = (val, Options) => {
    let EntSett = arrEntConf.map((dt) => {
      if (dt.ctrlType === Options.ctrlType) {
        dt.value = val;
      }
      dt.isShow = false;
      return dt;
    });
    setarrEntConf(EntSett);
  };
  const IsTansOption = (sett) => {
    switch (sett.ctrlType) {
      case "cmbT13":
        return sett._BillType;
      case "cmbT22":
        return sett._TaxRelTyp;
      case "cmbX2":
        return sett._ALCalOpt;
      case "cmbT38":
        return sett._TaxCalOpt;
      default:
        return sett.options;
    }
  };
  const OptionDisplay = (dt, options) => {
    switch (options.ctrlType) {
      case "cmbT13":
        return dt.strBillType;
      case "cmbT22":
        return dt.strTaxType;
      case "cmbX2":
      case "cmbT38":
        return dt.optionName;
      case "cmbZ2":
        return dt.strDiscOption;
      default:
        return dt;
    }
  };
  const OptionValue = (dt, options) => {
    switch (options.ctrlType) {
      case "cmbT13":
        return dt.iBT_Id;
      case "cmbT22":
        return dt.iTaxRelId;
      case "cmbX2":
        return dt.taxCalcOnId;
      case "cmbT38":
        return dt.addLessBillDiscId;
      case "cmbZ2":
        return dt.iDiscOpionId;
      default:
        return dt;
    }
  };
  return (
    <div className="Config-card">
      <div className="config-h">
        <h3>{_Config.Header}</h3>
        <div className="m-hsearch">
          <input
            type="text"
            autoCorrect="off"
            autoComplete="off"
            placeholder="Search Voucher Screen"
            value={strSearch}
            onChange={(e) => {
              Search_Configuration(e.target.value, "Search");
            }}
            autoFocus
          />
          <i
            className="fas fa-search"
            onClick={(e) => Search_Configuration(strSearch, "Search")}
          ></i>
        </div>
      </div>
      <div className="Config-Wrapper mt-1">
        <table className="option-list">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Options</th>
              <th>Configuration</th>
            </tr>
          </thead>
          <tbody>
            {arrEntConf.map((config, Index) => (
              <tr key={Index}>
                <td>{config.sNo}</td>
                <td>{config.name}</td>
                <td>
                  {config.ctrlType.startsWith("cmb") && (
                    <div
                      className="custom-select"
                      onMouseLeave={(e) => IsShowOption(false, config.ctrlType)}
                    >
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Select Default Value"
                        value={config.value}
                        onChange={(e) => {}}
                        onClick={(e) => IsShowOption(true, config.ctrlType)}
                      />
                      <i
                        className="fa-solid fa-caret-down"
                        style={{
                          position: "absolute",
                          right: "10px",
                          color: "darkgreen",
                          transform: config.isShow
                            ? "rotate(-180deg)"
                            : "rotate(0deg)",
                        }}
                      ></i>
                      {config.isShow && (
                        <div className="custom-option">
                          <div className="option-list">
                            {IsTansOption(config).map((dt, Index) => (
                              <button
                                style={{
                                  height: "32px",
                                  borderBottom: "none",
                                }}
                                key={Index}
                                tabIndex={Index}
                                onClick={(e) => {
                                  Input_OnChange(
                                    OptionValue(dt, config),
                                    config
                                  );
                                }}
                              >
                                {OptionDisplay(dt, config)}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {config.ctrlType.startsWith("txt") && (
                    <input
                      type="text"
                      className="form-control"
                      spellCheck={false}
                      autoComplete="off"
                      onClick={(e) => e.target.select()}
                      defaultValue={config.value}
                      onChange={(e) => (config.value = e.target.value)}
                    />
                  )}
                  {config.ctrlType.startsWith("dtp") && (
                    <input
                      type="date"
                      className="form-control"
                      defaultValue={config.value}
                      onChange={(e) => (config.value = e.target.value)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="btn-section">
        <button className="btn-fabgreen" onClick={Save_ConfigurationSetting}>
          Update &nbsp; <i className="fas fa-Save"></i>
        </button>
        <button className="btn-fabgreen" onClick={Clear_ConfigurationSetting}>
          Clear &nbsp; <i className="fas fa-eraser"></i>
        </button>
      </div>
    </div>
  );
}

export default Configuration;
