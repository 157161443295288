import React from "react";
import CounterMaster from "../Organization/CounterMaster";
import Sessionmaster from "../Organization/SessionMaster";
import { useState } from "react";
function PosTransaction() {
  const [isNewCouter, setisNewCouter] = useState(false);
  return (
    <>
      {!isNewCouter && (
        <Sessionmaster Close={(e) => setisNewCouter(!isNewCouter)} />
      )}
      {isNewCouter && (
        <CounterMaster Close={(e) => setisNewCouter(!isNewCouter)} />
      )}
    </>
  );
}

export default PosTransaction;
