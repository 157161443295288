import React, { useEffect, useState } from "react";
import PrintInvoicDesign from "./PrintInvoice";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import { API_FETCH } from "./Utility";
function WhatsApp(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [PhoneNumber, setPhoneNumber] = useState("");
  const [numberEmptyError, setnumberEmptyError] = useState(false);
  const [strMessage, setstrMessage] = useState("");
  const [EntData, setsetEntData] = useState();

  useEffect(() => {
    Load_Voucher();
  }, []);
  const Load_Voucher = async () => {
    if (props.iVouchId === 0) {
      Show_Message(dispatch, "Please Select Voucher", "info");
    } else {
      let VoucherId = props.iVouchId === undefined ? 0 : props.iVouchId;
      Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `PrintDesing/PrintPreView/${props.iScrId}/0/${VoucherId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`
      );
      if (res) {
        var objEntPdf = PrintInvoicDesign(res, props.ScrId, "PDF", true);
        if (objEntPdf.PartyInfo[0].mobile1 === "Mobile No : ") {
          setsetEntData(objEntPdf);
        } else {
          let MobNum = objEntPdf.PartyInfo[0].mobile1.replace(
            "Mobile No : ",
            ""
          );
          setPhoneNumber(MobNum);
          setsetEntData(objEntPdf);
        }
      }
    }
  };
  const Sent_WhatsappMsg = async () => {
    var Info = EntData;
    if (PhoneNumber.length < 1) {
      setnumberEmptyError(true);
      setTimeout(() => setnumberEmptyError(false), 3000);
    } else {
      var strCompanyName = _AuthParems.Branchname;
      var partyinfo = Info.PartyInfo[0];
      var Summary = Info.Summary[0];

      var Message = `
Dear ${partyinfo.custName},
Thank You for Your Recent Purchase Invoice-#${partyinfo.salInvNo}

We hope this message finds you well. We want to express our sincere gratitude for choosing ${strCompanyName} for your recent purchase. We appreciate your trust in our Products.

Your order details are as follows:

Invoice Number: ${partyinfo.salInvNo}
Date of Purchase: ${partyinfo.salInvDate}
Total Amount: ₹${stringtodecimal(Summary.netAmt)}

We understand that you have a choice when it comes to your Product needs, and we are thrilled to have had the opportunity to serve you. Our team is committed to providing top-notch quality and excellent customer service, and we hope your recent experience reflects that commitment.

If you have any questions about your purchase or if there's anything else we can assist you with, please don't hesitate to reach out to our customer support team at ${
        Info.mobile
      }.

Once again, thank you for choosing ${strCompanyName}. We truly value your business and look forward to serving you again in the future.

Best Regards,
${strCompanyName}`;

      // const File = UrlToFile(Info.PdfURL, Info.FileName);
      // const mediaURL = `https://graph.facebook.com/v13.0/158016350738053/media`;
      // const MessageURL = `https://graph.facebook.com/v13.0/158016350738053/messages`;

      // const Auth_Token = `Bearer EAAYYhFTjxQ0BO3luBWed7lkZBhSmcZA6uqbJdNZBawYiOWHKZA92hw1pOtZC1v83Q2FGBoZAmgEWAyXphCBM3Q8vK9CBRj2Gi397bZAY2STitUxoP5vtZC1iCPzYtF4AQbKqYSs4X0FvbQSX2k0Pj7oijA4DG4xdEx0F669vHThskd0MVZAvV7iB6j3U86sCTwPSFEqZA2zmyI7pwAHZConBrpIqyIj0mikAp6lZBHAZD`;

      // await fetch(MessageURL, {
      //   method: "POST", // *GET, POST, PUT, DELETE, etc.
      //   headers: {
      //     "content-type": "application/json",
      //     Authorization: Auth_Token,
      //   },
      //   body: {
      //     messaging_product: "whatsapp",
      //     recipient_type: "individual",
      //     to: PhoneNumber,
      //     type: "template",
      //     template: {
      //       name: "justbill_pos",
      //       namespace: "f6855786_54ba_48bb_8d7d_57e51d4957dd",
      //       language: { code: "en_US" },
      //       components: [
      //         {
      //           type: "header",
      //           parameters: [
      //             {
      //               type: "document",
      //               document: {
      //                 link: Info.PdfURL,
      //               },
      //             },
      //           ],
      //         },
      //         {
      //           type: "body",
      //           parameters: [
      //             {
      //               type: "text",
      //               text: strCustomerName,
      //             },

      //             {
      //               type: "text",
      //               text: Info.FileName,
      //             },
      //             {
      //               type: "text",
      //               text: strCompanyName,
      //             },
      //             {
      //               type: "text",
      //               text: 9360062917,
      //             },
      //             {
      //               type: "text",
      //               text: Message,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   },
      // });

      const encodedAttachmentURL = encodeURIComponent(
        Info.PdfURL.split(",")[1]
      );
      let number = PhoneNumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");
      number = 91 + number;
      let url = `https://web.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(
        Message
      )}&source=${encodedAttachmentURL}&app_absent=0`;
      window.open(url);
    }
  };
  const stringtodecimal = (num) => {
    return Number(num).toFixed(2);
  };
  const UrlToFile = (dataUrl, fileName) => {
    // Split the Data URL to get the content type and data
    const [, contentType, data] = dataUrl.match(/data:(.*?);base64,(.*)/);

    // Convert base64 to a Blob
    const blob = new Blob([atob(data)], { type: contentType });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: contentType });

    return file;
  };
  const Clear_Message = () => {};

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Sent WhatsApp</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            {numberEmptyError && (
              <div style={{ color: "red", fontSize: "13px" }}>
                Mobile number cannot be empty!
              </div>
            )}
            <div className="col-sm-12 col-md-12">
              <label style={{ color: "gray" }}>Phone Number</label>
              <div className="What_Phone">
                <span>+91</span>
                <input
                  className="form-control"
                  type="number"
                  value={PhoneNumber}
                  placeholder="Please Enter Mobile Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Notes</label>
              <input
                className="form-control"
                type="text"
                value={strMessage}
                placeholder="Please Enter Message"
                onChange={(e) => setstrMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={Sent_WhatsappMsg}
            >
              Sent Message
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={Clear_Message}
            >
              Clear Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatsApp;
