import React, { useEffect, useState, useRef } from "react";
import JsBarcode from "jsbarcode";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import { API_FETCH, DecryptData } from "../General/Utility";
import { useDispatch, useSelector } from "react-redux";
function BarcodePrint(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [IsLoding, setIsLoding] = useState(true);
  const [strMessage, setstrMessage] = useState("");
  const [strIcon, setstrIcon] = useState("");
  const [IsNotification, setIsNotification] = useState(false);

  useEffect(() => {
    LoadBarCodeDesign();
  }, []);
  const LoadBarCodeDesign = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);

      var CompId = Number(localStorage.iCompId);
      const res = await API_FETCH(
        "Barcode/LoadPrintDesign/" + CompId + "/" + _LocalParems.DB,
        dispatch
      );
      if (res) {
        setIsLoding(false);
        ShowBarCode(
          res.objbarCodeDesign,
          res.objEntCodeFormate,
          res.objEntCompanyInfo,
          res.objEntState,
          res.objEntPrintDesign,
          props.ProductList
        );
      }
    } catch (err) {
      setIsLoding(false);
      MsgBox(err.message, "error");
    }
  };
  const BarcodeBasedOn = (BarcodeFormate) => {
    var Default = BarcodeFormate.filter((Data) => Data.defaultValue === 1);
    var CodeBasedOn = "";
    switch (Default[0].displayName) {
      case "Product Code":
        CodeBasedOn = "strProCode";
        break;
      case "Product Name":
        CodeBasedOn = "strProName";
        break;
      case "HSN Code":
        CodeBasedOn = "strCommodityCode";
        break;
      case "Unit Name":
        CodeBasedOn = "strUnitName";
        break;
      case "Remark":
        CodeBasedOn = "Remarks";
        break;
      default:
        CodeBasedOn = "dPriceRate";
        break;
    }
    return CodeBasedOn;
  };
  const BarcodeCompanuDataAssign = (
    PrintDesign,
    ProductList,
    CompanyInfo,
    StateName
  ) => {
    let PoductAssign = PrintDesign.filter((Data) => Data.columType === 2);
    let CompanyAssign = PrintDesign.filter((Data) => Data.columType === 1);
    var State = StateName.filter(
      (Data) => Number(Data.iSId) === CompanyInfo[0].iSName
    );
    let BarcodeData = [];
    for (var P = 0; P < ProductList.length; P++) {
      var BarcodeContent = [];
      for (var i = 0; i < PoductAssign.length; i++) {
        const DesignData = {
          columType: PoductAssign[i].columType,
          dWidth: PoductAssign[i].dWidth,
          dXAxis: PoductAssign[i].dXAxis,
          dYAxis: PoductAssign[i].dYAxis,
          iColumnId: PoductAssign[i].iColumnId,
          iFontSize: PoductAssign[i].iFontSize,
          inOrder: PoductAssign[i].inOrder,
          printAreaId: PoductAssign[i].printAreaId,
          strColor: PoductAssign[i].strColor,
          strColumName: PoductAssign[i].strColumName,
          strFontWeight: PoductAssign[i].strFontWeight,
          strPrefix: PoductAssign[i].strPrefix,
          strSuffix: PoductAssign[i].strSuffix,
          strTextAlign: PoductAssign[i].strTextAlign,
          strValue: "",
        };
        switch (PoductAssign[i].strColumName) {
          case "ProCode":
            DesignData.strValue = ProductList[P].strProCode;
            break;
          case "ProName":
            DesignData.strValue = ProductList[P].strProName;
            break;
          case "PrintName":
            DesignData.strValue = ProductList[P].strPrintName;
            break;
          case "CommodityCode":
            DesignData.strValue = ProductList[P].strCommodityCode;
            break;
          case "PriceRate":
            DesignData.strValue = ProductList[P].dPriceRate;
            break;
          case "Discount":
            DesignData.strValue = ProductList[P].dDiscount;
            break;
          case "UnitName":
            DesignData.strValue = ProductList[P].strUnitName;
            break;
          case "Remarks":
            DesignData.strValue = ProductList[P].Remarks;
            break;
          default:
            break;
        }
        BarcodeContent.push(DesignData);
      }
      for (var j = 0; j < CompanyAssign.length; j++) {
        const DesignData = {
          columType: CompanyAssign[j].columType,
          dWidth: CompanyAssign[j].dWidth,
          dXAxis: CompanyAssign[j].dXAxis,
          dYAxis: CompanyAssign[j].dYAxis,
          iColumnId: CompanyAssign[j].iColumnId,
          iFontSize: CompanyAssign[j].iFontSize,
          inOrder: CompanyAssign[j].inOrder,
          printAreaId: CompanyAssign[j].printAreaId,
          strColor: CompanyAssign[j].strColor,
          strColumName: CompanyAssign[j].strColumName,
          strFontWeight: CompanyAssign[j].strFontWeight,
          strValue: "",
        };
        switch (CompanyAssign[j].strColumName) {
          case "CompanyName":
            DesignData.strValue = CompanyInfo[0].strCompName;
            break;
          case "Address1":
            DesignData.strValue = CompanyInfo[0].strAdd1;
            break;
          case "Address2":
            DesignData.strValue = CompanyInfo[0].strAdd2;
            break;
          case "Address3":
            DesignData.strValue = CompanyInfo[0].strAdd3;
            break;
          case "Address4":
            DesignData.strValue = CompanyInfo[0].strAdd4;
            break;
          case "State":
            DesignData.strValue = State[0].strSName;
            break;
          case "Phone":
            DesignData.strValue = CompanyInfo[0].strPhone;
            break;
          case "Pincode":
            DesignData.strValue = CompanyInfo[0].iPincode;
            break;
          case "Mobile":
            DesignData.strValue = CompanyInfo[0].strMobile;
            break;
          case "Email":
            DesignData.strValue = CompanyInfo[0].strEmail;
            break;
          case "TIN":
            DesignData.strValue = CompanyInfo[0].strTIN;
            break;
          case "GST":
            DesignData.strValue = CompanyInfo[0].strGST;
            break;
          case "Remarks":
            DesignData.strValue = CompanyInfo[0].strRemarks;
            break;
          case "Logo":
            DesignData.strValue = CompanyInfo[0].imgData;
            break;
          default:
            break;
        }
        BarcodeContent.push(DesignData);
      }
      BarcodeData.push(BarcodeContent);
    }
    return BarcodeData;
  };
  const PrintPdf = () => {
    const doc = new jsPDF("p", "px", [300, 500]);
    doc.html(document.getElementById("Barcode"), {
      callback: (pdf) => {
        pdf.save("Barcode.pdf");
      },
    });
  };
  const PrintBarcode = useRef();
  const Print = useReactToPrint({
    content: () => PrintBarcode.current,
  });

  const ShowBarCode = (
    CodeDesign,
    BarcodeFormate,
    CompanyInfo,
    StateName,
    PrintDesign,
    ProductList
  ) => {
    try {
      ProductList = ProductList.filter((Data) => Data.SelectProduct === true);
      var BarcodeValue = BarcodeBasedOn(BarcodeFormate);
      var BarCodeContent = BarcodeCompanuDataAssign(
        PrintDesign,
        ProductList,
        CompanyInfo,
        StateName
      );
      var BarCode = document.getElementById("Barcode");

      let TotalQty = ProductList.map((item) => item.Qty).reduce(
        (prev, next) => prev + next
      );
      let CurrentCopy = 0;
      let CurrentColumn = 0;
      for (var i = 0; i < ProductList.length; i++) {
        var ImageData = ProductList[i][BarcodeValue];
        var Qty = ProductList[i].Qty;
        for (var j = 0; j < Qty; j++) {
          CurrentCopy++;
          //#region  BarcodeDataContainer
          var BarcodeData = document.createElement("div");

          BarcodeData.setAttribute("id", "Pro_" + i + "Copy_" + j);

          var BarcodeParentElement = document.createElement("div");
          BarcodeParentElement.setAttribute(
            "id",
            "ProParen_" + i + "CopyParen_" + j
          );
          BarcodeParentElement.setAttribute(
            "style",
            "position:relative;width:100%;height:100%"
          );

          BarcodeData.appendChild(BarcodeParentElement);
          BarCode.appendChild(BarcodeData);
          let BarCodeContainer = document.getElementById(
            "ProParen_" + i + "CopyParen_" + j
          );

          //#endregion
          let AssignData = BarCodeContent[i].sort(
            (a, b) => a.inOrder - b.inOrder
          );
          //#region Append  BarcodeData to BarcodeDataContainer
          for (var BD = 0; BD < AssignData.length; BD++) {
            let contentStyle =
              "position:absolute;left:" +
              AssignData[BD].dXAxis +
              "px;top:" +
              AssignData[BD].dYAxis +
              "px;text-align:" +
              AssignData[BD].strTextAlign +
              ";font-size:" +
              AssignData[BD].iFontSize +
              "px;color: " +
              AssignData[BD].strColor +
              ";Font-weight: " +
              AssignData[BD].strFontWeight;

            if (AssignData[BD].strColumName === "Barcode") {
              var BarCodeImg = document.createElement("canvas");
              BarCodeImg.setAttribute("style", contentStyle);
              BarCodeImg.setAttribute("id", "Bar_Img_" + i + "_" + j + "");
              BarCodeContainer.appendChild(BarCodeImg);

              var ImgId = "#Bar_Img_" + i + "_" + j + "";
              let Codeformat = {
                format1: "pharmacode",
                format2: "CODE39",
              };
              JsBarcode(ImgId, ImageData, {
                format: Codeformat.format2,
                width: CodeDesign[0].dwidth,
                height: CodeDesign[0].dHeight,
                displayValue: false,
                font: "arial",
                textAlign: "center",
                fontSize: CodeDesign[0].iFontSize,
                lineColor: CodeDesign[0].strLineColor,
                background: "transparent",
              });
            } else {
              var BarCodeDataDiv = document.createElement("p");
              BarCodeDataDiv.setAttribute("style", contentStyle);
              BarCodeDataDiv.innerHTML =
                AssignData[BD].strPrefix +
                AssignData[BD].strValue +
                AssignData[BD].strSuffix;
              BarCodeContainer.appendChild(BarCodeDataDiv);
            }
          }
          //#endregion
          let prevConHeight = 0;
          let prevConWidth = 0;
          if (prevConHeight === 0)
            for (let NCh = 0; NCh < BarCodeContainer.children.length; NCh++) {
              prevConHeight =
                prevConHeight + BarCodeContainer.children[NCh].clientHeight;
              prevConWidth =
                prevConWidth < BarCodeContainer.children[NCh].clientHeight
                  ? BarCodeContainer.children[NCh].clientHeight
                  : prevConWidth;
            }

          if (CurrentColumn < CodeDesign[0].iNoColumn) CurrentColumn++;
          else {
            CurrentColumn = 1;
          }
          let Barcodeparent = document.getElementById("Pro_" + i + "Copy_" + j);
          let NoOffRow = Math.ceil(TotalQty / CodeDesign[0].iNoColumn);
          let CurrRow = Math.ceil(CurrentCopy / CodeDesign[0].iNoColumn);
          Barcodeparent.style.position = "absolute";
          Barcodeparent.style.padding = 0;
          Barcodeparent.style.minHeight = prevConHeight + "px";
          Barcodeparent.style.minWidth = prevConWidth + "px";
          Barcodeparent.style.left =
            CurrentColumn === 1
              ? "0px"
              : (prevConWidth + CodeDesign[0].columGap) * (CurrentColumn - 1) +
                "px";
          Barcodeparent.style.top =
            CurrRow === 1
              ? "0px"
              : (prevConHeight + CodeDesign[0].rowGap) * (CurrRow - 1) + "px";
          BarCode.style.position = "relative";
          BarCode.style.width = "100%";
          BarCode.style.minHeight =
            (prevConHeight + CodeDesign[0].rowGap) * NoOffRow + "px";
          BarCode.style.top = 0;
          BarCode.style.left = 0;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const MsgBox = (Message, Icon) => {
    setIsNotification(!IsNotification);
    setstrMessage(Message);
    setstrIcon(Icon);
  };
  return (
    <div className="Popup-Screen">
      <div className="Popup-Screen-Box ">
        <div
          className="Popup-Screen-Container p-2"
          style={{ maxHeight: "400px", overflow: "auto" }}
        >
          <div className="Popup-Header">
            <p>BarCode Preview</p>
            <div className="icon-popup-btn-grp">
              <span className="icon-btn" onClick={props.Close}>
                <i className="fa-regular fa-rectangle-xmark"></i>
              </span>
              <span className="icon-btn" onClick={PrintPdf}>
                <i className="fa-solid fa-file-pdf"></i>
              </span>
              <span className="icon-btn" onClick={Print}>
                <i className="fa-sharp fa-solid fa-barcode"></i>
              </span>
            </div>
            <div className="d-flex justify-content-center"></div>
          </div>
          <div style={{ minHeight: "300px" }}>
            <div id="Barcode" ref={PrintBarcode}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarcodePrint;
