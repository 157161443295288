import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrintInvoicDesign from "../General/PrintInvoice";
import { useDispatch } from "react-redux";
import { API_FETCH, DecryptData } from "../General/Utility";
function PrintScreen(props) {
  const dispatch = useDispatch();
  const [IsLoding, setIsLoding] = useState(true);
  const [strMessage, setstrMessage] = useState("");
  const [strIcon, setstrIcon] = useState("");
  const [IsNotification, setIsNotification] = useState(false);

  useEffect(() => {
    Load_PrintScreen();
  }, []);
  const Load_PrintScreen = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);

      let VoucherId = props.iVouchId === undefined ? 0 : props.iVouchId;
      let TempId = props.iFormateId > 0 ? props.iFormateId : 0;
      const res = await API_FETCH(
        `PrintDesing/PrintPreView/${
          props.iScrId
        }/${TempId}/${VoucherId}/${Number(_LocalParems.Branch)}/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        PrintInvoicDesign(res, props.iScrId, "Print");
      }
    } catch (err) {
      setIsLoding(false);
      MsgBox("Oops!", err.strMessage, "info");
    }
  };
  const MsgBox = (Header, Message, Icon) => {
    setIsNotification(!IsNotification);
    setstrMessage(Message);
    setstrIcon(Icon);
  };
  const PrintRef = useRef();
  const Print = useReactToPrint({
    content: () => PrintRef.current,
    pageStyle: "@page {size: A4}",
  });
  return (
    <div className="Popup-Screen">
      <div
        className="Popup-Screen-Box"
        style={{ zIndex: "1", marginTop: "50px" }}
      >
        <div className="Popup-Screen-Container" style={{ minHeight: "600px" }}>
          <div className="Popup-Header">
            <p> PRINT PREVIEW</p>
            <div className="icon-popup-btn-grp">
              <span className="icon-btn" onClick={Print}>
                <i className="fa-solid fa-print"></i>
              </span>
              <span className="icon-btn" onClick={props.Close}>
                <i className="fa-solid fa-print"></i>
              </span>
            </div>
          </div>
          <div id="PrintPdf" ref={PrintRef}></div>
        </div>
      </div>
    </div>
  );
}
export default PrintScreen;
