import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import $ from "jquery";
import { API_FETCH, API_POST, Backup_Data } from "../General/Utility";

function Restoredatabase(props) {
  const _Common = useSelector((state) => state.Common.Admin);
  const [FileName, setFileName] = useState("");
  const [File, setFile] = useState();
  const [strDBName, setstrDBName] = useState("");
  const [strProcessMsg, setstrProcessMsg] = useState("");
  const [isBtnDisble, setisBtnDisble] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    Load_Dbnamelist();
  }, []);
  const Load_Dbnamelist = async () => {
    try {
      const res = await API_FETCH(`Admin/View_DBNameStartWith`, dispatch);
      if (res) {
        setstrDBName(res.EntDBName[0].dbName);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Restore_Database = async () => {
    try {
      if (FileName === "")
        Show_Message(dispatch, "Please Select Backup File", "info");
      else if (!FileName.startsWith(strDBName))
        Show_Message(dispatch, "Please Select Valid File", "info");
      else {
        const res = await API_FETCH(
          `Admin/Check_dbExists/${FileName}/${Number(_Common.Client)}`,
          dispatch
        );
        if (res) {
          await setstrProcessMsg(
            "Process take Some time Don't Close the Window . Restore Data Processing ....."
          );
          await setisBtnDisble(true);
          await progress(0);
          const BackupData = await Backup_Data(
            dispatch,
            _Common.Client,
            _Common.OrgName
          );
          //#region  Backup Before Restore
          if (BackupData) {
            await progress(1);
            const formdata = new FormData();
            formdata.append("FormFile", File);
            formdata.append("FileName", FileName);
            await progress(2);
            const res = await API_POST(
              `Admin/Restore_Database`,
              formdata,
              dispatch
            );
            if (res) {
              await Show_Message(dispatch, res.strMessage, "success");
              await props.Close();
              await BackupData.click();
              await setisBtnDisble(false);
            }
          }
          //#endregion
        }
      }
    } catch (e) {
      await console.error(e);
      await Show_Message(dispatch, e.message, "error");
    }
  };
  const progress = (tabindex) => {
    let linecomp = document.getElementById("percentcomp");
    let linepen = document.getElementById("percentpen");
    var stepsparent = $(".steps");
    linepen.style.width = stepsparent.width();
    var steps = $(".steps .step");
    linecomp.style.width = steps[tabindex].offsetLeft + "px";
    $(`.steps .step:eq(${tabindex})`).addClass("completed");
  };
  const Activatebtn = () => {
    const defaultBtn = document.getElementById("uploadsql");
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name.replace(".sql", ""));
    });
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Restore Organization</h5>
          <div className="icon-popup-btn-grp" hidden={isBtnDisble}>
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-2">
          <div className="row">
            <ul className="file_added">
              <li>File Added:</li>
              <li>
                {FileName !== "" && (
                  <a href="##">
                    {FileName !== "" && (
                      <i className="fa-solid fa-paperclip"></i>
                    )}
                    <span>{FileName}</span>
                    <i
                      className="fa-solid fa-xmark"
                      hidden={isBtnDisble}
                      onClick={(e) => {
                        setFileName("");
                        setFile();
                        var file = document.getElementById("uploadsql");
                        file.value = "";
                      }}
                    ></i>
                  </a>
                )}
              </li>
            </ul>
            <div className="resprogress">
              <div className="steps">
                <div className="step">
                  <span>
                    <i className="fa-regular fa-circle-check"></i>
                  </span>
                  Verification
                </div>
                <div className="step">
                  <span>
                    <i className="fa-solid fa-cloud-arrow-down"></i>
                  </span>
                  Backup
                </div>
                <div className="step">
                  <span>
                    <i className="fa-solid fa-trash"></i>
                  </span>
                  Delete
                </div>
                <div className="step">
                  <span>
                    <i className="fa-solid fa-cloud-arrow-up"></i>
                  </span>
                  Restore
                </div>
              </div>
              <div id="percentpen"></div>
              <div id="percentcomp"></div>
            </div>
            <p className="restorcon">{strProcessMsg}</p>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="filebtn"
              onClick={(e) => Activatebtn()}
              disabled={isBtnDisble}
            >
              <div className="custom-file">
                <input type="file" id="uploadsql" hidden accept=".sql" />
                <i className="fa-solid fa-cloud"></i>Choose file
              </div>
            </button>
            <button
              disabled={isBtnDisble}
              className="submitbtn"
              onClick={(e) =>
                Ask_Question(
                  dispatch,
                  Restore_Database,
                  "Do You Want to Restore Data?"
                )
              }
            >
              <i className="fa-solid fa-arrows-rotate"></i>Restore
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Restoredatabase;


