import jsPDF from "jspdf";
import NumbertoWord from "./NumbertoWord";
import { Buffer } from "buffer";
import { View_ScreenName } from "./Utility";
var options = { orientation: "p", unit: "pt", format: [0, 0] };

var doc = new jsPDF(options);
const PrintInvoicDesign = (PrintValue, iScrId, PrintFor, PrintShare) => {
  try {
    const ScrName = View_ScreenName(iScrId);
    let C = 1;
    if (PrintShare) {
      if (PrintValue.PinterType !== 3) {
        options.format = [827, 1100];
        doc = new jsPDF(options);
      }
      return CreateInvocie(
        PrintValue,
        C,
        iScrId,
        ScrName,
        PrintFor,
        PrintShare
      );
    } else {
      while (C <= PrintValue.NoofCopies) {
        if (PrintValue.PinterType !== 3) {
          options.format = [827, 1100];
          doc = new jsPDF(options);
        }
        CreateInvocie(PrintValue, C, iScrId, ScrName, PrintFor, PrintShare);
        C++;
        doc = new jsPDF(options); // Clear Page After Print First Copy
      }
    }
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};
export default PrintInvoicDesign;

const VoucherName = (VoucherInfo, ScrName) => {
  var InvoiceNo = "";
  switch (ScrName) {
    case "Sales Challan":
      InvoiceNo = VoucherInfo.salDCNo;
      break;
    case "Sales Enquiry":
      InvoiceNo = VoucherInfo.salEnqNo;
      break;
    case "Sales Estimate":
      InvoiceNo = VoucherInfo.eSNo;
      break;
    case "Sales Invoice":
      InvoiceNo = VoucherInfo.salInvNo;
      break;
    case "Sales Order":
      InvoiceNo = VoucherInfo.salOrdNo;
      break;
    case "Sales Quotation":
      InvoiceNo = VoucherInfo.salQuoNo;
      break;
    case "Sales Return":
      InvoiceNo = VoucherInfo.salRetNo;
      break;
    case "Purchase Challan":
      InvoiceNo = VoucherInfo.purDCNo;
      break;
    case "Purchase Enquiry":
      InvoiceNo = VoucherInfo.purEnqNo;
      break;
    case "Purchase Estimate":
      InvoiceNo = VoucherInfo.purESNo;
      break;
    case "Purchase Invoice":
      InvoiceNo = VoucherInfo.purInvNo;
      break;
    case "Purchase Order":
      InvoiceNo = VoucherInfo.purOrdNo;
      break;
    case "Purchase Quotation":
      InvoiceNo = VoucherInfo.purQuoNo;
      break;
    case "Purchase Return":
      InvoiceNo = VoucherInfo.purRetNo;
      break;
    case "Outsource Dc":
      InvoiceNo = VoucherInfo.outSDCNo;
      break;
    case "Insource Dc":
      InvoiceNo = VoucherInfo.inSDCNo;
      break;
    case "Insource Invoice":
      InvoiceNo = VoucherInfo.inSInvNo;
      break;
    case "Inward Dc":
      InvoiceNo = VoucherInfo.inwDCNo;
      break;
    case "Outward Dc":
      InvoiceNo = VoucherInfo.outWDCNo;
      break;
    case "Outward Invoice":
      InvoiceNo = VoucherInfo.outWInvNo;
      break;
    default:
      break;
  }
  return InvoiceNo;
};
const AssignPrintData = (JsonString, dFormat) => {
  let JsonArray = JsonString === undefined ? [] : JSON.parse(JsonString);

  JsonArray = JsonArray.map((DT) => {
    let ColumName = Object.keys(DT);
    for (let i = 0; i < ColumName.length; i++) {
      if (ColumName[i].includes("Qty")) {
        DT[ColumName[i]] = parseFloat(DT[ColumName[i]]).toFixed(
          dFormat.strQtyFormat
        );
      }
      if (ColumName[i].includes("Per")) {
        DT[ColumName[i]] = parseFloat(DT[ColumName[i]]).toFixed(
          dFormat.strPerFormat
        );
      }
      if (ColumName[i].includes("Amt") || ColumName[i].includes("Rate")) {
        DT[ColumName[i]] = parseFloat(DT[ColumName[i]]).toFixed(
          dFormat.strAmtFormat
        );
      }
      if (ColumName[i].includes("GoodsValue")) {
        DT[ColumName[i]] = parseFloat(DT[ColumName[i]]).toFixed(
          dFormat.strAmtFormat
        );
      }
    }
    return DT;
  });
  return JsonArray;
};
const PaperType = [
  { PrintFormate: "letter", PaperNameId: 1, PaperName: "Letter" },
  { PrintFormate: "legal", PaperNameId: 2, PaperName: "Legal" },
  { PrintFormate: "a4", PaperNameId: 3, PaperName: "Executive" },
  { PrintFormate: "a4", PaperNameId: 4, PaperName: "A4" },
  { PrintFormate: "a5", PaperNameId: 5, PaperName: "A5" },
  { PrintFormate: "a4", PaperNameId: 6, PaperName: "Envelope #10" },
  { PrintFormate: "a4", PaperNameId: 7, PaperName: "Envelope DL" },
  { PrintFormate: "c5", PaperNameId: 8, PaperName: "Envelope C5" },
  { PrintFormate: "b5", PaperNameId: 9, PaperName: "Envelope B5" },
  { PrintFormate: "a4", PaperNameId: 10, PaperName: "Envelope Monarch" },
  { PrintFormate: "b5", PaperNameId: 11, PaperName: "B5 (JIS)" },
  { PrintFormate: "b5", PaperNameId: 12, PaperName: "B5 (ISO)" },
  { PrintFormate: "a6", PaperNameId: 13, PaperName: "A6" },
  {
    PrintFormate: "credit-card",
    PaperNameId: 14,
    PaperName: "Double Post Card",
  },
  { PrintFormate: "credit-card", PaperNameId: 15, PaperName: "Post Card" },
  { PrintFormate: "", PaperNameId: 16, PaperName: "8.5x13" },
  { PrintFormate: "", PaperNameId: 17, PaperName: "16K " },
];
const CreateInvocie = (
  PrintValue,
  _iCurrentCopy,
  _iScrId,
  ScrName,
  PrintFor,
  PrintShare
) => {
  //#region Print Template
  let _objLineTempFirst = PrintValue.objLineTemplate.objFirst;
  let _objLineTempFirstAlter = PrintValue.objLineTemplate.objFirstAlter;
  let _objLineTempLast = PrintValue.objLineTemplate.objLast;
  let _objLineTempMiddle = PrintValue.objLineTemplate.objMiddle;
  //#endregion
  //#region Print Area Design
  let _objGeneralArea =
    PrintValue.objGeneralArea === null ? [] : PrintValue.objGeneralArea;
  let _objCompanyArea =
    PrintValue.objCompanyArea === null ? [] : PrintValue.objCompanyArea;
  let _objPartyArea =
    PrintValue.objPartyArea === null ? [] : PrintValue.objPartyArea;
  let _objVoucherArea =
    PrintValue.objVoucherArea === null ? [] : PrintValue.objVoucherArea;
  let _objRefVouchersArea =
    PrintValue.objRefVouchersArea === null ? [] : PrintValue.objRefVouchersArea;
  let _objDeliveryArea =
    PrintValue.objDeliveryArea === null ? [] : PrintValue.objDeliveryArea;
  let _objTransportArea =
    PrintValue.objTransportArea === null ? [] : PrintValue.objTransportArea;
  let _objValidityArea =
    PrintValue.objValidityArea === null ? [] : PrintValue.objValidityArea;
  let _objRemarksArea =
    PrintValue.objRemarksArea === null ? [] : PrintValue.objRemarksArea;
  let _objItemsArea =
    PrintValue.objItemsArea === null ? [] : PrintValue.objItemsArea;
  let _objAddLessArea =
    PrintValue.objAddLessArea === null ? [] : PrintValue.objAddLessArea;
  let _objDiscountArea =
    PrintValue.objDiscountArea === null ? [] : PrintValue.objDiscountArea;
  let _objTaxArea = PrintValue.objTaxArea === null ? [] : PrintValue.objTaxArea;
  let _objSummaryArea =
    PrintValue.objSummaryArea === null ? [] : PrintValue.objSummaryArea;
  //#endregion
  //#region  Print Data
  const DecimalFormate = {
    strAmtFormat: parseInt(PrintValue.strAmtFormat),
    strPerFormat: parseInt(PrintValue.strPerFormat),
    strQtyFormat: parseInt(PrintValue.strQtyFormat),
  };
  let _dtCompany = AssignPrintData(PrintValue.jsonCompany, DecimalFormate);
  let _dtParty = AssignPrintData(PrintValue.jsonParty, DecimalFormate);
  let _dtVoucher = AssignPrintData(PrintValue.jsonVoucher, DecimalFormate);
  let _dtRefVouchers = AssignPrintData(
    PrintValue.jsonRefVoucher,
    DecimalFormate
  );
  let _dtDelivery = AssignPrintData(PrintValue.jsonDelivery, DecimalFormate);
  let _dtTransport = AssignPrintData(PrintValue.jsonTransport, DecimalFormate);
  let _dtValidity = AssignPrintData(PrintValue.jsonValidity, DecimalFormate);
  let _dtRemarks = AssignPrintData(PrintValue.jsonRemarks, DecimalFormate);
  let _dtItems = AssignPrintData(PrintValue.jsonOriginalItem, DecimalFormate);
  let _dtTax = AssignPrintData(PrintValue.jsonTax, DecimalFormate);
  let _dtDiscount = AssignPrintData(PrintValue.jsonDiscount, DecimalFormate);
  let _dtSummary = AssignPrintData(PrintValue.jsonSummary, DecimalFormate);
  let _dtAddLess = AssignPrintData(PrintValue.jsonAddLess, DecimalFormate);
  let _dtJobs = AssignPrintData(PrintValue.jsonJobs, DecimalFormate);
  //#endregion

  let dTotalHeight = 0;
  let dTotalHeightJobs = 0;
  let dTotalLineJobs = 0;
  let dTotalLine = 0;
  let _dGapPerLine = 0;
  let iPrintedItemSNo = [];
  let iPrintedAddLessSNo = [];
  let iSubTotalItemsSNo = [];
  if (PrintValue.PinterType === 3) {
    //#region Find GapPerLine
    let dDescriptionBeginHeight = _objLineTempFirst.filter(
      (LT) => LT.lineName === "DescriptionBegin"
    )[0].y1Pos;
    if (_objItemsArea.filter((DT) => DT.fieldName !== "Label").length > 0) {
      let EntDescriptionPrintStart = _objItemsArea.filter(
        (DT) => DT.fieldName !== "Label"
      );
      // There's no real number bigger than plus Infinity
      let MinLineAt = Number.POSITIVE_INFINITY;
      let MaxLineAt = Number.NEGATIVE_INFINITY;
      let tmpLineAt;
      for (let i = EntDescriptionPrintStart.length - 1; i >= 0; i--) {
        tmpLineAt = EntDescriptionPrintStart[i].lineAt;
        if (tmpLineAt < MinLineAt) MinLineAt = tmpLineAt;
        if (tmpLineAt > MaxLineAt) MaxLineAt = tmpLineAt;
      }
      _dGapPerLine = MinLineAt - dDescriptionBeginHeight;
    }
    //#endregion

    //#region Calculate Total Desc Area Height
    let TotalHeightWidth = termalPrinterDesc(
      _objItemsArea,
      _dtItems,
      _objAddLessArea,
      _dtAddLess
    );
    dTotalHeight = TotalHeightWidth.dTotalHeight;
    dTotalLine = TotalHeightWidth.dTotalLine;

    //Calculate gap height between line's and add it into TotalHeight
    dTotalHeight += (dTotalLine - 1) * _dGapPerLine;
    //#endregion

    //#region Update LineDescriptionEnd Depends on Items Height
    _objLineTempFirst.forEach((entLineTempFirst) => {
      if (entLineTempFirst.y1Pos > dDescriptionBeginHeight) {
        entLineTempFirst.y1Pos = entLineTempFirst.y1Pos + dTotalHeight;
        entLineTempFirst.y2Pos = entLineTempFirst.y2Pos + dTotalHeight;
      }
    });
    let objGeneralAreas = _objGeneralArea.filter(
      (DT) =>
        (DT.fieldName === "Label" && DT.prefix === "SubTotal") ||
        DT.suffix === "Only"
    );
    if (objGeneralAreas.length > 0) {
      objGeneralAreas.forEach((entGeneralArea) => {
        entGeneralArea.lineAt = entGeneralArea.lineAt + dTotalHeight;
      });
    }
    _objSummaryArea.forEach((entSummaryArea) => {
      entSummaryArea.lineAt = entSummaryArea.lineAt + dTotalHeight;
    });
    //#endregion

    let dDescriptionEndHeight = _objLineTempFirst.filter(
      (F) => F.lineName === "DescriptionEnd"
    )[0].y1Pos;
    let dend = _objSummaryArea.filter((S) => S.labelName === "***")[0].lineAt;

    let iHeight = parseFloat(
      dDescriptionBeginHeight +
        dTotalHeight +
        (dend - dDescriptionEndHeight) +
        30
    );
    let DescriptionLine = {
      First: _objLineTempFirst,
      Alter: [],
      Middle: [],
      Last: [],
      fAddLessHeaderHeight: 0,
      dFirstDescriptionBegin: fnLineTempDescBegin(_objLineTempFirst),
      dFirstAlterDescriptionBegin: [],
      dMiddleDescriptionBegin: [],
      dLastDescriptionBegin: [],
      dFirstDescriptionEnd: fnLineTempDescEnd(_objLineTempFirst),
      dFirstAlterDescriptionEnd: [],
      dLastDescriptionEnd: [],
      dMiddleDescriptionEnd: [],
      fCurrentPageDescBegin: 0,
      GapPerLine: _dGapPerLine,
      iTotalPages: 1,
      iCurrPage: 1,
      iCurrentCopy: _iCurrentCopy,
      dCurrentPageDescLimit: 0,
    };
    options.format = [286, iHeight];
    doc = new jsPDF(options);

    //#region Initialize fCurrentPageDescBegin,dCurrentPageDescLimit
    let PageDescBeginEnd =
      fnGetThermalCurrentPageDescLimitation(DescriptionLine);
    DescriptionLine.fCurrentPageDescBegin = PageDescBeginEnd.DescriptionBegin;
    DescriptionLine.dCurrentPageDescLimit = PageDescBeginEnd.DescriptionEnd;
    //#endregion

    //#region Draw Line
    fnDrawLine(_objLineTempFirst);
    //#endregion

    //#region Header Part
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((AR) => AR.fieldName === "Company").length > 0
    )
      fnDrawtermalPageData(
        _objCompanyArea,
        _dtCompany,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Party").length > 0
    )
      fnDrawtermalPageData(
        _objPartyArea,
        _dtParty,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Voucher").length > 0
    )
      fnDrawtermalPageData(
        _objVoucherArea,
        _dtVoucher,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "RefVouchers").length > 0
    )
      fnDrawtermalPageData(
        _objRefVouchersArea,
        _dtRefVouchers,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Delivery").length > 0
    )
      fnDrawtermalPageData(
        _objDeliveryArea,
        _dtDelivery,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Transport").length > 0
    )
      fnDrawtermalPageData(
        _objTransportArea,
        _dtTransport,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Validity").length > 0
    )
      fnDrawtermalPageData(
        _objValidityArea,
        _dtValidity,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    if (
      _iCurrentCopy === 1 ||
      _objGeneralArea.filter((DT) => DT.fieldName === "Remarks").length > 0
    )
      fnDrawtermalPageData(
        _objRemarksArea,
        _dtRemarks,
        DescriptionLine,
        PrintValue.objEntLogo
      );
    //#endregion

    //#region Footer Part
    fnDrawtermalPageData(
      _objDiscountArea,
      _dtDiscount,
      DescriptionLine,
      PrintValue.objEntLogo
    );
    fnDrawTaxData(_objTaxArea, _dtTax, DescriptionLine);
    fnDrawtermalPageData(
      _objSummaryArea,
      _dtSummary,
      DescriptionLine,
      PrintValue.objEntLogo
    );
    //#endregion

    //#region Item Part
    let objItemHeader = [];
    if (_iCurrentCopy === 1) {
      let HeaderContent = fnDrawItemsHeader(
        _objItemsArea,
        _dtItems,
        true,
        objItemHeader,
        DescriptionLine
      );
      DescriptionLine = HeaderContent.DescriptionLine;
      objItemHeader = HeaderContent.objItemHeader;
    } else {
      fnDrawItemsAutoHeader(_objItemsArea, _dtItems);
    }
    let PrintedItem = fnDrawItemsData(
      _objItemsArea,
      _dtItems,
      true,
      _dtJobs,
      _objAddLessArea,
      DescriptionLine,
      iPrintedItemSNo,
      iPrintedAddLessSNo,
      iSubTotalItemsSNo,
      PrintValue.objEntLogo
    );
    iPrintedItemSNo = PrintedItem.iPrintedItemSNo;
    iPrintedAddLessSNo = PrintedItem.iPrintedAddLessSNo;
    iSubTotalItemsSNo = PrintedItem.iSubTotalItemsSNo;
    //#endregion

    //#region AddLess Part
    if (_objAddLessArea.length > 0 && _dtAddLess.length > 0) {
      fnDrawItemsHeader(_objAddLessArea, _dtAddLess, false, DescriptionLine);
      PrintedItem = fnDrawItemsData(
        _objAddLessArea,
        _dtAddLess,
        false,
        DescriptionLine,
        iPrintedItemSNo,
        iPrintedAddLessSNo,
        iSubTotalItemsSNo,
        PrintValue.objEntLogo
      );
      iPrintedItemSNo = PrintedItem.iPrintedItemSNo;
      iPrintedAddLessSNo = PrintedItem.iPrintedAddLessSNo;
      iSubTotalItemsSNo = PrintedItem.iSubTotalItemsSNo;
    }
    //#endregion

    //#region General
    fnDrawTermalGeneralData(
      _objGeneralArea,
      _dtItems,
      iSubTotalItemsSNo,
      _dtSummary,
      DescriptionLine,
      PrintValue.objEntLogo,
      DecimalFormate
    );
    //#endregion
  }
  if (PrintValue.PinterType !== 3) {
    //#region Calculate Total Number of page

    //#region Find GapPerLine
    let _dDescriptionBegin = _objLineTempFirst.filter(
      (LT) => LT.lineName === "DescriptionBegin"
    )[0].y1Pos;
    if (_objItemsArea.filter((DT) => DT.fieldName !== "Label").length > 0) {
      let EntDescriptionPrintStart = _objItemsArea.filter(
        (DT) => DT.fieldName !== "Label"
      );
      // There's no real number bigger than plus Infinity
      let MinLineAt = Number.POSITIVE_INFINITY;
      let MaxLineAt = Number.NEGATIVE_INFINITY;
      let tmpLineAt;
      for (let i = EntDescriptionPrintStart.length - 1; i >= 0; i--) {
        tmpLineAt = EntDescriptionPrintStart[i].lineAt;
        if (tmpLineAt < MinLineAt) MinLineAt = tmpLineAt;
        if (tmpLineAt > MaxLineAt) MaxLineAt = tmpLineAt;
      }
      _dGapPerLine = MinLineAt - _dDescriptionBegin;
    }
    //#endregion

    //Calculate gap height between line's and add it into TotalHeight
    if (_iScrId === 73) {
      let TotalJobHeightWidth = fnGetTotalHeightAndLineJobs(
        _objItemsArea,
        _dtJobs
      );
      dTotalHeightJobs = TotalJobHeightWidth.dTotalHeightJobs;
      dTotalLineJobs = TotalJobHeightWidth.dTotalLineJobs;
      let TotalHeightWidth = fnGetTableHeight(_objItemsArea, _dtItems, _dtJobs);
      dTotalHeight = TotalHeightWidth.dTotalHeight;
      dTotalLine = TotalHeightWidth.dTotalLine;
    } else {
      let TotalHeightWidth = fnGetTableHeight(_objItemsArea, _dtItems, _dtJobs);
      dTotalHeight = TotalHeightWidth.dTotalHeight;
      dTotalLine = TotalHeightWidth.dTotalLine;
    }
    if (_objAddLessArea.length > 0 && _dtAddLess.length > 0) {
      let TotalHeightWidth = fnGetTableHeight(
        _objAddLessArea,
        _dtAddLess,
        _dtJobs
      );
      dTotalHeight = TotalHeightWidth.dTotalHeight;
      dTotalLine = TotalHeightWidth.dTotalLine;
    }

    //Calculate gap height between line's and add it into TotalHeight
    dTotalHeight += (dTotalLine - 1) * _dGapPerLine;
    dTotalHeightJobs += (dTotalLineJobs - 1) * _dGapPerLine;
    dTotalHeight = dTotalHeight + dTotalHeightJobs;
    let DescriptionLine = {
      First: _objLineTempFirst,
      Alter: _objLineTempFirstAlter,
      Middle: _objLineTempMiddle,
      Last: _objLineTempLast,
      fAddLessHeaderHeight: 0,
      dFirstDescriptionBegin: fnLineTempDescBegin(_objLineTempFirst),
      dFirstAlterDescriptionBegin: fnLineTempDescBegin(_objLineTempFirstAlter),
      dMiddleDescriptionBegin: fnLineTempDescBegin(_objLineTempMiddle),
      dLastDescriptionBegin: fnLineTempDescBegin(_objLineTempLast),
      dFirstDescriptionEnd: fnLineTempDescEnd(_objLineTempFirst),
      dFirstAlterDescriptionEnd: fnLineTempDescEnd(_objLineTempFirstAlter),
      dLastDescriptionEnd: fnLineTempDescEnd(_objLineTempLast),
      dMiddleDescriptionEnd: fnLineTempDescEnd(_objLineTempMiddle),
      fCurrentPageDescBegin: 0,
      GapPerLine: _dGapPerLine,
      iTotalPages: 1,
      iCurrPage: 1,
      iCurrentCopy: _iCurrentCopy,
      dCurrentPageDescLimit: 0,
    };
    //Calculate Page Cound
    DescriptionLine.iTotalPages = fnPageCount(dTotalHeight, DescriptionLine);
    //#endregion
    let objItemHeader = [];
    while (DescriptionLine.iCurrPage <= DescriptionLine.iTotalPages) {
      //#region Draw Line
      if (
        DescriptionLine.iCurrPage === 1 &&
        DescriptionLine.iTotalPages === 1
      ) {
        fnDrawLine(_objLineTempFirst);
      } else if (
        DescriptionLine.iCurrPage === 1 &&
        DescriptionLine.iTotalPages !== 1
      ) {
        fnDrawLine(_objLineTempFirstAlter);
      } else if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages) {
        doc.addPage(options);
        fnDrawLine(_objLineTempLast);
      } else {
        doc.addPage(options);
        fnDrawLine(_objLineTempMiddle);
      }
      //#endregion
      // PageDesc Limitation
      //#region Initialize fCurrentPageDescBegin,dCurrentPageDescLimit
      let PageDescBeginEnd = fnGetCurrentPageDescLimitation(DescriptionLine);
      DescriptionLine.fCurrentPageDescBegin = PageDescBeginEnd.DescriptionBegin;
      DescriptionLine.dCurrentPageDescLimit = PageDescBeginEnd.DescriptionEnd;
      //#endregion

      // #region Header Part
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((AR) => AR.fieldName === "Company").length > 0
      )
        fnDrawData(
          _objCompanyArea,
          _dtCompany,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      else {
        if (
          _objCompanyArea.filter(
            (DT) =>
              DT.suffix.includes("OnlyMiddle") || DT.suffix.includes("OnlyLast")
          ).length > 0
        )
          fnDrawData(
            _objCompanyArea.filter(
              (DT) =>
                DT.suffix.includes("OnlyMiddle") ||
                DT.suffix.includes("OnlyLast")
            ),
            _dtCompany,
            DescriptionLine,
            PrintValue.objEntLogo
          );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Party").length > 0
      )
        fnDrawData(
          _objPartyArea,
          _dtParty,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Voucher").length > 0
      ) {
        fnDrawData(
          _objVoucherArea,
          _dtVoucher,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "RefVouchers").length >
          0
      ) {
        fnDrawData(
          _objRefVouchersArea,
          _dtRefVouchers,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Delivery").length > 0
      ) {
        fnDrawData(
          _objDeliveryArea,
          _dtDelivery,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Transport").length > 0
      ) {
        fnDrawData(
          _objTransportArea,
          _dtTransport,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Validity").length > 0
      ) {
        fnDrawData(
          _objValidityArea,
          _dtValidity,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      if (
        DescriptionLine.iCurrPage === 1 ||
        _objGeneralArea.filter((DT) => DT.fieldName === "Remarks").length > 0
      ) {
        fnDrawData(
          _objRemarksArea,
          _dtRemarks,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      } else {
        if (
          _objRemarksArea.filter(
            (DT) =>
              DT.suffix.includes("OnlyMiddle") || DT.suffix.includes("OnlyLast")
          ).length > 0
        )
          fnDrawData(
            _objRemarksArea.filter(
              (DT) =>
                DT.suffix.includes("OnlyMiddle") ||
                DT.suffix.includes("OnlyLast")
            ),
            _dtRemarks,
            DescriptionLine,
            PrintValue.objEntLogo
          );
      }
      //#endregion

      //#region Footer Part
      if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages) {
        fnDrawData(
          _objDiscountArea,
          _dtDiscount,
          DescriptionLine,
          PrintValue.objEntLogo
        );
        fnDrawTaxData(_objTaxArea, _dtTax);
        fnDrawData(
          _objSummaryArea,
          _dtSummary,
          DescriptionLine,
          PrintValue.objEntLogo
        );
      }
      //#endregion

      //#region Item Part
      if (DescriptionLine.iCurrPage === 1) {
        let HeaderContent = fnDrawItemsHeader(
          _objItemsArea,
          _dtItems,
          true,
          objItemHeader,
          DescriptionLine
        );
        DescriptionLine = HeaderContent.DescriptionLine;
        objItemHeader = HeaderContent.objItemHeader;
      } else {
        fnDrawItemsAutoHeader(
          objItemHeader,
          _objItemsArea,
          _dtItems,
          DescriptionLine
        );
      }
      let PrintedItem = fnDrawItemsData(
        _objItemsArea,
        _dtItems,
        true,
        _dtJobs,
        _objAddLessArea,
        DescriptionLine,
        iPrintedItemSNo,
        iPrintedAddLessSNo,
        iSubTotalItemsSNo,
        PrintValue.objEntLogo
      );
      iPrintedItemSNo = PrintedItem.iPrintedItemSNo;
      iPrintedAddLessSNo = PrintedItem.iPrintedAddLessSNo;
      iSubTotalItemsSNo = PrintedItem.iSubTotalItemsSNo;
      //#endregion

      //#region AddLess Part
      if (
        DescriptionLine.iCurrPage === DescriptionLine.iTotalPages &&
        _dtAddLess.length > 0 &&
        _dtAddLess.length > 0
      ) {
        PrintedItem = fnDrawItemsData(
          _objAddLessArea,
          _dtAddLess,
          false,
          _dtJobs,
          DescriptionLine,
          iPrintedItemSNo,
          iPrintedAddLessSNo,
          iSubTotalItemsSNo,
          PrintValue.objEntLogo
        );
        if (PrintedItem.iPrintedItemSNo > 0) {
          iPrintedItemSNo = PrintedItem.iPrintedItemSNo;
          iPrintedAddLessSNo = PrintedItem.iPrintedAddLessSNo;
          iSubTotalItemsSNo = PrintedItem.iSubTotalItemsSNo;
        }
      }
      //#endregion

      //#region General
      fnDrawGeneralData(
        _objGeneralArea,
        _dtItems,
        iSubTotalItemsSNo,
        _dtSummary,
        DescriptionLine,
        PrintValue.objEntLogo,
        DecimalFormate
      );
      //#endregion

      DescriptionLine.iCurrPage++;
    }
  }
  var FileName = VoucherName(_dtVoucher[0], ScrName);
  if (PrintShare) {
    var string = doc.output("dataurlstring", FileName);
    const objPdfFile = {
      PdfURL: string,
      FileName: FileName.length === 0 ? "Invoice" : FileName,
      Summary: _dtSummary,
      CompanyInfo: _dtCompany,
      PartyInfo: _dtParty,
    };
    return objPdfFile;
  } else {
    if (PrintFor === "Print") {
      doc.output(
        "dataurlnewwindow",
        FileName.length === 0 ? "Invoice" : FileName
      );
    } else {
      doc.save(FileName.length === 0 ? "Invoice" : FileName + ".pdf");
    }
  }
};
const fnDrawString = (objEntString) => {
  let printContent =
    objEntString.printContent === undefined ? "" : objEntString.printContent;
  printContent = printContent.includes("&#xD;&#xA;")
    ? printContent.replace(/&#xD;&#xA;/g, "\r\n")
    : printContent;
  //Print String Data
  doc.setFontSize(objEntString.fontSize + 2);
  doc.setTextColor(objEntString.color);
  doc.setFont(objEntString.font, "", objEntString.fontStyle);
  doc.text(printContent, objEntString.startAt, objEntString.lineAt, {
    align: objEntString.alignment,
  });
};
const fnMeasureString = (pText, pFontSize, pStyle) => {
  var lDiv = document.createElement("p");
  lDiv.style.fontStyle = pStyle;
  lDiv.style.fontSize = "" + pFontSize + "px";
  lDiv.style.position = "absolute";
  lDiv.style.left = -1000;
  lDiv.style.top = -1000;
  lDiv.textContent = pText;
  document.body.appendChild(lDiv);

  var lResult = {
    dContentWidth: lDiv.clientWidth,
    dContentHeight: lDiv.clientHeight,
  };
  document.body.removeChild(lDiv);
  lDiv = "";
  return lResult;
};
const fnGetPrintAreaProperties = (PrintArea, strHelp) => {
  var FieldName = PrintArea.fieldName;
  FieldName = FieldName.replace(/^.{1}/g, FieldName[0].toLowerCase());
  const objPrintParm = {
    designId: PrintArea.designId,
    startAt: PrintArea.startAt,
    lineAt: PrintArea.lineAt,
    width: PrintArea.width,
    alignment: PrintArea.alignment,
    fontSize: PrintArea.fontSize,
    fontStyle: PrintArea.fontStyle,
    color: PrintArea.color,
    wordWrap: PrintArea.wordWrap,
    printContent: strHelp === "Label" ? PrintArea.labelName : "",
    fieldName: FieldName,
    font: PrintArea.font,
    dHeightDiff: 0,
    labelName: PrintArea.labelName,
    dMaxHeight: 0,
    dMaxLine: 0,
    dContentWidth: 0,
    dContentHeight: 0,
    strFileName: "",
    iPrintContentLength: 0,
    iWidthLimit: 0,
    iContentWidth: 0,
    iPrintContentValidLength: 0,
    iLineCount: 0,
    dPerElementHeight: 0,
    iPrintContentSplitByWidthCount: 0,
    iCount: 0,
    dDescriptionBegin: 0,
    dDescriptionEnd: 0,
  };
  return objPrintParm;
};
const fnDrawLine = (LineTemp) => {
  for (let i = 0; i < LineTemp.length; i++) {
    let X1 = LineTemp[i].x1Pos;
    let Y1 = LineTemp[i].y1Pos - 10;
    let X2 = LineTemp[i].x2Pos;
    let Y2 = LineTemp[i].y2Pos - 10;
    doc.setDrawColor(LineTemp[i].color);
    doc.line(X1, Y1, X2, Y2);
  }
};
const fnDrawData = (objEntArea, dtData, DescriptionLine, objEntLogo) => {
  try {
    let iTotalPages = DescriptionLine.iTotalPages;
    let iCurrentPages = DescriptionLine.iCurrPage;
    let _dGapPerLine = DescriptionLine.GapPerLine;
    if (objEntArea.length === 0) return;
    let objElements = [];
    let objLabels = [];
    if (iTotalPages === iCurrentPages) {
      objLabels = objEntArea.filter(
        (DT) =>
          DT.fieldName === "Label" &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyFirst"
      );
      objElements = objEntArea.filter(
        (DT) =>
          DT.fieldName !== "Label" &&
          !DT.fieldName.startsWith("LogoId") &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyFirst"
      );
    } else if (iCurrentPages === 1) {
      objLabels = objEntArea.filter(
        (DT) =>
          DT.fieldName === "Label" &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyFirst"
      );
      objElements = objEntArea.filter(
        (DT) =>
          DT.fieldName !== "Label" &&
          !DT.fieldName.startsWith("LogoId") &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyLast"
      );
    } else {
      objLabels = objEntArea.filter(
        (DT) =>
          DT.fieldName === "Label" &&
          DT.suffix !== "OnlyMiddle" &&
          DT.suffix !== "OnlyFirst"
      );
      objElements = objEntArea.filter(
        (DT) =>
          DT.fieldName !== "Label" &&
          !DT.fieldName.startsWith("LogoId") &&
          DT.suffix !== "OnlyFirst" &&
          DT.suffix !== "OnlyLast"
      );
    }
    objLabels.forEach((entLabel) => {
      let objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
      fnAlignment(objPrintParam);
      fnDrawString(objPrintParam);
    });
    //#region Draw drItem
    if (dtData.length === 0) return;
    dtData.forEach((drData) => {
      objElements.forEach((entElement) => {
        let objPrintParam = fnGetPrintAreaProperties(entElement);
        objPrintParam.printContent = drData[objPrintParam.fieldName].toString();
        objPrintParam = fnAlignment(objPrintParam);
        if (
          objPrintParam.wordWrap === "wrap" &&
          objPrintParam.printContent.length > 0
        ) {
          let TextWH = fnMeasureString(
            objPrintParam.printContent,
            objPrintParam.fontSize,
            objPrintParam.fontStyle
          );
          let dContentHeight = TextWH.dContentHeight;
          let dContentWidth = TextWH.dContentWidth;
          let dPerLineHeightWithGap = dContentHeight + _dGapPerLine;
          objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
          objPrintParam.iWidthLimit = parseInt(Math.floor(objPrintParam.width));
          objPrintParam.iContentWidth = parseInt(Math.floor(dContentWidth));
          objPrintParam.iPrintContentValidLength =
            (objPrintParam.iPrintContentLength * objPrintParam.iWidthLimit) /
            objPrintParam.iContentWidth;
          if (
            objPrintParam.iPrintContentLength <
            objPrintParam.iPrintContentValidLength
          )
            objPrintParam.iPrintContentValidLength =
              objPrintParam.iPrintContentLength;
          let printContentSplitByWidth = SpliText(
            objPrintParam.printContent,
            objPrintParam.iPrintContentValidLength
          );
          objPrintParam.iPrintContentSplitByWidthCount =
            printContentSplitByWidth.length;

          for (
            let iPText = 0;
            iPText < objPrintParam.iPrintContentSplitByWidthCount;
            iPText++
          ) {
            objPrintParam.printContent = printContentSplitByWidth[iPText];
            objPrintParam.lineAt =
              parseFloat(objPrintParam.lineAt) + iPText * dPerLineHeightWithGap;
            fnDrawString(objPrintParam);
          }
        } else {
          fnDrawString(objPrintParam);
        }
      });
    });

    //#endregion
    //#region Draw Logo
    var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
    EntLogo.forEach((entLogo) => {
      let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
      let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
      fnDrawLogo(entLogo, objentLogoData);
    });
    //#endregion
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};
const fnDrawLogo = (objEntLogo, objentLogoData) => {
  if (objentLogoData.length === 0) return;
  var UnitBitArray = new Uint8Array(objentLogoData[0].imgData);
  var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
  let strImage = "data:image/jpeg;base64," + ArrayToString;
  let strImageWidth = objentLogoData[0].dLogoWidth;
  let strImageHeight = objentLogoData[0].dLogoHeight;
  doc.addImage(
    strImage,
    "JPEG",
    objEntLogo.startAt,
    objEntLogo.lineAt,
    strImageWidth,
    strImageHeight,
    objentLogoData[0].strLogoName
  );
};
const fnPageCount = (dTotalHeight, DescriptionLine) => {
  let _dDescription, iTotalPages;
  let _dGapPerLine = DescriptionLine.GapPerLine;
  //#region Check one Page is Enough

  _dDescription =
    fnLineTempDescEnd(DescriptionLine.First) -
    fnLineTempDescBegin(DescriptionLine.First) -
    2 * _dGapPerLine;
  if (dTotalHeight <= _dDescription) {
    iTotalPages = 1;
    return iTotalPages;
  }
  //#endregion

  //#region Check two Page is Enough

  let dFirstAlterDescription =
    fnLineTempDescEnd(DescriptionLine.Alter) -
    fnLineTempDescBegin(DescriptionLine.Alter) -
    2 * _dGapPerLine;

  let dLastDescription =
    fnLineTempDescEnd(DescriptionLine.Last) -
    fnLineTempDescBegin(DescriptionLine.Last) -
    2 * _dGapPerLine;

  _dDescription = dFirstAlterDescription + dLastDescription;

  if (dTotalHeight <= _dDescription) {
    iTotalPages = 2;
    return iTotalPages;
  }
  //#endregion

  //#region Calculate exact page count

  let dMiddleDescription =
    fnLineTempDescEnd(DescriptionLine.Middle) -
    fnLineTempDescBegin(DescriptionLine.Middle) -
    2 * _dGapPerLine;

  for (let iCount = 1; iCount <= 50; iCount++) {
    _dDescription =
      dFirstAlterDescription + dMiddleDescription * iCount + dLastDescription;
    if (dTotalHeight <= _dDescription) {
      iTotalPages = 2 + iCount;
      return iTotalPages;
    }
  }

  //#endregion
};
const fnLineTempDescBegin = (EntTemplate) => {
  if (EntTemplate.length > 0) {
    return EntTemplate.filter((DT) => DT.lineName === "DescriptionBegin")[0]
      .y1Pos;
  } else {
    return [];
  }
};
const fnLineTempDescEnd = (EntTemplate) => {
  if (EntTemplate.length > 0) {
    return EntTemplate.filter((DT) => DT.lineName === "DescriptionEnd")[0]
      .y1Pos;
  } else {
    return [];
  }
};
const fnDrawTaxData = (objEntArea, dtData) => {
  let objPrintParam = "";
  if (objEntArea.length === 0) return;
  let objLabels = objEntArea.filter((DT) => DT.fieldName === "Label");
  let objTotalElements = objEntArea.filter(
    (DT) => DT.fieldName !== "Label" && DT.fieldName.includes("Total")
  );

  let objExceptTotalElements = objEntArea.filter(
    (DT) => DT.fieldName !== "Label" && !DT.fieldName.includes("Total")
  );
  objLabels.forEach((entLabel) => {
    objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    objPrintParam = fnAlignment(objPrintParam);
    fnDrawString(objPrintParam);
  });
  if (dtData.length === 0) return;
  dtData.forEach((drData) => {
    objTotalElements.forEach((entTotalElement) => {
      objPrintParam = fnGetPrintAreaProperties(entTotalElement);
      objPrintParam.printContent = "" + drData[objPrintParam.fieldName];
      objPrintParam = fnAlignment(objPrintParam);
      fnDrawString(objPrintParam);
    });
  });
  let fTaxLineGap = 20;
  let iCount = 0;
  dtData.forEach((drData) => {
    objExceptTotalElements.forEach((entExceptTotalElement) => {
      objPrintParam = fnGetPrintAreaProperties(entExceptTotalElement);
      objPrintParam.printContent = "" + drData[objPrintParam.fieldName];
      objPrintParam = fnAlignment(objPrintParam);
      objPrintParam.lineAt = objPrintParam.lineAt + iCount * fTaxLineGap;
      fnDrawString(objPrintParam);
    });
    iCount++;
  });
};
const fnGetCurrentPageDescLimitation = (DescriptionLine) => {
  let dDescriptionBegin = 0;
  let dDescriptionEnd = 0;
  if (DescriptionLine.iCurrPage === 1) {
    if (DescriptionLine.iTotalPages === 1) {
      dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.First);
      dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.First);
    } else {
      dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.Alter);
      dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.Alter);
    }
  } else if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages) {
    dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.Last);
    dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.Last);
  } else {
    dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.Middle);
    dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.Middle);
  }
  const objEntDesc = {
    DescriptionBegin: parseFloat(
      dDescriptionBegin + DescriptionLine.GapPerLine
    ),
    DescriptionEnd: dDescriptionEnd,
  };
  return objEntDesc;
};
const fnGetThermalCurrentPageDescLimitation = (DescriptionLine) => {
  let dDescriptionBegin = 0;
  let dDescriptionEnd = 0;
  dDescriptionBegin = fnLineTempDescBegin(DescriptionLine.First);
  dDescriptionEnd = fnLineTempDescEnd(DescriptionLine.First);
  const objEntDesc = {
    DescriptionBegin: parseFloat(
      dDescriptionBegin + DescriptionLine.GapPerLine
    ),
    DescriptionEnd: dDescriptionEnd,
  };
  return objEntDesc;
};
const fnGetTableHeight = (objItemsArea, _dtItems, _dtJobs) => {
  let dTotalHeight = 0;
  let dTotalLine = 0;
  let dMaxHeight = 0;
  let dMaxLine = 0;

  let EntItemsArea = objItemsArea.filter(
    (DT) => DT.fieldName !== "Label" && DT.fieldName !== "AddLessHeaderLabel"
  );
  let objItemAggregation = [];
  _dtItems.forEach((drItem, Index) => {
    EntItemsArea.forEach((ItemArea, ItemIndex) => {
      //#region Load dtItemAggregation
      objItemAggregation = fnAddItemAggregation(
        objItemAggregation,
        ItemArea,
        drItem
      );
    });
    //#endregion
    // There's no real number bigger than plus Infinity
    var MaxContentHeight = Number.NEGATIVE_INFINITY;
    var MaxLineCound = Number.NEGATIVE_INFINITY;
    for (var i = objItemAggregation.length - 1; i >= 0; i--) {
      MaxContentHeight = objItemAggregation[i].contentHeight;
      MaxLineCound = objItemAggregation[i].contentLineCount;
      if (dMaxHeight < MaxContentHeight) dMaxHeight = MaxContentHeight;
      if (dMaxLine < MaxLineCound) dMaxLine = MaxLineCound;
    }
    //#region Get MaxHeight & Line compare with Same StartAt with Diff LineAt Logics
    let dStartAtList = [];
    dStartAtList = [...new Set(objItemAggregation.map((item) => item.startAt))];
    dStartAtList.forEach((dStartAt) => {
      let iCount = 0;
      for (let i = 0; i < objItemAggregation.length; i++) {
        if (objItemAggregation[i].startAt === dStartAt) iCount++;
      }
      if (iCount > 1) {
        objItemAggregation = objItemAggregation.filter(
          (DT) => DT.startAt === dStartAt
        );
        let SumConHeight = objItemAggregation
          .map((item) => item.contentHeight)
          .reduce((prev, curr) => prev + curr, 0);

        let SumConLinCount = objItemAggregation
          .map((item) => item.contentLineCount)
          .reduce((prev, curr) => prev + curr, 0);

        dMaxHeight = Math.max(dMaxHeight, SumConHeight);
        dMaxLine = Math.max(dMaxLine, SumConLinCount);
      }
    });
    //#endregion

    dTotalHeight += dMaxHeight;
    dTotalLine += dMaxLine;
    objItemAggregation = [];
  });
  let EntItemsAreaHeader = objItemsArea.filter(
    (DT) => DT.fieldName === "AddLessHeaderLabel"
  );
  if (EntItemsAreaHeader.length > 0) {
    var ItemArea = EntItemsAreaHeader[0];
    let objPrintParm = fnGetPrintAreaProperties(ItemArea);
    if (objPrintParm.printContent === "" || objPrintParm.printContent === null)
      return;
    dTotalHeight += dTotalHeight;
    dTotalLine += 1;
  }
  const objENtTHL = {
    dTotalHeight: dTotalHeight,
    dTotalLine: dTotalLine,
  };
  return objENtTHL;
};
const fnGetTotalHeightAndLineJobs = (objItemsArea, dtJobs) => {
  if (objItemsArea.length === 0 || dtJobs.length === 0) {
    const objENtJTHL = {
      dTotalHeightJobs: 0,
      dTotalLineJobs: 0,
    };
    return objENtJTHL;
  }
  var dMaxHeight = 0;
  let dMaxLine = 0;
  let dTotalHeightJobs = 0;
  let dTotalLineJobs = 0;

  let EntItemsArea = objItemsArea.filter(
    (DT) => DT.fieldName !== "Label" && DT.fieldName !== "AddLessHeaderLabel"
  );
  let objItemAggregation = [];
  //#region MultiJobPrintLogic
  dtJobs.forEach((drJobs) => {
    EntItemsArea.forEach((ItemArea) => {
      //#region Load dtItemAggregation
      objItemAggregation = fnAddItemAggregation(
        objItemAggregation,
        ItemArea,
        drJobs
      );
      //#endregion
    });
    dMaxHeight = Math.max.apply(
      Math,
      objItemAggregation.map(function (H) {
        return H.contentHeight;
      })
    );
    dMaxLine = Math.max.apply(
      Math,
      objItemAggregation.map(function (H) {
        return H.contentLineCount;
      })
    );

    //#region Get MaxHeight & Line compare with Same StartAt with Diff LineAt Logics

    let dStartAtList = [];
    objItemAggregation.map((item) => {
      var findItem = dStartAtList.find((x) => x.startAt === item.startAt);
      if (!findItem) dStartAtList.push(item);
      return item;
    });
    dStartAtList.forEach((dStartAt) => {
      let iCount = objItemAggregation.filter(
        (DT) => DT.startAt === dStartAt
      ).length;
      if (iCount > 1) {
        objItemAggregation = objItemAggregation.filter(
          (DT) => DT.startAt === dStartAt
        );
        dMaxHeight = Math.max.apply(
          Math,
          objItemAggregation.map(function (H) {
            return H.contentHeight;
          })
        );
        dMaxLine = Math.max.apply(
          Math,
          objItemAggregation.map(function (H) {
            return H.contentLineCount;
          })
        );
      }
    });
    //#endregion

    dTotalHeightJobs += dMaxHeight;
    dTotalLineJobs += dMaxLine;
    objItemAggregation = [];
  });
  const objENtJTHL = {
    dTotalHeightJobs: dTotalHeightJobs,
    dTotalLineJobs: dTotalLineJobs,
  };
  return objENtJTHL;
  //#endregion
};
const fnAddEachItemAggregation = (objItemAggregation, objEntParam) => {
  const entItemAggregation = {
    designId: objEntParam.designId,
    lineAt: objEntParam.lineAt,
    startAt: objEntParam.startAt,
    width: objEntParam.width,
    fieldName: objEntParam.fieldName,
    contentWidth: objEntParam.dContentWidth,
    contentLineCount: 1,
    contentHeight: objEntParam.dContentHeight,
  };
  objItemAggregation.push(entItemAggregation);
  return objItemAggregation;
};
const fnAddItemAggregation = (objItemAggregation, ItemArea, drItem) => {
  let dContentHeight = 0;
  let dContentWidth = 0;
  let iDesignId = ItemArea.designId;
  let dLineAt = ItemArea.lineAt;
  let dStartAt = ItemArea.startAt;
  let width = ItemArea.width;
  let strFieldName = ItemArea.fieldName;
  let strWordWrap = ItemArea.wordWrap;
  let fontSize = ItemArea.fontSize;
  let fontStyle = ItemArea.fontStyle;

  const entItemAggregation = {
    designId: 0,
    lineAt: 0,
    startAt: 0,
    width: 0,
    fieldName: 0,
    contentWidth: 0,
    contentLineCount: 0,
  };
  let TextWH = "";
  var FieldName = strFieldName;
  FieldName = FieldName.replace(/^.{1}/g, FieldName[0].toLowerCase());
  let printContent = "" + drItem[FieldName] + "";
  if (printContent.includes("\n")) {
    let i = 0;
    let PrintContent = printContent.split("\r\n");
    let icount = PrintContent.length;
    PrintContent.forEach((print) => {
      while (i < icount) {
        dLineAt = i === 0 ? dLineAt : dLineAt + 20;
        printContent = print;
        if (strWordWrap === "wrap") {
          TextWH = fnMeasureString(printContent, fontSize, parseInt(width));
          dContentHeight = TextWH.dContentHeight;
          dContentWidth = TextWH.dContentWidth;
        } else {
          TextWH = fnMeasureString(printContent, fontSize);
          dContentHeight = TextWH.dContentHeight;
          dContentWidth = TextWH.dContentWidth;
        }
        entItemAggregation.designId = iDesignId;
        entItemAggregation.lineAt = dLineAt + 10;
        entItemAggregation.startAt = dStartAt;
        entItemAggregation.width = width;
        entItemAggregation.fieldName = strFieldName;
        entItemAggregation.contentWidth = dContentWidth;
        if (strWordWrap === "wrap")
          entItemAggregation.contentLineCount =
            width === 0 ? 0 : parseInt(Math.ceil(dContentWidth / width));
        else entItemAggregation.contentLineCount = width === 0 ? 0 : 1;

        entItemAggregation.contentHeight =
          dContentHeight * entItemAggregation.contentLineCount;
        if (entItemAggregation.contentHeight0)
          entItemAggregation.contentLineCount = 0;
        objItemAggregation.push(entItemAggregation);
        break;
      }
      i++;
    });
  } else {
    if (strWordWrap === "wrap") {
      TextWH = fnMeasureString(printContent, fontSize, fontStyle);
      dContentHeight = TextWH.dContentHeight;
      dContentWidth = TextWH.dContentWidth;
    } else {
      TextWH = fnMeasureString(printContent, fontSize, fontStyle);
      dContentHeight = TextWH.dContentHeight;
      dContentWidth = TextWH.dContentWidth;
    }
    entItemAggregation.designId = iDesignId;
    entItemAggregation.lineAt = dLineAt + 10;
    entItemAggregation.startAt = dStartAt;
    entItemAggregation.width = width;
    entItemAggregation.fieldName = strFieldName;
    entItemAggregation.contentWidth = dContentWidth;
    if (strWordWrap === "wrap")
      entItemAggregation.contentLineCount =
        width === 0 ? 0 : parseInt(Math.ceil(dContentWidth / width));
    else entItemAggregation.contentLineCount = width === 0 ? 0 : 1;

    entItemAggregation.contentHeight =
      dContentHeight * entItemAggregation.contentLineCount;
    if (entItemAggregation.contentHeight === 0)
      entItemAggregation.contentLineCount = 0;
    objItemAggregation.push(entItemAggregation);
  }
  return objItemAggregation;
};
const fnAlignment = (objPrintParm) => {
  // let dContentWidth = 0;
  // let dGap = 0;
  // let TextWH = "";
  // switch (objPrintParm.alignment) {
  //   case "right":
  //     TextWH = fnMeasureString(
  //       objPrintParm.printContent,
  //       objPrintParm.fontSize,
  //       objPrintParm.fontStyle
  //     );
  //     dContentWidth = TextWH.dContentWidth;

  //     dGap = 0;
  //     if (dGap < 0) dGap = 0;
  //     break;
  //   case "center":
  //     TextWH = fnMeasureString(
  //       objPrintParm.printContent,
  //       objPrintParm.fontSize,
  //       objPrintParm.fontStyle
  //     );
  //     dContentWidth = TextWH.dContentWidth;
  //     dGap = (objPrintParm.width - dContentWidth) / 2;
  //     if (dGap < 0) dGap = 0;
  //     break;
  //   default:
  //     break;
  // }
  // objPrintParm.startAt = parseFloat(objPrintParm.startAt + dGap);
  return objPrintParm;
};
const SpliText = (str, chunkSize) => {
  let chunks = [];
  while (str) {
    if (str.length < chunkSize) {
      chunks.push(str);
      break;
    } else {
      chunks.push(str.substr(0, chunkSize));
      str = str.substr(chunkSize);
    }
  }
  return chunks;
};
const fnDrawItemsHeader = (
  objEntArea,
  dtData,
  bIsItem,
  objItemHeader,
  DescriptionLine
) => {
  let objLabels = [];
  let iTotalPages = DescriptionLine.iTotalPages;
  let _dGapPerLine = DescriptionLine.GapPerLine;
  if (bIsItem) objLabels = objEntArea.filter((DT) => DT.fieldName === "Label");
  else
    objLabels = objEntArea.filter(
      (DT) => DT.fieldName === "Label" || DT.fieldName === "AddLessHeaderLabel"
    );
  objItemHeader = [];
  objLabels.forEach((entLabel) => {
    let objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    //#region Get Description Height Diff of First Page
    objPrintParam.dHeightDiff =
      DescriptionLine.dFirstDescriptionBegin - parseFloat(objPrintParam.lineAt);
    if (iTotalPages > 1)
      objPrintParam.dHeightDiff =
        DescriptionLine.dFirstAlterDescriptionBegin -
        parseFloat(objPrintParam.lineAt);
    //#endregion

    objItemHeader = fnAddItemHeader(objItemHeader, objPrintParam);
    objPrintParam = fnAlignment(objPrintParam);
    if (bIsItem) fnDrawString(objPrintParam);
    else {
      objPrintParam.lineAt = parseFloat(DescriptionLine.fCurrentPageDescBegin);
      fnDrawString(objPrintParam);
    }
    if (
      objEntArea.filter((DT) => DT.fieldName === "AddLessHeaderLabel").length >
      0
    ) {
      var TextWH = fnMeasureString(
        objPrintParam.printContent,
        objPrintParam.fontSize,
        objPrintParam.fontStyle
      );
      let dContentHeight = TextWH.dContentHeight;
      DescriptionLine.fAddLessHeaderHeight = parseFloat(
        dContentHeight + _dGapPerLine
      );
      fnDrawString(objPrintParam);
    }
  });
  return {
    DescriptionLine: DescriptionLine,
    objItemHeader: objItemHeader,
  };
};
const fnAddItemHeader = (objEntItemHeader, objPrintParm) => {
  const objItemHeader = {
    designId: objPrintParm.designId,
    labelName: objPrintParm.labelName,
    dHeightDiff: objPrintParm.dHeightDiff,
  };
  objEntItemHeader.push(objItemHeader);
  return objEntItemHeader;
};
const fnDrawItemsData = (
  objEntArea,
  dtData,
  bIsItem,
  _dtJobs,
  _objAddLessArea,
  DescriptionLine,
  iPrintedItemSNo,
  iPrintedAddLessSNo,
  iSubTotalItemsSNo,
  objEntLogo
) => {
  let _dGapPerLine = DescriptionLine.GapPerLine;
  if (objEntArea.length === 0 || dtData.length === 0) {
    return {
      iPrintedItemSNo: 0,
      iPrintedAddLessSNo: 0,
      iSubTotalItemsSNo: 0,
    };
  }
  let dTotalItemHeight = 0;
  let dPerLineHeightWithGap = 0;
  let dContentHeight = 0;
  let dContentWidth = 0;
  let dPreviousElementHeight = 0;
  let objEntItemAggregation = [];
  let objElements = [];
  let objItemAggregations = [];

  var objPrintParam;
  if (bIsItem) {
    objElements = objEntArea
      .filter((DT) => DT.fieldName !== "Label")
      .sort((a, b) => a.lineAt - b.lineAt)
      .sort((c, d) => c.startAt - d.startAt);
  } else {
    objElements = objEntArea
      .filter(
        (DT) =>
          DT.fieldName !== "Label" && DT.fieldName !== "AddLessHeaderLabel"
      )
      .sort((a, b) => a.lineAt - b.lineAt)
      .sort((c, d) => c.startAt - d.startAt);
  }

  dtData.forEach((drData) => {
    let iSNo = drData["sNo"];
    if (bIsItem) {
      if (iPrintedItemSNo.includes(iSNo)) return;
      let Result = fnCanDrawItem(drData, objEntArea, DescriptionLine, _dtJobs);
      dTotalItemHeight = Result.dTotalItemHeight;
      if (!Result.bCanDrawItem) return;
    } else {
      if (iPrintedAddLessSNo.includes(iSNo)) return;
      let Result = fnCanDrawItem(
        drData,
        _objAddLessArea,
        DescriptionLine,
        _dtJobs
      );
      dTotalItemHeight = Result.dTotalItemHeight;
      if (!Result.bCanDrawItem) return;
    }
    objElements.forEach((entElement) => {
      objPrintParam = fnGetPrintAreaProperties(entElement);
      objPrintParam.printContent =
        drData[objPrintParam.fieldName] !== undefined
          ? "" + drData[objPrintParam.fieldName]
          : "";

      if (objPrintParam.printContent.includes("\n")) {
        let iNo = 0;
        let PrintContent = objPrintParam.printContent.split("\r\n");
        objPrintParam.iCount = PrintContent.length;
        PrintContent.forEach((print) => {
          while (iNo < objPrintParam.iCount) {
            objPrintParam.lineAt =
              iNo === 0 ? objPrintParam.lineAt : objPrintParam.lineAt + 20;
            objPrintParam.printContent = print;
            let TextWH = fnMeasureString(
              objPrintParam.printContent,
              objPrintParam.fontSize
            );
            dContentHeight = TextWH.dContentHeight;
            dContentWidth = TextWH.dContentWidth;
            objPrintParam = fnAlignment(objPrintParam);
            objPrintParam.dContentHeight = dContentHeight;
            objPrintParam.dContentWidth = dContentWidth;
            objEntItemAggregation = fnAddEachItemAggregation(
              objEntItemAggregation,
              objPrintParam,
              drData
            );

            dPreviousElementHeight = 0;
            dPerLineHeightWithGap = dContentHeight + _dGapPerLine;
            objPrintParam.iPrintContentLength =
              objPrintParam.printContent.length;
            objPrintParam.iWidthLimit = parseInt(
              Math.floor(objPrintParam.width)
            );
            objPrintParam.iContentWidth = parseInt(Math.floor(dContentWidth));
            objPrintParam.iPrintContentValidLength =
              (objPrintParam.iPrintContentLength * objPrintParam.iWidthLimit) /
              objPrintParam.iContentWidth;
            if (
              objPrintParam.iPrintContentLength <
              objPrintParam.iPrintContentValidLength
            )
              objPrintParam.iPrintContentValidLength =
                objPrintParam.iPrintContentLength;

            objItemAggregations = objEntItemAggregation.filter(
              // eslint-disable-next-line no-loop-func
              (DT) =>
                DT.lineAt !== parseFloat(objPrintParam.lineAt) &&
                DT.startAt === parseFloat(objPrintParam.startAt)
            );
            if (objItemAggregations.length > 0) {
              // eslint-disable-next-line no-loop-func
              objItemAggregations.forEach((entItemAggregation) => {
                objPrintParam.iLineCount = entItemAggregation.contentLineCount;
                objPrintParam.dPerElementHeight =
                  objPrintParam.iLineCount * entItemAggregation.contentHeight +
                  objPrintParam.iLineCount * _dGapPerLine;
                dPreviousElementHeight += objPrintParam.dPerElementHeight;
              });
            }

            let printContentSplitByWidth = SpliText(
              objPrintParam.printContent,
              objPrintParam.iPrintContentValidLength
            );
            objPrintParam.iPrintContentSplitByWidthCount =
              printContentSplitByWidth.Length;
            if (objPrintParam.strWordWrap === "wrap") {
              for (
                let iPrintContent = 0;
                iPrintContent < objPrintParam.iPrintContentSplitByWidthCount;
                iPrintContent++
              ) {
                objPrintParam.printContent =
                  printContentSplitByWidth[iPrintContent];
                objPrintParam.lineAt =
                  parseFloat(
                    parseFloat(DescriptionLine.fCurrentPageDescBegin) +
                      dPreviousElementHeight +
                      iPrintContent * dPerLineHeightWithGap
                  ) + DescriptionLine.fAddLessHeaderHeight;
                objPrintParam.startAt = objPrintParam.startAt + 15;
                fnDrawString(objPrintParam);
              }
              objEntItemAggregation[
                objEntItemAggregation.length - 1
              ].contentHeight = dContentHeight;
              objEntItemAggregation[
                objEntItemAggregation.length - 1
              ].contentWidth = dContentWidth;
              objEntItemAggregation[
                objEntItemAggregation.length - 1
              ].contentLineCount = objPrintParam.iPrintContentSplitByWidthCount;
            } else {
              objPrintParam.printContent = printContentSplitByWidth[0];
              objPrintParam.lineAt =
                DescriptionLine.fCurrentPageDescBegin +
                parseFloat(dPreviousElementHeight) +
                DescriptionLine.fAddLessHeaderHeight;
              objPrintParam.startAt = objPrintParam.startAt + 15;
              fnDrawString(objPrintParam);
            }
            break;
          }
          iNo++;
        });
      } else {
        let TextWH = fnMeasureString(
          objPrintParam.printContent,
          objPrintParam.fontSize,
          objPrintParam.strFontStyle
        );
        dContentHeight = TextWH.dContentHeight;
        dContentWidth = TextWH.dContentWidth;
        objPrintParam = fnAlignment(objPrintParam);
        objPrintParam.dContentHeight = dContentHeight;
        objPrintParam.dContentWidth = dContentWidth;
        objEntItemAggregation = fnAddEachItemAggregation(
          objEntItemAggregation,
          objPrintParam,
          drData
        );

        dPreviousElementHeight = 0;
        dPerLineHeightWithGap = dContentHeight + _dGapPerLine;
        objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
        objPrintParam.iWidthLimit = parseInt(Math.floor(objPrintParam.width));
        objPrintParam.iContentWidth = parseInt(Math.floor(dContentWidth));
        objPrintParam.iPrintContentValidLength =
          (objPrintParam.iPrintContentLength * objPrintParam.iWidthLimit) /
          objPrintParam.iContentWidth;
        if (
          objPrintParam.iPrintContentLength <
          objPrintParam.iPrintContentValidLength
        )
          objPrintParam.iPrintContentValidLength =
            objPrintParam.iPrintContentLength;

        objItemAggregations = objEntItemAggregation.filter(
          (DT) =>
            DT.lineAt !== objPrintParam.lineAt &&
            DT.startAt === objPrintParam.startAt
        );
        if (objItemAggregations.length > 0) {
          objItemAggregations.forEach((entItemAggregation) => {
            objPrintParam.iLineCount = entItemAggregation.contentLineCount;
            objPrintParam.dPerElementHeight =
              objPrintParam.iLineCount * entItemAggregation.contentHeight +
              objPrintParam.iLineCount * _dGapPerLine;
            dPreviousElementHeight += objPrintParam.dPerElementHeight;
          });
        }

        let printContentSplitByWidth = SpliText(
          objPrintParam.printContent,
          objPrintParam.iPrintContentValidLength
        );
        objPrintParam.iPrintContentSplitByWidthCount =
          printContentSplitByWidth.length;
        if (objPrintParam.wordWrap === "wrap") {
          for (
            let iPrintContent = 0;
            iPrintContent < objPrintParam.iPrintContentSplitByWidthCount;
            iPrintContent++
          ) {
            objPrintParam.printContent =
              printContentSplitByWidth[iPrintContent];
            objPrintParam.lineAt =
              DescriptionLine.fCurrentPageDescBegin +
              dPreviousElementHeight +
              iPrintContent * dPerLineHeightWithGap +
              DescriptionLine.fAddLessHeaderHeight;
            objPrintParam.startAt = objPrintParam.startAt + 15;
            fnDrawString(objPrintParam);
          }
          objEntItemAggregation[
            objEntItemAggregation.length - 1
          ].contentHeight = dContentHeight;
          objEntItemAggregation[objEntItemAggregation.length - 1].contentWidth =
            dContentWidth;
          objEntItemAggregation[
            objEntItemAggregation.length - 1
          ].contentLineCount = objPrintParam.iPrintContentSplitByWidthCount;
        } else {
          objPrintParam.printContent = printContentSplitByWidth[0];
          objPrintParam.lineAt =
            DescriptionLine.fCurrentPageDescBegin +
            dPreviousElementHeight +
            DescriptionLine.fAddLessHeaderHeight;
          objPrintParam.startAt = objPrintParam.startAt + 15;
          fnDrawString(objPrintParam);
        }
      }
    });
    if (bIsItem) {
      iPrintedItemSNo.push(iSNo);
      iSubTotalItemsSNo.push(iSNo);
    } else iPrintedAddLessSNo.push(iSNo);

    objEntItemAggregation = [];
    DescriptionLine.fCurrentPageDescBegin = parseFloat(dTotalItemHeight);

    //#region Multi-job Print Logic
    if (_dtJobs.length > 0) {
      _dtJobs = _dtJobs.filter((Data) => Data.outWInvSno === drData["SNo"]);
      _dtJobs.forEach((drJobs) => {
        if (bIsItem) {
          let Result = fnCanDrawItem(drJobs, objEntArea, DescriptionLine);
          dTotalItemHeight = Result.dTotalItemHeight;
          if (!Result.bCanDrawItem) {
            return;
          }
        } else {
          let Result = fnCanDrawItem(drJobs, _objAddLessArea, DescriptionLine);
          dTotalItemHeight = Result.dTotalItemHeight;
          if (!Result.bCanDrawItem) {
            return;
          }
        }
        objElements.forEach((entElement) => {
          objPrintParam = fnGetPrintAreaProperties(entElement);
          objPrintParam.printContent = drJobs.includes(objPrintParam.fieldName)
            ? "" + drJobs[objPrintParam.fieldName]
            : "";
          if (objPrintParam.printContent !== "") return;
          let TextWH = fnMeasureString(
            objPrintParam.printContent,
            objPrintParam.fontSize
          );
          dContentHeight = TextWH.dContentHeight;
          dContentWidth = TextWH.dContentWidth;
          objPrintParam = fnAlignment(objPrintParam);
          objEntItemAggregation = fnAddEachItemAggregation(
            objEntItemAggregation,
            objPrintParam,
            drData
          );

          dPreviousElementHeight = 0;
          dPerLineHeightWithGap = dContentHeight + _dGapPerLine;
          objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
          objPrintParam.iWidthLimit = parseInt(Math.floor(objPrintParam.width));
          objPrintParam.iContentWidth = parseInt(Math.floor(dContentWidth));
          objPrintParam.iPrintContentValidLength =
            (objPrintParam.iPrintContentLength * objPrintParam.iWidthLimit) /
            objPrintParam.iContentWidth;
          if (
            objPrintParam.iPrintContentLength <
            objPrintParam.iPrintContentValidLength
          )
            objPrintParam.iPrintContentValidLength =
              objPrintParam.iPrintContentLength;

          objItemAggregations = objEntItemAggregation.filter(
            (DT) =>
              DT.lineAt !== objPrintParam.lineAt &&
              DT.startAt === objPrintParam.startAt
          );
          if (objItemAggregations.length > 0) {
            objItemAggregations.forEach((entItemAggregation) => {
              objPrintParam.iLineCount = entItemAggregation.contentLineCount;
              objPrintParam.dPerElementHeight =
                objPrintParam.iLineCount * entItemAggregation.contentHeight +
                objPrintParam.iLineCount * _dGapPerLine;
              dPreviousElementHeight += objPrintParam.dPerElementHeight;
            });
          }

          let printContentSplitByWidth = SpliText(
            objPrintParam.printContent,
            objPrintParam.iPrintContentValidLength
          );

          objPrintParam.iPrintContentSplitByWidthCount =
            printContentSplitByWidth.length;
          if (objPrintParam.wordWrap === "wrap") {
            for (
              let iPrintContent = 0;
              iPrintContent < objPrintParam.iPrintContentSplitByWidthCount;
              iPrintContent++
            ) {
              objPrintParam.printContent =
                printContentSplitByWidth[iPrintContent];

              objPrintParam.lineAt =
                DescriptionLine.fCurrentPageDescBegin +
                dPreviousElementHeight +
                iPrintContent * dPerLineHeightWithGap +
                DescriptionLine.fAddLessHeaderHeight;
              objPrintParam.startAt = objPrintParam.startAt + 10;
              fnDrawString(objPrintParam);
            }
            objEntItemAggregation[
              objEntItemAggregation.length - 1
            ].contentHeight = dContentHeight;
            objEntItemAggregation[
              objEntItemAggregation.length - 1
            ].contentWidth = dContentWidth;
            objEntItemAggregation[
              objEntItemAggregation.length - 1
            ].contentLineCount = objPrintParam.iPrintContentSplitByWidthCount;
          } else {
            objPrintParam.printContent = printContentSplitByWidth[0];

            objPrintParam.lineAt =
              DescriptionLine.fCurrentPageDescBegin +
              dPreviousElementHeight +
              DescriptionLine.fAddLessHeaderHeight;
            objPrintParam.startAt = objPrintParam.startAt + 10;
            fnDrawString(objPrintParam);
          }
        });
        objEntItemAggregation = [];
        DescriptionLine.fCurrentPageDescBegin = parseFloat(dTotalItemHeight);
      });
    }
    //#endregion
  });
  return {
    iPrintedItemSNo: iPrintedItemSNo,
    iPrintedAddLessSNo: iPrintedAddLessSNo,
    iSubTotalItemsSNo: iSubTotalItemsSNo,
  };
};
const fnDrawItemsAutoHeader = (
  objItemHeader,
  objEntArea,
  dtData,
  DescriptionLine
) => {
  let objLabels = objEntArea.filter((DT) => DT.fieldName === "Label");
  let objPrintParam = [];
  objLabels.forEach((entLabel) => {
    objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    objPrintParam.iCount = objItemHeader.filter(
      (DT) => DT.designId === objPrintParam.designId
    ).length;
    if (objPrintParam.iCount > 0) {
      var entItemHeader = objItemHeader.filter(
        (DT) => DT.designId === objPrintParam.designId
      )[0];
      objPrintParam.dHeightDiff = entItemHeader.dHeightDiff;
      if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages)
        objPrintParam.lineAt = parseFloat(
          DescriptionLine.dLastDescriptionBegin - objPrintParam.dHeightDiff
        );
      else
        objPrintParam.lineAt = parseFloat(
          DescriptionLine.dMiddleDescriptionBegin - objPrintParam.dHeightDiff
        );
    }
    objPrintParam = fnAlignment(objPrintParam);
    fnDrawString(objPrintParam);
  });
};
const fnCanDrawItem = (dtData, objArea, DescriptionLine, _dtJobs) => {
  let bCanDrawItem = true;
  let dHeightPerItem = 0;
  let dTotalItemHeight = 0;
  let dLinePerItem = 0;
  let dtItem = [];
  dtItem.push(dtData);
  let dTotalLinHw = fnGetTableHeight(objArea, dtItem, _dtJobs);
  dHeightPerItem = dTotalLinHw.dTotalHeight;
  dLinePerItem = dTotalLinHw.dTotalLine;
  dHeightPerItem += dLinePerItem * DescriptionLine.GapPerLine;
  dTotalItemHeight = DescriptionLine.fCurrentPageDescBegin + dHeightPerItem;
  if (DescriptionLine.dCurrentPageDescLimit <= dTotalItemHeight) {
    bCanDrawItem = false;
  }
  return {
    bCanDrawItem: bCanDrawItem,
    dTotalItemHeight: dTotalItemHeight,
  };
};
const fnDrawGeneralData = (
  objEntArea,
  _dtItems,
  iSubTotalItemsSNo,
  _dtSummary,
  DescriptionLine,
  objEntLogo,
  DecimalFormate
) => {
  let dContentHeight = 0,
    dContentWidth = 0;
  let objPrintParam = "";
  let dPerLineHeightWithGap = 0;
  try {
    //#region Label's
    let objLabels = objEntArea.filter(
      (DT) =>
        DT.fieldName === "Label" &&
        DT.prefix !== "SubTotal" &&
        DT.suffix !== "Only"
    );
    objLabels.forEach((entLabel) => {
      objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
      objPrintParam = fnAlignment(objPrintParam);
      fnDrawString(objPrintParam);
    });
    //#endregion

    //#region PageNumber
    if (objEntArea.filter((DT) => DT.fieldName === "PageNumber").length > 0) {
      let entPageNumber = objEntArea.filter(
        (DT) => DT.fieldName === "PageNumber"
      )[0];
      objPrintParam = fnGetPrintAreaProperties(entPageNumber);
      objPrintParam = fnAlignment(objPrintParam);
      objPrintParam.printContent =
        DescriptionLine.iCurrPage + " / " + DescriptionLine.iTotalPages;
      fnDrawString(objPrintParam);
    }

    //#endregion

    //#region CopyType
    if (objEntArea.filter((DT) => DT.fieldName === "CopiesName").length > 0) {
      let entPageNumber = objEntArea.filter(
        (DT) => DT.fieldName === "CopiesName"
      )[0];
      objPrintParam = fnGetPrintAreaProperties(entPageNumber);
      objPrintParam = fnAlignment(objPrintParam);
      var entAreas = objEntArea.filter((r) => r.fieldName === "CopiesName")[0];
      let StrLable = entAreas.labelName;
      let StrSuffix = entAreas.suffix;
      let StrPrefix = entAreas.prefix;
      switch (DescriptionLine.iCurrentCopy) {
        case 1:
          objPrintParam.printContent = StrLable;
          break;
        case 2:
          objPrintParam.printContent = StrPrefix;
          break;
        default:
          objPrintParam.printContent = StrSuffix;
          break;
      }
      objPrintParam.font = entPageNumber.font;
      fnDrawString(objPrintParam);
    }
    //#endregion

    //#region SubTotal
    let objSubTotals = objEntArea.filter(
      (DT) => DT.fieldName === "Label" && DT.prefix === "SubTotal"
    );
    objSubTotals.forEach((entSubTotal) => {
      let dSubTotal = 0;
      let dSubTotalDiff = 0;
      objPrintParam = fnGetPrintAreaProperties(entSubTotal, "Label");
      //#region Get SubTotal Height Diff of First Page
      dSubTotalDiff = parseFloat(
        objPrintParam.lineAt - DescriptionLine.dFirstDescriptionEnd
      );
      //#endregion
      var printContent = objPrintParam.printContent;
      printContent = printContent.replace(
        /^.{1}/g,
        printContent[0].toLowerCase()
      );
      iSubTotalItemsSNo.forEach((iSubTotalItemSNo) => {
        let drItem = _dtItems.filter(
          (Data) => Data.sNo === iSubTotalItemSNo
        )[0];
        dSubTotal += Number(drItem[printContent]);
      });
      if (objPrintParam.printContent.includes("Qty")) {
        objPrintParam.printContent = parseFloat(dSubTotal)
          .toFixed(DecimalFormate.strQtyFormat)
          .toString();
      } else {
        objPrintParam.printContent = parseFloat(dSubTotal)
          .toFixed(DecimalFormate.strAmtFormat)
          .toString();
      }
      objPrintParam = fnAlignment(objPrintParam);
      switch (DescriptionLine.iTotalPages) {
        case 1:
          objPrintParam.lineAt = parseFloat(
            DescriptionLine.dFirstDescriptionEnd + dSubTotalDiff
          );
          break;
        case 2:
          if (DescriptionLine.iCurrPage === 1)
            objPrintParam.lineAt = parseFloat(
              DescriptionLine.dFirstAlterDescriptionEnd + dSubTotalDiff
            );
          else
            objPrintParam.lineAt = parseFloat(
              DescriptionLine.dLastDescriptionEnd + dSubTotalDiff
            );
          break;
        default:
          if (DescriptionLine.iCurrPage === 1)
            objPrintParam.lineAt = parseFloat(
              DescriptionLine.dFirstAlterDescriptionEnd + dSubTotalDiff
            );
          else if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages)
            objPrintParam.lineAt = parseFloat(
              DescriptionLine.dLastDescriptionEnd + dSubTotalDiff
            );
          else
            objPrintParam.lineAt = parseFloat(
              DescriptionLine.dMiddleDescriptionEnd + dSubTotalDiff
            );
          break;
      }
      fnDrawString(objPrintParam);
    });
    //#endregion

    //#region Amount in words
    if (DescriptionLine.iCurrPage === DescriptionLine.iTotalPages) {
      let objAmountInWords = objEntArea.filter(
        (DT) => DT.fieldName === "Label" && DT.suffix === "Only"
      );
      objAmountInWords.forEach((entAmountInWord) => {
        objPrintParam = fnGetPrintAreaProperties(entAmountInWord, "Label");
        var StringWidthHeight = fnMeasureString(
          objPrintParam.printContent,
          objPrintParam.fontSize,
          objPrintParam.fontStyle
        );
        dContentHeight = StringWidthHeight.dContentHeight;
        dContentWidth = StringWidthHeight.dContentWidth;
        objPrintParam = fnAlignment(objPrintParam);
        let strWords = objPrintParam.printContent.replace(
          /^.{1}/g,
          objPrintParam.printContent[0].toLowerCase()
        );
        strWords = _dtSummary[0][strWords];
        //Convet Number to Words
        objPrintParam.printContent =
          NumbertoWord(parseFloat(strWords).toFixed(2)) + " Rupees Only /-";

        dPerLineHeightWithGap = dContentHeight + DescriptionLine.GapPerLine;
        objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
        objPrintParam.iWidthLimit = parseInt(objPrintParam.width);
        objPrintParam.iContentWidth = parseInt(Math.floor(dContentWidth));
        objPrintParam.iPrintContentValidLength =
          (objPrintParam.iPrintContentLength * objPrintParam.iWidthLimit) /
          objPrintParam.iContentWidth;

        if (
          objPrintParam.iPrintContentLength <
          objPrintParam.iPrintContentValidLength
        )
          objPrintParam.iPrintContentValidLength =
            objPrintParam.iPrintContentLength;

        let printContentSplitByWidth = SpliText(
          objPrintParam.printContent,
          objPrintParam.iPrintContentValidLength
        );
        let iPrintContentSplitByWidthCount = printContentSplitByWidth.length;
        if (objPrintParam.wordWrap === "wrap") {
          for (
            let iPriCon = 0;
            iPriCon < iPrintContentSplitByWidthCount;
            iPriCon++
          ) {
            objPrintParam.printContent = printContentSplitByWidth[iPriCon];
            objPrintParam.lineAt = parseFloat(
              parseFloat(objPrintParam.lineAt) + iPriCon * dPerLineHeightWithGap
            );
            fnDrawString(objPrintParam);
          }
        }
      });
    }
    //#endregion

    //#region Draw Logo
    var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
    EntLogo.forEach((entLogo) => {
      let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
      let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
      fnDrawLogo(entLogo, objentLogoData);
    });
    //#endregion
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};
const termalPrinterDesc = (
  _objItemsArea,
  _dtItems,
  _objAddLessArea,
  _dtAddLess
) => {
  //#region Calculate Total Desc Area Height
  let dTotalHeight = 0;
  let dTotalLine = 0;
  let TotalJobHeightWidth = fnGetTableHeight(_objItemsArea, _dtItems);
  dTotalHeight = TotalJobHeightWidth.dTotalHeight;
  dTotalLine = TotalJobHeightWidth.dTotalLine;
  if (_objAddLessArea.length > 0 && _dtAddLess.length > 0)
    fnGetTableHeight(_objAddLessArea, _dtAddLess);
  dTotalHeight = TotalJobHeightWidth.dTotalHeight;
  dTotalLine = TotalJobHeightWidth.dTotalLine;

  //#endregion
  const objENtTHL = {
    dTotalHeight: dTotalHeight,
    dTotalLine: dTotalLine,
  };
  return objENtTHL;
};
const fnDrawTermalGeneralData = (
  objEntArea,
  _dtItems,
  iSubTotalItemsSNo,
  _dtSummary,
  DescriptionLine,
  objEntLogo,
  DecimalFormate
) => {
  let dContentHeight = 0,
    dContentWidth = 0,
    dPerLineHeightWithGap = 0;
  let objPrintParam = "";
  //#region Label's
  let objLabels = objEntArea.filter(
    (DT) =>
      DT.fieldName === "Label" &&
      DT.prefix !== "SubTotal" &&
      DT.suffix !== "Only"
  );
  objLabels.forEach((entLabel) => {
    objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    objPrintParam = fnAlignment(objPrintParam);
    fnDrawString(objPrintParam);
  });
  //#endregion

  //#region PageNumber
  if (objEntArea.filter((DT) => DT.fieldName === "PageNumber").length > 0) {
    let entPageNumber = objEntArea.filter(
      (DT) => DT.FieldName === "PageNumber"
    )[0];
    objPrintParam = fnGetPrintAreaProperties(entPageNumber);
    objPrintParam = fnAlignment(objPrintParam);
    objPrintParam.strFont = entPageNumber.Font;
    objPrintParam.printContent =
      DescriptionLine.iCurrPage + " / " + DescriptionLine.iTotalPages;
    fnDrawString(objPrintParam);
  }
  //#endregion

  //#region CopyType
  if (objEntArea.filter((DT) => DT.fieldName === "CopiesName").length > 0) {
    let entPageNumber = objEntArea.filter(
      (DT) => DT.fieldName === "CopiesName"
    )[0];
    objPrintParam = fnGetPrintAreaProperties(entPageNumber);
    objPrintParam = fnAlignment(objPrintParam);
    var entAreas = objEntArea.filter((r) => r.fieldName === "CopiesName");
    let StrLable = entAreas.labelName;
    let StrSuffix = entAreas.suffix;
    let StrPrefix = entAreas.prefix;
    switch (DescriptionLine.iCurrentCopy) {
      case 1:
        objPrintParam.printContent = StrLable;
        break;
      case 2:
        objPrintParam.printContent = StrPrefix;
        break;
      default:
        objPrintParam.printContent = StrSuffix;
        break;
    }
    fnDrawString(objPrintParam);
  }
  //#endregion

  //#region SubTotal
  let objSubTotals = objEntArea.filter(
    (DT) => DT.fieldName === "Label" && DT.prefix === "SubTotal"
  );
  objSubTotals.forEach((entSubTotal) => {
    let dSubTotal = 0,
      dSubTotalDiff = 0;
    objPrintParam = fnGetPrintAreaProperties(entSubTotal, "Label");
    //#region Get SubTotal Height Diff of First Page
    dSubTotalDiff = parseFloat(
      objPrintParam.lineAt - DescriptionLine.dFirstDescriptionEnd
    );
    //#endregion
    var printContent = objPrintParam.printContent;
    printContent = printContent.replace(
      /^.{1}/g,
      printContent[0].toLowerCase()
    );
    iSubTotalItemsSNo.forEach((iSubTotalItemSNo) => {
      let drItem = _dtItems.filter((Data) => Data.sNo === iSubTotalItemSNo)[0];
      dSubTotal += drItem[printContent];
    });

    if (objPrintParam.printContent.includes("Qty")) {
      objPrintParam.printContent = parseFloat(dSubTotal)
        .toFixed(DecimalFormate.strQtyFormat)
        .toString();
    } else {
      objPrintParam.printContent = parseFloat(dSubTotal)
        .toFixed(DecimalFormate.strAmtFormat)
        .toString();
    }
    objPrintParam = fnAlignment(objPrintParam);
    objPrintParam.lineAt = parseFloat(
      DescriptionLine.dFirstDescriptionEnd + dSubTotalDiff
    );
    fnDrawString(objPrintParam);
  });
  //#endregion

  //#region Amount in words
  let objAmountInWords = objEntArea.filter(
    (DT) => DT.fieldName === "Label" && DT.suffix === "Only"
  );
  objAmountInWords.forEach((entAmountInWord) => {
    objPrintParam = fnGetPrintAreaProperties(entAmountInWord, "Label");
    let TxtWH = fnMeasureString(
      objPrintParam.printContent,
      objPrintParam.fontSize,
      objPrintParam.fontStyle
    );
    dContentHeight = TxtWH.dContentHeight;
    dContentWidth = TxtWH.dContentWidth;
    objPrintParam = fnAlignment(objPrintParam);

    let strWords = objPrintParam.printContent.replace(
      /^.{1}/g,
      objPrintParam.printContent[0].toLowerCase()
    );
    strWords = _dtSummary[0][strWords];
    //Convet Number to Words
    objPrintParam.printContent =
      NumbertoWord(parseFloat(strWords).toFixed(2)) + " Rupees Only /-";

    dPerLineHeightWithGap = dContentHeight + DescriptionLine.GapPerLine;
    objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
    objPrintParam.iWidthLimit = parseFloat(Math.floor(objPrintParam.width));
    objPrintParam.iContentWidth = parseFloat(Math.floor(dContentWidth));
    objPrintParam.iPrintContentValidLength =
      (objPrintParam.iPrintContentLength * objPrintParam.iWidthLimit) /
      objPrintParam.iContentWidth;
    if (
      objPrintParam.iPrintContentLength < objPrintParam.iPrintContentValidLength
    )
      objPrintParam.iPrintContentValidLength =
        objPrintParam.iPrintContentLength;

    let printContentSplitByWidth = SpliText(
      objPrintParam.printContent,
      objPrintParam.iPrintContentValidLength
    );
    objPrintParam.iPrintContentSplitByWidthCount =
      printContentSplitByWidth.length;
    if (objPrintParam.wordWrap === "wrap") {
      for (
        let iPrintContent = 0;
        iPrintContent < objPrintParam.iPrintContentSplitByWidthCount;
        iPrintContent++
      ) {
        objPrintParam.printContent = printContentSplitByWidth[iPrintContent];
        objPrintParam.lineAt = parseFloat(
          parseFloat(objPrintParam.lineAt) +
            iPrintContent * dPerLineHeightWithGap
        );
        fnDrawString(objPrintParam);
      }
    }
  });
  //#endregion

  //#region Draw Logo
  var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
  EntLogo.forEach((entLogo) => {
    let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
    let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
    fnDrawLogo(entLogo, objentLogoData);
  });
  //#endregion
};
const fnDrawtermalPageData = (objEntArea, dtData, objEntLogo) => {
  let objElements = objEntArea.filter(
    (DT) => DT.fieldName !== "Label" && !DT.fieldName.startsWith("LogoId")
  );
  let objLabels = objEntArea.filter((DT) => DT.fieldName === "Label");
  let objPrintParam = "";
  objLabels.forEach((entLabel) => {
    objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
    objPrintParam = fnAlignment(objPrintParam);
    fnDrawString(objPrintParam);
  });
  dtData.forEach((drData) => {
    objElements.forEach((entElement) => {
      objPrintParam = fnGetPrintAreaProperties(entElement);
      objPrintParam.printContent = drData[objPrintParam.fieldName].toString();
      objPrintParam = fnAlignment(objPrintParam);
      fnDrawString(objPrintParam);
    });
  });
  //#region Draw Logo
  var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
  EntLogo.forEach((entLogo) => {
    let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
    let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
    fnDrawLogo(entLogo, objentLogoData);
  });
  //#endregion
};
