import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import CustomTransView from "../Transaction/CustomTransView";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";

import Messagedialogbox from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import {
  Change_Unit,
  ProductRate_Onchange,
  Select_Product,
  StockAdjutment_Onchange,
  StockQty_Onchange,
} from "../features/Transactionstates";
import MasterProductSearch from "../Transaction/MasterProductSearch";
import ReactTable from "../General/ReactTable";
function StockAdjustment() {
  const dispatch = useDispatch();
  const _Proparem = useSelector((state) => state.Transstates.Product);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const iScrId = 38;
  const [selected, setselected] = useState(0);
  const [isTrans, setisTrans] = useState(false);
  const [isBtnView, setisBtnView] = useState(false);
  const [isBtnSave, setisBtnSave] = useState(false);
  const [isBtnDelete, setisBtnDelete] = useState(false);
  const [isProduct, setisProduct] = useState(false);

  const [strTransNo, setstrTransNo] = useState("");
  const [strVouchNo, setstrVouchNo] = useState("");
  const [strVouchDate, setstrVouchDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iStoAdjId, setiStoAdjId] = useState(0);
  const [strDescription, setstrDescription] = useState("");

  const [dNetAmt, setdNetAmt] = useState(0);
  const [dTQty, setdTQty] = useState(0);
  const [dGoodVal, setdGoodVal] = useState(0);
  const [dRoundOff, setdRoundOff] = useState(0);

  const [NoFormat, setNoFormat] = useState([]);
  const [EntTransDT, setEntTransDT] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);
  const [ListTabOrder, setListTabOrder] = useState({
    txtTransNo: "",
    txtStoAdjNo: "",
    dpStoAdjDate: "",
    txtDescription: "",
    btnNew: "",
    btnModify: "",
    btnSave: "",
    btnClear: "",
    btnView: "",
    btnExportImport: "",
    btnPrint: "",
    btnCancel: "",
    btnClose: "",
    btnPrintSettings: "",
    btnHelp: "",
    TopCenterVoucher: "",
    TopRightDelivery: "",
    MiddleRightSummary: "",
    ProductTableListTab: "",
    txtProductName: "",
    txtQty: "",
    cbUnit: "",
    txtRate: "",
    txtReason: "",
    btnAdd: "",
  });
  useEffect(() => {
    Load_StockAdjustment();
    ButtonPermission();
  }, []);

  const Load_StockAdjustment = async () => {
    const _LocalParem = await DecryptData(localStorage.jbctrlparams);
    const res = API_FETCH(
      `StockAdjustment/Load_StockAdjustment/${Number(_LocalParem.Branch)}/${
        _LocalParem.DB
      }`,
      dispatch
    );
    if (res) {
      setNoFormat(res.NoFormat);
      setstrTransNo(res.NoFormat[0].strTransNo);
      setstrVouchNo(res.NoFormat[0].strUserNo);
      //TabOrderAssign(_LocalParem.TabOrder);
      //setListValidateProperties(_LocalParem.TabOrder);
    }
  };
  const SaveClick = async () => {
    if (EntTransDT.length === 0) {
      Show_Message(dispatch, "Atleast One Item is Required per Bill", "info");
    } else {
      const StoAdjHT = {
        iTitleTag: iScrId,
        strTransNo: strTransNo,
        iNumSeqNo: NoFormat[0].iNumSeqNo,
        strPrefix: NoFormat[0].strPrefix,
        strCompCode: NoFormat[0].strCompCode,
        strFinanFrom: NoFormat[0].strFinanFrom,
        iStoAdjId: iStoAdjId,
        strVouchNo: strVouchNo,
        strVouchDate: strVouchDate,
        strDescription: strDescription,
        dTQty: Number(dTQty),
        dTGoodsValue: Number(dGoodVal),
        dRoundOff: Number(dRoundOff),
        dNetAmt: Number(dNetAmt.replace(/[^0-9.-]+/g, "")),
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        "StockAdjustment/InsUpd_StockAdjustment/",
        {
          objEntItem: StoAdjHT,
          objBaseTransDT: EntTransDT,
          strDBName: _AuthParems.DB,
          iUserId: Number(_AuthParems.User),
        },
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    }
  };
  const DeleteClick = async () => {
    if (iStoAdjId === 0) {
      Show_Message(dispatch, "Please Select Voucher to Delete!", "error");
    } else {
      const res = await API_POST(
        `StockAdjustment/Delete_StockAdjustment/${iStoAdjId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    }
  };
  const DeleteIconClick = async (iIndex) => {
    const res = await API_POST(
      "StockAdjustment/DeleteProduct/",
      {
        iRowIndex: iIndex,
        objBaseTransDT: EntTransDT,
        bUpdateStatus: _Proparem.bUpdateStatus,
        iProId: 0,
        dQty: 0,
        iUnitId: 0,
        dPriceRate: 0,
        strProAttDTId: "0",
        strStoAttDTId: "0",
        strTraAttDTId: "0",
        strReason: _Proparem.Reason,
        objEntAddLess: [],
        objEntConRow: [],
        objEntTax: [],
        objItemRemark: _Proparem.ItemRemark[0],
        objPenCon: [],
        objRootCalc: _Proparem.listRootCalc[0],
        strColumn: "",
        strJobId: "",
        strSearch: "",
        strSetCurrentStatus: "Yes",
        iYearId: Number(_AuthParems.Year),
        strDBName: _AuthParems.DB,
        iRoleId: Number(_AuthParems.User),
      },
      dispatch
    );
    if (res) {
      setEntTransDT(res.EntTransDT);
      let Summary = res.objStoAdjSummary;
      setdTQty(Number(Summary.qty).toFixed(2));
      setdGoodVal(Number(Summary.goodsValue).toFixed(2));
      setdRoundOff(Number(Summary.roundOff).toFixed(2));
      setdNetAmt(Summary.netAmt);
    }
  };
  const ClearClick = () => {
    setEntTransDT([]);
    setstrDescription("");
    setdNetAmt(0);
    setdTQty(Number(0).toFixed(2));
    setdRoundOff(Number(0).toFixed(2));
    setdGoodVal(Number(0).toFixed(2));
    ClearProduct();
    Load_StockAdjustment();
  };
  const View_StcokAdjustment = async (iVouchId) => {
    setiStoAdjId(iVouchId);
    const res = API_FETCH(
      `StockAdjustment/View_Voucher/${iVouchId}/${Number(_AuthParems.Branch)}/${
        _AuthParems.DB
      }`,
      dispatch
    );
    if (res) {
      setstrTransNo(res.objEntHT[0].strTransNo);
      setstrVouchNo(res.objEntHT[0].strVouchNo);
      setstrVouchDate(res.objEntHT[0].strVouchDate);
      setstrDescription(res.objEntHT[0].strDescription);
      setEntTransDT(res.EntTransDT);
      $(".rt-tbody")
        .stop()
        .animate({
          scrollTop: 30 * (res.EntTransDT.length - 1),
        });
      let Summary = res.objEntSummary[0];
      setdTQty(Number(Summary.qty).toFixed(2));
      setdGoodVal(Number(Summary.goodsValue).toFixed(2));
      setdRoundOff(Number(Summary.roundOff).toFixed(2));
      setdNetAmt(Summary.netAmt);
    }
  };
  const AssignSelectedProduct = async (Index, proinfo) => {
    const ItemRemark = [
      {
        strRemark1: proinfo.remark1,
        strRemark2: proinfo.remark2,
        strRemark3: proinfo.remark3,
        strRemark4: proinfo.remark4,
      },
    ];
    const res = API_FETCH(
      `Transaction/GetParticularProDetials/${proinfo.proId}/Purchase/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      dispatch(
        Select_Product({
          iProId: proinfo.proId,
          strProCode: proinfo.proCode,
          strProName: proinfo.proName,
          iUnitId: proinfo.unitId,
          strUnitName: proinfo.unitName,
          dPriceRate: proinfo.rateWithoutTax,
          dPriceRatewithTax: proinfo.rateWithTax,
          isItemDiscper: true,
          dItemDisc: 0,
          isCashDiscper: true,
          dCashDisc: 0,
          dQty: proinfo.qty,
          FQty: 0,
          iRowIndex: Index,
          bUpdateStatus: true,
          UnitList: res.objUnit,
          ItemRemark: ItemRemark,
          listRootCalc: _Proparem.listRootCalc,
          arrReadonly: _Proparem.arrReadonly,
          strBatchNo: "",
          dpManufactureDate: "",
          dpExpiryDate: "",
          JobName: "",
          JobId: "",
          Reason: proinfo.reason,
        })
      );
    }
  };
  const AddProductClick = async () => {
    if (_Proparem.strProName === "") {
      Show_Message(dispatch, "Please select Product", "info");
    } else if (Number(_Proparem.dQty) === 0) {
      Show_Message(dispatch, "Minimum Qty is Required", "info");
    } else {
      const res = await API_POST(
        "StockAdjustment/Add_Product/",
        {
          bUpdateStatus: _Proparem.bUpdateStatus,
          iRowIndex: _Proparem.iRowIndex,
          iProId: _Proparem.iProId,
          dQty: _Proparem.dQty,
          iUnitId: _Proparem.iUnitId,
          dPriceRate: _Proparem.dPriceRate,
          strProAttDTId: "0",
          strStoAttDTId: "0",
          strTraAttDTId: "0",
          objBaseTransDT: EntTransDT,
          strReason: _Proparem.Reason,
          objEntAddLess: [],
          objEntConRow: [],
          objEntTax: [],
          objItemRemark: _Proparem.ItemRemark[0],
          objPenCon: [],
          objRootCalc: _Proparem.listRootCalc[0],
          strColumn: "",
          strJobId: "",
          strSearch: "",
          strSetCurrentStatus: "Yes",
          iYearId: Number(_AuthParems.Year),
          strDBName: _AuthParems.DB,
          iRoleId: Number(_AuthParems.User),
          iBranchId: Number(_AuthParems.Branch),
        },
        dispatch
      );
      if (res) {
        setEntTransDT(res.EntTransDT);
        let Summary = res.objEntSummary[0];
        setdTQty(Number(Summary.qty).toFixed(2));
        setdGoodVal(Number(Summary.goodsValue).toFixed(2));
        setdRoundOff(Number(Summary.roundOff).toFixed(2));
        setdNetAmt(Summary.netAmt);
        ClearProduct();
      }
    }
  };
  const ClearProduct = () => {
    dispatch(
      Select_Product({
        iProId: 0,
        strProCode: "",
        strProName: "",
        iUnitId: 0,
        strUnitName: "",
        dPriceRate: (0).toFixed(2),
        dPriceRatewithTax: (0).toFixed(2),
        isItemDiscper: true,
        dItemDisc: (0).toFixed(2),
        isCashDiscper: true,
        dCashDisc: (0).toFixed(2),
        dQty: (0).toFixed(2),
        FQty: (0).toFixed(2),
        UnitList: [],
        iRowIndex: 0,
        bUpdateStatus: false,
        strBatchNo: "",
        dpManufactureDate: new Date().toISOString().split("T")[0],
        dpExpiryDate: new Date().toISOString().split("T")[0],
        JobName: "",
        JobId: 0,
        Reason: "",
        ItemRemark: [
          {
            strRemark1: "",
            strRemark2: "",
            strRemark3: "",
            strRemark4: "",
          },
        ],
        listRootCalc: [
          {
            dValue1: 0,
            dValue2: 0,
            dValue3: 0,
            dConstantValue: 0,
            dFinalValue: 0,
            strOp1: "",
            strOp2: "",
            strOp3: "",
          },
        ],
      })
    );
  };
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtTransNo":
          TabOrder.txtTransNo = EntProperties[i].iTabIndex;
          break;
        case "txtStoAdjNo":
          TabOrder.txtStoAdjNo = EntProperties[i].iTabIndex;
          break;
        case "dpStoAdjDate":
          TabOrder.dpStoAdjDate = EntProperties[i].iTabIndex;
          break;
        case "txtDescription":
          TabOrder.txtDescription = EntProperties[i].iTabIndex;
          break;
        case "btnNew":
          TabOrder.btnNew = EntProperties[i].iTabIndex;
          break;
        case "btnModify":
          TabOrder.btnModify = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder.btnSave = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder.btnClear = EntProperties[i].iTabIndex;
          break;
        case "btnView":
          TabOrder.btnView = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder.btnExportImport = EntProperties[i].iTabIndex;
          break;
        case "btnPrint":
          TabOrder.btnPrint = EntProperties[i].iTabIndex;
          break;
        case "btnCancel":
          TabOrder.btnCancel = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder.btnClose = EntProperties[i].iTabIndex;
          break;
        case "btnPrintSettings":
          TabOrder.btnPrintSettings = EntProperties[i].iTabIndex;
          break;
        case "btnHelp":
          TabOrder.btnHelp = EntProperties[i].iTabIndex;
          break;
        case "TopCenterVoucher":
          TabOrder.TopCenterVoucher = EntProperties[i].iTabIndex;
          break;
        case "TopRightDelivery":
          TabOrder.TopRightDelivery = EntProperties[i].iTabIndex;
          break;
        case "MiddleRightSummary":
          TabOrder.MiddleRightSummary = EntProperties[i].iTabIndex;
          break;
        case "lbProduct":
          TabOrder.ProductTableListTab = EntProperties[i].iTabIndex;
          break;
        case "txtProductName":
          TabOrder.txtProductName = EntProperties[i].iTabIndex;
          break;
        case "txtQty":
          TabOrder.txtQty = EntProperties[i].iTabIndex;
          break;
        case "cbUnit":
          TabOrder.cbUnit = EntProperties[i].iTabIndex;
          break;
        case "txtRate":
          TabOrder.txtRate = EntProperties[i].iTabIndex;
          break;
        case "txtReason":
          TabOrder.txtReason = EntProperties[i].iTabIndex;
          break;
        case "btnAdd":
          TabOrder.btnAdd = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const KeyDown = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (
          ActiveIndex + 1 === ListTabOrder.ProductTableListTab &&
          EntTransDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (
          ActiveIndex + 1 === ListTabOrder.ProductTableListTab &&
          EntTransDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (
          ActiveIndex - 1 === ListTabOrder.ProductTableListTab &&
          EntTransDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (
          maxTab - 1 === ListTabOrder.ProductTableListTab &&
          EntTransDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtStoAdjNo":
          ErrorMsg = strVouchNo === "" ? false : true;
          break;
        case "txtTransNo":
          ErrorMsg = strTransNo === "" ? false : true;
          break;
        case "txtDescription":
          ErrorMsg = strDescription === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const ButtonPermission = async () => {
    const AuthParems = await DecryptData(_AuthParems.jbctrlparams);
    let ListMenu = AuthParems.PurchasePer.filter(
      (Data) => Data.iScrId === iScrId
    )[0];
    setisBtnSave(iStoAdjId > 0 ? !ListMenu.isModify : !ListMenu.isCreate);
    setisBtnDelete(!ListMenu.isDelete);
    setisBtnView(!ListMenu.isView);
  };
  const TransColum = [
    {
      accessor: "sNo",
      Header: "SNo",
    },
    {
      accessor: "proCode",
      Header: "Code",
    },
    {
      accessor: "proName",
      Header: "Product Name",
    },
    {
      accessor: "qty",
      Header: "Qty",
    },
    {
      accessor: "rateWithoutTax",
      Header: "Rate",
    },
    {
      accessor: "goodsValue",
      Header: "Goods Value",
    },
    {
      accessor: "itemAmt",
      Header: "Amount",
    },
    {
      accessor: "reason",
      Header: "Reason",
    },
  ];
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Trans-container">
        <div className="Trans-card">
          <div className="Trans-wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-12 mt-1">
                <label>
                  Trans No <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  id="txtTransNo"
                  name="strTransNo"
                  readOnly
                  value={strTransNo}
                  onChange={(e) => setstrTransNo(e.target.value)}
                  tabIndex={ListTabOrder.txtTransNo}
                  autoFocus
                  onKeyDown={(e) => KeyDown(e)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>
                  Vocher no <span className="danger">*</span>
                </label>
                <div className="d-inline-flex " style={{ width: "100%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Voucher Number"
                    aria-label="Recipient's username"
                    value={strVouchNo}
                    aria-describedby="button-addon2"
                    id="txtVoucherNo"
                    name="strVouchNo"
                    onChange={(e) => setstrVouchNo(e.target.value)}
                    tabIndex={ListTabOrder.txtStoAdjNo}
                    onKeyDown={(e) => KeyDown(e)}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <label>
                  Date <span className="danger">*</span>
                </label>
                <input
                  type="date"
                  name="strVouchDate"
                  className="form-control"
                  value={strVouchDate}
                  onChange={(e) => setstrVouchDate(e.target.value)}
                  tabIndex={ListTabOrder.dpStoAdjDate}
                  onKeyDown={(e) => KeyDown(e)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Description</label>
                <textarea
                  className="form-control rounded"
                  aria-label="With textarea"
                  placeholder="Description"
                  style={{ height: "90px" }}
                  id="strDescription"
                  value={strDescription}
                  onChange={(e) => setstrDescription(e.target.value)}
                  tabIndex={ListTabOrder.txtDescription}
                  onKeyDown={(e) => KeyDown(e)}
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <label>Product Name</label>
                <div style={{ position: "relative" }}>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Select Product"
                    defaultValue={_Proparem.strProName}
                    onClick={(e) => setisProduct(!isProduct)}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      color: "darkgreen",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-1 col-sm-12">
                <label>Qty</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  name="dQty"
                  id="dQty"
                  value={_Proparem.dQty}
                  onChange={(e) =>
                    dispatch(StockQty_Onchange(Number(e.target.value)))
                  }
                  onClick={(e) => e.target.select()}
                  tabIndex={ListTabOrder.txtQty}
                  onKeyDown={(e) => KeyDown(e)}
                />
              </div>
              <div className="col-md-2 col-sm-12">
                <label>Unit</label>
                <div>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_selecrparem.isoptionShow}
                    placeholder="Unit"
                    select_value={(val) => dispatch(Change_Unit(Number(val)))}
                    taborder={ListTabOrder.setiUnitId}
                    keydown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown");
                      else KeyDown(e);
                    }}
                    btnname="Unit"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={_Proparem.iUnitId}
                    displayName="strUnitName"
                    disvalue="iUnitId"
                    arrydata={_Proparem.UnitList}
                    disabled={false}
                    EmptVal="Unit"
                  />
                </div>
              </div>
              <div className="col-md-1 col-sm-12">
                <label>Rate</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dRate"
                  id="dRate"
                  value={_Proparem.dPriceRate}
                  onChange={(e) =>
                    dispatch(
                      ProductRate_Onchange({
                        Name: e.target.value,
                        Value: e.target.value.toFixed(2),
                      })
                    )
                  }
                  onClick={(e) => e.target.select()}
                  tabIndex={ListTabOrder.txtRate}
                  onKeyDown={(e) => KeyDown(e)}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <label>Reason</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  name="strReason"
                  id="strReason"
                  value={_Proparem.Reason}
                  onChange={(e) =>
                    dispatch(StockAdjutment_Onchange(e.target.value))
                  }
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Adjustment Reason"
                  tabIndex={ListTabOrder.txtReason}
                  onKeyDown={(e) => KeyDown(e)}
                />
              </div>
              <div className="col-md-1 col-sm-12 d-flex justify-content-center align-items-center mt-4">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={AddProductClick}
                  tabIndex={ListTabOrder.btnAdd}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") AddProductClick();
                    else KeyDown(e);
                  }}
                  style={{
                    fontWeight: "bold",
                    fontSize: "10px",
                    borderRadius: "7px",
                    color: "darkgreen",
                  }}
                >
                  Add
                </button>
              </div>
            </div>
            <ReactTable
              columns={TransColum}
              data={EntTransDT}
              minRows={5}
              row_click={(rowInfo, Index) => {
                setselected(Index);
              }}
              row_doubleclick={(rowInfo, Index) => {
                AssignSelectedProduct(Index, rowInfo);
              }}
              background={true}
              tabIndex={12}
              className="full-table"
            />
            <div className="row">
              <div className="col-sm-12 col-md-3 mt-2">
                <label>Total Qty</label>
                <input
                  id="txtTotQty"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  value={dTQty}
                  readOnly
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-2">
                <label>Total Goods Value</label>
                <input
                  id="txtTotGoodsVal"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  value={dGoodVal}
                  readOnly
                />
              </div>
              <div className="col-sm-12 col-md-3 mt-2">
                <label>Round Off</label>
                <input
                  id="txtTotRF"
                  aria-label="First name"
                  className="form-control"
                  style={{ textAlign: "right" }}
                  value={dRoundOff}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-2">
                <div className="net-amount">
                  <h1>NET AMOUNT</h1>
                  <h2>
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "INR",
                    }).format(Number(dNetAmt).toFixed(2))}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              disabled={isBtnSave}
              onClick={(e) => {
                if (PropertiesValidation()) {
                  Ask_Question(
                    dispatch,
                    SaveClick,
                    iStoAdjId > 0
                      ? "Do You Want to Update Stock Adjustment?"
                      : "Do You Want to Save Stock Adjustment?"
                  );
                }
              }}
              tabIndex={ListTabOrder.btnSave}
              onKeyDown={(e) => KeyDown(e)}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex={ListTabOrder.btnClear}
              onKeyDown={(e) => KeyDown(e)}
              onClick={ClearClick}
            >
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              disabled={isBtnView}
              type="button"
              className="btn-fabgreen"
              onClick={(e) => setisTrans(!isTrans)}
              tabIndex={ListTabOrder.btnView}
              onKeyDown={(e) => KeyDown(e)}
            >
              View &nbsp; <i className="fas fa-eye"></i>
            </button>

            <button
              type="button"
              disabled={isBtnDelete}
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  DeleteClick,
                  "Do You Want to Delete Stock Adjustment?"
                );
              }}
              tabIndex={ListTabOrder.btnCancel}
              onKeyDown={(e) => KeyDown(e)}
            >
              Delete &nbsp; <i className="far fa-trash-alt"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex={ListTabOrder.btnClose}
              onKeyDown={(e) => KeyDown(e)}
              onClick={() => (window.location.href = "/JustBill/DashBoard")}
            >
              Close &nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
          {isTrans && (
            <CustomTransView
              Close={(e) => setisTrans(!isTrans)}
              View_Invoice={View_StcokAdjustment}
              ScrId={iScrId}
              Title="Stock AdjustMent"
            />
          )}
          {_Common.IsProSearch && (
            <MasterProductSearch
              iScrId={iScrId}
              strScrType="Purchase"
              iPartyId={0}
              iTaxRelId={1}
              disabled={false}
            />
          )}
          <Messagedialogbox />
        </div>
      </div>
    </>
  );
}

export default StockAdjustment;
