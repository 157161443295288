import React from "react";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import Messagedialogbox from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function LineDesign(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [strLineTempName, setstrLineTempName] = useState("");
  const [iLineTempId, setiLineTempId] = useState(0);

  const [iLineNameId, setiLineNameId] = useState("");
  const [strLineName, setstrLineName] = useState("");
  const [strLineColor, setstrLineColor] = useState("black");

  const [X1Position, setX1Position] = useState(0);
  const [Y1Position, setY1Position] = useState(0);
  const [Y2Position, setY2Position] = useState(0);
  const [X2Position, setX2Position] = useState(0);
  const [selected, setselected] = useState(0);

  const [LineTemplate, setLineTemplate] = useState([]);
  const [arrDesign, setarrDesign] = useState([]);
  const [isPrint, setisPrint] = useState(false);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_LinetTemplate("");
  }, []);
  const Load_LinetTemplate = async (strtxt) => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    setstrSearch(strtxt);
    const objsearch = {
      strSearch: strtxt,
      strColName: "",
      iBranchId: Number(_LocalParems.Branch),
      strDBName: _LocalParems.DB,
    };
    const res = await API_POST(
      `PrintDesing/Load_LinetTemplate/`,
      objsearch,
      dispatch
    );
    if (res) {
      setLineTemplate(res.EntLineTemplate);
    }
  };
  const Save_Linetemplate = async () => {
    const objLineModel = {
      iLineTempId: iLineTempId,
      strLineTempName: strLineTempName,
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      "PrintDesing/InsUpdLinetemplate/",
      objLineModel,
      dispatch
    );
    if (res) {
      Show_Message(dispatch, res.strMessage, "success");
      Clear_lineDesign();
    }
  };
  const Delete_Linetemplate = async () => {
    const res = await API_POST(
      "PrintDesing/Delete_Linetemplate/" + iLineTempId + "/" + _AuthParems.DB,
      dispatch
    );
    if (res) {
      Show_Message(dispatch, res.strMessage, "success");
      Load_LinetTemplate();
    }
  };
  const Save_LineDesign = async () => {
    if (strLineTempName === "") {
      Show_Message(dispatch, "Please Enter Line Template Name", "info");
    } else {
      const LineInsUpd = {
        iPrintLineTempId: 0,
        strPrintLineTempName: "null",
        iLineTempId: Number(iLineTempId),
        strLineTempName: strLineTempName,
        iLineNameId: Number(iLineNameId),
        strLineName: strLineName,
        strLineColor: strLineColor,
        dX1: X1Position,
        dY1: Y1Position,
        dX2: X2Position,
        dY2: Y2Position,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "PrintDesing/InsUpd_Linedesign/",
        LineInsUpd,
        dispatch
      );
      if (res) {
        Clear_lineDesign();
        View_LineTemplate(Number(iLineTempId));
        Show_Message(dispatch, res.strMessage, "success");
      }
    }
  };
  const Delete_LineDesign = async (Lineid) => {
    try {
      Pre_Loading(dispatch, true);
      const res = await API_POST(
        "PrintDesing/Delete_LineDesign/" +
          Number(Lineid) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        View_LineTemplate(Number(iLineTempId));
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Clear_lineDesign = () => {
    setX1Position(0);
    setY1Position(0);
    setY2Position(0);
    setX2Position(0);
    setiLineNameId(0);
    setstrLineName("");
    setstrLineColor("black");
    setstrLineTempName("");
    setiLineTempId(0);
    setarrDesign([]);
    Load_LinetTemplate("");
  };
  const View_LineTemplate = async (template) => {
    setiLineTempId(template.iLineTempId);
    setstrLineTempName(template.strLineTempName);
    const res = await API_FETCH(
      "PrintDesing/View_LineDesign/" +
        template.iLineTempId +
        "/" +
        Number(_AuthParems.Branch) +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      setarrDesign(res.EntLineDesign);
    }
  };
  const Modify_LineDesign = (LineInfo) => {
    setstrLineName(LineInfo.lineName);
    setX1Position(LineInfo.x1Pos);
    setX2Position(LineInfo.x2Pos);
    setY1Position(LineInfo.y1Pos);
    setY2Position(LineInfo.y2Pos);
    setiLineTempId(LineInfo.lineTempId);
    setiLineNameId(LineInfo.lineId);
    setstrLineColor(LineInfo.color);
  };
  const KeyDown = (e) => {
    var RowCount = arrDesign.length - 1;
    if (e.key === "ArrowDown") {
      if (RowCount === selected) setselected(0);
      else setselected(selected + 1);
    } else if (e.key === "ArrowUp") {
      if (selected === 0) setselected(RowCount);
      else setselected(selected - 1);
    } else if (e.key === "Enter") {
      Modify_LineDesign(arrDesign[selected]);
    }
  };
  const LineColums = [
    {
      Header: "Name",
      accessor: "lineName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "x1Pos",
      accessor: "x1Pos",
      style: { justifyContent: "center" },
    },
    {
      Header: "y1Pos",
      accessor: "y1Pos",
      style: { justifyContent: "center" },
    },
    {
      Header: "x2Pos",
      accessor: "x2Pos",
      style: { justifyContent: "center" },
    },
    {
      Header: "Y2Pos",
      accessor: "y2Pos",
      style: { justifyContent: "center" },
    },
    {
      id: "sNo",
      maxWidth: 50,
      style: { justifyContent: "center" },
      accessor: (RowInfo) => (
        <i
          onClick={(e) => {
            dispatch(
              Show_Message({
                Button: Delete_LineDesign,
                Question: "Do You Want to  Delete Line Design?",
                isdialog: true,
                Id: RowInfo.lineId,
              })
            );
          }}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];
  return (
    <>
      <SideMenu ScrId={152} />
      <div className="content">
        <div className="Master-card">
          <div className="m-header">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search Line Template"
                value={strSearch}
                onChange={(e) => Load_LinetTemplate(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Load_LinetTemplate(strSearch)}
              ></i>
            </div>
          </div>
          <div className="data-list">
            {LineTemplate.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = LineTemplate.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    View_LineTemplate(dt);
                  }
                }}
              >
                <label onClick={(e) => View_LineTemplate(dt)}>
                  {dt.strLineTempName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="main-content">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label>Line Template Name</label>
                <input
                  type="text"
                  className="form-control rounded"
                  placeholder="Enter Line Template Name"
                  value={strLineTempName}
                  onChange={(e) => setstrLineTempName(e.target.value)}
                  disabled={iLineNameId > 0 ? true : false}
                />
              </div>
              <div className="col-md-3 col-sm-6 p-2">
                <br />
                <button
                  className="btn-fabgreen"
                  disabled={
                    iLineTempId > 0 ? (iLineNameId > 0 ? true : false) : true
                  }
                  onClick={(e) => {
                    if (strLineTempName === "") {
                      Show_Message(
                        dispatch,
                        "Please Enter Template Name",
                        "info"
                      );
                    } else {
                      Ask_Question(
                        dispatch,
                        Save_Linetemplate,
                        "Do You Want to Save Line Template?"
                      );
                    }
                  }}
                >
                  Save Line Template&nbsp; <i className="fa fa-save"></i>
                </button>
              </div>
              <div className="col-md-3 col-sm-6 p-2">
                <br />
                <button
                  className="btn-fabgreen"
                  disabled={
                    iLineTempId > 0 ? (iLineNameId > 0 ? true : false) : true
                  }
                  onClick={(e) => {
                    if (iLineTempId === 0) {
                      Show_Message(
                        dispatch,
                        "Please Select Line template",
                        "info"
                      );
                    } else {
                      Ask_Question(
                        dispatch,
                        Delete_Linetemplate,
                        "Do You Want to Delete Line Template?"
                      );
                    }
                  }}
                >
                  Delete Line Template&nbsp;
                  <i className="far fa-trash-alt"></i>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-12 mt-1">
                <label>Line Name</label>
                <input
                  type="text"
                  className="form-control rounded"
                  placeholder="Enter Line Name"
                  value={strLineName}
                  onChange={(e) => setstrLineName(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>x1 Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Line Start Point"
                  value={X1Position}
                  onChange={(e) => setX1Position(Number(e.target.value))}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>x2 Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Line End Point"
                  value={X2Position}
                  onChange={(e) => setX2Position(Number(e.target.value))}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>y1 Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Height Start Point"
                  value={Y1Position}
                  onChange={(e) => setY1Position(Number(e.target.value))}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>y2 Position</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Height End Point"
                  value={Y2Position}
                  onChange={(e) => setY2Position(Number(e.target.value))}
                />
              </div>
              <div className="col-md-2 col-sm-12 mb-1">
                <label>
                  Color<span className="danger"></span>
                </label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow7"
                  showoption={_parem.isoptionShow7}
                  placeholder="Select Color"
                  select_value={(val) => setstrLineColor(val)}
                  defaultval={strLineColor}
                  arrydata={arrColor}
                  EmptVal="Color"
                />
              </div>
              <ReactTable
                columns={LineColums}
                data={arrDesign}
                minRows={5}
                row_click={(Design, Index) => {}}
                row_doubleclick={(Design, Index) => {
                  Modify_LineDesign(Design);
                }}
                background={true}
                className="full-table"
              />
              {isPrint && (
                <div className="popup-container">
                  <div className="popup-content">
                    <div className="popup-header">
                      <h5>{strLineTempName}</h5>
                      <div className="icon-popup-btn-grp">
                        <span
                          className="icon-btn"
                          onClick={(e) => setisPrint(!isPrint)}
                        >
                          <i className="fa-regular fa-rectangle-xmark"></i>
                        </span>
                      </div>
                    </div>
                    <div className="popup-body">
                      <div className="LineDesign">
                        {arrDesign.map((design, Index) => (
                          <hr
                            key={Index}
                            style={{
                              position: "absolute",
                              top: design.y1Pos + "px",
                              left: design.x1Pos + "px",
                              height:
                                design.y2Pos === design.y1Pos
                                  ? "1px"
                                  : design.y2Pos - design.y1Pos + "px",
                              width:
                                design.y2Pos === design.y1Pos
                                  ? design.x2Pos - design.x1Pos + "px"
                                  : "1px",
                              background: design.color,
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Messagedialogbox />
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                if (iLineTempId === 0) {
                  Show_Message(dispatch, "Please Select Line Template", "info");
                } else if (strLineName === "") {
                  Show_Message(dispatch, "Please Enter Line Name", "info");
                } else if (X1Position === 0) {
                  Show_Message(dispatch, "Please Enter x1 Position", "info");
                } else if (X2Position === 0) {
                  Show_Message(dispatch, "Please Enter x2 Position", "info");
                } else if (Y1Position === 0) {
                  Show_Message(dispatch, "Please Enter y1 Position", "info");
                } else if (Y2Position === 0) {
                  Show_Message(dispatch, "Please Enter y2 Position", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    Save_LineDesign,
                    "Do You Want to Save Line Design?"
                  );
                }
              }}
            >
              Save Line Design&nbsp; <i className="fa fa-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setisPrint(!isPrint)}
              disabled={iLineTempId === 0 ? true : false}
            >
              Print Line Design&nbsp; <i className="fas fa-print"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={Clear_lineDesign}
            >
              Clear Line Design&nbsp; <i className="fa-solid fa-broom"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/Others/PrintMaster")}
            >
              Close &nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LineDesign;
