import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "../General/Utility";
function ReportFilter(props) {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strMaxValue, setstrMaxValue] = useState(0);
  const [strMinValue, setstrMinValue] = useState(0);
  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iRptColId, setiRptColId] = useState(0);
  const [strFieldType, setstrFieldType] = useState("");
  const [MathSymbol, setMathSymbol] = useState(1);
  const [isDate, setisDate] = useState(false);
  const [isAmt, setisAmt] = useState(false);
  const [isTable, setisTable] = useState(false);
  const [isMinMaxVal, setisMinMaxVal] = useState(false);
  const [strSearch, setstrSearch] = useState("");
  const [IsUpdated, setIsUpdated] = useState(false);
  const [arrRptCol, setarrRptCol] = useState([]);
  const [arrFiltCol, setarrFiltCol] = useState([]);
  const [arrRptData, setarrRptData] = useState([]);
  const [arrMathSymbol, setarrMathSymbol] = useState([]);

  useEffect(() => {
    Load_ReportFilter();
  }, []);
  const Load_ReportFilter = async () => {
    try {
      const res = await API_FETCH(
        `Reports/Load_ReportFilter/${Number(props.iRptId)}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setarrRptCol(res.EntFilter);
        setarrMathSymbol(res.EntMathSymbol);
        var FilderList = res.EntFilter;
        if (props.iRptId === 1017) {
          FilderList = FilderList.map((Data) => {
            Data.strDataType = Data.strDataType.replace("\t", "");
            return Data;
          });
        }
        Load_FilterData(FilderList[0].iRptColId, FilderList[0].strDataType);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Update_Filter = async () => {
    try {
      let drChecked = "";
      let drUnChecked = "";
      if (strFieldType === "String") {
        drChecked = [];
        drUnChecked = [];
        arrRptData.forEach((dt) => {
          if (dt.Check) drChecked.push(dt.Id);
          else drUnChecked.push(dt.Id);
        });
        drChecked = drChecked.toString();
        drUnChecked = drUnChecked.toString();
      }
      const objFilter = {
        strDataType: strFieldType,
        RptId: Number(props.iRptId),
        RptColId: Number(iRptColId),
        MSId: Number(MathSymbol),
        MinValue: Number(strMinValue),
        MaxValue: Number(strMaxValue),
        FromDate: strFromDate,
        ToDate: strToDate,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
        strSearch: strSearch,
        strCheckedIds: drChecked,
        strUnCheckedIds: drUnChecked,
      };
      const res = await API_POST(
        `Reports/Update_ReportFilter`,
        objFilter,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Filter();
        setIsUpdated(true);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Delete_ReportFilter = async () => {
    try {
      const res = await API_POST(
        `Reports/Delete_ReportFilter/${Number(props.iRptId)}/${Number(
          iRptColId
        )}/${Number(_AuthParems.Branch)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Load_ReportFilter();
        setIsUpdated(true);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Clear_defaultvalues = () => {
    setarrRptData([]);
    setstrMaxValue(0);
    setstrMinValue(0);
    setMathSymbol(1);
  };
  const Clear_Filter = () => {
    Clear_defaultvalues();
    Load_FilterData(iRptColId, strFieldType);
  };
  const Load_FilterData = async (Colid, strColype) => {
    Clear_defaultvalues();
    setiRptColId(Number(Colid));
    setstrFieldType(strColype);
    const parems = {
      strColName: strColype,
      strSearch: "",
      iItemId: Number(Colid),
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      `Reports/Load_ReportFilterCol/`,
      parems,
      dispatch
    );
    if (res) {
      let arrFilter = [];
      arrFilter = res.EntFilterCol;
      switch (strColype) {
        case "decimal":
          setisAmt(true);
          setisDate(false);
          setisTable(false);
          if (Number(arrFilter[0].msId) === 6) {
            setisMinMaxVal(true);
            setstrMinValue(arrFilter[0].minValue);
            setstrMaxValue(arrFilter[0].maxValue);
          } else {
            setisMinMaxVal(false);
            setstrMinValue(arrFilter[0].minValue);
          }
          break;
        case "DateTime":
          setisDate(true);
          setisTable(false);
          setisAmt(false);
          setstrFromDate(arrFilter[0].fromDate);
          setstrToDate(arrFilter[0].toDate);
          break;
        default:
          arrFilter = JSON.parse(arrFilter);
          arrFilter.forEach((dtcol) => {
            dtcol.Check = Boolean(Number(dtcol.Check));
            dtcol.Id = Number(dtcol.Id);
            dtcol.SNo = Number(dtcol.SNo);
          });
          setisTable(true);
          setisDate(false);
          setisAmt(false);
          const filtercol = Object.keys(arrFilter[0]);
          setarrRptData(arrFilter);
          setarrFiltCol(filtercol);
          break;
      }
    }
  };
  const MathSymbolChange = (MathSymble) => {
    setMathSymbol(MathSymble);
    setisMinMaxVal(Number(MathSymble) === 6 ? true : false);
  };
  const table_Onchange = (rptcolid, val) => {
    let arrreport = arrRptData;
    arrreport.forEach((dtcol) => {
      if (dtcol.Id === rptcolid) {
        dtcol.Check = val;
      }
    });
    setarrRptData(arrreport);
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Report Filter</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={() => props.Close(IsUpdated)}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="row p-0">
              <div className="col-sm-12 col-md-6 mt-1">
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Filter Column"
                  select_value={(val) =>
                    Load_FilterData(val.iRptColId, val.strDataType)
                  }
                  btnname="Filter"
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iRptColId}
                  displayName="strAliasName"
                  disvalue="iRptColId"
                  arrydata={arrRptCol}
                  disabled={false}
                  EmptVal="Column"
                />
              </div>
              <div className="col-sm-12 col-md-6 mt-1">
                <div className="m-hsearch">
                  <input
                    type="search"
                    placeholder="Search Reports"
                    value={strSearch}
                    onChange={(e) => {
                      setstrSearch(e.target.value);
                    }}
                    autoFocus
                  />
                  <i
                    className="fas fa-search"
                    onClick={(e) => setstrSearch(strSearch)}
                  ></i>
                </div>
              </div>
            </div>
            {isAmt && (
              <div className="row">
                <div className="col-md-6 col-sm-6 mb-1">
                  <label>{isMinMaxVal ? "Min Amount" : "Amount"}</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Please Enter Value"
                    onClick={(e) => e.target.select()}
                    value={strMinValue}
                    onChange={(e) => setstrMinValue(e.target.value)}
                  />
                </div>
                {isMinMaxVal && (
                  <div className="col-md-6 col-sm-6 mb-1">
                    <label>Max Amount</label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Please Enter Value"
                      onClick={(e) => e.target.select()}
                      value={strMaxValue}
                      onChange={(e) => setstrMaxValue(e.target.value)}
                    />
                  </div>
                )}
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Math symbol</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_selecrparem.isoptionShow1}
                    placeholder="Math Symble"
                    select_value={(val) => MathSymbolChange(val)}
                    btnname="Math Symble"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={MathSymbol}
                    displayName="symbol"
                    disvalue="msId"
                    arrydata={arrMathSymbol}
                    disabled={false}
                    EmptVal="Math Symble"
                  />
                </div>
              </div>
            )}
            {isDate && (
              <div className="row">
                <div className="col-md-6 col-sm-6 mt-1">
                  <label>From</label>
                  <input
                    type="Date"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Please Enter Value"
                    value={strFromDate}
                    onChange={(e) => setstrFromDate(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 mt-1">
                  <label>To</label>
                  <input
                    type="Date"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Please Enter Value"
                    value={strToDate}
                    onChange={(e) => setstrToDate(e.target.value)}
                  />
                </div>
              </div>
            )}
            {isTable && (
              <table className="mt-1">
                <thead>
                  <tr>
                    {arrFiltCol.map((colname, Index) => (
                      <th key={Index}>{colname}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {arrRptData.map((dt, trIndex) => (
                    <tr key={trIndex}>
                      {arrFiltCol.map((colname, tdIndex) =>
                        colname !== "Check" ? (
                          <td key={tdIndex}>{dt[colname]}</td>
                        ) : (
                          <td
                            key={tdIndex}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-check"
                              defaultChecked={dt[colname]}
                              onChange={(e) =>
                                table_Onchange(dt.Id, e.target.checked)
                              }
                            />
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                Ask_Question(
                  dispatch,
                  Update_Filter,
                  "Do You Want to Create Filter?"
                )
              }
            >
              Save&nbsp;<i className="fas fa-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  Delete_ReportFilter,
                  "Do You Want to Delete All Filter?"
                );
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen " onClick={Clear_Filter}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReportFilter;
