import React from "react";
import SideMenu from "../General/SideMenu";
import Counter from "../Image/Counter.png";
import Organization from "../Image/Oraganization.png";
import PosTransaction from "./PosTransaction";
import { useState } from "react";
function POSMaster() {
  const [arrTabBtnshow, setarrTabBtnshow] = useState([true, false, false]);
  const tab_click = (tabindex) => {
    let line = document.getElementById("tab-line");
    line.style.left = (tabindex + 1 - 1) * 25 + "%";
    let EntTab = arrTabBtnshow.map((Dt, Index) => {
      if (Index === tabindex) {
        Dt = true;
      } else {
        Dt = false;
      }
      return Dt;
    });
    setarrTabBtnshow(EntTab);
  };

  return (
    <>
      <SideMenu />
      <div className="Org-Master">
        <div className="common-tab">
          <label onClick={(e) => tab_click(0)}>
            <img src={Organization} alt="Sub Organization"></img>
            Sub Organization
          </label>
          <label onClick={(e) => tab_click(1)}>
            <img src={Counter} alt="POS Counter" />
            Transaction
          </label>
          <label onClick={(e) => tab_click(2)}>
            <i className="fa-solid fa-user-tie"></i>
            Employee
          </label>
          <div id="tab-line"></div>
        </div>
        {arrTabBtnshow[0] && <div className="Org-Content"></div>}
        {arrTabBtnshow[1] && (
          <div className="Org-Content">
            <PosTransaction />
          </div>
        )}
      </div>
    </>
  );
}

export default POSMaster;
