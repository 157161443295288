import {
  fnTaxableAmt,
  fnItemAmt,
  fnItemDiscValuecal,
  fnCashDiscValue,
} from "../General/Formula";

const NetAmtCalculation = (
  objEntItems,
  OthCalWQty,
  bWithTaxConsider,
  iTaxCalcOnId
) => {
  let dItemDiscPer = 0,
    dItemDiscAmt = 0,
    dQty = 0,
    dRateWithOutTax = 0,
    dItemDiscValue = 0,
    dBillDiscValue = 0,
    dTaxPer = 0,
    dGoodsValue = 0,
    dTaxableAmt = 0,
    dTaxAmt = 0,
    dRateWithTax = 0,
    dItemAmt = 0,
    dFinalVal = 0,
    dCashDisPer = 0,
    dCashDisAmt = 0,
    dCashDiscValue = 0;
  // Add New Item Into Array
  objEntItems = objEntItems.map((Item) => {
    dItemDiscPer = Item.ItemDiscPer;
    dItemDiscAmt = Item.ItemDiscAmt;
    dCashDisPer = Item.CashDiscPer;
    dCashDisAmt = Item.CashDiscAmt;

    //#region Get standard values
    dQty = Math.abs(Item.Qty);
    dFinalVal = Math.abs(Item.FinalValue);
    dRateWithOutTax = Item.RateWithoutTax;

    // Calculate Without Tax Rate
    if (Item.RateWithTax > 0 && dRateWithOutTax === 0)
      dRateWithOutTax = Item.RateWithTax / (1 + Item.TaxPer / 100);
    //#region  Refresh ItemDiscValue
    Item.ItemDiscValue = fnItemDiscValuecal(
      "ItemDiscPer",
      dItemDiscPer,
      dItemDiscAmt,
      dQty * dRateWithOutTax
    );
    Item.ItemDiscPer = dItemDiscPer;
    Item.ItemDiscAmt = dItemDiscAmt;
    //#endregion

    dItemDiscValue = Item.ItemDiscValue;
    dBillDiscValue = Item.BillDiscValue;
    dTaxPer = Item.TaxPer;
    //#endregion

    //#region  Get Calculation value
    if (OthCalWQty === "Yes") dGoodsValue = dQty * dRateWithOutTax * dFinalVal;
    else dGoodsValue = dQty * dRateWithOutTax;

    //#region  Refresh CashDiscValue
    Item.CashDiscValue = fnCashDiscValue(
      "CashDiscPer",
      dCashDisPer,
      dCashDisAmt,
      dQty * dRateWithOutTax - Item.ItemDiscValue
    );
    Item.CashDiscPer = dCashDisPer;
    Item.CashDiscAmt = dCashDisAmt;
    dCashDiscValue = Item.CashDiscValue;
    //#endregion

    dTaxableAmt = fnTaxableAmt(
      iTaxCalcOnId,
      dGoodsValue,
      dItemDiscValue,
      dCashDiscValue,
      dBillDiscValue
    );
    dTaxAmt = (dTaxableAmt * dTaxPer) / 100;
    dRateWithTax = dRateWithOutTax + (dRateWithOutTax * dTaxPer) / 100;
    dItemAmt = fnItemAmt(
      iTaxCalcOnId,
      dTaxableAmt,
      dTaxAmt,
      dItemDiscValue,
      dCashDiscValue,
      dBillDiscValue
    );
    //#endregion

    //#region  Assign value
    if (bWithTaxConsider) Item.RateWithTax = Number(dRateWithTax.toFixed(2));
    Item.RateWithoutTax = Number(dRateWithOutTax.toFixed(2));
    Item.GoodsValue = Number(dGoodsValue.toFixed(2));
    Item.TaxableAmt = Number(dTaxableAmt.toFixed(2));
    Item.TaxAmt = Number(dTaxAmt.toFixed(2));
    Item.ItemAmt = Number(dItemAmt.toFixed(2));
    //#endregion

    return Item;
  });
  return objEntItems;
};
export default NetAmtCalculation;
