const NumbertoWord = (Number) => {
  // ========== file: /src/makeOrdinal.js ==========
  var ENDS_WITH_DOUBLE_ZERO_PATTERN =
    /(Hundred|Thousand|(M|B|Tr|Quadr)illion)$/;
  var ENDS_WITH_TEEN_PATTERN = /teen$/;
  var ENDS_WITH_Y_PATTERN = /y$/;
  var ENDS_WITH_ZERO_THROUGH_TWELVE_PATTERN =
    /(Zero|One|Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Eleven|Twelve)$/;
  var ordinalLessThanThirteen = {
    zero: "zeroth",
    one: "first",
    two: "second",
    three: "third",
    four: "fourth",
    five: "fifth",
    six: "sixth",
    seven: "seventh",
    eight: "eighth",
    nine: "ninth",
    ten: "tenth",
    eleven: "eleventh",
    twelve: "twelfth",
  };

  function makeOrdinal(words) {
    // Ends with *00 (100, 1000, etc.) or *teen (13, 14, 15, 16, 17, 18, 19)
    if (
      ENDS_WITH_DOUBLE_ZERO_PATTERN.test(words) ||
      ENDS_WITH_TEEN_PATTERN.test(words)
    ) {
      return words + "th";
    }
    // Ends with *y (20, 30, 40, 50, 60, 70, 80, 90)
    else if (ENDS_WITH_Y_PATTERN.test(words)) {
      return words.replace(ENDS_WITH_Y_PATTERN, "ieth");
    }
    // Ends with one through twelve
    else if (ENDS_WITH_ZERO_THROUGH_TWELVE_PATTERN.test(words)) {
      return words.replace(
        ENDS_WITH_ZERO_THROUGH_TWELVE_PATTERN,
        replaceWithOrdinalVariant
      );
    }
    return words;
  }

  function replaceWithOrdinalVariant(match, numberWord) {
    return ordinalLessThanThirteen[numberWord];
  }

  // ========== file: /src/toOrdinal.js ==========

  function toOrdinal(number) {
    var num = parseInt(number, 10);

    if (!isFinite(num)) {
      throw new TypeError(
        "Not a finite number: " + number + " (" + typeof number + ")"
      );
    }
    var str = String(num);
    var lastTwoDigits = Math.abs(num % 100);
    var betweenElevenAndThirteen = lastTwoDigits >= 11 && lastTwoDigits <= 13;
    var lastChar = str.charAt(str.length - 1);
    return (
      str +
      (betweenElevenAndThirteen
        ? "th"
        : lastChar === "1"
        ? "st"
        : lastChar === "2"
        ? "nd"
        : lastChar === "3"
        ? "rd"
        : "th")
    );
  }

  // ========== file: /src/toWords.js ==========

  var TEN = 10;
  var ONE_HUNDRED = 100;
  var ONE_THOUSAND = 1000;
  var ONE_LACK = 100000;
  var TEN_LAKH = 1000000;
  var ONE_CRORE = 10000000; //         1.000.000.000 (9)
  var ONE_TRILLION = 1000000000000; //     1.000.000.000.000 (12)
  var ONE_QUADRILLION = 1000000000000000; // 1.000.000.000.000.000 (15)
  var MAX = 9007199254740992; // 9.007.199.254.740.992 (15)

  var LESS_THAN_TWENTY = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  var TENTHS_LESS_THAN_HUNDRED = [
    "zero",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  function toWords(number, asOrdinal) {
    var words;
    var num = parseInt(number, 10);

    if (!isFinite(num)) {
      throw new TypeError(
        "Not a finite number: " + number + " (" + typeof number + ")"
      );
    }
    words = generateWords(num);
    return asOrdinal ? makeOrdinal(words) : words;
  }

  function generateWords(number) {
    var remainder,
      word,
      words = arguments[1];

    // We’re done
    if (number === 0) {
      return !words ? "zero" : words.join(" ").replace(/,$/, "");
    }
    // First run
    if (!words) {
      words = [];
    }
    // If negative, prepend “minus”
    if (number < 0) {
      words.push("minus");
      number = Math.abs(number);
    }

    if (number < 20) {
      remainder = 0;
      word = LESS_THAN_TWENTY[number];
    } else if (number < ONE_HUNDRED) {
      remainder = number % TEN;
      word = TENTHS_LESS_THAN_HUNDRED[Math.floor(number / TEN)];
      // In case of remainder, we need to handle it here to be able to add the “-”
      if (remainder) {
        word += " " + LESS_THAN_TWENTY[remainder];
        remainder = 0;
      }
    } else if (number < ONE_THOUSAND) {
      remainder = number % ONE_HUNDRED;
      word = generateWords(Math.floor(number / ONE_HUNDRED)) + " Hundred";
    } else if (number < ONE_LACK) {
      remainder = number % ONE_THOUSAND;
      word = generateWords(Math.floor(number / ONE_THOUSAND)) + " Thousand";
    } else if (number < TEN_LAKH) {
      remainder = number % ONE_LACK;
      word = generateWords(Math.floor(number / ONE_LACK)) + " Lakh";
    } else if (number < ONE_CRORE) {
      remainder = number % TEN_LAKH;
      word = generateWords(Math.floor(number / TEN_LAKH)) + " Lakh";
    } else if (number < ONE_TRILLION) {
      remainder = number % ONE_CRORE;
      word = generateWords(Math.floor(number / ONE_CRORE)) + " Crore";
    } else if (number < ONE_QUADRILLION) {
      remainder = number % ONE_TRILLION;
      word = generateWords(Math.floor(number / ONE_TRILLION)) + " Trillion";
    } else if (number <= MAX) {
      remainder = number % ONE_QUADRILLION;
      word =
        generateWords(Math.floor(number / ONE_QUADRILLION)) + " Quadrillion";
    }

    words.push(word);
    return generateWords(remainder, words);
  }
  return toWords(Number);
};
export default NumbertoWord;
