import { createSlice } from "@reduxjs/toolkit";
import JBLogo from "../Image/justBillRound.png";

const CommonStates = createSlice({
  name: "Common",
  initialState: {
    Admin: {
      isNewFy: false,
      Client: 0,
      User: 0,
      Role: 0,
      Rolename: "",
      Company: 0,
      OrgName: "",
      Branch: "",
      Branchname: "",
      Logo: "",
      Counter: 0,
      Session: 0,
      Year: 0,
      Fyear: "",
      NxtFY: "",
      DB: "",
      MasterPer: [],
      PurchasePer: [],
      InventoryPer: [],
      SalesPer: [],
      PosPer: [],
      GroupsPer: [],
      JobworkPer: [],
      AccountsPer: [],
      ReportscrPer: [],
      ToolsPer: [],
      OthersPer: [],
      CompanyPer: { iBackup: 0, iNoAccess: 0, iRestore: 0 },
      BranchPer: [],
      WarehousePer: [],
      CounterPer: [],
    },
  },
  reducers: {
    Update_AdminCntrl: (state, { payload }) => {
      const EntParems = payload;
      state.Admin.isNewFy = false;
      state.Admin.Client = Number(EntParems.Client);
      state.Admin.User = Number(EntParems.User);
      state.Admin.Role = Number(EntParems.Role);
      state.Admin.Rolename = EntParems.Rolename;
      state.Admin.Company = EntParems.Company;
      state.Admin.OrgName = EntParems.OrgName
        ? EntParems.OrgName.replace(/_/g, " ")
        : "";
      state.Admin.Branch = EntParems.Branch;
      state.Admin.Branchname = EntParems.Branchname;
      state.Admin.Logo = EntParems.Logo ? EntParems.Logo : JBLogo;
      state.Admin.Counter = EntParems.Counter;
      state.Admin.Session = EntParems.Session;
      state.Admin.Year = EntParems.Year;
      state.Admin.Fyear = EntParems.Fyear;
      state.Admin.NxtFY = EntParems.NxtFY;
      state.Admin.DB = EntParems.DB;

      state.Admin.MasterPer = EntParems.MasterPer;
      state.Admin.PurchasePer = EntParems.PurchasePer;
      state.Admin.InventoryPer = EntParems.InventoryPer;
      state.Admin.SalesPer = EntParems.SalesPer;
      state.Admin.PosPer = EntParems.PosPer;
      state.Admin.GroupsPer = EntParems.GroupsPer;
      state.Admin.JobworkPer = EntParems.JobworkPer;
      state.Admin.AccountsPer = EntParems.AccountsPer;
      state.Admin.ReportscrPer = EntParems.ReportscrPer;
      state.Admin.ToolsPer = EntParems.ToolsPer;
      state.Admin.OthersPer = EntParems.OthersPer;
      state.Admin.CompanyPer = EntParems.CompanyPer;
      state.Admin.BranchPer = EntParems.BranchPer;
      state.Admin.WarehousePer = EntParems.WarehousePer;
      state.Admin.CounterPer = EntParems.CounterPer;
    },
  },
});
export const { Update_AdminCntrl } = CommonStates.actions;
export default CommonStates.reducer;
