import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./Components/General/PrivateRoutes";
import UserLogin from "./Components/General/UserLogin";
import Dashboard from "./Components/General/DashBoard";

/* =================Master Screen============================================== */

import ProductMaster from "./Components/Master/ProductMaster";
import CustomerMaster from "./Components/Master/CustomerMaster";
import SupplierMaster from "./Components/Master/SupplierMaster";
import VendorMaster from "./Components/Master/VendorMaster";
import JobWorkMaster from "./Components/Master/JobWorkMaster";
import ServiceMaster from "./Components/Master/ServiceMaster";
import CategoryMaster from "./Components/Master/CategoryMaster";
import GSTTaxMaster from "./Components/Master/GSTTaxMaster";
import TaxMaster from "./Components/Master/TaxMaster";
import PriceMaster from "./Components/Master/PriceMaster";
import UnitMaster from "./Components/Master/UnitMaster";
import OtherHeadMaster from "./Components/Master/OtherHeadMaster";
import BankAccount from "./Components/Master/BankAccount";
import PriceGroupMaster from "./Components/Master/PriceGroupMaster";
import DiscountMaster from "./Components/Master/DiscountMaster";

/* ============================ POS Screen ====================================*/

import Pointofsales from "./Components/POS/Pointofsales";
import POSMaster from "./Components/POS/POSMaster";

/* =================Sales Screen============================================== */

import SalesInvoice from "./Components/Sales/SalesInvoice";
import SalesChallan from "./Components/Sales/SalesChallan";
import SalesOrder from "./Components/Sales/SalesOrder";
import SalesQuotation from "./Components/Sales/SalesQuotation";
import SalesEnquiry from "./Components/Sales/SalesEnquiry";
import SalesReturn from "./Components/Sales/SalesReturn";
import SalesEstimate from "./Components/Sales/SalesEstimate";

/* =================Purchase Screen============================================ */

import PurchaseInvoice from "./Components/Purchase/PurchaseInvoice";
import PurchaseChallan from "./Components/Purchase/PurchaseChallan";
import PurchaseOrder from "./Components/Purchase/PurchaseOrder";
import PurchaseQuotation from "./Components/Purchase/PurchaseQuotation";
import PurchaseEnquiry from "./Components/Purchase/PurchaseEnquiry";
import PurchaseReturn from "./Components/Purchase/PurchaseReturn";
import PurchaseEstimate from "./Components/Purchase/PurchaseEstimate";

/* =================Inventroy Screen============================================ */
import OpenStock from "./Components/Inventory/OpenStock";
import StockAdjustment from "./Components/Inventory/StockAdjustment";
import StockTransfer from "./Components/Inventory/StockTransfer";
import StockReceive from "./Components/Inventory/StockReceive";
import TransferAdjustment from "./Components/Inventory/TransferAdjustment";

/* =================Groups Screen============================================== */

import Attribute from "./Components/Groups/Attribute";
import AttributeGroup from "./Components/Groups/AttributeGroup";
import BillOfMaterial from "./Components/Groups/BillOfMaterial";
import RouteGroup from "./Components/Groups/RouteGroup";

/* =================JobWork Screen============================================== */

import InwardChallan from "./Components/JobWork/InwardChallan";
import OutwardChallan from "./Components/JobWork/OutwardChallan";
import OutsourceChallan from "./Components/JobWork/OutsourceChallan";
import InsourceChallan from "./Components/JobWork/InsourceChallan";
import OutwardInvoice from "./Components/JobWork/OutwardInvoice";
import InSInvoice from "./Components/JobWork/InsourceInvoice";
import ProductionOrder from "./Components/JobWork/ProductionOrder";
import JobAdjustment from "./Components/JobWork/TransactionAdjustment";
import Forecast from "./Components/JobWork/Forecast";

/* =================Accounts Screen============================================== */

import Receipt from "./Components/Accounts/Receipt";
import Payment from "./Components/Accounts/Payment";
import CreditNote from "./Components/Accounts/CreditNote";
import DebitNote from "./Components/Accounts/DebitNote";
import PaySettlement from "./Components/Accounts/PaymentSettlement";
import RecSettlement from "./Components/Accounts/ReceiptSettlement";
import ExpenseIncome from "./Components/Accounts/ExpenseIncome";
import JournalEntry from "./Components/Accounts/JournalEntry";
import JournalView from "./Components/Accounts/JournalView";

/* =================Reports Screen============================================== */

import GeneralReports from "./Components/Reports/GeneralReports";
import AccountsReport from "./Components/Reports/AccountsReport";

/* =================Others Screen============================================== */

import PrintMaster from "./Components/Print/PrintMaster";
import LineDesign from "./Components/Print/LineDesign";
import BarcodeGenerator from "./Components/Print/BarcodeGenerator";
import BarcodeSetting from "./Components/Print/BarcodeSetting";
import BarcodePrint from "./Components/Print/BarcodePrint";
import EWayBIlling from "./Components/General/EWayBIlling";
import LandingPage from "./Components/General/LandingPage";
import CompanyMaster from "./Components/Organization/CompanyMaster";
import ConfigurationSetting from "./Components/Organization/ConfigurationSetting";
import PageNotExists from "./Components/General/PageNotExists";
import Compositeproduct from "./Components/Master/Compositeproduct";
import ControlPanel from "./Components/Organization/ControlPanel";
import ManualInvoice from "./Components/ManualInvoice/ManualInvoice";
import DoctorMaster from "./Components/Master/DoctorMaster";
import OrderBooking from "./Components/POS/OrderBooking";
import DoctorSessionMaster from "./Components/Master/DoctorSessionMaster";
import UserMaster from "./Components/Organization/UserMaster";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/User-Login" element={<UserLogin />} />
        <Route path="/Admin/Control-Panel" element={<ControlPanel />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/JustBill/DashBoard" element={<Dashboard />} />
          <Route path="/Profile/Settings" element={<ConfigurationSetting />} />
          <Route path="/Profile/CompanyMaster" element={<CompanyMaster />} />
          <Route path="/POS/POSMaster" element={<POSMaster />} />
          <Route path="/Master/Product" element={<ProductMaster />} />
          <Route path="/Master/Compoproduct" element={<Compositeproduct />} />
          <Route path="/Master/Customer" element={<CustomerMaster />} />
          <Route path="/Master/Supplier" element={<SupplierMaster />} />
          <Route path="/Master/Vendor" element={<VendorMaster />} />
          <Route path="/Master/JobWork" element={<JobWorkMaster />} />
          <Route path="/Master/Service" element={<ServiceMaster />} />
          <Route path="/Master/Category" element={<CategoryMaster />} />
          <Route path="/Master/Tax" element={<TaxMaster />} />
          <Route path="/Master/Price" element={<PriceMaster />} />
          <Route path="/Master/Unit" element={<UnitMaster />} />
          <Route path="/Master/OtherHeadMaster" element={<OtherHeadMaster />} />
          <Route path="/Master/GSTTax" element={<GSTTaxMaster />} />
          <Route path="/Master/BankAccount" element={<BankAccount />} />
          <Route path="/Master/PriceGroup" element={<PriceGroupMaster />} />
          <Route path="/Master/DoctorMaster" element={<DoctorMaster />} />
          <Route
            path="/Master/DoctorSessionMaster"
            element={<DoctorSessionMaster />}
          />
          <Route path="/Master/Discount" element={<DiscountMaster />} />
          <Route path="/Pointofsales/Invoice" element={<Pointofsales />} />
          <Route path="/Pointofsales/OrderBooking" element={<OrderBooking />} />
          <Route path="/Sales/Invoice" element={<SalesInvoice />} />
          <Route path="/Sales/Challan" element={<SalesChallan />} />
          <Route path="/Sales/Order" element={<SalesOrder />} />
          <Route path="/Sales/Quotation" element={<SalesQuotation />} />
          <Route path="/Sales/Enquiry" element={<SalesEnquiry />} />
          <Route path="/Sales/Return" element={<SalesReturn />} />
          <Route path="/Sales/Estimate" element={<SalesEstimate />} />
          <Route path="/Sales/ManualInvoice" element={<ManualInvoice />} />

          <Route path="/Purchase/Invoice" element={<PurchaseInvoice />} />
          <Route path="/Purchase/Challan" element={<PurchaseChallan />} />
          <Route path="/Purchase/Order" element={<PurchaseOrder />} />
          <Route path="/Purchase/Quotation" element={<PurchaseQuotation />} />
          <Route path="/Purchase/Enquiry" element={<PurchaseEnquiry />} />
          <Route path="/Purchase/Return" element={<PurchaseReturn />} />
          <Route path="/Purchase/Estimate" element={<PurchaseEstimate />} />

          <Route path="/Inventory/OpenStock" element={<OpenStock />} />
          <Route path="/Inventory/StockTransfer" element={<StockTransfer />} />
          <Route path="/Inventory/Adjustment" element={<StockAdjustment />} />
          <Route path="/Inventory/StockReceive" element={<StockReceive />} />
          <Route
            path="/Inventory/TransferAdjustment"
            element={<TransferAdjustment />}
          />

          <Route path="/Attribute" element={<Attribute />} />
          <Route path="/AttributeGroup" element={<AttributeGroup />} />
          <Route path="/BillOfMaterial" element={<BillOfMaterial />} />
          <Route path="/RouteGroup" element={<RouteGroup />} />

          <Route path="/Jobwork/InwardChallan" element={<InwardChallan />} />
          <Route path="/Jobwork/OutwardChallan" element={<OutwardChallan />} />
          <Route path="/Jobwork/OutsourceDC" element={<OutsourceChallan />} />
          <Route path="/Jobwork/InsourceDC" element={<InsourceChallan />} />
          <Route path="/Jobwork/OutwardInvoice" element={<OutwardInvoice />} />
          <Route path="/Jobwork/InsourceInvoice" element={<InSInvoice />} />
          <Route path="/Jobwork/Production" element={<ProductionOrder />} />
          <Route path="/Jobwork/Adjustment" element={<JobAdjustment />} />
          <Route path="/Jobwork/Forecast" element={<Forecast />} />

          <Route path="/Accounts/Receipt" element={<Receipt />} />
          <Route path="/Accounts/Payment" element={<Payment />} />
          <Route path="/Accounts/CreditNote" element={<CreditNote />} />
          <Route path="/Accounts/DebitNote" element={<DebitNote />} />
          <Route path="/Accounts/PaySettlement" element={<PaySettlement />} />
          <Route path="/Accounts/RecSettlement" element={<RecSettlement />} />
          <Route path="/Accounts/ExpenseIncome" element={<ExpenseIncome />} />
          <Route path="/Accounts/JournalEntry" element={<JournalEntry />} />
          <Route path="/Accounts/JournalView" element={<JournalView />} />

          <Route path="/Reports/AccountsReport" element={<AccountsReport />} />
          <Route path="/Reports/GeneralReports" element={<GeneralReports />} />

          <Route path="/LineDesign" element={<LineDesign />} />
          <Route path="/Others/Barcode" element={<BarcodeGenerator />} />
          <Route path="/Others/BarcodeSetting" element={<BarcodeSetting />} />
          <Route path="/Others/PrintMaster" element={<PrintMaster />} />
          <Route path="/UserAccount" element={<UserMaster />} />
          <Route path="/EWayBIlling" element={<EWayBIlling />} />
          <Route path="/BarcodePrint" element={<BarcodePrint />} />

          <Route path="*" element={<PageNotExists />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
