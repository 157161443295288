import { useEffect, useState } from "react";
import SideMenu from "./SideMenu";

import { API_FETCH, DecryptData } from "./Utility";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "./ReactTable";
function EWayBIlling(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [isNewInvoiceCreate, setisNewInvoiceCreate] = useState(false);
  const [selected, setselected] = useState("");
  const [iVouchId, setiVouchId] = useState(0);
  const [iPartyId, setiPartyId] = useState(0);
  const [IsLoding, setIsLoding] = useState(false);
  const [strMessage, setstrMessage] = useState("");
  const [strIcon, setstrIcon] = useState("");
  const [IsNotification, setIsNotification] = useState(false);
  const [IsDialogOpen, setIsDialogOpen] = useState(false);
  const [IsDialogFor, setIsDialogFor] = useState("");
  const [strQuestion, setstrQuestion] = useState("");
  // E-way Parameters
  const [iScrId, setiScrId] = useState(17);
  const [supplyType, setsupplyType] = useState("");
  const [subSupplyType, setsubSupplyType] = useState("");
  const [subSupplyDesc, setsubSupplyDesc] = useState("");
  const [docType, setdocType] = useState("");
  const [docNo, setdocNo] = useState("");
  const [docDate, setdocDate] = useState("");
  const [fromGstin, setfromGstin] = useState("");
  const [fromTrdName, setfromTrdName] = useState("");
  const [fromAddr1, setfromAddr1] = useState("");
  const [fromAddr2, setfromAddr2] = useState("");
  const [fromPlace, setfromPlace] = useState("");
  const [fromPincode, setfromPincode] = useState("");
  const [actFromStateCode, setactFromStateCode] = useState("");
  const [fromStateCode, setfromStateCode] = useState("");
  const [toGstin, settoGstin] = useState("");
  const [toTrdName, settoTrdName] = useState("");
  const [toAddr1, settoAddr1] = useState("");
  const [toAddr2, settoAddr2] = useState("");
  const [toPlace, settoPlace] = useState("");
  const [toPincode, settoPincode] = useState("");
  const [actToStateCode, setactToStateCode] = useState("");
  const [toStateCode, settoStateCode] = useState("");
  const [transactionType, settransactionType] = useState("");
  const [otherValue, setotherValue] = useState("");
  const [totalValue, settotalValue] = useState("");
  const [cgstValue, setcgstValue] = useState("");
  const [sgstValue, setsgstValue] = useState("");
  const [igstValue, setigstValue] = useState("");
  const [cessValue, setcessValue] = useState("");
  const [cessNonAdvolValue, setcessNonAdvolValue] = useState("");
  const [totInvValue, settotInvValue] = useState("");
  const [transporterId, settransporterId] = useState("");
  const [transporterName, settransporterName] = useState("");
  const [transDocNo, settransDocNo] = useState("");
  const [transMode, settransMode] = useState("");
  const [transDistance, settransDistance] = useState("");
  const [transDocDate, settransDocDate] = useState("");
  const [vehicleNo, setvehicleNo] = useState("");
  const [vehicleType, setvehicleType] = useState("");

  const [ListEntInvoice, setListEntInvoice] = useState([]);
  const [ListEntItem, setListEntItem] = useState([]);

  useEffect(() => {
    GetInvoiceData(iScrId);
  }, []);
  const GetInvoiceData = async (ScrId) => {
    setiScrId(ScrId);
    setIsLoding(true);
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const res = await API_FETCH(
      "Transaction/GetVouchers/" + ScrId + "/" + _LocalParems.DB
    );
    if (res) {
      setListEntInvoice(res.objVouchers);
    }
  };
  const View_Voucher = async (VouchId) => {
    let ControllerUrl = "";
    switch (iScrId) {
      case 11:
        setsupplyType("I");
        ControllerUrl = "PurchaseInvoice/GetVoucherDetails/";
        break;
      case 73:
        setsupplyType("O");
        ControllerUrl = "OutwardInvoice/GetVoucherDetails/";
        break;
      case 76:
        setsupplyType("I");
        ControllerUrl = "InsourceInvoice/GetVoucherDetails/";
        break;
      default:
        setsupplyType("O");
        ControllerUrl = "SalesInvoice/GetVoucherDetails/";
        break;
    }
    setIsLoding(true);
    const res = await API_FETCH(
      [`${ControllerUrl}${VouchId}/${_AuthParems.DB}`],
      [`Home/GetCompanyInfo/${Number(localStorage.iCompId)}`],
      dispatch
    );
    if (res) {
      const Response = await API_FETCH(
        "Transaction/View_SateCode/" +
          res[0].data.objEntHT[0].iPartyId +
          "/" +
          iScrId +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (Response) {
        AssignVoucher_Data(
          res[0].data,
          res[1].data.objCompanyInfo[0],
          Response.data.StateCode
        );
      }
    }
  };
  const AssignVoucher_Data = (VoucherInfo, CompanyInfo, StateCode) => {
    // Company Details
    setfromTrdName(CompanyInfo.strCompName);
    setfromAddr1(CompanyInfo.strAdd1 + "," + CompanyInfo.strAdd2);
    setfromAddr2(CompanyInfo.strAdd3);
    setfromGstin(CompanyInfo.strGST);
    setfromPincode(CompanyInfo.iPincode);
    setfromPlace(CompanyInfo.strAdd4);
    setfromStateCode(CompanyInfo.iSName);
    setactFromStateCode(CompanyInfo.iSName);

    // To Party or Trade Details
    settoTrdName(VoucherInfo.objPartyList[0]);
    var partyAdd = VoucherInfo.objPartyList[1].split("\r\n");
    if (partyAdd.length > 1) {
      settoAddr1(partyAdd[0] + "," + partyAdd[1]);
      settoAddr2(partyAdd[2]);
      settoPlace(partyAdd[3]);
      var partyPin = partyAdd[4].includes("Pin-")
        ? parseInt(partyAdd[4].replace("Pin-", ""))
        : "";
      settoPincode(partyPin);
    }
    settoStateCode(StateCode);
    setactToStateCode(StateCode);
    settoGstin(VoucherInfo.objPartyList[3]);
    setdocNo(VoucherInfo.objEntHT[0].strVouchNo);
    setdocDate(VoucherInfo.objEntHT[0].strVouchDate);
    setvehicleNo(VoucherInfo.objEntHT[0].strVehicle);
    settransDocDate(VoucherInfo.objEntHT[0].dpDespatchDate);
    settransDocNo(VoucherInfo.objEntHT[0].strDespatch);

    // Total Invocie Discound,taxValue,Taxable Value
    settotalValue(VoucherInfo.objEntSummary[0].goodsValue.toFixed(2));
    settotInvValue(VoucherInfo.objEntSummary[0].netAmt.toFixed(2));
    setcessValue((0).toFixed(2));
    setcessNonAdvolValue((0).toFixed(2));
    let otheVal =
      VoucherInfo.objEntSummary[0].totalDiscount -
      VoucherInfo.objEntSummary[0].totalAddLess +
      VoucherInfo.objEntSummary[0].roundOff;

    setotherValue(otheVal.toFixed(2));
    let Taxypeid = VoucherInfo.objEntHT[0].iTaxTypeId;
    switch (Taxypeid) {
      case 2:
        setigstValue(VoucherInfo.objEntSummary[0].totalTax.toFixed(2));
        setcgstValue((0).toFixed(2));
        setsgstValue((0).toFixed(2));
        break;
      default:
        setigstValue((0).toFixed(2));
        setcgstValue((VoucherInfo.objEntSummary[0].totalTax / 2).toFixed(2));
        setsgstValue((VoucherInfo.objEntSummary[0].totalTax / 2).toFixed(2));
        break;
    }
    // Item Detailes Assign
    let EntItem = [];
    VoucherInfo.objEntDT.map((Data) => {
      const objItem = {
        productName: Data.proName,
        productDesc: Data.remark1 + Data.remark2 + Data.remark3 + Data.remark4,
        hsnCode: Data.commodityCode,
        quantity: Data.qty,
        qtyUnit: Data.unitName,
        cgstRate:
          Taxypeid === 1 ? (Data.taxPer / 2).toFixed(2) : (0).toFixed(2),
        sgstRate:
          Taxypeid === 1 ? (Data.taxPer / 2).toFixed(2) : (0).toFixed(2),
        igstRate: Taxypeid === 1 ? (0).toFixed(2) : Data.taxPer.toFixed(2),
        cessRate: 0,
        cessNonadvol: 0,
        taxableAmount: Data.taxableAmt,
      };
      EntItem.push(objItem);
      return Data;
    });
    setListEntItem(EntItem);
  };
  const MsgBox = (Message, Icon) => {
    setIsNotification(!IsNotification);
    setstrMessage(Message);
    setstrIcon(Icon);
  };
  const ListScreenType = [
    { ScrType: "Sales Invocie", ScrId: 17 },
    { ScrType: "Outward Invocie", ScrId: 73 },
    { ScrType: "Purchase Invocie", ScrId: 11 },
    { ScrType: "InSource Invocie", ScrId: 76 },
  ];
  const ListSupplyType = [{ SupTyp: "O" }, { SupTyp: "I" }];

  const ListdocType = [
    { docType: "INV" },
    { docType: "CHL" },
    { docType: "BIL" },
    { docType: "BOE" },
    { docType: "OTH" },
  ];
  const ListTransportMode = [
    { TransType: 1, TransTypeName: "Road" },
    { TransType: 2, TransTypeName: "Rail" },
    { TransType: 3, TransTypeName: "Air" },
    { TransType: 4, TransTypeName: "Ship" },
  ];
  const E_InvoiceJson = [
    {
      Version: "1.1",
      TranDtls: {
        TaxSch: "GST",
        SupTyp: "B2B",
        IgstOnIntra: "N",
        RegRev: null,
        EcmGstin: null,
      },
      DocDtls: {
        Typ: "INV",
        No: "KSB-008",
        Dt: "05/12/2023",
      },
      SellerDtls: {
        Gstin: "33HAAPS5220R1ZA",
        LglNm: "KSB CORPORATION PVT LTD",
        TrdNm: null,
        Addr1: "20/A,MIDDLE STREET",
        Addr2: "OKKANADU KEELAIYUR",
        Loc: "COIMBATORE",
        Pin: 614625,
        Stcd: "33",
        Ph: "",
        Em: "ksbcorporationoffical@gmail.com",
      },
      BuyerDtls: {
        Gstin: "33AVYPM1084B1ZJ",
        LglNm: "FABGREEN TECHNOLOGIES",
        TrdNm: null,
        Pos: "33",
        Addr1: "5th Cross",
        Addr2: "Om Ganesh Nagar",
        Loc: "Vadavalli,Coimbatore",
        Pin: 641041,
        Stcd: "33",
        Ph: "9360062917",
        Em: null,
      },
      ValDtls: {
        AssVal: 5000,
        IgstVal: 0,
        CgstVal: 300,
        SgstVal: 300,
        CesVal: 0,
        StCesVal: 0,
        Discount: 0,
        OthChrg: 0,
        RndOffAmt: 0,
        TotInvVal: 5600,
      },
      ItemList: [
        {
          SlNo: "1",
          PrdDesc: "Apple",
          IsServc: "N",
          HsnCd: "7895",
          Qty: 20,
          Unit: "PCS",
          UnitPrice: 250,
          TotAmt: 5000,
          Discount: 0,
          PreTaxVal: 0,
          AssAmt: 5000,
          GstRt: 12,
          IgstAmt: 0,
          CgstAmt: 300,
          SgstAmt: 300,
          CesRt: 0,
          CesAmt: 0,
          CesNonAdvlAmt: 0,
          StateCesRt: 0,
          StateCesAmt: 0,
          StateCesNonAdvlAmt: 0,
          OthChrg: 0,
          TotItemVal: 5600,
        },
      ],
    },
    {
      Version: "1.1",
      TranDtls: {
        TaxSch: "GST",
        SupTyp: "B2B",
        IgstOnIntra: "N",
        RegRev: null,
        EcmGstin: null,
      },
      DocDtls: {
        Typ: "INV",
        No: "KSB-009",
        Dt: "05/12/2023",
      },
      SellerDtls: {
        Gstin: "33HAAPS5220R1ZA",
        LglNm: "KSB CORPORATION PVT LTD",
        TrdNm: null,
        Addr1: "20/A,MIDDLE STREET",
        Addr2: "OKKANADU KEELAIYUR",
        Loc: "COIMBATORE",
        Pin: 614625,
        Stcd: "33",
        Ph: "",
        Em: "ksbcorporationoffical@gmail.com",
      },
      BuyerDtls: {
        Gstin: "33AVYPM1084B1ZJ",
        LglNm: "FABGREEN TECHNOLOGIES",
        TrdNm: null,
        Pos: "33",
        Addr1: "6th Cross",
        Addr2: "Om Ganesh Nagar",
        Loc: "Vadavalli,Coimbatore",
        Pin: 641042,
        Stcd: "33",
        Ph: "9360062917",
        Em: null,
      },
      ValDtls: {
        AssVal: 5000,
        IgstVal: 0,
        CgstVal: 300,
        SgstVal: 300,
        CesVal: 0,
        StCesVal: 0,
        Discount: 0,
        OthChrg: 0,
        RndOffAmt: 0,
        TotInvVal: 5600,
      },
      ItemList: [
        {
          SlNo: "2",
          PrdDesc: "Mango",
          IsServc: "N",
          HsnCd: "8796",
          Qty: 20,
          Unit: "PCS",
          UnitPrice: 250,
          TotAmt: 5000,
          Discount: 0,
          PreTaxVal: 0,
          AssAmt: 5000,
          GstRt: 12,
          IgstAmt: 0,
          CgstAmt: 300,
          SgstAmt: 300,
          CesRt: 0,
          CesAmt: 0,
          CesNonAdvlAmt: 0,
          StateCesRt: 0,
          StateCesAmt: 0,
          StateCesNonAdvlAmt: 0,
          OthChrg: 0,
          TotItemVal: 5600,
        },
      ],
    },
  ];
  const EwayJosnObject = {
    $schema: "http://json-schema.org/draft-04/schema#",
    type: "object",
    properties: {
      supplyType: {
        type: "string",
        maxLength: 1,
        minLength: 1,
        enum: ["O", "I"],
        description: "Supply Type",
      },
      subSupplyType: {
        type: "string",
        description: "Sub Supply Type",
      },
      subSupplyDesc: {
        type: "string",
        maxLength: 20,
        description: "Other Sub Supply Description",
      },
      docType: {
        type: "string",
        enum: ["INV", "CHL", "BIL", "BOE", "OTH"],
        description: "Document Type",
      },
      docNo: {
        type: "string",
        maxLength: 16,
        description: "Document Number (Alphanumeric with / and - are allowed)",
      },
      docDate: {
        type: "string",
        pattern: "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
        description: "Document Date",
      },
      fromGstin: {
        type: "string",
        maxLength: 15,
        minLength: 15,
        pattern: "[0-9]{2}[0-9|A-Z]{13}",
        description: "From GSTIN (Supplier or Consignor)",
      },
      fromTrdName: {
        type: "string",
        maxLength: 100,
        description: "From Trade Name (Consignor Trade name)",
      },
      fromAddr1: {
        type: "string",
        maxLength: 120,
        description: "From Address Line 1 (Valid Special Chars #,-,/)",
      },
      fromAddr2: {
        type: "string",
        maxLength: 120,
        description: "From Address Line 2(Valid Special Chars # , - ,/)",
      },
      fromPlace: {
        type: "string",
        maxLength: 50,
        description: "From Place",
      },
      actFromStateCode: {
        type: "integer",
        maximum: 99,
        description: "Actual From State Code",
      },
      fromPincode: {
        type: "integer",
        maximum: 999999,
        minimum: 100000,
        description: "From Pincode",
      },
      fromStateCode: {
        type: "integer",
        maximum: 99,
        description: "From State Code",
      },
      toGstin: {
        type: "string",
        maxLength: 15,
        minLength: 15,
        pattern: "[0-9]{2}[0-9|A-Z]{13}",
        description: "To GSTIN (Consignee or Recipient)",
      },
      toTrdName: {
        type: "string",
        maxLength: 100,
        description:
          "To Trade Name (Consignee Trade name or Recipient Trade name)",
      },
      toAddr1: {
        type: "string",
        maxLength: 120,
        description: "To Address Line 1 (Valid Special Chars #,-,/)",
      },
      toAddr2: {
        type: "string",
        maxLength: 120,
        description: "To Address Line 2 (Valid Special Chars #,-,/)",
      },
      toPlace: {
        type: "string",
        maxLength: 50,
        description: "To Place",
      },
      toPincode: {
        type: "integer",
        description: "To Pincode",
      },
      actToStateCode: {
        type: "integer",
        maximum: 99,
        description: "Actual To State Code",
      },
      toStateCode: {
        type: "integer",
        maximum: 99,
        description: "To State Code",
      },
      transactionType: {
        type: "integer",
        maximum: 4,
        description: "Transaction type",
      },
      totalValue: {
        type: "number",
        multipleOf: 0.01,
        description: "Sum of Taxable value ",
      },
      cgstValue: {
        type: "number",
        multipleOf: 0.01,
        description: "CGST value",
      },
      sgstValue: {
        type: "number",
        multipleOf: 0.01,
        description: "SGST value",
      },
      igstValue: {
        type: "number",
        multipleOf: 0.01,
        description: "IGST value",
      },
      cessValue: {
        type: "number",
        multipleOf: 0.01,
        description: "Cess value",
      },
      cessNonAdvolValue: {
        type: "number",
        multipleOf: 0.01,
        description: "Cess Non Advol value",
      },
      otherValue: {
        type: "number",
        multipleOf: 0.01,
        description: "Other charges, if any ",
      },
      totInvValue: {
        type: "number",
        multipleOf: 0.01,
        description:
          "Total Invoice Value (Including taxable value, tax value,and other charges if any)",
      },
      transMode: {
        type: "string",
        enum: ["1", "2", "3", "4"],
        description: "Mode of transport (Road-1, Rail-2, Air-3, Ship-4) ",
      },
      transDistance: {
        type: "string",
        description: "Distance (<4000 km) ",
      },
      transporterName: {
        type: "string",
        maxLength: 100,
        description: "Name of the transporter",
      },
      transporterId: {
        type: "string",
        pattern: "[0-9]{2}[0-9|A-Z]{13}",
        description: "15 Digit Transporter GSTIN/TRANSIN",
      },
      transDocNo: {
        type: "string",
        maxLength: 15,
        description:
          "Transport Document Number (Alphanumeric with / and – are allowed)",
      },
      transDocDate: {
        type: "string",
        description: "Transport Document Date",
      },
      vehicleNo: {
        type: "string",
        minLength: 7,
        maxLength: 15,
        description: "Vehicle Number",
      },
      vehicleType: {
        type: "string",
        description: "Vehicle Type",
      },
      itemList: {
        type: "array",
        items: [
          {
            type: "object",
            properties: {
              productName: {
                type: "string",
                maxLength: 100,
                description: "Product / Item Name",
              },
              productDesc: {
                type: "string",
                maxLength: 100,
                description: "Product / Item description",
              },
              hsnCode: {
                type: "number",
                description: "HSN Code",
              },
              quantity: {
                type: "number",
                description: "Quantity",
              },
              qtyUnit: {
                type: "string",
                maxLength: 3,
                minLength: 3,
                description: "Unit",
              },
              taxableAmount: {
                type: "number",
                multipleOf: 0.01,
                description: "Taxable Amount",
              },
              sgstRate: {
                type: "number",
                multipleOf: 0.001,
                description: "SGST Rate of Tax",
              },
              cgstRate: {
                type: "number",
                multipleOf: 0.001,
                description: "CGST Rate of Tax",
              },
              igstRate: {
                type: "number",
                multipleOf: 0.001,
                description: "IGST Rate of Tax",
              },
              cessRate: {
                type: "number",
                multipleOf: 0.001,
                description: "Cess Rate of Tax",
              },
              cessNonadvol: {
                type: "number",
                description: "Cess Non-Advolerum",
              },
            },
            required: ["hsnCode", "taxableAmount"],
          },
        ],
      },
    },
    required: [
      "supplyType",
      "subSupplyType",
      "docType",
      "docNo",
      "docDate",
      "fromGstin",
      "fromPincode",
      "fromStateCode",
      "toGstin",
      "toPincode",
      "toStateCode",
      "transDistance",
      "itemList",
      "actToStateCode",
      "actFromStateCode",
      "totInvValue",
      "transactionType",
    ],
  };
  const UserRequest = {
    supplyType: "O",
    subSupplyType: "1",
    subSupplyDesc: "",
    docType: "INV",
    docNo: "7001-8",
    docDate: "15/12/2017",
    fromGstin: "29AKLPM8755F1Z2",
    fromTrdName: "welton",
    fromAddr1: "2ND CROSS NO 59  19  A",
    fromAddr2: "GROUND FLOOR OSBORNE ROAD",
    fromPlace: "FRAZER TOWN",
    fromPincode: 560090,
    actFromStateCode: 29,
    fromStateCode: 29,
    toGstin: "02EHFPS5910D2Z0",
    toTrdName: "sthuthya",
    toAddr1: "Shree Nilaya",
    toAddr2: "Dasarahosahalli",
    toPlace: "Beml Nagar",
    toPincode: 560090,
    actToStateCode: 29,
    toStateCode: 27,
    transactionType: 4,
    otherValue: "-100",
    totalValue: 56099,
    cgstValue: 0,
    sgstValue: 0,
    igstValue: 300.67,
    cessValue: 400.56,
    cessNonAdvolValue: 400,
    totInvValue: 68358,
    transporterId: "",
    transporterName: "",
    transDocNo: "",
    transMode: "1",
    transDistance: 100,
    transDocDate: "",
    vehicleNo: "PVC1234",
    vehicleType: "R",
    itemList: [
      {
        productName: "Wheat",
        productDesc: "Wheat",
        hsnCode: 1001,
        quantity: 4,
        qtyUnit: "BOX",
        cgstRate: 0,
        sgstRate: 0,
        igstRate: 3,
        cessRate: 3,
        cessNonadvol: 0,
        taxableAmount: 5609889,
      },
    ],
  };
  const ItemColumn = [
    {
      Header: "ProductName",
      accessor: "productName",
      maxWidth: 200,
      minWidth: 200,
    },
    { Header: "Product Desc", accessor: "productDesc" },
    { Header: "HSN Code", accessor: "hsnCode", style: { textAlign: "center" } },
    { Header: "Qty", accessor: "quantity", style: { textAlign: "right" } },
    { Header: "Unit", accessor: "qtyUnit", style: { textAlign: "center" } },
    {
      Header: "CGST Rate",
      accessor: "cgstRate",
      style: { textAlign: "right" },
    },
    {
      Header: "SGST Rate",
      accessor: "sgstRate",
      style: { textAlign: "right" },
    },
    {
      Header: "IGST Rate",
      accessor: "igstRate",
      style: { textAlign: "right" },
    },
    {
      Header: "Cess Rate",
      accessor: "cessRate",
      style: { textAlign: "right" },
    },
    {
      Header: "Cess Non-Advolerum",
      accessor: "cessNonadvol",
      style: { textAlign: "right" },
    },
    {
      Header: "Taxable Amount",
      accessor: "taxableAmount",
      style: { textAlign: "right" },
    },
  ];
  const InvoiceColumn = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 100,
    },
    {
      Header: "VouchId",
      accessor: "vouchId",
      show: false,
    },
    {
      Header: "Voucher Number",
      accessor: "vouchNo",
      minWidth: 200,
      maxWidth: 200,
    },
    {
      Header: "Date",
      accessor: "vouchDate",
    },
    {
      Header: "Party Name",
      accessor: "partyName",
    },
    {
      Header: "Total Qty",
      accessor: "tQty",
      Cell: (props) => props.value.toFixed(2),
    },
    {
      Header: "Total Tax",
      accessor: "tTaxAmt",
      Cell: (props) => props.value.toFixed(2),
    },
    {
      Header: "Net Amount",
      accessor: "netAmt",
      Cell: (props) => props.value.toFixed(2),
    },
  ];
  return (
    <>
      <SideMenu ScrId={151} />
      <div className="EwayBilling-Container">
        <div className="ewaybillcreation">
          <button
            onClick={(e) => {
              if (iVouchId === 0) {
                MsgBox("Please Select Invoice", "info");
              } else {
                View_Voucher(iVouchId);
                setisNewInvoiceCreate(!isNewInvoiceCreate);
              }
            }}
          >
            Create E-way Invoice <i className="fa-solid fa-circle-plus"></i>
          </button>
          {!isNewInvoiceCreate && (
            <div className="EWayInput">
              <select
                className="select"
                value={iScrId}
                onChange={(e) =>
                  GetInvoiceData(Number(e.target.value), iPartyId)
                }
              >
                {ListScreenType.map((ScrType, Index) => (
                  <option key={Index} value={ScrType.ScrId}>
                    {ScrType.ScrType}
                  </option>
                ))}
              </select>
            </div>
          )}
          {isNewInvoiceCreate && (
            <button
              onClick={(e) => {
                setiVouchId(0);
                setisNewInvoiceCreate(!isNewInvoiceCreate);
              }}
            >
              <i className="fa-solid fa-arrow-left"></i> Go Back
            </button>
          )}
        </div>
        {isNewInvoiceCreate && (
          <div className="EwayBill_View">
            <div className="EWayInput">
              <label>
                Supply Type<span className="danger">*</span>
              </label>
              <select
                className="select"
                value={supplyType}
                onChange={(e) => setsupplyType(e.target.value)}
              >
                {ListSupplyType.map((SupType, Index) => (
                  <option key={Index} value={SupType.SupTyp}>
                    {SupType.SupTyp}
                  </option>
                ))}
              </select>
            </div>
            <div className="EWayInput">
              <label>
                Sub Supply Type<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={subSupplyType}
                onChange={(e) => setsubSupplyType(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Other Sub Supply Description<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={subSupplyDesc}
                onChange={(e) => setsubSupplyDesc(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Document Type<span className="danger">*</span>
              </label>
              <select
                className="select"
                value={docType}
                onChange={(e) => setdocType(e.target.value)}
              >
                {ListdocType.map((docType, Index) => (
                  <option key={Index} value={docType.docType}>
                    {docType.docType}
                  </option>
                ))}
              </select>
            </div>
            <div className="EWayInput">
              <label>
                Document Number<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={docNo}
                onChange={(e) => setdocNo(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Document Date<span className="danger">*</span>
              </label>
              <input
                type="date"
                pattern="[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]"
                value={docDate}
                onChange={(e) => setdocDate(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                From GSTIN (Supplier or Consignor)
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                value={fromGstin}
                onChange={(e) => setfromGstin(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                From Trade Name (Consignor Trade name)
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                value={fromTrdName}
                onChange={(e) => setfromTrdName(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                From Address Line 1<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={fromAddr1}
                onChange={(e) => setfromAddr1(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                From Address Line 2<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={fromAddr2}
                onChange={(e) => setfromAddr2(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                From Place<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={fromPlace}
                onChange={(e) => setfromPlace(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Actual From State Code<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={actFromStateCode}
                onChange={(e) => setactFromStateCode(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                From Pincode<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={fromPincode}
                onChange={(e) => setfromPincode(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                From State Code<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={fromStateCode}
                onChange={(e) => setfromStateCode(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                To GSTIN (Consignee or Recipient)
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                value={toGstin}
                onChange={(e) => settoGstin(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                To Trade Name<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={toTrdName}
                onChange={(e) => settoTrdName(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                To Address Line 1<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={toAddr1}
                onChange={(e) => settoAddr1(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                To Address Line 2<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={toAddr2}
                onChange={(e) => settoAddr2(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                To Place<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={toPlace}
                onChange={(e) => settoPlace(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                To Pincode<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={toPincode}
                onChange={(e) => settoPincode(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Actual To State Code<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={actToStateCode}
                onChange={(e) => setactToStateCode(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                To State Code<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={toStateCode}
                onChange={(e) => settoStateCode(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Transaction type<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={transactionType}
                onChange={(e) => settransactionType(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Sum of Taxable value <span className="danger">*</span>
              </label>
              <input
                type="number"
                value={totalValue}
                onChange={(e) => settotalValue(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                CGST value<span className="danger">*</span>
              </label>
              <input
                type="number"
                value={cgstValue}
                onChange={(e) => setcgstValue(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                SGST value<span className="danger">*</span>
              </label>
              <input
                type="number"
                value={sgstValue}
                onChange={(e) => setsgstValue(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                IGST value<span className="danger">*</span>
              </label>
              <input
                type="number"
                value={igstValue}
                onChange={(e) => setigstValue(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Cess value<span className="danger">*</span>
              </label>
              <input
                type="number"
                value={cessValue}
                onChange={(e) => setcessValue(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Cess Non Advol value<span className="danger">*</span>
              </label>
              <input
                type="number"
                value={cessNonAdvolValue}
                onChange={(e) => setcessNonAdvolValue(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Other charges, if any <span className="danger">*</span>
              </label>
              <input
                type="number"
                value={otherValue}
                onChange={(e) => setotherValue(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Total Invoice value<span className="danger">*</span>
              </label>
              <input
                type="number"
                value={totInvValue}
                onChange={(e) => settotInvValue(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Transport Mode <span className="danger">*</span>
              </label>
              <select
                className="select"
                value={transMode}
                onChange={(e) => settransMode(e.target.value)}
              >
                {ListTransportMode.map((TransMode, Index) => (
                  <option key={Index} value={TransMode.TransType}>
                    {TransMode.TransTypeName}
                  </option>
                ))}
              </select>
            </div>
            <div className="EWayInput">
              <label>
                Transport Distance (below 4000km)
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                value={transDistance}
                onChange={(e) => settransDistance(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Name of the transporter<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={transporterName}
                onChange={(e) => settransporterName(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                15 Digit Transporter GSTIN/TRANSIN
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                value={transporterId}
                onChange={(e) => settransporterId(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Transport Document Number<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={transDocNo}
                onChange={(e) => settransDocNo(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Transport Document Date<span className="danger">*</span>
              </label>
              <input
                type="date"
                value={transDocDate}
                onChange={(e) => settransDocDate(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Vehicle Number<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={vehicleNo}
                onChange={(e) => setvehicleNo(e.target.value)}
              />
            </div>
            <div className="EWayInput">
              <label>
                Vehicle Type<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={vehicleType}
                onChange={(e) => setvehicleType(e.target.value)}
              />
            </div>
            <ReactTable
              columns={ItemColumn}
              data={ListEntItem}
              minRows={10}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo) => {}}
              background={true}
              className="full-table"
            />
          </div>
        )}
        {!isNewInvoiceCreate && (
          <ReactTable
            columns={InvoiceColumn}
            data={ListEntInvoice}
            minRows={5}
            row_click={(rowInfo, Index) => {
              setselected(Index);
            }}
            row_doubleclick={(rowInfo) => {
              setiVouchId(rowInfo.vouchId);
              setiPartyId(rowInfo.partyId);
            }}
            background={true}
            className="full-table"
          />
        )}
      </div>
    </>
  );
}

export default EWayBIlling;
