import React from "react";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "../General/Utility";
import ReactTable from "../General/ReactTable";

function VoucherNo(props) {
  const dispatch = useDispatch();
  const _Auth = useSelector((state) => state.Common.Admin);
  const _Config = useSelector((state) => state.Config.Config);
  const [strSearch, setstrSearch] = useState("");
  const [VoucherNumberList, setVoucherNumberList] = useState([]);
  useEffect(() => {
    Load_VoucherNumberSetting("");
  }, []);
  const Load_VoucherNumberSetting = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strSearch: strtxt,
        iBranchId: Number(_Auth.Branch),
        strDBName: _Auth.DB,
      };
      const res = await API_POST(
        `ConfigurationSetting/Load_VoucherNumberSetting/`,
        objSearch,
        dispatch
      );
      if (res) {
        setVoucherNumberList(res.EntVouchSett);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Save_VoucherNumSetting = async () => {
    try {
      let UpdateVoucherNumber = VoucherNumberList;
      let CheckUserFormate = UpdateVoucherNumber.filter(
        (Data) => !Data.userNoFormat.includes("#N")
      );
      if (CheckUserFormate.length > 0)
        Show_Message(dispatch, "#N is Mandatory", "info");
      else {
        const res = await API_POST(
          "ConfigurationSetting/Update_VoucherNumSetting/",
          {
            objEntVoucherNo: UpdateVoucherNumber,
            strDBName: _Auth.DB,
            iBranchId: Number(_Auth.Branch),
          },
          dispatch
        );
        if (res) {
          Clear_VoucherSetting();
          Show_Message(dispatch, res.strMessage, "success");
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Clear_VoucherSetting = () => {
    setVoucherNumberList([]);
    Load_VoucherNumberSetting("");
  };
  const Input_OnChange = async (Value, RowInfo, valueType) => {
    let UpdateVoucherList = VoucherNumberList;
    switch (valueType) {
      case "MaxDigit":
        let DigitLenth = RowInfo.nextNumber.toString().length;
        if (DigitLenth <= Number(Value))
          UpdateVoucherList = UpdateVoucherList.map((Data) => {
            if (Data.scrId === RowInfo.scrId) {
              Data.numSeqMaxDigit = Number(Value);
            }
            return Data;
          });
        setVoucherNumberList(UpdateVoucherList);
        break;
      case "nextNumber":
        Pre_Loading(dispatch, true);
        const res = await API_FETCH(
          `ConfigurationSetting/View_NextNumber/${RowInfo.scrId}/${Number(
            Value
          )}/${Number(_Auth.Branch)}/${_Auth.DB}`,
          dispatch
        );
        if (res) {
          UpdateVoucherList = UpdateVoucherList.map((Data) => {
            if (Data.scrId === RowInfo.scrId) {
              if (RowInfo.numSeqMaxDigit < Value.length) {
                Show_Message(
                  dispatch,
                  "Next Number Lenth More the MaxDigit",
                  "info"
                );
              } else Data.nextNumber = Number(Value);
            }
            return Data;
          });
          setVoucherNumberList(UpdateVoucherList);
        }
        break;
      case "isManualNumber":
        UpdateVoucherList = UpdateVoucherList.map((Data) => {
          if (Data.scrId === RowInfo.scrId) {
            Data.isManualNumber = Value;
          }
          return Data;
        });
        setVoucherNumberList(UpdateVoucherList);
        break;
      default:
        UpdateVoucherList = UpdateVoucherList.map((Data) => {
          if (Data.scrId === RowInfo.scrId) Data.userNoFormat = Value;
          return Data;
        });
        setVoucherNumberList(UpdateVoucherList);
        break;
    }
  };
  const VoucherColumn = [
    {
      Header: "Screen Name",
      accessor: "scrName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Type",
      accessor: "voucherType",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Max Degit",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "left" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.numSeqMaxDigit}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.numSeqMaxDigit = e.target.value)}
        />
      ),
    },
    {
      Header: "Next Number",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "left" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.nextNumber}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.nextNumber = e.target.value)}
        />
      ),
    },
    {
      Header: "Invoice No Formate",
      minWidth: 150,
      maxWidth: 150,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.userNoFormat}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.userNoFormat = e.target.value)}
        />
      ),
    },
    {
      Header: "Invoice No",
      accessor: "userNo",
      minWidth: 150,
      maxWidth: 150,
      style: { justifyContent: "center" },
    },
    {
      Header: "Manual No",
      minWidth: 70,
      maxWidth: 70,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          className="form-check-input"
          type="checkbox"
          checked={rowinfo.isManualNumber}
          onChange={() => (rowinfo.isManualNumber = !rowinfo.isManualNumber)}
        />
      ),
    },
  ];
  return (
    <div className="Config-card">
      <div className="config-h">
        <h3>{_Config.Header}</h3>
        <div className="m-hsearch">
          <input
            type="text"
            autoCorrect="off"
            autoComplete="off"
            placeholder="Search Voucher Screen"
            value={strSearch}
            onChange={(e) => {
              Load_VoucherNumberSetting(e.target.value, "Search");
            }}
            autoFocus
          />
          <i
            className="fas fa-search"
            onClick={(e) => Load_VoucherNumberSetting(strSearch, "Search")}
          ></i>
        </div>
      </div>
      <div className="Config-Wrapper mt-1">
        <ReactTable
          columns={VoucherColumn}
          data={VoucherNumberList}
          minRows={10}
          row_click={(ProInfo, Index) => {}}
          row_doubleclick={(ProInfo, Index) => {}}
          background={true}
          className="auto-y"
        />
      </div>
      <div className="btn-section">
        <button
          type="button"
          className="btn-fabgreen"
          onClick={Save_VoucherNumSetting}
        >
          Update &nbsp; <i className="fas fa-Save"></i>
        </button>
        <button
          type="button"
          className="btn-fabgreen"
          onClick={Clear_VoucherSetting}
        >
          Clear &nbsp; <i className="fas fa-eraser"></i>
        </button>
      </div>
    </div>
  );
}

export default VoucherNo;
