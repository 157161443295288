import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading } from "../General/Messagedialogbox";
import $ from "jquery";
import {
  Add_CommonDefault,
  Add_TransHtparem,
  Add_TransSummary,
  Add_Transdata,
  Add_party,
  add_BillDiscount,
} from "../features/POS";
import { API_FETCH, API_POST } from "../General/Utility";
function POSReturn(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [strSearch, setstrSearch] = useState("");
  const [strFromDate, setstrFromDate] = useState("");
  const [strToDate, setstrToDate] = useState("");
  const [VouchersList, setVouchersList] = useState([]);

  useEffect(() => {
    $("[tabindex=52]").focus();
    load_Vouchers("", "Search");
    short_cut_keypress();
  }, []);
  const load_Vouchers = async (value, SearchFor) => {
    let FromDate = strFromDate;
    let ToDate = strToDate;
    let Search = strSearch;
    switch (SearchFor) {
      case "Search":
        setstrSearch(value);
        Search = value;
        break;
      case "FromDate":
        setstrFromDate(value);
        FromDate = value;
        break;
      case "ToDate":
        setstrToDate(value);
        ToDate = value;
        break;
      default:
        break;
    }
    const objSearch = {
      iBranchId: Number(_AuthParems.Branch),
      strFromDate: FromDate,
      strToDate: ToDate,
      strSearch: Search,
      iScrId: 96,
      strDBName: _AuthParems.DB,
      iCounterId: Number(localStorage.Counter),
    };
    const res = await API_POST(`POS/Load_Vouchers/`, objSearch, dispatch);
    if (res) {
      setstrFromDate(res.strFromDate);
      setstrToDate(res.strToDate);
      setVouchersList(res.objVouchers);
    }
  };
  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        props.Close();
      }
    });
  };
  const View_PosReturn = async (vouchid) => {
    props.View_Return();
    Pre_Loading(dispatch, true);
    const res = await API_FETCH(
      `POS/View_ReturnVoucherDetails/${vouchid}/${Number(_AuthParems.Branch)}/${
        _AuthParems.DB
      }`,
      dispatch
    );
    if (res) {
      // Add To Cart Not Allowed When Modify Bill
      let _EntHT = res.objEntHT[0];
      let summary = res.objEntSummary;
      dispatch(
        Add_party({
          iPartyId: _EntHT.iPartyId,
          iTaxRelId: _EntHT.iTaxTypeId,
          strPartyName: res.objPartyList[0],
          strPartyCode: "",
          strAliasName: "",
          strAdd1: "",
          strAdd2: "",
          strAdd3: "",
          strAdd4: "",
          strPincode: "",
          iState: 0,
          iCountry: 0,
          strMobile1: res.objPartyList[2],
          strMobile2: "",
          strPhone1: "",
          strPhone2: "",
          strEmail: "",
          strGSTNo: res.objPartyList[3],
          strTinNo: "",
          strShippAdd: _EntHT.strShippingAdd,
          strPartyAdd: res.objPartyList[1],
          dOutstandingAmt: 0,
          dRoyaltyPoint: 0,
        })
      );
      dispatch(
        Add_CommonDefault({
          iScrId: 96,
          strScrType: "Sales",
          iVouchId: _EntHT.iPOSRetId,
          iCartInvId: 0,
          strVouchNo: _EntHT.strVouchNo,
          strVouchDate: _EntHT.strVouchDate,
          iBillTypeId: _EntHT.iBillTypeId,
          isDisablebtn: false,
          iTaxCalcOnId: _EntHT.iTaxCalcOnId,
          isDisabled: true,
        })
      );
      dispatch(
        Add_TransHtparem({
          //#region  Voucher Tab Parem
          strTransNo: _EntHT.strTransNo,
          strPartyDCVouch: "",
          strConvertDCVouch: "",
          //#endregion

          //#region  Reference Vouchers Tap Parem
          strRefNum1: "",
          dpRefNum1Date: "",
          strRefNum2: "",
          dpRefNum2Date: "",
          strRefNum3: "",
          dpRefNum3Date: "",
          strRefNum4: "",
          dpRefNum4Date: "",
          //#endregion

          //#region  Validity Tap Parem
          iValidity: _EntHT.strValidity,
          dpValDateUpto: _EntHT.dpValDateUpto,
          iPayTerm: _EntHT.strPayTerm,
          dpPayTermDateUpto: _EntHT.dpPayTermDateUpto,
          iDeliTerm: _EntHT.strDeliTerm,
          dpDelTermDateUpto: _EntHT.dpDelTermDateUpto,
          //#endregion

          //#region  Delivery Mode Parem
          iDMId: _EntHT.iDMId,
          strAgent: _EntHT.strAgent,
          strReference: _EntHT.strReference,
          strDestination: _EntHT.strDestination,
          strEComGstNo: "",
          //#endregion

          //#region  Transport Tap Parem
          strTransport: _EntHT.strTransport,
          strVehicle: _EntHT.strVehicle,
          strDespatch: _EntHT.strDespatch,
          dpDespatchDate: _EntHT.dpDespatchDate,
          strLRNNumber: _EntHT.strLRNNumber,
          dpLRNoDate: _EntHT.dpLRNoDate,
          //#endregion

          //#region  Remark Tap Parem
          strRemark1: _EntHT.strRemark1,
          strRemark2: _EntHT.strRemark2,
          strRemark3: _EntHT.strRemark3,
          strRemark4: _EntHT.strRemark4,
          //#endregion
        })
      );
      dispatch(
        Add_Transdata({
          EntTransDT: Update_TransDt(res.objEntDT, _EntHT.iTaxTypeId),
          EntTransPenConDT: [],
          EntTransTax: UpdateTaxGroup(res.objEntTax),
          EntTransAL: res.objEntALDT,
          EntTransDTHelp: res.objEntDTHelp,
        })
      );
      dispatch(
        Add_TransSummary({
          dnetAmt: summary.netAmt,
          dqty: summary.qty,
          dtotalDiscount: summary.totalDiscount,
          dgoodsValue: summary.goodsValue,
          dtotalTax: summary.totalTax,
          dtotalAddLess: summary.totalAddLess,
          dtotalTCSTax: summary.totalTCSTax,
          droundOff: summary.roundOff,
          dBillDisVal: _EntHT.dTBillDiscValue,
        })
      );
      dispatch(
        add_BillDiscount({
          dBillDisPer: _EntHT.dTBillDiscPer,
          dBillDisAmt: _EntHT.dTBillDiscAmt,
          dBillDisValue: _EntHT.dTBillDiscValue,
          dALBillDisPer: _EntHT.dTAddLessDiscPer,
          dALBillDisAmt: _EntHT.dTAddLessDiscAmt,
          dALBillDisValue: _EntHT.dTAddLessDiscValue,
          iALBillDiscId: _EntHT.iAddLessBillDiscId,
        })
      );
    }
  };
  const UpdateTaxGroup = (arr) => {
    let result = [];
    arr.forEach((tax, Index) => {
      const objtax = {
        TaxDesc: tax.taxDesc,
        TaxableAmt: tax.taxableAmt,
        TaxAmt: tax.taxAmt,
        TaxId: tax.taxId,
        TaxPer: tax.taxPer,
        Level: tax.level,
      };
      result.push(objtax);
    });

    return result;
  };
  const Update_TransDt = (Returnproduct, iTaxRelId) => {
    let TransDT = [];
    Returnproduct.forEach((item, Index) => {
      TransDT.push({
        ProId: item.proId,
        ItemGrpId: item.itemGrpId,
        ProCode: item.proCode,
        ProName: item.proName,
        CommodityCode: item.commodityCode,
        AttValues: item.attValues,
        UnitId: item.unitId,
        UnitName: item.unitName,
        RateWithoutTax: item.rateWithoutTax,
        RateWithTax: item.rateWithTax,
        dMinMargin: item.minMargin,
        ItemDiscPer: item.itemDiscPer,
        ItemDiscAmt: item.itemDiscAmt,
        ItemDiscValue: item.itemDiscValue,
        CashDiscPer: item.cashDiscPer,
        CashDiscAmt: item.cashDiscAmt,
        CashDiscValue: item.cashDiscValue,
        BillDiscValue: item.billDiscValue,
        Qty: item.qty,
        BatchNo: item.batchNo,
        ManufactureDate: item.manufactureDate,
        ExpiryDate: item.expiryDate,
        iTaxRelId: iTaxRelId,
        TaxPer: item.taxPer,
        iSGST: item.sgstId,
        iCGST: item.cgstId,
        iIGST: item.igstId,
        iTCS: item.tcsId,
        strSGST: item.sgstDesc,
        strCGST: item.cgstDesc,
        strIGST: item.igstDesc,
        strTCS: item.tcsDesc,
        strSGSTPer: item.sgstPer,
        strCGSTPer: item.cgstPer,
        strIGSTPer: item.igstPer,
        strTCSPer: item.tcstPer,
        TaxLvl: item.taxLvl,
        taxableOn: item.taxableOn,
        TcsTaxRelId: item.tcsTaxRelId,
        totalVal: 0,
        iIndex: Index,
        IsNewItem: item.isNewItem,
        GoodsValue: item.goodsValue,
        TaxableAmt: item.taxableAmt,
        TaxAmt: item.taxAmt,
        ItemAmt: item.itemAmt,
        FQty: item.fQty,
        Value1: item.value1,
        Value2: item.value2,
        Value3: item.value3,
        TotSqureValue: item.totSqureValue,
        ConstantValue: item.constantValue,
        FinalValue: item.finalValue,
        SNo: item.sNo,
        SACCode: item.sacCode,
        YearId: item.yearId,
        RGName: item.rgName,
        RGId: item.rgId,
        JobId: item.jobId,
        JobCode: item.jobCode,
        JobName: item.jobName,
        SerId: item.serId,
        SerCode: item.serCode,
        SerName: item.serName,
        Remark1: item.remark1,
        Remark2: item.remark2,
        Remark3: item.remark3,
        Remark4: item.remark4,
        RowStatus: item.rowStatus,
        IsSerProduct: item.isSerProduct,
        ConScrId: item.conScrId,
        InvDTId: item.invDTId,
        IsConvertable: item.isConvertable,
        ConvertedQty: item.convertedQty,
        RowId: item.rowId,
        Reason: item.reason,
        BranchId: item.branchId,
        DecimalQty: item.decimalQty,
        StockMaintain: item.stockMaintain,
      });
    });
    return TransDT;
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Return</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="input-search">
            <input
              type="text"
              value={strSearch}
              onChange={(e) => load_Vouchers(e.target.value, "Search")}
              className="form-control"
              placeholder="Search bill number ...."
              autoFocus
              autoComplete="off"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  $(`[tabindex=0]`).focus();
                } else if (e.key === "Enter") {
                  View_PosReturn(VouchersList[0].vouchId);
                }
              }}
            />
            <input
              type="date"
              value={strFromDate}
              onChange={(e) => load_Vouchers(e.target.value, "FromDate")}
              className="form-control"
              autoComplete="off"
            />
            <input
              type="date"
              value={strToDate}
              onChange={(e) => load_Vouchers(e.target.value, "ToDate")}
              className="form-control"
              autoComplete="off"
            />
          </div>
          <div className="pos-view">
            {VouchersList.map((Vouch, trIndex) => (
              <button
                key={trIndex}
                tabIndex={trIndex}
                onDoubleClick={(e) => View_PosReturn(Vouch.vouchId)}
                onKeyDown={(e) => {
                  let arrlength = VouchersList.length - 1;
                  var ActiveIndex = document.activeElement.tabIndex;
                  if (e.key === "ArrowDown") {
                    if (ActiveIndex === arrlength) {
                      $("[tabindex=0]").focus();
                      $("[tabindex=0]").select();
                    } else {
                      $(`[tabindex=${ActiveIndex + 1}]`).focus();
                      $(`[tabindex=${ActiveIndex + 1}]`).select();
                    }
                  }
                  if (e.key === "ArrowUp") {
                    if (ActiveIndex === 0) {
                      $(`[tabindex=${arrlength}]`).focus();
                      $(`[tabindex=${arrlength}]`).select();
                    } else {
                      $(`[tabindex=${ActiveIndex - 1}]`).focus();
                      $(`[tabindex=${ActiveIndex - 1}]`).select();
                    }
                  }
                  if (e.key === "Enter") {
                    View_PosReturn(Vouch.vouchId);
                  } else if (e.key === "Escape") props.Close();
                  if (e.key === "Escape") props.Close();
                }}
              >
                <div className="bill-content">
                  <h6>{Vouch.partyName}</h6>
                  <div className="trans-cont">
                    <div className="transview1">
                      <label>
                        <span>Inv No : {Vouch.vouchNo}</span>
                        <span>Date : {Vouch.vouchDate}</span>
                        <span>Qty : {Number(Vouch.tQty).toFixed(2)}</span>
                        <span>
                          Tax Amount :
                          {new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "INR",
                          }).format(Number(Vouch.tTaxAmt).toFixed(2))}
                        </span>
                        <span>
                          Net Amount :
                          {new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "INR",
                          }).format(Number(Vouch.netAmt).toFixed(2))}
                        </span>
                      </label>
                      <label></label>
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="pos-btn-group">
          <div className="pos-tooltip">
            <a className="icon-btn" href="##">
              <i className="fa-solid fa-print"></i>
            </a>
            <span className="pos-tooltiptxt">Print</span>
          </div>
          <div className="pos-tooltip">
            <a className="icon-btn" href="##">
              <i className="fa-solid fa-download"></i>
            </a>
            <span className="pos-tooltiptxt">Download</span>
          </div>
          <div className="pos-tooltip">
            <a className="icon-btn" href="##">
              <i className="fa-brands fa-whatsapp"></i>
            </a>
            <span className="pos-tooltiptxt">whatsapp</span>
          </div>
          <div className="pos-tooltip">
            <a className="icon-btn" href="##">
              <i className="fa-regular fa-envelope"></i>
            </a>
            <span className="pos-tooltiptxt">E-mail</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default POSReturn;
