import React from "react";
import { useEffect, useState } from "react";
import { Buffer } from "buffer";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { API_FETCH, API_POST } from "../General/Utility";
function LogoGallery(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [ImageByteArray, setImageByteArray] = useState([]);
  const [strLogoName, setstrLogoName] = useState("");
  const [ImageType, setImageType] = useState("");
  const [iLogoId, setiLogoId] = useState(0);
  const [dImageWidth, setdImageWidth] = useState(0);
  const [dImageHeight, setdImageHeight] = useState(0);
  const [strImage, setstrImage] = useState("");
  const [ListEntLogo, setListEntLogo] = useState([]);
  useEffect(() => {
    Load_Logo(0);
  }, []);
  const Load_Logo = async (LogoId) => {
    try {
      const res = await API_FETCH(
        "PrintDesing/View_Logo/" +
          LogoId +
          "/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        setListEntLogo(res.objEntLogo);
        if (res.objEntLogo.length > 0) setiLogoId(res.objEntLogo[0].iLogoId);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Save_Logo = async () => {
    try {
      let Type = ImageType.toLocaleLowerCase();
      if (Type === "jpg" || Type === "jpeg" || Type === "png") {
        Pre_Loading(dispatch, true);
        setImageType(Type);
        const UpdateImage = {
          iLogoId: iLogoId,
          strLogoName: strLogoName,
          dLogoWidth: dImageWidth,
          dLogoHeight: dImageHeight,
          strImgCompLogoTag: "",
          strPathTag: "",
          ImgData: ImageByteArray,
          strDBName: _AuthParems.DB,
          iBranchId: Number(_AuthParems.Branch),
        };
        const res = await API_POST(
          "PrintDesing/InsUpd_Logo/",
          UpdateImage,
          dispatch
        );
        if (res) {
          Show_Message(dispatch, res.strMessage, "success");
          Clear_Logo();
        }
      } else {
        Show_Message(
          dispatch,
          "Only jpg/jpeg and png files are allowed!",
          "error"
        );
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Delete_Logo = async (LogoId) => {
    try {
      const res = await API_POST(
        "PrintDesing/Delete_Logo/" + LogoId + "/" + _AuthParems.DB,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Clear_Logo = () => {
    setImageType("");
    setstrLogoName("");
    setListEntLogo([]);
    setImageByteArray([]);
    setiLogoId(0);
    setstrImage("");
    setdImageHeight("");
    setdImageWidth("");
    Load_Logo(0);
  };
  const View_Logo = (logo) => {
    $(".img-wrapper").addClass("active"); // For Image Active
    setiLogoId(logo.iLogoId);
    setdImageWidth(logo.dLogoWidth);
    setdImageHeight(logo.dLogoHeight);
    setstrLogoName(logo.strLogoName);
    setImageByteArray(logo.imgData);
    setImageType(logo.strLogoName.split(".")[1]);
    var ArrayToString = Buffer.from(logo.imgData).toString("base64");
    var Image = "data:image/png;base64," + ArrayToString;
    setstrImage(Image);
  };
  //#region  Image Selection
  const ActivatDeafultBtn = () => {
    const defaultBtn = document.getElementById("default-btn");
    // eslint-disable-next-line no-useless-escape
    let regExp = /[0-9a-zA-Z\^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~\_ ]+$/;
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      const file = this.files[0];
      if (file) {
        const reader = new FileReader();
        const Bytreader = new FileReader();
        reader.readAsDataURL(file);
        Bytreader.readAsArrayBuffer(file);

        reader.onload = function () {
          setstrImage(reader.result);
        };
        Bytreader.onload = function () {
          var fileByteArray = [];
          var array = new Uint8Array(Bytreader.result);
          for (var i = 0; i < array.length; i++) fileByteArray.push(array[i]);
          setImageByteArray(fileByteArray);
        };
        if (this.value) {
          setstrLogoName(this.value.match(regExp)[0]);
        }
        $(".wrapper").addClass("active");
      }
    });
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Logo Gallery</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row p-2">
            <div className="col-md-4 col-sm-12">
              <div className="data-list">
                {ListEntLogo.map((logo, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = ListEntLogo.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_Logo(logo);
                      }
                    }}
                  >
                    <label onClick={(e) => View_Logo(logo)}>
                      {logo.strLogoName}
                    </label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12 p-2">
              <div className="row">
                <center>
                  <div className="img-container">
                    <div className="img-content">
                      <div className="img-wrapper">
                        <div className="image">
                          {strImage !== "" && <img src={strImage} alt="" />}
                        </div>
                        <div className="content">
                          <div id="custom-btn" onClick={ActivatDeafultBtn}>
                            <i className="fas fa-cloud-upload-alt"></i>
                          </div>
                        </div>
                        <div
                          id="cancel-btn"
                          onClick={(e) => {
                            setstrImage("");
                            setImageByteArray([]);
                            setstrLogoName("");
                            $(".wrapper").removeClass("active");
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </div>
                        <div className="file-name">{strLogoName}</div>
                      </div>
                      <input
                        id="default-btn"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        hidden
                      />
                    </div>
                  </div>
                </center>
                <div className="col-md-6 col-sm-6">
                  <label>Image Height</label>
                  <span className="danger">*</span>
                  <input
                    autoFocus
                    type="number"
                    className="form-control"
                    placeholder="Enter Image Height"
                    value={dImageHeight}
                    onChange={(e) => setdImageHeight(e.target.value)}
                    onClick={(e) => e.target.select()}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Image Width</label>
                  <span className="danger">*</span>
                  <input
                    onClick={(e) => e.target.select()}
                    type="number"
                    className="form-control"
                    spellCheck={false}
                    placeholder="Enter Image Width"
                    value={dImageWidth}
                    onChange={(e) => setdImageWidth(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(dispatch, Save_Logo, "Do You Want to Save Image?");
              }}
            >
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                Ask_Question(
                  dispatch,
                  Delete_Logo,
                  "Do You Want to Delete Image?",
                  iLogoId
                )
              }
            >
              Delete&nbsp; <i className="far fa-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={(e) => Clear_Logo()}>
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoGallery;
