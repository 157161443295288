import { fnTaxableAmt, fnTaxAmt } from "../General/Formula";
const GSTTaxCalculation = (EntDT, EntAL, ALTax, OthCalWQty, iTaxCalcOnId) => {
  let dRateWithoutTax = 0;
  let dQty = 0;
  let dItemDiscValue = 0;
  let dCashDiscValue = 0;
  let dBillDiscValue = 0;
  let dTaxableAmt = 0;
  let dTaxAmt = 0;
  let dFinalVal = 0;
  let iTaxId = 0;
  let bIsNewItem = true;
  let objALTaxDetails = [];
  let EntTaxDetails = [];
  let TaxGroup = [];
  let objTaxDetails = [];
  ALTax = JSON.parse(JSON.stringify(ALTax));

  //#region Generate for dtItem
  EntDT.forEach((Item) => {
    bIsNewItem = Item.IsNewItem === 1 ? true : false;
    if (bIsNewItem) {
      if (Number(Item.iTaxRelId) === 1)
        EntTaxDetails.push(
          {
            TaxDesc: Item.strSGST,
            TaxableAmt: 0,
            TaxAmt: 0,
            TaxId: Item.iSGST,
            TaxPer: Item.strSGSTPer,
            Level: Item.TaxLvl,
          },
          {
            TaxDesc: Item.strCGST,
            TaxableAmt: 0,
            TaxAmt: 0,
            TaxId: Item.iCGST,
            TaxPer: Item.strCGSTPer,
            Level: Item.TaxLvl,
          }
        );
      if (Number(Item.iTaxRelId) === 2)
        EntTaxDetails.push({
          TaxDesc: Item.strIGST,
          TaxableAmt: 0,
          TaxAmt: 0,
          TaxId: Item.iIGST,
          TaxPer: Item.strIGSTPer,
          Level: Item.TaxLvl,
        });
    }
    EntTaxDetails.forEach((TaxDetail) => {
      dQty = Math.abs(Item.Qty);
      dRateWithoutTax = Item.RateWithoutTax;
      dItemDiscValue = Item.ItemDiscValue;
      dCashDiscValue = Item.CashDiscValue;
      dBillDiscValue = Item.BillDiscValue;
      dFinalVal = Item.FinalValue;
      if (OthCalWQty === "Yes")
        TaxDetail.TaxableAmt = fnTaxableAmt(
          iTaxCalcOnId,
          dQty * dRateWithoutTax * dFinalVal,
          dItemDiscValue,
          dCashDiscValue,
          dBillDiscValue
        );
      else
        TaxDetail.TaxableAmt = fnTaxableAmt(
          iTaxCalcOnId,
          dQty * dRateWithoutTax,
          dItemDiscValue,
          dCashDiscValue,
          dBillDiscValue
        );
      TaxDetail.TaxAmt = fnTaxAmt(TaxDetail.TaxPer, TaxDetail.TaxableAmt);

      iTaxId = TaxDetail.TaxId;
      objTaxDetails.push(TaxDetail);
    });
    return Item;
  });
  //#endregion

  //#region Generate for dtAddLess
  EntAL.forEach((ALItem) => {
    ALTax.forEach((tax) => {
      if (ALItem.serId === tax.serId) {
        dQty = 1;
        dRateWithoutTax = ALItem.rateWithoutTax;
        dItemDiscValue = 0;
        dCashDiscValue = 0;
        dBillDiscValue = 0;
        iTaxId = tax.taxId;
        tax.taxableAmt = fnTaxableAmt(
          iTaxCalcOnId,
          dQty * dRateWithoutTax,
          dItemDiscValue,
          dCashDiscValue,
          dBillDiscValue
        );
        tax.taxAmt = fnTaxAmt(tax.taxPer, tax.taxableAmt);

        if (objALTaxDetails.filter((AL) => AL.taxId === iTaxId).length === 0) {
          objALTaxDetails.push(tax);
        } else {
          var ExistTaxDetail = objALTaxDetails.filter(
            (AL) => AL.TaxId === iTaxId
          );
          if (ExistTaxDetail.length > 0) {
            ExistTaxDetail = ExistTaxDetail[0];
            ExistTaxDetail.TaxableAmt =
              ExistTaxDetail.TaxableAmt + ExistTaxDetail.TaxableAmt;
            ExistTaxDetail.TaxAmt =
              ExistTaxDetail.TaxAmt + ExistTaxDetail.TaxAmt;
            objALTaxDetails.push(ExistTaxDetail);
          }
        }
      }
    });
  });
  //#endregion

  //#region Aggregate dtItem & dtAddLess
  if (objALTaxDetails.length > 0) {
    objALTaxDetails.forEach((ALTaxDT) => {
      iTaxId = ALTaxDT.taxId;
      objTaxDetails.forEach((EntTaxDetails) => {
        if (EntTaxDetails.TaxId === iTaxId) {
          iTaxId = 0;
          dTaxableAmt = EntTaxDetails.TaxableAmt;
          dTaxAmt = EntTaxDetails.TaxAmt;
          EntTaxDetails.TaxableAmt = dTaxableAmt + ALTaxDT.taxableAmt;
          EntTaxDetails.TaxAmt = dTaxAmt + ALTaxDT.taxAmt;
        }
      });
      if (iTaxId !== 0) {
        let EntTG = {
          TaxDesc: ALTaxDT.taxDesc,
          TaxableAmt: ALTaxDT.taxableAmt,
          TaxAmt: ALTaxDT.taxAmt,
          TaxId: ALTaxDT.taxId,
          TaxPer: ALTaxDT.taxPer,
          Level: ALTaxDT.level,
        };
        objTaxDetails.push(EntTG);
      }
    });
  }
  //#endregion

  //#region  Tax Calculation
  var EntTaxGroup = grouparraybykey(objTaxDetails);
  EntTaxGroup.forEach((objTG) => {
    // create a variable for the sum and initialize it
    let taxbleamt = 0;
    let taxamt = 0;
    // iterate over each item in the array
    for (let i = 0; i < objTaxDetails.length; i++) {
      if (
        objTaxDetails[i].taxPer === objTG.TaxPer &&
        objTaxDetails[i].taxDesc === objTG.TaxDesc &&
        objTaxDetails[i].taxId === objTG.TaxId
      ) {
        taxbleamt += objTaxDetails[i].taxableAmt;
        taxamt += objTaxDetails[i].taxAmt;
      }
    }
    objTG.TaxableAmt = taxbleamt;
    objTG.TaxAmt = taxamt;
    let EntTG = {
      taxDesc: objTG.TaxDesc,
      taxableAmt: objTG.TaxableAmt,
      taxAmt: objTG.TaxAmt,
      taxId: objTG.TaxId,
      taxPer: objTG.TaxPer,
      level: objTG.Level,
    };
    TaxGroup.push(EntTG);
  });
  //#endregion

  return {
    _objBaseTransDT: EntDT,
    _objEntTransTax: objTaxDetails,
    _objEntTaxGrp: TaxGroup,
  };
};

const grouparraybykey = (objTaxDetails) => {
  var helper = {};
  var result = objTaxDetails.reduce(function (r, o) {
    var key = o.TaxId + "-" + o.TaxDesc + "-" + o.TaxPer;
    if (!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    }
    return r;
  }, []);
  return result;
};
export default GSTTaxCalculation;
