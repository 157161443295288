import { createSlice } from "@reduxjs/toolkit";

export const AccountsSlice = createSlice({
  name: "Account",
  initialState: {
    default: {
      iBranchId: 0,
      iScrId: 0,
      iPayModeId: 1,
      iVenTypeId: 1,
      iBankId: 0,
      iPayRecId: 0,
      strTransNo: "",
      strVouchNo: "",
      strVouchDate: new Date().toISOString().split("T")[0],
      iVenId: 0,
      strVenName: "",
      strPrintName: "",
      strChequeNo: "",
      strChequeDate: new Date().toISOString().split("T")[0],
      iBankAccId: 0,
      strBankName: "",
      strAccHolderName: "",
      strAccNo: "",
      dAmount: (0).toFixed(2),
      strRemark: "",
      strSearch: "",

      isCheque: true,
      isBanAcc: true,
      isCancelStatus: false,
    },
    HT: {
      arrNoFormat: [],
      arrPayment: [],
      arrPayMode: [],
      arrBankAcc: [],
      arrBankAccDetails: [],
      arrVenType: [],
      arrValidateProperties: [],
    },
    TabOrder: {
      VoucherTab: "",
      TransNoTab: "",
      TranDate: "",
      VouchDateTap: "",
      VenNameTab: "",
      VenTypeTab: "",
      PayModeTab: "",
      CheckNoTab: "",
      CheckDateTab: "",
      BankAccountTab: "",
      AccHolTab: "",
      AccNoTab: "",
      BankNameTab: "",
      AmountTab: "",
      PrintNameTab: "",
      Remarktab: "",
      PaymentListTab: "",
      PaymentFIlterTab: "",
      PaymentSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnCancel: "",
      BtnAdjust: "",
    },
    DebitCredit: {
      iDebCrdId: 0,
      iScrId: 0,
      iBranchId: 0,
      strTransNo: "",
      strVouchNo: "",
      strVouchDate: new Date().toISOString().split("T")[0],
      iVenTypeId: 1,
      iVenId: 0,
      strVenName: "",
      strRemark: "",
      dAmount: (0).toFixed(2),
      isCancel: false,
    },
    DebCrdHT: {
      arrNoFormat: [],
      arrEntHT: [],
      arrVenType: [],
      arrValidation: [],
    },
    DebCrdTab: {
      VoucherTab: "",
      TransNoTab: "",
      TranDate: "",
      VouchDateTap: "",
      VenNameTab: "",
      VenTypeTab: "",
      AmountTab: "",
      Remarktab: "",
      ArrTab: "",
      FilterTab: "",
      SearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnCancel: "",
    },
    SettleMentHT: {
      iScrId: 0,
      iPayRecId: 0,
      iVouchId: 0,
      iVenTypeId: 0,
      strVouchType: "",
      iVenId: 0,
      strVenName: 0,
      strVouchNo: "",
      dAmount: (0).toFixed(2),
      dBalAmount: (0).toFixed(2),
      dUnSettleAmount: (0).toFixed(2),
      iHTCount: 0,
      iDTCount: 0,
      iVenScrId: 0,
      strVenScrCol: "",
      strFromDate: new Date().toISOString().split("T")[0],
      strToDate: new Date().toISOString().split("T")[0],
      isUnSettle: true,
    },
    SettleMentDT: {
      arrVenType: [],
      arrVouchType: [],
      arrHTdata: [],
      arrDTdata: [],
    },
  },
  reducers: {
    setAcc_default: (state, actions) => {
      state.default = actions.payload;
    },
    setAcc_HT: (state, actions) => {
      state.HT = actions.payload;
    },
    setAcc_Party: (state, actions) => {
      state.default.strVenName = actions.payload.strVenName;
      state.default.iVenId = actions.payload.iVenId;
    },
    setAcc_PartyBank: (state, actions) => {
      state.default.iPayModeId = actions.payload.iPayModeId;
      state.default.strChequeNo = actions.payload.strChequeNo;
      state.default.strAccHolderName = actions.payload.strAccHolderName;
      state.default.iBankId = actions.payload.iBankId;
      state.default.strBankName = actions.payload.strBankName;
      state.default.strAccNo = actions.payload.strAccNo;
    },
    setAcc_BanKName: (state, actions) => {
      state.default.iBankId = actions.payload.iBankId;
      state.default.strBankName = actions.payload.strBankName;
    },
    setAcc_InpuPermission: (state, actions) => {
      state.default.isCheque = actions.payload.isCheque;
      state.default.isBanAcc = actions.payload.isBanAcc;
    },
    setAcc_Payment: (state, actions) => {
      state.HT.arrPayment = actions.payload;
    },
    setAcc_VouchNo: (state, actions) => {
      state.default.iBranchId = actions.payload.iBranchId;
      state.default.iScrId = actions.payload.iScrId;
      state.default.iVenTypeId = actions.payload.iVenTypeId;
      state.default.strTransNo = actions.payload.strTransNo;
      state.default.strVouchNo = actions.payload.strVouchNo;
      state.default.iBankAccId = actions.payload.iBankAccId;
    },
    setAcc_TabOrder: (state, actions) => {
      state.TabOrder = actions.payload;
    },
    refresh_Acc: (state) => {
      state.default = {
        iBranchId: 0,
        iScrId: 0,
        iPayModeId: 1,
        iVenTypeId: 1,
        iBankAccId: 0,
        iPayRecId: 0,
        strTransNo: "",
        strVouchNo: "",
        strVouchDate: new Date().toISOString().split("T")[0],
        iVenId: 0,
        strVenName: "",
        strPrintName: "",
        strChequeNo: "",
        strChequeDate: new Date().toISOString().split("T")[0],

        iBankId: 0,
        strBankName: "",
        strAccHolderName: "",
        strAccNo: "",
        dAmount: (0).toFixed(2),
        strRemark: "",
        strSearch: "",

        isCheque: true,
        isBanAcc: true,
        isCancelStatus: false,
      };
      state.HT = {
        arrNoFormat: [],
        arrPayment: [],
        arrPayMode: [],
        arrBankAcc: [],
        arrBankAccDetails: [],
        arrVenType: [],
        arrValidateProperties: [],
      };
      state.TabOrder = {
        VoucherTab: "",
        TransNoTab: "",
        TranDate: "",
        VouchDateTap: "",
        VenNameTab: "",
        VenTypeTab: "",
        PayModeTab: "",
        CheckNoTab: "",
        CheckDateTab: "",
        BankAccountTab: "",
        AccHolTab: "",
        AccNoTab: "",
        BankNameTab: "",
        AmountTab: "",
        PrintNameTab: "",
        Remarktab: "",
        PaymentListTab: "",
        PaymentFIlterTab: "",
        PaymentSearchTab: "",
        BtnSavtab: "",
        BtnCleartab: "",
        BtnDeletetab: "",
        BtnClosetab: "",
        BtnCancel: "",
        BtnAdjust: "",
      };
    },
    refresh_ParyAcc: (state) => {
      state.default.strChequeNo = "";
      state.default.strAccNo = "";
      state.default.strAccHolderName = "";
      state.default.strBankName = "";
      state.default.iBankId = "";
      state.default.dAmount = (0).toFixed(2);
    },
    refAcc_Party: (state) => {
      state.default.iPayRecId = 0;
      state.default.iVenId = 0;
      state.default.strVenName = "";
      state.default.strPrintName = "";
      state.default.strRemark = "";
      state.default.strChequeNo = "";
      state.default.strAccNo = "";
      state.default.strAccHolderName = "";
      state.default.strBankName = "";
      state.default.iBankId = 0;
      state.default.dAmount = (0).toFixed(2);
    },
    setDebCrdDeafult: (state, actions) => {
      state.DebitCredit = actions.payload;
    },
    setDebCrdVenType: (state, actions) => {
      state.DebitCredit.iVenTypeId = actions.payload;
    },
    setDebCrdHT: (state, actions) => {
      state.DebitCredit.iScrId = actions.payload.iScrId;
      state.DebitCredit.iBranchId = actions.payload.iBranchId;
      state.DebitCredit.iVenTypeId = actions.payload.iVenTypeId;
      state.DebitCredit.strTransNo = actions.payload.strTransNo;
      state.DebitCredit.strVouchNo = actions.payload.strVouchNo;
      state.DebCrdHT.arrNoFormat = actions.payload.arrNoFormat;
      state.DebCrdHT.arrEntHT = actions.payload.arrEntHT;
      state.DebCrdHT.arrVenType = actions.payload.arrVenType;
      state.DebCrdHT.arrValidation = actions.payload.arrValidation;
    },
    setEntDebCrdHT: (state, actions) => {
      state.DebCrdHT.arrEntHT = actions.payload;
    },
    setAccDeCr_party: (state, actions) => {
      state.DebitCredit.strVenName = actions.payload.strVenName;
      state.DebitCredit.iVenId = actions.payload.iVenId;
    },
    setAccDeCr_Taborder: (state, actions) => {
      state.DebCrdTab = actions.payload;
    },
    refresh_AccDebCrd: (state) => {
      state.DebitCredit = {
        iDebCrdId: 0,
        iScrId: 0,
        iBranchId: 0,
        strTransNo: "",
        strVouchNo: "",
        strVouchDate: new Date().toISOString().split("T")[0],
        iVenTypeId: 1,
        iVenId: 0,
        strVenName: "",
        strRemark: "",
        dAmount: (0).toFixed(2),
        isCancel: false,
      };
      state.DebCrdHT = {
        arrNoFormat: [],
        arrEntHT: [],
        arrVenType: [],
        arrValidation: [],
      };
      state.DebCrdTab = {
        VoucherTab: "",
        TransNoTab: "",
        TranDate: "",
        VouchDateTap: "",
        VenNameTab: "",
        VenTypeTab: "",
        AmountTab: "",
        Remarktab: "",
        ArrTab: "",
        FilterTab: "",
        SearchTab: "",
        BtnSavtab: "",
        BtnCleartab: "",
        BtnDeletetab: "",
        BtnClosetab: "",
        BtnCancel: "",
      };
    },
    refresh_AccDeCrParty: (state) => {
      state.DebitCredit = {
        iVenId: 0,
        strVenName: "",
        strRemark: "",
        dAmount: (0).toFixed(2),
        isCancel: false,
      };
    },
    setSettlementDefault: (state, actions) => {
      state.SettleMentHT.iScrId = actions.payload.iScrId;
      state.SettleMentHT.iVenTypeId = actions.payload.iVenTypeId;
      state.SettleMentHT.iVenScrId = actions.payload.iVenScrId;
      state.SettleMentHT.strVenScrCol = actions.payload.strVenScrCol;
      state.SettleMentHT.strFromDate = actions.payload.strFromDate;
      state.SettleMentDT.arrVenType = actions.payload.arrVenType;
      state.SettleMentDT.arrVouchType = actions.payload.arrVouchType;
    },
    setSettlementVoucher: (state, actions) => {
      state.SettleMentHT.iHTCount = actions.payload.iHTCount;
      state.SettleMentHT.iDTCount = actions.payload.iDTCount;
      state.SettleMentDT.arrHTdata = actions.payload.arrHTdata;
      state.SettleMentDT.arrDTdata = actions.payload.arrDTdata;
    },
    setSettlementVenParem: (state, actions) => {
      state.SettleMentHT.iScrId = actions.payload.iScrId;
      state.SettleMentHT.iVenScrId = actions.payload.iVenScrId;
      state.SettleMentHT.strVenScrCol = actions.payload.strVenScrCol;
      state.SettleMentHT.iVenTypeId = actions.payload.iVenTypeId;
    },
    setSettlementVendor: (state, { payload }) => {
      state.SettleMentHT.iVenId = payload.partyId;
      state.SettleMentHT.strVenName = payload.partyName;
    },
    setsettlementtab: (state, actions) => {
      state.SettleMentHT.isUnSettle = actions.payload;
    },
    setsettlementfromdate: (state, actions) => {
      state.SettleMentHT.strFromDate = actions.payload;
    },
    setsettlementtodate: (state, actions) => {
      state.SettleMentHT.strToDate = actions.payload;
    },
    setsettlementvouchtype: (state, actions) => {
      state.SettleMentHT.iScrId = actions.payload;
    },
    setsettlementdAmount: (state, actions) => {
      state.SettleMentHT.dAmount = actions.payload;
    },
    setselectedvoucher: (state, actions) => {
      state.SettleMentHT.iVouchId = actions.payload.iVouchId;
      state.SettleMentHT.strVouchNo = actions.payload.strVouchNo;
      state.SettleMentHT.dUnSettleAmount = actions.payload.dUnSettleAmount;
      state.SettleMentHT.strVouchType = actions.payload.strVouchType;
      state.SettleMentHT.dAmount = actions.payload.dAmount;
    },
    setselectedpayrec: (state, actions) => {
      state.SettleMentHT.iPayRecId = actions.payload.iPayRecId;
      state.SettleMentHT.dBalAmount = actions.payload.dBalAmount;
      state.SettleMentHT.arrDTdata = actions.payload.arrDTdata;
    },
    setnewsettlment: (state, actions) => {
      state.SettleMentDT.arrDTdata = actions.payload.arrDTdata;
      state.SettleMentHT.dBalAmount = actions.payload.dBalAmount;
    },
    refresh_Settlement: (state) => {
      state.SettleMentHT = {
        iScrId: 0,
        iPayRecId: 0,
        iVouchId: 0,
        iVenTypeId: 0,
        strVouchType: "",
        iVenId: 0,
        strVenName: 0,
        strVouchNo: "",
        dAmount: (0).toFixed(2),
        dBalAmount: (0).toFixed(2),
        dUnSettleAmount: (0).toFixed(2),
        iHTCount: 0,
        iDTCount: 0,
        iVenScrId: 0,
        strVenScrCol: 0,
        strFromDate: new Date().toISOString().split("T")[0],
        strToDate: new Date().toISOString().split("T")[0],
        isUnSettle: true,
      };
      state.SettleMentDT = {
        arrVenType: [],
        arrVouchType: [],
        arrHTdata: [],
        arrDTdata: [],
      };
    },
    refresh_Settlementdt: (state) => {
      state.SettleMentHT.strVouchNo = "";
      state.SettleMentHT.dAmount = 0;
      state.SettleMentHT.dUnSettleAmount = 0;
    },
    refresh_Settlementtab: (state) => {
      state.SettleMentHT.iScrId = 0;
      state.SettleMentHT.iPayRecId = 0;
      state.SettleMentHT.iVouchId = 0;
      state.SettleMentHT.iVenTypeId = 0;
      state.SettleMentHT.strVouchType = "";
      state.SettleMentHT.strVenName = "";
      state.SettleMentHT.iVenId = 0;
      state.SettleMentHT.strVouchNo = "";
      state.SettleMentHT.dAmount = 0;
      state.SettleMentHT.dBalAmount = 0;
      state.SettleMentHT.dUnSettleAmount = 0;
      state.SettleMentHT.iHTCount = 0;
      state.SettleMentHT.iDTCount = 0;
      state.SettleMentHT.iVenScrId = 0;
      state.SettleMentHT.strVenScrCol = "";
      state.SettleMentDT.arrHTdata = [];
      state.SettleMentDT.arrDTdata = [];
    },
  },
});
export const {
  setAcc_default,
  setAcc_HT,
  setAcc_Party,
  setAcc_BanKName,
  setAcc_VouchNo,
  setAcc_InpuPermission,
  setAcc_PartyBank,
  setAcc_TabOrder,
  setAcc_Payment,
  refresh_ParyAcc,
  refresh_Acc,
  refAcc_Party,
  setDebCrdDeafult,
  setDebCrdHT,
  setEntDebCrdHT,
  refresh_AccDebCrd,
  setDebCrdVenType,
  refresh_AccDeCrParty,
  setAccDeCr_party,
  setAccDeCr_Taborder,
  setSettlementDefault,
  setSettlementVenParem,
  setSettlementVendor,
  setsettlementtab,
  setsettlementfromdate,
  setsettlementtodate,
  setSettlementVoucher,
  setsettlementvouchtype,
  setselectedvoucher,
  setsettlementdAmount,
  setselectedpayrec,
  setnewsettlment,
  refresh_Settlement,
  refresh_Settlementdt,
  refresh_Settlementtab,
} = AccountsSlice.actions;
export default AccountsSlice.reducer;
