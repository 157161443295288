import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import {
  Select_AttProduct,
  Select_BatchProduct,
  Select_Product,
  ShowHide_Popup,
} from "../features/Transactionstates";
import { useEffect } from "react";
import { API_POST } from "../General/Utility";
import PopupSearch from "./PopupSearch";

function TransProductSearch(props) {
  const dispatch = useDispatch();

  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [arrProduct, setarrProduct] = useState([]);
  const [arrEntWH, setarrEntWH] = useState([]);
  const [iWHId, setiWHId] = useState(0);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_StockbyProduct();
  }, []);
  const Load_StockbyProduct = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const parems = {
        iScrId: _Common.iScrId,
        ScrType: _Common.strScrType,
        iPartyId: _HT.iPartyId,
        TaxRelId: _HT.iTaxRelId,
        strSearch: "",
        iWHId: props.WHId ? props.WHId : iWHId,
        iBranchId: _AuthParems.Branch,
        iUserId: Number(_AuthParems.User),
        iRoleId: Number(_AuthParems.Role),
        iClientId: Number(_AuthParems.Client),
        strDBName: _AuthParems.DB,
      };
      setiWHId(parems.WHId);
      const res = await API_POST(
        `ProductTrans/Load_StockbyProduct/`,
        parems,
        dispatch
      );
      if (res) {
        setarrEntTableCol(res.EntTablDesign);
        setarrProduct(res.EntProduct);
        setarrEntWH(res.EntWH);
        setiWHId(res.iWHId);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Warehouse_OnChange = async (WHId) => {
    try {
      await Pre_Loading(dispatch, true);
      setiWHId(WHId);
      const parems = {
        iScrId: _Common.iScrId,
        ScrType: _Common.strScrType,
        iPartyId: _HT.iPartyId,
        TaxRelId: _HT.iTaxRelId,
        strSearch: strSearch,
        iWHId: WHId,
        iBranchId: _AuthParems.Branch,
        iUserId: Number(_AuthParems.User),
        iRoleId: Number(_AuthParems.Role),
        iClientId: Number(_AuthParems.Client),
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `ProductTrans/Search_StockbyProduct/`,
        parems,
        dispatch
      );
      if (res) {
        setarrProduct(res.EntProduct);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Product = async (strtxt) => {
    try {
      await Pre_Loading(dispatch, true);
      setstrSearch(strtxt);
      const parems = {
        iScrId: _Common.iScrId,
        ScrType: _Common.strScrType,
        iPartyId: _HT.iPartyId,
        TaxRelId: _HT.iTaxRelId,
        strSearch: strSearch,
        iWHId: iWHId,
        iBranchId: _AuthParems.Branch,
        iUserId: Number(_AuthParems.User),
        iRoleId: Number(_AuthParems.Role),
        iClientId: Number(_AuthParems.Client),
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `ProductTrans/Search_StockbyProduct/`,
        parems,
        dispatch
      );
      if (res) {
        setarrProduct(res.EntProduct);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Product = async (Product) => {
    try {
      await Pre_Loading(dispatch, true);
      const objTrans = {
        iScrId: _Common.iScrId,
        ScrType: _Common.strScrType,
        iPartyId: _HT.iPartyId,
        iTaxRelId: _HT.iTaxRelId,
        Search: strSearch,
        iWHId: props.WHId ? props.WHId : iWHId,
        iBranchId: _AuthParems.Branch,
        strDBName: _AuthParems.DB,
        iProId: Product.proId,
        isComposPro: Product.isComposPro,
        isBatchPro: Product.isBatchPro,
        isAttPro: Product.isAttPro,
        iAttGrpId: Product.iAttGrpId,
        TransType: "Transaction",
      };
      if (Product.isAttPro === 1) {
        Product.WHId = iWHId;
        await dispatch(Select_AttProduct(Product));
        await dispatch(ShowHide_Popup("IsAttPro"));
      } else {
        const res = await API_POST(
          `ProductTrans/View_Product/`,
          objTrans,
          dispatch
        );
        if (res) {
          res.WHId = iWHId;
          if (Product.isAttPro === 0 && Product.isBatchPro === 1) {
            await dispatch(Select_BatchProduct(res));
            await dispatch(ShowHide_Popup("IsBatchPro"));
          } else {
            await Popup_Close();
            if (props.View_Product) await props.View_Product(res);
            else await dispatch(Select_Product(res));
          }
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Popup_Close = async () => {
    await dispatch(ShowHide_Popup("IsProSearch"));
  };
  return (
    <PopupSearch
      ScrName="PRODUCT SEARCH "
      FilterCol={[]}
      FilterVal={""}
      FilterShow={false}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrProduct}
      Warehouse={arrEntWH}
      WHId={iWHId}
      Wharehouse_Onchange={(val) => Warehouse_OnChange(val)}
      IsWareHouse={_Common.IsMultiWarehouse}
      UpdateFilterCol={""}
      Search_Data={(e) => Search_Product(e)}
      View_Data={(e) => View_Product(e)}
      Close={() => Popup_Close()}
      disableWH={props.WHId ? true : false}
    />
  );
}
export default TransProductSearch;
