import { API_POST } from "../General/Utility";
import { Show_Message } from "../General/Messagedialogbox";

export const Country_OnChange = async (iCoutryId, DB, dispatch) => {
  let EntState = [];
  try {
    const parem = {
      strDBName: DB,
      iCountryId: iCoutryId,
    };
    const res = await API_POST(`Common/Country_Onchange`, parem, dispatch);
    if (res) {
      EntState = res.EntState;
    }
  } catch (err) {
    Show_Message(dispatch, err.message, "error");
  } finally {
    return EntState;
  }
};
