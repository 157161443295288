import React from "react";
import Attributeselect from "./Attributeselect";
import { Show_Message } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { API_FETCH } from "../General/Utility";
function AttributeProduct(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const add_Newattribute = () => {
    let EmptyAtt = props.attOption.filter(
      (opt) => opt.arrAttValue.length === 0
    ).length;
    if (EmptyAtt > 0) {
      Show_Message(dispatch, "Please Add Attribute Value", "info");
    } else {
      if (props.iAttGrpId === 0) {
        props.setattOption([
          ...props.attOption,
          {
            iAttId: 0,
            strAttribute: "",
            strAttVal: "",
            iAttValId: 0,
            isAttribute: false,
            iAttGrpId: 0,
            arrAttValue: [],
          },
        ]);
      }
    }
  };
  const show_attribute = (val, Index) => {
    let EntAtt = props.attOption;
    EntAtt = EntAtt.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt.isAttribute = val;
      }
      return dt;
    });
    props.setattOption(EntAtt);
  };
  const select_attribute = (val, Index) => {
    let EntAtt = props.attOption;
    EntAtt = EntAtt.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt.strAttribute = val.strAttribute;
        dt.iAttId = val.iAttId;
      }
      return dt;
    });
    $(`.atttable:eq(${Index + 1}) .attoption input`).focus();
    props.setattOption(EntAtt);
  };
  const add_attributeoption = (val, Index) => {
    let EntAtt = props.attOption;
    EntAtt = EntAtt.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt.strAttVal = val;
      }
      return dt;
    });
    props.setattOption(EntAtt);
  };
  const add_attributevalue = (Index) => {
    let EntAtt = props.attOption;
    EntAtt = EntAtt.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt.arrAttValue.push({
          iAttId: dt.iAttId,
          iAttValId: 0,
          iAttGrpId: dt.iAttGrpId,
          strAttribute: dt.strAttribute,
          strAttVal: dt.strAttVal,
        });
        dt.strAttVal = "";
      }
      return dt;
    });
    update_attribute(EntAtt);
  };
  const delete_attribute = (Index) => {
    let EntAtt = props.attOption;
    EntAtt = EntAtt.filter((dt, dtIndex) => dtIndex !== Index);
    update_attribute(EntAtt);
  };
  const delete_attributevalue = async (attval) => {
    let EntAtt = props.attOption.map((att) => {
      att.arrAttValue = att.arrAttValue.filter(
        (val) => val.strAttVal !== attval.strAttVal
      );
      return att;
    });
    let EntProduct = props.ListAttProduct.filter((pro) =>
      pro.strAttValues.toLowerCase().includes(attval.strAttVal.toLowerCase())
    );
    if (EntProduct.length > 0) {
      let ProId = props.strProId;
      EntProduct.forEach((pro) => {
        ProId += pro.iProId + ",";
      });
      ProId = ProId.slice(0, -1);
      let attvalId = props.strAttValId;
      attvalId += attval.iAttValId + ",";
      attvalId = attvalId.slice(0, -1);
      const res = await API_FETCH(
        `ProductMaster/Check_ForeignKeyValidation/${ProId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        let EntPro = props.ListAttProduct.filter(
          (dt) =>
            !dt.strAttValues
              .toLowerCase()
              .includes(attval.strAttVal.toLowerCase())
        );
        props.setListAttProduct(EntPro);
        props.setstrProId(ProId);
        props.setstrAttValId(attvalId);
      } else {
        props.setstrProId("");
        Show_Message(dispatch, res.strMessage, "error");
      }
    } else {
      update_attribute(EntAtt);
    }
  };
  const Pair_Attributes = (arrs, result = []) => {
    if (arrs.length === 0) return [result];
    const combinations = [];
    for (const value of arrs[0].arrAttValue) {
      for (const subCombination of Pair_Attributes(arrs.slice(1), [
        ...result,
        {
          iAttId: arrs[0].iAttId,
          strAttribute: arrs[0].strAttribute,
          strAttVal: value.strAttVal,
          iAttValId: value.iAttValId,
        },
      ])) {
        combinations.push(subCombination);
      }
    }
    return combinations;
  };
  const Join_Attvalues = (att) => {
    let strattval = "";
    att.forEach((dt) => {
      strattval += `${dt.strAttVal} `;
    });
    return strattval;
  };
  const update_attribute = (EntAtt) => {
    EntAtt = EntAtt.filter((dt) => dt.arrAttValue.length > 0);
    if (EntAtt.length === 0) {
      props.setattOption([
        {
          iAttId: 0,
          strAttribute: "",
          strAttVal: "",
          iAttValId: 0,
          isAttribute: false,
          iAttGrpId: 0,
          arrAttValue: [],
        },
      ]);
      props.setListAttProduct([]);
    } else {
      props.setattOption(EntAtt);
      let products = Pair_Attributes(EntAtt);
      products = products.filter((dt) => {
        return dt.filter((dval) => dval.iAttValId === 0).length > 0;
      });
      let listproduct = [];
      if (props.iAttGrpId > 0) {
        let EntPro = props.ListAttProduct.filter((dt) => dt.iProId > 0);
        for (let p = 0; p < EntPro.length; p++) {
          const AttPro = EntPro[p];
          let objProduct = {
            iTitleTag: props.iScrId,
            iProId: AttPro.iProId,
            iBranchId: Number(_AuthParems.Branch),
            iNumSeqNo: AttPro.iNumSeqNo,
            strPrefix: AttPro.strPrefix,
            strProCode: AttPro.strProCode,
            strProName: AttPro.strProName,
            strAttValues: AttPro.strAttValues,
            arrAttValues: products[p],
            strRemark: AttPro.strRemark,
            strPrintName: AttPro.strPrintName,
            strCommodityCode: AttPro.strCommodityCode,
            iCat1Id: props.iCat1Id,
            iCat2Id: props.iCat2Id,
            iCat3Id: props.iCat3Id,
            iStatusId: props.iStatusId,
            iProTypeId: props.iProTypeId,
            iProGenreId: props.iProGenreId,
            dPriceRate: Number(AttPro.dPriceRate).toFixed(2),
            dCostRate: Number(AttPro.dCostRate).toFixed(2),
            dMinMargin: Number(props.dMinMargin).toFixed(2),
            dDiscount: Number(props.dDiscount).toFixed(2),
            iTaxTypeId: Number(props.iTaxTypeId),
            iSGSTTaxId: Number(props.iSGSTTaxId),
            iCGSTTaxId: Number(props.iCGSTTaxId),
            iIGSTTaxId: Number(props.iIGSTTaxId),
            bQtyDecimal: AttPro.bQtyDecimal,
            bStockMaintain: props.chkStockMaintainYes,
            isBatchPro: props.IsBatchPro,
            strConcatAttNames: props.strConcatAttNames,
            strAll: "",
            strAttNames: "",
            strCat1Name: "",
            strCat2Name: "",
            strCat3Name: "",
            strFilter: "",
            strFilterColumn: "",
            strMasterProCodeTag: "",
            strProCodeTag: "",
            strProDescription1: AttPro.strProDescription1,
            strProDescription2: AttPro.strProDescription2,
            strProGenreName: "",
            strProTypeName: "",
            strSearch: "",
            strStatusName: "",
            strUnitName: "",
            iBrandid: AttPro.iBrandid,
            iManufacturerid: AttPro.iManufacturerid,
            iProductLocationId: AttPro.iProductLocationId,
            iAttGrpId: props.iAttGrpId,
            dReOrderQty: AttPro.dReOrderQty,
            ImgData: AttPro.imgData,
            strImgType: AttPro.strImgType,
            strImgName: AttPro.strImgName,
            strUPC: AttPro.strUPC,
            strEAN: AttPro.strEAN,
            strISBN: AttPro.strISBN,
          };
          listproduct.push(objProduct);
        }
      }
      for (let P = 0; P < products.length; P++) {
        let NoSeq = props.listCodeFormat[0].iNumSeqNo;
        let Prefix = props.listCodeFormat[0].strPrefix;
        let maxdigit = props.listCodeFormat[0].iMaxDegit;
        let attval = Join_Attvalues(products[P]);
        let objProduct = {
          iTitleTag: props.iScrId,
          iProId: 0,
          iBranchId: Number(_AuthParems.Branch),
          iNumSeqNo: NoSeq + P,
          strPrefix: Prefix,
          strProCode: Prefix + String(NoSeq + P).padStart(maxdigit, "0"),
          strProName: props.strProName,
          strAttValues: attval,
          arrAttValues: products[P],
          strRemark: props.strRemarks,
          strPrintName: props.strPrintName,
          strCommodityCode: props.strHSNCode,
          iCat1Id: props.iCat1Id,
          iCat2Id: props.iCat2Id,
          iCat3Id: props.iCat3Id,
          iStatusId: props.iStatusId,
          iProTypeId: props.iProTypeId,
          iProGenreId: props.iProGenreId,
          dPriceRate: Number(props.dPriceRate).toFixed(2),
          dMinMargin: Number(props.dMinMargin).toFixed(2),
          dCostRate: Number(props.dCostRate).toFixed(2),
          dDiscount: Number(props.dDiscount).toFixed(2),
          iTaxTypeId: Number(props.iTaxTypeId),
          iSGSTTaxId: Number(props.iSGSTTaxId),
          iCGSTTaxId: Number(props.iCGSTTaxId),
          iIGSTTaxId: Number(props.iIGSTTaxId),
          bQtyDecimal: true,
          bStockMaintain: props.chkStockMaintainYes,
          isBatchPro: props.IsBatchPro,
          strConcatAttNames: props.strConcatAttNames,
          strAll: "",
          strAttNames: "",
          strCat1Name: "",
          strCat2Name: "",
          strCat3Name: "",
          strFilter: "",
          strFilterColumn: "",
          strMasterProCodeTag: "",
          strProCodeTag: "",
          strProDescription1: "",
          strProDescription2: "",
          strProGenreName: "",
          strProTypeName: "",
          strSearch: "",
          strStatusName: "",
          strUnitName: "",
          iBrandid: props.ibrandid,
          iManufacturerid: props.imanufacid,
          iProductLocationId: props.ilocationid,
          iAttGrpId: props.iAttGrpId,
          dReOrderQty: Number((10).toFixed(2)),
          ImgData: props.ImageByteArray,
          strImgType: "",
          strImgName: props.strImageName,
          strUPC: props.strUPC,
          strEAN: props.strEAN,
          strISBN: props.strISBN,
        };
        listproduct.push(objProduct);
      }
      // Sorting the array alphabetically by the "value" property
      listproduct.sort((a, b) => {
        let valueA = a.strAttValues.toLowerCase();
        let valueB = b.strAttValues.toLowerCase();
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
        return 0;
      });
      props.setListAttProduct(listproduct);
    }
  };
  return (
    <div>
      <div className="Att-Container">
        <label>Multiple Attribute</label>
        {props.attOption.map((att, Index) => (
          <div className="atttable" key={Index}>
            <div className="sec">
              {Index === 0 && <label className="danger">Attribute</label>}
              <br />
              <Attributeselect
                attribute={att.strAttribute}
                arrydata={props.ListAtttribute}
                disabled={false}
                showoption={att.isAttribute}
                showatt={(val) => show_attribute(val, Index)}
                select_value={(val) => select_attribute(val, Index)}
              />
            </div>
            <div className="sec">
              {Index === 0 && <label className="danger">Options</label>}
              <br />
              <div className="attoption">
                <div className="attlable">
                  {att.arrAttValue.map((val, tdIndex) => (
                    <label
                      key={tdIndex}
                      onClick={(e) => delete_attributevalue(val)}
                    >
                      {val.strAttVal}
                      <span>
                        <i
                          style={{
                            color: val.iAttValId > 0 ? "lightblue" : "gray",
                          }}
                          className="fa-solid fa-circle-xmark"
                        ></i>
                      </span>
                    </label>
                  ))}
                </div>
                <input
                  type="text"
                  value={att.strAttVal}
                  placeholder="Attribute Option"
                  onChange={(e) => add_attributeoption(e.target.value, Index)}
                  onKeyDown={(e) => {
                    if (att.strAttVal !== "" && e.key === "Enter")
                      add_attributevalue(Index);
                  }}
                />
              </div>
            </div>
            <div className="sec">
              {Index === 0 && <label className="danger">Remove</label>}
              <br />
              <i
                style={{ color: props.iProId > 0 ? "lightblue" : "gray" }}
                className="fa-solid fa-trash mt-3"
                onClick={(e) => {
                  if (props.iProId === 0) delete_attribute(Index);
                }}
              ></i>
            </div>
          </div>
        ))}
      </div>
      <label
        className="btn-More"
        style={{ color: props.iProId > 0 ? "gray" : "#2098ff" }}
      >
        <i className="fa-solid fa-circle-plus"></i>
        <span onClick={add_Newattribute}>Add More Attribute</span>
      </label>
      <div className="html-table">
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: "200px" }}>Item Name</th>
              <th>Print Name</th>
              <th className="amt">
                Cost Price <small> (₹)</small>
              </th>
              <th className="amt">
                Sale Price <small> (₹)</small>
              </th>
              <th>Reorder Qty</th>
              <th>UPC</th>
              <th>EAN</th>
              <th>ISBN</th>
            </tr>
          </thead>
          <tbody>
            {props.ListAttProduct.map((Dt, Index) => (
              <tr key={Index}>
                <td style={{ minWidth: "200px" }}>
                  <input
                    type="text"
                    disabled={props.iProId > 0 ? true : false}
                    value={Dt.strAttValues}
                    className="form-control"
                    onChange={(e) => {
                      let attproduct = props.ListAttProduct;
                      attproduct = attproduct.map((Dt, AttIndex) => {
                        if (AttIndex === Index) Dt.strProName = e.target.value;
                        return Dt;
                      });
                      props.setListAttProduct(attproduct);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={Dt.strPrintName}
                    className="form-control"
                    placeholder="Print Name"
                    onChange={(e) => {
                      let attproduct = props.ListAttProduct;
                      attproduct = attproduct.map((Dt, AttIndex) => {
                        if (AttIndex === Index)
                          Dt.strPrintName = e.target.value;
                        return Dt;
                      });
                      props.setListAttProduct(attproduct);
                    }}
                  />
                </td>
                <td className="amt">
                  <input
                    type="number"
                    value={Dt.dCostRate}
                    className="form-control"
                    onChange={(e) => {
                      let attproduct = props.ListAttProduct;
                      attproduct = attproduct.map((Dt, AttIndex) => {
                        if (AttIndex === Index) {
                          Dt.dCostRate = e.target.value;
                          Dt.dPriceRate =
                            Number(e.target.value) > Dt.dPriceRate
                              ? e.target.value
                              : Dt.dPriceRate;
                        }
                        return Dt;
                      });
                      props.setListAttProduct(attproduct);
                    }}
                    onClick={(e) => e.target.select()}
                  />
                </td>
                <td className="amt">
                  <input
                    type="number"
                    value={Dt.dPriceRate}
                    className="form-control"
                    onChange={(e) => {
                      let attproduct = props.ListAttProduct;
                      attproduct = attproduct.map((Dt, AttIndex) => {
                        if (AttIndex === Index) {
                          Dt.dPriceRate = e.target.value;
                        }
                        return Dt;
                      });
                      props.setListAttProduct(attproduct);
                    }}
                    onClick={(e) => e.target.select()}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    disabled={
                      props.iProId > 0 && props.iAttGrpId === 0 ? true : false
                    }
                    value={Dt.dReOrderQty}
                    className="form-control"
                    onChange={(e) => {
                      let attproduct = props.ListAttProduct;
                      attproduct = attproduct.map((Dt, AttIndex) => {
                        if (AttIndex === Index) Dt.dReOrderQty = e.target.value;
                        return Dt;
                      });
                      props.setListAttProduct(attproduct);
                    }}
                    onClick={(e) => e.target.select()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    disabled={
                      props.iProId > 0 && props.iAttGrpId === 0 ? true : false
                    }
                    value={Dt.strUPC}
                    className="form-control"
                    placeholder="UPC Code"
                    onChange={(e) => {
                      let attproduct = props.ListAttProduct;
                      attproduct = attproduct.map((Dt, AttIndex) => {
                        if (AttIndex === Index) Dt.strUPC = e.target.value;
                        return Dt;
                      });
                      props.setListAttProduct(attproduct);
                    }}
                    onClick={(e) => e.target.select()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    disabled={
                      props.iProId > 0 && props.iAttGrpId === 0 ? true : false
                    }
                    value={Dt.strEAN}
                    className="form-control"
                    placeholder="EAN Code"
                    onChange={(e) => {
                      let attproduct = props.ListAttProduct;
                      attproduct = attproduct.map((Dt, AttIndex) => {
                        if (AttIndex === Index) Dt.strEAN = e.target.value;
                        return Dt;
                      });
                      props.setListAttProduct(attproduct);
                    }}
                    onClick={(e) => e.target.select()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    disabled={
                      props.iProId > 0 && props.iAttGrpId === 0 ? true : false
                    }
                    value={Dt.strISBN}
                    placeholder="ISBN Code"
                    className="form-control"
                    onChange={(e) => {
                      let attproduct = props.ListAttProduct;
                      attproduct = attproduct.map((Dt, AttIndex) => {
                        if (AttIndex === Index) Dt.strISBN = e.target.value;
                        return Dt;
                      });
                      props.setListAttProduct(attproduct);
                    }}
                    onClick={(e) => e.target.select()}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default AttributeProduct;
