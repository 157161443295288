import React, { useEffect, useState } from "react";
import AccountsButton from "./AccountsButton";
import SideMenu from "../General/SideMenu";
import MasterList from "../Master/MasterListItem";
import PayRecBody from "./PayRecBody";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import Messagedialogbox from "../General/Messagedialogbox";
import {
  refresh_Acc,
  setAcc_HT,
  setAcc_InpuPermission,
  setAcc_Payment,
  setAcc_TabOrder,
  setAcc_VouchNo,
  setAcc_default,
} from "../features/Accounts";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
function Payment() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _AccParem = useSelector((state) => state.Accounts.default);
  const _HTParem = useSelector((state) => state.Accounts.HT);
  const _TabParem = useSelector((state) => state.Accounts.TabOrder);
  const [strSearch, setstrSearch] = useState("");
  useEffect(() => {
    Load_Payment();
  }, []);
  const Load_Payment = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `Payment/Load_Payment/${Number(_LocalParems.Branch)}/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        dispatch(
          setAcc_HT({
            arrNoFormat: res.objEntNoFormat,
            arrPayment: res.objEntPayment,
            arrPayMode: res.objEntPayMode,
            arrBankAcc: res.objEntBank,
            arrBankAccDetails: [],
            arrVenType: res.objEntVenType,
            arrValidateProperties: res.Transtab,
          })
        );
        dispatch(
          setAcc_VouchNo({
            iBranchId: Number(_LocalParems.Branch),
            iScrId: 41,
            iVenTypeId: 1,
            strTransNo: res.objEntNoFormat[0].strTransNo,
            strVouchNo: res.objEntNoFormat[0].strUserNo,
            iBankAccId:
              res.objEntBank.length > 0 ? res.objEntBank[0].bankAccId : 0,
          })
        );
        TabOrderAssign(res.Transtab);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Payment = async (strtext) => {
    try {
      await Pre_Loading(dispatch, true);
      setstrSearch(strtext);
      const objSearch = {
        iBranchId: Number(_AuthParems.Branch),
        iScrId: "",
        iPartyId: "",
        strColName: "All",
        strSearch: strtext,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST("Payment/Search_Payment/", objSearch);
      if (res) {
        dispatch(setAcc_Payment(res.objEntPay));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = () => {
    dispatch(refresh_Acc());
    Load_Payment();
  };
  const Cancel_Payment = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `Payment/Cancel_Payment/${_AccParem.iPayRecId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Save_Payment = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const objInsUpdPay = {
        iPayId: _AccParem.iPayRecId,
        iBranchId: _AccParem.iBranchId,
        strPayNo: _AccParem.strVouchNo,
        strTransNo: _AccParem.strTransNo,
        dpPayDate: _AccParem.strVouchDate,
        strPrefix: _HTParem.arrNoFormat[0].strPrefix,
        iNumSeqNo: _HTParem.arrNoFormat[0].iNumSeqNo,
        strCompCode: _HTParem.arrNoFormat[0].strCompCode,
        strFinanFrom: _HTParem.arrNoFormat[0].strFinanFrom,
        iVenType: _AccParem.iVenTypeId,
        iVenId: _AccParem.iVenId,
        iPayModeId: _AccParem.iPayModeId,
        dAmount: _AccParem.dAmount,
        iBankAccId: _AccParem.iBankAccId,
        strChequeNo: _AccParem.strChequeNo,
        dpChequeDate: _AccParem.strChequeDate,
        strAccNo: _AccParem.strAccNo,
        strAccHolderName: _AccParem.strAccHolderName,
        strPrintName: _AccParem.strPrintName,
        strRemark: _AccParem.strRemark,
        iBNId: _AccParem.iBankId,
        iVouchId: 0,
        iCallerScrId: 0,
        strBankName: _AccParem.strBankName,
        strFilter: "",
        strFilterColumn: "All",
        strSearch: "",
        strVenName: _AccParem.strVenName,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "Payment/InsUpd_Payment/",
        objInsUpdPay,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Payment = async (PayId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `Payment/View_Payment/${PayId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        let objVoucher = res.objEntPay;
        dispatch(
          setAcc_default({
            iBranchId: _AccParem.iBranchId,
            iScrId: _AccParem.iScrId,
            iPayRecId: PayId,
            strTransNo: objVoucher.strTransNo,
            strVouchNo: objVoucher.strPayNo,
            strVouchDate: objVoucher.dpPayDate,
            iVenTypeId: objVoucher.iVenType,
            iVenId: objVoucher.iVenId,
            strVenName: objVoucher.strVenName,
            iPayModeId: objVoucher.iPayModeId,
            iBankAccId: objVoucher.iBankAccId,
            strChequeNo: objVoucher.strChequeNo,
            strChequeDate: objVoucher.dpChequeDate,
            strAccHolderName: objVoucher.strAccHolderName,
            strBankName: objVoucher.strBankName,
            iBankId: objVoucher.iBNId,
            strAccNo: objVoucher.strAccNo,
            strPrintName: objVoucher.strPrintName,
            strRemark: objVoucher.strRemark,
            dAmount: Number(objVoucher.dAmount).toFixed(2),
            isCheque: _AccParem.isCheque,
            isBanAcc: _AccParem.isBanAcc,
            isCancelStatus: objVoucher.bCancelStatus,
            strSearch: "",
          })
        );
        switch (objVoucher.iPayModeId) {
          case 1: // Cash
            dispatch(
              setAcc_InpuPermission({
                isCheque: true,
                isBanAcc: true,
              })
            );
            break;
          case 2: //Cheque / DD
            dispatch(
              setAcc_InpuPermission({
                isCheque: false,
                isBanAcc: false,
              })
            );
            break;
          default:
            dispatch(
              setAcc_InpuPermission({
                isCheque: true,
                isBanAcc: false,
              })
            );
            break;
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const TabOrderAssign = (EntProperties) => {
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      let colName = EntProperties[i].strCtrlName;
      let InOrder = EntProperties[i].iTabIndex;
      dispatch(
        setAcc_TabOrder({
          TransNoTab: colName === "txtEntNo" ? InOrder : _TabParem.TransNoTab,
          TranDate: colName === "dpEntDate" ? InOrder : _TabParem.TranDate,
          VoucherTab: colName === "txtVouchNo" ? InOrder : _TabParem.VoucherTab,
          VouchDateTap:
            colName === "dpVouchDate" ? InOrder : _TabParem.VouchDateTap,
          VenNameTab: colName === "txtVenName" ? InOrder : _TabParem.VenNameTab,
          VenTypeTab: colName === "cbVenType" ? InOrder : _TabParem.VenTypeTab,
          PayModeTab: colName === "cbPayMode" ? InOrder : _TabParem.PayModeTab,
          CheckNoTab:
            colName === "txtChequeNo" ? InOrder : _TabParem.CheckNoTab,
          CheckDateTab:
            colName === "dpChequeDate" ? InOrder : _TabParem.CheckDateTab,
          BankAccountTab:
            colName === "cbBankAcc" ? InOrder : _TabParem.BankAccountTab,
          AccHolTab:
            colName === "txtAccHolderName" ? InOrder : _TabParem.AccHolTab,
          AccNoTab: colName === "" ? InOrder : _TabParem.AccNoTab,
          BankNameTab:
            colName === "txtBankName" ? InOrder : _TabParem.BankNameTab,
          AmountTab: colName === "txtAmt" ? InOrder : _TabParem.AmountTab,
          PrintNameTab:
            colName === "txtPrintName" ? InOrder : _TabParem.PrintNameTab,
          Remarktab: colName === "txtRemark" ? InOrder : _TabParem.Remarktab,
          PaymentListTab:
            colName === "lbVouchers" ? InOrder : _TabParem.PaymentListTab,
          PaymentFIlterTab:
            colName === "cbFilter" ? InOrder : _TabParem.PaymentFIlterTab,
          PaymentSearchTab:
            colName === "txtSearch" ? InOrder : _TabParem.PaymentSearchTab,
          BtnSavtab: colName === "btnSave" ? InOrder : _TabParem.BtnSavtab,
          BtnCleartab: colName === "btnClear" ? InOrder : _TabParem.BtnCleartab,
          BtnDeletetab: colName === "" ? InOrder : _TabParem.BtnDeletetab,
          BtnClosetab: colName === "btnClose" ? InOrder : _TabParem.BtnClosetab,
          BtnCancel: colName === "btnCancel" ? InOrder : _TabParem.BtnCancel,
          BtnAdjust: colName === "btnAdjust" ? InOrder : _TabParem.BtnAdjust,
        })
      );
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = _HTParem.arrValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtVouchNo":
          ErrorMsg = _AccParem.strVouchNo === "" ? false : true;
          break;
        case "cbVenType":
          ErrorMsg = _AccParem.iVenTypeId === "" ? false : true;
          break;
        case "txtVenName":
          ErrorMsg = _AccParem.strVenName === "" ? false : true;
          break;
        case "txtPrintName":
          ErrorMsg = _AccParem.strPrintName === "" ? false : true;
          break;
        case "txtRemark":
          ErrorMsg = _AccParem.strRemark === "" ? false : true;
          break;
        case "txtChequeNo":
          ErrorMsg = _AccParem.strChequeNo === "" ? false : true;
          break;
        case "dpChequeDate":
          ErrorMsg = _AccParem.strChequeDate === "" ? false : true;
          break;
        case "txtBankName ":
          ErrorMsg = _AccParem.strBankName === "" ? false : true;
          break;
        case "txtAccHolderName":
          ErrorMsg = _AccParem.strAccHolderName === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  return (
    <main className="main-container">
      <SideMenu ScrId={41} />
      <div className="content">
        <MasterList
          ScrId={41}
          strSearchtxt={strSearch}
          tabOrder={_TabParem.PaymentSearchTab}
          Search={(val) => Search_Payment(val)}
          arrMasterData={_HTParem.arrPayment}
          isImg={false}
          displayName="strPayNo"
          Parem1="iPayId"
          View_Data={(iPay) => View_Payment(iPay)}
          placeholder="Payment"
        />
        <div className="main-content">
          <PayRecBody
            PartyUrl="Payment/View_Party/"
            ViewAllBankUrl="Payment/View_AllBank/"
            ViewPartyBankUrl="Payment/View_PartyBank/"
          />
          <Messagedialogbox />
        </div>
        <AccountsButton
          iVouchId={_AccParem.iPayRecId}
          iScrId={41}
          Save={(e) => {
            if (PropertiesValidation()) {
              if (
                Number(_AccParem.iPayModeId) === 2 &&
                _AccParem.strChequeNo === ""
              ) {
                Show_Message(dispatch, "Cheque/DD Number is Invalid", "info");
              } else if (Number(_AccParem.dAmount) === 0) {
                Show_Message(dispatch, "Please Enter Amount", "info");
              } else {
                Ask_Question(
                  dispatch,
                  Save_Payment,
                  "Do You Want to  Save Payment?"
                );
              }
            }
          }}
          Clear={ClearClick}
          Cancel={(e) => {
            if (Number(_AccParem.iPayRecId) === 0) {
              Show_Message(dispatch, "Please Select Payment", "info");
            } else {
              Ask_Question(
                dispatch,
                Cancel_Payment,
                "Do You Want to  Cancel Payment?"
              );
            }
          }}
        />
      </div>
    </main>
  );
}
export default Payment;
