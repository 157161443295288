import { useEffect, useState } from "react";

import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST } from "../General/Utility";
import { Merge_ConvertedVouchers } from "../features/Transactionstates";
import ReactTable from "../General/ReactTable";
function PendingConvertion(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _HTparem = useSelector((state) => state.Transstates.HT);
  const _DTparem = useSelector((state) => state.Transstates.DT);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _defaultparem = useSelector((state) => state.Transstates.Default);

  const [selected, setselected] = useState(0);
  const [isJobWork, setisJobWork] = useState(false);
  const [isConversion, setisConversion] = useState(true);
  const [iProId, setiProId] = useState(0);
  const [strProName, setstrProName] = useState("");
  const [dQty, setdQty] = useState(0);
  const [iUnitId, setiUnitId] = useState(0);
  const [UnitList, setUnitList] = useState([]);
  const [PendingConList, setPendingConList] = useState([]);
  const [arrtab, setarrtab] = useState([true, true, true, true]);
  useEffect(() => {
    setisJobWork(props.isConversion ? true : false);
    if (props.isConversion) Load_PenConversion();
    else Load_PendingScreens();
  }, []);
  const Load_PendingScreens = () => {
    let EntTab = arrtab;
    switch (_HTparem.iScrId) {
      case 14:
      case 8:
        EntTab = EntTab.map((dt) => {
          if (dt.SNo > 1) {
            dt.Show = false;
          }
          return dt;
        });
        break;
      case 15:
      case 9:
        SelectionChanged("Quo");
        EntTab = EntTab.map((dt) => {
          if (dt.SNo > 2) {
            dt.Show = false;
          }
          return dt;
        });
        break;
      case 16:
      case 10:
        SelectionChanged("Ord");
        EntTab = EntTab.map((dt) => {
          if (dt.SNo > 3) {
            dt.Show = false;
          }
          return dt;
        });
        break;
      default:
        break;
    }
    setarrtab(EntTab);
  };
  const Load_PenConversion = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `Transaction/Load_PendingConversion/${Number(_AuthParems.Branch)}/${
          _HTparem.iScrId
        }/${_HTparem.strScrType}/${Number(_HTparem.iPartyId)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        setPendingConList(res.EntPenConDT);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Convert_SelectedProduct = () => {
    let objEntPenCon = PendingConList.filter((DT) => DT.isChecked === true);
    if (objEntPenCon.length > 0) {
      let ConRowManiDT = [];
      objEntPenCon.forEach((DT) => {
        let EntConRowData = {
          sNo: DT.sNo,
          transType: DT.transType,
          vouchNo: DT.vouchNo,
          vouchDate: DT.vouchDate,
          proCode: DT.proCode,
          proName: DT.proName,
          qty: DT.qty,
          rateWithoutTax: Number(DT.rateWithoutTax).toFixed(2),
          taxPer: DT.taxPer,
          remark1: DT.remark1,
          remark2: DT.remark2,
          remark3: DT.remark3,
          remark4: DT.remark4,
          unit: DT.unit,
          unitId: DT.unitId,
          proId: DT.proId,
          partyId: DT.partyId,
          scrId: DT.scrId,
          vouchId: DT.vouchId,
          rowId: DT.rowId,
          productDimAttGroupId: DT.productDimAttGroupId,
          storageDimAttGroupId: DT.storageDimAttGroupId,
          trackingDimAttGroupId: DT.trackingDimAttGroupId,
          productDimAttId: DT.productDimAttId,
          storageDimAttId: DT.storageDimAttId,
          trackingDimAttId: DT.trackingDimAttId,
          productDimAttDTId: DT.productDimAttDTId,
          storageDimAttDTId: DT.storageDimAttDTId,
          trackingDimAttDTId: DT.trackingDimAttDTId,
          yearId: DT.yearId,
          value1: DT.value1,
          value2: DT.value2,
          value3: DT.value3,
          constantValue: DT.constantValue,
          finalValue: DT.finalValue,
          totSqureValue: DT.totSqureValue,
          itemDiscPer: DT.itemDiscPer,
          itemDiscAmt: DT.itemDiscAmt,
          itemDiscValue: DT.itemDiscValue,
          billDiscValue: DT.billDiscValue,
          enqId: DT.enqId,
          enqDTId: DT.enqDTId,
          enqQty: DT.enqQty,
          quoId: DT.quoId,
          quoDTId: DT.quoDTId,
          quoQty: DT.quoQty,
          ordId: DT.ordId,
          ordDTId: DT.ordDTId,
          ordQty: DT.ordQty,
          dCId: DT.dCId,
          dCDTId: DT.dCDTId,
          dCQty: DT.dCQty,
          invId: DT.invId,
          invDTId: DT.invDTId,
          invQty: DT.invQty,
          conUniqueId: DT.conUniqueId,
          conScrId: DT.conScrId,
        };
        ConRowManiDT.push(EntConRowData);
      });
      setPendingConList(ConRowManiDT);
      setisConversion(!isConversion);
    } else {
      Show_Message(dispatch, "Please Select Atleast One Item", "info");
    }
  };
  const View_Product = async (proinfo) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        "ProductTrans/View_Product/" + proinfo.proId + "/" + _AuthParems.DB,
        dispatch
      );
      if (res) {
        setiProId(proinfo.proId);
        setstrProName(proinfo.proName);
        setdQty(proinfo.qty);
        setiUnitId(proinfo.unitId);
        setUnitList(res.objUnit);
        $("#txtproQty").focus();
        $("#txtproQty").select();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const AddProduct = async () => {
    try {
      await Pre_Loading(dispatch, true);
      if (Number(dQty) === 0) {
        Show_Message(dispatch, "Qty Range should not be Zero(0)", "error");
        $("#txtproQty").focus();
      } else {
        const res = await API_POST(
          "Transaction/Merge_ConvertedProduct/",
          {
            iRowIndex: selected,
            iScrId: _HTparem.iScrId,
            iProId: iProId,
            dQty: Number(dQty),
            iUnitId: iUnitId,
            objEntConRow: PendingConList,
            objEntHelp: _DTparem.arrEntDTHelp,
            iYearId: Number(_AuthParems.Year),
            objEntPenConHelp: [],
            strDBName: _AuthParems.DB,
            iBranchId: Number(_AuthParems.Branch),
          },
          dispatch
        );
        if (res) {
          res.objConRow.map((Data) => {
            var UnitName = UnitList.filter(
              (UnitData) => UnitData.iUnitId === Data.unitId
            )[0];
            if (UnitName !== undefined && UnitName.iUnitId === Data.unitId) {
              Data.unit = UnitName.strUnitName;
            }
            return Data;
          });
          setPendingConList(res.objConRow);
          Clear_Product();
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Merge_ConvertProduct = async () => {
    try {
      props.Close();
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `${_defaultparem.Controller}/MergePenConRows/`,
        {
          iScrId: _HTparem.iScrId,
          iTaxRelId: _HTparem.iTaxRelId,
          iPartyId: _HTparem.iPartyId,
          objBaseTransDT: _DTparem.arrEntDT,
          objEntConRow: PendingConList,
          objConDCVouch: [],
          iYearId: Number(_AuthParems.Year),
          strDBName: _AuthParems.DB,
          iRoleId: Number(_AuthParems.Role),
          iBranchId: Number(_AuthParems.Branch),
          iTaxCalcOnId: _HTparem.iTaxCalcOnId,
        },
        dispatch
      );
      if (res) {
        await dispatch(Merge_ConvertedVouchers(res.data));
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Clear_Product = () => {
    setiProId(0);
    setstrProName("");
    setdQty(0);
    setiUnitId(0);
    setUnitList([]);
  };
  const SelectionChanged = async (VouchType) => {
    try {
      await Pre_Loading(dispatch, true);
      let ScrId = 0;
      switch (_HTparem.strScrType) {
        case "Sales":
          switch (VouchType) {
            case "Enq":
              ScrId = 14;
              break;
            case "Quo":
              ScrId = 15;
              break;
            case "Ord":
              ScrId = 16;
              break;
            case "DC":
              ScrId = 17;
              break;
            default:
              break;
          }
          break;
        case "Purchase":
          switch (VouchType) {
            case "Enq":
              ScrId = 8;
              break;
            case "Quo":
              ScrId = 9;
              break;
            case "Ord":
              ScrId = 10;
              break;
            case "DC":
              ScrId = 11;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      const res = await API_FETCH(
        `Transaction/Load_PendingConvertion/${ScrId}/${
          _HTparem.strScrType
        }/${Number(_HTparem.iPartyId)}/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        setPendingConList(res.EntPenPrduct);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const columns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 40,
      style: { justifyContent: "center" },
    },
    {
      Header: "Select",
      id: "isChecked",
      accessor: (rowInfo) => (
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={false}
          id="IsChecked"
          onClick={() => (rowInfo.isChecked = !rowInfo.isChecked)}
        />
      ),
      maxWidth: 70,
      style: { justifyContent: "center" },
      show: isConversion,
    },

    {
      Header: "Trans Type",
      accessor: "transType",
      minWidth: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: "Vouch No",
      accessor: "vouchNo",
      minWidth: 120,
      maxWidth: 120,
      style: { justifyContent: "left" },
    },
    {
      Header: "Date",
      accessor: "vouchDate",
      maxWidth: 80,
      style: { justifyContent: "center" },
    },
    {
      Header: "PartyDC No",
      accessor: "partyDcNo",
      maxWidth: 150,
      show: isConversion && isJobWork,
      style: { justifyContent: "left" },
    },
    {
      Header: "Product Name",
      accessor: "proName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Qty",
      accessor: "qty",
      style: { justifyContent: "right" },
      maxWidth: 70,
    },
    {
      Header: "Unit",
      accessor: "unit",
      style: { justifyContent: "center" },
      maxWidth: 70,
    },
    {
      Header: "Rate",
      accessor: "rateWithoutTax",
      show: isConversion,
      style: { justifyContent: "right" },
      maxWidth: 60,
    },
  ];

  const tab_click = (tabindex, vouchtype) => {
    let line = document.getElementById("popup-tab-line");
    let lable = $(`.popup-tab label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.popup-tab label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    SelectionChanged(vouchtype);
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{isConversion ? "Pending Convertion" : "Convert Dc"}</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-0">
          {isConversion && !isJobWork && (
            <div className="popup-tab">
              <label onClick={(e) => tab_click(0, "Enq")} className="active">
                Enquiry
              </label>
              {arrtab[1] && (
                <label onClick={(e) => tab_click(1, "Quo")}>Quotation</label>
              )}
              {arrtab[2] && (
                <label onClick={(e) => tab_click(2, "Ord")}>Order</label>
              )}
              {arrtab[3] && (
                <label onClick={(e) => tab_click(3, "DC")}>Challan</label>
              )}
              <div id="popup-tab-line"></div>
            </div>
          )}
          {!isConversion && (
            <div className="row p-2">
              <div className="col-md-4 col-sm-12">
                <label>Product Name</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  name="strProName"
                  id="txtProName"
                  value={strProName}
                  onChange={(e) => setstrProName(e.target.value)}
                  readOnly
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Qty</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  name="dQty"
                  id="txtproQty"
                  autoFocus
                  value={dQty}
                  onChange={(e) => setdQty(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Unit</label>
                <div>
                  <CustomizedSelectoption
                    optionparem="isoptionShow9"
                    showoption={_selecrparem.isoptionShow9}
                    placeholder="Unit"
                    select_value={(val) => setiUnitId(Number(val))}
                    btnname="Unit"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={iUnitId}
                    displayName="strUnitName"
                    disvalue="iUnitId"
                    arrydata={UnitList}
                    disabled={false}
                    EmptVal="Unit"
                  />
                </div>
              </div>
              <div className="col-md-2 col-sm-12">
                <div className="trans-inputbtn">
                  <button type="button" onClick={AddProduct}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
          <ReactTable
            columns={columns}
            data={PendingConList}
            minRows={5}
            row_click={(VouchInfo, Index) => {}}
            row_doubleclick={(VouchInfo, Index) => {
              if (!isConversion) View_Product(VouchInfo);
            }}
            background={true}
            className="full-table"
          />
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) =>
                isConversion
                  ? Convert_SelectedProduct()
                  : Merge_ConvertProduct()
              }
            >
              {isConversion ? "Convert" : "Merge"}&nbsp;
              <i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingConvertion;
