import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_POST } from "../General/Utility";
import PopupSearch from "./PopupSearch";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
function ServiceSearch(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [Service, setService] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [TableCol, setTableCol] = useState([]);
  const _ScrId = 59;

  useEffect(() => {
    Load_Service();
  }, []);

  const Load_Service = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const parems = {
        strSearch: "",
        strDBName: _AuthParems.DB,
        iScrId: _ScrId,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(`Transaction/Load_Service/`, parems, dispatch);
      if (res) {
        setService(res.Service);
        setTableCol(res.TablDesign);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Service = async (strtxt) => {
    setstrSearch(strtxt);
    const parems = {
      strSearch: strtxt,
      strDBName: _AuthParems.DB,
      iScrId: _ScrId,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(`Transaction/Search_Service/`, parems, dispatch);
    if (res) {
      setService(res.Service);
    }
  };
  return (
    <PopupSearch
      ScrName="Service Search"
      FilterCol={[]}
      FilterVal={""}
      FilterShow={false}
      strSearch={strSearch}
      Columns={TableCol}
      Data={Service}
      UpdateFilterCol={""}
      Search_Data={(e) => Search_Service(e)}
      View_Data={(e) => props.View_Service(e)}
      Close={() => props.Close()}
      IsService={true}
    />
  );
}

export default ServiceSearch;
