import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST } from "../General/Utility";

function TemplateCopy(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _parem = useSelector((state) => state.SelectOption.parems);

  const [iScreenFrom, setiScreenFrom] = useState(0);
  const [iTempFor, setiTempFor] = useState(0);
  const [iScreenTo, setiScreenTo] = useState(0);
  const [iSourceTemId, setiSourceTemId] = useState(0);
  const [strTempName, setstrTempName] = useState("");

  const [ListScreenFrom, setListScreenFrom] = useState([]);
  const [ListTempFor, setListTempFor] = useState([]);
  const [ListSourceTemp, setListSourceTemp] = useState([]);
  const [ListScreenTo, setListScreenTo] = useState([]);

  useEffect(() => {
    Load_PrintTemplates();
  }, []);
  const Load_PrintTemplates = async () => {
    try {
      Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        "PrintDesing/Load_OriginalTemplate/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        setListScreenFrom(res.EntScreen);
        setListScreenTo(res.EntScreen);
        setListTempFor(res.EntPrinterType);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Clear_Template = () => {
    Pre_Loading(dispatch, true);
    setiScreenFrom(0);
    setiScreenTo(0);
    setstrTempName("");
    setiTempFor(0);
    setiSourceTemId(0);
    Load_PrintTemplates();
  };
  const View_SourceTemplate = async (val, valfor) => {
    try {
      let Printer = iTempFor;
      let ScrId = iScreenFrom;
      switch (valfor) {
        case "Screen":
          setiScreenFrom(val);
          ScrId = val;
          break;
        case "PrinterType":
          setiTempFor(val);
          Printer = val;
          break;
        default:
          break;
      }
      const res = await API_FETCH(
        "PrintDesing/View_SourceTemplate/" +
          ScrId +
          "/" +
          Printer +
          "/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        setListSourceTemp(res.EntTemplate);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Copy_PrintTemplate = async () => {
    try {
      const objCopyTem = {
        iFromScrId: Number(iScreenFrom),
        iToScrId: Number(iScreenTo),
        iPrintTypeId: Number(iTempFor),
        iSourceTempId: Number(iSourceTemId),
        strNewTempName: strTempName,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        "PrintDesing/Copy_PrintTemplate/",
        objCopyTem,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Template();
        props.Close();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const CheckTempExist = async (strtempName) => {
    try {
      const objSearch = {
        strSearch: strtempName,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        "PrintDesing/CheckTemplateExist/",
        objSearch,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "info");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content" style={{ maxWidth: "300px" }}>
        <div className="popup-header">
          <h5>Template Copy </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row p-2">
            <div className="col-md-12 col-sm-12 mb-1">
              <label className="mb-1">Screen From</label>
              <CustomizedSelectoption
                optionparem="isoptionShow16"
                showoption={_parem.isoptionShow16}
                placeholder="Select Screen"
                select_value={(val) =>
                  View_SourceTemplate(Number(val), "Screen")
                }
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iScreenFrom}
                displayName="strScrName"
                disvalue="iScrId"
                arrydata={ListScreenFrom}
                EmptVal="Screen Name"
              />
            </div>
            <div className="col-md-12 col-sm-12 mb-1">
              <label className="mb-1">Printer Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow17"
                showoption={_parem.isoptionShow17}
                placeholder="Select Printer Type"
                select_value={(val) =>
                  View_SourceTemplate(Number(val), "PrinterType")
                }
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iTempFor}
                displayName="strPrinterTypeName"
                disvalue="iPrinterTypeId"
                arrydata={ListTempFor}
                EmptVal="Printer Type"
              />
            </div>
            <div className="col-md-12 col-sm-12 mb-1">
              <label className="mb-1">Screen To</label>
              <CustomizedSelectoption
                optionparem="isoptionShow18"
                showoption={_parem.isoptionShow18}
                placeholder="Select Screen"
                select_value={(val) => setiScreenTo(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iScreenTo}
                displayName="strScrName"
                disvalue="iScrId"
                arrydata={ListScreenTo}
                EmptVal="Screen Name"
              />
            </div>
            <div className="col-md-12 col-sm-12 mb-1">
              <label className="mb-1">Source Template</label>
              <CustomizedSelectoption
                optionparem="isoptionShow19"
                showoption={_parem.isoptionShow19}
                placeholder="Select Template"
                select_value={(val) => setiSourceTemId(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iSourceTemId}
                displayName="strPrintTempName"
                disvalue="iPrintTempId"
                arrydata={ListSourceTemp}
                EmptVal="Template Name"
              />
            </div>
            <div className="col-md-12 col-sm-12 mb-1">
              <label>Template Name</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Template Name"
                value={strTempName}
                onChange={(e) => setstrTempName(e.target.value)}
                onBlur={(e) => CheckTempExist(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iScreenFrom) === 0) {
                  Show_Message(dispatch, "Please Select Screen From", "info");
                } else if (Number(iTempFor) === 0) {
                  Show_Message(dispatch, "Please Select Printer Type", "info");
                } else if (Number(iScreenTo) === 0) {
                  Show_Message(dispatch, "Please Select Screen To", "info");
                } else if (Number(iSourceTemId) === 0) {
                  Show_Message(
                    dispatch,
                    "Please Select Source Template",
                    "info"
                  );
                } else if (strTempName.length === 0) {
                  Show_Message(dispatch, "Please Enter Template Name", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    Copy_PrintTemplate,
                    "Do You Want to Copy Template?"
                  );
                }
              }}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateCopy;
