import { API_FETCH } from "./Utility";
import { Show_Message } from "./Messagedialogbox";
import PrintAccountsDesign from "./PrintAccounts";
import PrintInvoicDesign from "./PrintInvoice";
const Print_DesignCaller = async (
  _AuthParems,
  ScrId,
  ScrType,
  VoucherId,
  TempId,
  PrintType,
  dispatch,
  PrintShare
) => {
  var objEntPrint = undefined;
  try {
    VoucherId = VoucherId ? VoucherId : 0;
    let URL = `PrintDesing/Print_Data/{0}/${ScrId}/${TempId}/${VoucherId}/${_AuthParems.Branch}/${_AuthParems.DB}`;
    if (ScrType === "Transaction") URL = URL.replace("{0}", "Transaction");
    else URL = URL.replace("{0}", "Accounts");
    const res = await API_FETCH(URL, dispatch);
    if (res) {
      if (ScrType === "Transaction")
        objEntPrint = await PrintInvoicDesign(
          res,
          ScrId,
          PrintType,
          PrintShare
        );
      else
        objEntPrint = await PrintAccountsDesign(
          res,
          ScrId,
          PrintType,
          PrintShare
        );
    }
  } catch (e) {
    Show_Message(dispatch, e.message, "error");
  } finally {
    if (PrintShare) return objEntPrint;
  }
};
export default Print_DesignCaller;
