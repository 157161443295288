import $ from "jquery";
import { useDispatch } from "react-redux";
import { hide_Options } from "../features/Customeselect";
import { useEffect, useState } from "react";
function CustomizedSelectoption(props) {
  const dispatch = useDispatch();
  const [arrOption, setarrOption] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [strdefaultval, setstrdefaultval] = useState("");
  useEffect(() => {
    if (props.arrydata !== undefined) {
      let defaultval = props.arrydata.filter(
        (dt) => dt[props.disvalue] === props.defaultval
      );
      if (defaultval.length > 0 && props.replace === undefined)
        setstrdefaultval(defaultval[0][props.displayName]);
      else if (defaultval.length > 0 && props.replace !== undefined) {
        setstrdefaultval(
          defaultval[0][props.displayName].replace(
            props.replace[0],
            props.replace[1]
          )
        );
      } else setstrdefaultval("");
    } else setstrdefaultval("");
    if (props.arrydata !== undefined && props.onChange !== undefined) {
      setarrOption(props.arrydata);
    }
  }, [props]);
  const input_Onchange = (val) => {
    if (props.onChange !== undefined) {
      setstrSearch(val);
      props.onChange(val);
    } else if (props.arrydata !== undefined) {
      const EntOption = props.arrydata.filter((dt) =>
        dt[props.displayName].toLowerCase().includes(val.toLowerCase())
      );
      setarrOption(EntOption);
    }
    setstrSearch(val);
  };
  const Select_Value = (item) => {
    dispatch(
      hide_Options({
        [props.optionparem]: !props.showoption,
      })
    );
    if (props.valueonly) {
      setstrSearch(item[props.displayName]);
      props.select_value(item[props.disvalue]);
    } else {
      setstrSearch(item[props.displayName]);
      props.select_value(item);
    }
  };
  const Mouse_leave = () => {
    dispatch(
      hide_Options({
        [props.optionparem]: false,
      })
    );
  };
  return (
    <div className="custom-select" onMouseLeave={(e) => Mouse_leave()}>
      <input
        className="form-control"
        disabled={props.disabled ? true : false}
        type="text"
        placeholder={props.placeholder}
        defaultValue={strdefaultval}
        onClick={(e) => {
          dispatch(hide_Options({ [props.optionparem]: !props.showoption }));
          input_Onchange("");
        }}
        tabIndex={props.taborder}
      />
      <i className="fa-solid fa-caret-down"></i>
      {props.showoption && (
        <div className="custom-option">
          <div className="search-option">
            <input
              type="text"
              placeholder={"Search " + props.EmptVal}
              value={strSearch}
              onChange={(e) => input_Onchange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "ArrowDown") {
                  $(".option-list [tabindex=0]").focus();
                } else if (e.key === "Enter") {
                  Select_Value(arrOption[0]);
                }
              }}
              autoFocus
            />
            {arrOption.length === 0 && <span> No Records Found</span>}
          </div>
          <div className="option-list">
            {arrOption.map((item, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onClick={(e) => Select_Value(item)}
                onKeyDown={(e) => {
                  let arrlength = arrOption.length - 1;
                  if (e.key === "ArrowDown") {
                    if (arrlength === Index)
                      $(`.option-list [tabindex=0]`).focus();
                    else $(`.option-list [tabindex=${Index + 1}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.option-list [tabindex=${arrlength}]`).focus();
                    else $(`.option-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") Select_Value(item);
                }}
              >
                {props.replace !== undefined
                  ? item[props.displayName].replace(
                      props.replace[0],
                      props.replace[1]
                    )
                  : item[props.displayName]}
                <br />
                {props.onChange !== undefined && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {props.displayName1 !== undefined && (
                      <label>
                        {props.displayName1} : {item[props.disvalue1]}
                      </label>
                    )}
                    {props.displayName2 !== undefined && (
                      <label>
                        {props.displayName2} : {item[props.disvalue2]}
                      </label>
                    )}
                  </div>
                )}
              </button>
            ))}
          </div>
          {props.btnshow && (
            <div className="popup-footer">
              <div className="popup-btn-group">
                <button
                  className="btn-fabgreen"
                  onClick={(e) => {
                    dispatch(
                      hide_Options({
                        [props.optionparem]: !props.showoption,
                      })
                    );
                    props.show_popup(e);
                  }}
                >
                  <i className="fa-solid fa-circle-plus"></i>
                  Create {props.btnname}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default CustomizedSelectoption;
