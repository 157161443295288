import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import $ from "jquery";

import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Messagedialogbox from "../General/Messagedialogbox";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import ReactTable from "../General/ReactTable";
import {
  setdiscountmasterdefault,
  ShowHide_Popup,
} from "../features/Transactionstates";
import TransProductSearch from "../Transaction/TransProductSearch";
import BatchProductSearch from "../Transaction/BatchProductSearch";
import AttributeProductSearch from "../Transaction/AttributeProductSearch";
function DiscountMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);

  //  Discount HT Table Parems
  const [isProduct, setisProduct] = useState(false);
  const [isManualCode, setisManualCode] = useState(false);
  const [UpdateStatus, setUpdateStatus] = useState(false);
  const [RowIndex, setRowIndex] = useState(0);
  const [idiscId, setidiscId] = useState(0);
  const [strdiscName, setstrdiscName] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strPrefix, setstrPrefix] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const [strdiscCode, setstrdiscCode] = useState("");
  const [dAmount, setdAmount] = useState((0).toFixed(2));
  const [ddiscPer, setddiscPer] = useState((0).toFixed(2));
  const [iRateFrom, setiRateFrom] = useState(1);
  const [idiscFrom, setidiscFrom] = useState(1);
  const [iStatus, setiStatus] = useState(1);
  const [idisType, setidisType] = useState(1);
  const [isDefault, setisDefault] = useState(false);
  const [strRemarks, setstrRemarks] = useState("");
  const [dpFromdate, setdpFromdate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dpTodate, setdpTodate] = useState(
    new Date().toISOString().split("T")[0]
  );
  //  Discount DT Table Parems
  const [iProId, setiProId] = useState(0);
  const [strProName, setstrProName] = useState("");
  const [strAttValues, setstrAttValues] = useState("");
  const [iUnitId, setiUnitId] = useState(0);
  const [strUnitName, setstrUnitName] = useState("");
  const [iBuyQty, setiBuyQty] = useState(0);
  const [iFreeQty, setiFreeQty] = useState(0);
  const [iNetQty, setiNetQty] = useState(0);
  const [ddisc, setddisc] = useState((0).toFixed(2));
  const [isdiscPer, setisdiscPer] = useState(true);
  const [strSearch, setstrSearch] = useState("");
  const [arrEntdiscType, setarrEntdiscType] = useState([]);
  const [arrDRFrom, setarrDRFrom] = useState([]);
  const [arrEntStatus, setarrEntStatus] = useState([]);
  const [arrUnit, setarrUnit] = useState([]);
  const [arrEntDiscHT, setarrEntDiscHT] = useState([]);
  const [arrEntDiscDT, setarrEntDiscDT] = useState([]);

  const [isComposPro, setisComposPro] = useState(0);
  useEffect(() => {
    Load_DiscountMaster();
  }, []);
  const Load_DiscountMaster = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const parems = {
        iBranchId: Number(_LocalParems.Branch),
        strDBName: _LocalParems.DB,
      };
      const res = await API_POST(
        `Discount/Load_DiscountMaster`,
        parems,
        dispatch
      );
      if (res) {
        setarrEntDiscHT(res.EntDiscount);
        setarrDRFrom(res.EntDiscRateFrom);
        setarrEntStatus(res.EntStatus);
        const codeforate = res.EntCodeFormate[0];
        setstrdiscCode(codeforate.strCode);
        setstrPrefix(codeforate.strPrefix);
        setisManualCode(codeforate.strPrefix === "" ? false : true);
        setiNumSeqNo(codeforate.iNumSeqNo);
        setarrEntdiscType(res.EntDiscType);
        await dispatch(setdiscountmasterdefault());
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Product = async (pro, BatchPro) => {
    try {
      setisProduct(!isProduct);
      let Product = pro.ProInfo[0];
      setiProId(Product.iProId);
      setstrProName(Product.strProName);
      setstrAttValues(Product.strAttValues);
      setisComposPro(Product.isComposPro);
      setarrUnit(pro.ProUnit);
      setiUnitId(pro.ProUnit[0].iUnitId);
      setstrUnitName(pro.ProUnit[0].strUnitName);
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err, "error");
    }
  };
  const ClearProduct = () => {
    setstrSearch("");
    setiProId(0);
    setstrAttValues("");
    setstrProName("");
    setiUnitId(0);
    setstrUnitName("");
    setiBuyQty(0);
    setiFreeQty(0);
    setiNetQty(0);
    setddisc(0);
    setisdiscPer(true);
    setUpdateStatus(false);
    setRowIndex(0);
    setarrUnit([]);
  };
  const AddProductClick = async () => {
    if (iProId === 0) Show_Message(dispatch, "Please Select Product", "info");
    else {
      const DT = {
        UpdateStatus: UpdateStatus,
        Index: RowIndex,
        discId: idiscId,
        ProId: iProId,
        ProName: strProName,
        AttValues: strAttValues,
        UnitId: iUnitId,
        UnitName: strUnitName,
        BuyQty: iBuyQty,
        FreeQty: iFreeQty,
        NetQty: iNetQty,
        DiscPer: isdiscPer ? ddisc : 0,
        DiscAmt: !isdiscPer ? ddisc : 0,
        isComposPro: isComposPro,
        DT: arrEntDiscDT,
      };
      const res = await API_POST("Discount/AddUpdate_Product", DT, dispatch);
      if (res) {
        setarrEntDiscDT(res.DT);
        ClearProduct();
      }
    }
  };
  const Modify_Product = async (product, index) => {
    try {
      await Pre_Loading(dispatch, true);
      setUpdateStatus(true);
      setRowIndex(index);
      setstrProName(product.proName);
      setstrAttValues(product.attValues);
      setiProId(product.proId);
      setiBuyQty(product.buyQty);
      setiFreeQty(product.freeQty);
      setiNetQty(product.netQty);
      setddisc(product.discPer > 0 ? product.discPer : product.discAmt);
      setisdiscPer(product.discPer > 0 ? true : false);
      setiUnitId(product.unitId);
      setstrUnitName(product.unitName);
      setisComposPro(product.isComposPro);
      const res = await API_FETCH(
        `Discount/View_Unit/${product.proId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setarrUnit(res.EntUnit);
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Delete_Product = async (Index) => {
    try {
      await Pre_Loading(dispatch, true);
      const DT = {
        UpdateStatus: UpdateStatus,
        Index: Index,
        discId: idiscId,
        ProId: iProId,
        ProName: strProName,
        AttValues: strAttValues,
        UnitId: iUnitId,
        UnitName: strUnitName,
        BuyQty: iBuyQty,
        FreeQty: iFreeQty,
        NetQty: iNetQty,
        DiscPer: isdiscPer ? ddisc : 0,
        DiscAmt: !isdiscPer ? ddisc : 0,
        isComposPro: isComposPro,
        DT: arrEntDiscDT,
      };
      const res = await API_POST("Discount/Delete_Product", DT, dispatch);
      if (res) {
        setarrEntDiscDT(res.DT);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Save_Discount = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const InsUpdDisc = {
        discId: idiscId,
        Prefix: strPrefix,
        NumSeqNo: iNumSeqNo,
        discCode: strdiscCode,
        discName: strdiscName,
        PrintName: strPrintName,
        Remark: strRemarks,
        Fromdate: dpFromdate,
        Todate: dpTodate,
        Amount: dAmount,
        Discper: ddiscPer,
        RateFrom: iRateFrom,
        DiscFrom: idiscFrom,
        DiscStatus: iStatus,
        BranchId: _AuthParems.Branch,
        DiscType: idisType,
        IsDefault: Number(isDefault),
      };
      const InsUpdParem = {
        _EntDiscHT: InsUpdDisc,
        _EntDiscDT: arrEntDiscDT,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `Discount/InsUpd_Discount/`,
        InsUpdParem,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Discount();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Delete_Discount = async () => {
    try {
      const res = await API_DELETE(
        `Discount/Delete_Discount/${Number(idiscId)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Discount();
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Clear_Discount = () => {
    setidiscId(0);
    setstrdiscName("");
    setstrdiscCode("");
    setiNumSeqNo(0);
    setstrPrefix("");
    setstrRemarks("");
    setdAmount(0);
    setddiscPer(0);
    setdpFromdate(new Date().toISOString().split("T")[0]);
    setdpTodate(new Date().toISOString().split("T")[0]);
    setidisType(1);
    setidiscFrom(1);
    setiRateFrom(1);
    setiStatus(1);
    setiProId(0);
    setstrAttValues("");
    setstrProName("");
    setiUnitId(0);
    setstrUnitName("");
    setiBuyQty(0);
    setiFreeQty(0);
    setiNetQty(0);
    setddisc(0);
    setisdiscPer(true);
    setRowIndex(0);
    setUpdateStatus(false);
    setarrUnit([]);
    setarrEntDiscHT([]);
    setarrEntDiscDT([]);
    Load_DiscountMaster();
  };
  const View_Discount = async (discount) => {
    try {
      await Pre_Loading(dispatch, true);
      setidiscId(discount.discId);
      setstrdiscName(discount.discName);
      setstrdiscCode(discount.discCode);
      setiNumSeqNo(discount.numSeqNo);
      setstrPrefix(discount.prefix);
      setstrRemarks(discount.remark);
      setdAmount(discount.amount);
      setddiscPer(discount.discper);
      setdpFromdate(discount.fromdate);
      setdpTodate(discount.todate);
      setidisType(discount.discType);
      setidiscFrom(discount.discFrom);
      setiRateFrom(discount.rateFrom);
      setiStatus(discount.discStatus);
      setisDefault(Boolean(discount.isDefault));
      const res = await API_FETCH(
        `Discount/View_Discount/${Number(discount.discId)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setarrEntDiscDT(res.EntDiscountDT);
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Discount = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        iBranchId: Number(_AuthParems.Branch),
        strSearch: strtxt,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `Discount/Search_Discount`,
        objSearch,
        dispatch
      );
      if (res) {
        setarrEntDiscHT(res.EntDiscount);
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    }
  };
  const DiscColumns = [
    {
      Header: "Product Name",
      accessor: "proName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Attribute",
      accessor: "attValues",
      style: { justifyContent: "left" },
    },
    {
      Header: "Unit",
      accessor: "unitName",
      maxWidth: 150,
      style: { justifyContent: "center" },
    },
    {
      Header: "Buy Qty",
      accessor: "buyQty",
      maxWidth: 150,
      style: { justifyContent: "center" },
    },
    {
      Header: "Free Qty",
      accessor: "freeQty",
      maxWidth: 150,
      style: { justifyContent: "center" },
    },
    {
      Header: "Net Qty",
      accessor: "netQty",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Disc Amt",
      accessor: "discAmt",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Disc Per",
      accessor: "discPer",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Delete",
      id: "delete",
      cell: ({ rowinfo, Index }) => (
        <span onClick={(e) => Delete_Product(Index)}>
          <i className="fa-solid fa-trash"></i>
        </span>
      ),
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
  ];
  return (
    <main className="main-container">
      <SideMenu ScrId={98} />
      <div className="content">
        <div className="left-list">
          <div className="m-header p-2 mt-2">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search discount"
                value={strSearch}
                onChange={(e) => Search_Discount(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Search_Discount(strSearch)}
              ></i>
            </div>
          </div>
          <div className="data-list">
            {arrEntDiscHT.map((discount, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = arrEntDiscHT.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    View_Discount(discount);
                  }
                }}
              >
                <label onClick={(e) => View_Discount(discount)}>
                  {discount.discName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="main-content">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                value={strdiscName}
                onChange={(e) => setstrdiscName(e.target.value)}
                placeholder="Discount Name"
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Code</label>
              <input
                type="text"
                className="form-control"
                value={strdiscCode}
                onChange={(e) => setstrdiscCode(e.target.value)}
                placeholder="Discount Code"
                disabled={isManualCode}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Print Name</label>
              <input
                type="text"
                className="form-control"
                value={strPrintName}
                onChange={(e) => setstrPrintName(e.target.value)}
                placeholder="Print Name"
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Discount Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selecrparem.isoptionShow}
                placeholder="Select Discount Type"
                select_value={(val) => setidisType(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={idisType}
                displayName="strDiscType"
                disvalue="iDiscTypeId"
                arrydata={arrEntdiscType}
                EmptVal="Discount Type"
                disabled={idiscId > 0 ? true : false}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Rate value</label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_selecrparem.isoptionShow1}
                placeholder="Select Rate value"
                select_value={(val) => setiRateFrom(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iRateFrom}
                displayName="strDiscRatefrom"
                disvalue="iRfId"
                arrydata={arrDRFrom}
                EmptVal="Disc Rate value"
                disabled={false}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Discount value</label>
              <CustomizedSelectoption
                optionparem="isoptionShow2"
                showoption={_selecrparem.isoptionShow2}
                placeholder="Select Discount value"
                select_value={(val) => setidiscFrom(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={idiscFrom}
                displayName="strDiscRatefrom"
                disvalue="iRfId"
                arrydata={arrDRFrom}
                EmptVal="Discount value"
                disabled={false}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Status</label>
              <CustomizedSelectoption
                optionparem="isoptionShow3"
                showoption={_selecrparem.isoptionShow3}
                placeholder="Select Status"
                select_value={(val) => setiStatus(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iStatus}
                displayName="strStatusName"
                disvalue="iStatusId"
                arrydata={arrEntStatus}
                EmptVal="Status"
                disabled={false}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Remark</label>
              <input
                type="text"
                className="form-control"
                value={strRemarks}
                placeholder="Enter Remarks"
                onChange={(e) => setstrRemarks(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-2">
              <label>Default Discount</label>
              <br />
              <input
                type="checkbox"
                style={{
                  cursor: "pointer",
                  height: "15px",
                  width: "15px",
                }}
                checked={isDefault}
                onChange={(e) => setisDefault(!isDefault)}
              />
            </div>
            {idisType === 2 && (
              <div className="col-sm-12 col-md-3">
                <label>From Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={dpFromdate}
                  onChange={(e) => setdpFromdate(e.target.value)}
                />
              </div>
            )}
            {idisType === 2 && (
              <div className="col-sm-12 col-md-3">
                <label>To Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={dpTodate}
                  onChange={(e) => setdpTodate(e.target.value)}
                />
              </div>
            )}
            {idisType === 4 && (
              <div className="col-sm-12 col-md-3">
                <label>Net Amount</label>
                <input
                  type="number"
                  className="form-control"
                  value={dAmount}
                  onChange={(e) => setdAmount(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
            )}
            {idisType !== 1 && idisType !== 3 && (
              <div className="col-sm-12 col-md-3">
                <label>Discount %</label>
                <input
                  type="number"
                  className="form-control"
                  value={ddiscPer}
                  onChange={(e) => setddiscPer(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
            )}
          </div>
          {idisType !== 2 && idisType !== 4 && (
            <div className="row mb-2">
              <div className="col-sm-12 col-md-4">
                <label>Product</label>
                <div className="custom-select">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Press Enter to Select Product "
                    defaultValue={strProName}
                    onClick={async (e) =>
                      dispatch(ShowHide_Popup("IsProSearch"))
                    }
                  />
                  <i className="fa-solid fa-caret-down"></i>
                </div>
              </div>
              <div className="col-sm-12 col-md-2">
                <label>Unit</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow4"
                  showoption={_selecrparem.isoptionShow4}
                  placeholder="Unit"
                  select_value={(val) => {
                    setiUnitId(Number(val.iUnitId));
                    setstrUnitName(val.strUnitName);
                  }}
                  btnname="Unit"
                  btnshow={false}
                  show_popup=""
                  valueonly={false}
                  defaultval={iUnitId}
                  displayName="strUnitName"
                  disvalue="iUnitId"
                  arrydata={arrUnit}
                  disabled={iProId === 0 ? true : false}
                  EmptVal="Unit"
                />
              </div>
              {idisType === 1 && (
                <div className="col-sm-12 col-md-2">
                  <label>Net Qty</label>
                  <input
                    type="number"
                    className="form-control"
                    value={iNetQty}
                    onChange={(e) => setiNetQty(e.target.value)}
                    disabled={iProId === 0 ? true : false}
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {idisType === 3 && (
                <div className="col-sm-12 col-md-2">
                  <label>Buy Qty</label>
                  <input
                    type="number"
                    className="form-control"
                    value={iBuyQty}
                    onChange={(e) => setiBuyQty(e.target.value)}
                    disabled={iProId === 0 ? true : false}
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {idisType === 3 && (
                <div className="col-sm-12 col-md-2">
                  <label>Free Qty</label>
                  <input
                    type="number"
                    className="form-control"
                    value={iFreeQty}
                    onChange={(e) => setiFreeQty(e.target.value)}
                    disabled={iProId === 0 ? true : false}
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {idisType !== 3 && (
                <div className="col-sm-12 col-md-2">
                  <label>Discount</label>
                  <div className="trancdisc">
                    <input
                      type="number"
                      className="form-control"
                      value={ddisc}
                      onClick={(e) => e.target.select()}
                      onChange={(e) => setddisc(e.target.value)}
                      onBlur={(e) =>
                        setddisc(Number(e.target.value).toFixed(2))
                      }
                      disabled={iProId === 0 ? true : false}
                    />
                    <button
                      className="btn-fabgreen active"
                      onClick={(e) => {
                        $(".trancdisc .btn-fabgreen").toggleClass("active");
                        setisdiscPer(!isdiscPer);
                      }}
                    >
                      {isdiscPer ? "%" : "₹"}
                    </button>
                  </div>
                </div>
              )}
              <div className="col-sm-6 col-md-2">
                <div className="btn-sub-section mt-3">
                  <button
                    className="btn-fabgreen"
                    onClick={AddProductClick}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") AddProductClick();
                    }}
                  >
                    Add&nbsp;<i className="bx bx-layer-plus"></i>
                  </button>
                  <button
                    className="btn-fabgreen"
                    onClick={(e) => ClearProduct()}
                  >
                    Clear&nbsp;<i className="fas fa-eraser"></i>
                  </button>
                </div>
              </div>
            </div>
          )}
          <ReactTable
            columns={DiscColumns}
            data={arrEntDiscDT}
            minRows={5}
            row_click={(ProInfo, Index) => {}}
            row_doubleclick={(ProInfo, Index) => {
              Modify_Product(ProInfo, Index);
            }}
            background={true}
            className="full-table"
          />
          <Messagedialogbox />
          {_Common.IsProSearch && (
            <TransProductSearch View_Product={View_Product} />
          )}
          {_Common.IsBatchPro && (
            <BatchProductSearch View_Product={View_Product} />
          )}
          {_Common.IsAttPro && (
            <AttributeProductSearch View_Product={View_Product} />
          )}
        </div>
        <div className="button-list">
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (strdiscName.length === 0) {
                Show_Message(dispatch, "Please Enter Discount Name", "success");
              } else
                Ask_Question(
                  dispatch,
                  Save_Discount,
                  "Do You Want to  Save Discount?"
                );
            }}
          >
            Save&nbsp;<i className="fas fa-save"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (idiscId === 0) {
                Show_Message(dispatch, "Please Select Discount", "success");
              } else
                Ask_Question(
                  dispatch,
                  Delete_Discount,
                  "Do You Want to Delete Discount?"
                );
            }}
          >
            Delete&nbsp;<i className="bx bx-trash-alt"></i>
          </button>
          <button className="btn-fabgreen" onClick={(e) => Clear_Discount()}>
            Clear&nbsp;<i className="fa-solid fa-broom"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            onClick={(e) => window.history.back()}
          >
            Close&nbsp;<i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </main>
  );
}

export default DiscountMaster;
