import React, { useEffect } from "react";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_POST, API_PUT } from "../General/Utility";
import {
  Clear_EmployeeParems,
  EmployeeInput_Onchange,
  Load_Employees,
  ShowHide_Popup,
} from "../features/Transactionstates";

function StaffProfile() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Select = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((state) => state.Transstates.Common);
  const _Employee = useSelector((state) => state.Transstates.Employee);
  const _AuthParem = useSelector((state) => state.Common.Admin);
  useEffect(() => {
    short_cut_keypress();
    Load_Employee();
  }, []);
  const Load_Employee = async () => {
    try {
      const parems = {
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
      };
      await Pre_Loading(dispatch, true);
      const res = await API_POST("POS/Load_Employee", parems, dispatch);
      if (res) {
        await dispatch(Load_Employees(res.Employee));
      } else Show_Message(dispatch, res.strMessage, "info");
    } catch (e) {
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Save_SelectedEmployee = async () => {
    try {
      if (Number(_Employee.iEmpId) === 0)
        Show_Message(dispatch, "Please Select Staff", "info");
      else {
        await Pre_Loading(dispatch, true);
        const res = await API_PUT(
          `EmployeeMaster/Save_SelectedEmployee/${Number(
            _Employee.iEmpId
          )}/${Number(Number(_Common.iSessionId))}/${_AuthParem.DB}`,
          dispatch
        );
        if (res) {
          await dispatch(ShowHide_Popup("IsEmployee"));
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Select_Employee = async (Emp) => {
    await dispatch(
      EmployeeInput_Onchange({
        iEmpId: Emp.iEmpId,
        strEmployeeName: Emp.strEmpName,
        iRoleId: Emp.iRoleId,
        strEmpRole: Emp.strEmpRole,
        dRewardPoint: Emp.dRewardPoint,
      })
    );
  };
  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        dispatch(ShowHide_Popup("IsEmployee"));
      }
    });
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Staff Profile</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => dispatch(ShowHide_Popup("IsEmployee"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-2">
          <div className="row">
            <div className="select">
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_Select.isoptionShow1}
                placeholder="Select Sales Person"
                select_value={(emp) => Select_Employee(emp)}
                btnname="Employee"
                btnshow={false}
                show_popup={""}
                valueonly={false}
                defaultval={_Employee.iEmpId}
                displayName="strEmpName"
                disvalue="iEmpId"
                arrydata={_Common.EntEmployee}
                disabled={false}
                EmptVal="Staff"
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Staff Name</label>
              <input
                type="text"
                className="form-control"
                value={_Employee.strEmployeeName}
                readOnly
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Role</label>
              <input
                type="text"
                className="form-control"
                value={_Employee.iRoleId}
                readOnly
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>JustBill Points</label>
              <input
                type="number"
                className="form-control"
                value={Number(_Employee.dRewardPoint).toFixed(2)}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => Save_SelectedEmployee()}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => dispatch(Clear_EmployeeParems())}
            >
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffProfile;
