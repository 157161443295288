import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST } from "../General/Utility";
function CategoryMaster(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const iScrId = 4;
  const strCatCodeTag = "Enter Category Code";
  const [iCatId, setiCatId] = useState(0);
  const [strCatCode, setstrCatCode] = useState("");
  const [strCatName, setstrCatName] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [iCatTypeId, setiCatTypeId] = useState("1");
  const [strColName, setstrColName] = useState("All");
  const [strSearch, setstrSearch] = useState("");

  const [arrCode, setarrCode] = useState([]);
  const [arrFilter, setarrFilter] = useState([]);
  const [arrCategory, setarrCategory] = useState([]);
  const [arrCategtype, setarrCategtype] = useState([]);
  const [ManualCode, setManualCode] = useState(true);

  useEffect(() => {
    Load_CategoreMaster();
  }, []);
  const Load_CategoreMaster = async () => {
    try {
      const objSearch = {
        strSearch: "",
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
        strColName: props.CategType,
      };
      const res = await API_POST(
        `Category/Load_CategoreMaster/`,
        objSearch,
        dispatch
      );
      if (res) {
        setiCatTypeId(Number(props.CategType));
        setstrColName(props.CategType);
        setarrFilter(res.EntFilter);
        setarrCategory(res.EntCategory);
        setarrCategtype(res.EntCategtype);
        setarrCode(res.EntCode);
        setstrCatCode(res.EntCode[0].strCode);
        setManualCode(!res.EntCode[0].isManualNo);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveClick = async () => {
    const ObjInsUpdCat = {
      iCatId: iCatId,
      strCatCode: strCatCode,
      strCatName: strCatName,
      strDescription: strDescription,
      iCatTypeId: Number(iCatTypeId),
      strCatCodeTag: strCatCodeTag,
      iNumSeqNo: arrCode[0].iNumSeqNo,
      strPrefix: arrCode[0].strPrefix,
      strFilterColumn: strColName,
      iTitleTag: iScrId,
      strDBName: _AuthParems.DB,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(
      "Category/InsUpd_Category/",
      ObjInsUpdCat,
      dispatch
    );
    if (res) {
      ClearClick();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const ClearClick = () => {
    setiCatId(0);
    setstrCatCode("");
    setstrCatName("");
    setstrDescription("");
    setiCatTypeId(1);
    setstrColName("All");
    Load_CategoreMaster();
  };
  const DeleteClick = async () => {
    try {
      const res = await API_POST(
        `Category/Delete_Category/${iCatId}/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Search_Category = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strSearch: strtxt,
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
        strColName: strColName,
      };
      const res = await API_POST(
        "Category/Search_Category/",
        objSearch,
        dispatch
      );
      if (res) setarrCategory(res.EntCategory);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Filter_Category = async (colName) => {
    try {
      setstrColName(colName);
      Pre_Loading(dispatch, true);
      const objSearch = {
        strSearch: strSearch,
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
        strColName: colName,
      };
      const res = await API_POST(
        "Category/Search_Category/",
        objSearch,
        dispatch
      );
      if (res) setarrCategory(res.EntCategory);
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_Category = async (iCatId) => {
    try {
      const res = await API_FETCH(
        `Category/View_Category/${iCatId}/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        let EntCateg = res.EntCategory[0];
        setiCatId(EntCateg.iCatId);
        setstrCatCode(EntCateg.strCatCode);
        setstrCatName(EntCateg.strCatName);
        setstrDescription(EntCateg.strDescription);
        setiCatTypeId(EntCateg.iCatTypeId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const TabIndex = (e) => {
    var maxTab = 60;
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 50 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + maxTab + "']").focus();
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 60) {
          $(".Cat .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 60) {
          $(".Cat .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 60) {
          $(".Cat .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $(".Cat .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
      }
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>CATEGORY </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <CustomizedSelectoption
                optionparem="isoptionShow19"
                showoption={_parem.isoptionShow19}
                placeholder="Select Category"
                keydown={(e) => TabIndex(e)}
                select_value={(val) => Filter_Category(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={strColName}
                displayName="strDisplayName"
                disvalue="strValueName"
                arrydata={arrFilter}
                EmptVal="Category "
              />
              <div className="m-hsearch">
                <input
                  type="search"
                  placeholder="Example Category Name"
                  onKeyDown={(e) => TabIndex(e)}
                  value={strSearch}
                  tabIndex="59"
                  onChange={(e) => {
                    Search_Category(e.target.value);
                  }}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Category(props.strSearchtxt)}
                ></i>
              </div>
              <div className="data-list">
                {arrCategory.map((dt, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrCategory.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_Category(dt.iCatId);
                      }
                    }}
                  >
                    <label onClick={(e) => View_Category(dt.iCatId)}>
                      {dt.strCatName}
                    </label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-8 col-sm-12 mb-1">
                  <label>Category Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow18"
                    showoption={_parem.isoptionShow18}
                    placeholder="Select Category Type"
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiCatTypeId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iCatTypeId}
                    displayName="catType"
                    disvalue="catTypeId"
                    arrydata={arrCategtype}
                    EmptVal="Category Type "
                  />
                </div>
                <div className="col-md-4 col-sm-12 mb-1">
                  <label>
                    Code<span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    tabIndex="51"
                    onKeyDown={(e) => TabIndex(e)}
                    readOnly={ManualCode}
                    value={strCatCode}
                    onChange={(e) => setstrCatCode(e.target.value)}
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>
                    Name <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strCatName}
                    tabIndex="52"
                    onKeyDown={(e) => TabIndex(e)}
                    onChange={(e) => setstrCatName(e.target.value)}
                    placeholder="Category Name"
                    autoComplete="off"
                    spellCheck={false}
                    autoFocus
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>Description</label>
                  <textarea
                    tabIndex="53"
                    onKeyDown={(e) => TabIndex(e)}
                    className="form-control"
                    value={strDescription}
                    onChange={(e) => setstrDescription(e.target.value)}
                    placeholder="Description"
                    autoComplete="off"
                    spellCheck={false}
                    style={{ minHeight: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex="54"
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => {
                if (iCatTypeId === "") {
                  Show_Message(dispatch, "Category Type is Required", "info");
                } else if (strCatName === "") {
                  Show_Message(dispatch, "Category Name is Required", "info");
                } else if (strCatCode === "") {
                  Show_Message(dispatch, "Category Code is Required", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    SaveClick,
                    "Do You Want to  Save Category?"
                  );
                }
              }}
            >
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex="55"
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => {
                if (iCatId === 0) {
                  Show_Message(dispatch, "Please Select Category", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    DeleteClick,
                    "Do You Want to  Delete Category?"
                  );
                }
              }}
            >
              Delete&nbsp; <i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              tabIndex="56"
              onKeyDown={(e) => TabIndex(e)}
              onClick={ClearClick}
            >
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex="57"
              onKeyDown={(e) => TabIndex(e)}
              onClick={props.Close}
            >
              Close&nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CategoryMaster;
