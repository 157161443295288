import NetAmtCalculation from "./NetAmtCalculation";
import GSTTaxCalculation from "./GSTTaxCalculation";
import SummaryCalculation from "./SummaryCalculation";
import fn_BillDiscount from "./fn_BillDiscount";
async function Calculation(DT, AL, POS, Disc, ALTax) {
  try {
    let EntTax = [];
    let EntTaxGrp = [];
    let EntDT = JSON.parse(JSON.stringify(DT));
    let ObjAL = JSON.parse(JSON.stringify(AL));

    // Bill Discount
    let BDiscAmt =
      Disc.dBillDisAmt + Disc.dCouponDiscAmt + Disc.dRewardPointAmt;
    let BDiscPer =
      Disc.dBillDisPer + Disc.dCouponDiscPer + Disc.dRewardPointper;

    // Default Configuration Settings
    let iOthCalWQty = POS.iOthCalWQty;
    let iTaxCalcOnId = POS.iTaxCalcOnId;
    let iAutoRoundOff = POS.iAutoRoundOff;

    let strCase = BDiscPer > 0 ? "BillDiscPer" : "BillDiscAmt";

    // Calculate Bill Discount
    const billdiscres = await fn_BillDiscount(
      strCase,
      BDiscPer,
      BDiscAmt,
      EntDT
    );
    EntDT = billdiscres._EntBaseTransDt;
    //  Item NetAmount Calculation
    EntDT = await NetAmtCalculation(EntDT, iOthCalWQty, true, iTaxCalcOnId);

    // Generate tax Detailes
    var result = await GSTTaxCalculation(
      EntDT,
      ObjAL,
      ALTax,
      iOthCalWQty,
      iTaxCalcOnId
    );
    // Generate TCS Tax Detailes

    // result = await TCSTaxCalculation(EntDT, "ProId");  Enable this Code Only if TCS Tax Applicable
    EntDT = result._objBaseTransDT;
    EntTax = result._objEntTransTax;
    EntTaxGrp = result._objEntTaxGrp;

    //  Summary Calculation
    const Summaryresult = await SummaryCalculation(
      EntDT,
      ObjAL,
      EntTax,
      iAutoRoundOff
    );

    // Calculated Values
    const EntCal_Val = {
      EntItems: EntDT,
      TaxGroup: EntTaxGrp,
      Summary: Summaryresult,
      EntAddLess: ObjAL,
      BillDiscValue: billdiscres.BillDiscValue,
    };
    return EntCal_Val;
  } catch (err) {
    alert(err);
  }
}
export default Calculation;
