import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { hide_Options } from "../features/Customeselect";
import { API_POST } from "../General/Utility";
import {
  Clear_POSCustomer,
  CustomerInput_Onchange,
  ShowHide_Popup,
} from "../features/Transactionstates";
import { Country_OnChange } from "../Master/MasterTransaction";
function CustomerSearch() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParem = useSelector((state) => state.Common.Admin);
  const _Customer = useSelector((state) => state.Transstates.Customer);
  const [arrState, setarrState] = useState([]);
  const [arrCountry, setarrCountry] = useState([]);

  useEffect(() => {
    Load_Customer();
    document.addEventListener("keydown", short_cut_keypress);
    return () => {
      document.removeEventListener("keydown", short_cut_keypress);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", short_cut_keypress);
    return () => {
      document.removeEventListener("keydown", short_cut_keypress);
    };
  }, [_Customer.PartyName]);

  const Load_Customer = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const parems = {
        strSearch: "",
        iBranchId: Number(_AuthParem.Branch),
        iPartyId: 0,
        strDBName: _AuthParem.DB,
      };
      const res = await API_POST(`POS/Load_Customer/`, parems, dispatch);
      if (res) {
        setarrCountry(res.EntCountry);
        setarrState(res.EntState);
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Key_Down = async (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowDown") {
      if (ActiveIndex === 15) {
        $("[tabindex=0]").focus();
        $("[tabindex=0]").select();
      } else {
        $(`[tabindex=${ActiveIndex + 1}]`).focus();
        $(`[tabindex=${ActiveIndex + 1}]`).select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex === 0) {
        $("[tabindex=15]").focus();
        $("[tabindex=15]").select();
      } else {
        $(`[tabindex=${ActiveIndex - 1}]`).focus();
        $(`[tabindex=${ActiveIndex - 1}]`).select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex === 15) dispatch(Clear_POSCustomer());
      else {
        $(`[tabindex=${ActiveIndex + 1}]`).focus();
        $(`[tabindex=${ActiveIndex + 1}]`).select();
      }
    }
    if (e.key === "Escape") await dispatch(ShowHide_Popup("IsPOSCustomer"));
  };
  const Input_ValueChange = async (e) => {
    await dispatch(
      CustomerInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        dispatch(ShowHide_Popup("IsPOSCustomer"));
      }
      if (e.key === "F5") {
        e.preventDefault();
        dispatch(Clear_POSCustomer());
      }
      if (e.key === "F2") {
        e.preventDefault();
        dispatch(hide_Options({ isoptionShow: true }));
      }
    });
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Customer Master</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => dispatch(ShowHide_Popup("IsPOSCustomer"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pb-4">
          <div className="row">
            <div className="col-sm-12 col-md-6 mt-2">
              <label>Customer Name</label>
              <input
                type="text"
                className="form-control"
                name="PartyName"
                spellCheck={false}
                autoComplete="off"
                tabIndex={0}
                value={_Customer.PartyName}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Cutomer Name"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-12 col-md-3 mt-2">
              <label>Alias Name</label>
              <input
                type="text"
                name="AliasName"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                tabIndex={1}
                value={_Customer.AliasName}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Alias Name"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>Mobile Number</label>
              <input
                type="number"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="Mobile"
                value={_Customer.Mobile}
                tabIndex={2}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Mobile Number"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>Phone No</label>
              <input
                type="number"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                tabIndex={3}
                name="Phone"
                value={_Customer.Phone}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Phone Number"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>Door /office No</label>
              <input
                type="text"
                tabIndex={4}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                name="Address1"
                value={_Customer.Address1}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Flot / Door No"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>Street /Area</label>
              <input
                type="text"
                tabIndex={5}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                value={_Customer.Address2}
                name="Address2"
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Street "
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>Village /Town</label>
              <input
                type="text"
                tabIndex={5}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                value={_Customer.Address3}
                name="Address3"
                onChange={(e) => Input_ValueChange(e)}
                placeholder=" Area"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>City</label>
              <input
                type="text"
                tabIndex={6}
                spellCheck={false}
                autoComplete="off"
                className="form-control"
                value={_Customer.Address4}
                name="Address4"
                onChange={(e) => Input_ValueChange(e)}
                placeholder="City"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>Pincode</label>
              <input
                type="number"
                className="form-control"
                tabIndex={7}
                spellCheck={false}
                autoComplete="off"
                name="Pincode"
                value={_Customer.Pincode}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Pincode"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>State</label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_parem.isoptionShow1}
                placeholder="Select State"
                tabIndex={8}
                onKeyDown={(e) => Key_Down(e)}
                select_value={(val) =>
                  Input_ValueChange({
                    target: {
                      name: "State",
                      value: val,
                    },
                  })
                }
                btnname="Customer"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={_Customer.State}
                displayName="strSName"
                disvalue="iSId"
                arrydata={arrState}
                disabled={false}
                EmptVal="State"
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>Country</label>
              <CustomizedSelectoption
                optionparem="isoptionShow2"
                showoption={_parem.isoptionShow2}
                placeholder="Select Country"
                tabIndex={9}
                onKeyDown={(e) => Key_Down(e)}
                select_value={async (val) => {
                  Input_ValueChange({
                    target: {
                      name: "Country",
                      value: val,
                    },
                  });
                  await setarrState(
                    await Country_OnChange(val, _AuthParem.DB, dispatch)
                  );
                }}
                btnname="Country"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={_Customer.Country}
                displayName="strCountryName"
                disvalue="iCountryId"
                arrydata={arrCountry}
                disabled={false}
                EmptVal="Country"
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>E-Mail</label>
              <input
                type="text"
                className="form-control"
                name="Email"
                value={_Customer.Email}
                spellCheck={false}
                autoComplete="off"
                tabIndex={10}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Example@gmail.com"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>GSTN No</label>
              <input
                type="text"
                className="form-control"
                name="GST"
                spellCheck={false}
                autoComplete="off"
                tabIndex={11}
                value={_Customer.GST}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="GSTIN Number"
                onKeyDown={(e) => Key_Down(e)}
                maxLength={15}
              />
            </div>
            <div className="col-sm-6 col-md-3 mt-2">
              <label>Remark</label>
              <input
                type="text"
                className="form-control"
                name="Remark"
                spellCheck={false}
                autoComplete="off"
                tabIndex={12}
                value={_Customer.Remark}
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Remark"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-sm-12 col-md-6 mt-2">
              <div className="flex-wrap mb-1">
                <input
                  type="checkbox"
                  name="IsSameAdd"
                  tabIndex={13}
                  checked={_Customer.IsSameAdd}
                  onChange={(e) =>
                    Input_ValueChange({
                      target: {
                        name: "IsSameAdd",
                        value: !_Customer.IsSameAdd,
                      },
                    })
                  }
                  onKeyDown={(e) => Key_Down(e)}
                />
                <span>Delivery Address Same As Billing Address</span>
              </div>
              <textarea
                disabled={_Customer.IsSameAdd}
                type="text"
                className="form-control"
                name="ShippAdd"
                value={_Customer.ShippAdd}
                style={{ minHeight: "100px" }}
                tabIndex={14}
                spellCheck={false}
                autoComplete="off"
                onChange={(e) => Input_ValueChange(e)}
                placeholder="Delivery Address"
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              onClick={(e) => dispatch(Clear_POSCustomer())}
              tabIndex={15}
              className="btn-fabgreen"
              onKeyDown={(e) => Key_Down(e)}
            >
              Clear (F5)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CustomerSearch;
