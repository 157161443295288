import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import { useDispatch, useSelector } from "react-redux";
import Messagedialogbox, {
  Show_Message,
  Pre_Loading,
  Ask_Question,
} from "../General/Messagedialogbox";
import ManualCustomerSelect from "./ManualCustomerSelect";
import {
  Update_Calculation,
  add_Othercharges,
  setInvoiceDT,
  setInvoiceHTA,
  setInvoiceHT,
  setInvoicetaxdefault,
  setdefaultdata,
  setdiscount,
  setisAL_Onchange,
  setisInvoiceDT_Onchange,
  Clear_ManualInvoice,
} from "../features/ManualInvoice";
import CustomerInfo from "./CustomerInfo";
import VouchernoSetting from "../Transaction/VouchernoSetting";
import $ from "jquery";
import ManualInvoiceView from "./ManualInvoiceView";
import PrintInvoicDesign from "../General/PrintInvoice";

import ManualReceipt from "./ManualReceipt";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
function ManualInvoice() {
  //#region  Clobal Redux Parems
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((state) => state.Invoice.Common);
  const _HT = useSelector((state) => state.Invoice.HT[0]);
  const _HTA = useSelector((state) => state.Invoice.HTA[0]);
  const _BillAdd = useSelector((state) => state.Invoice.BillAdd[0]);
  const _ShipAdd = useSelector((state) => state.Invoice.ShipAdd[0]);
  const _DT = useSelector((state) => state.Invoice.DT);
  const _Tax = useSelector((state) => state.Invoice.Tax);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  //#endregion

  //#region Validation Parem
  const [isCustomer, setisCustomer] = useState(false);
  const [isBillAdd, setisBillAdd] = useState(true);
  const [isVouchNoSett, setisVouchNoSett] = useState(false);
  const [isView, setisView] = useState(false);
  const [isReceipt, setisReceipt] = useState(false);
  const [strSavefor, setstrSavefor] = useState("Save");
  const [strSearch, setstrSearch] = useState("");
  //#endregion

  //#region  Load event
  useEffect(() => {
    Load_ManualInvoice();
  }, []);
  const Load_ManualInvoice = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);

      const Parem = `${_Common.ScrName}/Load_ManualInvoice/${_LocalParems.Branch}/${_LocalParems.DB}`;
      let res = await API_FETCH(Parem, dispatch); //  Call API
      if (res) {
        Assign_Default(res);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Assign_Default = (res) => {
    const EntFields = res.Fields;
    let EntInvCol = EntFields.filter(
      (dt) => dt.coltypeId === 1 && !dt.noAccess
    );
    let EntCol = EntFields.filter(
      (dt) => dt.coltypeId === 2 && dt.noAccess === false
    );
    EntCol = EntCol.sort((a, b) => a.tabIndex - b.tabIndex);
    dispatch(
      setdefaultdata({
        InvNo: res.NoFormat[0].strUserNo,
        PreFix: res.NoFormat[0].strPrefix,
        NumSeqNo: res.NoFormat[0].iNumSeqNo,
        ManualNo: res.NoFormat[0].isManualNo,
        InvColPer: EntFields,
        TaxCalcOnId: Number(res.TaxCalcOnId),
        OtherCal: res.OtherCal,
        AutoRoundOff: res.AutoRoundOff,
        EntCountry: res.Country,
        EntState: res.State,
        BSId: res.branchstate,
        Permission: EntFields,
        InvCol: EntInvCol,
        tblcol: EntCol,
        BillType: res.BillType,
        TaxType: res.TaxRelType,
        DT: [],
      })
    );
    dispatch(
      setInvoicetaxdefault({
        arrSGST: res.SGST,
        arrCGST: res.CGST,
        arrIGST: res.IGST,
        arrTcsTax: res.Tcs,
        arrTaxType: res.TaxType,
      })
    );
  };
  //#endregion

  //#region  Click Event
  const InsUpd_ManualInvoice = async () => {
    try {
      const BillAdd = Convetreadonlytoeditable(_BillAdd);
      const ShipAdd = Convetreadonlytoeditable(_ShipAdd);
      BillAdd.State = Number(BillAdd.State);
      BillAdd.Country = Number(BillAdd.Country);
      ShipAdd.State = Number(ShipAdd.State);
      ShipAdd.Country = Number(ShipAdd.Country);
      const InvParems = {
        _InvoiceHT: [_HT],
        _InvoiceHTA: [_HTA],
        _InvoiceDT: _DT.arrEntDT,
        _InvoiceDTTax: _DT.arrEntDTTax,
        _InvoiceAL: _DT.arrEntAL,
        _InvoiceALTax: _DT.arrEntALTax,
        _InvoiceTax: _DT.arrEntTax,
        _BillAdd: [BillAdd],
        _ShipAdd: [ShipAdd],
        iBranchId: _Common.bId,
        strDBName: _Common.db,
      };
      const res = await API_POST(
        "ManualInvoice/InsUpd_ManualInvoice",
        InvParems,
        dispatch
      );
      let isSuccess = false;
      if (res) {
        isSuccess = true;
      }
      await Print_ShareInvoice(isSuccess, res.strMessage);
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Delete_ManualInvoice = async () => {
    try {
      const InvParems = `ManualInvoice/Delete_ManualInvoice/${_HT.InvId}/${_Common.db}`;
      const res = await API_DELETE(InvParems, dispatch);
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        await Clear_Invoice();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Clear_Invoice = async () => {
    await dispatch(Clear_ManualInvoice());
    await Load_ManualInvoice();
  };
  const PrintInvoice = async (PrintFor) => {
    try {
      let VouchId = Number(_HT.InvId) > 0 ? Number(_HT.InvId) : 0;
      const parems = `PrintDesing/PrintPreView/100/0/${VouchId}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`;
      const res = await API_FETCH(parems, dispatch);
      if (res) {
        await PrintInvoicDesign(res, 100, PrintFor);
        await Clear_Invoice();
      } else Show_Message(res.strMessage, "info");
    } catch (err) {
      Show_Message(err.strMessage, "info");
    }
  };
  const Print_ShareInvoice = async (isSuccess, strMessage) => {
    try {
      if (isSuccess) {
        if (
          Number(_HT.InvId) === 0 &&
          (Number(_HT.BT_Id) === 2 ||
            Number(_HT.BT_Id) === 3 ||
            Number(_HT.BT_Id) === 4)
        ) {
          setisReceipt(!isReceipt);
        } else {
          Show_Message(dispatch, strMessage, "success");
          Pre_Loading(dispatch, true);
          switch (strSavefor) {
            case "Print":
              await PrintInvoice("Print");
              break;
            case "PDF":
              await PrintInvoice("PDF");
              break;
            case "Share":
              await Share_toEmail();
              break;
            default:
              await Clear_Invoice();
              break;
          }
          Pre_Loading(dispatch, false);
        }
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch.err.message, "error");
    }
  };
  const Close_Receipt = async () => {
    setisReceipt(!isReceipt);
    Pre_Loading(dispatch, true);
    switch (strSavefor) {
      case "Print":
        await PrintInvoice("Print");
        break;
      case "PDF":
        await PrintInvoice("PDF");
        break;
      case "Share":
        await Share_toEmail();
        break;
      default:
        break;
    }
    Pre_Loading(dispatch, false);
  };
  const Share_toEmail = async () => {
    try {
      let VouchId = Number(_HT.InvId) > 0 ? Number(_HT.InvId) : 0;
      const parems = `PrintDesing/PrintPreView/${100}/0/${VouchId}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`;
      const res = await API_FETCH(parems, dispatch);
      if (res) {
        var EntPdf = await PrintInvoicDesign(res, 100, "PDF", true);
        SentEmail(
          EntPdf,
          EntPdf.PartyInfo[0].email.replace("E-Mail: ", ""),
          EntPdf.CompanyInfo[0].compEMail
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const SentEmail = async (Info, ToEmail, FromEMail) => {
    try {
      if (FromEMail === "" && ToEmail === "") {
        Show_Message(dispatch, "Please Enter Valid Email Detailes", "info");
      } else {
        const Summary = Info.Summary[0];
        const formdata = new FormData();
        var strCompanyName = _AuthParems.Branchname;
        var partyinfo = Info.PartyInfo[0];

        var Message = `
Dear ${partyinfo.custName},
Thank You for Your Recent Purchase Invoice-#${partyinfo.salInvNo}
      
We hope this message finds you well. 
We want to express our sincere gratitude for choosing ${strCompanyName} for your recent purchase. 
We appreciate your trust in our Products.
      
Your order details are as follows:
Invoice Number: ${partyinfo.salInvNo}
Date of Purchase: ${partyinfo.salInvDate}
Total Amount: ₹${stringtodecimal(Summary.netAmt)}
      
We understand that you have a choice when it comes to your Product needs, and we are thrilled to have had the opportunity to serve you. 
Our team is committed to providing top-notch quality and excellent customer service, and we hope your recent experience reflects that commitment.
      
If you have any questions about your purchase or if there's anything else we can assist you with, 
please don't hesitate to reach out to our customer support team at ${
          Info.Mobile
        }.
      
Once again, thank you for choosing ${strCompanyName}. We truly value your business and look forward to serving you again in the future.
      
Best Regards,
      ${strCompanyName}`;
        formdata.append("strHost", "smtp.gmail.com");
        formdata.append("iPort", 587);
        formdata.append("strFromEmail", FromEMail);
        formdata.append("strPassword", "uhvf iyjk iqio ximf");
        formdata.append("strToEmail", ToEmail);

        formdata.append("strSubject", "Invoice Generated for Your Purchase");
        formdata.append("strBody", Message);
        formdata.append("Attachment", UrlToFile(Info.PdfURL, Info.FileName));
        formdata.append("FileName", Info.FileName);
        const res = await API_POST(`Transaction/SendEmail`, formdata, dispatch);
        if (res) {
          await Clear_Invoice();
          Show_Message(dispatch, res.strMessage, "success");
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const stringtodecimal = (num) => {
    return Number(num).toFixed(2);
  };
  const UrlToFile = (dataUrl, fileName) => {
    // Split the Data URL to get the content type and data
    const data = dataUrl.split(",");

    // Convert base64 to a Blob
    const blob = new Blob([atob(data[1])], { type: data[0] });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: data[0] });

    return file;
  };
  //#endregion

  //#region onChange Event
  const Add_NewProduct = async (proname) => {
    try {
      const Parem = { _InvoiceDT: _DT.arrEntDT, strProName: proname };
      let res = await API_POST(
        "ManualInvoice/Add_NewProduct/",
        Parem,
        dispatch
      );
      if (res) {
        setstrSearch("");
        dispatch(setInvoiceDT(res.EntProduct));
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const table_inputonchange = (val, col, trIndex) => {
    let EntDT = Convetreadonlytoeditable(_DT.arrEntDT);
    EntDT = EntDT.map((dt, Index) => {
      if (Index === trIndex) {
        dt[col.colName] = val;
        dt.rateWithTax = col.colName === "rateWithoutTax" ? 0 : dt.rateWithTax;
        dt.rateWithoutTax =
          col.colName === "rateWithTax" ? 0 : dt.rateWithoutTax;
        dt.itemDiscPer = col.colName === "itemDiscAmt" ? 0 : dt.itemDiscPer;
        dt.itemDiscAmt = col.colName === "itemDiscPer" ? 0 : dt.itemDiscAmt;
        if (
          col.colName !== "proName" ||
          col.colName !== "unitName" ||
          col.colName !== "commodityCode"
        )
          dispatch(setisInvoiceDT_Onchange(true));
        else dispatch(setisInvoiceDT_Onchange(false));
      }
      return dt;
    });
    dispatch(setInvoiceDT(EntDT));
  };
  const Input_HTOnchange = (Col, Val) => {
    dispatch(setInvoiceHT({ Name: Col, value: Val }));
  };
  const Input_HTAOnchange = (Col, Val) => {
    dispatch(setInvoiceHTA({ Name: Col, value: Val }));
  };
  const Othercharges_Onchange = (value, Name, Index) => {
    let EntAL = Convetreadonlytoeditable(_DT.arrEntAL);
    EntAL = EntAL.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt[Name] = value;
        dt[Name] = value;
        dt.rateWithTax = Name === "rateWithoutTax" ? 0 : dt.rateWithTax;
        dt.rateWithoutTax = Name === "rateWithTax" ? 0 : dt.rateWithoutTax;
      }
      return dt;
    });
    dispatch(add_Othercharges(EntAL));
    if (Name === "rateWithoutTax" || Name === "rateWithTax") {
      dispatch(setisAL_Onchange(true));
    } else {
      dispatch(setisAL_Onchange(false));
    }
  };
  const Product_TaxtypeOnchange = (val, Index) => {
    let EntProduct = Convetreadonlytoeditable(_DT.arrEntDT);
    EntProduct = EntProduct.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt.taxType = val.iTaxLvlId;
        dt.strTaxType = val.strTaxLvlType;
        dt.isTaxtype = false;
      }
      return dt;
    });
    dispatch(setInvoiceDT(EntProduct));
  };
  const Product_TaxOnchange = (Product, Tax, Index) => {
    let DT = Convetreadonlytoeditable(_DT);
    let EntTax = [
      {
        DTTaxId: 0,
        InvId: _HT.InvId,
        InvDTId: Product.invDTId,
        ProSNo: Product.sNo,
        Level: Product.taxType,
        TT_Id: Tax.iTT_Id,
        TaxId: Tax.iTaxId,
        TaxPer: Tax.dTaxPer,
        TaxDesc: Tax.strTaxDesc,
        TaxableAmt: Product.rateWithoutTax,
        TaxAmt: (Product.rateWithoutTax * Tax.dTaxPer) / 100,
      },
    ];
    if (Product.taxType === 1 && _HT.TaxRelId === 1) {
      let sgst = _Tax.arrSGST.filter(
        (Data) => Data.dTaxPer === Tax.dTaxPer / 2
      )[0];
      let cgst = _Tax.arrCGST.filter(
        (Data) => Data.dTaxPer === Tax.dTaxPer / 2
      )[0];
      EntTax = [
        {
          DTTaxId: 0,
          InvId: _HT.InvId,
          InvDTId: Product.invDTId,
          ProSNo: Product.sNo,
          TT_Id: sgst.iTT_Id,
          TaxId: sgst.iTaxId,
          TaxPer: sgst.dTaxPer,
          TaxDesc: sgst.strTaxDesc,
          Level: Product.taxType,
          TaxableAmt: Product.rateWithoutTax,
          TaxAmt: (Product.rateWithoutTax * sgst.dTaxPer) / 100,
        },
        {
          DTTaxId: 0,
          InvId: _HT.InvId,
          InvDTId: Product.invDTId,
          ProSNo: Product.sNo,
          TT_Id: cgst.iTT_Id,
          Level: Product.taxType,
          TaxId: cgst.iTaxId,
          TaxPer: cgst.dTaxPer,
          TaxDesc: cgst.strTaxDesc,
          TaxableAmt: Product.rateWithoutTax,
          TaxAmt: (Product.rateWithoutTax * cgst.dTaxPer) / 100,
        },
      ];
    }
    DT.arrEntDT.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt.objItemTax = EntTax;
        dt.taxPer = Tax.dTaxPer;
        dt.rateWithTax = 0;
        dt.isShowtax = false;
      }
      return dt;
    });
    Calculate_Transaction(DT, _HT);
  };
  const Service_TaxtypeOnchange = (val, Index) => {
    let EntOption = Convetreadonlytoeditable(_DT.arrEntAL);
    EntOption = EntOption.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt.taxType = val.iTaxLvlId;
        dt.strTaxType = val.strTaxLvlType;
        dt.isTaxtype = false;
      }
      return dt;
    });
    dispatch(add_Othercharges(EntOption));
  };
  const Service_TaxOnchange = (AL, Tax, Index) => {
    let DT = Convetreadonlytoeditable(_DT);
    let EntTax = [
      {
        ALTaxId: 0,
        InvId: _HT.InvId,
        ALId: AL.alId,
        ALSNo: AL.sNo,
        Level: AL.taxType,
        TT_Id: Tax.iTT_Id,
        TaxId: Tax.iTaxId,
        TaxPer: Tax.dTaxPer,
        TaxDesc: Tax.strTaxDesc,
        TaxableAmt: AL.rateWithoutTax,
        TaxAmt: (AL.rateWithoutTax * Tax.dTaxPer) / 100,
      },
    ];
    if (AL.taxType === 1 && _HT.TaxRelId === 1) {
      let sgst = _Tax.arrSGST.filter(
        (Data) => Data.dTaxPer === Tax.dTaxPer / 2
      )[0];
      let cgst = _Tax.arrCGST.filter(
        (Data) => Data.dTaxPer === Tax.dTaxPer / 2
      )[0];
      EntTax = [
        {
          ALTaxId: 0,
          ALId: AL.alId,
          InvId: _HT.InvId,
          ALSNo: AL.sNo,
          TT_Id: sgst.iTT_Id,
          TaxId: sgst.iTaxId,
          TaxPer: sgst.dTaxPer,
          TaxDesc: sgst.strTaxDesc,
          Level: AL.taxType,
          TaxableAmt: AL.rateWithoutTax,
          TaxAmt: (AL.rateWithoutTax * sgst.dTaxPer) / 100,
        },
        {
          ALTaxId: 0,
          ALId: AL.alId,
          InvId: _HT.InvId,
          ALSNo: AL.sNo,
          TT_Id: cgst.iTT_Id,
          Level: AL.taxType,
          TaxId: cgst.iTaxId,
          TaxPer: cgst.dTaxPer,
          TaxDesc: cgst.strTaxDesc,
          TaxableAmt: AL.rateWithoutTax,
          TaxAmt: (AL.rateWithoutTax * cgst.dTaxPer) / 100,
        },
      ];
    }
    DT.arrEntAL.map((dt, dtIndex) => {
      if (Index === dtIndex) {
        dt.objALTax = EntTax;
        dt.taxPer = Tax.dTaxPer;
        dt.rateWithTax = 0;
        dt.isShowtax = false;
      }
      return dt;
    });
    Calculate_Transaction(DT, _HT, dispatch);
  };
  const Discount_Onchange = (Name, Value) => {
    dispatch(
      setdiscount({
        Name: Name,
        value: Value,
      })
    );
  };
  const Calculate_Transaction = async (DT, HT, Discount) => {
    try {
      const objParems = {
        _InvoiceDT: DT.arrEntDT,
        _InvoiceAL: DT.arrEntAL,
        TaxRelId: HT.TaxRelId,
        iBranchId: _Common.bId,
        strDBName: _Common.db,
        OtherCal: HT.OtherCal,
        TaxCalcOnId: HT.TaxCalcOnId,
        dBillDiscPer: Number(HT.BillDiscPer),
        dBillDiscAmt: Number(HT.BillDiscAmt),
        dALBillDisc: Number(HT.ALDiscVal),
        AutoRoundOff: HT.AutoRoundOff,
      };
      const res = await API_POST(
        "ManualInvoice/Calculate_Transaction",
        objParems,
        dispatch
      );
      if (res) {
        let Summary = res.EntSummary;
        dispatch(
          Update_Calculation({
            arrEntDT: res.arrEntDT,
            arrEntDTTax: res.arrEntDTTax,
            arrEntAL: res.arrEntAL,
            arrEntALTax: res.arrEntALTax,
            arrEntTax: res.arrEntTax,
            Summary: {
              Qty: Summary.qty,
              GoodsValue: Summary.goodsValue,
              DiscAmt: Summary.discount,
              TaxAmt: Summary.tax,
              TCSTaxAmt: Summary.tcsTax,
              ALAmt: Summary.addLess,
              RoundOff: Summary.roundOff,
              NetAmt: Summary.netAmt,
            },
            BillDiscVal: res.BillDiscVal,
          })
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  //#endregion

  //#region  Other Charges
  const Add_OtherCharges = () => {
    let SNo = _DT.arrEntAL.length;
    dispatch(
      add_Othercharges([
        ..._DT.arrEntAL,
        {
          aLId: 0,
          invId: 0,
          sNo: SNo + 1,
          sACCode: "",
          venName: "",
          serName: "",
          rateWithoutTax: 0,
          rateWithTax: 0,
          taxableAmt: 0,
          taxPer: 0,
          taxAmt: 0,
          itemAmt: 0,
          isNewItem: 1,
          taxType: 1,
          objALTax: [],
          strTaxType: "GST",
          isTaxtype: false,
          isShowtax: false,
        },
      ])
    );
  };
  const Remove_OtherCharges = (trIndex) => {
    if (_DT.arrEntAL.length === 1) {
      dispatch(
        add_Othercharges([
          ..._DT.arrEntAL,
          {
            ALId: 0,
            InvId: 0,
            SNo: 1,
            SACCode: "",
            VenName: "",
            SerName: "",
            RateWithoutTax: 0,
            RateWithTax: 0,
            TaxableAmt: 0,
            TaxPer: 0,
            TaxAmt: 0,
            ItemAmt: 0,
            IsNewItem: 0,
            TaxType: 1,
            strTaxType: "GST",
            arrALTax: [],
            isTaxtype: false,
            isShowtax: false,
          },
        ])
      );
    } else
      dispatch(
        add_Othercharges(_DT.arrEntAL.filter((dt, Index) => Index !== trIndex))
      );
  };
  //#endregion

  //#region  Commmon Methods
  const Search_Product = (strtxt) => {
    try {
      setstrSearch(strtxt);
    } catch (err) {}
  };
  const Convetreadonlytoeditable = (data) => {
    return JSON.parse(JSON.stringify(data));
  };
  const isShowProTax = (Index, value) => {
    let EntDT = Convetreadonlytoeditable(_DT.arrEntDT);
    EntDT = EntDT.map((dt, dtIndex) => {
      if (Index === dtIndex) dt.isShowtax = value;
      return dt;
    });
    dispatch(setInvoiceDT(EntDT));
  };
  const isShowProtaxtype = (Index, value) => {
    let EntDT = Convetreadonlytoeditable(_DT.arrEntDT);
    EntDT = EntDT.map((dt, dtIndex) => {
      if (Index === dtIndex) dt.isTaxtype = value;
      return dt;
    });
    dispatch(setInvoiceDT(EntDT));
  };
  const isShowSerTax = (Index, value) => {
    let EntOption = Convetreadonlytoeditable(_DT.arrEntAL);
    EntOption = EntOption.map((dt, dtIndex) => {
      if (Index === dtIndex) dt.isShowtax = value;
      return dt;
    });
    dispatch(add_Othercharges(EntOption));
  };
  const isShowSertaxtype = (Index, value) => {
    let EntOption = Convetreadonlytoeditable(_DT.arrEntAL);
    EntOption = EntOption.map((dt, dtIndex) => {
      if (Index === dtIndex) dt.isTaxtype = value;
      return dt;
    });
    dispatch(add_Othercharges(EntOption));
  };
  //#endregion

  return (
    <>
      <SideMenu ScrId={100} scrType="Sales" />
      <div className="Manual-Container">
        <div className="Manual-card">
          <div className="Manual-wrapper">
            <div className="customer-container">
              <label>Cutomer Name</label>
              <div className="party-info">
                <ManualCustomerSelect />
                <div className="party-address">
                  <div className="address">
                    <label>
                      <i className="fa-solid fa-receipt"></i>
                      &nbsp;Billing Address &nbsp;
                      <i
                        className="bx bxs-pencil"
                        onClick={(e) => {
                          setisBillAdd(true);
                          setisCustomer(!isCustomer);
                        }}
                      ></i>
                    </label>
                    {_BillAdd.CustName.length > 0 && (
                      <p>
                        <strong>{_BillAdd.CustName}</strong>
                        <span>
                          {_BillAdd.Add1} {_BillAdd.Add1.length > 0 ? "," : ""}
                          {_BillAdd.Add2}
                        </span>
                        <span>
                          {_BillAdd.Add3} {_BillAdd.Add3.length > 0 ? "," : ""}
                          {_BillAdd.Add4}
                        </span>
                        <span>
                          {_BillAdd.Add4}
                          {_BillAdd.Pincode.length > 0 ? "-" : ""}
                          {_BillAdd.Pincode}
                        </span>
                        <span>
                          {_BillAdd.Mobile}
                          {_BillAdd.Email.length > 0 ? "," : ""}
                          {_BillAdd.Email}
                        </span>
                        <strong>GST : {_BillAdd.GST}</strong>
                      </p>
                    )}
                  </div>
                  <div className="address">
                    <label>
                      <i className="bx bxs-truck"></i>
                      &nbsp; Delivery Address &nbsp;
                      <i
                        className="bx bxs-pencil"
                        onClick={(e) => {
                          setisBillAdd(false);
                          setisCustomer(!isCustomer);
                        }}
                      ></i>
                    </label>
                    {_ShipAdd.CustName.length > 0 && (
                      <p>
                        <strong>{_ShipAdd.CustName}</strong>
                        <span>
                          {_ShipAdd.Add1}
                          {_ShipAdd.Add1.length > 0 ? "," : ""}
                          {_ShipAdd.Add2}
                        </span>
                        <span>
                          {_ShipAdd.Add3}
                          {_ShipAdd.Add3.length > 0 ? "," : ""}
                          {_ShipAdd.Add4}
                        </span>
                        <span>
                          {_ShipAdd.Add4}
                          {_ShipAdd.Pincode.length > 0 ? "-" : ""}
                          {_ShipAdd.Pincode}
                        </span>
                        <span>
                          {_ShipAdd.Mobile}{" "}
                          {_ShipAdd.Email.length > 0 ? "," : ""}
                          {_ShipAdd.Email}
                        </span>
                        <strong>GST : {_ShipAdd.GST}</strong>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="jb-row vouch-content">
              <div className="jb-col col-2">
                <label>Inv No</label>
                <input
                  type="text"
                  spellCheck={false}
                  autoComplete="off"
                  className="form-control"
                  placeholder="Invoice No"
                  onChange={(e) => Input_HTOnchange("InvNo", e.target.value)}
                  value={_HT.InvNo}
                  readOnly={_HT.ManualNo ? false : true}
                />
                <span
                  className="inv-no"
                  onClick={(e) => setisVouchNoSett(!isVouchNoSett)}
                >
                  <i className="bx bx-cog"></i>
                </span>
              </div>
              <div className="jb-col col-2">
                <label>Inv Date</label>
                <input
                  type="date"
                  spellCheck={false}
                  autoComplete="off"
                  className="form-control"
                  onChange={(e) => Input_HTOnchange("InvDate", e.target.value)}
                  value={_HT.InvDate}
                />
              </div>
              <div className="jb-col col-2">
                <label>
                  Bill Type <span className="danger">* </span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Bill Type"
                  select_value={(val) => Input_HTOnchange("BT_Id", val)}
                  btnname="Bill Type"
                  btnshow={false}
                  show_popup=""
                  valueonly={true}
                  defaultval={_HT.BT_Id}
                  displayName="strBillType"
                  disvalue="iBT_Id"
                  arrydata={_Common.arrEntBillType}
                  disabled={false}
                  EmptVal="Bill Type"
                />
              </div>
              <div className="jb-col col-2">
                <label>
                  Tax Type <span className="danger">* </span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Tax Type"
                  select_value={(val) => Input_HTOnchange("TaxRelId", val)}
                  btnname="Tax Type"
                  btnshow={false}
                  show_popup=""
                  valueonly={true}
                  defaultval={_HT.TaxRelId}
                  displayName="strTaxType"
                  disvalue="iTaxRelId"
                  arrydata={_Common.arrEntTaxType}
                  disabled={true}
                  EmptVal="Tax Type"
                />
              </div>
              {_Common.InvColPer.strPartyDC && (
                <div className="jb-col col-2">
                  <label>Party DC No</label>
                  <input
                    type="text"
                    placeholder="Party DC No"
                    value={_HT.PartyVouchers}
                    onChange={(e) =>
                      Input_HTAOnchange("PartyVouchers", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRefNo1 && (
                <div className="jb-col col-2">
                  <label>Ref No 1</label>
                  <input
                    type="text"
                    placeholder="Reference No"
                    value={_HTA.RefNum1}
                    onChange={(e) =>
                      Input_HTAOnchange("RefNum1", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpRefDate1 && (
                <div className="jb-col col-2">
                  <label>Ref Date 1</label>
                  <input
                    type="date"
                    value={_HTA.RefDate1}
                    onChange={(e) =>
                      Input_HTAOnchange("RefDate1", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRefNo2 && (
                <div className="jb-col col-2">
                  <label>Ref No 2</label>
                  <input
                    type="text"
                    placeholder="Reference No"
                    value={_HTA.RefNum2}
                    onChange={(e) =>
                      Input_HTAOnchange("RefNum2", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpRefDate2 && (
                <div className="jb-col col-2">
                  <label>Ref Date 2</label>
                  <input
                    type="date"
                    value={_HTA.RefDate2}
                    onChange={(e) =>
                      Input_HTAOnchange("RefDate2", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRefNo3 && (
                <div className="jb-col col-2">
                  <label>Ref No 3</label>
                  <input
                    type="text"
                    placeholder="Reference No"
                    value={_HTA.RefNum3}
                    onChange={(e) =>
                      Input_HTAOnchange("RefNum3", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpRefDate3 && (
                <div className="jb-col col-2">
                  <label>Ref Date 3</label>
                  <input
                    type="date"
                    value={_HTA.RefDate3}
                    onChange={(e) =>
                      Input_HTAOnchange("RefDate3", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRefNo4 && (
                <div className="jb-col col-2">
                  <label>Ref No 4</label>
                  <input
                    type="text"
                    placeholder="Reference No"
                    value={_HTA.RefNum4}
                    onChange={(e) =>
                      Input_HTAOnchange("RefNum4", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpRefDate4 && (
                <div className="jb-col col-2">
                  <label>Ref Date 4</label>
                  <input
                    type="date"
                    value={_HTA.RefDate4}
                    onChange={(e) =>
                      Input_HTAOnchange("RefDate4", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.deliveryMode && (
                <div className="jb-col col-2">
                  <label>Delivery Mode</label>
                  <input
                    type="text"
                    placeholder="Delivery Mode"
                    value={_HTA.DeliveryMode}
                    onChange={(e) =>
                      Input_HTAOnchange("DeliveryMode", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRefName && (
                <div className="jb-col col-2">
                  <label>Referenece Name</label>
                  <input
                    type="text"
                    placeholder="Reference Name"
                    value={_HTA.RefName}
                    onChange={(e) =>
                      Input_HTAOnchange("RefName", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strAgentName && (
                <div className="jb-col col-2">
                  <label>Agent Name</label>
                  <input
                    type="text"
                    placeholder="Reference Name"
                    value={_HTA.AgentName}
                    onChange={(e) =>
                      Input_HTAOnchange("AgentName", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strEcomGSTN && (
                <div className="jb-col col-2">
                  <label>Ecom GSTN</label>
                  <input
                    type="text"
                    placeholder="E-Com GSTN"
                    value={_HTA.EComGSTNo}
                    onChange={(e) =>
                      Input_HTAOnchange("EComGSTNo", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strValidDays && (
                <div className="jb-col col-2">
                  <label>Validitity</label>
                  <input
                    type="text"
                    placeholder="Reference No"
                    value={_HTA.ValidDays}
                    onChange={(e) =>
                      Input_HTAOnchange("ValidDays", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpValidDate && (
                <div className="jb-col col-2">
                  <label>Validitity Upto</label>
                  <input
                    type="date"
                    value={_HTA.ValidUpto}
                    onChange={(e) =>
                      Input_HTAOnchange("ValidUpto", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strPayTerms && (
                <div className="jb-col col-2">
                  <label>PayTerms</label>
                  <input
                    type="text"
                    placeholder="Reference No"
                    value={_HTA.PayTermDays}
                    onChange={(e) =>
                      Input_HTAOnchange("PayTermDays", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpPayTermUpto && (
                <div className="jb-col col-2">
                  <label>PayTerms Upto</label>
                  <input
                    type="date"
                    value={_HTA.PayTermUpto}
                    onChange={(e) =>
                      Input_HTAOnchange("PayTermUpto", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strDelTerms && (
                <div className="jb-col col-2">
                  <label>Delivery Terms</label>
                  <input
                    type="text"
                    placeholder="Delivery Terms"
                    value={_HTA.DeliTermDays}
                    onChange={(e) =>
                      Input_HTAOnchange("DeliTermDays", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpDelTermUpto && (
                <div className="jb-col col-2">
                  <label>Delivery Terms Upto</label>
                  <input
                    type="date"
                    value={_HTA.DeliTermUpto}
                    onChange={(e) =>
                      Input_HTAOnchange("DeliTermUpto", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strTransport && (
                <div className="jb-col col-2">
                  <label>Transport</label>
                  <input
                    type="text"
                    placeholder="Transport"
                    value={_HTA.Transport}
                    onChange={(e) =>
                      Input_HTAOnchange("Transport", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strVechicleNo && (
                <div className="jb-col col-2">
                  <label>Vechicle No</label>
                  <input
                    type="text"
                    placeholder="Transport"
                    value={_HTA.VehicleNumber}
                    onChange={(e) =>
                      Input_HTAOnchange("VehicleNumber", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strDespatch && (
                <div className="jb-col col-2">
                  <label>Desptach</label>
                  <input
                    type="text"
                    placeholder="Despatch"
                    value={_HTA.Despatch}
                    onChange={(e) =>
                      Input_HTAOnchange("Despatch", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpDespatchDate && (
                <div className="jb-col col-2">
                  <label>Desptach Date</label>
                  <input
                    type="date"
                    value={_HTA.DespatchDate}
                    onChange={(e) =>
                      Input_HTAOnchange("DespatchDate", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strDestination && (
                <div className="jb-col col-2">
                  <label>Destination</label>
                  <input
                    type="text"
                    placeholder="Destination"
                    value={_HTA.Destination}
                    onChange={(e) =>
                      Input_HTAOnchange("Destination", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strLicenseNo && (
                <div className="jb-col col-2">
                  <label>License No</label>
                  <input
                    type="text"
                    placeholder="License No"
                    value={_HTA.LicenseNo}
                    onChange={(e) =>
                      Input_HTAOnchange("LicenseNo", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.dpLicenseDate && (
                <div className="jb-col col-2">
                  <label>License Date</label>
                  <input
                    type="date"
                    value={_HTA.LicenseDate}
                    onChange={(e) =>
                      Input_HTAOnchange("LicenseDate", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRemark1 && (
                <div className="jb-col col-2">
                  <label>Remark 1</label>
                  <input
                    type="text"
                    placeholder="Remark"
                    value={_HTA.Remark1}
                    onChange={(e) =>
                      Input_HTAOnchange("Remark1", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRemark2 && (
                <div className="jb-col col-2">
                  <label>Remark 2</label>
                  <input
                    type="text"
                    placeholder="Remark"
                    value={_HTA.Remark2}
                    onChange={(e) =>
                      Input_HTAOnchange("Remark2", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRemark3 && (
                <div className="jb-col col-2">
                  <label>Remark 3</label>
                  <input
                    type="text"
                    placeholder="Remark"
                    value={_HTA.Remark3}
                    onChange={(e) =>
                      Input_HTAOnchange("Remark3", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
              {_Common.InvColPer.strRemark4 && (
                <div className="jb-col col-2">
                  <label>Remark 4</label>
                  <input
                    type="text"
                    placeholder="Remark"
                    value={_HTA.Remark4}
                    onChange={(e) =>
                      Input_HTAOnchange("Remark4", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              )}
            </div>
            <div className="table-container">
              <div className="table-wrapper">
                <div className="tbl-content">
                  <ul className="tbl-manual">
                    <li className="tbl-tr hr">
                      <ul className="tbl-td">
                        {_Common.arrEnttableCol.map((col, Index) => (
                          <li
                            key={Index}
                            style={{
                              minWidth: col.colWidth + "px",
                              maxWidth: col.colWidth + "px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {col.aliasName}
                          </li>
                        ))}
                      </ul>
                    </li>
                    {_DT.arrEntDT.map((dt, trIndex) => (
                      <li key={trIndex} className="tbl-tr bd">
                        <ul className="tbl-td">
                          {_Common.arrEnttableCol.map((col, tdIndex) => (
                            <li
                              key={tdIndex}
                              style={{
                                minWidth: col.colWidth + "px",
                                maxWidth: col.colWidth + "px",
                                display: "flex",
                                justifyContent: col.textAlign,
                                alignItems: "center",
                              }}
                            >
                              {col.colName === "sNo" && (
                                <span style={{ textAlign: col.textAlign }}>
                                  {trIndex + 1}
                                </span>
                              )}
                              {col.colName === "taxType" && (
                                <div
                                  className="custom-select"
                                  onMouseLeave={(e) =>
                                    isShowProtaxtype(trIndex, false)
                                  }
                                >
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Select Tax Type"
                                    value={dt.strTaxType}
                                    onChange={(e) => {}}
                                    onClick={(e) =>
                                      isShowProtaxtype(trIndex, true)
                                    }
                                  />
                                  <i className="fa-solid fa-caret-down"></i>
                                  {dt.isTaxtype && (
                                    <div className="custom-option">
                                      <div className="option-list">
                                        {_Tax.arrTaxType.map(
                                          (value, dtIndex) => (
                                            <button
                                              key={dtIndex}
                                              tabIndex={dtIndex}
                                              onClick={(e) =>
                                                Product_TaxtypeOnchange(
                                                  value,
                                                  trIndex
                                                )
                                              }
                                            >
                                              {value.strTaxLvlType}
                                            </button>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              {col.colName === "taxPer" && dt.taxType === 1 && (
                                <div
                                  className="custom-select"
                                  onMouseLeave={(e) =>
                                    isShowProTax(trIndex, false)
                                  }
                                >
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Select GST Tax"
                                    value={"GST " + dt.taxPer + " %"}
                                    onChange={(e) => {}}
                                    onClick={(e) => isShowProTax(trIndex, true)}
                                  />
                                  <i className="fa-solid fa-caret-down"></i>
                                  {dt.isShowtax && (
                                    <div className="custom-option">
                                      <div className="option-list">
                                        {_Tax.arrIGST.map((tax, dtIndex) => (
                                          <button
                                            key={dtIndex}
                                            tabIndex={dtIndex}
                                            onClick={(e) =>
                                              Product_TaxOnchange(
                                                dt,
                                                tax,
                                                trIndex
                                              )
                                            }
                                          >
                                            {tax.strTaxDesc}
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              {col.colName === "taxPer" && dt.taxType !== 1 && (
                                <div
                                  className="custom-select"
                                  onMouseLeave={(e) =>
                                    isShowProTax(trIndex, false)
                                  }
                                >
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Select TCS Tax"
                                    value={"TCS " + dt.taxPer + " %"}
                                    onClick={(e) => isShowProTax(trIndex, true)}
                                    onChange={(e) => {}}
                                  />
                                  <i className="fa-solid fa-caret-down"></i>
                                  {dt.isShowtax && (
                                    <div className="custom-option">
                                      <div className="option-list">
                                        {_Tax.arrTcsTax.map((tax, dtIndex) => (
                                          <button
                                            key={dtIndex}
                                            tabIndex={dtIndex}
                                            onClick={(e) =>
                                              Product_TaxOnchange(
                                                dt,
                                                tax,
                                                trIndex
                                              )
                                            }
                                          >
                                            {tax.strTaxDesc}
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              {col.colName !== "sNo" &&
                                col.colName !== "taxPer" &&
                                col.colName !== "taxType" &&
                                !col.readOnly && (
                                  <input
                                    type={
                                      col.datatype === "decimal"
                                        ? "number"
                                        : col.datatype === "string"
                                        ? "text"
                                        : col.datatype
                                    }
                                    className="form-control"
                                    value={dt[col.colName]}
                                    onClick={(e) => e.target.select()}
                                    onChange={(e) =>
                                      table_inputonchange(
                                        e.target.value,
                                        col,
                                        trIndex
                                      )
                                    }
                                    onBlur={(e) => {
                                      if (
                                        _DT.isOnchange &&
                                        col.datatype === "decimal"
                                      )
                                        Calculate_Transaction(_DT, _HT);
                                    }}
                                  />
                                )}
                              {col.colName !== "sNo" &&
                                col.colName !== "taxPer" &&
                                col.colName !== "taxType" &&
                                col.readOnly && (
                                  <label
                                    style={{
                                      width: "100%",
                                      textAlign: col.textAlign,
                                    }}
                                  >
                                    {dt[col.colName]}
                                  </label>
                                )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="tbl-fr">
                  <input
                    type="text"
                    className="form-control"
                    value={strSearch}
                    onChange={(e) => Search_Product(e.target.value)}
                    placeholder="Type or Click here to select product"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        Add_NewProduct(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="jb-row">
              <div className="jb-col col-9">
                <div className="sum-content">
                  <div className="othercharges">
                    <h1>Other Charges</h1>
                    {_DT.arrEntAL.map((AL, Index) => (
                      <div key={Index} className="Otc-content">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Other Charges"
                          value={AL.serName}
                          onChange={(e) =>
                            Othercharges_Onchange(
                              e.target.value,
                              "serName",
                              Index
                            )
                          }
                        />
                        <input
                          type="number"
                          className="form-control"
                          style={{ maxWidth: "100px" }}
                          value={AL.rateWithoutTax}
                          onClick={(e) => e.target.select()}
                          onChange={(e) =>
                            Othercharges_Onchange(
                              e.target.value,
                              "rateWithoutTax",
                              Index
                            )
                          }
                          onBlur={(e) => {
                            if (_DT.isALOnchange)
                              Calculate_Transaction(_DT, _HT);
                          }}
                        />
                        <input
                          type="number"
                          className="form-control"
                          style={{ maxWidth: "100px" }}
                          value={AL.rateWithTax}
                          onClick={(e) => e.target.select()}
                          onChange={(e) =>
                            Othercharges_Onchange(
                              e.target.value,
                              "rateWithTax",
                              Index
                            )
                          }
                          onBlur={(e) => {
                            if (_DT.isALOnchange)
                              Calculate_Transaction(_DT, _HT);
                          }}
                        />
                        <input
                          type="number"
                          className="form-control"
                          style={{ maxWidth: "100px" }}
                          value={AL.taxAmt}
                          readOnly
                        />
                        <div
                          className="custom-select"
                          onMouseLeave={(e) => isShowSertaxtype(Index, false)}
                        >
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Select Tax Type"
                            value={AL.strTaxType}
                            onChange={(e) => {}}
                            onClick={(e) => isShowSertaxtype(Index, true)}
                          />
                          <i className="fa-solid fa-caret-down"></i>
                          {AL.isTaxtype && (
                            <div className="custom-option">
                              <div className="option-list">
                                {_Tax.arrTaxType.map((value, dtIndex) => (
                                  <button
                                    key={dtIndex}
                                    tabIndex={dtIndex}
                                    onClick={(e) =>
                                      Service_TaxtypeOnchange(value, Index)
                                    }
                                  >
                                    {value.strTaxLvlType}
                                  </button>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        {AL.taxType === 1 && (
                          <div
                            className="custom-select"
                            onMouseLeave={(e) => isShowSerTax(Index, false)}
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Select GST Tax"
                              value={"GST " + AL.taxPer + " %"}
                              onChange={(e) => {}}
                              onClick={(e) => isShowSerTax(Index, true)}
                            />
                            <i className="fa-solid fa-caret-down"></i>
                            {AL.isShowtax && (
                              <div className="custom-option">
                                <div className="option-list">
                                  {_Tax.arrIGST.map((tax, dtIndex) => (
                                    <button
                                      key={dtIndex}
                                      tabIndex={dtIndex}
                                      onClick={(e) =>
                                        Service_TaxOnchange(AL, tax, Index)
                                      }
                                    >
                                      {tax.strTaxDesc}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {AL.taxType !== 1 && (
                          <div
                            className="custom-select"
                            onMouseLeave={(e) => isShowSerTax(Index, false)}
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Select TCS Tax"
                              value={"TCS " + AL.taxPer + " %"}
                              onClick={(e) => isShowSerTax(Index, true)}
                              onChange={(e) => {}}
                            />
                            <i className="fa-solid fa-caret-down"></i>
                            {AL.isShowtax && (
                              <div className="custom-option">
                                <div className="option-list">
                                  {_Tax.arrTcsTax.map((tax, dtIndex) => (
                                    <button
                                      key={dtIndex}
                                      tabIndex={dtIndex}
                                      onClick={(e) =>
                                        Service_TaxOnchange(AL, tax, Index)
                                      }
                                    >
                                      {tax.strTaxDesc}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        <span onClick={(e) => Remove_OtherCharges(Index)}>
                          <i className="bx bx-trash-alt"></i>
                        </span>
                      </div>
                    ))}
                    <div className="more-info">
                      <span onClick={(e) => Add_OtherCharges()}>
                        <i className="bx bx-plus-circle"></i>Add New Row
                      </span>
                    </div>
                  </div>
                  <div className="bill-disc">
                    <h1>Discount</h1>
                    <div className="disc-container">
                      <ul className="manul-tax">
                        <li className="hr">
                          <span>Tax</span>
                          <span>Taxable Amt</span>
                          <span>Tax %</span>
                          <span>Tax Amt</span>
                        </li>
                        {_DT.arrEntTax.map((tax, Index) => (
                          <li key={Index} className="bd">
                            <span>{tax.taxDesc}</span>
                            <span>{tax.taxableAmt}</span>
                            <span>{tax.taxPer}</span>
                            <span>{tax.taxAmt}</span>
                          </li>
                        ))}
                      </ul>
                      <div className="jb-col2">
                        <label>Bill Disc %</label>
                        <input
                          type="number"
                          className="form-control"
                          value={_HT.BillDiscPer}
                          onClick={(e) => e.target.select()}
                          onChange={(e) =>
                            Discount_Onchange("BillDiscPer", e.target.value)
                          }
                          onBlur={(e) => {
                            if (_HT.isOnchange) Calculate_Transaction(_DT, _HT);
                          }}
                        />
                      </div>
                      <div className="jb-col2">
                        <label>Bill Disc Rs.</label>
                        <input
                          type="number"
                          className="form-control"
                          value={_HT.BillDiscAmt}
                          onClick={(e) => e.target.select()}
                          onChange={(e) =>
                            Discount_Onchange("BillDiscAmt", e.target.value)
                          }
                          onBlur={(e) => {
                            if (_HT.isOnchange) Calculate_Transaction(_DT, _HT);
                          }}
                        />
                      </div>
                      <div className="jb-col2">
                        <label>Bill Disc</label>
                        <input
                          type="number"
                          className="form-control"
                          value={_HT.BillDiscVal}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="jb-col col-3">
                <div className="total-content">
                  <h1>Summary</h1>
                  <p>
                    <span>Goods Value</span>
                    <strong>
                      <sub>Rs.</sub>
                      {_HT.GoodsValue}
                    </strong>
                  </p>
                  <p>
                    <span>Qty</span>
                    <strong>{_HT.Qty}</strong>
                  </p>
                  <p>
                    <span>Tax Amount</span>
                    <strong>
                      <sub>Rs.</sub>
                      {_HT.TaxAmt}
                    </strong>
                  </p>
                  <p>
                    <span>Discount</span>
                    <strong>
                      <sub>Rs.</sub>
                      {_HT.DiscAmt}
                    </strong>
                  </p>
                  <p>
                    <span>Other Charges</span>
                    <strong>
                      <sub>Rs.</sub>
                      {_HT.ALAmt}
                    </strong>
                  </p>
                  <p>
                    <span>Round Off</span>
                    <strong>
                      <sub>Rs.</sub>
                      {_HT.RoundOff}
                    </strong>
                  </p>
                  <h2 className="sum-total">
                    <strong>Total</strong>
                    <strong>
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "INR",
                      }).format(Number(_HT.NetAmt).toFixed(2))}
                    </strong>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-inv-group">
            <button className="btn-jb" onClick={(e) => Clear_Invoice()}>
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button className="btn-jb">
              Delete&nbsp; <i className="bx bx-trash-alt"></i>
            </button>
            <div
              className="btn-popup"
              onMouseLeave={(e) => $(".share-popup").toggleClass("active")}
            >
              <div className="share-popup">
                <ul>
                  <li
                    onClick={(e) => {
                      $(".share-popup").toggleClass("active");
                      if (_HT.Customer === "")
                        Show_Message(
                          dispatch,
                          "Please Enter Customer Name",
                          "info"
                        );
                      else if (Number(_HT.BT_Id) === 0)
                        Show_Message(
                          dispatch,
                          "Please Select Bill Type",
                          "info"
                        );
                      else {
                        setstrSavefor("Save");
                        Ask_Question(
                          dispatch,
                          InsUpd_ManualInvoice,
                          "Do You Want to Save Invoice"
                        );
                      }
                    }}
                  >
                    Save Invoice &nbsp;<i className="bx bxs-save"></i>
                  </li>
                  <li
                    onClick={(e) => {
                      $(".share-popup").toggleClass("active");
                      if (_HT.Customer === "")
                        Show_Message(
                          dispatch,
                          "Please Enter Customer Name",
                          "info"
                        );
                      else {
                        setstrSavefor("Print");
                        Ask_Question(
                          dispatch,
                          InsUpd_ManualInvoice,
                          "Do You Want to Save and Print Invoice"
                        );
                      }
                    }}
                  >
                    Print Invoice &nbsp;<i className="bx bx-printer"></i>
                  </li>
                  <li
                    onClick={async (e) => {
                      $(".share-popup").toggleClass("active");
                      if (_HT.Customer === "")
                        Show_Message(
                          dispatch,
                          "Please Enter Customer Name",
                          "info"
                        );
                      else {
                        setstrSavefor("PDF");
                        await Ask_Question(
                          dispatch,
                          InsUpd_ManualInvoice,
                          "Do You Want to Save and Download Invoice"
                        );
                      }
                    }}
                  >
                    Save as PDF &nbsp;<i className="bx bxs-file-pdf"></i>
                  </li>
                  <li
                    onClick={(e) => {
                      $(".share-popup").toggleClass("active");
                      if (_HT.Customer === "")
                        Show_Message(
                          dispatch,
                          "Please Enter Customer Name",
                          "info"
                        );
                      else {
                        setstrSavefor("Share");
                        Ask_Question(
                          dispatch,
                          InsUpd_ManualInvoice,
                          "Do You Want to Save and Share Invoice"
                        );
                      }
                    }}
                  >
                    Share to Email &nbsp;<i className="bx bx-envelope"></i>
                  </li>
                </ul>
              </div>
              <button
                className="btn-jb btn-i"
                onClick={(e) => $(".share-popup").toggleClass("active")}
              >
                Save and Share
                <span>
                  <i className="bx bx-caret-down"></i>
                </span>
              </button>
            </div>
            <button className="btn-jb" onClick={(e) => setisView(!isView)}>
              View&nbsp; <i className="fas fa-eye"></i>
            </button>
            <button
              className="btn-jb"
              onClick={(e) => {
                if (Number(_HT.InvId) === 0)
                  Show_Message(dispatch, "Please Select Invoice", "info");
                else
                  Ask_Question(
                    dispatch,
                    Delete_ManualInvoice,
                    "Do You Want to Delete Invoice"
                  );
              }}
            >
              Delete&nbsp; <i className="bx bx-trash-alt"></i>
            </button>
            <button
              className="btn-jb"
              onClick={(e) => (window.location.href = "/JustBill/DashBoard")}
            >
              Close&nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
          {isCustomer && (
            <CustomerInfo
              Close={(e) => setisCustomer(!isCustomer)}
              isBillAdd={isBillAdd}
            />
          )}
          {isVouchNoSett && (
            <VouchernoSetting
              Close={(e) => setisVouchNoSett(!isVouchNoSett)}
              iScrId={100}
            />
          )}
          {isView && <ManualInvoiceView Close={(e) => setisView(!isView)} />}
          {isReceipt && (
            <ManualReceipt
              Close={(e) => Close_Receipt()}
              PopupClose={(e) => setisReceipt(!isReceipt)}
            />
          )}
          <Messagedialogbox />
        </div>
      </div>
    </>
  );
}

export default ManualInvoice;
