import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_POST } from "../General/Utility";
import { Show_Message } from "../General/Messagedialogbox";
import PopupSearch from "../Transaction/PopupSearch";
function SearchLedger(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strSearch, setstrSearch] = useState("");
  const [arrEntLedger, setarrEntLedger] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_Ledger();
  }, []);
  const Load_Ledger = async () => {
    try {
      const objSearch = {
        strSearch: "",
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `ServiceMaster/Load_Ledger/`,
        objSearch,
        dispatch
      );
      if (res) {
        setarrEntLedger(res.EntLedger);
        setarrEntTableCol(res.EntTablDesign);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Search_Ledger = async (strtext) => {
    try {
      setstrSearch(strtext);
      const objSearch = {
        strSearch: strtext,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `ServiceMaster/Search_Ledger/`,
        objSearch,
        dispatch
      );
      if (res) {
        setarrEntLedger(res.EntLedger);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };

  return (
    <>
      <PopupSearch
        ScrName="Ledger Master"
        FilterCol={[]}
        FilterVal={""}
        FilterShow={false}
        strSearch={strSearch}
        Columns={arrEntTableCol}
        Data={arrEntLedger}
        UpdateFilterCol={(e) => {}}
        Search_Data={(e) => Search_Ledger(e)}
        View_Data={(e) => props.View_Ledger(e)}
        Close={() => props.Close()}
        IsParty={false}
      />
    </>
  );
}
export default SearchLedger;
