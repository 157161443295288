import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import $ from "jquery";
import {
  Apply_CouponDiscount,
  Apply_RewardDiscount,
  Update_DiscountCalculation,
} from "../features/Transactionstates";
import { API_POST } from "../General/Utility";

function Discount(props) {
  const _AuthParem = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const _Disc = useSelector((state) => state.Transstates.Discount);
  const _Customer = useSelector((state) => state.Transstates.Customer);

  const dispatch = useDispatch();
  const [dcustomeroyaltypoint, setdcustomeroyaltypoint] = useState("");
  const [dBillDisc, setdBillDisc] = useState((0).toFixed(2));
  const [isBillDiscPer, setisBillDiscPer] = useState(true);
  const [strCouponCode, setstrCouponCode] = useState("");

  useEffect(() => {
    setstrCouponCode(_Disc.strCouponCode);
    setdBillDisc(_Disc.dBillDisPer + _Disc.dBillDisAmt);
    setisBillDiscPer(_Disc.dBillDisPer > _Disc.dBillDisAmt ? true : false);
    short_cut_keypress();
  }, []);

  const Apply_Couponclick = async (clear) => {
    try {
      let objDisc = {
        iCouponId: 0,
        strCouponCode: "",
        dCouponDiscPer: 0,
        dCouponDiscAmt: 0,
      };
      let IsCalCulate = false;
      if (clear) {
        IsCalCulate = true;
        setstrCouponCode("");
      } else {
        let EntCoupon = _Common.EntCoupon.filter(
          (dt) => dt.strCouponCode === strCouponCode
        );
        if (EntCoupon.length > 0) {
          EntCoupon = EntCoupon[0];
          var valid_from = Date.parse(EntCoupon.dpStartDate);
          var valid_to = Date.parse(EntCoupon.dpExpiryDate);
          var curr_date = Date.now();
          var datetime = new Date(EntCoupon.dpStartDate)
            .toLocaleString()
            .split("/")
            .join("-");
          if (valid_to < curr_date) {
            Show_Message(dispatch, "Coupon is Expired", "info");
          } else if (valid_from > curr_date) {
            Show_Message(
              dispatch,
              `Coupon Only Valid From ${datetime}`,
              "info"
            );
          } else {
            IsCalCulate = true;
            objDisc.iCouponId = EntCoupon.iCouponId;
            objDisc.strCouponCode = strCouponCode;
            objDisc.dCouponDiscPer = EntCoupon.dDiscPer;
            objDisc.dCouponDiscAmt = EntCoupon.dDiscAmt;
          }
        } else {
          Show_Message(
            dispatch,
            strCouponCode === ""
              ? "Please Enter Coupon Code"
              : "Invalid Coupon Code",
            "error"
          );
        }
      }
      const Disc =
        objDisc.dCouponDiscPer > objDisc.dCouponDiscAmt
          ? "BillDicPer"
          : "BillDicAmt";
      if (IsCalCulate) {
        const objParem = {
          Disc: Disc,
          iScrId: _Common.iScrId,
          iTaxRelId: _HT.iTaxRelId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iALBillDiscId: _Common.iALBillDiscId,
          iPartyId: _HT.iPartyId,
          iYearId: Number(_AuthParem.Year),
          iBranchId: Number(_AuthParem.Branch),
          strDBName: _AuthParem.DB,
          iUserId: Number(_AuthParem.User),
          objBaseTransDT: _DT.arrEntDT,
          objEntAddLess: _DT.arrEntAL,
          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dTBillDiscValue: Number(_Disc.dBillDisValue),
          dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
          dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
          dAddLessDiscValue: Number(_Disc.dALBillDisValue),
          dCouponDiscPer: Number(objDisc.dCouponDiscPer),
          dRewardPointper: Number(_Disc.dRewardPointper),
          dCouponDiscAmt: Number(objDisc.dCouponDiscAmt),
          dRewardPointAmt: Number(_Disc.dRewardPointAmt),
        };
        const res = await API_POST(
          `Transaction/CalculateDiscount`,
          objParem,
          dispatch
        );
        if (res) {
          dispatch(
            Apply_CouponDiscount({
              iCouponId: objDisc.iCouponId,
              strCouponCode: strCouponCode,
              dCouponDiscPer: objDisc.dCouponDiscPer,
              dCouponDiscAmt: objDisc.dCouponDiscAmt,
            })
          );
          dispatch(Update_DiscountCalculation({ Data: res, Disc: Disc }));
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Redeem_rewadPoint = async (clear) => {
    try {
      let objDisc = {
        dRewardPointper: 0,
        dRewardPointAmt: 0,
      };
      const Disc = _Customer.RewardType === "Per" ? "BillDicPer" : "BillDicAmt";
      let IsCalCulate = false;
      if (clear) {
        setdcustomeroyaltypoint("");
        IsCalCulate = true;
      } else {
        if (Number(dcustomeroyaltypoint) > Number(_Customer.RoyaltyPoint)) {
          Show_Message(
            dispatch,
            `Credit Point ${Number(
              _Customer.RoyaltyPoint
            )} only Available Not Enough to Redeem`,
            "info"
          );
        } else if (Number(dcustomeroyaltypoint) === 0) {
          Show_Message(dispatch, "Please Enter Valid Points", "info");
        } else {
          IsCalCulate = true;
          objDisc.dRewardPointper =
            _Customer.RewardType === "Per" ? Number(dcustomeroyaltypoint) : 0;
          objDisc.dRewardPointAmt =
            _Customer.RewardType === "Amt" ? Number(dcustomeroyaltypoint) : 0;
        }
      }
      if (IsCalCulate) {
        const objParem = {
          Disc: Disc,
          iScrId: _Common.iScrId,
          iTaxRelId: _HT.iTaxRelId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iALBillDiscId: _Common.iALBillDiscId,
          iPartyId: _HT.iPartyId,
          iYearId: Number(_AuthParem.Year),
          iBranchId: Number(_AuthParem.Branch),
          strDBName: _AuthParem.DB,
          iUserId: Number(_AuthParem.User),
          objBaseTransDT: _DT.arrEntDT,
          objEntAddLess: _DT.arrEntAL,
          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dTBillDiscValue: Number(_Disc.dBillDisValue),
          dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
          dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
          dAddLessDiscValue: Number(_Disc.dALBillDisValue),
          dCouponDiscPer: Number(_Disc.dCouponDiscPer),
          dRewardPointper: Number(objDisc.dRewardPointper),
          dCouponDiscAmt: Number(_Disc.dCouponDiscAmt),
          dRewardPointAmt: Number(objDisc.dRewardPointAmt),
        };
        const res = await API_POST(
          `Transaction/CalculateDiscount`,
          objParem,
          dispatch
        );
        if (res) {
          dispatch(
            Apply_RewardDiscount({
              dRewardPointper: objDisc.dRewardPointper,
              dRewardPointAmt: objDisc.dRewardPointAmt,
            })
          );
          dispatch(Update_DiscountCalculation({ Data: res, Disc: Disc }));
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Add_BillDiscount = async (clear) => {
    try {
      const Disc = isBillDiscPer ? "BillDicPer" : "BillDicAmt";
      let BillDisc = 0;
      if (clear) setdBillDisc(0);
      else {
        BillDisc = Number(dBillDisc);
      }
      const objParem = {
        Disc: Disc,
        iScrId: _Common.iScrId,
        iTaxRelId: _HT.iTaxRelId,
        iTaxCalcOnId: _Common.iTaxCalcOnId,
        iALBillDiscId: _Common.iALBillDiscId,
        iPartyId: _HT.iPartyId,
        iYearId: Number(_AuthParem.Year),
        iBranchId: Number(_AuthParem.Branch),
        strDBName: _AuthParem.DB,
        iUserId: Number(_AuthParem.User),
        objBaseTransDT: _DT.arrEntDT,
        objEntAddLess: _DT.arrEntAL,
        dTBillDiscPer: Number(isBillDiscPer ? Number(BillDisc) : 0),
        dTBillDiscAmt: Number(!isBillDiscPer ? Number(BillDisc) : 0),
        dTBillDiscValue: Number(_Disc.dBillDisValue),
        dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
        dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
        dAddLessDiscValue: Number(_Disc.dALBillDisValue),
        dCouponDiscPer: Number(_Disc.dCouponDiscPer),
        dRewardPointper: Number(_Disc.dRewardPointper),
        dCouponDiscAmt: Number(_Disc.dCouponDiscAmt),
        dRewardPointAmt: Number(_Disc.dRewardPointAmt),
      };
      const res = await API_POST(
        `Transaction/CalculateDiscount`,
        objParem,
        dispatch
      );
      if (res) {
        dispatch(Update_DiscountCalculation({ Data: res, Disc: Disc }));
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        props.Close();
      }
    });
  };

  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Discount</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pb-2">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <label>JB Points</label>
              <input
                type="number"
                value={dcustomeroyaltypoint}
                onChange={(e) => setdcustomeroyaltypoint(e.target.value)}
                className="form-control"
                placeholder="Enter Royalty Point"
                onClick={(e) => e.target.select()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") Redeem_rewadPoint(false);
                }}
                autoComplete="off"
                disabled={Number(_Customer.RoyaltyPoint) > 0 ? false : true}
              />
              <label>
                Available Reward Point : {Number(_Customer.RoyaltyPoint)}
              </label>
            </div>
            <div className="popup-btn-group mt-1">
              <button
                className="btn-fabgreen"
                onClick={(e) => Redeem_rewadPoint(false)}
                disabled={Number(_Customer.RoyaltyPoint) > 0 ? false : true}
              >
                Add Reward Dis&nbsp;<i className="bx bxs-discount"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => Redeem_rewadPoint(true)}
              >
                Clear Disc&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Coupon Code</label>
              <input
                value={strCouponCode}
                onChange={(e) => setstrCouponCode(e.target.value)}
                className="form-control"
                placeholder="Enter Coupon Code"
                onClick={(e) => e.target.select()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") Apply_Couponclick(false);
                }}
                autoComplete="off"
              />
              <label style={{ fontSize: "12px" }}>
                Coupon Disc :
                {_Disc.dCouponDiscPer < _Disc.dCouponDiscAmt && (
                  <i className="fa-solid fa-indian-rupee-sign">
                    {_Disc.dCouponDiscAmt}
                  </i>
                )}
                {_Disc.dCouponDiscPer > _Disc.dCouponDiscAmt
                  ? " " + _Disc.dCouponDiscPer + " %"
                  : ""}
              </label>
            </div>
            <div className="popup-btn-group mt-1">
              <button
                className="btn-fabgreen"
                onClick={(e) => Apply_Couponclick(false)}
              >
                Add Coupon Disc&nbsp;<i className="bx bxs-discount"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => Apply_Couponclick(true)}
              >
                Clear Disc&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Bill Discount</label>
              <div className="trancdisc">
                <input
                  type="number"
                  className="form-control"
                  name="dItemDisc"
                  id="txtproItemDis"
                  value={dBillDisc}
                  autoComplete="off"
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdBillDisc(e.target.value)}
                />
                <button
                  className="btn-fabgreen active"
                  onClick={(e) => {
                    $(".trancdisc .btn-fabgreen:eq(0)").toggleClass("active");
                    setisBillDiscPer(!isBillDiscPer);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                      $(".trancdisc .btn-fabgreen:eq(0)").toggleClass("active");
                      setisBillDiscPer(!isBillDiscPer);
                    }
                  }}
                >
                  {isBillDiscPer ? "%" : "₹"}
                </button>
              </div>
            </div>
            <div className="popup-btn-group mt-1">
              <button
                className="btn-fabgreen"
                onClick={(e) => Add_BillDiscount(false)}
              >
                Add Bill Dis&nbsp;<i className="bx bxs-discount"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => Add_BillDiscount(true)}
              >
                Clear Disc&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discount;
