import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import PartyCreation from "../Transaction/PartyCreation";
import ServiceSearch from "../Transaction/ServiceSearch";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import CustomTransView from "../Transaction/CustomTransView";
import Messagedialogbox from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
import PartySearch from "../Transaction/PartySearch";
import {
  CommonInput_Onchange,
  Input_Onchange,
  loadexpenseincome,
  ShowHide_Popup,
} from "../features/Transactionstates";
import { KeyUpDown, Validate_Properties } from "../Transaction/Transaction";
function ExpenseIncome() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const _tabOrder = JSON.parse(JSON.stringify(_Common.arrtaborder));
  const _select = useSelector((state) => state.SelectOption.parems);
  //#region  Parems
  const [iScrId, setiScrId] = useState(66);
  const [iPartyId, setiPartyId] = useState(0);
  const [iEIId, setiEIId] = useState(0);
  const [strPartyName, setstrPartyName] = useState("");
  const [strPartyAdd, setstrPartyAdd] = useState("");
  const [strVouchNo, setstrVouchNo] = useState("");
  const [strVouchDate, setstrVouchDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strTransNo, setstrTransNo] = useState("");
  const [strRemark1, setstrRemark1] = useState("");
  const [strRemark2, setstrRemark2] = useState("");
  const [strRemark3, setstrRemark3] = useState("");
  const [strRemark4, setstrRemark4] = useState("");
  const [iEITypeId, setiEITypeId] = useState(1);
  const [iVenTypeId, setiVenTypeId] = useState(2);
  const [iTaxCalcOnId, setiTaxCalcOnId] = useState(0);
  const [iTaxRelId, setiTaxRelId] = useState(0);
  const [iBillTypeId, setiBillTypeId] = useState(0);

  const [iSerId, setiSerId] = useState(0);
  const [strSerName, setstrSerName] = useState("");
  const [dPriceRate, setdPriceRate] = useState(0);
  const [isDiscPer, setisDiscPer] = useState(true);
  const [dItemDisc, setdItemDisc] = useState(0);
  const [dItemDiscPer, setdItemDiscPer] = useState(0);
  const [dItemDiscAmt, setdItemDiscAmt] = useState(0);
  const [strNetAmt, setstrNetAmt] = useState("");
  const [dBillDisPer, setdBillDisPer] = useState(0);
  const [dBillDisAmt, setdBillDisAmt] = useState(0);
  const [dBillDisValue, setdBillDisValue] = useState(0);
  const [iGoodsValue, setiGoodsValue] = useState(0);
  const [iTotalDisc, setiTotalDisc] = useState(0);
  const [iTotalTax, setiTotalTax] = useState(0);
  const [iRountoff, setiRountoff] = useState(0);
  const [iRowIndex, setiRowIndex] = useState(0);

  const [strSetCurrentStatus, setstrSetCurrentStatus] = useState("");
  const [bUpdateStatus, setbUpdateStatus] = useState(false);
  const [isService, setisService] = useState(false);
  const [strSearch, setstrSearch] = useState("");

  const [selected, setselected] = useState(0);
  const [EIListDT, setEIListDT] = useState([]);
  const [EIListTax, setEIListTax] = useState([]);
  const [EIListSummary, setEIListSummary] = useState([]);
  const [EIItemRemark, setEIItemRemark] = useState([]);
  const [EIRootCalc, setEIRootCalc] = useState([]);
  const [NoFormat, setNoFormat] = useState([]);
  const [arrParty, setarrParty] = useState([]);
  const [ListTabOrder, setListTabOrder] = useState({
    txtPartyName: "",
    txtBillAddress: "",
    cbBillType: "",
    cbTaxType: "",
    txtTransNo: "",
    txtEINo: "",
    dpEIDate: "",

    txtBillDiscPer: "",
    txtBillDiscAmt: "",
    txtBillDiscValue: "",

    btnNew: "",
    btnModify: "",
    btnSave: "",
    btnClear: "",
    btnView: "",
    btnExportImport: "",
    btnPrint: "",
    btnCancel: "",
    btnClose: "",
    btnPrintSettings: "",
    btnHelp: "",
    txtRemark1: "",
    txtRemark2: "",
    txtRemark3: "",
    txtRemark4: "",
    btnRefresh: "",

    TabParty: "",
    TabVoucher: "",
    TabRemarks: "",
    TabSummary: "",
    TabTax: "",
    TabDiscount: "",
    TabName: "Party",

    ServiceListTab: "",
    txtServiceName: "",
    txtRate: "",
    txtDiscount: "",
    btnAdd: "",
    isDiscPer: "",
  });

  const [isTransView, setisTransView] = useState(false);
  const [isViewBtn, setisViewBtn] = useState(false);
  const [isSaveBtn, setisSaveBtn] = useState(false);
  const [isDeleteBtn, setisDeleteBtn] = useState(false);

  //#endregion
  useEffect(() => {
    Load_ExponeIncome();
    ButtonPermission();
  }, []);

  const Load_ExponeIncome = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `ExpenseIncome/Load_ExpensseIncome/${Number(_LocalParems.Branch)}/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        dispatch(loadexpenseincome(res));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Party = async (iPartyId) => {
    try {
      await Pre_Loading(dispatch, true);
      const parems = {
        PartyId: iPartyId,
        PartyScrId: 3,
        VenType: iVenTypeId,
        BranchId: Number(_AuthParems.Branch),
        CompanyDB: _AuthParems.DB,
      };
      setiPartyId(iPartyId);
      const res = await API_POST(`PartyTrans/View_Party/`, parems, dispatch);
      if (res) {
        setstrPartyAdd(res.partyaddress);
        setstrPartyName(res.partyInfo.partyName);
        setiTaxRelId(res.partyInfo.taxRelId);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Add_Service = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        "ExpenseIncome/Add_Service/",
        {
          bUpdateStatus: bUpdateStatus,
          iRowIndex: iRowIndex,
          iScrId: iScrId,
          iTaxRelId: iTaxRelId,
          iPartyId: iPartyId,
          iTaxCalcOnId: iTaxCalcOnId,
          iSerId: Number(iSerId),
          dPriceRate: Number(dPriceRate),
          dDiscountPer: isDiscPer ? Number(dItemDisc) : 0,
          dDiscountAmt: !isDiscPer ? Number(dItemDisc) : 0,
          objBaseTransDT: EIListDT,
          objEntAddLess: [],
          objEntConRow: [],
          objEntTax: [],
          objItemRemark: EIItemRemark[0],
          objPenCon: [],
          objRootCalc: EIRootCalc[0],
          strColumn: "",
          strJobId: "",
          strProAttDTId: "",
          strReason: "",
          strSearch: "",
          strSetCurrentStatus: strSetCurrentStatus,
          strStoAttDTId: "",
          strTraAttDTId: "",
          iYearId: Number(_AuthParems.Year),
          strDBName: _AuthParems.DB,
          iRoleId: Number(_AuthParems.User),
          iBranchId: Number(_AuthParems.Branch),
        },
        dispatch
      );
      if (res) {
        setEIListDT(res.objEntDT);
        setEIListTax(res.objEntTax);
        setEIListSummary(res.objEntSummary);

        let Summary = res.objEntSummary[0];
        setiGoodsValue(Summary.goodsValue);
        setiTotalTax(Summary.totalTax);
        setiRountoff(Summary.roundOff);
        setiTotalDisc(Summary.totalDiscount);
        setstrNetAmt(
          new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "INR",
          }).format(Summary.netAmt)
        );
        Clear_Service();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Service = (service) => {
    setiSerId(Number(service.iId));
    setstrSerName(service.strName);
    setisService(!isService);
  };
  const Clear_Service = () => {
    setiSerId(0);
    setstrSerName("");
    setdItemDiscPer(0);
    setdItemDisc(0);
    setdItemDiscAmt(0);
    setdPriceRate(0);
    setbUpdateStatus(false);
    setisDiscPer(true);
  };
  const AssignSelectedService = (SerInfo, Index) => {
    setiRowIndex(Index);
    setbUpdateStatus(true);
    setiSerId(SerInfo.SerId);
    setstrSerName(SerInfo.SerName);
    setdPriceRate(SerInfo.Rate);
    setdItemDisc(
      SerInfo.ItemDiscPer > SerInfo.ItemDiscAmt
        ? SerInfo.ItemDiscPer
        : SerInfo.ItemDiscAmt
    );
    setisDiscPer(SerInfo.ItemDiscPer > SerInfo.ItemDiscAmt ? true : false);
  };
  const DeleteService = async (iIndex) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        "ExpenseIncome/DeleteService/",
        {
          iRowIndex: iIndex,
          iScrId: iScrId,
          iTaxRelId: iTaxRelId,
          iPartyId: iPartyId,
          iTaxCalcOnId: iTaxCalcOnId,
          iSerId: iSerId,
          dPriceRate: Number(dPriceRate),
          dDiscountPer: Number(dItemDiscPer),
          dDiscountAmt: Number(dItemDiscAmt),
          objBaseTransDT: EIListDT,
          objEntAddLess: [],
          objEntConRow: [],
          objEntTax: [],
          objItemRemark: EIItemRemark[0],
          objPenCon: [],
          objRootCalc: EIRootCalc[0],
          strColumn: "",
          strJobId: "",
          strProAttDTId: "",
          strReason: "",
          strSearch: "",
          strSetCurrentStatus: strSetCurrentStatus,
          strStoAttDTId: "",
          strTraAttDTId: "",
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        setEIListDT(res.objEIDT);
        setEIListTax(res.objTaxDT);
        setEIListSummary(res.objEISummary);
        setstrNetAmt(
          new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "INR",
          }).format(res.objEISummary[0].netAmt)
        );
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ViewClick = async (iVouchId) => {
    try {
      await Pre_Loading(dispatch, true);
      setiEIId(iVouchId);
      const res = await API_FETCH(
        `ExpenseIncome/View_Voucher/${iVouchId}/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        setstrPartyName(res.objPartyList[0]);
        setstrPartyAdd(res.objPartyList[1]);
        setiPartyId(res.objEntHT[0].iPartyId);
        setNoFormat([
          {
            iNumSeqNo: res.objEntHT[0].iNumSeqNo,
            strPrefix: res.objEntHT[0].strPrefix,
            strCompCode: res.objEntHT[0].strCompCode,
            strFinanFrom: res.objEntHT[0].strFinanFrom,
            strTransNo: res.objEntHT[0].strTransNo,
          },
        ]);
        setstrVouchNo(res.objEntHT[0].strVouchNo);
        setstrVouchDate(res.objEntHT[0].strVouchDate);
        setiBillTypeId(res.objEntHT[0].iBillTypeId);
        setiTaxCalcOnId(res.objEntHT[0].iTaxCalcOnId);
        setiTaxRelId(res.objEntHT[0].iTaxTypeId);
        setstrRemark1(res.objEntHT[0].strRemark1);
        setstrRemark2(res.objEntHT[0].strRemark2);
        setstrRemark3(res.objEntHT[0].strRemark3);
        setstrRemark4(res.objEntHT[0].strRemark4);
        setdBillDisPer(res.objEntHT[0].dTBillDiscPer);
        setdBillDisAmt(res.objEntHT[0].dTBillDiscAmt);
        setdBillDisValue(res.objEntHT[0].dTBillDiscValue);
        let objEIDT = res.objEntDT;
        for (var i = 0; i < objEIDT.length; i++) {
          var Keys = Object.keys(objEIDT[i]);
          for (var D = 1; D < Keys.length; D++) {
            objEIDT[i][Keys[D]] =
              objEIDT[i][Keys[D]] === null ? "" : objEIDT[i][Keys[D]];
          }
        }
        setEIListDT(objEIDT);
        setEIListTax(res.objEntTax);
        let Summary = res.objEntSummary[0];
        setiGoodsValue(Summary.goodsValue);
        setiTotalTax(Summary.totalTax);
        setiRountoff(Summary.roundOff);
        setiTotalDisc(Summary.totalDiscount);
        setEIListSummary(res.objEntSummary);
        setstrNetAmt(
          new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "INR",
          }).format(Summary.netAmt)
        );
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      if (iEIId === 0) {
        Show_Message(dispatch, "Please Select Voucher to Delete!", "error");
      } else {
        const res = await API_FETCH(
          `ExpenseIncome/Delete_ExpenseIncome/${iEIId}/${Number(
            _AuthParems.Branch
          )}/${_AuthParems.DB}`,
          dispatch
        );
        if (res) {
          ClearClick();
          Show_Message(dispatch, res, "success");
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const SaveClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      if (iBillTypeId === 0) {
        Show_Message(dispatch, "Bill Type is Required", "error");
      } else if (EIListDT.length === 0) {
        Show_Message(
          dispatch,
          "Atleast One Item is Required per Bill",
          "error"
        );
      } else {
        const EIListHT = [
          {
            iEIId: iEIId,
            iBranchId: Number(_AuthParems.Branch),
            iTitleTag: iScrId,
            iPartyId: iPartyId,
            iNumSeqNo: NoFormat[0].iNumSeqNo,
            strPrefix: NoFormat[0].strPrefix,
            strCompCode: NoFormat[0].strCompCode,
            strFinanFrom: NoFormat[0].strFinanFrom,
            strTransNo: NoFormat[0].strTransNo,
            strVouchNo: strVouchNo,
            strVouchDate: strVouchDate,
            iBillTypeId: iBillTypeId,
            iTaxCalcOnId: iTaxCalcOnId,
            iTaxTypeId: iTaxRelId,
            iVenTypeId: iVenTypeId,
            iEITypeId: iEITypeId,
            strRemark1: strRemark1,
            strRemark2: strRemark2,
            strRemark3: strRemark3,
            strRemark4: strRemark4,
            dTBillDiscPer: Number(dBillDisPer),
            dTBillDiscAmt: Number(dBillDisAmt),
            dTBillDiscValue: Number(dBillDisValue),
            dTotGoodval: Number(EIListSummary[0].goodsValue),
            dTotDiscount: Number(EIListSummary[0].totalDiscount),
            dTotTax: Number(EIListSummary[0].totalTax),
            dRoundOff: Number(EIListSummary[0].roundOff),
            dNetAmt: Number(EIListSummary[0].netAmt),
          },
        ];
        const res = await API_POST(
          "ExpenseIncome/InsUpd_ExpenseIncome/",
          {
            objEntSI: EIListHT[0],
            objBaseTransDT: EIListDT,
            objEntTax: EIListTax,
            strDBName: _AuthParems.DB,
          },
          dispatch
        );
        if (res) {
          ClearClick();
          Show_Message(dispatch, res.strMessage, "success");
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = () => {
    setstrPartyName("");
    setstrPartyAdd("");
    setstrTransNo("");
    setstrVouchNo("");
    setstrVouchDate(new Date().toISOString().split("T")[0]);
    setiTaxRelId(0);
    setiBillTypeId(0);
    setiEITypeId(1);
    setiVenTypeId(2);
    setstrRemark1("");
    setiEIId(0);
    setiRowIndex(0);
    setisDiscPer(true);
    setiScrId(66);
    setiPartyId(0);
    setstrRemark2("");
    setstrRemark3("");
    setstrRemark4("");
    setdBillDisPer(0);
    setdBillDisAmt(0);
    setdBillDisValue(0);
    setstrNetAmt(0);
    setEIListDT([]);
    setEIListDT([]);
    setEIListTax([]);
    setiGoodsValue(0);
    setiTotalDisc(0);
    setiTotalTax(0);
    setiRountoff(0);
    Clear_Service();
    Load_ExponeIncome();
  };
  const BillDiscPer_onKeyDown = async (e) => {
    try {
      await Pre_Loading(dispatch, true);
      setdBillDisPer(Number(e.target.value));
      const res = await API_POST(
        "ExpenseIncome/CalculateBillDiscount/",
        {
          strCase: "BillDicPer",
          iScrId: iScrId,
          iTaxTypeId: iTaxRelId,
          iTaxCalcOnId: iTaxCalcOnId,
          iPartyId: iPartyId,
          dTBillDiscPer: Number(e.target.value),
          dTBillDiscAmt: Number(dBillDisAmt),
          dTBillDiscValue: Number(dBillDisValue),
          objBaseTransDT: EIListDT,
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        setEIListDT(res.objEIDT);
        setEIListTax(res.objTaxDT);
        setEIListSummary(res.objEISummary);
        setstrNetAmt(
          new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "INR",
          }).format(res.objEISummary[0].netAmt)
        );
        setdBillDisAmt(parseFloat(res.dBillDiscAmt).toFixed(2));
        setdBillDisValue(parseFloat(res.dBillDiscValue).toFixed(2));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const BillDiscAmt_onKeyDown = async (e) => {
    try {
      await Pre_Loading(dispatch, true);
      setdBillDisAmt(Number(e.target.value));
      const res = await API_POST(
        "ExpenseIncome/CalculateBillDiscount/",
        {
          strCase: "BillDicAmt",
          iScrId: iScrId,
          iTaxTypeId: iTaxRelId,
          iTaxCalcOnId: iTaxCalcOnId,
          iPartyId: iPartyId,
          dTBillDiscPer: Number(dBillDisPer),
          dTBillDiscAmt: Number(e.target.value),
          dTBillDiscValue: Number(dBillDisValue),
          objBaseTransDT: EIListDT,
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        setEIListDT(res.objEIDT);
        setEIListTax(res.objTaxDT);
        setEIListSummary(res.objEISummary);
        setstrNetAmt(
          new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "INR",
          }).format(res.objEISummary[0].netAmt)
        );
        setdBillDisPer(parseFloat(res.dBillDiscPer).toFixed(2));
        setdBillDisValue(parseFloat(res.dBillDiscValue).toFixed(2));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const KeyDown = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
        if (
          ActiveIndex + 1 === ListTabOrder.ServiceListTab &&
          EIListDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex + 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        $("[tabindex='" + 0 + "']").focus();
        $("[tabindex='" + 0 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
        if (
          ActiveIndex + 1 === ListTabOrder.ServiceListTab &&
          EIListDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex + 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        $("[tabindex='" + 0 + "']").focus();
        $("[tabindex='" + 0 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (
          ActiveIndex - 1 === ListTabOrder.ServiceListTab &&
          EIListDT.length > 0
        ) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex - 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder.ServiceListTab && EIListDT.length > 0) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + maxTab + "']").focus();
          $("[tabindex='" + maxTab + "']").select();
        }
      }
    }
  };
  const Searchparty = async (SearchText) => {
    setstrSearch(SearchText);
    const parems = {
      iScrId: 3,
      iPartyId: iVenTypeId,
      strColName: "SuppName",
      strSearch: SearchText,
      strDBName: _AuthParems.DB,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(`PartyTrans/Search_Party`, parems, dispatch);
    if (res) {
      setarrParty(res.EntParty);
    }
  };
  const ButtonPermission = async () => {
    const AuthParems = await DecryptData(localStorage.jbctrlparams);
    let ListMenu = AuthParems.AccountsPer.filter(
      (Data) => Data.iScrId === 66
    )[0];
    setisSaveBtn(iEIId > 0 ? !ListMenu.isModify : !ListMenu.isCreate);
    setisDeleteBtn(!ListMenu.isDelete);
    setisViewBtn(!ListMenu.isView);
  };
  const columns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 70,
      style: { justifyContent: "center" },
    },
    {
      Header: "Service Name",
      accessor: "serName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Rate W/O Tax",
      accessor: "rateWithoutTax",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Rate + Tax",
      accessor: "rateWithTax",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Goods Value",
      accessor: "goodsValue",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Disc %",
      accessor: "itemDiscPer",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Disc Amt",
      accessor: "itemDiscAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Disc Value",
      accessor: "itemDiscValue",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Bill Disc",
      accessor: "billDiscValue",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Tax %",
      accessor: "taxPer",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "center" },
    },
    {
      Header: "Tax Amt",
      accessor: "taxAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Amount",
      accessor: "itemAmt",
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Options",
      id: "proId",
      maxWidth: 70,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div>
          <i
            onClick={() => DeleteService(rowInfo.sNo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  const Input_ValueChange = async (e) => {
    await dispatch(
      Input_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  return (
    <main className="main-container">
      <SideMenu ScrId={iScrId} />
      <div className="content1">
        <div className="main-content1">
          <div className="row mt-2">
            <div className="col-md-3 col-sm-12 mt-1">
              <label>
                Name <span className="danger">*</span>
              </label>
              <div className="custom-select">
                <input
                  type="text"
                  className="form-control"
                  spellCheck={false}
                  placeholder="Type Party Name"
                  autoComplete="off"
                  tabIndex={_tabOrder.txtPartyName}
                  onKeyDown={(e) => {
                    if (_HT.strTransNo.length > 0) {
                      if (e.key === "Enter") {
                        dispatch(ShowHide_Popup("IsVenSearch"));
                      } else KeyUpDown(e, _Common, _DT);
                    }
                  }}
                  onClick={(e) => {
                    if (_HT.strTransNo.length > 0) {
                      dispatch(
                        ShowHide_Popup({
                          isshow: "IsVenSearch",
                          default: true,
                        })
                      );
                    }
                  }}
                  id="txtPartyName"
                  value={_HT.strPartyName}
                  onChange={(e) => {
                    if (_HT.strTransNo.length > 0) {
                      dispatch(ShowHide_Popup("IsVenSearch"));
                    }
                  }}
                  disabled={
                    _HT.iVouchId > 0
                      ? true
                      : _HT.strTransNo.length > 0
                      ? false
                      : true
                  }
                  autoFocus
                />
                <i
                  className="fa-solid fa-caret-down"
                  style={{
                    position: "absolute",
                    right: "10px",
                    color: "darkgreen",
                    transform: _Common.IsVenSearch
                      ? "rotate(-180deg)"
                      : "rotate(0deg)",
                  }}
                ></i>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mt-1">
              <label>
                Bill No <span className="danger">*</span>
              </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Voucher Number"
                  id="txtVoucherNo"
                  name="strVouchNo"
                  spellCheck={false}
                  tabIndex={_tabOrder.txtInvNo}
                  onChange={(e) => {
                    Input_ValueChange(e);
                  }}
                  readOnly={_HT.isManualCode}
                  value={_HT.strVouchNo}
                  onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                />
                <span
                  className="inv-no"
                  onClick={(e) => dispatch(ShowHide_Popup("IsVouchNo"))}
                >
                  <i className="bx bx-cog"></i>
                </span>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mt-1">
              <label>
                Trans No
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                id="txtTransNo"
                name="strTransNo"
                readOnly
                value={_HT.strTransNo}
                tabIndex={_tabOrder.txtTransNo}
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-1">
              <label>
                Date <span className="danger">*</span>
              </label>
              <input
                type="date"
                name="strVouchDate"
                tabIndex={_tabOrder.dpInvDate}
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                className="form-control"
                value={_HT.strVouchDate}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-1">
              <label>
                Bill Type <span className="danger">* </span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_select.isoptionShow}
                placeholder="Bill Type"
                select_value={(val) =>
                  Input_ValueChange({
                    target: { name: "iBillTypeId", value: val },
                  })
                }
                taborder={_tabOrder.cbBillType}
                keydown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown");
                  else KeyUpDown(e, _Common, _DT);
                }}
                btnname="Bill Type"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={_HT.iBillTypeId}
                displayName="strBillType"
                disvalue="iBT_Id"
                arrydata={_Common.BillType}
                disabled={false}
                EmptVal="Bill Type"
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-1">
              <label>
                Tax Type <span className="danger">* </span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_select.isoptionShow1}
                placeholder="Tax Type"
                select_value={(val) => {}}
                taborder={_tabOrder.cbTaxType}
                keydown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown");
                  else KeyUpDown(e, _Common, _DT);
                }}
                btnname="Tax Type"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={_HT.iTaxRelId}
                displayName="strTaxType"
                disvalue="iTaxRelId"
                arrydata={_Common.TaxType}
                disabled={true}
                EmptVal="Tax Type"
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-1">
              <label>
                Vendor Type <span className="danger">*</span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow2"
                showoption={_select.isoptionShow2}
                placeholder="Select Vendore Type"
                select_value={(val) =>
                  dispatch(
                    CommonInput_Onchange({
                      name: "VenTypeId",
                      value: val,
                    })
                  )
                }
                btnname="Location"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={_Common.VenTypeId}
                displayName="strVenTypeName"
                disvalue="iVenTypeId"
                arrydata={_Common.VendorType}
                EmptVal="Vendor Type "
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-1">
              <label>
                Expense Income Type <span className="danger">* </span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow3"
                showoption={_select.isoptionShow3}
                placeholder="Expense Income Type"
                select_value={(val) =>
                  Input_ValueChange({
                    target: { name: "iEITypeId", value: val },
                  })
                }
                btnname="Expense Income"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={_HT.iEITypeId}
                displayName="strEITypeName"
                disvalue="iEITypeId"
                arrydata={_Common.EIType}
                disabled={false}
                EmptVal="Expense Income"
              />
            </div>
            <div className="col-md-3 col-sm-12 mt-1">
              <label>Remark 1</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Remark1"
                name="strRemark1"
                spellCheck={false}
                autoComplete="off"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                tabIndex={_tabOrder.txtRemark1}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strRemark1}
              />
            </div>
            <div className="col-md-3 col-sm-12 mt-1">
              <label>Remark 2</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Remark2"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                tabIndex={_tabOrder.txtRemark2}
                name="strRemark2"
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strRemark2}
              />
            </div>
            <div className="col-md-3 col-sm-12 mt-1">
              <label>Remark 3</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Remark3"
                tabIndex={_tabOrder.txtRemark3}
                name="strRemark3"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strRemark3}
              />
            </div>
            <div className="col-md-3 col-sm-12 mt-1">
              <label>Remark 4</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                onKeyDown={(e) => KeyUpDown(e, _Common, _DT)}
                placeholder="Enter Remark4"
                spellCheck={false}
                name="strRemark4"
                autoComplete="off"
                tabIndex={_tabOrder.txtRemark4}
                onChange={(e) => {
                  Input_ValueChange(e);
                }}
                value={_HT.strRemark4}
              />
            </div>
            <div className="col-md-2 col-sm-12 mt-1">
              <label>Address</label>
              <textarea
                className="form-control rounded"
                aria-label="With textarea"
                placeholder="Address of Party"
                style={{
                  height: "100px",
                  whiteSpace: "break-spaces",
                  overflow: "auto",
                  maxHeight: "100px",
                  background: "rgb(238,238,238)",
                }}
                id="txtPartyDetails"
                value={strPartyAdd}
                onChange={(e) => setstrPartyAdd(e.target.value)}
                tabIndex={ListTabOrder.txtBillAddress}
                onKeyDown={(e) => KeyDown(e)}
              ></textarea>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 col-sm-12">
              <label>Service Name</label>
              <div className="custom-select">
                <input
                  className="form-control"
                  disabled={false}
                  type="text"
                  placeholder="Select Service"
                  value={strSerName}
                  onClick={(e) => setisService(!isService)}
                  onChange={(e) => setisService(!isService)}
                />
                <i className="fa-solid fa-caret-down"></i>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <label>Cost Rate</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                name="dPriceRate"
                id="txtSerRate"
                value={dPriceRate}
                onChange={(e) => setdPriceRate(e.target.value)}
                onClick={(e) => e.target.select()}
                tabIndex={ListTabOrder.txtRate}
                onKeyDown={(e) => KeyDown(e)}
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <label>Discount</label>
              <div className="trancdisc">
                <input
                  type="number"
                  className="form-control"
                  value={dItemDisc}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdItemDisc(e.target.value)}
                  tabIndex={ListTabOrder.txtDiscount}
                  onKeyDown={(e) => KeyDown(e)}
                  onBlur={(e) =>
                    setdItemDisc(Number(e.target.value).toFixed(2))
                  }
                />
                <button
                  className="btn-fabgreen active"
                  onClick={(e) => {
                    $(".trancdisc .btn-fabgreen").toggleClass("active");
                    setisDiscPer(!isDiscPer);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowRight" || e.key === "ArrowLeft")
                      setisDiscPer(!isDiscPer);
                    else KeyDown(e);
                  }}
                >
                  {isDiscPer ? "%" : "₹"}
                </button>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="btn-sub-section mt-3">
                <button
                  onClick={Add_Service}
                  tabIndex={ListTabOrder.btnAdd}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") Add_Service();
                    else KeyDown(e);
                  }}
                  className="btn-fabgreen"
                >
                  Add&nbsp;<i className="bx bx-layer-plus"></i>
                </button>
                <button
                  onClick={(e) => Clear_Service()}
                  className="btn-fabgreen"
                >
                  Clear&nbsp;<i className="fas fa-eraser"></i>
                </button>
              </div>
            </div>
            <ReactTable
              columns={columns}
              data={EIListDT}
              minRows={5}
              row_click={(ServInfo, Index) => {}}
              row_doubleclick={async (ServInfo, Index) => {
                setselected(Index);
                AssignSelectedService(ServInfo, Index);
              }}
              background={true}
              className="full-table"
              tabIndex={ListTabOrder.ProductTableListTab}
              KeyDown={(e) => KeyDown(e)}
            />
          </div>
          <div className="row mt-2">
            <div className="col-sm-12 col-md-4 ">
              <div className="sum-container">
                <div className="Exp-sum-lbl">
                  <label>Goods Value</label>
                  <label>{Number(iGoodsValue).toFixed(2)}</label>
                </div>
                <div className="Exp-sum-lbl">
                  <label>Discount</label>
                  <label>{Number(iTotalDisc).toFixed(2)}</label>
                </div>
                <div className="Exp-sum-lbl">
                  <label>Tax</label>
                  <label>{Number(iTotalTax).toFixed(2)}</label>
                </div>
                <div className="Exp-sum-lbl">
                  <label>Round Off</label>
                  <label>{Number(iRountoff).toFixed(2)}</label>
                </div>
                <div className="Exp-sum-foot">
                  <label>Net Amount</label>
                  <label>{strNetAmt}</label>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 ">
              <div className="sum-container">
                {EIListTax.map((Tax, Index) => (
                  <div className="Exp-sum-lbl" key={Index}>
                    <label>{Tax.taxDesc}</label>
                    <label>{Tax.taxableAmt.toFixed(2)}</label>
                    <label>{Tax.taxAmt.toFixed(2)}</label>
                  </div>
                ))}
                <div className="Exp-sum-foot">
                  <label>Tax Amount</label>
                  <label>
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "INR",
                    }).format(parseFloat(iTotalTax).toFixed(2))}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 ">
              <div className="sum-container">
                <div className="row p-2">
                  <div className="col-sm-6 col-md-6">
                    <label>Bill Discount Per</label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      name="dBillDisPer"
                      value={dBillDisPer}
                      tabIndex={ListTabOrder.txtBillDiscPer}
                      onKeyDown={(e) => KeyDown(e)}
                      onChange={(e) => BillDiscPer_onKeyDown(e)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <label>Bill Discount Amt</label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      name="dBillDisAmt"
                      value={dBillDisAmt}
                      tabIndex={ListTabOrder.txtBillDiscAmt}
                      onChange={(e) => BillDiscAmt_onKeyDown(e)}
                      onClick={(e) => e.target.select()}
                      onKeyDown={(e) => KeyDown(e)}
                    />
                  </div>
                </div>
                <div className="Exp-sum-foot">
                  <label>Bill Discount</label>
                  <label>
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "INR",
                    }).format(parseFloat(dBillDisValue).toFixed(2))}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {_Common.IsVenSearch && <PartySearch />}
          {_Common.IsVenCreation && <PartyCreation />}
          {isService && (
            <ServiceSearch
              Close={(e) => setisService(!isService)}
              View_Service={View_Service}
            />
          )}
          {isTransView && (
            <CustomTransView
              Close={(e) => setisTransView(!isTransView)}
              ScrId={iScrId}
              Title="Expense Income"
              View_Invoice={ViewClick}
            />
          )}
          <Messagedialogbox />
        </div>
        <div className="button-list">
          <button
            type="button"
            className="btn-fabgreen"
            disabled={isSaveBtn}
            onClick={async (e) => {
              if (
                await Validate_Properties(_Common.arrValidation, _HT, dispatch)
              ) {
                Ask_Question(
                  dispatch,
                  SaveClick,
                  iEIId > 0
                    ? "Do You Want to Update ?"
                    : "Do You Want to Save ?"
                );
              }
            }}
            tabIndex={ListTabOrder.btnSave}
            onKeyDown={(e) => KeyDown(e)}
          >
            Save &nbsp; <i className="fas fa-save"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            tabIndex={ListTabOrder.btnClear}
            onKeyDown={(e) => KeyDown(e)}
            onClick={ClearClick}
          >
            Clear &nbsp; <i className="fas fa-eraser"></i>
          </button>
          <button
            disabled={isViewBtn}
            type="button"
            className="btn-fabgreen"
            onClick={(e) => setisTransView(!isTransView)}
            tabIndex={ListTabOrder.btnView}
            onKeyDown={(e) => KeyDown(e)}
          >
            View &nbsp; <i className="fas fa-eye"></i>
          </button>
          <button
            type="button"
            disabled={isDeleteBtn}
            className="btn-fabgreen"
            onClick={(e) => {
              Ask_Question(dispatch, DeleteClick, "Do You Want to Delete ?");
            }}
            tabIndex={ListTabOrder.btnCancel}
            onKeyDown={(e) => KeyDown(e)}
          >
            Delete &nbsp; <i className="far fa-trash-alt"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            tabIndex={ListTabOrder.btnClose}
            onKeyDown={(e) => KeyDown(e)}
            onClick={() => window.history.back()}
          >
            Close &nbsp; <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </main>
  );
}
export default ExpenseIncome;
