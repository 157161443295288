import React from "react";
import { useEffect } from "react";
import $ from "jquery";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_POST } from "../General/Utility";
function PosView(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [strSearch, setstrSearch] = useState("");
  const [strFromDate, setstrFromDate] = useState("");
  const [strToDate, setstrToDate] = useState("");
  const [VouchersList, setVouchersList] = useState([]);
  const [iVouchId, setiVouchId] = useState(0);
  useEffect(() => {
    $("[tabindex=52]").focus();
    load_Vouchers("", "Search");
    short_cut_keypress();
  }, []);
  const load_Vouchers = async (value, SearchFor) => {
    let FromDate = strFromDate;
    let ToDate = strToDate;
    let Search = strSearch;
    switch (SearchFor) {
      case "Search":
        setstrSearch(value);
        Search = value;
        break;
      case "FromDate":
        setstrFromDate(value);
        FromDate = value;
        break;
      case "ToDate":
        setstrToDate(value);
        ToDate = value;
        break;
      default:
        break;
    }
    const objSearch = {
      iBranchId: Number(_AuthParems.Branch),
      strFromDate: FromDate,
      strToDate: ToDate,
      strSearch: Search,
      iScrId: 93,
      strDBName: _AuthParems.DB,
      iCounterId: Number(localStorage.Counter),
    };
    const res = await API_POST(`POS/load_Vouchers/`, objSearch, dispatch);
    if (res) {
      setstrFromDate(res.strFromDate);
      setstrToDate(res.strToDate);
      setVouchersList(res.objVouchers);
    }
  };
  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        props.Close();
      }
    });
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Invoice</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="input-search">
            <input
              type="text"
              value={strSearch}
              onChange={(e) => load_Vouchers(e.target.value, "Search")}
              className="form-control"
              placeholder="Search bill number ...."
              autoFocus
              autoComplete="off"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  $(`[tabindex=0]`).focus();
                } else if (e.key === "Enter") {
                  props.ViewInvoice(VouchersList[0].vouchId);
                }
              }}
            />
            <input
              type="date"
              value={strFromDate}
              onChange={(e) => load_Vouchers(e.target.value, "FromDate")}
              className="form-control"
              autoComplete="off"
            />
            <input
              type="date"
              value={strToDate}
              onChange={(e) => load_Vouchers(e.target.value, "ToDate")}
              className="form-control"
              autoComplete="off"
            />
          </div>
          <div className="pos-view">
            {VouchersList.map((Vouch, trIndex) => (
              <button
                key={trIndex}
                tabIndex={trIndex}
                onClick={(e) => {
                  $(`[tabindex=${trIndex}]`)
                    .addClass("active")
                    .siblings()
                    .removeClass("active");
                  setiVouchId(Vouch.vouchId);
                }}
                onDoubleClick={(e) => props.ViewInvoice(Vouch.vouchId)}
                onKeyDown={(e) => {
                  let arrlength = VouchersList.length - 1;
                  var ActiveIndex = document.activeElement.tabIndex;
                  if (e.key === "ArrowDown") {
                    if (ActiveIndex === arrlength) {
                      $("[tabindex=0]").focus();
                      $("[tabindex=0]").select();
                    } else {
                      $(`[tabindex=${ActiveIndex + 1}]`).focus();
                      $(`[tabindex=${ActiveIndex + 1}]`).select();
                    }
                  }
                  if (e.key === "ArrowUp") {
                    if (ActiveIndex === 0) {
                      $(`[tabindex=${arrlength}]`).focus();
                      $(`[tabindex=${arrlength}]`).select();
                    } else {
                      $(`[tabindex=${ActiveIndex - 1}]`).focus();
                      $(`[tabindex=${ActiveIndex - 1}]`).select();
                    }
                  }
                  if (e.key === "Enter") {
                    props.ViewInvoice(Vouch.vouchId);
                  } else if (e.key === "Escape") props.Close();
                  if (e.key === "Escape") props.Close();
                }}
              >
                <div className="bill-content">
                  <h6>{Vouch.partyName}</h6>
                  <div className="trans-cont">
                    <div className="transview1">
                      <label>
                        <span>Inv No : {Vouch.vouchNo}</span>
                        <span>Date : {Vouch.vouchDate}</span>
                        <span>Qty : {Number(Vouch.tQty).toFixed(2)}</span>
                        <span>
                          Tax Amount :
                          {new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "INR",
                          }).format(Number(Vouch.tTaxAmt).toFixed(2))}
                        </span>
                        <span>
                          Net Amount :
                          {new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "INR",
                          }).format(Number(Vouch.netAmt).toFixed(2))}
                        </span>
                      </label>
                      <label></label>
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="pos-btn-group">
          <div className="pos-tooltip">
            <a className="icon-btn" href="##">
              <i className="fa-solid fa-print"></i>
            </a>
            <span className="pos-tooltiptxt">Print</span>
          </div>
          <div className="pos-tooltip">
            <a className="icon-btn" href="##">
              <i className="fa-solid fa-download"></i>
            </a>
            <span className="pos-tooltiptxt">Download</span>
          </div>
          <div className="pos-tooltip" onClick={(e) => props.Return(iVouchId)}>
            <a className="icon-btn" href="##">
              <i className="fa-solid fa-arrow-rotate-left"></i>
            </a>
            <span className="pos-tooltiptxt">Return</span>
          </div>
          <div className="pos-tooltip">
            <a className="icon-btn" href="##">
              <i className="fa-brands fa-whatsapp"></i>
            </a>
            <span className="pos-tooltiptxt">whatsapp</span>
          </div>
          <div className="pos-tooltip">
            <a className="icon-btn" href="##">
              <i className="fa-regular fa-envelope"></i>
            </a>
            <span className="pos-tooltiptxt">E-mail</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PosView;
