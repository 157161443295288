import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import $ from "jquery";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import MasterCodeSetting from "./MasterCodeSetting";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
function PriceGroupMaster(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const iScrId = 80;
  const [strPrefix, setstrPrefix] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const strPGCodeTag = "Enter PriceGroup Code";
  const [IsMasterCode, setIsMasterCode] = useState(false);

  const [strPGCode, setstrPGCode] = useState("");
  const [iPGrpId, setiPGrpId] = useState(0);
  const [strPGrpName, setstrPGrpName] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [dDiscount, setdDiscount] = useState((0).toFixed(2));
  const [strSearch, setstrSearch] = useState("");
  const [ListPriceGRP, setListPriceGRP] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);
  const [IsDefault, setIsDefault] = useState(false);

  useEffect(() => {
    Load_PriceGroup();
  }, []);
  const Load_PriceGroup = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `PriceGroupMaster/Load_PriceGroup/${Number(_LocalParems.Branch)}/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        setListPriceGRP(res.objEntPRG);
        setstrPGCode(res.objCodFormate[0].strCode);
        setstrPrefix(res.objCodFormate[0].strPrefix);
        setiNumSeqNo(res.objCodFormate[0].iNumSeqNo);
        setManualCode(!res.objCodFormate[0].isManualNo);
        ButtonPermission(_LocalParems.GroupsPer, iPGrpId);
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const SaveClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const InsUpdPG = {
        strPGCode: strPGCode,
        iPGrpId: iPGrpId,
        strPGrpName: strPGrpName,
        dDiscount: Number(dDiscount),
        iTitleTag: iScrId,
        iNumSeqNo: iNumSeqNo,
        strPrefix: strPrefix,
        strDescription: strDescription,
        dMinMargin: 0,
        strPGCodeTag: strPGCodeTag,
        strSearch: strSearch,
        bIsDefault: IsDefault,
        strFilter: "",
        strFilterColumn: "All",
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        "PriceGroupMaster/InsUpd_PriceGroup/",
        InsUpdPG,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = () => {
    setstrPGCode("");
    setiPGrpId(0);
    setstrPGrpName("");
    setstrDescription("");
    setdDiscount((0.0).toFixed());
    setBtnDeleteDisable(false);
    setBtnSaveDisable(false);
    Load_PriceGroup();
  };
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_DELETE(
        `PriceGroupMaster/Delete_PriceGroup/${Number(
          _AuthParems.Branch
        )}/${iPGrpId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_PriceGroup = async (iPGrpId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        "PriceGroupMaster/View_PriceGroup/" +
          `${iPGrpId}/${Number(_AuthParems.Branch)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        let EntPG = res.EntPG[0];
        setstrPGCode(EntPG.strPGCode);
        setiPGrpId(EntPG.iPGrpId);
        setstrPGrpName(EntPG.strPGrpName);
        setdDiscount(EntPG.dDiscount);
        setstrDescription(EntPG.strDescription);
        setIsDefault(EntPG.bIsDefault);
        ButtonPermission(_AuthParems.GroupsPer, EntPG.iPGrpId);
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_PriceGroup = async (SearchText) => {
    try {
      setstrSearch(SearchText);
      SearchText = SearchText === "" ? "null" : SearchText;
      const res = await API_FETCH(
        "PriceGroupMaster/Search_PriceGroup/" +
          SearchText +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        setListPriceGRP(res);
      }
    } catch (err) {
      Show_Message(dispatch, err.strMessage, "error");
    }
  };
  const ButtonPermission = (ListMenu, PgId) => {
    ListMenu = ListMenu.filter((Data) => Data.iScrId === 62);
    if (ListMenu.length > 0) {
      if (Number(PgId) > 0) {
        setBtnSaveDisable(!Boolean(ListMenu[0].iModify));
        setBtnDeleteDisable(!Boolean(ListMenu[0].iDelete));
      }
      if (Number(PgId) === 0) {
        setBtnSaveDisable(!Boolean(ListMenu[0].iCreate));
      }
    }
  };

  return (
    <main className="main-container">
      <SideMenu ScrId={80} />
      <div className="content">
        <div className="left-list">
          <div className="m-header p-2 mt-2">
            <div className="m-hsearch">
              <input
                type="search"
                placeholder="Search Price Group"
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                    $(".data-list [tabindex=0]").focus();
                  }
                }}
                value={strSearch}
                onChange={(e) => {
                  Search_PriceGroup(e.target.value);
                }}
                autoFocus
              />

              <i
                className="fas fa-search"
                onClick={(e) => props.Search(props.strSearchtxt)}
              ></i>
            </div>
          </div>
          <div className="data-list">
            {ListPriceGRP.map((pg, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = props.arrMasterData.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    View_PriceGroup(pg.iPGrpId);
                  }
                }}
              >
                <label onClick={(e) => View_PriceGroup(pg.iPGrpId)}>
                  {pg.strPGrpName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-2">
              <label>
                Name <span className="danger">*</span>
              </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={strPGrpName}
                onChange={(e) => setstrPGrpName(e.target.value)}
                autoFocus
                placeholder="Price Group Name"
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>
                Code<span className="danger">*</span>
              </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  value={strPGCode}
                  readOnly={ManualCode}
                  onChange={(e) => setstrPGCode(e.target.value)}
                />
                <span
                  className="inv-no"
                  onClick={(e) => setIsMasterCode(!IsMasterCode)}
                >
                  <i className="bx bx-cog"></i>
                </span>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Discount</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                value={dDiscount}
                onClick={(e) => e.target.select()}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setdDiscount((0).toFixed(2));
                  } else setdDiscount(parseFloat(dDiscount).toFixed(2));
                }}
                onChange={(e) => setdDiscount(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-2">
              <label>Description</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={strDescription}
                onChange={(e) => setstrDescription(e.target.value)}
                placeholder="Description"
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-2">
              <label>Default Price Group</label>
              <input
                type="checkbox"
                className="form-check"
                checked={IsDefault}
                onChange={(e) => setIsDefault(!IsDefault)}
              />
            </div>
          </div>
          {IsMasterCode && (
            <MasterCodeSetting
              Close={(e) => setIsMasterCode(!IsMasterCode)}
              iScrId={iScrId}
            />
          )}
          <Messagedialogbox />
        </div>
        <div className="button-list">
          <button
            type="button"
            className="btn-fabgreen"
            disabled={BtnSaveDisable}
            onClick={(e) => {
              if (strPGCode === "") {
                Show_Message(dispatch, "Please Enter Code", "error");
              } else if (strPGrpName === "") {
                Show_Message(dispatch, "Please Enter Name", "error");
              } else {
                Ask_Question(
                  dispatch,
                  SaveClick,
                  "Do You Want to  Save Price Group?"
                );
              }
            }}
          >
            Save&nbsp;<i className="fas fa-save"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            disabled={BtnDeleteDisable}
            onClick={(e) => {
              if (iPGrpId === 0) {
                Show_Message(dispatch, "Please Select PriceGroup", "error");
              } else if (IsDefault) {
                Show_Message(
                  dispatch,
                  "Couldn't Delete Default Group",
                  "error"
                );
              } else {
                Ask_Question(
                  dispatch,
                  DeleteClick,
                  "Do You Want to  Delete Price Group?"
                );
              }
            }}
          >
            Delete&nbsp;<i className="bx bx-trash-alt"></i>
          </button>
          <button type="Reset" className="btn-fabgreen" onClick={ClearClick}>
            Clear&nbsp;<i className="fa-solid fa-broom"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            onClick={(e) => (window.location.href = `/JustBill/DashBoard`)}
          >
            Close&nbsp;<i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </main>
  );
}

export default PriceGroupMaster;
