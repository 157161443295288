import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
  Add_TransHtparem,
  Add_CommonDefault,
  Add_TransSummary,
  Add_party,
  Add_Transdata,
  add_BillDiscount,
  Clear_invoice,
  setothercharges,
} from "../features/POS";
import PosView from "./PosView";
import Notes from "./Notes";
import OtherCharges from "./OtherCharges";
import CustomerSearch from "./CustomerSearch";
import Sessionopenclose from "./Sessionopenclose";
import Receipt from "./Receipt";
import DeliveryOption from "./DeliveryOption";
import Posrightmenu from "./Posrightmenu";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import Discount from "./Discount";
import StaffProfile from "./StaffProfile";
import ProductReturn from "./ProductReturn";
import POSReturn from "./POSReturn";
import MobileProductSearch from "./MobileProductSearch";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import { Update_AdminCntrl } from "../features/CommonStates";
import {
  AddProduct,
  Load_NewSession,
  Modify_Product,
  Remove_product,
  ShowHide_Popup,
  Update_BillType,
  setPOS_DefaultParems,
} from "../features/Transactionstates";
import TransProductSearch from "../Transaction/TransProductSearch";
import BatchProductSearch from "../Transaction/BatchProductSearch";
import AttributeProductSearch from "../Transaction/AttributeProductSearch";
import { TransConfig } from "../Transaction/Transaction";
import PosproductInput from "./PosproductInput";
import ItemRemarks from "../Transaction/ItemRemarks";
import RootCalculation from "../Transaction/RootCalculation";

function Pointofsales() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const tableRef = useRef(null);
  const buttonsave = useRef();
  const buttoncard = useRef();
  const buttonclear = useRef();

  //#region  Redonly Parem from Global Redux Storage
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((state) => state.Transstates.Data);
  const _Sum = useSelector((state) => state.Transstates.Summary);
  const _Employee = useSelector((state) => state.Transstates.Employee);
  const _Product = useSelector((state) => state.Transstates.Product);
  const _Pos = useSelector((state) => state.Pointofsales.Pos);
  const _Customer = useSelector((state) => state.Transstates.Customer);
  const _Disc = useSelector((state) => state.Pointofsales.BillDiscount);
  //#endregion

  //#region Default parems
  const [strSearch, setstrSearch] = useState("");
  const [isDiscount, setisDiscount] = useState(false);
  const [iVouchId, setiVouchId] = useState(0);
  const [isConvertReturn, setisConvertReturn] = useState(false);
  const [arrEntPermission, setarrEntPermission] = useState([]);
  //#endregion

  //#region Popup Parems
  const [isPosView, setisPosView] = useState(false);
  const [isAddNotes, setisAddNotes] = useState(false);
  const [isOtherCharges, setisOtherCharges] = useState(false);
  const [isReceipt, setisReceipt] = useState(false);
  const [isAddtoCart, setisAddtoCart] = useState(false);
  const [isDelivery, setisDelivery] = useState(false);
  const [isReturn, setisReturn] = useState(false);
  const [isProReturn, setisProReturn] = useState(false);
  //#endregion
  const _ScrId = 93;

  useEffect(() => {
    Load_PosInvoice();
    document.addEventListener("keydown", short_cut_keypress);
    return () => {
      document.removeEventListener("keydown", short_cut_keypress);
    };
  }, []);
  const Load_PosInvoice = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      await dispatch(Update_AdminCntrl(_LocalParems));
      const config = TransConfig(_ScrId);
      const objTrans = await {
        strController: config.Controller,
        strScrType: config.strScrType,
        iScrId: config.iScrId,
        strScrName: config.strScrName,
        iPartyScrId: 2,
        iPartyId: 0,
        iVenType: 0,
        strSearch: "",
        strColName: "",
        strDBName: _LocalParems.DB,
        iClientId: Number(_LocalParems.Client),
        iUserId: Number(_LocalParems.User),
        iRoleId: Number(_LocalParems.Role),
        iBranchId: Number(_LocalParems.Branch),
        iCounterId: Number(_LocalParems.Counter),
        iSessionId: Number(_LocalParems.Session),
      };
      const res = await API_POST(`POS/Load_Invoice/`, objTrans, dispatch);
      if (res) {
        if (Number(res.POS.counterId) === 0)
          Show_Message(
            dispatch,
            "Please Update Counter for Current User",
            "info"
          );
        else {
          res.Transparem = objTrans;
          await dispatch(setPOS_DefaultParems(res));
          if (Number(res.POS.sessionId) === 0)
            await dispatch(ShowHide_Popup("IsSession"));
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };

  //#region  Click Event
  const Save_Invoice = async (ObjReceipt) => {
    try {
      await Pre_Loading(dispatch, true);
      let URL = "POS/InsUpd_Invoice/";
      setisReceipt(!isReceipt);
      let ReceiptDt = {
        strPrefix: "",
        iNumSeqNo: 0,
        strCompCode: "",
        strFinanFrom: "",
        strRecNo: "",
        strTransNo: "",
        dpRecDate: _HT.strVouchDate,
        iVenType: 5,
        iVenId: _Customer.iPartyId,
        iPayModeId: _HT.iBillTypeId,
        dAmount: ObjReceipt.dAmount,
        iVouchId: 0,
        iBankAccId: ObjReceipt.iBankAccId,
        strChequeNo: ObjReceipt.strChequeNo,
        dpChequeDate: ObjReceipt.strChequeDate,
        strAccNo: ObjReceipt.strAccNo,
        strAccHolderName: ObjReceipt.strAccHolderName,
        strPrintName: ObjReceipt.strPrintName,
        strRemark: ObjReceipt.strRemark,
        iBNId: ObjReceipt.iBankId,
        iRecId: 0,
        iCallerScrId: 93,
        strBankName: "",
        strFilter: "",
        strFilterColumn: "",
        strSearch: "",
        strVenName: "",
        strDBName: _AuthParems.DB,
        iSessionId: Number(_AuthParems.Session),
      };
      const POSInvListHT = {
        iPosInvId: _HT.iVouchId,
        iTitleTag: _Common.iScrId,
        iPartyId: _Customer.PartyId,
        strShippingAdd: _Customer.ShippAdd,
        iNumSeqNo: 0,
        strPrefix: "",
        strCompCode: "",
        strFinanFrom: "",
        strTransNo: "",
        strVouchNo: _HT.strVouchNo,
        strVouchDate: _HT.strVouchDate,
        iAddLessBillDiscId: _Disc.iALBillDiscId,
        iBillTypeId: _HT.iBillTypeId,
        iTaxCalcOnId: Number(_Pos.iTaxCalcOnId),
        iTaxTypeId: Number(_HT.iTaxRelId),
        iDMId: _HT.iDMId,
        strConvertDCVouch: _HT.strConvertDCVouch,
        strPartyDCVouch: _HT.strPartyDCVouch,
        strRefNum1: _HT.strRefNum1,
        dpRefNum1Date: _HT.dpRefNum1Date,
        strRefNum2: _HT.strRefNum2,
        dpRefNum2Date: _HT.dpRefNum2Date,
        strRefNum3: _HT.strRefNum3,
        dpRefNum3Date: _HT.dpRefNum3Date,
        strRefNum4: _HT.strRefNum4,
        dpRefNum4Date: _HT.dpRefNum4Date,
        strRemark1: _HT.strRemark1,
        strRemark2: _HT.strRemark2,
        strRemark3: _HT.strRemark3,
        strRemark4: _HT.strRemark4,
        strValidity: _HT.strValidity,
        dpValDateUpto: _HT.dpValDateUpto,
        strPayTerm: _HT.strPayTerm,
        dpPayTermDateUpto: _HT.dpPayTermDateUpto,
        strDeliTerm: _HT.strDeliTerm,
        dpDelTermDateUpto: _HT.dpDelTermDateUpto,

        strEComGstNo: _HT.strEComGstNo,
        strTransport: _HT.strTransport,
        strVehicle: _HT.strVehicle,
        strDespatch: _HT.strDespatch,
        dpDespatchDate: _HT.dpDespatchDate,
        strLRNNumber: _HT.strLRNNumber,
        dpLRNoDate: _HT.dpLRNoDate,
        strAgent: _HT.strAgent,
        strReference: _HT.strReference,
        strDestination: _HT.strDestination,

        dTBillDiscPer: Number(_Disc.dBillDisPer),
        dTBillDiscAmt: Number(_Disc.dBillDisAmt),
        dTBillDiscValue: Number(_Disc.dBillDisValue),
        dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
        dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
        dTAddLessDiscValue: Number(_Disc.dALBillDisValue),

        dTotQty: Number(_Sum.Qty),
        dTotGoodval: Number(_Sum.GoodsValue),
        dTotDiscount: Number(_Sum.Discount),
        dTotTax: Number(_Sum.Tax),
        dAddLess: Number(_Sum.AddLess),
        dTotTCSTax: Number(_Sum.TCSTax),
        dRoundOff: Number(_Sum.RoundOff),
        dNetAmt: Number(_Sum.NetAmt),
        dRoyaltyPoint: Number(_Disc.dRewardPointAmt),
        iCouponId: Number(_Disc.iCouponId),
        iCartInvId: _HT.iCartInvId,
        iUserId: Number(_AuthParems.User),
        iEmpId: Number(_Employee.iEmpId),
        FYid: Number(_AuthParems.Year),
        strDBName: _AuthParems.DB,
        iSessionId: Number(_AuthParems.Session),
        iBranchId: Number(_AuthParems.Branch),
      };
      const cust = {
        iCustId: _Customer.PartyId,
        CustName: _Customer.PartyName,
        Add1: _Customer.Address1,
        Add2: _Customer.Address2,
        Add3: _Customer.Address3,
        Add4: _Customer.Address4,
        Pincode: _Customer.Pincode,
        State: _Customer.State,
        Country: _Customer.Country,
        Mobile: _Customer.Mobile,
        Mobile1: "",
        Phone1: _Customer.Phone,
        Phone2: "",
        Email: _Customer.Email,
        GST: _Customer.GST,
        Remark: _Customer.Remark,
        ShippAdd: _Customer.ShippAdd,
        IsSameAdd: _Customer.IsSameAdd,
        RoyaltyPoint: _Customer.RoyaltyPoint,
        Status: _Customer.Status,
        CreatedBy: _Customer.CreatedBy,
      };
      const res = await API_POST(
        URL,
        {
          objEntPos: POSInvListHT,
          objTransDT: _DT.arrEntDT,
          objDTHelp: _DT.arrEntDTHelp,
          objEntALDT: _DT.arrEntAL,
          objEntTax: _DT.arrEntTax,
          objEntCust: cust,
          objEntReceipt: ReceiptDt,
        },
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        CancelInvoice();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "success");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const InsUpd_Return = async () => {
    const ReturnHT = {
      iPOSRetId: _HT.iVouchId,
      iTitleTag: 96,
      iPartyId: _Customer.iPartyId,
      strShippingAdd: _Customer.strShippAdd,
      strVouchNo: _HT.strVouchNo,
      strVouchDate: _HT.strVouchDate,
      iAddLessBillDiscId: _Disc.iALBillDiscId,
      iBillTypeId: _HT.iBillTypeId,
      iTaxCalcOnId: Number(_Pos.iTaxCalcOnId),
      iTaxTypeId: Number(_HT.iTaxRelId),
      iDMId: _HT.iDMId,
      strConvertDCVouch: _HT.strConvertDCVouch,
      strPartyDCVouch: _HT.strPartyDCVouch,
      strRefNum1: _HT.strRefNum1,
      dpRefNum1Date: _HT.dpRefNum1Date,
      strRefNum2: _HT.strRefNum2,
      dpRefNum2Date: _HT.dpRefNum2Date,
      strRefNum3: _HT.strRefNum3,
      dpRefNum3Date: _HT.dpRefNum3Date,
      strRefNum4: _HT.strRefNum4,
      dpRefNum4Date: _HT.dpRefNum4Date,
      strRemark1: _HT.strRemark1,
      strRemark2: _HT.strRemark2,
      strRemark3: _HT.strRemark3,
      strRemark4: _HT.strRemark4,
      strValidity: _HT.iValidity.toString(),
      dpValDateUpto: _HT.dpValDateUpto,
      strPayTerm: _HT.iPayTerm.toString(),
      dpPayTermDateUpto: _HT.dpPayTermDateUpto,
      strDeliTerm: _HT.iDeliTerm.toString(),
      dpDelTermDateUpto: _HT.dpDelTermDateUpto,

      strEComGstNo: _HT.strEComGstNo,
      strTransport: _HT.strTransport,
      strVehicle: _HT.strVehicle,
      strDespatch: _HT.strDespatch,
      dpDespatchDate: _HT.dpDespatchDate,
      strLRNNumber: _HT.strLRNNumber,
      dpLRNoDate: _HT.dpLRNoDate,
      strAgent: _HT.strAgent,
      strReference: _HT.strReference,
      strDestination: _HT.strDestination,

      dTBillDiscPer: Number(_Disc.dBillDisPer),
      dTBillDiscAmt: Number(_Disc.dBillDisAmt),
      dTBillDiscValue: Number(_Disc.dBillDisValue),
      dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
      dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
      dTAddLessDiscValue: Number(_Disc.dALBillDisValue),

      dTotQty: Number(_Sum.Qty),
      dTotGoodval: Number(_Sum.GoodsValue),
      dTotDiscount: Number(_Sum.Discount),
      dTotTax: Number(_Sum.Tax),
      dAddLess: Number(_Sum.AddLess),
      dTotTCSTax: Number(_Sum.TCSTax),
      dRoundOff: Number(_Sum.RoundOff),
      dNetAmt: Number(_Sum.NetAmt),
      dRoyaltyPoint: Number(_Disc.dRewardPointAmt),
      iCouponId: Number(_Disc.iCouponId),
      iCartInvId: _HT.iCartInvId,
      iUserId: Number(_AuthParems.User),
      iEmpId: Number(_Employee.iEmpId),
      FYid: Number(_AuthParems.Year),
      strDBName: _AuthParems.DB,
      iSessionId: Number(_AuthParems.Session),
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(
      "POS/InsUpd_Return",
      {
        objEntPos: ReturnHT,
        objBaseTransDT: _DT.EntTransDT,
        objDTHelp: _DT.EntTransDTHelp,
        objEntALDT: _DT.EntTransAL,
        objEntTax: _DT.EntTransTax,
      },
      dispatch
    );
    if (res) {
      Show_Message(dispatch, res.strMessage, "success");
      CancelInvoice();
    }
  };
  const Delete_PosInvoice = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `POS/Delete_Invoice/${Number(_HT.iVouchId)}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "info");
        CancelInvoice();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "success");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Delete_Return = async () => {
    const res = await API_POST(
      `POS/Delete_Return/${Number(_HT.iVouchId)}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      Show_Message(dispatch, res.strMessage, "info");
      CancelInvoice();
    }
  };
  const CancelInvoice = () => {
    setisAddtoCart(false);
    setisConvertReturn(false);
    dispatch(Clear_invoice());
    Load_PosInvoice();
  };
  const Show_Receipt = () => {
    setisReceipt(!isReceipt);
  };
  //#endregion

  //#region  View Methods
  const View_posinvoice = async (vouchid) => {
    Pre_Loading(dispatch, true);
    setisPosView(!isPosView);
    const res = await API_FETCH(
      `}POS/View_VoucherDetails/${vouchid}/93/${Number(_AuthParems.Branch)}/${
        _AuthParems.DB
      }`,

      dispatch
    );
    if (res) {
      // Add To Cart Not Allowed When Modify Bill
      setisAddtoCart(true);
      let _EntHT = res.objEntHT[0];
      let _EntService = res.EntService;
      let summary = res.objEntSummary;
      dispatch(
        Add_party({
          iPartyId: _EntHT.iPartyId,
          iTaxRelId: _EntHT.iTaxTypeId,
          strPartyName: res.objPartyList[0],
          strPartyCode: "",
          strAliasName: "",
          strAdd1: "",
          strAdd2: "",
          strAdd3: "",
          strAdd4: "",
          strPincode: "",
          iState: 0,
          iCountry: 0,
          strMobile1: res.objPartyList[2],
          strMobile2: "",
          strPhone1: "",
          strPhone2: "",
          strEmail: "",
          strGSTNo: res.objPartyList[3],
          strTinNo: "",
          strShippAdd: _EntHT.strShippingAdd,
          strPartyAdd: res.objPartyList[1],
          dOutstandingAmt: 0,
          dRoyaltyPoint: Number(_EntHT.dRoyaltyPoint),
        })
      );
      dispatch(
        Add_CommonDefault({
          iScrId: 93,
          strScrType: "Sales",
          iVouchId: _EntHT.iPosInvId,
          strVouchNo: _EntHT.strVouchNo,
          strVouchDate: _EntHT.strVouchDate,
          iBillTypeId: _EntHT.iBillTypeId,
          isDisablebtn:
            _EntHT.bCancelStatus === true || res.objCanAccess === false
              ? true
              : false,
          iTaxCalcOnId: _EntHT.iTaxCalcOnId,
          isDisabled: true,
          iCartInvId: 0,
        })
      );
      dispatch(
        Add_TransHtparem({
          //#region  Voucher Tab Parem
          strTransNo: _EntHT.strTransNo,
          strPartyDCVouch: _EntHT.strPartyDCVouch,
          strConvertDCVouch: _EntHT.strConvertDCVouch,
          //#endregion

          //#region  Reference Vouchers Tap Parem
          strRefNum1: _EntHT.strRefNum1,
          dpRefNum1Date: _EntHT.dpRefNum1Date,
          strRefNum2: _EntHT.strRefNum2,
          dpRefNum2Date: _EntHT.dpRefNum2Date,
          strRefNum3: _EntHT.strRefNum3,
          dpRefNum3Date: _EntHT.dpRefNum3Date,
          strRefNum4: _EntHT.strRefNum4,
          dpRefNum4Date: _EntHT.dpRefNum4Date,
          //#endregion

          //#region  Validity Tap Parem
          iValidity: _EntHT.strValidity,
          dpValDateUpto: _EntHT.dpValDateUpto,
          iPayTerm: _EntHT.strPayTerm,
          dpPayTermDateUpto: _EntHT.dpPayTermDateUpto,
          iDeliTerm: _EntHT.strDeliTerm,
          dpDelTermDateUpto: _EntHT.dpDelTermDateUpto,
          //#endregion

          //#region  Delivery Mode Parem
          iDMId: _EntHT.iDMId,
          strAgent: _EntHT.strAgent,
          strReference: _EntHT.strReference,
          strDestination: _EntHT.strDestination,
          strEComGstNo: _EntHT.strEComGstNo,
          //#endregion

          //#region  Transport Tap Parem
          strTransport: _EntHT.strTransport,
          strVehicle: _EntHT.strVehicle,
          strDespatch: _EntHT.strDespatch,
          dpDespatchDate: _EntHT.dpDespatchDate,
          strLRNNumber: _EntHT.strLRNNumber,
          dpLRNoDate: _EntHT.dpLRNoDate,
          //#endregion

          //#region  Remark Tap Parem
          strRemark1: _EntHT.strRemark1,
          strRemark2: _EntHT.strRemark2,
          strRemark3: _EntHT.strRemark3,
          strRemark4: _EntHT.strRemark4,
          //#endregion
        })
      );
      dispatch(
        Add_Transdata({
          EntTransDT: Update_TransDt(res.objEntDT, _EntHT.iTaxTypeId),
          EntTransPenConDT: [],
          EntTransTax: UpdateTaxGroup(res.objEntTax),
          EntTransAL: res.objEntALDT,
          EntTransDTHelp: res.objEntDTHelp,
        })
      );
      dispatch(
        Add_TransSummary({
          dnetAmt: summary.netAmt,
          dqty: summary.qty,
          dtotalDiscount: summary.totalDiscount,
          dgoodsValue: summary.goodsValue,
          dtotalTax: summary.totalTax,
          dtotalAddLess: summary.totalAddLess,
          dtotalTCSTax: summary.totalTCSTax,
          droundOff: summary.roundOff,
          dBillDisVal: _EntHT.dTBillDiscValue,
        })
      );
      dispatch(
        add_BillDiscount({
          dBillDisPer: _EntHT.dTBillDiscPer,
          dBillDisAmt: _EntHT.dTBillDiscAmt,
          dBillDisValue: _EntHT.dTBillDiscValue,
          dALBillDisPer: _EntHT.dTAddLessDiscPer,
          dALBillDisAmt: _EntHT.dTAddLessDiscAmt,
          dALBillDisValue: _EntHT.dTAddLessDiscValue,
          iALBillDiscId: _EntHT.iAddLessBillDiscId,
        })
      );
      dispatch(
        setothercharges({
          SerId: 0,
          ServiceName: "",
          SerCode: "",
          SACcode: "",
          bUpdateStatus: false,
          iRowIndex: 0,
          RateWithoutTax: (0).toFixed(2),
          RateWithTax: (0).toFixed(2),
          dTaxPer: 0,
          EntALTax: _EntService._EntServiceTax,
        })
      );
    }
  };
  const Update_TransDt = (EntPro, iTaxRelId) => {
    let results = [];
    EntPro.forEach((item, Index) => {
      let objItem = {
        ProId: item.proId,
        ItemGrpId: item.itemGrpId,
        ProCode: item.proCode,
        ProName: item.proName,
        CommodityCode: item.commodityCode,
        AttValues: item.attValues,
        UnitId: item.unitId,
        UnitName: item.unitName,
        RateWithoutTax: item.rateWithoutTax,
        RateWithTax: item.rateWithTax,
        dMinMargin: item.minMargin,
        ItemDiscPer: item.itemDiscPer,
        ItemDiscAmt: item.itemDiscAmt,
        ItemDiscValue: item.itemDiscValue,
        CashDiscPer: item.cashDiscPer,
        CashDiscAmt: item.cashDiscAmt,
        CashDiscValue: item.cashDiscValue,
        BillDiscValue: item.billDiscValue,
        Qty: item.qty,
        BatchNo: item.batchNo,
        ManufactureDate: item.manufactureDate,
        ExpiryDate: item.expiryDate,
        iTaxRelId: iTaxRelId,
        TaxPer: item.taxPer,
        iSGST: item.sgstId,
        iCGST: item.cgstId,
        iIGST: item.igstId,
        iTCS: item.tcsId,
        strSGST: item.sgstDesc,
        strCGST: item.cgstDesc,
        strIGST: item.igstDesc,
        strTCS: item.tcsDesc,
        strSGSTPer: item.sgstPer,
        strCGSTPer: item.cgstPer,
        strIGSTPer: item.igstPer,
        strTCSPer: item.tcstPer,
        TaxLvl: item.taxLvl,
        taxableOn: item.taxableOn,
        TcsTaxRelId: item.tcsTaxRelId,
        totalVal: 0,
        iIndex: Index,
        IsNewItem: item.isNewItem,
        GoodsValue: item.goodsValue,
        TaxableAmt: item.taxableAmt,
        TaxAmt: item.taxAmt,
        ItemAmt: item.itemAmt,
        FQty: item.fQty,
        Value1: item.value1,
        Value2: item.value2,
        Value3: item.value3,
        TotSqureValue: item.totSqureValue,
        ConstantValue: item.constantValue,
        FinalValue: item.finalValue,
        SNo: item.sNo,
        SACCode: item.sacCode,
        YearId: item.yearId,
        RGName: item.rgName,
        RGId: item.rgId,
        JobId: item.jobId,
        JobCode: item.jobCode,
        JobName: item.jobName,
        SerId: item.serId,
        SerCode: item.serCode,
        SerName: item.serName,
        Remark1: item.remark1,
        Remark2: item.remark2,
        Remark3: item.remark3,
        Remark4: item.remark4,
        RowStatus: item.rowStatus,
        IsSerProduct: item.isSerProduct,
        ConScrId: item.conScrId,
        InvDTId: item.invDTId,
        IsConvertable: item.isConvertable,
        ConvertedQty: item.convertedQty,
        RowId: item.rowId,
        Reason: item.reason,
        BranchId: item.branchId,
        DecimalQty: item.decimalQty,
        StockMaintain: item.stockMaintain,
      };
      results.push(objItem);
    });
    return results;
  };
  const UpdateTaxGroup = (arr) => {
    let result = [];
    arr.forEach((tax, Index) => {
      const objtax = {
        TaxDesc: tax.taxDesc,
        TaxableAmt: tax.taxableAmt,
        TaxAmt: tax.taxAmt,
        TaxId: tax.taxId,
        TaxPer: tax.taxPer,
        Level: tax.level,
      };
      result.push(objtax);
    });

    return result;
  };
  //#endregion

  //#region Common Methods

  const Update_PaymentMode = (PayId) => {
    dispatch(Update_BillType(PayId));
    let index = 0;
    switch (PayId) {
      case 6:
        index = 1;
        break;
      case 4:
        index = 2;
        break;
      case 7:
        index = 3;
        break;
      case 8:
        index = 4;
        break;
      default:
        index = 0;
        break;
    }
    $(`.Payment .icon:eq(${index})`).focus();
    $(`.Payment .icon:eq(${index})`)
      .toggleClass("active")
      .siblings()
      .removeClass("active");
  };
  const validatename = (columnName) => {
    switch (columnName) {
      case "proName":
      case "unitName":
      case "attValues":
      case "taxPer":
        return false;
      default:
        return true;
    }
  };
  const validatedatatype = (columnName, DataType) => {
    switch (columnName) {
      case "manufactureDate":
      case "expiryDate":
        return "Date";
      default:
        if (DataType === "Decimal") return "number";
        else return "text";
    }
  };
  const converttodecimal = (type, value, len) => {
    switch (type) {
      case "Decimal":
        return Number(value).toFixed(len);
      case "Int32":
        return Number(value);
      default:
        return value;
    }
  };
  const numtodecimal = (num) => {
    return Number(num).toFixed(2);
  };
  const short_cut_keypress = async (e) => {
    switch (e.key) {
      case "F1":
        e.preventDefault();
        $("#pro-search").focus();
        $("#pro-search").select();
        dispatch(ShowHide_Popup("IsProSearch"));
        break;
      case "F2":
        e.preventDefault();
        await dispatch(ShowHide_Popup("IsPOSCustomer"));
        break;
      case "F3":
        e.preventDefault();
        dispatch(ShowHide_Popup("IsSession"));
        break;
      case "F4":
        e.preventDefault();
        var index = $("#btn-paymt .icon.active").index();
        switch (index) {
          case 1:
            Update_PaymentMode(6);
            break;
          case 2:
            Update_PaymentMode(4);
            break;
          case 3:
            Update_PaymentMode(7);
            break;
          case 4:
            Update_PaymentMode(8);
            break;
          default:
            Update_PaymentMode(2);
            break;
        }
        break;
      case "F5":
        e.preventDefault();
        $(".product-table tbody tr:eq(0) td input:eq(0)").focus();
        $(".product-table tbody tr:eq(0) td input:eq(0)").select();
        break;
      case "F6":
        e.preventDefault();
        setisPosView(true);
        break;
      case "F8":
        e.preventDefault();
        setisDiscount(true);
        break;
      case "F9":
        e.preventDefault();
        setisAddNotes(true);
        break;
      default:
        break;
    }
    if (e.ctrlKey && e.key === "s") {
      e.preventDefault();
      buttonsave.current.click();
    }
    if (e.ctrlKey && e.key === "h" && !isAddtoCart) {
      e.preventDefault();
      buttoncard.current.click();
    }
    if (e.ctrlKey && e.key === "e" && !isAddtoCart) {
      e.preventDefault();
      buttonclear.current.click();
    }
    if (e.ctrlKey && e.key === "a") {
      e.preventDefault();
      $("#pos-menu").addClass("active");
    }
    if (e.ctrlKey && e.shiftKey) {
      e.preventDefault();
      window.location.reload();
    }
  };
  const table_keypress = (e, tabindex, rowIndex) => {
    const table = tableRef.current;
    const row = table.rows[1];
    const inputElements = row.querySelectorAll("input");
    let Enttabindex = [];
    for (let i = 0; i < inputElements.length; i++) {
      Enttabindex.push(inputElements[i].tabIndex);
    }
    let ProCount = _DT.arrEntDT.length;
    const strQuery = ".product-table tbody tr:nth-child(";
    const maxindex = Math.max(...Enttabindex);
    const minindex = Math.min(...Enttabindex);
    switch (e.key) {
      case "ArrowUp":
        rowIndex = rowIndex + 1;
        Enttabindex = Enttabindex.sort((a, b) => b - a);
        const PrevInput = Enttabindex.filter((dt) => dt < tabindex);
        if (e.shiftKey) {
          if (rowIndex === 1) {
            $(`${strQuery}${ProCount}) input[tabindex="${tabindex}"]`)
              .focus()
              .select();
          } else {
            $(`${strQuery}${rowIndex - 1}) input[tabindex="${tabindex}"]`)
              .focus()
              .select();
          }
        } else {
          if (minindex === tabindex && rowIndex === 1) {
            $(`${strQuery}${ProCount}) input[tabindex="${maxindex}"]`)
              .focus()
              .select();
          } else if (minindex === tabindex && rowIndex !== 1) {
            $(`${strQuery}${rowIndex - 1}) input[tabindex="${maxindex}"]`)
              .focus()
              .select();
          } else {
            $(`${strQuery}${rowIndex}) input[tabindex="${PrevInput[0]}"]`)
              .focus()
              .select();
          }
        }
        break;
      case "ArrowDown":
        rowIndex = rowIndex + 1;
        Enttabindex = Enttabindex.sort((a, b) => a - b);
        const nextInput = Enttabindex.filter((dt) => dt > tabindex);
        if (e.shiftKey) {
          if (rowIndex === ProCount) {
            $(`${strQuery}1) input[tabindex="${tabindex}"]`).focus().select();
          } else {
            $(`${strQuery}${rowIndex + 1}) input[tabindex="${tabindex}"]`)
              .focus()
              .select();
          }
        } else {
          if (maxindex === tabindex && rowIndex === ProCount) {
            $(`${strQuery}1) input[tabindex="${minindex}"]`).focus().select();
          } else if (maxindex === tabindex && rowIndex !== ProCount) {
            $(`${strQuery}${rowIndex + 1}) input[tabindex="${minindex}"]`)
              .focus()
              .select();
          } else {
            $(`${strQuery}${rowIndex}) input[tabindex="${nextInput[0]}"]`)
              .focus()
              .select();
          }
        }
        break;
      case "Delete":
        if (ProCount > 0) Remove_tableProduct(rowIndex);
        break;
      case "F1":
        dispatch(ShowHide_Popup("IsProRemark"));
        break;
      case "F3":
        dispatch(ShowHide_Popup("IsProCal"));
        break;
      default:
        break;
    }
  };
  const textalign = ["left", "right", "center "];

  //#endregion

  //#region  Product Methods
  const Add_NewProduct = async (Product, BatchPro) => {
    try {
      await Pre_Loading(dispatch, true);
      const ProInfo = Product.ProInfo ? Product.ProInfo[0] : Product;
      if (ProInfo.strProName.length === 0) {
        $("#txtproName").on("focus");
        await Show_Message(dispatch, "Please Select Product", "info");
      } else {
        const parems = {
          bUpdateStatus: false,
          iScrId: _Common.iScrId,
          iTaxRelId: _HT.iTaxRelId,
          iPartyId: _HT.iPartyId,
          iVouchId: _HT.iVouchId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iProId: ProInfo.iProId,
          strJobId: "0",
          iSerId: 0,
          iRowIndex: 0,
          iUnitId: Number(ProInfo.iUnitId),
          dQty: Number(ProInfo.dQty),
          FQty: Number(ProInfo.dFQty),
          dPriceRate: Number(_Product.dRate),
          dPriceRateWithTax: Number(_Product.dRatewithTax),
          dDiscountPer: _Product.isItemDiscPer ? Number(_Product.dItemDisc) : 0,
          dDiscountAmt: _Product.isItemDiscPer ? 0 : Number(_Product.dItemDisc),
          dCashDiscountPer:
            _Product.isCashDiscPer === false ? Number(_Product.dCashDisc) : 0,
          dCashDiscountAmt:
            _Product.isCashDiscPer === true ? Number(_Product.dCashDisc) : 0,
          BatchNo: BatchPro ? BatchPro.batchNo : ProInfo.batchNo,
          ManufactureDate: BatchPro ? BatchPro.mftDate : ProInfo.mftDate,
          ExpiryDate: BatchPro ? BatchPro.expDate : ProInfo.expDate,
          isComposPro: Number(ProInfo.isComposPro),
          isBatchPro: Number(ProInfo.isBatchPro),
          objBaseTransDT: _DT.arrEntDT,
          objItemRemark: _Product.Remarks,
          objRootCalc: _Product.Calculation,
          objPenCon: _DT.arrEntPenCon,
          objEntAddLess: _DT.arrEntAL,
          objEntConRow: [],
          objEntTax: _DT.arrEntTax,
          strSearch: "",
          strColumn: "",
          Reason: "",
          DimAttDTId: "0",
          iALBillDiscId: _Common.iALBillDiscId,
          strSetCurrentStatus: _Common.strCurrentStatus,
          dAddLessDiscValue: Number(_Disc.dALBillDisValue),
          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dCouponDiscPer: Number(_Disc.dCouponDiscPer),
          dRewardPointper: Number(_Disc.dRewardPointper),
          dCouponDiscAmt: Number(_Disc.dCouponDiscAmt),
          dRewardPointAmt: Number(_Disc.dRewardPointAmt),
          iYearId: Number(_AuthParems.Year),
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
          iUserid: Number(_AuthParems.User),
        };
        const res = await API_POST(
          `ProductTrans/AddUpd_Product/`,
          parems,
          dispatch
        );
        if (res) {
          await dispatch(AddProduct(res));
          $(`.product-table tbody tr:eq(${res.EntDT.length - 1}) input:eq(0)`)
            .focus()
            .select();
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, true);
    }
  };
  const View_ProductUnit = async (ProInfo, Index) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `ProductTrans/View_ProductUnit/${ProInfo.proId}/${_Common.strScrType}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        await dispatch(
          Modify_Product({
            ProInfo: ProInfo,
            Unit: res.ProUnit,
            Index: Index,
          })
        );
        await dispatch(ShowHide_Popup("IsPosPro"));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Remove_tableProduct = async (iIndex) => {
    try {
      const res = await API_POST(
        `ProductTrans/Delete_tableProduct/`,
        {
          iRowIndex: iIndex,
          iScrId: _Common.iScrId,
          iALBillDiscId: _Common.iALBillDiscId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,

          iTaxRelId: _HT.iTaxRelId,
          iPartyId: _HT.iPartyId,
          iVouchId: _HT.iVouchId,

          iProId: 0,
          iUnitId: 0,
          dQty: 0,
          dPriceRate: 0,
          dDiscountPer: 0,
          dDiscountAmt: 0,

          strSearch: "",
          strColumn: "",
          strReason: "",
          strJobId: "0",
          strSetCurrentStatus: "",

          strProAttDTId: "",
          strStoAttDTId: "",
          strTraAttDTId: "",
          objItemRemark: {},
          objRootCalc: {},

          dAddLessDiscValue: _Disc.dALBillDisValue,
          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dCouponDiscPer: Number(_Disc.dCouponDiscPer),
          dRewardPointper: Number(_Disc.dRewardPointper),
          dCouponDiscAmt: Number(_Disc.dCouponDiscAmt),
          dRewardPointAmt: Number(_Disc.dRewardPointAmt),

          objPenCon: _DT.arrEntPenCon,
          objBaseTransDT: _DT.arrEntDT,
          objEntAddLess: _DT.arrEntAL,
          objEntConRow: [],
          objEntTax: _DT.arrEntTax,

          iYearId: Number(_AuthParems.Year),
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
          iUserId: Number(_AuthParems.User),
        },
        dispatch
      );
      if (res) {
        dispatch(Remove_product(res));
      }
    } catch (e) {
      await Show_Message(dispatch, e.message, "error");
    }
  };

  //#endregion

  return (
    <div className="container-pos">
      <div className="pos-wrapper">
        <div className="possection">
          {!_Common.IsTabletMode && (
            <div className="pos-itemlist">
              <table className="product-table" ref={tableRef}>
                <thead>
                  <tr>
                    {_Common.arrtransper.map((dt, Index) => (
                      <th
                        key={Index}
                        style={{
                          minWidth: dt.colLen + "px",
                          maxWidth: dt.colLen + "px",
                        }}
                      >
                        {dt.aliasName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {_DT.arrEntDT.map((product, trIndex) => (
                    <tr
                      key={trIndex}
                      onClick={(e) => {
                        View_ProductUnit(product, trIndex);
                      }}
                      onKeyDown={async (e) => {
                        await e.preventDefault();
                        if (e.key === "Enter") {
                          View_ProductUnit(product, trIndex);
                        } else table_keypress(e, trIndex);
                      }}
                    >
                      {_Common.arrtransper.map((dt, tdIndex) => (
                        <td
                          key={tdIndex}
                          style={{
                            minWidth: dt.colLen + "px",
                            maxWidth: dt.colLen + "px",
                            whiteSpace: dt.isWordwrap ? "wrap" : "nowrap",
                            textAlign: textalign[dt.textAlign - 1],
                          }}
                        >
                          {dt.columnName === "proName" && (
                            <>
                              {`${product.proName}`}
                              <br />
                              <span>
                                GST - {numtodecimal(product.taxPer)} % | Unit :
                                {product.unitName}
                              </span>
                            </>
                          )}
                          {validatename(dt.columnName) === true && (
                            <>
                              {converttodecimal(
                                dt.datatype,
                                product[dt.columnName],
                                2
                              )}
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {_Common.IsTabletMode && (
            <>
              {!isConvertReturn && (
                <div className="search-product">
                  <div className="search-box">
                    <input
                      type="text"
                      placeholder="Search Product or Scan Barcode Press F1 to Search"
                      defaultValue={strSearch}
                      onClick={(e) => dispatch(ShowHide_Popup("IsProSearch"))}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                          $("[tabindex=0]").focus();
                        }
                      }}
                      autoFocus
                      autoComplete="off"
                      id="pro-search"
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
              )}
              {isConvertReturn && (
                <h3 className="return-product">Product Return</h3>
              )}
              <MobileProductSearch />
            </>
          )}
          <div className="pos-footer">
            <div className="info">
              <h2 className="infoheader">LAST BILL INFO</h2>
              <ul>
                <li>
                  <p>Inv No :</p>
                  <p>{_Common.LastInvNo}</p>
                </li>
                <li>
                  <p>Paid Amt : </p>
                  <p>
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "INR",
                    }).format(_Common.dLBPaidAmt)}
                  </p>
                </li>
                <li>
                  <p>Pay Type : </p>
                  <p>{_Common.LasBillType}</p>
                </li>
                <li>
                  <p>Net Amt : </p>
                  <p>
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "INR",
                    }).format(_Common.dLBNetAmt)}
                  </p>
                </li>
                <li>
                  <p>Balance : </p>
                  <p>
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "INR",
                    }).format(_Common.dLBBalAmt)}
                  </p>
                </li>
                <li>
                  <p>Inv Date : </p>
                  <p>{_Common.LastInvDate}</p>
                </li>
              </ul>
            </div>
            <div className="pos-btn-grp">
              <button
                className="btn-fabgreen"
                disabled={_DT.arrEntDT.length > 0 ? false : true}
                onClick={(e) => setisOtherCharges(!isOtherCharges)}
              >
                Other Charges
              </button>
              <button
                className="btn-fabgreen"
                disabled={_DT.arrEntDT.length > 0 ? false : true}
                onClick={(e) => setisDiscount(!isDiscount)}
              >
                Discount
              </button>
              <button
                className="btn-fabgreen"
                disabled={_DT.arrEntDT.length > 0 ? false : true}
                onClick={(e) => setisDelivery(!isDelivery)}
              >
                Delivery
              </button>
              <button
                className="btn-fabgreen"
                disabled={_DT.arrEntDT.length > 0 ? false : true}
                onClick={(e) => setisAddNotes(!isAddNotes)}
              >
                Notes
              </button>
              <button
                className="btn-fabgreen"
                disabled={Number(_HT.iVouchId) === 0 ? true : false}
                onClick={(e) => {
                  if (Number(_HT.iVouchId) === 0) {
                    Show_Message(
                      dispatch,
                      "Please Select Voucher to Delete!",
                      "info"
                    );
                  } else {
                    Ask_Question(
                      dispatch,
                      Delete_PosInvoice,
                      `Do You Want to Delete Invoice ?`,
                      true
                    );
                  }
                }}
              >
                Delete&nbsp;<i className="bx bx-trash-alt"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => setisReturn(!isReturn)}
              >
                Return
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => setisPosView(!isPosView)}
              >
                View &nbsp;<i className="fas fa-eye"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="possection">
          <div className="profile_view">
            <h1>{_Common.strScrName}</h1>
            <span className="profile-icon">
              <i
                className="bx bx-cog"
                onClick={(e) => dispatch(ShowHide_Popup("IsRightMenu"))}
              ></i>
            </span>
          </div>
          <div className="card party">
            <h2>Name : {_Customer.PartyName}</h2>
            <h2>Mobile : {_Customer.Mobile}</h2>
            {!isConvertReturn && (
              <span onClick={(e) => dispatch(ShowHide_Popup("IsPOSCustomer"))}>
                <i className="fa-solid fa-user-plus"></i>
              </span>
            )}
          </div>
          <div className="card staff">
            <h2>Billing Staff</h2>
            <div className="st">
              <i
                className="fa-solid fa-user-tie"
                onClick={(e) => dispatch(ShowHide_Popup("IsEmployee"))}
              ></i>
              <sub>+</sub>
            </div>
            <div className="text">
              <label>
                {_Employee.strEmployeeName} <br />
                <span>{_Employee.strEmpRole}</span>
              </label>
              <label>Reward Point : {_Employee.dRewardPoint}</label>
            </div>
          </div>
          <div className="card sum">
            <h2>Summary</h2>
            <div className="text">
              <p>Sub Total</p>
              <p>
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "INR",
                }).format(Number(_Sum.GoodsValue))}
              </p>
            </div>
            <div className="text">
              <p>GST</p>
              <p>
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "INR",
                }).format(Number(_Sum.Tax))}
              </p>
            </div>
            <div className="text">
              <p>Discount</p>
              <p>
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "INR",
                }).format(Number(_Sum.Discount))}
              </p>
            </div>
            {_Disc.strCoupon.length > 0 && (
              <div className="text">
                <p
                  style={{
                    width: "100%",
                    color: "green",
                    fontSize: "12px",
                    textAlign: "right",
                  }}
                >
                  {_Disc.dCouponDiscPer + _Disc.dCouponDiscAmt}% Coupon Discount
                  Applied
                </p>
              </div>
            )}
            <div className="text">
              <p>Other Charges</p>
              <p>
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "INR",
                }).format(Number(_Sum.AddLess))}
              </p>
            </div>
            <div className="text">
              <p>Round Off</p>
              <p>
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "INR",
                }).format(Number(_Sum.RoundOff))}
              </p>
            </div>
            <div className="text">
              <p>
                TOTAL
                <br />
                <span>
                  (Item : {_DT.arrEntDT.length},Qty : {_Sum.Qty})
                </span>
              </p>
              <p>
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "INR",
                }).format(Number(_Sum.NetAmt))}
              </p>
            </div>
          </div>
          <div className="card">
            <h2>Payment</h2>
            <div className="Payment" id="btn-paymt">
              <button
                onClick={(e) => Update_PaymentMode(2)}
                className="icon active"
                onKeyDown={(e) => {
                  if (e.key === "ArrowLeft") Update_PaymentMode(8);
                  if (e.key === "ArrowRight") Update_PaymentMode(6);
                }}
              >
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </button>
              <button
                onClick={(e) => Update_PaymentMode(6)}
                className="icon"
                onKeyDown={(e) => {
                  if (e.key === "ArrowLeft") Update_PaymentMode(2);
                  if (e.key === "ArrowRight") Update_PaymentMode(4);
                }}
              >
                <i className="fa-regular fa-credit-card"></i>
              </button>
              <button
                className="icon"
                onClick={(e) => Update_PaymentMode(4)}
                onKeyDown={(e) => {
                  if (e.key === "ArrowLeft") Update_PaymentMode(6);
                  if (e.key === "ArrowRight") Update_PaymentMode(7);
                }}
              >
                <i className="fa-solid fa-building-columns"></i>
              </button>
              <button
                className="icon"
                onClick={(e) => Update_PaymentMode(7)}
                onKeyDown={(e) => {
                  if (e.key === "ArrowLeft") Update_PaymentMode(4);
                  if (e.key === "ArrowRight") Update_PaymentMode(8);
                }}
              >
                <i className="fa-solid fa-wallet"></i>
              </button>
              <button
                className="icon"
                onClick={(e) => Update_PaymentMode(8)}
                onKeyDown={(e) => {
                  if (e.key === "ArrowLeft") Update_PaymentMode(7);
                  if (e.key === "ArrowRight") Update_PaymentMode(2);
                }}
              >
                <p>UPI</p>
              </button>
            </div>
          </div>
          <div className="pos-sum-btn">
            <button
              className="btn-fabgreen"
              ref={buttonclear}
              onClick={CancelInvoice}
            >
              <span>
                <i className="fa-solid fa-eraser mr-2"></i> Clear Invoice
              </span>
            </button>
            <button
              className="btn-fabgreen"
              ref={buttonsave}
              disabled={_DT.arrEntDT.length > 0 ? false : true}
              onClick={(e) => {
                if (_DT.arrEntDT.length === 0) {
                  Show_Message(dispatch, "Atleast one Item Required", "info");
                } else if (_HT.iBillTypeId === 0) {
                  Show_Message(dispatch, "Bill Type is Required ", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    Show_Receipt,
                    `Do You Want to Save "Invoice"?`
                  );
                }
              }}
            >
              <span>
                <i className="fa-regular fa-floppy-disk mr-2"></i>
                {isConvertReturn ? "Save Return" : "Save Invoice"}
              </span>
            </button>
          </div>
        </div>
        {_Common.IsRightMenu && <Posrightmenu />}
        {_Common.IsPOSCustomer && <CustomerSearch />}
        {_Common.IsEmployee && <StaffProfile />}
        {_Common.IsPosPro && <PosproductInput />}
        {_Common.IsProRemark && <ItemRemarks />}
        {_Common.IsProCal && <RootCalculation />}
        {_Common.IsSession && (
          <Sessionopenclose SessionOpen={Load_PosInvoice} />
        )}
        {_Common.IsProSearch && (
          <TransProductSearch View_Product={Add_NewProduct} />
        )}
        {_Common.IsBatchPro && (
          <BatchProductSearch View_Product={Add_NewProduct} />
        )}
        {_Common.IsAttPro && (
          <AttributeProductSearch View_Product={Add_NewProduct} />
        )}
        {isReceipt && (
          <Receipt
            Close={(e) => setisReceipt(!isReceipt)}
            PayMode={Number(_HT.iBillTypeId)}
            NetAmt={Number(_Sum.NetAmt)}
            Save_Invoice={(e) => Save_Invoice(e)}
          />
        )}
        {isDelivery && (
          <DeliveryOption Close={(e) => setisDelivery(!isDelivery)} />
        )}
        {isPosView && (
          <PosView
            Close={(e) => setisPosView(!isPosView)}
            ViewInvoice={View_posinvoice}
            Return={(PosInvId) => {
              setisPosView(!isPosView);
              setisProReturn(!isProReturn);
              setiVouchId(PosInvId);
            }}
          />
        )}

        {isReturn && (
          <POSReturn
            Close={(e) => setisReturn(!isReturn)}
            View_Return={() => {
              setisReturn(!isReturn);
              setisConvertReturn(!isConvertReturn);
            }}
          />
        )}
        {isProReturn && (
          <ProductReturn
            Close={(e) => {
              setiVouchId(0);
              setisProReturn(!isProReturn);
            }}
            VouchId={iVouchId}
            View_Return={(arrperm) => {
              setiVouchId(0);
              setisConvertReturn(!isConvertReturn);
              setisProReturn(!isProReturn);
              setarrEntPermission(arrperm);
            }}
          />
        )}
        {isOtherCharges && (
          <OtherCharges Close={(e) => setisOtherCharges(!isOtherCharges)} />
        )}
        {isDiscount && <Discount Close={(e) => setisDiscount(!isDiscount)} />}
        {isAddNotes && <Notes Close={(e) => setisAddNotes(!isAddNotes)} />}
        <Messagedialogbox />
      </div>
    </div>
  );
}
export default Pointofsales;
