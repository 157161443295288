import { useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { API_POST } from "../General/Utility";
function BOMProductSearch(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [isOption, setisOption] = useState(false);
  const [arrProduct, setarrProduct] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  useEffect(() => {}, [props]);
  const Search_Product = async (strtxt) => {
    setstrSearch(strtxt);
    const objSearch = {
      iScrId: props.iScrId,
      strColName: "All",
      strSearch: strtxt,
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST("BOM/Search_Product/", objSearch, dispatch);
    if (res) {
      setarrProduct(res.objEntDT);
    }
  };
  return (
    <div className="custom-select" onMouseLeave={(e) => setisOption(false)}>
      <input
        className="form-control"
        type="text"
        placeholder="Select Product"
        defaultValue={props.strProName}
        onClick={(e) => {
          setisOption(true);
          Search_Product("");
        }}
      />
      <i className="fa-solid fa-caret-down"></i>
      {isOption && (
        <div className="custom-option">
          <div className="search-option">
            <input
              className="form-control"
              type="text"
              autoFocus
              placeholder="Search Party"
              value={strSearch}
              onChange={(e) => Search_Product(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "ArrowDown") {
                  $(".option-list [tabindex=0]").focus();
                } else if (e.key === "Enter") {
                  setisOption(!isOption);
                  props.View_Product(arrProduct[0].proId);
                }
              }}
            />
            {arrProduct.length === 0 && <span> Product Not Found</span>}
          </div>
          <div className="option-list">
            {arrProduct.map((product, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onClick={(e) => {
                  setisOption(!isOption);
                  props.View_Product(product.proId);
                }}
                onKeyDown={(e) => {
                  let arrlength = arrProduct.length - 1;
                  if (e.key === "ArrowDown") {
                    if (arrlength === Index)
                      $(`.option-list [tabindex=0]`).focus();
                    else $(`.option-list [tabindex=${Index + 1}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.option-list [tabindex=${arrlength}]`).focus();
                    else $(`.option-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    setisOption(!isOption);
                    props.View_Product(product.proId);
                  }
                }}
                className="pro-con"
              >
                <div className="sub-con ">
                  <label>{product.proName} </label>
                  <label style={{ color: "blue" }}>{product.attValues}</label>
                </div>
                <div className="sub-con">
                  <label>Stock In Hand </label>
                  <label
                    style={{
                      color: product.tQty >= 0 ? "gray" : "red",
                      fontWeight: "600",
                    }}
                  >
                    {product.tQty} {product.unitName}
                  </label>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
export default BOMProductSearch;
