import React, { useEffect, useState } from "react";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "../General/Utility";

function LineCopy(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _parem = useSelector((state) => state.SelectOption.parems);
  const [strLineTemplateName, setstrLineTemplateName] = useState("");
  const [iTemplateId, setiTemplateId] = useState(0);
  const [ListSourceTemp, setListSourceTemp] = useState([]);

  useEffect(() => {
    Load_LineTemplate();
  }, []);
  const Load_LineTemplate = async () => {
    try {
      Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        "PrintDesing/Load_OriginalLinetemplate/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        Pre_Loading(dispatch, false);
        setListSourceTemp(res);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "info");
    }
  };
  const CheckTemplate_Exist = async (strtempName) => {
    try {
      const objSearch = {
        strSearch: strtempName,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      setstrLineTemplateName(strtempName);
      const res = await API_POST(
        "PrintDesing/CheckLineTemplateExist/",
        objSearch,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "info");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Copy_LineTemplate = async () => {
    try {
      const objSearch = {
        itemId: iTemplateId,
        strColName: strLineTemplateName,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        "PrintDesing/Copy_Linetemplate/",
        objSearch,
        dispatch
      );
      if (res) {
        setstrLineTemplateName("");
        setiTemplateId(0);
        props.Close();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content" style={{ maxWidth: "300px" }}>
        <div className="popup-header">
          <h5>Line Template Copy </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row p-2">
            <label>Source Template</label>
            <CustomizedSelectoption
              optionparem="isoptionShow20"
              showoption={_parem.isoptionShow20}
              placeholder="Select Template"
              select_value={(val) => setiTemplateId(Number(val))}
              btnname=""
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={iTemplateId}
              displayName="strLineTempName"
              disvalue="iLineTempId"
              arrydata={ListSourceTemp}
              EmptVal="Template Name"
            />
            <label>Template Name</label>
            <input
              type="text"
              aria-label="First name"
              className="form-control"
              placeholder="Enter Template Name"
              value={strLineTemplateName}
              onChange={(e) => CheckTemplate_Exist(e.target.value)}
            />
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                if (strLineTemplateName.length === 0) {
                  Show_Message(
                    dispatch,
                    "Please Enter line Tempalte Name",
                    "info"
                  );
                } else if (Number(iTemplateId) === 0) {
                  Show_Message(
                    dispatch,
                    "Please Select Source Tempalte",
                    "info"
                  );
                } else {
                  Ask_Question(
                    dispatch,
                    Copy_LineTemplate,
                    "Do You Want to Copy Line Template?"
                  );
                }
              }}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LineCopy;
