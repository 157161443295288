import React, { useEffect } from "react";
import { useState } from "react";
import Organization from "../Image/Oraganization.png";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import { API_POST, API_PUT } from "../General/Utility";
import {
  ShowHide_Popup,
  Update_POStemplate,
} from "../features/Transactionstates";

export default function Posrightmenu() {
  const [dCashout, setdCashout] = useState((0).toFixed(2));
  const [dCashIn, setdCashIn] = useState((0).toFixed(2));
  const [strOutReason, setstrOutReason] = useState("");
  const [strInReason, setstrInReason] = useState("");
  const [Timer, setTimer] = useState("");

  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _Employee = useSelector((store) => store.Transstates.Employee);

  useEffect(() => {
    short_cut_keypress();
    diffDateAndToString(_Common.TDateTime, _Common.TStartTime);
  }, []);
  const LogoutClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };
  const Clear_Click = () => {
    setdCashout(0);
    setdCashIn(0);
    setstrInReason("");
    setstrOutReason("");
  };
  const CashOut_Click = async () => {
    try {
      const objEntSession = {
        TransId: 0,
        SessionId: Number(localStorage.Session),
        CashOut: Number(dCashout),
        Remark: strOutReason,
        EmpId: Number(_Employee.iEmpId),
        iUserId: Number(_AuthParems.User),
        strDBName: _AuthParems.DB,
        isCashIn: false,
      };
      const res = await API_POST(`POS/InsUpd_CashOut`, objEntSession, dispatch);
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const CashIn_Click = async () => {
    try {
      const objEntSession = {
        TransId: 0,
        SessionId: Number(localStorage.Session),
        CashOut: 0,
        CashIn: Number(dCashIn),
        Remark: strInReason,
        EmpId: Number(_Employee.iEmpId),
        iUserId: Number(_AuthParems.User),
        strDBName: _AuthParems.DB,
        isCashIn: true,
      };
      const res = await API_POST(`POS/InsUpd_CashOut`, objEntSession, dispatch);
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        $("#pos-menu").removeClass("active");
      }
    });
  };
  const Update_Template = async () => {
    try {
      let iTabletMode = Number(!_Common.IsTabletMode);
      dispatch(Update_POStemplate(!_Common.IsTabletMode));
      await API_PUT(
        `POS/Update_Template/${iTabletMode}/${Number(_Common.iSessionId)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const diffDateAndToString = (date, time) => {
    setInterval(() => {
      // To calculate the time difference of two dates
      const Curr_Date = new Date();
      const fromdate = new Date(date + " " + time);
      var total = Date.parse(Curr_Date) - Date.parse(fromdate);
      var ss = Math.floor((total / 1000) % 60);
      var mm = Math.floor((total / 1000 / 60) % 60);
      var hr = Math.floor((total / 1000 / 60 / 60) % 24);
      var d = Math.floor(total / 1000 / 60 / 60 / 24);

      const Time = `${convertwodegit(d)}:${convertwodegit(hr)}:${convertwodegit(
        mm
      )}:${convertwodegit(ss)}`;
      setTimer(Time);
    }, 1000);
  };
  const convertwodegit = (num) => {
    return num.toString().padStart(2, "0");
  };
  return (
    <div
      id="pos-menu"
      onMouseLeave={(e) => dispatch(ShowHide_Popup("IsRightMenu"))}
    >
      <div className="companyname">
        <img src={Organization} alt="Company Name"></img>
        <div>{_AuthParems.Branchname}</div>
        <div id="cmplogo">
          <img className="logo" src={_AuthParems.Logo} alt="JB" />
        </div>
      </div>
      <ul>
        <li className="cash-out">
          <label>Cash In</label>
          <input
            type="number"
            className="form-control mt-2"
            value={dCashIn}
            onChange={(e) => setdCashIn(e.target.value)}
            onClick={(e) => e.target.select()}
          />
          <input
            type="text"
            className="form-control mt-2"
            value={strInReason}
            onChange={(e) => setstrInReason(e.target.value)}
            placeholder="Cash In Reason"
            onClick={(e) => e.target.select()}
          />
        </li>
        <li className="mt-1">
          <button onClick={(e) => CashIn_Click()} className="mbtn">
            <i className="fa-solid fa-indian-rupee-sign"></i>
            Add Cash In
          </button>
        </li>
        <li className="cash-out">
          <label>Cash Out</label>
          <input
            type="number"
            className="form-control mt-2"
            value={dCashout}
            onChange={(e) => setdCashout(e.target.value)}
            onClick={(e) => e.target.select()}
          />
          <input
            type="text"
            className="form-control mt-2"
            value={strOutReason}
            onChange={(e) => setstrOutReason(e.target.value)}
            placeholder="Cash Out Reason"
            onClick={(e) => e.target.select()}
          />
        </li>
        <li className="mt-1">
          <button onClick={(e) => CashOut_Click()} className="mbtn">
            <i className="fa-solid fa-indian-rupee-sign"></i>
            Add Cash Out
          </button>
        </li>
        <li className="mt-1">
          <button
            onClick={(e) => dispatch(ShowHide_Popup("IsSession"))}
            className="mbtn"
          >
            <i className="fa-regular fa-clock"></i>
            {Number(_Common.iSessionId) === 0
              ? "Start Session"
              : "End Session "}
            <span style={{ fontSize: "12px", color: "blueviolet" }}>
              {Timer}
            </span>
          </button>
        </li>
        <li className="touch-btn">
          <label>Screen Template</label>
          <label className="switch-touch " onClick={() => Update_Template()}>
            <div
              className={_Common.IsTabletMode ? "toggle active" : "toggle"}
            ></div>
            <span className="pc">PC Screen</span>
            <span className="mobile">Mobile Screen</span>
          </label>
        </li>
        <li>
          <button onClick={(e) => LogoutClick()} className="mbtn mt-2">
            <i className="fa-solid fa-power-off"></i> Sign Out
          </button>
        </li>
        <li className="pos-help">
          <label>Shorcut Keys</label>
          <label>(F1) - Load and Search Product</label>
          <label>(F2) - Select Customer or Add New Customer</label>
          <label>(F3) - Session Open Close</label>
          <label>(F4) - Payment Mode</label>
          <label>(F6) - View Invoice</label>
          <label>(F7) - View Cart Invoice</label>
          <label>(F8) - Discount</label>
          <label>(F9) - Add Notes</label>
        </li>
      </ul>
    </div>
  );
}
