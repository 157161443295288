import { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import SideMenu from "../General/SideMenu";

import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Messagedialogbox, {
  Ask_Question,
  Show_Message,
} from "../General/Messagedialogbox";
import PartyCreation from "../Transaction/PartyCreation";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";

function RouteGroup() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 69;
  const [iRGId, setiRGId] = useState(0);
  const [isNewJob, setisNewJob] = useState(false);
  const [iAmount, setiAmount] = useState("");
  const [strRouteGrpName, setstrRouteGrpName] = useState("");
  const [strAlias, setstrAlias] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strJobName, setstrJobName] = useState("");
  const [strJobCode, setstrJobCode] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [iJobId, setiJobId] = useState(0);
  const [ListRouteGroup, setListRouteGroup] = useState([]);
  const [ListJob, setListJob] = useState([]);
  const [arrJoblist, setarrJoblist] = useState([]);

  useEffect(() => {
    Load_RouteGroup();
  }, []);
  const Load_RouteGroup = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const res = await API_FETCH(
      `RouteGroup/Load_RouteGroup/${Number(_LocalParems.Branch)}/${
        _LocalParems.DB
      }`,
      dispatch
    );
    if (res) {
      setListRouteGroup(res.objRouteGroup);
      setarrJoblist(res.objJobList);
    }
  };
  const Save_RouteGroup = async () => {
    const InsUpdRG = {
      iScrId: iScrId,
      iRGId: Number(iRGId),
      strRGName: strRouteGrpName,
      strAliasName: strAlias,
      strPrintName: strPrintName,
      strRemarks: strRemarks,
      iBranchId: Number(_AuthParems.Branch),
    };
    const objInsUpdRG = {
      _objEntRG: ListJob,
      objEntRG: InsUpdRG,
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      "RouteGroup/InsUpd_RouteGroup/",
      objInsUpdRG,
      dispatch
    );
    if (res) {
      Clear_RouteGroup();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const Clear_RouteGroup = () => {
    setstrRouteGrpName("");
    setstrAlias("");
    setstrRemarks("");
    setstrPrintName("");
    setstrJobName("");
    setstrJobCode("");
    setiJobId(0);
    setiAmount(0);
    setListJob([]);
    setListRouteGroup([]);
    Load_RouteGroup();
  };
  const Select_Job = (ProductInfo) => {
    setiJobId(ProductInfo.jobId.toString());
    setstrJobName(ProductInfo.jobName);
    setiAmount(ProductInfo.amount);
  };
  const Add_Job = () => {
    let ListFilterDt = ListJob.filter((D) => D.jobId === Number(iJobId));
    if (Number(iJobId) === 0) {
      Show_Message(dispatch, "Select Job Work", "info");
    } else if (ListFilterDt.length > 0) {
      let JobList = ListJob;
      JobList.map((Data) => {
        if (Data.jobId === Number(iJobId)) {
          Data.jobName = strJobName;
          Data.amount = parseFloat(iAmount).toFixed(2);
          Data.jobId = Number(iJobId);
          Data.jobCode = strJobCode;
        }
        return Data;
      });
      setListJob(JobList);
      setstrJobName("");
      setstrJobCode("");
      setiAmount(0);
      setiJobId(0);
    } else {
      const AddJobItem = {
        orderNo: ListJob.length + 1,
        jobName: strJobName,
        amount: parseFloat(iAmount).toFixed(2),
        jobId: Number(iJobId),
        jobCode: strJobCode,
        remark1: "",
        remark2: "",
        remark3: "",
        remark4: "",
        hours: "",
      };
      if (ListJob.length === 0) {
        setListJob([AddJobItem]);
      } else {
        setListJob([...ListJob, AddJobItem]);
      }
      setstrJobName("");
      setstrJobCode("");
      setiAmount(0);
      setiJobId(0);
    }
  };
  const Delete_RouteGroup = async () => {
    const res = await API_POST(
      "RouteGroup/Delete_RouteGroup/" +
        Number(_AuthParems.Branch) +
        "/" +
        Number(iRGId) +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      Clear_RouteGroup();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const View_Routegroup = async (RG) => {
    const res = await API_FETCH(
      `RouteGroup/View_RouteGroup/${Number(RG.iRGId)}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      setListJob(res.DT);
      setiRGId(Number(RG.iRGId));
      setstrRouteGrpName(RG.strRGName);
      setstrAlias(RG.strAliasName);
      setstrPrintName(RG.strPrintName);
      setstrRemarks(RG.strRemarks);
    }
  };
  const RemoveRow = (rowinfo) => {
    const NewList = ListJob.filter((D) => D.jobId !== rowinfo.jobId);
    NewList.map((Data) => {
      if (Data.orderNo > 1 && rowinfo.orderNo < Data.orderNo) {
        Data.orderNo = Data.orderNo - 1;
      }
      return Data;
    });
    setListJob(NewList);
  };
  const Search_RouteGroup = async (strtxt) => {
    setstrSearch(strtxt);
    const objSearch = {
      strSearch: strtxt,
      strColName: "All",
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
    };
    const res = await API_FETCH(
      `RouteGroup/Search_RouteGroup/`,
      objSearch,
      dispatch
    );
    if (res) setListRouteGroup(res);
  };
  const View_JobWork = async () => {
    const res = await API_FETCH(
      `RouteGroup/View_JobWork/${_AuthParems.DB}/${Number(
        _AuthParems.User
      )}/${Number(_AuthParems.Branch)}`,
      dispatch
    );
    if (res) {
      if (res.length > 0) {
        var JobLenth = res.length - 1;
        setiJobId(Number(res[JobLenth].iId));
        setstrJobName(res[JobLenth].strName);
        setstrJobCode(res[JobLenth].strCode);
      }
    }
  };
  const Job = [
    {
      Header: "SNO",
      accessor: "orderNo",
      maxWidth: 70,
    },
    {
      Header: "Name",
      accessor: "jobName",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Action",
      id: "jobId",
      maxWidth: 100,
      accessor: (rowinfo) => (
        <div>
          <i
            onClick={() => RemoveRow(rowinfo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  return (
    <main className="main-container">
      <SideMenu ScrId={iScrId} />
      <div className="content">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          Search={(val) => Search_RouteGroup(val)}
          arrMasterData={ListRouteGroup}
          isImg={false}
          displayName="strRGName"
          Parem1="iRGId"
          View_Data={(rg) => View_Routegroup(rg)}
          placeholder="Route Group"
        />
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-2">
              <label>
                Name <span className="danger">*</span>
              </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                autoFocus
                value={strRouteGrpName}
                onChange={(e) => setstrRouteGrpName(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Enter RoutGroup Name"
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-2">
              <label>Alias</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={strAlias}
                onClick={(e) => e.target.select()}
                placeholder="Enter Alias Name"
                onChange={(e) => setstrAlias(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-2">
              <label>Print Name</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                onClick={(e) => e.target.select()}
                placeholder="Enter Print Name"
                value={strPrintName}
                onChange={(e) => setstrPrintName(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-2">
              <label>Remark</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                onClick={(e) => e.target.select()}
                placeholder="Enter Remark"
                value={strRemarks}
                onChange={(e) => setstrRemarks(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-2">
              <label>Job</label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selecrparem.isoptionShow}
                placeholder="Job Work"
                select_value={(val) => {
                  setiJobId(val.iId);
                  setstrJobName(val.strName);
                  setstrJobCode(val.strCode);
                }}
                btnname="Job Work"
                btnshow={true}
                show_popup={(e) => setisNewJob(!isNewJob)}
                valueonly={false}
                defaultval={iJobId}
                displayName="strName"
                disvalue="iId"
                arrydata={arrJoblist}
                disabled={false}
                EmptVal="Job Work"
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>Amount</label>
              <input
                type="number"
                aria-label="First name"
                onClick={(e) => e.target.select()}
                placeholder="Enter Amount"
                className="form-control"
                style={{ minWidth: "150px", maxWidth: "150px" }}
                value={iAmount}
                onChange={(e) => setiAmount(Number(e.target.value))}
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <div className="btn-sub-section mt-3">
                <button
                  className="btn-fabgreen"
                  onClick={Add_Job}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") Add_Job();
                  }}
                >
                  Add&nbsp;<i className="bx bx-layer-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <ReactTable
            columns={Job}
            data={ListJob}
            minRows={5}
            row_click={(jobinfo, Index) => {}}
            row_doubleclick={(jobinfo, Index) => {
              Select_Job(jobinfo);
            }}
            background={true}
            className="full-table"
          />
          <Messagedialogbox />
          {isNewJob && (
            <PartyCreation
              Close={(e) => setisNewJob(!isNewJob)}
              View_JobWork={View_JobWork}
              strScrType="JobCreation"
            />
          )}
        </div>
        <Button
          Save={(e) => {
            if (strRouteGrpName === "") {
              Show_Message(dispatch, "Please Enter Name", "info");
            } else if (ListJob.length === 0) {
              Show_Message(dispatch, "Atleast One Job Required", "info");
            } else {
              Ask_Question(
                dispatch,
                Save_RouteGroup,
                "Do You Want to  Save RouteGroup?"
              );
            }
          }}
          Clear={Clear_RouteGroup}
          Delete={(e) => {
            if (Number(iRGId) === 0) {
              Show_Message(dispatch, "Please Select Route Group", "info");
            } else {
              Ask_Question(
                dispatch,
                Delete_RouteGroup,
                "Do You Want to  Delete RouteGroup?"
              );
            }
          }}
          ScrId={iScrId}
        />
      </div>
    </main>
  );
}
export default RouteGroup;
