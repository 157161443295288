import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_POST, DecryptData } from "../General/Utility";
import $ from "jquery";
import ReactTable from "../General/ReactTable";
function GSTTaxMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strFilterColumn, setstrFilterColumn] = useState("ProName");
  const [strSearch, setstrSearch] = useState("");

  const [iItemTypeId, setiItemTypeId] = useState(27);
  const [iTaxLvlId, setiTaxLvlId] = useState(1);
  const [SGSTTaxId, setSGSTTaxId] = useState(24);
  const [CGSTTaxId, setCGSTTaxId] = useState(27);
  const [IGSTTaxId, setIGSTTaxId] = useState(18);
  const [TcsTaxId, setTcsTaxId] = useState(43);

  const [isTcsShow, setisTcsShow] = useState(false);
  const [isAllItem, setisAllItem] = useState(false);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);

  const [ListEntFillter, setListEntFillter] = useState([]);
  const [ListEntItemType, setListEntItemType] = useState([]);
  const [ListEntTaxLevel, setListEntTaxLevel] = useState([]);
  const [ListEntCgst, setListEntCgst] = useState([]);
  const [ListEntsgst, setListEntsgst] = useState([]);
  const [ListEntigst, setListEntigst] = useState([]);
  const [ListEntTCS, setListEntTCS] = useState([]);
  const [ListEntList, setListEntList] = useState([]);

  useEffect(() => {
    Load_TaxMaster();
  }, []);

  const Load_TaxMaster = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const objSearch = {
        ItemtypeId: iItemTypeId,
        iTaxLevel: iTaxLvlId,
        strColName: strFilterColumn,
        strDBName: _LocalParems.DB,
        iBranchId: _LocalParems.Branch,
      };

      const res = await API_POST(
        "TaxMaster/Load_TaxMaster/",
        objSearch,
        dispatch
      );
      if (res) {
        setListEntFillter(res.objEntCmbFilter);
        setListEntItemType(res.objEntItemTyp);
        setListEntTaxLevel(res.objEntTaxType);
        setListEntCgst(res.objEntCGSTTax);
        setListEntsgst(res.objEntSGSTTax);
        setListEntigst(res.objEntIGSTTax);
        setListEntTCS(res.objEntTCSTax);
        res.objEntItem.sort((a, b) => a.sNo - b.sNo);
        setListEntList(res.objEntItem);
        ButtonPermission(_LocalParems.MasterPer);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const UpdateClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const objEntTax = {
        strSearchColumn: strFilterColumn,
        strSearch: strSearch,
        iTaxLvl: Number(iTaxLvlId),
        iItemTypeId: Number(iItemTypeId),
        iCGSTTaxId: Number(CGSTTaxId),
        iSGSTTaxId: Number(SGSTTaxId),
        iIGSTTaxId: Number(IGSTTaxId),
        iTCSTaxId: Number(TcsTaxId),
      };
      let Row = ListEntList.filter((e) => e.checkBox === true);
      const objEntTaxUpd = {
        objBulkTaxModel: objEntTax,
        objTaxDT: isAllItem === true ? [] : Row,
        isTaxForAll: isAllItem,
        strDBName: _AuthParems.DB,
        iBranchId: _AuthParems.Branch,
      };
      const res = await API_POST(
        "TaxMaster/Update_Tax/",
        objEntTaxUpd,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = async () => {
    setstrSearch("");
    setSGSTTaxId(24);
    setCGSTTaxId(27);
    setIGSTTaxId(18);
    setTcsTaxId(43);
    setiTaxLvlId(1);
    setisAllItem(false);
    setBtnSaveDisable(false);
    setisTcsShow(false);
    setstrFilterColumn("ProName");
    setListEntList([]);
    await Load_TaxMaster();
  };
  const Search_Item = async (Search) => {
    try {
      setstrSearch(Search);
      const parem = {
        ItemtypeId: iItemTypeId,
        strSearch: Search,
        iTaxLevel: iTaxLvlId,
        strColName: strFilterColumn,
        strDBName: _AuthParems.DB,
        iBranchId: _AuthParems.Branch,
      };
      const res = await API_POST("TaxMaster/Search_tax/", parem, dispatch);
      if (res) {
        setListEntList(res.Item);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Filter_Product = async (strCol) => {
    try {
      setstrFilterColumn(strCol);
      const parem = {
        ItemtypeId: iItemTypeId,
        strSearch: strSearch,
        iTaxLevel: iTaxLvlId,
        strColName: strCol,
        strDBName: _AuthParems.DB,
        iBranchId: _AuthParems.Branch,
      };
      const res = await API_POST("TaxMaster/Search_tax/", parem, dispatch);
      if (res) {
        setListEntList(res.Item);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Screentype_OnChange = async (temTypeId) => {
    try {
      await Pre_Loading(dispatch, true);
      setiItemTypeId(temTypeId);
      const parem = {
        ItemtypeId: temTypeId,
        strSearch: strSearch,
        iTaxLevel: iTaxLvlId,
        strColName: strFilterColumn,
        strDBName: _AuthParems.DB,
        iBranchId: _AuthParems.Branch,
      };
      const res = await API_POST(`TaxMaster/Load_TaxType`, parem, dispatch);
      if (res) {
        setListEntList(res.Item);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const TaxTypeChange = async (TaxLvl) => {
    try {
      await Pre_Loading(dispatch, true);
      setiTaxLvlId(Number(TaxLvl));
      const parem = {
        ItemtypeId: iItemTypeId,
        strSearch: strSearch,
        iTaxLevel: iTaxLvlId,
        strColName: strFilterColumn,
        strDBName: _AuthParems.DB,
        iBranchId: _AuthParems.Branch,
      };
      const res = await API_POST(`TaxMaster/Load_TaxType`, parem, dispatch);
      if (res) {
        setisTcsShow(Number(TaxLvl) === 1 ? false : true);
        setListEntList(res.Item);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setSGSTTaxId(objtax.iTaxId);
        setCGSTTaxId(
          ListEntCgst.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setIGSTTaxId(
          ListEntigst.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setSGSTTaxId(
          ListEntsgst.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setCGSTTaxId(objtax.iTaxId);
        setIGSTTaxId(
          ListEntigst.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setIGSTTaxId(objtax.iTaxId);
        setSGSTTaxId(
          ListEntsgst.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setCGSTTaxId(
          ListEntCgst.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const CheckBoxChange = (checked, rowInfo) => {
    try {
      let EntList = ListEntList;
      EntList = EntList.map((e) => {
        if (e.itemId === rowInfo.itemId) {
          e.checkBox = checked;
        }
        return e;
      });
      setListEntList(EntList);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const AllBoxOnChange = (isallSelect) => {
    try {
      setisAllItem(isallSelect);
      let listEntItem = ListEntList;
      listEntItem = listEntItem.map((Data) => {
        Data.checkBox = isallSelect;
        return Data;
      });
      setListEntList(listEntItem);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ButtonPermission = (MasterPer) => {
    MasterPer = MasterPer.filter((Data) => Data.iScrId === 62);
    setBtnSaveDisable(!MasterPer[0].isModify);
  };
  const tab_click = async (temTypeId, tabindex) => {
    await Screentype_OnChange(temTypeId);
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
  };
  const columns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Name",
      accessor: "itemName",
      style: { justifyContent: "left" },
    },
    {
      Header: "SGST",
      accessor: "sgstTaxDesc",
      style: { justifyContent: "left" },
      maxWidth: 150,
      show: iTaxLvlId === 1,
    },
    {
      Header: "CGST",
      accessor: "cgstTaxDesc",
      maxWidth: 100,
      style: { justifyContent: "left" },
      show: iTaxLvlId === 1,
    },
    {
      Header: "IGST",
      accessor: "igstTaxDesc",
      maxWidth: 100,
      style: { justifyContent: "left" },
      show: iTaxLvlId === 1,
    },
    {
      Header: "TCS",
      accessor: "tcsTaxDesc",
      maxWidth: 100,
      style: { justifyContent: "left" },
      show: iTaxLvlId !== 1,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={isAllItem}
            onChange={(e) => AllBoxOnChange(!isAllItem)}
          />
          <span>Select</span>
        </div>
      ),
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.checkBox}
          onChange={(e) => CheckBoxChange(!rowinfo.checkBox, rowinfo)}
        />
      ),
    },
  ];
  return (
    <main className="main-container">
      <SideMenu ScrId={6} />
      <div className="content1">
        <div className="main-content1">
          <div className="trans-tab">
            <div className="trans-buttons">
              {ListEntItemType.map((dt, Index) => (
                <label
                  key={Index}
                  onClick={(e) => tab_click(dt.iItemTypeId, Index)}
                  className="active"
                >
                  {dt.strItemType}
                </label>
              ))}
              <div id="tab-line"></div>
            </div>
          </div>
          <div className="fgt-two-Column">
            <div className="fgt-row">
              <div className="fgt-col">
                <label>Tax Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_parem.isoptionShow1}
                  placeholder="Select Tax Type"
                  select_value={(val) => TaxTypeChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iTaxLvlId}
                  displayName="strTaxLvlType"
                  disvalue="iTaxLvlId"
                  arrydata={ListEntTaxLevel}
                  EmptVal="Tax Type "
                />
              </div>
              {!isTcsShow && (
                <div className="fgt-col">
                  <label>GST Tax</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_parem.isoptionShow4}
                    placeholder="Select IGST Tax"
                    select_value={(val) => TaxOnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={IGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={ListEntigst}
                    EmptVal="GST Tax "
                    replace={["IGST", "GST"]}
                  />
                </div>
              )}
              {isTcsShow && (
                <div className="fgt-col">
                  <label>TCS Tax</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow5"
                    showoption={_parem.isoptionShow5}
                    placeholder="Select TCS Tax"
                    select_value={(val) => setTcsTaxId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={TcsTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={ListEntTCS}
                    EmptVal="TCS Tax "
                  />
                </div>
              )}
            </div>
            <div className="fgt-row">
              {iItemTypeId === 27 && (
                <div className="fgt-col">
                  <label>Search By</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow6"
                    showoption={_parem.isoptionShow6}
                    placeholder="Select Filter"
                    select_value={(val) => Filter_Product(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={strFilterColumn}
                    displayName="strDisplayName"
                    disvalue="strValueName"
                    arrydata={ListEntFillter}
                    EmptVal="Filter Column "
                  />
                </div>
              )}
              <div className="fgt-col">
                <label>Search Item</label>
                <div className="m-hsearch">
                  <input
                    type="text"
                    autoCorrect="off"
                    autoComplete="off"
                    placeholder="Search"
                    value={strSearch}
                    onChange={(e) => Search_Item(e.target.value)}
                    autoFocus
                  />
                  <i
                    className="fas fa-search"
                    onClick={(e) => Search_Item(strSearch)}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <ReactTable
              columns={columns}
              data={ListEntList}
              minRows={10}
              row_click={(TaxInfo, Index) => {}}
              row_doubleclick={(TaxInfo, Index) => {}}
              background={true}
              className="full-table"
              tabIndex=""
            />
          </div>
          <Messagedialogbox />
        </div>
        <div className="button-list">
          <button
            disabled={BtnSaveDisable}
            type="button"
            className="btn-fabgreen"
            onClick={(e) => {
              Ask_Question(
                dispatch,
                UpdateClick,
                "Do You Want to  Update Tax?"
              );
            }}
          >
            Save &nbsp; <i className="fas fa-save"></i>
          </button>
          <button type="reset" className="btn-fabgreen" onClick={ClearClick}>
            Clear &nbsp; <i className="fas fa-eraser"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            onClick={() => (window.location.href = `/JustBill/DashBoard`)}
          >
            Close &nbsp; <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </main>
  );
}
export default GSTTaxMaster;
