import React, { useEffect, useState } from "react";

import $ from "jquery";
import SideMenu from "../General/SideMenu";

import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Messagedialogbox, { Ask_Question } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function TransactionAdjustment() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _SelectParem = useSelector((state) => state.SelectOption.parems);

  const [isPending, setisPending] = useState(true);
  const [objReason, setobjReason] = useState([]);
  const [selected, setselected] = useState(0);
  const [strSearch, setstrSearch] = useState("");
  const [iScrId, setiScrId] = useState(71);
  const [iAdjustTypeId, setiAdjustTypeId] = useState(4);
  const [iPOId, setiPOId] = useState(0);
  const [dQty, setdQty] = useState(0);
  const [strProName, setstrProName] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [strTabName, setstrTabName] = useState("Pending");
  const [arrVouchType, setarrVouchType] = useState([]);
  const [arrPOVoucher, setarrPOVoucher] = useState([]);
  const [arrVoucherlist, setarrVoucherlist] = useState([]);
  useEffect(() => {
    Load_TransAdjustment();
  }, []);

  const Load_TransAdjustment = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);

    const res = await API_FETCH(
      `TransactionAdjustment/Load_TransAdjustment/${iScrId}/${Number(
        _LocalParems.Branch
      )}/${_LocalParems.DB}`,
      dispatch
    );
    if (res) {
      setarrVouchType(res.objVouchType);
      setobjReason(res.objReason);
      setarrPOVoucher(res.objPOVoucher);
      setarrVoucherlist(res.objPendingData);
    }
  };
  const Vouchertype_Onchange = async (ScrId) => {
    setiScrId(Number(ScrId));
    Pre_Loading(dispatch, true);
    const res = await API_POST(
      `TransactionAdjustment/LoadVouchers/`,
      {
        iScrId: Number(ScrId),
        strColumn: strTabName,
        strSearch: strSearch,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      },
      dispatch
    );
    if (res) {
      Pre_Loading(dispatch, false);
      setarrVoucherlist(res);
    }
  };
  const Save_Adjustment = async (e) => {
    if (arrVoucherlist.length === 0) {
      Show_Message(dispatch, "No rows are found", "info");
    } else {
      var PendingVoucher = arrVoucherlist;
      var AdjustType = objReason.filter(
        (F) => F.iAdjustTypeId === iAdjustTypeId
      );
      var POType = arrPOVoucher.filter((F) => F.iPOId === iPOId);
      PendingVoucher.forEach((element) => {
        element.adjustTypeId = iAdjustTypeId;
        element.adjustType = AdjustType.map((a) => a.strAdjustType)[0];
        element.poId = iPOId;
        element.poCode = POType.map((a) => a.strPOName)[0];
      });
      Pre_Loading(dispatch, true);
      const res = await API_POST(
        "TransactionAdjustment/InsUpd_TransAdjustment/",
        {
          objEntTAP: PendingVoucher,
          strDBName: _AuthParems.DB,
          iBranchId: Number(_AuthParems.Branch),
        },
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    }
  };
  const Delete_AdjustMent = async (iTransAdjId) => {
    Pre_Loading(dispatch, true);
    const res = await API_POST(
      `TransactionAdjustment/Delete_TransAdjustment/${iTransAdjId}/${_AuthParems.DB}`,

      dispatch
    );
    if (res) {
      ClearClick();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const ClearClick = () => {
    setiScrId(71);
    setselected(0);
    setiAdjustTypeId(4);
    setiPOId(0);
    setdQty(0);
    setstrProName("");
    setarrVoucherlist([]);
    Load_TransAdjustment();
  };
  const AddClick = async () => {
    let ExistQty = arrVoucherlist[selected].pendingQty;
    if (dQty === 0) {
      Show_Message(dispatch, "Please Enter Qty", "info");
    } else if (ExistQty < dQty) {
      Show_Message(
        dispatch,
        "Only " + ExistQty + " Qty is available to adjust.",
        "info"
      );
    } else {
      var PendingVouchers = JSON.parse(
        JSON.stringify(arrVoucherlist, (k, v) => (v === null ? "" : v))
      );
      const res = await API_POST(
        "TransactionAdjustment/AddQty/",
        {
          dQty: dQty,
          iRowIndex: selected,
          objPenModel: PendingVouchers,
          Remark: strRemark,
        },
        dispatch
      );
      if (res) {
        setdQty(0);
        setstrProName("");
        setarrVoucherlist(res);
      }
    }
  };
  const Select_Trans = async (tabindex) => {
    let TabName = tabindex === 0 ? "Pending" : "Completed";
    setstrTabName(TabName);
    Pre_Loading(dispatch, true);
    const res = await API_POST(
      `TransactionAdjustment/LoadVouchers/`,
      {
        iScrId: Number(iScrId),
        strColumn: TabName,
        strSearch: strSearch,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      },
      dispatch
    );
    if (res) {
      setarrVoucherlist(res);
      Pre_Loading(dispatch, false);
    }
  };
  const Search_AdjustMent = async (strtxt) => {
    setstrSearch(strtxt);
    const res = await API_POST(
      "TransactionAdjustment/LoadVouchers/",
      {
        iScrId: Number(iScrId),
        strColumn: strTabName,
        strSearch: strtxt,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      },
      dispatch
    );
    if (res) {
      setarrVoucherlist(res);
    }
  };
  const tab_click = (tabindex) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    setisPending(tabindex === 0 ? true : false);
    Select_Trans(tabindex);
  };
  const Columns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
    },
    {
      Header: "Vendor Name",
      accessor: "venName",
      style: { justifyContent: "left" },
      minWidth: 200,
    },
    {
      Header: "Vouch No",
      accessor: "vouchNo",
      maxWidth: 100,
      style: { justifyContent: "left" },
    },
    {
      Header: "Date",
      accessor: "vouchDate",
      maxWidth: 100,
    },
    {
      Header: "Product Name",
      accessor: "proName",
      minWidth: 200,
    },
    {
      Header: "Actual Qty",
      accessor: "actualQty",
      show: isPending,
    },
    {
      Header: "Convert Qty",
      accessor: "convertedQty",
    },
    {
      Header: "Pending Qty",
      accessor: "pendingQty",
      show: isPending,
    },
    {
      Header: "Unit",
      accessor: "unit",
    },
    {
      Header: "Qty",
      accessor: "qty",
      show: isPending,
    },
    {
      Header: "AdjustType",
      accessor: "adjustType",
      show: !isPending,
    },
    {
      Header: "Production Voucher",
      accessor: "poCode",
      minWidth: 200,
      show: !isPending,
    },
    {
      Header: "Remark",
      accessor: "remark",
    },
    {
      Header: "Options",
      id: "traAdjId",
      maxWidth: 70,
      accessor: (rowInfo) => (
        <div>
          <i
            onClick={(e) => {
              Ask_Question(
                dispatch,
                Delete_AdjustMent,
                "Do You Want Delete Adjustment ?",
                rowInfo.traAdjId
              );
            }}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  return (
    <>
      <SideMenu ScrId={77} />
      <div className="content">
        <div className="Master-card1">
          <div className="main-content">
            <div className="fg-card shadow-base">
              <div className="trans-buttons">
                <label onClick={(e) => tab_click(0)}>Pending</label>
                <label onClick={(e) => tab_click(1)}>Completed</label>
                <div id="tab-line"></div>
              </div>
              <div className="row d-flex justify-content-between p-2">
                <div className="col-md-3 col-sm-12">
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_SelectParem.isoptionShow}
                    placeholder="Select Voucher Type"
                    select_value={(val) => Vouchertype_Onchange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iScrId}
                    displayName="strScrName"
                    disvalue="iScrId"
                    arrydata={arrVouchType}
                    EmptVal="Voucher Type"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="m-hsearch">
                    <input
                      type="search"
                      placeholder="Search Transaction Adjustment"
                      value={strSearch}
                      onChange={(e) => Search_AdjustMent(e.target.value)}
                      autoFocus
                    />
                    <i
                      className="fas fa-search"
                      onClick={(e) => Search_AdjustMent(strSearch)}
                    ></i>
                  </div>
                </div>
              </div>
              {isPending && (
                <div className="row p-2">
                  <div className="col-sm-12 col-md-4">
                    <label>Product Name</label>
                    <input
                      type="text"
                      aria-label="First name"
                      style={{ minWidth: "300px" }}
                      className="form-control"
                      name="strProName"
                      value={strProName}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-6 col-md-2">
                    <label>Qty</label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      name="dQty"
                      id="txtproQty"
                      value={dQty}
                      onChange={(e) => setdQty(Number(e.target.value))}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <label>Reason</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow1"
                      showoption={_SelectParem.isoptionShow1}
                      placeholder="Select Reason"
                      select_value={(val) => setiAdjustTypeId(val)}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iAdjustTypeId}
                      displayName="strAdjustType"
                      disvalue="iAdjustTypeId"
                      arrydata={objReason}
                      EmptVal="Reason"
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <label>Production Voucher</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow2"
                      showoption={_SelectParem.isoptionShow2}
                      placeholder="Production Voucher"
                      select_value={(val) => setiPOId(val)}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iPOId}
                      displayName="strPOName"
                      disvalue="iPOId"
                      arrydata={arrPOVoucher}
                      EmptVal="Production Voucher"
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <label>Remark</label>
                    <input
                      type="text"
                      className="form-control"
                      name="txtRemark"
                      id="txtRemark"
                      value={strRemark}
                      onChange={(e) => setstrRemark(e.target.value)}
                      onClick={(e) => e.target.select()}
                      placeholder="Remark"
                    />
                  </div>
                  <div className="col-sm-12 col-md-2">
                    <div className="trans-inputbtn">
                      <button type="button" onClick={AddClick}>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <ReactTable
                columns={Columns}
                data={isPending ? arrVoucherlist : arrVoucherlist}
                minRows={5}
                row_click={(ProInfo, Index) => {}}
                row_doubleclick={(ProInfo, Index) => {
                  setstrProName(ProInfo.proName);
                  setdQty(ProInfo.qty);
                }}
                background={true}
                className="full-table"
              />
            </div>
          </div>
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  Save_Adjustment,
                  "Do You Want Save Adjustment ?"
                );
              }}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
            <button type="button" className="btn-fabgreen" onClick={ClearClick}>
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={() => (window.location.href = `/JustBill/DashBoard`)}
            >
              Close &nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
          <Messagedialogbox />
        </div>
      </div>
    </>
  );
}

export default TransactionAdjustment;
