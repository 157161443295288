import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import Messagedialogbox, {
  Ask_Question,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import MasterCodeSetting from "./MasterCodeSetting";
import Button from "./MasterButton";
import MasterList from "./MasterListItem";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
function DoctorSessionMaster() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);

  const _ScrId = 103;
  const maxTab = 8;
  const [SessionId, setSessionId] = useState(0);
  const [NumSeqNo, setNumSeqNo] = useState("");
  const [Prefix, setPrefix] = useState("");
  const [SessionCode, setSessionCode] = useState("");
  const [ManualCode, setManualCode] = useState(true);
  const [strSearch, setstrSearch] = useState("");
  const [IsMasterCode, setIsMasterCode] = useState(false);

  const [SessionName, setSessionName] = useState("");
  const [DayId, setDayId] = useState(1);
  const [StartTime, setStartTime] = useState(
    new Date().toTimeString().split(" ")[0]
  );
  const [EndTime, setEndTime] = useState(
    new Date().toTimeString().split(" ")[0]
  );
  const [Remark, setRemark] = useState("");
  const [Status, setStatus] = useState(1);

  const [arrDays, setarrDays] = useState([]);
  const [arrEntSession, setarrEntSession] = useState([]);
  const [arrEntStatus, setarrEntStatus] = useState([]);

  useEffect(() => {
    Load_Session();
  }, []);

  const Load_Session = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const parems = await `DoctorMaster/Load_SessionMaster/${_ScrId}/${Number(
        _LocalParems.Branch
      )}/${_LocalParems.DB}`;
      const res = await API_FETCH(parems, dispatch);
      if (res) {
        const Codeformate = res.Codeformate[0];
        setNumSeqNo(Codeformate.iNumSeqNo);
        setPrefix(Codeformate.strPrefix);
        setSessionCode(Codeformate.strCode);
        setManualCode(!Codeformate.isManualNo);
        setarrEntStatus(res.EntStatus);
        setarrEntSession(res.EntSession);
        setarrDays(res.EntDays);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Clear_Session = () => {
    setSessionId(0);
    setNumSeqNo("");
    setPrefix("");
    setSessionCode("");
    setManualCode(false);
    setstrSearch("");
    setIsMasterCode("");
    setarrDays([]);
    setarrEntSession([]);
    setarrEntStatus([]);
    setSessionName("");
    setDayId(1);
    setStartTime(new Date().toTimeString().split(" ")[0]);
    setEndTime(new Date().toTimeString().split(" ")[0]);
    setRemark("");
    setStatus(1);
    Load_Session();
  };
  const Search_Session = async (strtxt) => {
    try {
      setstrSearch(strtxt);
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Save_Session = async () => {
    try {
      const objSession = {
        SessionId: SessionId,
        NumSeqNo: NumSeqNo.toString(),
        Prefix: Prefix,
        SessionCode: SessionCode,
        SessionName: SessionName,
        DayId: DayId,
        strDayName: "",
        StartTime: time_Onchange(StartTime),
        EndTime: time_Onchange(EndTime),
        Session: "",
        Remark: Remark,
        Status: Status,
        BranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `DoctorMaster/InsUpd_Session`,
        objSession,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Session();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const View_Session = async (sess) => {
    try {
      const res = await API_FETCH(
        `DoctorMaster/View_SessionMaster/${Number(sess.sessionId)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        const _Session = res.EntSession[0];
        setSessionId(_Session.sessionId);
        setNumSeqNo(_Session.numSeqNo);
        setPrefix(_Session.prefix);
        setSessionCode(_Session.sessionCode);
        setSessionName(_Session.sessionName);
        setDayId(_Session.dayId);
        setStartTime(_Session.startTime);
        setEndTime(_Session.endTime);
        setRemark(_Session.remark);
        setStatus(_Session.status);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Delete_Session = async () => {
    try {
      const res = await API_DELETE(
        `DoctorMaster/Delete_Session/${Number(SessionId)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Session();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const time_Onchange = (newTime) => {
    try {
      const curr_date = new Date().toISOString().split("T")[0];
      let LocalTime = new Date(`${curr_date}T${newTime}`).toLocaleTimeString();
      LocalTime = `${LocalTime.split(":")[0]}:${LocalTime.split(":")[1]} ${
        LocalTime.split(" ")[1]
      }`;
      return `${LocalTime}`;
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Key_Down = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === 8) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === 8) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 8) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 8) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 8) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === 8) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === 8) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  return (
    <main className="main-container">
      <SideMenu ScrId={_ScrId} />
      <div className="content">
        <MasterList
          ScrId={_ScrId}
          strSearchtxt={strSearch}
          tabOrder={8}
          Search={(val) => Search_Session(val)}
          arrMasterData={arrEntSession}
          isImg={false}
          displayName="session"
          View_Data={(Doc) => View_Session(Doc)}
          placeholder="Session"
        />
        <div className="main-content">
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <label>Session Name</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Session Name"
                  spellCheck={false}
                  autoComplete="off"
                  value={SessionName}
                  onChange={(e) => setSessionName(e.target.value)}
                  tabIndex={0}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Session Code</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Session Code"
                  spellCheck={false}
                  autoComplete="off"
                  value={SessionCode}
                  onChange={(e) => setSessionCode(e.target.value)}
                  disabled={ManualCode}
                  tabIndex={0}
                />
                <span
                  className="inv-no"
                  onClick={(e) => setIsMasterCode(!IsMasterCode)}
                >
                  <i className="bx bx-cog"></i>
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <label>Remark</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Remark"
                  spellCheck={false}
                  autoComplete="off"
                  value={Remark}
                  onChange={(e) => setRemark(e.target.value)}
                  tabIndex={0}
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>Status</label>
              <CustomizedSelectoption
                optionparem="isoptionShow2"
                showoption={_selecrparem.isoptionShow2}
                placeholder="Select Status"
                tabIndex={10}
                select_value={(val) => setStatus(val)}
                btnname="Status"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={Status}
                displayName="strStatusName"
                disvalue="iStatusId"
                arrydata={arrEntStatus}
                disabled={false}
                EmptVal="Status "
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>Day</label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selecrparem.isoptionShow}
                placeholder="Select Day"
                tabIndex={0}
                select_value={(val) => setDayId(val)}
                btnname="Day"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={DayId}
                displayName="dayName"
                disvalue="dayId"
                arrydata={arrDays}
                disabled={false}
                EmptVal="Day"
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>Session From</label>
              <input
                type="time"
                className="form-control"
                value={StartTime}
                onChange={(e) => setStartTime(e.target.value)}
                tabIndex={1}
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>Session To</label>
              <input
                type="time"
                className="form-control"
                value={EndTime}
                onChange={(e) => setEndTime(e.target.value)}
                tabIndex={2}
                onKeyDown={(e) => Key_Down(e)}
              />
            </div>
          </div>
          {IsMasterCode && (
            <MasterCodeSetting
              Close={(e) => setIsMasterCode(!IsMasterCode)}
              iScrId={_ScrId}
            />
          )}
          <Messagedialogbox />
        </div>
        <Button
          Save={(e) => {
            if (Number(DayId) === 0)
              Show_Message(dispatch, "Please Select Day", "info");
            if (SessionCode.length === 0)
              Show_Message(dispatch, "Please Enter Valid Session Code", "info");
            else if (StartTime.length === 0)
              Show_Message(
                dispatch,
                "Please Select Session Starting Time",
                "info"
              );
            else if (EndTime.length === 0)
              Show_Message(
                dispatch,
                "Please Select Session Ending Time",
                "info"
              );
            if (SessionId > 0) {
              Ask_Question(
                dispatch,
                Save_Session,
                "Do You Want to Update Session?"
              );
            } else {
              Ask_Question(
                dispatch,
                Save_Session,
                "Do You Want to Save Session?"
              );
            }
          }}
          SaveIndex={3}
          DeleteIndex={4}
          CloseIndex={5}
          ClearIndex={6}
          ExportIndex={7}
          Export={(e) => {}}
          onKeyDown={(e) => Key_Down(e)}
          Clear={() => Clear_Session()}
          ScrId={_ScrId}
          Iid={SessionId}
          Delete={(e) => {
            if (Number(SessionId) === 0) {
              Show_Message(dispatch, "Please Select Session", "info");
            } else
              Ask_Question(
                dispatch,
                Delete_Session,
                "Do You Want to  Delete Session?"
              );
          }}
        />
      </div>
    </main>
  );
}

export default DoctorSessionMaster;
