import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import {
  ShowHide_Popup,
  Update_BatchProduct,
} from "../features/Transactionstates";
import { useEffect } from "react";
import { API_POST } from "../General/Utility";
import PopupSearch from "./PopupSearch";

function BatchProductSearch(props) {
  const dispatch = useDispatch();

  const _Common = useSelector((store) => store.Transstates.Common);
  const _Product = useSelector((store) => store.Transstates.Product);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [arrProduct, setarrProduct] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_BatchProduct("");
  }, []);

  const Load_BatchProduct = async (strtxt) => {
    try {
      await Pre_Loading(dispatch, true);
      setstrSearch(strtxt);
      const res = await API_POST(
        `ProductTrans/Load_ProductByBatch/`,
        {
          iScrId: _Common.iScrId,
          iPartyId: _HT.iPartyId,
          iTaxRelId: _HT.iTaxRelId,
          iProId: _Product.Product.ProInfo[0].iProId,
          strDBName: _AuthParems.DB,
          iBranchId: _AuthParems.Branch,
          iWHId: _Product.WHId,
        },
        dispatch
      );
      if (res) {
        setarrProduct(res.EntProduct);
        setarrEntTableCol(res.EntTablDesign);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Product = async (Product) => {
    try {
      await Pre_Loading(dispatch, true);
      await dispatch(ShowHide_Popup("IsBatchPro"));
      if (props.View_Product)
        await props.View_Product(_Product.Product, Product);
      else {
        await dispatch(
          Update_BatchProduct({ BatchInfo: Product, Product: _Product.Product })
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  return (
    <PopupSearch
      ScrName="Product By Batch"
      FilterCol={[]}
      FilterVal={""}
      FilterShow={false}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrProduct}
      UpdateFilterCol={""}
      Search_Data={(e) => Load_BatchProduct(e)}
      View_Data={(e) => View_Product(e)}
      Close={() => dispatch(ShowHide_Popup("IsBatchPro"))}
      IsProduct={true}
    />
  );
}
export default BatchProductSearch;
