import React from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  AddProduct,
  Clear_Product,
  Modify_Product,
  ProductInput_Onchange,
  ProductRate_LostFocus,
  ProductRate_Onchange,
  Remove_product,
  Select_Unit,
  ShowHide_Popup,
  settableCol,
} from "../features/Transactionstates";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useEffect } from "react";
import { API_FETCH, API_POST } from "../General/Utility";
import { KeyUpDown } from "./Transaction";
import ReactTable from "../General/ReactTable";
function TransactionInput() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const _Product = useSelector((store) => store.Transstates.Product);
  const _Disc = useSelector((store) => store.Transstates.Discount);

  useEffect(() => {
    let arrtransper = JSON.parse(JSON.stringify(_Common.arrtransper));
    var TransPermission = arrtransper.filter(
      (Data) => Data.isShowInScr === true
    );
    TransPermission = TransPermission.sort((a, b) => {
      return a.inOrderTO - b.inOrderTO;
    });
    let EntCol = [];
    var Option = "";
    Option = {
      Header: "Delete",
      id: "proId",
      minWidth: 40,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <div>
          <i
            onClick={() => {
              Remove_tableProduct(
                rowinfo.sNo,
                _Common,
                _HT,
                _DT,
                _Disc,
                dispatch
              );
            }}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    };
    EntCol.push(Option);
    for (let i = 0; i < TransPermission.length; i++) {
      var ColName,
        SmallLetter,
        Header,
        TextAlign = "";
      var MinWidth = 0;
      ColName = TransPermission[i].columnName;
      if (ColName.startsWith("Sal")) ColName = ColName.slice(3);
      if (ColName.startsWith("Pur")) ColName = ColName.slice(3);
      SmallLetter = ColName[0].toLowerCase();
      ColName = SmallLetter + ColName.slice(1);
      Header = TransPermission[i].aliasName;
      TextAlign = "center";
      MinWidth = Header.length > 7 ? 120 : 70;
      if (ColName.endsWith("Per")) {
        TextAlign = "right";
        MinWidth = 100;
      }
      if (ColName.endsWith("Amt")) TextAlign = "right";
      if (ColName.endsWith("Value")) TextAlign = "right";
      if (ColName.endsWith("Tax")) TextAlign = "right";

      if (ColName.startsWith("sNo")) MinWidth = 40;
      if (ColName.endsWith("proName")) {
        TextAlign = "Left";
        MinWidth = 200;
      }
      const col = {
        Header: Header,
        accessor: ColName,
        maxWidth: 200,
        minWidth: MinWidth,
        style: { justifyContent: TextAlign },
      };
      EntCol.push(col);
    }

    dispatch(settableCol(EntCol));
  }, [_Common.arrtransper, _HT.iPartyId, _DT.arrEntDT, _Disc.dBillDisPer]);

  const AddProductClick = async () => {
    try {
      if (_Product.strProName.length === 0) {
        $("#txtproName").on("focus");
        await Show_Message(dispatch, "Please Select Product", "info");
      } else if (Number(_Product.dQty) === 0) {
        await Show_Message(dispatch, "Qty Range should not be Zero(0)", "info");
      } else if (
        Number(_Product.dRate) === 0 &&
        Number(_Product.dRatewithTax) === 0 &&
        !_Common.IsJobWorkDc
      ) {
        $("#txtproRate").on("focus");
        Show_Message(
          dispatch,
          "Rate should not be Zero if Item has Qty",
          "info"
        );
      } else {
        await Pre_Loading(dispatch, true);
        const objParams = {
          bUpdateStatus: _Product.bUpdateStatus,
          iScrId: _Common.iScrId,
          iTaxRelId: _HT.iTaxRelId,
          iPartyId: _HT.iPartyId,
          iVouchId: _HT.iVouchId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,
          iProId: _Product.iProId,
          strJobId: _Product.JobId,
          iSerId: 0,
          iRowIndex: _Product.iRowIndex,
          iUnitId: Number(_Product.iUnitId),
          dQty: Number(_Product.dQty),
          FQty: Number(_Product.FQty),
          dPriceRate: Number(_Product.dRate),
          dPriceRateWithTax: Number(_Product.dRatewithTax),
          dDiscountPer: _Product.isItemDiscPer ? Number(_Product.dItemDisc) : 0,
          dDiscountAmt: _Product.isItemDiscPer ? 0 : Number(_Product.dItemDisc),
          dCashDiscountPer:
            _Product.isCashDiscPer === false ? Number(_Product.dCashDisc) : 0,
          dCashDiscountAmt:
            _Product.isCashDiscPer === true ? Number(_Product.dCashDisc) : 0,
          strProAttDTId: _Product.strProAttDTId,
          strStoAttDTId: _Product.strStoAttDTId,
          strTraAttDTId: _Product.strTraAttDTId,
          BatchNo: _Product.BatchNo,
          ManufactureDate: _Product.MftDate,
          ExpiryDate: _Product.ExpDate,
          isComposPro: Number(_Product.isComposPro),
          isBatchPro: Number(_Product.isBatchPro),
          objBaseTransDT: _DT.arrEntDT,
          objItemRemark: _Product.Remarks,
          objRootCalc: _Product.Calculation,
          objPenCon: _DT.arrEntPenCon,
          objEntAddLess: _DT.arrEntAL,
          objEntConRow: [],
          objEntTax: [],
          strSearch: "",
          strColumn: "",
          Reason: "",
          DimAttDTId: "0",
          iALBillDiscId: _Common.iALBillDiscId,
          strSetCurrentStatus: _Common.strCurrentStatus,
          dAddLessDiscValue: Number(_Disc.dALBillDisValue),
          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dCouponDiscPer: 0,
          dRewardPointper: 0,
          dCouponDiscAmt: 0,
          dRewardPointAmt: 0,
          iWHId: Number(_Product.WHId),
          iYearId: Number(_AuthParems.Year),
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
          iUserid: Number(_AuthParems.User),
        };
        const res = await API_POST(
          `ProductTrans/AddUpd_Product/`,
          objParams,
          dispatch
        );
        if (res) {
          await dispatch(AddProduct(res));
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_ProductUnit = async (ProInfo, Index) => {
    try {
      await Pre_Loading(dispatch, true);
      if (ProInfo.isComposPro === 1) {
        dispatch(
          Modify_Product({
            ProInfo: ProInfo,
            Unit: [
              {
                bIsChecked: false,
                iLeastUnitId: 0,
                iUnitId: ProInfo.unitId,
                strUnitName: ProInfo.unitName,
              },
            ],
            Index: Index,
          })
        );
      } else {
        const res = await API_FETCH(
          `ProductTrans/View_ProductUnit/${ProInfo.proId}/${_Common.strScrType}/${_AuthParems.DB}`,
          dispatch
        );
        if (res) {
          dispatch(
            Modify_Product({
              ProInfo: ProInfo,
              Unit: res.ProUnit,
              Index: Index,
            })
          );
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Unit_Onchange = async (UnitId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `ProductTrans/Unit_Onchange/${Number(UnitId)}/${_Product.iProId}/ ${
          _HT.iPartyId
        }/${_HT.iTaxRelId}/${_Common.strScrType}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        dispatch(Select_Unit({ dPriceRate: res.dPriceRate, UnitId: UnitId }));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Input_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Rate_Onchange = (e) => {
    dispatch(
      ProductRate_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Rate_LostFocus = (e) => {
    dispatch(
      ProductRate_LostFocus({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Disc_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.name,
        Value: e.value,
      })
    );
  };
  const Remove_tableProduct = async (iIndex) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `ProductTrans/Delete_tableProduct/`,
        {
          iRowIndex: iIndex,
          iScrId: _Common.iScrId,
          iALBillDiscId: _Common.iALBillDiscId,
          iTaxCalcOnId: _Common.iTaxCalcOnId,

          iTaxRelId: _HT.iTaxRelId,
          iPartyId: _HT.iPartyId,
          iVouchId: _HT.iVouchId,

          iProId: 0,
          iUnitId: 0,
          dQty: 0,
          dPriceRate: 0,
          dDiscountPer: 0,
          dDiscountAmt: 0,

          strSearch: "",
          strColumn: "",
          strReason: "",
          strJobId: "0",
          strSetCurrentStatus: "",

          strProAttDTId: "",
          strStoAttDTId: "",
          strTraAttDTId: "",
          objItemRemark: {},
          objRootCalc: {},

          dAddLessDiscValue: _Disc.dALBillDisValue,
          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dCouponDiscPer: 0,
          dRewardPointper: 0,
          dCouponDiscAmt: 0,
          dRewardPointAmt: 0,
          objPenCon: _DT.arrEntPenCon,
          objBaseTransDT: _DT.arrEntDT,
          objEntAddLess: _DT.arrEntAL,
          objEntConRow: [],
          objEntTax: _DT.arrEntTax,

          iYearId: Number(_AuthParems.Year),
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
          iUserId: Number(_AuthParems.User),
        },
        dispatch
      );
      if (res) {
        dispatch(Remove_product(res));
      }
    } catch (e) {
      await Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const KeyDown = (e) => {
    if (e.key === "Enter" && e.key === "ArrowUp" && e.key === "ArrowDown") {
      KeyUpDown(e, _Common, _DT);
    }
  };
  return (
    <div className="fg-card shadow-base">
      <div className="row p-2">
        <div className="col-sm-12 col-md-4 mt-2">
          <label>Product Name</label>
          <div style={{ position: "relative" }}>
            <div className="custom-select">
              <input
                type="text"
                className="form-control"
                id="txtProName"
                name="strProName"
                spellCheck={false}
                autoComplete="off"
                placeholder="Press Enter to Select Product "
                value={_Product.strProName}
                tabIndex={_Common.arrtaborder.txtProductName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(ShowHide_Popup("IsProSearch"));
                  }
                }}
                onClick={(e) => {
                  dispatch(ShowHide_Popup("IsProSearch"));
                }}
                onInput={(e) => {
                  dispatch(ShowHide_Popup("IsProSearch"));
                }}
                disabled={_HT.iPartyId === 0 ? true : false}
              />
              <i className="fa-solid fa-caret-down"></i>
            </div>
            {_Product.strProName.length > 0 && (
              <div className="tran-msgicon">
                <i
                  className="fa-solid fa-message"
                  onClick={(e) => dispatch(ShowHide_Popup("IsProRemark"))}
                ></i>
              </div>
            )}
            {_Product.strProName.length > 0 && (
              <div className="tran-calicon">
                <i
                  className="fa-solid fa-calculator"
                  onClick={(e) => dispatch(ShowHide_Popup("IsProCal"))}
                ></i>
              </div>
            )}
          </div>
        </div>
        {!_Common.IsHideJob && (
          <div className="col-sm-12 col-md-3 mt-2">
            <label>Job Name</label>
            <CustomizedSelectoption
              optionparem="isoptionShow16"
              showoption={_selecrparem.isoptionShow16}
              placeholder="Select JobWork"
              taborder={_Common.arrtaborder.cbJob}
              select_value={(val) =>
                Input_Onchange({
                  target: { name: "JobId", value: val },
                })
              }
              btnname="JobWork"
              btnshow={true}
              show_popup={(e) =>
                dispatch(
                  ShowHide_Popup({ Scrtype: "Job", isshow: "IsVenCreation" })
                )
              }
              valueonly={true}
              defaultval={_Product.JobId}
              displayName="strName"
              disvalue="iId"
              arrydata={_Product.arrJobWork}
              EmptVal="Job Work"
              disabled={_Product.iProId === 0 ? true : false}
            />
          </div>
        )}
        <div className="col-sm-6 col-md-2 mt-2">
          <label>Qty</label>
          <input
            type="number"
            className="form-control"
            name="dQty"
            autoComplete="off"
            value={_Product.dQty}
            onClick={(e) => e.target.select()}
            onChange={(e) => {
              Input_Onchange(e);
            }}
            tabIndex={_Common.arrtaborder.txtQty}
            onKeyDown={(e) => KeyDown(e)}
            disabled={_Product.iProId === 0 ? true : false}
          />
        </div>
        <div className="col-sm-6 col-md-2 mt-2">
          <label>Unit</label>
          <CustomizedSelectoption
            optionparem="isoptionShow3"
            showoption={_selecrparem.isoptionShow3}
            placeholder="Select Unit"
            select_value={(val) => Unit_Onchange(val)}
            taborder={_Common.arrtaborder.cbUnit}
            keydown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown");
              else KeyDown(e);
            }}
            btnname="Unit"
            btnshow={false}
            show_popup=""
            valueonly={true}
            defaultval={_Product.iUnitId}
            displayName="strUnitName"
            disvalue="iUnitId"
            arrydata={_Product.Unit}
            disabled={_Product.iProId === 0 ? true : false}
            EmptVal="Unit"
          />
        </div>
        <div className="col-sm-6 col-md-2 mt-2">
          <label>Rate Without Tax</label>
          <input
            type="number"
            aria-label="First name"
            className="form-control"
            name="dRate"
            autoComplete="off"
            id="txtproRate"
            value={_Product.dRate}
            onClick={(e) => e.target.select()}
            onChange={(e) => Rate_Onchange(e)}
            tabIndex={_Common.arrtaborder.txtRate}
            onKeyDown={(e) => KeyDown(e)}
            onBlur={(e) => Rate_LostFocus(e)}
            disabled={_Product.iProId === 0 ? true : false}
          />
        </div>
        <div className="col-sm-6 col-md-2 mt-2" hidden={_Common.IsJobWorkDc}>
          <label>Rate With Tax</label>
          <input
            type="number"
            aria-label="First name"
            className="form-control"
            name="dRatewithTax"
            id="dPriceRatewithTax"
            autoComplete="off"
            value={_Product.dRatewithTax}
            onClick={(e) => e.target.select()}
            onChange={(e) => Rate_Onchange(e)}
            tabIndex={_Common.arrtaborder.txtRate}
            onKeyDown={(e) => KeyDown(e)}
            onBlur={(e) => Rate_LostFocus(e)}
            disabled={_Product.iProId === 0 ? true : false}
          />
        </div>
        {_Common.TransPer.IsItemDisc && !_Common.IsJobWorkDc && (
          <div className="col-sm-6 col-md-2 mt-2">
            <label>Discount</label>
            <div className="trancdisc">
              <input
                type="number"
                className="form-control"
                name="dItemDisc"
                id="txtproItemDis"
                value={_Product.dItemDisc}
                autoComplete="off"
                onClick={(e) => e.target.select()}
                onChange={(e) => Input_Onchange(e)}
                tabIndex={_Common.arrtaborder.txtDiscount}
                onKeyDown={(e) => KeyDown(e)}
                onBlur={(e) => {
                  e.target.value =
                    e.target.value === "" ? (0).toFixed(2) : e.target.value;
                  Input_Onchange(e);
                }}
              />
              <button
                className="btn-fabgreen active"
                onClick={(e) => {
                  $(".trancdisc .btn-fabgreen:eq(0)").toggleClass("active");
                  Disc_Onchange({
                    name: "isItemDiscPer",
                    value: !_Product.isItemDiscPer,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                    $(".trancdisc .btn-fabgreen:eq(0)").toggleClass("active");
                    Disc_Onchange({
                      name: "isItemDiscPer",
                      value: !_Product.isItemDiscPer,
                    });
                  } else KeyDown(e);
                }}
              >
                {_Product.isItemDiscPer ? "%" : "₹"}
              </button>
            </div>
          </div>
        )}
        {_Common.TransPer.IsCashDisc && !_Common.IsJobWorkDc && (
          <div className="col-sm-6 col-md-2 mt-2">
            <label>Cash Discount</label>
            <div className="trancdisc">
              <input
                type="text"
                className="form-control"
                name="dCashDisc"
                id="dCashDisc"
                autoComplete="off"
                value={_Product.dCashDisc}
                onClick={(e) => e.target.select()}
                onChange={(e) => Input_Onchange(e)}
                tabIndex={_Common.arrtaborder.txtDiscount}
                onKeyDown={(e) => KeyDown(e)}
                onBlur={(e) => {
                  e.target.value =
                    e.target.value === "" ? (0).toFixed(2) : e.target.value;
                  Input_Onchange(e);
                }}
              />
              <button
                className="btn-fabgreen active"
                onClick={(e) => {
                  $(".trancdisc .btn-fabgreen:eq(1)").toggleClass("active");
                  Disc_Onchange({
                    name: "isCashDiscPer",
                    value: !_Product.isCashDiscPer,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                    $(".trancdisc .btn-fabgreen:eq(1)").toggleClass("active");
                    Disc_Onchange({
                      name: "isCashDiscPer",
                      value: !_Product.isCashDiscPer,
                    });
                  } else KeyDown(e);
                }}
              >
                {_Product.isCashDiscPer ? "%" : "₹"}
              </button>
            </div>
          </div>
        )}
        {_Common.TransPer.IsFQty && !_Common.IsJobWorkDc && (
          <div className="col-sm-6 col-md-2 mt-2">
            <label>FQTY</label>
            <input
              type="text"
              aria-label="First name"
              className="form-control"
              name="FQty"
              id="FQty"
              autoComplete="off"
              value={_Product.FQty}
              onClick={(e) => e.target.select()}
              onChange={(e) => Input_Onchange(e)}
              tabIndex={_Common.arrtaborder.txtDiscount}
              onKeyDown={(e) => KeyDown(e)}
              onBlur={(e) => {
                e.target.value = e.target.value === "" ? 0 : e.target.value;
                Input_Onchange(e);
              }}
              disabled={_Product.iProId === 0 ? true : false}
            />
          </div>
        )}
        {_Common.TransPer.IsBatchNo &&
          _Product.isBatchPro &&
          !_Common.IsJobWorkDc && (
            <div className="col-sm-6 col-md-2 mt-2">
              <label>Batch No</label>
              <input
                type="text"
                className="form-control"
                placeholder="Batch No"
                autoComplete="off"
                name="BatchNo"
                value={_Product.BatchNo}
                onClick={(e) => e.target.select()}
                onChange={(e) => Input_Onchange(e)}
                onKeyDown={(e) => KeyDown(e)}
                disabled={_Product.iProId === 0 ? true : false}
              />
            </div>
          )}
        {_Common.TransPer.IsMftDate &&
          _Product.isBatchPro &&
          !_Common.IsJobWorkDc && (
            <div className="col-sm-6 col-md-2 mt-2">
              <label>Manufacture Date</label>
              <input
                type="date"
                className="form-control"
                name="MftDate"
                value={_Product.MftDate}
                onClick={(e) => e.target.select()}
                onChange={(e) => Input_Onchange(e)}
                disabled={_Product.iProId === 0 ? true : false}
                onKeyDown={(e) => KeyDown(e)}
              />
            </div>
          )}
        {_Common.TransPer.IsExpDate &&
          _Product.isBatchPro &&
          !_Common.IsJobWorkDc && (
            <div className="col-sm-6 col-md-2 mt-2">
              <label>Expiry Date</label>
              <input
                type="date"
                className="form-control"
                name="ExpDate"
                value={_Product.ExpDate}
                onClick={(e) => e.target.select()}
                onChange={(e) => Input_Onchange(e)}
                disabled={_Product.iProId === 0 ? true : false}
                onKeyDown={(e) => KeyDown(e)}
              />
            </div>
          )}
        <div className="col-sm-4 col-md-2">
          <div className="btn-sub-section mt-3">
            {_Product.strProName.length > 0 && (
              <button
                className="btn-fabgreen"
                onClick={() => AddProductClick()}
                tabIndex={_Common.arrtaborder.btnAdd}
                onKeyDown={(e) => {
                  if (e.key === "Enter") AddProductClick();
                  else KeyDown(e);
                }}
              >
                Add&nbsp;<i className="bx bx-layer-plus"></i>
              </button>
            )}
            {_Product.strProName.length > 0 && (
              <button
                className="btn-fabgreen"
                onClick={() => dispatch(Clear_Product())}
              >
                Clear&nbsp;<i className="fas fa-eraser"></i>
              </button>
            )}
          </div>
        </div>
      </div>
      <ReactTable
        columns={_DT.arrEntDTCol}
        data={_DT.arrEntDT}
        minRows={_Common.MinRow}
        row_click={(ProInfo, Index) => {}}
        row_doubleclick={(ProInfo, Index) => View_ProductUnit(ProInfo, Index)}
        background={true}
        className="full-table"
        tabIndex={_Common.arrtaborder.ProductTableListTab}
        KeyDown={(e) => KeyDown(e)}
      />
    </div>
  );
}

export default TransactionInput;
