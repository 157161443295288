import React, { useState } from "react";
import { useEffect } from "react";

import PrinterSetting from "../Print/PrinterSetting";
import $ from "jquery";
import PrintScreen from "../Print/PrintScreen";
import EmailPopup from "../General/EmailPopup";
import PrintInvoicDesign from "../General/PrintInvoice";
import WhatsApp from "../General/WhatsApp";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import { API_FETCH, API_POST } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function CustomTransView(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [bIsPrintOpen, setbIsPrintOpen] = useState(false);
  const [strSearch, setstrSearch] = useState("");
  const [iVouchId, setiVouchId] = useState(0);
  const [VouchersList, setVouchersList] = useState([]);
  const [IsPrintSetting, setIsPrintSetting] = useState(false);
  const [selected, setselected] = useState(0);
  const [IsOpenEmail, setIsOpenEmail] = useState(false);
  const [IsOpenwhatsapp, setIsOpenwhatsapp] = useState(false);

  useEffect(() => {
    ViewClick();
  }, []);
  const ViewClick = async () => {
    const parems = await `Transaction/View_CustomTrans/${props.ScrId}/${Number(
      _AuthParems.Branch
    )}/${_AuthParems.DB}`;
    const res = await API_FETCH(parems, dispatch);
    if (res.strStatus === "Success") {
      setstrFromDate(res.strFromDate);
      setstrToDate(res.strToDate);
      setVouchersList(res.objVouchers);
      if (res.objVouchers.length > 0) {
        setiVouchId(res.objVouchers[0].vouchId);
      }
    }
  };
  const Search_CustomTrans = async (val, type) => {
    setstrSearch(val);
    let FromDate = "";
    let ToDate = "";
    let Search = "";
    switch (type) {
      case "Search":
        setstrSearch(val);
        Search = val;
        FromDate = strFromDate;
        ToDate = strToDate;
        break;
      case "FromDate":
        setstrFromDate(val);
        FromDate = val;
        ToDate = strToDate;
        Search = strSearch;
        break;
      case "ToDate":
        setstrToDate(val);
        ToDate = val;
        FromDate = strFromDate;
        Search = strSearch;
        break;
      default:
        break;
    }
    const objSearch = {
      iPartyId: 0,
      iScrId: props.ScrId,
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
      strScrType: "",
      strFromDate: FromDate,
      strToDate: ToDate,
      strSearch: Search,
    };
    const res = await API_POST(
      `Transaction/Search_CustomTrans/`,
      objSearch,
      dispatch
    );
    if (res) {
      setVouchersList(res.ovjEntVouch);
    }
  };
  const columns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 100,
    },
    {
      Header: "VouchId",
      accessor: "vouchId",
      show: false,
    },
    {
      Header: "Voucher Number",
      accessor: "vouchNo",
      minWidth: 200,
    },
    {
      Header: "Date",
      accessor: "vouchDate",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Total Qty",
      accessor: "tQty",
      Cell: (props) => props.value.toFixed(2),
    },
    {
      Header: "Net Amount",
      accessor: "netAmt",
      Cell: (props) => props.value.toFixed(2),
      minWidth: 200,
    },
  ];
  const EIcolumns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 100,
    },
    {
      Header: "VouchId",
      accessor: "vouchId",
      show: false,
    },
    {
      Header: "Voucher Number",
      accessor: "vouchNo",
      minWidth: 200,
    },
    {
      Header: "Date",
      accessor: "vouchDate",
    },
    {
      Header: "Vendor Type",
      accessor: "vendorType",
      minWidth: 200,
    },
    {
      Header: "ExpenseIncome Type",
      accessor: "eiType",
      minWidth: 200,
    },
    {
      Header: "Net Amount",
      accessor: "netAmt",
      Cell: (props) => props.value.toFixed(2),
    },
  ];
  const TableKeyDow = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter")
      if (ActiveIndex === 52) {
        if (VouchersList.length > 0) {
          setiVouchId(VouchersList[selected].vouchId);
          props.Close();
          props.View_Invoice(VouchersList[selected].vouchId);
        }
      }
    if (e.key === "ArrowDown") {
      $(".Invoice_table .rt-tbody .rt-tr:eq(1)").focus();
    }
    if (e.key === "ArrowUp") {
      $(".Invoice_table .rt-tbody .rt-tr:eq(1)").focus();
    }
  };
  const PrintPdfClick = async (PrintFor) => {
    try {
      if (iVouchId === 0)
        Show_Message(dispatch, "Please Select Voucher", "info");
      else {
        let VoucherId = iVouchId === undefined ? 0 : iVouchId;
        let TempId = 0;
        Pre_Loading(dispatch, true);
        const res = await API_FETCH(
          "PrintDesing/PrintPreView/" +
            props.ScrId +
            "/" +
            TempId +
            "/" +
            VoucherId +
            "/" +
            _AuthParems.DB,
          dispatch
        );
        if (res) {
          PrintInvoicDesign(res, props.ScrId, PrintFor);
        }
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.strMessage, "info");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{props.Title}</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => setIsPrintSetting(!IsPrintSetting)}
            >
              <i className="fa-solid fa-gear"></i>
            </span>
            <span className="icon-btn">
              <i
                className="fa-regular fa-rectangle-xmark"
                onClick={props.Close}
              ></i>
            </span>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-md-6 col-sm-12 mt-2">
            <div className="m-hsearch">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                autoFocus
                autoComplete="off"
                id="Invoice_Search"
                style={{
                  marginRight: "10px",
                  borderRadius: "3px 0 0 3px !important",
                }}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" ||
                    e.key === "ArrowUp" ||
                    e.key === "ArrowDown"
                  )
                    TableKeyDow(e);
                }}
                tabIndex={52}
                name="strSearch"
                value={strSearch}
                onChange={(e) => Search_CustomTrans(e.target.value, "Search")}
              />
              <i
                className="fas fa-search"
                onClick={(e) => Search_CustomTrans(strSearch, "Search")}
              ></i>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 mt-2">
            <input
              type="date"
              className="form-control "
              value={strFromDate}
              name="strFromDate"
              tabIndex={2}
              style={{
                marginRight: "10px",
                borderRadius: "3px 0 0 3px !important",
              }}
              onChange={(e) => Search_CustomTrans(e.target.value, "FromDate")}
            />{" "}
          </div>
          <div className="col-md-3 col-sm-12 mt-2">
            <input
              type="date"
              tabIndex={3}
              className="form-control"
              value={strToDate}
              name="strToDate"
              onChange={(e) => Search_CustomTrans(e.target.value, "ToDate")}
            />
          </div>
        </div>
        <ReactTable
          columns={props.ScrId === 66 ? EIcolumns : columns}
          data={VouchersList}
          minRows={5}
          row_click={(VouchInfo, Index) => {}}
          row_doubleclick={(VouchInfo, Index) => {
            setiVouchId(VouchInfo.vouchId);
            props.Close();
            props.View_Invoice(VouchInfo.vouchId);
          }}
          background={true}
          className="full-table"
        />
        <div className="popup-footer">
          <div className="popup-btn-group">
            <div className="PTip">
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="icon-btn" href="#">
                  <i
                    className="fa-solid fa-print"
                    onClick={(e) => PrintPdfClick("Print")}
                    tabIndex="1"
                  ></i>
                </a>
              }
              <div className="tooltiptext">Print</div>
            </div>
            <div className="PTip">
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="icon-btn" href="#">
                  <i
                    className="fa-solid fa-download"
                    onClick={(e) => PrintPdfClick("PDF")}
                  ></i>
                </a>
              }
              <div className="tooltiptext">Download</div>
            </div>
            <div
              className="PTip"
              onClick={(e) => setIsOpenwhatsapp(!IsOpenwhatsapp)}
            >
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="icon-btn" href="#">
                  <i className="fa-brands fa-whatsapp" tabIndex="3"></i>
                </a>
              }
              <div className="tooltiptext">WhatsApp</div>
            </div>
            <div className="PTip" onClick={(e) => setIsOpenEmail(!IsOpenEmail)}>
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="icon-btn" href="#">
                  <i className="fa-solid fa-envelope" tabIndex="4"></i>
                </a>
              }
              <div className="tooltiptext">E-Mail</div>
            </div>
          </div>
        </div>
      </div>
      {IsPrintSetting && (
        <PrinterSetting
          Close={(e) => setIsPrintSetting(!IsPrintSetting)}
          iScrId={props.ScrId}
        />
      )}
      {IsOpenEmail && (
        <EmailPopup
          Close={(e) => setIsOpenEmail(!IsOpenEmail)}
          iVouchId={iVouchId}
          VouchersList={VouchersList}
          iScrId={props.ScrId}
          ScrName={props.ScrName}
        />
      )}
      {IsOpenwhatsapp && (
        <WhatsApp
          Close={(e) => setIsOpenwhatsapp(!IsOpenwhatsapp)}
          iVouchId={iVouchId}
          iScrId={props.ScrId}
          ScrName={props.ScrName}
        />
      )}
      {bIsPrintOpen && (
        <PrintScreen
          Close={(e) => setbIsPrintOpen(!bIsPrintOpen)}
          iScrId={props.ScrId}
          iFormateId={0}
          iVouchId={iVouchId}
        />
      )}
    </div>
  );
}
export default CustomTransView;
