import React, { useEffect, useState } from "react";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST } from "../General/Utility";

function EmployeeRole(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _parem = useSelector((state) => state.SelectOption.parems);

  const [iRoleId, setiRoleId] = useState(0);
  const [strRoleName, setstrRoleName] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [iStatus, setiStatus] = useState(1);
  const [arrEmpRole, setarrEmpRole] = useState([]);
  const [arrStatus, setarrStatus] = useState([]);

  useEffect(() => {
    Load_EmployeeRole();
  }, []);
  const Load_EmployeeRole = async () => {
    try {
      const res = await API_FETCH(
        `EmployeeMaster/Load_EmployeeRole/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        setarrEmpRole(res.EmployeeRole);
        setarrStatus(res.EntStatus);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Clear_Click = () => {
    setiRoleId(0);
    setstrRoleName("");
    setstrDescription("");
    setiStatus(1);
    setarrEmpRole([]);
    setarrStatus([]);
    Load_EmployeeRole();
  };
  const Save_EmployeeRole = async () => {
    try {
      const parems = {
        iRoleId: iRoleId,
        strRoleName: strRoleName,
        strDescription: strDescription,
        iStatus: iStatus,
        iBranchId: _AuthParems.Branch,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `EmployeeMaster/InsUpd_EmployeeRole`,
        parems,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_EmployeeRole = async (role) => {
    setiRoleId(role.iRoleId);
    setstrRoleName(role.strRoleName);
    setstrDescription(role.strDescription);
    setiStatus(role.iStatus);
  };
  const Delete_EmployeeRole = async () => {
    try {
      const res = await API_POST(
        `EmployeeMaster/Delete_EmployeeRole/${Number(iRoleId)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Employee Role</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <div className="data-list">
                {arrEmpRole.map((Role, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrEmpRole.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_EmployeeRole(Role);
                      }
                    }}
                  >
                    <label onClick={(e) => View_EmployeeRole(Role)}>
                      {Role.strRoleName}
                    </label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 mt-1">
                  <label>
                    Role Name <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strRoleName}
                    tabIndex="52"
                    onChange={(e) => setstrRoleName(e.target.value)}
                    placeholder="Category Name"
                    autoComplete="off"
                    spellCheck={false}
                    autoFocus
                  />
                </div>
                <div className="col-md-12 col-sm-12 mt-1">
                  <label>Description</label>
                  <textarea
                    tabIndex="53"
                    className="form-control"
                    value={strDescription}
                    onChange={(e) => setstrDescription(e.target.value)}
                    placeholder="Description"
                    autoComplete="off"
                    spellCheck={false}
                    style={{ minHeight: "100px" }}
                  />
                </div>
                <div className="col-md-12 col-sm-12 mt-1">
                  <label>Status</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow6"
                    showoption={_parem.isoptionShow6}
                    placeholder="Select Status"
                    select_value={(val) => setiStatus(val)}
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStatus}
                    displayName="strStatusName"
                    disvalue="iStatusId"
                    arrydata={arrStatus}
                    disabled={false}
                    EmptVal="Status "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex="54"
              onClick={(e) => {
                if (strRoleName === "") {
                  Show_Message(dispatch, "Please Enter Role Name", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    Save_EmployeeRole,
                    "Do You Want to  Save Employee Role?"
                  );
                }
              }}
            >
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex="55"
              onClick={(e) => {
                if (Number(iRoleId) === 0) {
                  Show_Message(dispatch, "Please Select Employee Role", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    Delete_EmployeeRole,
                    "Do You Want to Delete Employee Role?"
                  );
                }
              }}
            >
              Delete&nbsp; <i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              tabIndex="56"
              onClick={Clear_Click}
            >
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex="57"
              onClick={props.Close}
            >
              Close&nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeRole;
