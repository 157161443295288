import React, { useEffect, useState } from "react";

import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "../General/Utility";
function UnitMaster(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const iScrId = 64;

  const [iUnitId, setiUnitId] = useState(0);
  const [strUnitName, setstrUnitName] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [iLeastUnit, setiLeastUnit] = useState(0);
  const [iUQC, setiUQC] = useState(0);
  const [dConvertValue, setdConvertValue] = useState(0);
  const [bChkLeastUnit, setbChkLeastUnit] = useState(false);
  const [bChkNonLeastUnit, setbChkNonLeastUnit] = useState(true);
  const [strSearch, setstrSearch] = useState("");

  const [ListEntUnit, setListEntUnit] = useState([]);
  const [ListEntLeast, setListEntLeast] = useState([]);
  const [ListEntUqc, setListEntUqc] = useState([]);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);

  useEffect(() => {
    Load_Unitmaster();
  }, []);
  const Load_Unitmaster = async (e) => {
    try {
      const res = await API_FETCH(
        `UnitMaster/Load_UnitMaster/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setListEntUnit(res.objEntUnit);
        setListEntLeast(res.objLeastUnit);
        setListEntUqc(res.objLoadUQC);
        ButtonPermission(_AuthParems.MasterPer, iUnitId);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const InsUpdUnit = {
        iTitleTag: iScrId,
        iUnitId: iUnitId,
        strUnitName: strUnitName,
        strPrintName: strPrintName,
        iLeastUnit: bChkLeastUnit ? 0 : Number(iLeastUnit),
        iUQC: Number(iUQC),
        dConvertValue: bChkLeastUnit ? 1 : Number(dConvertValue),
        bChkLeastUnit: bChkLeastUnit,
        strLeastUnitName: bChkLeastUnit ? "" : "strLeastUnitName",
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "UnitMaster/InsUpd_UnitMaster/",
        InsUpdUnit,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = async () => {
    setstrUnitName("");
    setstrPrintName("");
    setiLeastUnit(0);
    setiUQC(0);
    setdConvertValue(0);
    setbChkLeastUnit(false);
    setbChkNonLeastUnit(true);
    setstrSearch("");
    setiUnitId(0);
    setBtnDeleteDisable(false);
    setBtnSaveDisable(false);
    await Load_Unitmaster();
  };
  const View_Unit = async (iUnitId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `UnitMaster/View_UnitMaster/${iUnitId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        let Unit = res.EntUnit;
        setiUnitId(Unit.iUnitId);
        setstrUnitName(Unit.strUnitName);
        setstrPrintName(Unit.strPrintName);
        setiLeastUnit(Unit.iLeastUnit);
        setiUQC(Unit.iUQC);
        setdConvertValue(Unit.dConvertValue);
        setbChkLeastUnit(Unit.bChkLeastUnit);
        setbChkNonLeastUnit(Unit.bChkLeastUnit ? false : true);
        ButtonPermission(_AuthParems.MasterPer, Unit.iUnitId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `UnitMaster/Delete_UnitMaster/${iUnitId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_UnitMaster = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strSearch: strtxt,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `UnitMaster/Search_UnitMaster/`,
        objSearch,
        dispatch
      );
      if (res) {
        setListEntUnit(res.EntUnit);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const TabIndex = (e) => {
    var maxTab = 61;
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 50 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + maxTab + "']").focus();
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 61) {
          $(".Unit .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 61) {
          $(".Unit .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 61) {
          $(".Unit .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $(".Unit .ReactTable .rt-tbody .rt-tr:eq(0)").focus();
      }
    }
  };
  const ButtonPermission = (Menu, UnitId) => {
    Menu = Menu.filter((Data) => Data.iScrId === 64);
    if (Menu.length > 0) {
      if (Number(UnitId) > 0) {
        setBtnSaveDisable(!Menu[0].isModify);
        setBtnDeleteDisable(!Menu[0].isDelete);
      }
      if (Number(UnitId) === 0) setBtnSaveDisable(!Menu[0].isCreate);
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Unit Master </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn">
              <i
                className="fa-regular fa-rectangle-xmark"
                onClick={(e) => {
                  if (props.Close === undefined)
                    window.location.href = `/JustBill/DashBoard`;
                  else props.Close();
                }}
              ></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <div className="m-hsearch">
                <input
                  type="search"
                  placeholder="Search Unit Name"
                  tabIndex="60"
                  onKeyDown={(e) => TabIndex(e)}
                  name="strSearch"
                  value={strSearch}
                  onChange={(e) => Search_UnitMaster(e.target.value)}
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_UnitMaster(strSearch)}
                ></i>
              </div>
              <div className="data-list">
                {ListEntUnit.map((Unit, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = ListEntUnit.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_Unit(Unit.iUnitId);
                      }
                    }}
                  >
                    <label onClick={(e) => View_Unit(Unit.iUnitId)}>
                      {Unit.strUnitName}
                    </label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      tabIndex="50"
                      onKeyDown={(e) => TabIndex(e)}
                      checked={bChkLeastUnit}
                      onChange={(e) => {
                        setbChkLeastUnit(!bChkLeastUnit);
                        setbChkNonLeastUnit(false);
                      }}
                    />
                    <label>Least Unit</label>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      tabIndex="51"
                      onKeyDown={(e) => TabIndex(e)}
                      checked={bChkNonLeastUnit}
                      onChange={(e) => {
                        setbChkLeastUnit(false);
                        setbChkNonLeastUnit(!bChkNonLeastUnit);
                      }}
                    />
                    <label>Non-Least Unit</label>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Least Unit</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow20"
                    showoption={_parem.isoptionShow20}
                    placeholder="Select Least Unit"
                    select_value={(val) => setiLeastUnit(val)}
                    btnname="Unit"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={iLeastUnit}
                    displayName="strLeastUnitName"
                    disvalue="iLeastUnitId"
                    arrydata={ListEntLeast}
                    EmptVal="Least Unit"
                    disabled={bChkLeastUnit}
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>UQC</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow21"
                    showoption={_parem.isoptionShow21}
                    placeholder="Select UQC"
                    select_value={(val) => setiUQC(val)}
                    btnname="Unit"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={iUQC}
                    displayName="strUQCName"
                    disvalue="iUQCId"
                    arrydata={ListEntUqc}
                    EmptVal="UQC"
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <label>Unit Name</label>
                  <input
                    type="text"
                    className="form-control"
                    tabIndex="52"
                    spellCheck={false}
                    onKeyDown={(e) => TabIndex(e)}
                    autoFocus
                    value={strUnitName}
                    onChange={(e) => setstrUnitName(e.target.value)}
                    placeholder="Unit Name"
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Print Name</label>
                  <input
                    type="text"
                    aria-label="First name"
                    tabIndex="53"
                    onKeyDown={(e) => TabIndex(e)}
                    spellCheck={false}
                    className="form-control"
                    value={strPrintName}
                    onChange={(e) => setstrPrintName(e.target.value)}
                    placeholder="Print Name"
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Convert Value</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    tabIndex="54"
                    onKeyDown={(e) => TabIndex(e)}
                    onClick={(e) => e.target.select()}
                    value={dConvertValue}
                    onChange={(e) => setdConvertValue(e.target.value)}
                    onBlur={(e) => {
                      if (e.target.value === "") setdConvertValue(0);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex="57"
              disabled={BtnSaveDisable}
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => {
                if (strUnitName === "") {
                  Show_Message(dispatch, "Please Enter UnitName", "error");
                } else if (iUQC === 0) {
                  Show_Message(dispatch, "Please Select UQC", "error");
                } else if (iLeastUnit === 0 && !bChkLeastUnit) {
                  Show_Message(dispatch, "Please Select LeastUnit", "error");
                } else if (dConvertValue === 0 && !bChkLeastUnit) {
                  Show_Message(
                    dispatch,
                    "Please Enter Convertion Value",
                    "error"
                  );
                } else {
                  Ask_Question(
                    dispatch,
                    SaveClick,
                    "Do You Want to Save Unit?"
                  );
                }
              }}
            >
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              tabIndex="58"
              onKeyDown={(e) => TabIndex(e)}
              disabled={BtnDeleteDisable}
              onClick={(e) => {
                if (iUnitId === 0) {
                  Show_Message(dispatch, "Please Select Unit", "error");
                } else {
                  Ask_Question(
                    dispatch,
                    DeleteClick,
                    "Do You Want to Delete Unit?"
                  );
                }
              }}
            >
              Delete&nbsp; <i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="Reset"
              className="btn-fabgreen"
              tabIndex="59"
              onKeyDown={(e) => TabIndex(e)}
              onClick={ClearClick}
            >
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UnitMaster;
