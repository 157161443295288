import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import $ from "jquery";
import { API_FETCH, API_POST } from "../General/Utility";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Country_OnChange } from "../Master/MasterTransaction";
import EmployeeRole from "./EmployeeRole";

function EmployeeMaster() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _parem = useSelector((state) => state.SelectOption.parems);

  const [iEmpid, setiEmpid] = useState(0);
  const [strEmpName, setstrEmpName] = useState("");
  const [iRoleId, setiRoleId] = useState(0);
  const [strAdd1, setstrAdd1] = useState("");
  const [strAdd2, setstrAdd2] = useState("");
  const [strAdd3, setstrAdd3] = useState("");
  const [strAdd4, setstrAdd4] = useState("");
  const [strPincode, setstrPincode] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strPhone, setstrPhone] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [iCountry, setiCountry] = useState(100);
  const [iState, setiState] = useState(32);
  const [iStatus, setiStatus] = useState(1);

  const [strJoinDate, setstrJoinDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dRewardPoint, setdRewardPoint] = useState(0);
  const [arrEmployee, setarrEmployee] = useState([]);
  const [arrEmpRole, setarrEmpRole] = useState([]);
  const [arrCountry, setarrCountry] = useState([]);
  const [arrState, setarrState] = useState([]);
  const [arrStatus, setarrStatus] = useState([]);

  const [IsUpdate, setIsUpdate] = useState(false);
  const [IsEmpRole, setIsEmpRole] = useState(false);

  useEffect(() => {
    Load_Employees();
  }, []);
  const Load_Employees = async () => {
    try {
      const res = await API_FETCH(
        `EmployeeMaster/Load_Employees/${Number(_AuthParems.Branch)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        setarrEmployee(res.Employee);
        setarrEmpRole(res.EmployeeRole);
        setarrCountry(res.EntCountry);
        setarrState(res.EntState);
        setarrStatus(res.EntStatus);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Save_Employee = async () => {
    try {
      const objEntSession = {
        iEmpId: iEmpid,
        strEmpName: strEmpName,
        strAdd1: strAdd1,
        strAdd2: strAdd2,
        strAdd3: strAdd3,
        strAdd4: strAdd4,
        strPincode: strPincode,
        iState: iState,
        iCountry: iCountry,
        strMobile: strMobile,
        strPhone: strPhone,
        strEmail: strEmail,
        strEmpRole: "",
        iRoleId: iRoleId,
        dRewardPoint: dRewardPoint,
        strJoinDate: strJoinDate,
        iWHId: 0,
        iBranchId: _AuthParems.Branch,
        strDBName: _AuthParems.DB,
        iStatus: iStatus,
      };
      const res = await API_POST(
        `EmployeeMaster/InsUpd_Employee`,
        objEntSession,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Close_Employee();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_Employee = (staff) => {
    setIsUpdate(true);
    setiEmpid(staff.iEmpId);
    setstrEmpName(staff.strEmpName);
    setiRoleId(staff.iRoleId);
    setstrAdd1(staff.strAdd1);
    setstrAdd2(staff.strAdd2);
    setstrAdd3(staff.strAdd3);
    setstrAdd4(staff.strAdd4);
    setstrPincode(staff.strPincode);
    setiState(staff.iState);
    setiCountry(staff.iCountry);
    setstrEmail(staff.strEmail);
    setstrMobile(staff.strMobile);
    setstrPhone(staff.strPhone);
    setdRewardPoint(staff.dRewardPoint);
    setstrJoinDate(staff.strJoinDate);
    setiRoleId(staff.iRoleId);
    setiStatus(staff.iStatus);
  };
  const Delete_Employee = async () => {
    try {
      const res = await API_POST(
        `EmployeeMaster/Delete_Employee/${Number(iEmpid)}/${_AuthParems.DB}`,

        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Close_Employee();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Close_Employee = () => {
    setiEmpid(0);
    setiRoleId(0);
    setstrJoinDate(new Date().toISOString().split("T")[0]);
    setarrEmployee([]);
    setarrEmpRole([]);
    setIsUpdate(false);
    Load_Employees();
  };
  const New_Employee = () => {
    setiEmpid(0);
    setstrJoinDate(new Date().toISOString().split("T")[0]);
    setarrEmployee([]);
    setIsUpdate(true);
  };
  const arrTableCol = [
    {
      strAliasName: "Employee Name",
      strColName: "strEmpName",
      strTextAlign: "left",
      strWidth: "100%",
      color: "black",
    },
    {
      strAliasName: "Role",
      strColName: "strEmpRole",
      strTextAlign: "center",
      strWidth: "150",
      color: "black",
    },
    {
      strAliasName: "Reward Point",
      strColName: "dRewardPoint",
      strTextAlign: "center",
      strWidth: "150",
      color: "black",
    },
    {
      strAliasName: "Mobile",
      strColName: "strMobile",
      strTextAlign: "center",
      strWidth: "150",
      color: "black",
    },
    {
      strAliasName: "Update",
      strTextAlign: "center",
      strWidth: "50",
      color: "black",
      cell: ({ Employee }) => (
        <i
          className="fa-solid fa-pen-to-square"
          onClick={(e) => {
            setIsUpdate(true);
            View_Employee(Employee);
          }}
        ></i>
      ),
    },
  ];
  return (
    <section className="Config-card">
      <div className="config-h">
        <h3>Employee Master</h3>
        <div className="Config-btn">
          <button onClick={(e) => New_Employee()} className="btn-fabgreen">
            New Employee <i className="fa-solid fa-user-plus"></i>
          </button>
        </div>
      </div>
      <div className="Config-Wrapper">
        {!IsUpdate && (
          <div className="table-card">
            <table className="option-list">
              <thead>
                <tr>
                  {arrTableCol.map((ht, Index) => (
                    <th key={Index}>{ht.strAliasName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {arrEmployee.map((Emp, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrEmployee.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") View_Employee(Emp);
                    }}
                    onClick={(e) => View_Employee(Emp)}
                    onDoubleClick={(e) => View_Employee(Emp)}
                  >
                    {arrTableCol.map((dt, Index) =>
                      dt.cell ? (
                        <td
                          key={Index}
                          style={{
                            textAlign: dt.strTextAlign,
                            minWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            width: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            maxWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            color: "black",
                          }}
                        >
                          <dt.cell key={Index} Employee={Emp} />
                        </td>
                      ) : (
                        <td
                          key={Index}
                          style={{
                            textAlign: dt.strTextAlign,
                            minWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            width: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            maxWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            color: "black",
                          }}
                        >
                          {Emp[dt.strColName]}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {IsUpdate && (
          <div className="row p-2">
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Employee Name</label>
              <input
                type="text"
                className="form-control"
                value={strEmpName}
                onChange={(e) => setstrEmpName(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Employee Name"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Employee Role</label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_parem.isoptionShow}
                placeholder="Select Employee Role"
                select_value={(val) => setiRoleId(val)}
                btnname="Employee Role"
                btnshow={true}
                show_popup={() => setIsEmpRole(!IsEmpRole)}
                valueonly={true}
                defaultval={iRoleId}
                displayName="strRoleName"
                disvalue="iRoleId"
                arrydata={arrEmpRole}
                disabled={false}
                EmptVal="Employee Role"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Door/Offfice No</label>
              <input
                type="text"
                className="form-control"
                value={strAdd1}
                onChange={(e) => setstrAdd1(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Door/Offfice No"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Street/Area</label>
              <input
                type="text"
                className="form-control"
                value={strAdd2}
                onChange={(e) => setstrAdd2(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Street/Area"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Vilalge/Town</label>
              <input
                type="text"
                className="form-control"
                value={strAdd3}
                onChange={(e) => setstrAdd3(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Vilalge/Town"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                value={strAdd4}
                onChange={(e) => setstrAdd4(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="City"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Pincode</label>
              <input
                type="text"
                className="form-control"
                value={strPincode}
                onChange={(e) => setstrPincode(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Pincode"
              />
            </div>
            <div className="col-md-4 col-sm-12 mb-1">
              <label>Country</label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_parem.isoptionShow1}
                placeholder="Select Country"
                select_value={async (val) => {
                  await setiCountry(val);
                  let State = await Country_OnChange(
                    val,
                    _AuthParems.DB,
                    dispatch
                  );
                  await setarrState(State);
                }}
                btnname="Country"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iCountry}
                displayName="strCountryName"
                disvalue="iCountryId"
                arrydata={arrCountry}
                disabled={false}
                EmptVal="Country"
              />
            </div>
            <div className="col-md-4 col-sm-12 mb-1">
              <label>State</label>
              <CustomizedSelectoption
                optionparem="isoptionShow2"
                showoption={_parem.isoptionShow2}
                placeholder="Select State"
                select_value={(val) => setiState(val)}
                btnname="State"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iState}
                displayName="strSName"
                disvalue="iSId"
                arrydata={arrState}
                disabled={false}
                EmptVal="State "
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Mobile Number</label>
              <input
                type="number"
                className="form-control"
                value={strMobile}
                onChange={(e) => setstrMobile(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Mobile Number"
              />{" "}
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Phone Number</label>
              <input
                type="number"
                className="form-control"
                value={strPhone}
                onChange={(e) => setstrPhone(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Phone Number"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>E-Mail</label>
              <input
                type="email"
                className="form-control"
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="example@gmail.com"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Joining Date</label>
              <input
                type="date"
                className="form-control"
                value={strJoinDate}
                onChange={(e) => setstrJoinDate(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Joining Date"
              />
            </div>
            <div className="col-md-4 col-sm-12 mt-1">
              <label>Reward Point</label>
              <input
                type="text"
                className="form-control"
                value={dRewardPoint}
                onChange={(e) => setdRewardPoint(e.target.value)}
                onClick={(e) => e.target.select()}
                placeholder="Reward Point"
              />
            </div>
            <div className="col-md-4 col-sm-12 mb-1">
              <label>Status</label>
              <CustomizedSelectoption
                optionparem="isoptionShow3"
                showoption={_parem.isoptionShow3}
                placeholder="Select Status"
                select_value={(val) => setiStatus(val)}
                btnname="State"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iStatus}
                displayName="strStatusName"
                disvalue="iStatusId"
                arrydata={arrStatus}
                disabled={false}
                EmptVal="Status "
              />
            </div>
          </div>
        )}
        {IsEmpRole && <EmployeeRole Close={() => setIsEmpRole(!IsEmpRole)} />}
      </div>
      {IsUpdate && (
        <div className="btn-section mt-1">
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (strEmpName === "")
                Show_Message(dispatch, "Please Enter Employee Name", "info");
              else
                Ask_Question(
                  dispatch,
                  Save_Employee,
                  "Do You Want to Save New Employee?"
                );
            }}
          >
            <i className="fa-regular fa-floppy-disk"></i>
            Save
          </button>
          <button
            className="btn-fabgreen"
            onClick={() => {
              if (Number(iEmpid) === 0)
                Show_Message(dispatch, "Please Select Employee", "info");
              else
                Ask_Question(
                  dispatch,
                  Delete_Employee,
                  "Do You Want to Delete Employee?"
                );
            }}
          >
            <i className="fa-solid fa-trash"></i>
            Delete
          </button>
          <button onClick={(e) => Close_Employee()} className="btn-fabgreen">
            Close <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      )}
    </section>
  );
}

export default EmployeeMaster;
