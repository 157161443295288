import { createSlice } from "@reduxjs/toolkit";

export const MasterSlice = createSlice({
  name: "Master",
  initialState: {
    Master: {
      arrMasterData: [],
      displayName: "",
      displayValue: "",
      tabOrder: 0,
      isImg: false,
    },
  },
  reducers: {
    setMasterDefault: (state, action) => {
      state.Master = action.payload;
    },
  },
});
export const { setMasterDefault } = MasterSlice.actions;
export default MasterSlice.reducer;
