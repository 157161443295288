import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import $ from "jquery";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
function Receipt(props) {
  const dispatch = useDispatch();
  const [iRecId, setiRecId] = useState(0);
  const [iBankAccId, setiBankAccId] = useState(0);
  const [iBankId, setiBankId] = useState(0);
  const [strChequeNo, setstrChequeNo] = useState("");
  const [strChequeDate, setstrChequeDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strAccNo, setstrAccNo] = useState("");
  const [strAccHolderName, setstrAccHolderName] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [dPaidAmt, setdPaidAmt] = useState(0);
  const [strIFSC, setstrIFSC] = useState("");
  useEffect(() => {
    setdPaidAmt(props.NetAmt);
  }, []);
  const Save_Receipt = () => {
    const objInsUpdRec = {
      dAmount: dPaidAmt,
      iBankAccId: iBankAccId,
      strChequeNo: strChequeNo,
      strChequeDate: strChequeDate,
      strAccNo: strAccNo,
      strAccHolderName: strAccHolderName,
      strPrintName: "",
      strRemark: strRemark,
      iBankId: iBankId,
    };
    props.Save_Invoice(objInsUpdRec);
  };
  const Clear_Parem = () => {
    setiRecId(0);
    setiBankAccId(0);
    setiBankId(0);
    setstrChequeNo("");
    setstrAccNo("");
    setstrAccHolderName("");
    setstrRemark("");
    setdPaidAmt(0);
    setstrIFSC(0);
    setstrChequeDate(new Date().toISOString().split("T")[0]);
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Receipt</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body rctbody p-2">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="net-amount">
                <h1>NET AMOUNT</h1>
                <h2>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(props.NetAmt).toFixed(2))}
                </h2>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="net-amount">
                <h1>PAID AMOUNT</h1>
                <h2>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(dPaidAmt).toFixed(2))}
                </h2>
              </div>
            </div>
          </div>
          <div className=" mt-2">
            <label>Enter Paid Amount</label>
            <input
              type="number"
              className="form-control"
              value={dPaidAmt}
              onChange={(e) => setdPaidAmt(e.target.value)}
              placeholder="Description"
              onClick={(e) => e.target.select()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setdPaidAmt(Number(dPaidAmt).toFixed(2));
                  $("[tabindex = 1000]").focus();
                }
              }}
              autoFocus
            />
          </div>
          {props.PayMode === 3 && (
            <div className="mt-2">
              <input
                type="text"
                className="form-control"
                value={strChequeNo}
                onChange={(e) => setstrChequeNo(e.target.value)}
                placeholder="Cheque No"
                onClick={(e) => e.target.select()}
              />
            </div>
          )}
          {props.PayMode === 3 && (
            <div className="mt-2">
              <label>Cheque Date</label>
              <input
                type="date"
                className="form-control"
                value={strChequeDate}
                onChange={(e) => setstrChequeDate(e.target.value)}
                onClick={(e) => e.target.select()}
              />
            </div>
          )}
          {props.PayMode === 4 && (
            <div className="mt-2">
              <input
                type="number"
                className="form-control"
                value={strAccNo}
                onChange={(e) => setstrAccNo(e.target.value)}
                placeholder="Account No"
                onClick={(e) => e.target.select()}
              />
            </div>
          )}
          {props.PayMode === 4 && (
            <div className="mt-2">
              <input
                type="number"
                className="form-control"
                value={strIFSC}
                onChange={(e) => setstrIFSC(e.target.value)}
                placeholder="IFSC Code"
                onClick={(e) => e.target.select()}
              />
            </div>
          )}
          {props.PayMode === 4 && (
            <div className="mt-2">
              <input
                type="text"
                className="form-control"
                value={strAccHolderName}
                onChange={(e) => setstrAccHolderName(e.target.value)}
                placeholder="Account Holder Name"
                onClick={(e) => e.target.select()}
              />
            </div>
          )}
          {props.PayMode !== 2 && (
            <div className="mt-2">
              <input
                type="text"
                className="form-control"
                value={strRemark}
                onChange={(e) => setstrRemark(e.target.value)}
                placeholder="Description"
                onClick={(e) => e.target.select()}
              />
            </div>
          )}
        </div>
        <div className="popup-footer mt-2">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(dPaidAmt) === 0)
                  Show_Message(dispatch, "Please Enter Paid Amount", "info");
                else
                  Ask_Question(
                    dispatch,
                    Save_Receipt,
                    "Do You Want to  Save Receipt?"
                  );
              }}
            >
              Save&nbsp;<i className="fas fa-save"></i>
            </button>
            <button onClick={(e) => Clear_Parem()} className="btn-fabgreen">
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Receipt;
