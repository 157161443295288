import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST } from "../General/Utility";
import {
  Clear_Transaction,
  ShowHide_Popup,
} from "../features/Transactionstates";
import { Load_Transaction } from "../Transaction/Transaction";
function ReceiptPopup() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [strTransNo, setstrTransNo] = useState("");
  const [strVouchNo, setstrVouchNo] = useState("");
  const [dNetAmount, setdNetAmount] = useState(0);
  const [dPaidAmt, setdPaidAmt] = useState(0);
  const [strAccNo, setstrAccNo] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [strVenName, setstrVenName] = useState("");
  const [strAccHolderName, setstrAccHolderName] = useState("");
  const [strChequeNo, setstrChequeNo] = useState("");
  const [dpChequeDate, setdpChequeDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [BNId, setBNId] = useState(0);
  const [strBankName, setstrBankName] = useState("");
  const [iPayModeId, setiPayModeId] = useState(0);
  const [iBankAccId, setiBankAccId] = useState(0);
  const [ListNoFormat, setListNoFormat] = useState([]);
  const [ListPayMode, setListPayMode] = useState([]);
  const [ListVoucherDetails, setListVoucherDetails] = useState();
  const [IsCheckPayment, setIsCheckPayment] = useState(false);
  const [IsBankpayment, setIsBankpayment] = useState(false);
  const [arrAllBank, setarrAllBank] = useState([]);

  useEffect(() => {
    Load_Receipt();
  }, []);
  const Load_Receipt = async () => {
    const res = await API_FETCH(
      `Receipt/View_ReceiptVoucher/${Number(_AuthParems.Branch)}/${
        _Common.iScrId
      }/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      setListNoFormat(res.EntNoFormat);
      setListPayMode(res.EntPayMode);
      let EntVoucher = res.EntVoucher;
      setListVoucherDetails(EntVoucher);
      setstrTransNo(res.EntNoFormat[0].strTransNo);
      setstrVouchNo(res.EntNoFormat[0].strUserNo);
      setstrVenName(EntVoucher.venName);
      setdNetAmount(EntVoucher.netAmt.toFixed(2));
      setdPaidAmt(EntVoucher.netAmt.toFixed(2));
      switch (EntVoucher.bT_Id) {
        case 2: // Cash
          setiPayModeId(1);
          setIsCheckPayment(false);
          setIsBankpayment(false);
          ClearClick();
          break;
        default:
          let PayMode = 0;
          if (EntVoucher.bT_Id === 3) {
            //Cheque
            PayMode = 2;
          }
          if (EntVoucher.bT_Id === 4) {
            //Bank
            PayMode = 7;
          }
          PayMode_OnSelectionChange(PayMode, EntVoucher);
          break;
      }
    }
  };
  const Save_Receipt = async () => {
    const objInsUpdRec = {
      strPrefix: ListNoFormat[0].strPrefix,
      iNumSeqNo: ListNoFormat[0].iNumSeqNo,
      strCompCode: ListNoFormat[0].strCompCode,
      strFinanFrom: ListNoFormat[0].strFinanFrom,
      strRecNo: strVouchNo,
      strTransNo: strTransNo,
      dpRecDate: _HT.strVouchDate,
      iVenType: _Common.VenTypeId,
      iVenId: ListVoucherDetails.venId,
      iPayModeId: iPayModeId,
      dAmount: dPaidAmt,
      iVouchId: ListVoucherDetails.vouchId,
      iBankAccId: iBankAccId,
      strChequeNo: strChequeNo,
      dpChequeDate: iPayModeId === 2 ? dpChequeDate : _HT.strVouchDate,
      strAccNo: strAccNo,
      strAccHolderName: strAccHolderName,
      strPrintName: "",
      strRemark: strRemark,
      iBNId: BNId,
      iRecId: 0,
      iCallerScrId: _Common.iScrId,
      strBankName: strBankName,
      strFilter: "",
      strFilterColumn: "",
      strSearch: "",
      strVenName: strVenName,
      strDBName: _AuthParems.DB,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(
      "Receipt/InsUpd_Receipt/",
      objInsUpdRec,
      dispatch
    );
    if (res) {
      Show_Message(dispatch, res.strMessage, "success");
      Close_Click();
    }
  };
  const ClearClick = () => {
    setstrAccNo("");
    setstrChequeNo("");
    setBNId(0);
    setiBankAccId(0);
    setstrAccHolderName("");
    setstrBankName("");
    setdpChequeDate(new Date().toISOString().split("T")[0]);
  };
  const PayMode_OnSelectionChange = async (PayMode, VoucherInfo) => {
    setiPayModeId(Number(PayMode));
    Pre_Loading(dispatch, true);
    if (Number(PayMode) === 1) {
      setIsCheckPayment(false);
      setIsBankpayment(false);
    } else if (Number(PayMode) === 2) {
      setIsCheckPayment(true);
      setIsBankpayment(false);
    } else if (Number(PayMode) !== 2 || Number(PayMode) !== 1) {
      setIsBankpayment(true);
      setIsCheckPayment(false);
    } else if (Number(PayMode) !== 1) {
      const res = await API_FETCH(
        "Receipt/View_PartyBank/" +
          VoucherInfo.venId +
          "/" +
          _Common.VenTypeId +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        setstrChequeNo("");
        if (res.objPartyBank.length > 0) {
          setstrAccHolderName(res.objPartyBank[0].strAccHolderName);
          setBNId(res.objPartyBank[0].iBankNameId);
          setstrBankName(res.objPartyBank[0].strBankName);
          setstrAccNo(res.objPartyBank[0].strAccNo);
        }
      }
    }
  };
  const SearchBank = async (strtext) => {
    const objSearch = {
      strSearch: strtext,
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(`Receipt/View_AllBank`, objSearch, dispatch);
    if (res) setarrAllBank(res.EntBank);
  };
  const Close_Click = async () => {
    try {
      await dispatch(ShowHide_Popup("IsReceipt"));
      await dispatch(Clear_Transaction());
      await Load_Transaction(dispatch, _Common, _Common.iScrId);
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content" style={{ maxWidth: "400px" }}>
        <div className="popup-header">
          <h5>Receipt</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={() => Close_Click()}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-6 col-sm-12 mt-2">
              <label>Party Name</label>
              <input className="form-control" value={strVenName} readOnly />
            </div>
            <div className="col-md-6 col-sm-6 mt-2">
              <label>Payment Mode</label>
              <CustomizedSelectoption
                optionparem="isoptionShow16"
                showoption={_selecrparem.isoptionShow16}
                placeholder="Select Payment Mode"
                select_value={(val) =>
                  PayMode_OnSelectionChange(val, ListVoucherDetails)
                }
                btnname="Payment Mode"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={iPayModeId}
                displayName="strPaymentMode"
                disvalue="iPM_Id"
                arrydata={ListPayMode}
                EmptVal="Payment Mode"
              />
            </div>
            {IsBankpayment && (
              <>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Bank</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow17"
                    showoption={_selecrparem.isoptionShow17}
                    placeholder="Select Bank "
                    onChange={(val) => SearchBank(val)}
                    select_value={(val) => {
                      setBNId(val.bnId);
                      setstrBankName(val.bankNames);
                    }}
                    btnname="Bank"
                    btnshow={false}
                    show_popup=""
                    valueonly={false}
                    defaultval={BNId}
                    displayName="bankNames"
                    disvalue="bnId"
                    arrydata={arrAllBank}
                    EmptVal="Bank"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Account No</label>
                  <input
                    className="form-control"
                    type="text"
                    value={strAccNo}
                    placeholder="Enter Account No"
                    onChange={(e) => setstrAccNo(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Acc/Holder Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter Acc/Holder Name"
                    type="text"
                    value={strAccHolderName}
                    onChange={(e) => setstrAccHolderName(e.target.value)}
                  />
                </div>
              </>
            )}
            {IsCheckPayment && (
              <>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Cheque No</label>
                  <input
                    className="form-control"
                    placeholder="Enter Cheque No"
                    type="text"
                    value={strChequeNo}
                    onChange={(e) => setstrChequeNo(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Cheque Date</label>
                  <input
                    className="form-control"
                    type="date"
                    value={dpChequeDate}
                    onChange={(e) => setdpChequeDate(e.target.value)}
                  />
                </div>
              </>
            )}
            <div className="col-md-6 col-sm-12 mt-1">
              <label>Remark</label>
              <input
                className="form-control"
                type="text"
                value={strRemark}
                placeholder="Enter Remark"
                onChange={(e) => setstrRemark(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-1">
              <label>Paid Amount</label>
              <input
                type="number"
                className="form-control"
                value={dPaidAmt}
                onClick={(e) => e.target.select()}
                onChange={(e) => setdPaidAmt(e.target.value)}
              />
            </div>
            <div
              className="net-amount mt-2"
              style={{ maxWidth: "70%", margin: "0 auto" }}
            >
              <h1>NET AMOUNT</h1>
              <h2>
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "INR",
                }).format(Number(dNetAmount).toFixed(2))}
              </h2>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen mt-2"
              onClick={(e) => {
                if (Number(iPayModeId) === 2 && strChequeNo === "") {
                  Show_Message(dispatch, "Cheque/DD Number is Invalid", "info");
                } else if (
                  (Number(iPayModeId) === 7 && strAccNo === "") ||
                  (Number(iPayModeId) === 7 && strAccHolderName === "") ||
                  (Number(iPayModeId) === 7 && BNId === 0)
                ) {
                  Show_Message(
                    dispatch,
                    "Enter valid Account Detailes",
                    "info"
                  );
                } else if (Number(dPaidAmt) === 0) {
                  Show_Message(dispatch, "Please Enter Amount", "info");
                } else {
                  Ask_Question(
                    dispatch,
                    Save_Receipt,
                    "Do You Want to  Save Receipt?"
                  );
                }
              }}
            >
              Save Receipt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReceiptPopup;
