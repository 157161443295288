import React, { useEffect, useState } from "react";
import $ from "jquery";
import UnitMaster from "./UnitMaster";
import { Show_Message } from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import { API_FETCH, API_POST } from "../General/Utility";
function UOMMaster(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strUnitName, setstrUnitName] = useState("");
  const [DefaultUnit, setDefaultUnit] = useState([]);
  const [SelectedDefault, setSelectedDefault] = useState([]);
  const [listUnits, setlistUnits] = useState([]);
  const [IsUnitMasterOpen, setIsUnitMasterOpen] = useState(false);

  useEffect(() => {
    try {
      Load_UnitMaster("Existing");
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  }, []);
  const Load_UnitMaster = async (UnitFor) => {
    try {
      const res = await API_FETCH(
        "UOMMaster/GetDefaultData/" + _AuthParems.DB,
        dispatch
      );
      if (res) {
        if (props.ListUnit.length > 0 && UnitFor === "Existing") {
          for (var i = 0; i < props.ListUnit.length; i++) {
            for (var D = 0; D < res.length; D++) {
              if (res[D].iUnitId === props.ListUnit[i].iUnitId) {
                res[D].bIsChecked = true;
              }
            }
          }
          setstrUnitName(props.UnitName);
          setSelectedDefault(props.ListUnit);
          setlistUnits(res.EntUnit);
          setDefaultUnit(props.ListUnit);
        } else {
          setlistUnits(res.EntUnit);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const CheckBox_Check = async (checked, UnitViewCheck, ListUnit) => {
    try {
      let tempList = ListUnit === undefined ? listUnits : ListUnit;
      tempList.map((Unitview, Index) => {
        if (Unitview.iUnitId === UnitViewCheck.iUnitId) {
          Unitview.bIsChecked = checked;
        }
        return Unitview;
      });
      let SelectedUnit = tempList.filter((Data) => Data.bIsChecked === true);
      // Update State
      if (SelectedUnit.length > 0 && SelectedUnit.length <= 4) {
        setlistUnits(tempList);
        const res = await API_POST(
          "UOMMaster/GenerateUnit/",
          {
            objViewModel: tempList,
            strDBName: _AuthParems.DB,
          },
          dispatch
        );
        if (res) {
          if (res.strStatus === "Validation") {
            setDefaultUnit([]);
            setstrUnitName("");
            setSelectedDefault([]);
          } else {
            setDefaultUnit(res.objUnitModel);
            setstrUnitName(res.strUnitName);
            setSelectedDefault(res.objUnitModel);
          }
        }
      } else if (SelectedUnit.length > 4) {
        setlistUnits(listUnits);
        Show_Message(dispatch, "More Then Four Unit Not Allowed", "info");
      } else {
        setDefaultUnit([]);
        setSelectedDefault([]);
      }
    } catch (err) {
      setDefaultUnit([]);
      setSelectedDefault([]);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const onunittypeChange = async (type, val, unit) => {
    try {
      const AssignSelectedUnit = {
        iUnitId: unit.iUnitId,
        strUnitFor: type,
        objViewUnit: DefaultUnit,
        bIsChecked: val,
      };
      const res = await API_POST(
        "UOMMaster/SelectedUnit/",
        AssignSelectedUnit,
        dispatch
      );
      if (res) {
        setSelectedDefault(res.EntUnit);
        setDefaultUnit(res.EntUnit);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ClearClick = (e) => {
    try {
      setlistUnits([]);
      setSelectedDefault([]);
      setDefaultUnit([]);
      setstrUnitName("");
      Load_UnitMaster("New");
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveClick = () => {
    try {
      if (SelectedDefault.length === 0)
        Show_Message(dispatch, "Please Select Unit", "error");
      else props.GetDefautUnit(SelectedDefault, strUnitName);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Unit Master </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn">
              <i
                className="fa-regular fa-rectangle-xmark"
                onClick={props.Close}
              ></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="unitmaster">
            <div className="unitlist">
              <div className="dt-header">
                <label>Unit Name</label>
              </div>
              <div className="unit-wrapper">
                {listUnits.map((unit, Index) => (
                  <button
                    className="dt-unit"
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = listUnits.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                    }}
                  >
                    <label>{unit.strUnitName}</label>
                    <input
                      type="checkbox"
                      checked={unit.bIsChecked}
                      className="form-check-input"
                      onChange={(e) => CheckBox_Check(e.target.checked, unit)}
                    />
                  </button>
                ))}
              </div>
            </div>
            <div className="unitlist">
              <div className="dt-header">
                <label>Unit Name</label>
                <label>Purchase</label>
                <label>Sales</label>
                <label>Stock</label>
                <label>Report</label>
              </div>
              {DefaultUnit.map((unit, Index) => (
                <button
                  className="dt-unit"
                  key={Index}
                  tabIndex={Index}
                  onKeyDown={(e) => {
                    const arrlength = DefaultUnit.length - 1;
                    if (e.key === "ArrowDown") {
                      if (Index === arrlength)
                        $(`.data-list [tabindex=${Index + 1}]`).focus();
                      else $(`.data-list [tabindex=${0}]`).focus();
                    }
                    if (e.key === "ArrowUp") {
                      if (Index === 0)
                        $(`.data-list [tabindex=${arrlength}]`).focus();
                      else $(`.data-list [tabindex=${Index - 1}]`).focus();
                    }
                  }}
                >
                  <label>{unit.strUnitName}</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unit.bPurchaseDefault}
                    onChange={(e) =>
                      onunittypeChange("Purchase", e.target.checked, unit)
                    }
                  />
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unit.bSalesDefault}
                    onChange={(e) =>
                      onunittypeChange("Sales", e.target.checked, unit)
                    }
                  />
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unit.bReportDefault}
                    onChange={(e) =>
                      onunittypeChange("Report", e.target.checked, unit)
                    }
                  />
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unit.bStockDefault}
                    onChange={(e) =>
                      onunittypeChange("Stock", e.target.checked, unit)
                    }
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => SaveClick(SelectedDefault)}
            >
              Save &nbsp; <i className="bx bx-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => setIsUnitMasterOpen(!IsUnitMasterOpen)}
            >
              Create Unit &nbsp; <i className="bx bx-plus"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              onClick={(e) => ClearClick(e)}
            >
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
          </div>
        </div>
        {IsUnitMasterOpen && (
          <UnitMaster
            Close={(e) => {
              setIsUnitMasterOpen(!IsUnitMasterOpen);
              Load_UnitMaster("New");
            }}
          />
        )}
      </div>
    </div>
  );
}
export default UOMMaster;
