import { createSlice } from "@reduxjs/toolkit";

export const ConfigurationSlice = createSlice({
  name: "Configuration",
  initialState: {
    Config: {
      SettIndex: 0,
      value: "",
      Header: "",
      IsShowConfig: false,
      IsShowCustom: false,
      EntConfigType: [
        "Organization",
        "Configuration",
        "Master",
        "Purchase",
        "Sales",
        "JobWork",
        "Accounts",
        "Code No",
      ],
      EntConfig: [
        { ScrId: 0, Value: -1, Index: 1, HeaderIndex: 0, Name: "Warehouses" },
        { ScrId: 0, Value: -1, Index: 2, HeaderIndex: 0, Name: "Counter" },
        {
          ScrId: 0,
          Value: -1,
          Index: 3,
          HeaderIndex: 0,
          Name: "Employee Profile",
        },
        { ScrId: 0, Value: -1, Index: 4, HeaderIndex: 0, Name: "User" },
        { ScrId: 0, Value: -1, Index: 5, HeaderIndex: 0, Name: "Role" },
        {
          ScrId: 0,
          Value: 0,
          Index: 6,
          HeaderIndex: 1,
          Name: "General",
          Config: true,
        },
        {
          ScrId: 0,
          Value: 1,
          Index: 7,
          HeaderIndex: 1,
          Name: "Accounts",
          Config: true,
        },
        {
          ScrId: 0,
          Value: 3,
          Index: 8,
          HeaderIndex: 1,
          Name: "Master",
          Config: true,
        },
        {
          ScrId: 0,
          Value: 5,
          Index: 9,
          HeaderIndex: 1,
          Name: "Stock",
          Config: true,
        },
        {
          ScrId: 0,
          Value: 6,
          Index: 10,
          HeaderIndex: 1,
          Name: "Tax",
          Config: true,
        },
        {
          ScrId: 0,
          Value: 7,
          Index: 11,
          HeaderIndex: 1,
          Name: "Transactions",
          Config: true,
        },
        {
          ScrId: 0,
          Value: 9,
          Index: 12,
          HeaderIndex: 1,
          Name: "JobWork",
          Config: true,
        },
        {
          ScrId: 1,
          Value: -1,
          Index: 13,
          HeaderIndex: 2,
          Name: "Product",
          Custome: true,
        },
        {
          ScrId: 2,
          Value: -1,
          Index: 14,
          HeaderIndex: 2,
          Name: "Customer",
          Custome: true,
        },
        {
          ScrId: 3,
          Value: -1,
          Index: 15,
          HeaderIndex: 2,
          Name: "Supplier",
          Custome: true,
        },
        {
          ScrId: 50,
          Value: -1,
          Index: 16,
          HeaderIndex: 2,
          Name: "Vendore",
          Custome: true,
        },
        {
          ScrId: 51,
          Value: -1,
          Index: 17,
          HeaderIndex: 2,
          Name: "Jobwork",
          Custome: true,
        },
        {
          ScrId: 59,
          Value: -1,
          Index: 18,
          HeaderIndex: 2,
          Name: "Service",
          Custome: true,
        },
        {
          ScrId: 29,
          Value: -1,
          Index: 19,
          HeaderIndex: 2,
          Name: "Bank Account",
          Custome: true,
        },
        {
          ScrId: 101,
          Value: -1,
          Index: 20,
          HeaderIndex: 2,
          Name: "Doctor Master",
          Custome: true,
        },

        {
          ScrId: 7,
          Value: -1,
          Index: 20,
          HeaderIndex: 3,
          Name: "Purchase Enquiry",
          Custome: true,
        },
        {
          ScrId: 8,
          Value: -1,
          Index: 21,
          HeaderIndex: 3,
          Name: "Purchase Quotation",
          Custome: true,
        },
        {
          ScrId: 9,
          Value: -1,
          Index: 23,
          HeaderIndex: 3,
          Name: "Purchase Order",
          Custome: true,
        },
        {
          ScrId: 10,
          Value: -1,
          Index: 22,
          HeaderIndex: 3,
          Name: "Purchase Challan",
          Custome: true,
        },
        {
          ScrId: 11,
          Value: -1,
          Index: 23,
          HeaderIndex: 3,
          Name: "Purchase Invoice",
          Custome: true,
        },
        {
          ScrId: 12,
          Value: -1,
          Index: 24,
          HeaderIndex: 3,
          Name: "Purchase Return",
          Custome: true,
        },
        {
          ScrId: 78,
          Value: -1,
          Index: 25,
          HeaderIndex: 3,
          Name: "Purchase Estimate",
          Custome: true,
        },
        {
          ScrId: 93,
          Value: -1,
          Index: 26,
          HeaderIndex: 4,
          Name: "POS",
          Custome: true,
        },
        {
          ScrId: 103,
          Value: -1,
          Index: 27,
          HeaderIndex: 4,
          Name: "AppoinMent Booking",
          Custome: true,
        },
        {
          ScrId: 13,
          Value: -1,
          Index: 28,
          HeaderIndex: 4,
          Name: "Sales Enquiry",
          Custome: true,
        },
        {
          ScrId: 14,
          Value: -1,
          Index: 29,
          HeaderIndex: 4,
          Name: "Sales Quotation",
          Custome: true,
        },
        {
          ScrId: 15,
          Value: -1,
          Index: 30,
          HeaderIndex: 4,
          Name: "Sales Order",
          Custome: true,
        },
        {
          ScrId: 16,
          Value: -1,
          Index: 31,
          HeaderIndex: 4,
          Name: "Sales Challan",
          Custome: true,
        },
        {
          ScrId: 17,
          Value: -1,
          Index: 32,
          HeaderIndex: 4,
          Name: "Sales Invoice",
          Custome: true,
        },
        {
          ScrId: 18,
          Value: -1,
          Index: 33,
          HeaderIndex: 4,
          Name: "Sales Return",
          Custome: true,
        },
        {
          ScrId: 67,
          Value: -1,
          Index: 34,
          HeaderIndex: 4,
          Name: "Sales Estimate",
          Custome: true,
        },
        {
          ScrId: 71,
          Value: -1,
          Index: 35,
          HeaderIndex: 5,
          Name: "Inward Challan",
          Custome: true,
        },
        {
          ScrId: 72,
          Value: -1,
          Index: 36,
          HeaderIndex: 5,
          Name: "Outward Challan",
          Custome: true,
        },
        {
          ScrId: 73,
          Value: -1,
          Index: 37,
          HeaderIndex: 5,
          Name: "Outward Invoice",
          Custome: true,
        },
        {
          ScrId: 74,
          Value: -1,
          Index: 38,
          HeaderIndex: 5,
          Name: "Outsource Challan",
          Custome: true,
        },
        {
          ScrId: 75,
          Value: -1,
          Index: 39,
          HeaderIndex: 5,
          Name: "Insource Challan",
          Custome: true,
        },
        {
          ScrId: 76,
          Value: -1,
          Index: 40,
          HeaderIndex: 5,
          Name: "Insource Invoice",
          Custome: true,
        },
        {
          ScrId: 41,
          Value: -1,
          Index: 41,
          HeaderIndex: 6,
          Name: "Payment",
          Custome: true,
        },
        {
          ScrId: 42,
          Value: -1,
          Index: 42,
          HeaderIndex: 6,
          Name: "Receipt",
          Custome: true,
        },
        {
          ScrId: 43,
          Value: -1,
          Index: 43,
          HeaderIndex: 6,
          Name: "Debit Note",
          Custome: true,
        },
        {
          ScrId: 44,
          Value: -1,
          Index: 44,
          HeaderIndex: 6,
          Name: "Credit Note",
          Custome: true,
        },
        {
          ScrId: 66,
          Value: -1,
          Index: 45,
          HeaderIndex: 6,
          Name: "Expence Income",
          Custome: true,
        },
        {
          ScrId: 79,
          Value: -1,
          Index: 46,
          HeaderIndex: 6,
          Name: "Journal Entry",
          Custome: true,
        },
        {
          ScrId: 0,
          Value: -1,
          Index: 47,
          HeaderIndex: 7,
          Name: "Master Code",
        },
        {
          ScrId: 0,
          Value: -1,
          Index: 48,
          HeaderIndex: 7,
          Name: "Voucher Number",
        },
      ],
    },
  },
  reducers: {
    Open_Configuration: (state, { payload }) => {
      state.Config.value = payload.value;
      state.Config.Header = payload.Header;
      state.Config.SettIndex = payload.SettIndex;
      state.Config.IsShowConfig = payload.IsShowConfig;
      state.Config.IsShowCustom = payload.IsShowCustom;
    },
  },
});
export const { Open_Configuration } = ConfigurationSlice.actions;
export default ConfigurationSlice.reducer;
