import React, { useEffect, useState } from "react";
import $ from "jquery";
function Attributeselect(props) {
  const [strSearch, setstrSearch] = useState("");
  const [arroption, setarroption] = useState("");
  useEffect(() => {}, [props]);
  const input_Onchange = (val) => {
    let EntOption = props.arrydata.filter((dt) =>
      dt.strAttribute.toLowerCase().includes(val.toLowerCase())
    );
    setarroption(EntOption);
    setstrSearch(val);
  };
  const Select_Value = (item) => {
    if (item === undefined) {
      item = {
        iAttId: 0,
        strAttribute: strSearch,
        strAttVal: "",
        iAttValId: 0,
        isAttribute: false,
        arrAttValue: [],
      };
      props.showatt(false);
      props.select_value(item);
    } else {
      props.showatt(false);
      setstrSearch(item.strAttribute);
      props.select_value(item);
    }
  };
  return (
    <div
      className="custom-select"
      onMouseLeave={(e) => props.showatt(false)}
      style={{ minWidth: "200px" }}
    >
      <input
        className="form-control"
        disabled={props.disabled ? true : false}
        type="text"
        placeholder="Select Attribute"
        defaultValue={props.attribute}
        onClick={(e) => {
          props.showatt(true);
          input_Onchange("");
        }}
      />
      <i className="fa-solid fa-caret-down"></i>
      {props.showoption && (
        <div className="custom-option">
          <div className="option-list">
            <div className="search-option">
              <input
                className="form-control"
                type="text"
                autoFocus
                placeholder="Search Attribute"
                value={strSearch}
                onChange={(e) => input_Onchange(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown") {
                    $(".option-list [tabindex=0]").focus();
                  } else if (e.key === "Enter") {
                    Select_Value(arroption[0]);
                  }
                }}
              />
              {arroption.length === 0 && <span>Attribute Not Found</span>}
            </div>
            {arroption.map((item, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onClick={(e) => Select_Value(item)}
                onKeyDown={(e) => {
                  let arrlength = arroption.length - 1;
                  if (e.key === "ArrowDown") {
                    if (arrlength === Index)
                      $(`.option-list [tabindex=0]`).focus();
                    else $(`.option-list [tabindex=${Index + 1}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.option-list [tabindex=${arrlength}]`).focus();
                    else $(`.option-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") Select_Value(item);
                }}
              >
                {item.strAttribute}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Attributeselect;
