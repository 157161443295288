import jsPDF from "jspdf";
import NumbertoWord from "../General/NumbertoWord";
import { View_ScreenName } from "./Utility";
var options = { orientation: "p", unit: "pt", format: [827, 1100] };
var doc = new jsPDF(options);
const PrintAccountsDesign = (PrintValue, iScrId, PrintFor, PrintShare) => {
  try {
    const ScrName = View_ScreenName(iScrId);
    if (PrintShare) {
      if (PrintValue.PinterType !== 3) {
      }
      return CreateInvocie(
        PrintValue,
        1,
        iScrId,
        ScrName,
        PrintFor,
        PrintShare
      );
    } else {
      CreateInvocie(PrintValue, 1, iScrId, ScrName, PrintFor, PrintShare);
      doc = new jsPDF(options); // Clear Page After Print First Copy
    }
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};
export default PrintAccountsDesign;
const AssignPrintData = (JsonString, dFormat) => {
  let JsonArray = JsonString === undefined ? [] : JSON.parse(JsonString);

  JsonArray = JsonArray.map((DT) => {
    let ColumName = Object.keys(DT);
    for (let i = 0; i < ColumName.length; i++) {
      if (ColumName[i].includes("Qty")) {
        DT[ColumName[i]] = parseFloat(DT[ColumName[i]]).toFixed(
          dFormat.strQtyFormat
        );
      }
      if (ColumName[i].includes("Per")) {
        DT[ColumName[i]] = parseFloat(DT[ColumName[i]]).toFixed(
          dFormat.strPerFormat
        );
      }
      if (ColumName[i].includes("Amt") || ColumName[i].includes("Rate")) {
        DT[ColumName[i]] = parseFloat(DT[ColumName[i]]).toFixed(
          dFormat.strAmtFormat
        );
      }
      if (ColumName[i].includes("GoodsValue")) {
        DT[ColumName[i]] = parseFloat(DT[ColumName[i]]).toFixed(
          dFormat.strAmtFormat
        );
      }
    }
    return DT;
  });
  return JsonArray;
};
const CreateInvocie = (
  PrintValue,
  _iCurrentCopy,
  _iScrId,
  ScrName,
  PrintFor,
  PrintShare
) => {
  //#region Print Template
  let _objLineTempFirst = PrintValue.objLineTemplate.objFirst;
  //#endregion
  //#region Print Area Design
  let _objCompanyArea = PrintValue.objCompanyArea;
  let _objPartyArea = PrintValue.objVendoreArea;
  let _objAccuntsArea = PrintValue.objAccountsArea;

  //#endregion
  //#region  Print Data
  const DecimalFormate = {
    strAmtFormat: parseInt(PrintValue.strAmtFormat),
    strPerFormat: parseInt(PrintValue.strPerFormat),
    strQtyFormat: parseInt(PrintValue.strQtyFormat),
  };
  let _dtCompany = AssignPrintData(PrintValue.jsonCompany, DecimalFormate);
  let _dtParty = AssignPrintData(PrintValue.jsonVendore, DecimalFormate);
  let _dtAccounts = AssignPrintData(PrintValue.jsonAccounts, DecimalFormate);

  fnDrawData(_objCompanyArea, _dtCompany);
  fnDrawData(_objPartyArea, _dtParty);
  fnDrawData(_objAccuntsArea, _dtAccounts);

  //#region Draw Line
  fnDrawLine(_objLineTempFirst);
  //#endregion
  //#endregion

  var FileName = VoucherName(_dtAccounts[0], ScrName);
  if (PrintShare) {
    var string = doc.output("dataurlstring", FileName);
    const objPdfFile = {
      PdfURL: string,
      FileName: FileName,
      Summary: [],
      CompanyInfo: _dtCompany,
      PartyInfo: _dtParty,
    };
    return objPdfFile;
  } else {
    if (PrintFor === "Print") {
      doc.output("dataurlnewwindow", FileName);
    } else {
      doc.save(FileName + ".pdf");
    }
  }
};
const VoucherName = (VoucherInfo, ScrName) => {
  var InvoiceNo = "";
  switch (ScrName) {
    case "Payment":
      InvoiceNo = VoucherInfo.payNo;
      break;
    case "Receipt":
      InvoiceNo = VoucherInfo.recNo;
      break;
    case "DebitNote":
      InvoiceNo = VoucherInfo.debNo;
      break;
    case "CreditNote":
      InvoiceNo = VoucherInfo.creNo;
      break;
    default:
      break;
  }
  return ScrName + "-" + InvoiceNo.slice(-5);
};
const fnDrawData = (objEntArea, dtData, objEntLogo) => {
  try {
    if (objEntArea.length === 0) return;
    let objElements = [];
    let objLabels = [];
    let dContentHeight = 0,
      dContentWidth = 0;
    let objPrintParam = "";
    let dPerLineHeightWithGap = 0;
    objElements = objEntArea.filter(
      (DT) => DT.fieldName !== "Label" && !DT.fieldName.startsWith("LogoId")
    );
    objLabels = objEntArea.filter(
      (DT) => DT.fieldName === "Label" && DT.suffix !== "Only"
    );
    objLabels.forEach((entLabel) => {
      let objPrintParam = fnGetPrintAreaProperties(entLabel, "Label");
      fnAlignment(objPrintParam);
      fnDrawString(objPrintParam);
    });

    //#region Draw drItem
    if (dtData.length === 0) return;
    dtData.forEach((drData) => {
      objElements.forEach((entElement) => {
        let objPrintParam = fnGetPrintAreaProperties(entElement);
        objPrintParam.printContent = drData[objPrintParam.fieldName].toString();
        objPrintParam = fnAlignment(objPrintParam);
        fnDrawString(objPrintParam);
      });
    });
    //#endregion

    //#region Amount in words
    if (
      objEntArea.filter(
        (DT) => DT.fieldName === "Label" && DT.suffix === "Only"
      ).length > 0
    ) {
      let objAmountInWords = objEntArea.filter(
        (DT) => DT.fieldName === "Label" && DT.suffix === "Only"
      );
      objAmountInWords.forEach((entAmountInWord) => {
        objPrintParam = fnGetPrintAreaProperties(entAmountInWord, "Label");
        var StringWidthHeight = fnMeasureString(
          objPrintParam.printContent,
          objPrintParam.fontSize,
          objPrintParam.fontStyle
        );
        dContentHeight = StringWidthHeight.dContentHeight;
        dContentWidth = StringWidthHeight.dContentWidth;
        objPrintParam = fnAlignment(objPrintParam);

        let strWords = objPrintParam.printContent.replace(
          /^.{1}/g,
          objPrintParam.printContent[0].toLowerCase()
        );
        strWords = dtData[0][strWords];
        //Convet Number to Words
        objPrintParam.printContent =
          NumbertoWord(parseFloat(strWords).toFixed(2)) + " Rupees Only /-";

        dPerLineHeightWithGap = dContentHeight + 0;
        objPrintParam.iPrintContentLength = objPrintParam.printContent.length;
        objPrintParam.iWidthLimit = parseInt(objPrintParam.width);
        objPrintParam.iContentWidth = parseInt(Math.floor(dContentWidth));
        objPrintParam.iPrintContentValidLength =
          (objPrintParam.iPrintContentLength * objPrintParam.iWidthLimit) /
          objPrintParam.iContentWidth;

        if (
          objPrintParam.iPrintContentLength <
          objPrintParam.iPrintContentValidLength
        )
          objPrintParam.iPrintContentValidLength =
            objPrintParam.iPrintContentLength;

        let printContentSplitByWidth = SpliText(
          objPrintParam.printContent,
          objPrintParam.iPrintContentValidLength
        );
        let iPrintContentSplitByWidthCount = printContentSplitByWidth.length;
        if (objPrintParam.wordWrap === "wrap") {
          for (
            let iPriCon = 0;
            iPriCon < iPrintContentSplitByWidthCount;
            iPriCon++
          ) {
            objPrintParam.printContent = printContentSplitByWidth[iPriCon];
            objPrintParam.lineAt = parseFloat(
              parseFloat(objPrintParam.lineAt) + iPriCon * dPerLineHeightWithGap
            );
            fnDrawString(objPrintParam);
          }
        }
      });
    }
    //#endregion

    //#region Draw Logo
    var EntLogo = objEntArea.filter((DT) => DT.fieldName.startsWith("LogoId"));
    if (EntLogo.length > 0) {
      EntLogo.forEach((entLogo) => {
        let LogoId = parseInt(entLogo.fieldName.replace("LogoId", ""));
        let objentLogoData = objEntLogo.filter((DT) => DT.iLogoId === LogoId);
        fnDrawLogo(entLogo, objentLogoData);
      });
    }
    //#endregion
  } catch (err) {
    console.error(err.message);
    alert(err.message);
  }
};
const fnDrawLine = (LineTemp) => {
  for (let i = 0; i < LineTemp.length; i++) {
    let X1 = LineTemp[i].x1Pos;
    let Y1 = LineTemp[i].y1Pos - 10;
    let X2 = LineTemp[i].x2Pos;
    let Y2 = LineTemp[i].y2Pos - 10;
    doc.setDrawColor(LineTemp[i].color);
    doc.line(X1, Y1, X2, Y2);
  }
};
const fnDrawString = (objEntString) => {
  let printContent =
    objEntString.printContent === undefined ? "" : objEntString.printContent;
  printContent = printContent.includes("&#xD;&#xA;")
    ? printContent.replace(/&#xD;&#xA;/g, "\r\n")
    : printContent;
  //Print String Data
  doc.setFontSize(objEntString.fontSize + 2);
  doc.setTextColor(objEntString.color);
  doc.setFont(objEntString.font, "", objEntString.fontStyle);
  doc.text(printContent, objEntString.startAt, objEntString.lineAt, {
    align: objEntString.alignment,
  });
};
const fnDrawLogo = (objEntLogo, objentLogoData) => {
  if (objentLogoData.length === 0) return;
  var UnitBitArray = new Uint8Array(objentLogoData[0].imgData);
  var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
  let strImage = "data:image/jpeg;base64," + ArrayToString;
  let strImageWidth = objentLogoData[0].dLogoWidth;
  let strImageHeight = objentLogoData[0].dLogoHeight;
  doc.addImage(
    strImage,
    "JPEG",
    objEntLogo.startAt,
    objEntLogo.lineAt,
    strImageWidth,
    strImageHeight,
    objentLogoData[0].strLogoName
  );
};
const fnMeasureString = (pText, pFontSize, pStyle) => {
  var lDiv = document.createElement("p");
  lDiv.style.fontStyle = pStyle;
  lDiv.style.fontSize = "" + pFontSize + "px";
  lDiv.style.position = "absolute";
  lDiv.style.left = -1000;
  lDiv.style.top = -1000;
  lDiv.textContent = pText;
  document.body.appendChild(lDiv);

  var lResult = {
    dContentWidth: lDiv.clientWidth,
    dContentHeight: lDiv.clientHeight,
  };
  document.body.removeChild(lDiv);
  lDiv = "";
  return lResult;
};
const fnGetPrintAreaProperties = (PrintArea, strHelp) => {
  var FieldName = PrintArea.fieldName;
  FieldName = FieldName.replace(/^.{1}/g, FieldName[0].toLowerCase());
  const objPrintParm = {
    designId: PrintArea.designId,
    startAt: PrintArea.startAt,
    lineAt: PrintArea.lineAt,
    width: PrintArea.width,
    alignment: PrintArea.alignment,
    fontSize: PrintArea.fontSize,
    fontStyle: PrintArea.fontStyle,
    color: PrintArea.color,
    wordWrap: PrintArea.wordWrap,
    printContent: strHelp === "Label" ? PrintArea.labelName : "",
    fieldName: FieldName,
    font: PrintArea.font,
    dHeightDiff: 0,
    labelName: PrintArea.labelName,
    dMaxHeight: 0,
    dMaxLine: 0,
    dContentWidth: 0,
    dContentHeight: 0,
    strFileName: "",
    iPrintContentLength: 0,
    iWidthLimit: 0,
    iContentWidth: 0,
    iPrintContentValidLength: 0,
    iLineCount: 0,
    dPerElementHeight: 0,
    iPrintContentSplitByWidthCount: 0,
    iCount: 0,
    dDescriptionBegin: 0,
    dDescriptionEnd: 0,
  };
  return objPrintParm;
};
const fnAlignment = (objPrintParm) => {
  // let dContentWidth = 0;
  // let dGap = 0;
  // let TextWH = "";
  // switch (objPrintParm.alignment) {
  //   case "right":
  //     TextWH = fnMeasureString(
  //       objPrintParm.printContent,
  //       objPrintParm.fontSize,
  //       objPrintParm.fontStyle
  //     );
  //     dContentWidth = TextWH.dContentWidth;

  //     dGap = 0;
  //     if (dGap < 0) dGap = 0;
  //     break;
  //   case "center":
  //     TextWH = fnMeasureString(
  //       objPrintParm.printContent,
  //       objPrintParm.fontSize,
  //       objPrintParm.fontStyle
  //     );
  //     dContentWidth = TextWH.dContentWidth;
  //     dGap = (objPrintParm.width - dContentWidth) / 2;
  //     if (dGap < 0) dGap = 0;
  //     break;
  //   default:
  //     break;
  // }
  // objPrintParm.startAt = parseFloat(objPrintParm.startAt + dGap);
  return objPrintParm;
};
const SpliText = (str, chunkSize) => {
  let chunks = [];
  while (str) {
    if (str.length < chunkSize) {
      chunks.push(str);
      break;
    } else {
      chunks.push(str.substr(0, chunkSize));
      str = str.substr(chunkSize);
    }
  }
  return chunks;
};
