import React from "react";
import { API_POST } from "../General/Utility";
import { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Merger_StockConvertion } from "../features/Transactionstates";

function StockTransConvertion(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.Transstates.Common);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strSearch, setstrSearch] = useState("");
  const [arrEntVoucher, setarrEntVoucher] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_PendingVoucher();
  }, []);
  const Load_PendingVoucher = async () => {
    const parems = {
      iPartyId: 0,
      iScrId: props.ScrId,
      AdjScrId: props.AdjScrId,
      dpFrom: "",
      dpTo: "",
      strSearch: "",
      iWHId: Number(props.WHId),
      iBranchId: Number(_AuthParems.Branch),
      iUserId: Number(_AuthParems.User),
      iRoleId: Number(_AuthParems.Role),
      iClientId: Number(_AuthParems.Client),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      `InventoryTrans/Load_PendingVoucher/`,
      parems,
      dispatch
    );
    if (res) {
      setstrFromDate(res.strFromDate);
      setstrToDate(res.strToDate);
      setarrEntVoucher(res.EntStock);
      setarrEntTableCol(res.EntTableDesign);
    }
  };
  const Search_Voucher = async (val, type) => {
    let FromDate = strFromDate;
    let ToDate = strToDate;
    let Search = strSearch;
    switch (type) {
      case "Search":
        Search = val;
        setstrSearch(Search);
        break;
      case "FromDate":
        FromDate = val;
        strFromDate(FromDate);
        break;
      case "ToDate":
        ToDate = val;
        strToDate(ToDate);
        break;
      default:
        break;
    }
    const parems = {
      iPartyId: 0,
      iScrId: props.ScrId,
      dpFrom: FromDate,
      dpTo: ToDate,
      strSearch: Search,
      iWHId: Number(props.WHId),
      iBranchId: Number(_AuthParems.Branch),
      iUserId: Number(_AuthParems.User),
      iRoleId: Number(_AuthParems.Role),
      iClientId: Number(_AuthParems.Client),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      `InventoryTrans/Search_PendingVoucher/`,
      parems,
      dispatch
    );
    if (res) {
      setarrEntVoucher(res.EntStock);
    }
  };
  const Convert_Click = async () => {
    let EntVouch = arrEntVoucher.filter((dt) => dt.isChecked);
    const parems = {
      AdjScrId: props.AdjScrId,
      iScrId: _Common.iScrId,
      iWHId: Number(props.WHId),
      iBranchId: Number(_AuthParems.Branch),
      iUserId: Number(_AuthParems.User),
      iRoleId: Number(_AuthParems.Role),
      iClientId: Number(_AuthParems.Client),
      strDBName: _AuthParems.DB,
      EntPenCon: EntVouch,
    };
    const Controller =
      props.ScrId === 104 ? "StockReceive" : "StocktransAdjustment";
    const res = await API_POST(
      `${Controller}/Convert_PendingVouchers/`,
      parems,
      dispatch
    );
    if (res) {
      await dispatch(Merger_StockConvertion(res));
      await props.Close();
    }
  };
  const TableKeyDow = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter")
      if (ActiveIndex === 52) {
        if (arrEntVoucher.length > 0) {
          props.View(arrEntVoucher[0].vouchId);
        }
      }
    if (e.key === "ArrowDown") {
      $(".Invoice_table .rt-tbody .rt-tr:eq(1)").focus();
    }
    if (e.key === "ArrowUp") {
      $(".Invoice_table .rt-tbody .rt-tr:eq(1)").focus();
    }
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 1 && ActiveIndex <= 3)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 4 + "']").focus();
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    } else if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content medium">
        <div className="popup-header">
          <h5>Pending Stock Transfer</h5>
          <div className="icon-popup-btn-grp">
            <span className=" icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-6 col-sm-12 mt-2">
              <div className="m-hsearch">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  autoFocus
                  autoComplete="off"
                  id="Invoice_Search"
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" ||
                      e.key === "ArrowUp" ||
                      e.key === "ArrowDown"
                    )
                      TableKeyDow(e);
                  }}
                  tabIndex={52}
                  name="strSearch"
                  value={strSearch}
                  onChange={(e) => Search_Voucher(e.target.value, "Search")}
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Voucher(strSearch, "Search")}
                ></i>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mt-2">
              <input
                type="date"
                className="form-control "
                value={strFromDate}
                name="strFromDate"
                tabIndex={2}
                onKeyDown={(e) => TabIndex(e)}
                style={{
                  marginRight: "10px",
                  borderRadius: "3px 0 0 3px !important",
                }}
                onChange={(e) => Search_Voucher(e.target.value, "FromDate")}
              />
            </div>
            <div className="col-md-3 col-sm-12 mt-2">
              <input
                type="date"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={3}
                className="form-control"
                value={strToDate}
                name="strToDate"
                onChange={(e) => Search_Voucher(e.target.value, "ToDate")}
              />
            </div>
          </div>
          <div className="table-card mt-1">
            <table className="option-list">
              <thead>
                <tr>
                  {arrEntTableCol.map(
                    (ht, Index) =>
                      ht.isShow && <th key={Index}>{ht.strAliasName}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {arrEntVoucher.map((voucher, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrEntVoucher.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "ArrowLeft") $("#to_date").focus();
                      if (e.key === "ArrowRight") $("#Invoice_Search").focus();
                      if (e.key === "Enter") props.View_Voucher(voucher);
                    }}
                  >
                    {arrEntTableCol.map(
                      (dt, dtIndex) =>
                        dt.isShow && (
                          <td
                            key={dtIndex}
                            style={{
                              textAlign: dt.strTextAlign,
                              width: dt.strWidth.includes("%")
                                ? dt.strWidth
                                : dt.strWidth + "px",
                              maxWidth: dt.strWidth.includes("%")
                                ? dt.strWidth
                                : dt.strWidth + "px",
                              color: "black",
                            }}
                          >
                            {dt.strDataType === "bool" ? (
                              <input
                                type="checkbox"
                                className="form-ontrol"
                                defaultValue={voucher.isChecked}
                                onChange={(e) =>
                                  (voucher.isChecked = !voucher.isChecked)
                                }
                              />
                            ) : dt.strDataType === "number" ? (
                              Number(voucher[dt.strColName]).toFixed(2)
                            ) : (
                              voucher[dt.strColName]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <div className="btn-fabgreen" onClick={(e) => Convert_Click()}>
              Convert <i className="fa-solid fa-print" tabIndex="1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockTransConvertion;
