import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";

import CustomizedSelectoption from "../General/CustomizedSelectoption";
import {
  API_FETCH,
  API_POST,
  DecryptData,
  EncryptData,
} from "../General/Utility";
import ReactTable from "../General/ReactTable";
import { Update_AdminCntrl } from "../features/CommonStates";

function UserMaster(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [strUserName, setstrUserName] = useState("");
  const [strPassword, setstrPassword] = useState("");
  const [strConfirmPassword, setstrConfirmPassword] = useState("");
  const [strUserStatus, setstrUserStatus] = useState("");
  const [strUserRole, setstrUserRole] = useState("");
  const [iUserId, setiUserId] = useState(0);
  const [iUserRole, setiUserRole] = useState(0);
  const [ListEntUser, setListEntUser] = useState([]);
  const [iScrTypeId, setiScrTypeId] = useState(1);

  const [bIsNew, setbIsNew] = useState(false);
  const [bIsView, setbIsView] = useState(false);
  const [bIsModify, setbIsModify] = useState(false);
  const [bIsExport, setbIsExport] = useState(false);
  const [bIsDelete, setbIsDelete] = useState(false);
  const [bIsNoAccess, setbIsNoAccess] = useState(false);
  const [bIsPrint, setbIsPrint] = useState(false);
  const [bIsConvert, setbIsConvert] = useState(false);
  const [bIsBackup, setbIsBackup] = useState(false);
  const [bIsRestore, setbIsRestore] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [isMaster, setisMaster] = useState(true);
  const [isTransaction, setisTransaction] = useState(false);
  const [isOthers, setisOthers] = useState(false);
  const [isReport, setisReport] = useState(false);
  const [isCompany, setisCompany] = useState(false);

  const [arrEntPermission, setarrEntPermission] = useState([]);
  const [arrEntScreen, setarrEntScreen] = useState([]);
  const [arrUserRole, setarrUserRole] = useState([]);

  useEffect(() => {
    Load_UserAcccount();
  }, []);
  const Load_UserAcccount = async () => {
    try {
      const res = await API_FETCH(
        `User/View_User/${Number(_AuthParems.Client)}`,
        dispatch
      );
      if (res) {
        setListEntUser(res.EntUser);
        setarrEntScreen(res.EntScrType);
        setarrUserRole(res.EntUserRole);
        View_PermColumn([], 1);
      }
    } catch (err) {
      Show_Message(dispatch, JSON.stringify(err), "info");
    }
  };
  const SaveClick = async () => {
    try {
      if (strUserRole.toLowerCase() === "admin" && Number(iUserId) === 0) {
        Show_Message(dispatch, "User Role Admin Already Exist", "info");
        setstrUserRole("");
      } else {
        const UserModel = {
          iUserId: iUserId,
          iClientId: Number(_AuthParems.Client),
          strUserRole: strUserRole,
          strUserName: strUserName,
          strPassword: strPassword,
          strStatus: strUserStatus,
        };
        const res = await API_FETCH(
          "User/Check_UserNameExists/" + strUserName,
          dispatch
        );
        if (res) {
          if (res.isExist) {
            Show_Message(dispatch, "User Name Already Exists", "info");
          } else {
            const res = await API_POST("User/InsUpdUser", UserModel, dispatch);
            if (res) {
              await ClearClick();
              await Load_UpdatedPermission();
            }
          }
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ClearClick = () => {
    setiUserId(0);
    setiScrTypeId(1);
    setListEntUser([]);
    setstrUserRole("");
    setstrUserName("");
    setstrPassword("");
    setstrConfirmPassword("");
    setstrUserStatus("");
    Load_UserAcccount();
  };
  const Clear_Update = () => {
    View_UserPermission(iUserId, iScrTypeId, iUserRole);
  };
  const Clear_User = () => {
    setiUserId(0);
    setListEntUser([]);
    setstrUserRole("");
    setiUserRole(0);
    setstrUserName("");
    setstrPassword("");
    setstrConfirmPassword("");
    setstrUserStatus("");
  };
  const View_User = (User) => {
    try {
      setiUserId(User.iUserId);
      setstrUserRole(User.strRole);
      setiUserRole(User.iUserRole);
      setstrUserName(User.strUserName);
      setstrPassword(User.strPassword);
      setstrConfirmPassword(User.strPassword);
      setstrUserStatus(User.strStatus);
      View_UserPermission(User.iUserId, 1, User.iUserRole);
    } catch (err) {
      Show_Message(dispatch, JSON.stringify(err), "info");
    }
  };
  const View_UserPermission = async (UserId, CatId, UserRole) => {
    try {
      await Pre_Loading(dispatch, true);
      setiScrTypeId(Number(CatId));
      setiUserId(UserId);
      const res = await API_FETCH(
        `User/View_UserPermission/${CatId}/${UserId}/${Number(
          UserRole
        )}/${Number(_AuthParems.Client)}`,
        dispatch
      );
      if (res) {
        View_PermColumn(res.EntPermission, CatId);
      }
    } catch (err) {
      Show_Message(dispatch, JSON.stringify(err), "info");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Load_UpdatedPermission = async () => {
    try {
      const res = await API_FETCH(
        `User/View_UserPermission/0/${iUserId}/${Number(iUserRole)}/${Number(
          _AuthParems.Client
        )}`,
        dispatch
      );
      if (res) {
        const _LocalParem = await DecryptData(localStorage.jbctrlparams);
        const TransPerm = res.EntPermission;
        _LocalParem.MasterPer = await View_Permission(TransPerm, 1);
        _LocalParem.PurchasePer = await View_Permission(TransPerm, 2);
        _LocalParem.SalesPer = await View_Permission(TransPerm, 3);
        _LocalParem.GroupsPer = await View_Permission(TransPerm, 4);
        _LocalParem.JobworkPer = await View_Permission(TransPerm, 5);
        _LocalParem.AccountsPer = await View_Permission(TransPerm, 6);
        _LocalParem.ReportscrPer = await View_Permission(TransPerm, 7);
        _LocalParem.ToolsPer = await View_Permission(TransPerm, 8);
        _LocalParem.OthersPer = await View_Permission(TransPerm, 9);
        _LocalParem.PosPer = await View_Permission(TransPerm, 10);
        _LocalParem.InventoryPer = await View_Permission(TransPerm, 11);
        _LocalParem.ReportPer = await View_Permission(TransPerm, 12);
        _LocalParem.CompanyPer = await View_Permission(TransPerm, 13);
        _LocalParem.BranchPer = await View_Permission(TransPerm, 14);
        _LocalParem.WarehousePer = await View_Permission(TransPerm, 15);
        _LocalParem.CounterPer = await View_Permission(TransPerm, 16);
        localStorage.jbctrlparams = await EncryptData(_LocalParem);
        await dispatch(
          Update_AdminCntrl(await DecryptData(localStorage.jbctrlparams))
        );
      }
    } catch (err) {
      Show_Message(dispatch, JSON.stringify(err), "info");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Permission = (arrPermission, id) => {
    let EntMenu = arrPermission.filter((per) => per.iScrCatId === id);
    return EntMenu.sort((a, b) => a.iInOrder - b.iInOrder);
  };
  const Key_Down = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 13)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  const CheckUser = async (UserName) => {
    try {
      setstrUserName(UserName);
      if (UserName !== "") {
        const res = await API_FETCH("Home/CheckUserName/" + UserName, dispatch);
        if (res) {
        }
      }
    } catch (err) {
      Show_Message(dispatch, JSON.stringify(err), "info");
    }
  };
  const DeleteUser = async (iUserId, iRolId) => {
    try {
      if (Number(iUserId) > 1) {
        const res = await API_POST(
          "Home/DeleteUser/" +
            Number(iUserId) +
            "/" +
            Number(iRolId) +
            "/" +
            _AuthParems.msdb,
          dispatch
        );
        if (res) {
          ClearClick();
          Show_Message(dispatch, res.strMessage, "success");
        }
      } else if (Number(iUserId) === 1) {
        Show_Message(dispatch, "Admin User Can't Delete ", "error");
      } else {
        Show_Message(dispatch, "Please Select Valid User", "error");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_PermColumn = (EntPermission, ScrId) => {
    setisMaster(false);
    setisCompany(false);
    setisTransaction(false);
    setisReport(false);
    setisOthers(false);
    setarrEntPermission(EntPermission);
    switch (ScrId) {
      case 1:
      case 11:
        setisMaster(true);
        break;
      case 2:
      case 3:
      case 5:
      case 6:
      case 10:
        setisTransaction(true);
        break;
      case 4:
      case 7:
      case 8:
      case 9:
        break;
      case 14:
      case 15:
      case 16:
        setisOthers(true);
        break;
      case 12:
        setisReport(true);
        break;
      case 13:
        setisCompany(true);
        break;
      default:
        break;
    }
  };
  const Update_UserPermission = async () => {
    try {
      if (Number(iUserId) === 0) {
        Show_Message(dispatch, "Please Select User", "info");
      } else {
        const objUser = {
          iScrCatId: iScrTypeId,
          iUserId: iUserId,
          iUserRole: iUserRole,
          iClientId: Number(_AuthParems.Client),
          objEntPerm: arrEntPermission,
        };
        const res = await API_POST(
          "User/Update_UserPermission/",
          objUser,
          dispatch
        );
        if (res) {
          await Show_Message(dispatch, res.strMessage, "success");
          await Clear_Update();
          await Load_UpdatedPermission();
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Permission_AllOnChange = (Value, key) => {
    let arrPermission = arrEntPermission;
    arrPermission = arrPermission.map((Data) => {
      Data[key] = Number(Value);
      return Data;
    });
    setarrEntPermission(arrPermission);
  };
  const TransColumn = [
    {
      Header: "S.No",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Name",
      accessor: "strName",
      style: { justifyContent: "left" },
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            checked={bIsNew}
            onChange={(e) => {
              setbIsNew(!bIsNew);
              Permission_AllOnChange(!bIsNew, "isCreate");
            }}
          />
          <span>Create</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      show: !isReport,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isCreate}
          onChange={(e) => {
            rowinfo.isCreate = !rowinfo.isCreate;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            checked={bIsView}
            onChange={(e) => {
              setbIsView(!bIsView);
              Permission_AllOnChange(!bIsView, "isView");
            }}
          />
          <span>View</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: !isReport,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isView}
          onChange={(e) => {
            rowinfo.isView = !rowinfo.isView;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsModify}
            onChange={(e) => {
              setbIsModify(!bIsModify);
              Permission_AllOnChange(!bIsModify, "isModify");
            }}
          />
          <span>Edit</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: !isReport,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isModify}
          onChange={(e) => {
            rowinfo.isModify = !rowinfo.isModify;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsDelete}
            onChange={(e) => {
              setbIsDelete(!bIsDelete);
              Permission_AllOnChange(!bIsDelete, "isDelete");
            }}
          />
          <span>Delete</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: !isReport,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isDelete}
          onChange={(e) => {
            rowinfo.isDelete = !rowinfo.isDelete;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsExport}
            onChange={(e) => {
              setbIsExport(!bIsExport);
              Permission_AllOnChange(!bIsExport, "isExport");
            }}
          />
          <span>Export</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: isMaster,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isExport}
          onChange={(e) => {
            rowinfo.isExport = !rowinfo.isExport;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsPrint}
            onChange={(e) => {
              setbIsPrint(!bIsPrint);
              Permission_AllOnChange(!bIsPrint, "isPrint");
            }}
          />
          <span>Print</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: isTransaction,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isPrint}
          onChange={(e) => {
            rowinfo.isPrint = !rowinfo.isPrint;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsConvert}
            onChange={(e) => {
              setbIsConvert(!bIsConvert);
              Permission_AllOnChange(!bIsConvert, "isConvert");
            }}
          />
          <span>Convert</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: isTransaction,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isConvert}
          onChange={(e) => {
            rowinfo.isConvert = !rowinfo.isConvert;
          }}
        />
      ),
    },
    {
      Header: "In Order",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: !isOthers,
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          onClick={(e) => e.target.select()}
          defaultValue={rowinfo.iInOrder}
          onChange={(e) => (rowinfo.iInOrder = e.target.value)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsBackup}
            onChange={(e) => {
              setbIsBackup(!bIsBackup);
              Permission_AllOnChange(!bIsBackup, "isBackup");
            }}
          />
          <span>Backup</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: isCompany,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isBackup}
          onChange={(e) => {
            rowinfo.isBackup = !rowinfo.isBackup;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsRestore}
            onChange={(e) => {
              setbIsRestore(!bIsRestore);
              Permission_AllOnChange(!bIsRestore, "isRestore");
            }}
          />
          <span>Restore</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: isCompany,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isRestore}
          onChange={(e) => {
            rowinfo.isRestore = !rowinfo.isRestore;
          }}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsNoAccess}
            onChange={(e) => {
              setbIsNoAccess(!bIsNoAccess);
              Permission_AllOnChange(!bIsNoAccess, "isNoAccess");
            }}
          />
          <span>No Access</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isNoAccess}
          onChange={(e) => {
            rowinfo.isNoAccess = !rowinfo.isNoAccess;
          }}
        />
      ),
    },
  ];
  const arrEntTableCol = [
    {
      strAliasName: "User",
      strColName: "strUserName",
      strTextAlign: "left",
      strWidth: "100%",
      color: "black",
    },
    {
      strAliasName: "User Role",
      strColName: "strRole",
      strTextAlign: "center",
      strWidth: "150",
      color: "black",
    },
    {
      strAliasName: "Delete",
      strTextAlign: "center",
      strWidth: "50",
      color: "black",
      cell: ({ user }) => <i className="fa-solid fa-trash"></i>,
    },
    {
      strAliasName: "Update",
      strTextAlign: "center",
      strWidth: "50",
      color: "black",
      cell: ({ user }) => (
        <i
          className="fa-solid fa-pen-to-square"
          onClick={(e) => {
            setisUpdate(true);
            View_User(user);
          }}
        ></i>
      ),
    },
  ];

  return (
    <section className="Config-card">
      <div className="config-h">
        <h3>User Account</h3>
        <div className="Config-btn">
          {!isUpdate && (
            <button
              onClick={(e) => {
                Clear_User();
                setisUpdate(true);
              }}
              className="btn-fabgreen"
            >
              New User <i className="fa-solid fa-user-plus"></i>
            </button>
          )}
          {isUpdate && (
            <button
              onClick={(e) => {
                ClearClick();
                setisUpdate(false);
              }}
              className="btn-fabgreen"
            >
              Back to User <i className="fa-solid fa-xmark"></i>
            </button>
          )}
          <button onClick={props.Close} className="btn-fabgreen">
            Close <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
      <div className="Config-Wrapper">
        {!isUpdate && (
          <div className="table-card">
            <table className="option-list">
              <thead>
                <tr>
                  {arrEntTableCol.map((ht, Index) => (
                    <th key={Index}>{ht.strAliasName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {ListEntUser.map((user, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = ListEntUser.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") View_User(user);
                    }}
                    onClick={(e) => View_User(user)}
                    onDoubleClick={(e) => View_User(user)}
                  >
                    {arrEntTableCol.map((dt, Index) =>
                      dt.cell ? (
                        <td
                          key={Index}
                          style={{
                            textAlign: dt.strTextAlign,
                            minWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            width: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            maxWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            color: "black",
                          }}
                        >
                          <dt.cell key={Index} user={user} />
                        </td>
                      ) : (
                        <td
                          key={Index}
                          style={{
                            textAlign: dt.strTextAlign,
                            minWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            width: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            maxWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            color: "black",
                          }}
                        >
                          {user[dt.strColName]}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {isUpdate && (
          <div className="row p-2">
            <div className="col-sm-6 col-md-3">
              <label>User Name</label>
              <span className="danger">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter User Name"
                tabIndex={6}
                value={strUserName}
                onKeyDown={(e) => Key_Down(e)}
                onClick={(e) => e.target.select()}
                onChange={(e) => CheckUser(e.target.value)}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <label>User Role</label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_parem.isoptionShow}
                placeholder="Select User Role"
                select_value={(val) => {
                  setiUserRole(val.iRoleId);
                  setstrUserRole(val.strRole);
                }}
                btnname=""
                btnshow={false}
                show_popup=""
                valueonly={false}
                defaultval={iUserRole}
                displayName="strRole"
                disvalue="iRoleId"
                arrydata={arrUserRole}
                EmptVal="User Role"
                disabled={
                  strUserRole === "Admin" ? (iUserId > 0 ? true : false) : false
                }
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <label>Password</label>
              <span className="danger">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Password"
                tabIndex={7}
                value={strPassword}
                onKeyDown={(e) => Key_Down(e)}
                onClick={(e) => e.target.select()}
                onChange={(e) => setstrPassword(e.target.value)}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <label>Confirm Password</label>
              <span className="danger">*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Password"
                tabIndex={8}
                value={strConfirmPassword}
                onKeyDown={(e) => Key_Down(e)}
                onClick={(e) => e.target.select()}
                onChange={(e) => setstrConfirmPassword(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value !== strPassword) {
                    Show_Message(dispatch, "Password Doesn't Match", "info");
                  }
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <label>Screen</label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_parem.isoptionShow1}
                placeholder="Select Screen"
                select_value={(val) =>
                  View_UserPermission(iUserId, Number(val), iUserRole)
                }
                btnname=""
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={iScrTypeId}
                displayName="strScrCatName"
                disvalue="iScrCatId"
                arrydata={arrEntScreen}
                disabled={iUserId === 0 ? true : false}
                EmptVal="Screen"
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <ReactTable
                columns={TransColumn}
                data={arrEntPermission}
                minRows={10}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {}}
                background={true}
                tabIndex={12}
                className="full-table"
              />
            </div>
          </div>
        )}
      </div>
      {isUpdate && (
        <div className="btn-section mt-1">
          <button
            type="button"
            className="btn-fabgreen"
            disabled={Number(iUserId) === 0 ? false : true}
            onClick={(e) => {
              if (strUserRole === "") {
                Show_Message(dispatch, "Please Enter UserRole", "info");
              } else if (strUserName === "") {
                Show_Message(dispatch, "Please Enter UserName", "info");
              } else if (strPassword === "") {
                Show_Message(dispatch, "Please Enter Password", "info");
              } else {
                Ask_Question(dispatch, SaveClick, "Do You Want to  Save User?");
              }
            }}
          >
            Save &nbsp; <i className="fas fa-Save"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            onClick={(e) => Update_UserPermission()}
          >
            Update &nbsp; <i className="fas fa-Save"></i>
          </button>
          <button type="button" className="btn-fabgreen" onClick={ClearClick}>
            Clear &nbsp; <i className="fas fa-eraser"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            onClick={(e) => {
              if (iUserId === 0) {
                Show_Message(dispatch, "Please Select User", "info");
              } else {
                Ask_Question(
                  dispatch,
                  DeleteUser,
                  "Do You Want to  Delete User?"
                );
              }
            }}
          >
            Delete &nbsp; <i className="fas fa-trase"></i>
          </button>
          <button
            type="button"
            className="btn-fabgreen"
            onClick={(e) => window.history.back()}
          >
            Close &nbsp; <i className="fas fa-times"></i>
          </button>
        </div>
      )}
    </section>
  );
}

export default UserMaster;


