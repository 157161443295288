import { useEffect } from "react";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import $ from "jquery";
import { API_FETCH } from "../General/Utility";
import { useDispatch, useSelector } from "react-redux";
var Option = { orientation: "p", unit: "mm", format: [0, 0] };
var doc = new jsPDF(Option);

function BarcodePreview(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  useEffect(() => {
    LoadBarCodeDesign();
  }, []);
  const LoadBarCodeDesign = async () => {
    try {
      var CompId = Number(localStorage.iCompId);
      const res = await API_FETCH(
        "Barcode/LoadPrintDesign/" + CompId + "/" + _AuthParems.DB,
        dispatch
      );
      if (res) {
        let ProductList = props.ProductList.filter(
          (Data) => Data.SelectProduct === true
        );
        var BarcodeValue = BarcodeBasedOn(res.objEntCodeFormate);
        Printbarcode(
          res.objbarCodeDesign,
          BarcodeValue,
          res.objEntCompanyInfo,
          res.objEntState,
          res.objEntPrintDesign,
          ProductList
        );
      }
    } catch (err) {
      MsgBox(err.message, "error");
    }
  };
  const BarcodeBasedOn = (BarcodeFormate) => {
    var Default = BarcodeFormate.filter((Data) => Data.defaultValue === 1);
    var CodeBasedOn = "";
    switch (Default[0].displayName) {
      case "Product Code":
        CodeBasedOn = "strProCode";
        break;
      case "Product Name":
        CodeBasedOn = "strProName";
        break;
      case "HSN Code":
        CodeBasedOn = "strCommodityCode";
        break;
      case "Unit Name":
        CodeBasedOn = "strUnitName";
        break;
      case "Remark":
        CodeBasedOn = "Remarks";
        break;
      default:
        CodeBasedOn = "dPriceRate";
        break;
    }
    return CodeBasedOn;
  };
  const Printbarcode = (
    CodeDesign,
    BarcodeValue,
    CompanyInfo,
    StateName,
    PrintDesign,
    ProductList
  ) => {
    try {
      PrintDesign = PrintDesign.map((Data) => {
        Data.dXAxis = Data.dXAxis * 10;
        Data.dYAxis = Data.dYAxis * 10;
        return Data;
      });
      // Calculat Min Max Values of LineAt and StartAt
      let dMaxLineAt = PrintDesign.sort((a, b) => b.dYAxis - a.dYAxis)[0]
        .dYAxis;
      let dMinLineAt = PrintDesign.sort((a, b) => a.dYAxis - b.dYAxis)[0]
        .dYAxis;
      let dMinStartAt = PrintDesign.sort((a, b) => a.dXAxis - b.dXAxis)[0]
        .dXAxis;

      var dTotalQty = 0;
      for (let i = ProductList.length - 1; i >= 0; i--) {
        dTotalQty += ProductList[i].Qty;
      }
      let Codeformat = {
        format1: "pharmacode",
        format2: "CODE39",
        format3: "code128",
      };
      //Filter Company Design
      let objEntProString = PrintDesign.filter(
        (DT) => DT.strColumName !== "Barcode" && DT.columType === 2
      );
      //Filter Product Design
      let objEntCompString = PrintDesign.filter(
        (DT) => DT.strColumName !== "Barcode" && DT.columType === 1
      );
      //Filter Barcode Design
      let objEntBarData = PrintDesign.filter(
        (DT) => DT.strColumName === "Barcode"
      )[0];
      // Add PrintContent
      let objEntString = [];
      for (let PS = 0; PS < objEntProString.length; PS++) {
        objEntString.push(
          AddPrintContent(objEntProString[PS], ProductList[0], 1)
        );
      }
      for (let CS = 0; CS < objEntCompString.length; CS++) {
        objEntString.push(
          AddPrintContent(objEntCompString[CS], CompanyInfo[0], StateName, 2)
        );
      }

      //Find Each Copy Height and Width
      let dConHeight = 0;
      let dMaxConWidth = 0;
      for (let ES = objEntString.length - 1; ES >= 0; ES--) {
        let textWH = fnMeasureString(
          objEntString[ES].strprintContent,
          objEntString[ES].strprintContent.iFontSize,
          objEntString[ES].strprintContent.strFontWeight
        );
        dConHeight += textWH.dContentHeight;
        if (dMaxConWidth < textWH.dContentWidth)
          dMaxConWidth = textWH.dContentWidth;
      }
      let TotalRow = Math.ceil(dTotalQty / CodeDesign[0].iNoColumn);
      dConHeight = dConHeight + CodeDesign[0].dHeight + CodeDesign[0].rowGap;
      let dCopyWidth =
        objEntBarData.dWidth > dMaxConWidth
          ? objEntBarData.dWidth + CodeDesign[0].columGap
          : dMaxConWidth + CodeDesign[0].columGap;
      dCopyWidth = Math.ceil(dCopyWidth);
      dConHeight = Math.ceil(dConHeight);
      //Page Width  and  Height Calculation
      let PageWidth = dCopyWidth * CodeDesign[0].iNoColumn;
      let PageyHeight = dConHeight * TotalRow;
      Option.format = [PageWidth, PageyHeight];
      doc = new jsPDF(Option);
      //Print Barcode and Data
      let CurrColumn = 1;
      let dFromLeft = 0;
      let dFromTop = 0;
      for (let i = 0; i < ProductList.length; i++) {
        var Qty = ProductList[i].Qty;
        var BarCodeData = ProductList[i][BarcodeValue];
        // Add PrintContent
        let objEntDataString = [];
        for (let PS = 0; PS < objEntProString.length; PS++) {
          objEntDataString.push(
            AddPrintContent(objEntProString[PS], ProductList[i], 1)
          );
        }
        for (let CS = 0; CS < objEntCompString.length; CS++) {
          objEntDataString.push(
            AddPrintContent(objEntCompString[CS], CompanyInfo[0], 2, StateName)
          );
        }
        for (var j = 0; j < Qty; j++) {
          $("#Barcode").empty();
          for (let EDS = 0; EDS < objEntDataString.length; EDS++) {
            const objStringData = {
              strprintContent: objEntDataString[EDS].strprintContent,
              iFontSize: objEntDataString[EDS].iFontSize,
              strColor: objEntDataString[EDS].strColor,
              strFontWeight: objEntDataString[EDS].strFontWeight,
              dXAxis: dFromLeft + objEntDataString[EDS].dXAxis,
              dYAxis: dFromTop + objEntDataString[EDS].dYAxis,
              strTextAlign: objEntDataString[EDS].strTextAlign,
            };
            DrawString(objStringData);
          }
          let BXA = dFromLeft + objEntBarData.dXAxis;
          let BYA = dFromTop + objEntBarData.dYAxis;
          let BWith = objEntBarData.dWidth;
          let BLiWid = CodeDesign[0].dwidth;
          let BHeight = CodeDesign[0].dHeight;
          let BFontSiz = CodeDesign[0].iFontSize;
          let BLColor = CodeDesign[0].strLineColor;
          let BTexTalign = objEntBarData.strTextAlign;
          JsBarcode("#Barcode", BarCodeData, {
            format: Codeformat.format3,
            width: BLiWid,
            height: BHeight,
            displayValue: false,
            font: "arial",
            textAlign: BTexTalign,
            fontSize: BFontSiz,
            lineColor: BLColor,
            background: "transparent",
            marginBottom: 0,
            marginRight: 0,
            marginLeft: 0,
            marginTop: 0,
          });
          var Img = document.getElementById("Barcode");

          // Generate PDF
          doc.addImage(Img.src, "PNG", BXA, BYA, BWith, BHeight);
          CurrColumn += 1;
          if (CurrColumn !== 1 && CurrColumn <= CodeDesign[0].iNoColumn) {
            dFromLeft = dFromLeft + dCopyWidth;
          }
          if (CurrColumn > CodeDesign[0].iNoColumn) {
            CurrColumn = 1;
            dFromLeft = 0;
            dFromTop = dFromTop + dConHeight;
          }
        }
      }
      doc.output("dataurlnewwindow", "BarCode");
    } catch (err) {
      MsgBox(err.message, "error");
    }
  };
  const MsgBox = (Message, Icon) => {
    alert(Message);
  };
  const AddPrintContent = (objEntString, objEntData, iColumType, State) => {
    let printContent = "";
    if (iColumType === 1) {
      printContent = GetProColumn(
        objEntString.strColumName,
        objEntData
      ).Value.toString();
    } else {
      printContent = GetCompanyColumn(
        objEntString.strColumName,
        objEntData,
        State
      ).Value.toString();
    }

    objEntString = {
      columType: objEntString.columType,
      dWidth: objEntString.dWidth,
      dXAxis: objEntString.dXAxis,
      dYAxis: objEntString.dYAxis,
      iColumnId: objEntString.iColumnId,
      iFontSize: objEntString.iFontSize,
      inOrder: objEntString.inOrder,
      printAreaId: objEntString.printAreaId,
      strColor: objEntString.strColor,
      strColumName: objEntString.strColumName,
      strDBName: objEntString.strDBName,
      strFontWeight: objEntString.strFontWeight,
      strPrefix: objEntString.strPrefix,
      strSuffix: objEntString.strSuffix,
      strTextAlign: objEntString.strTextAlign,
      strprintContent: printContent,
    };
    return objEntString;
  };
  const DrawString = (objStringData) => {
    var printContent = objStringData.strprintContent;
    printContent = printContent.includes("&#xD;&#xA;")
      ? printContent.replace(/&#xD;&#xA;/g, "\r\n")
      : printContent;
    //Print String Data
    doc.setFontSize(objStringData.iFontSize * 2);
    doc.setTextColor(objStringData.strColor);
    doc.setFont("Arial", "", objStringData.strFontWeight);
    doc.text(printContent, objStringData.dXAxis, objStringData.dYAxis, {
      align: objStringData.strTextAlign,
    });
  };
  const fnMeasureString = (pText, pFontSize, pStyle) => {
    var lDiv = document.createElement("p");
    lDiv.style.fontStyle = pStyle;
    lDiv.style.fontSize = "" + pFontSize + "px";
    lDiv.style.position = "absolute";
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;
    lDiv.textContent = pText;
    document.body.appendChild(lDiv);

    var lResult = {
      dContentWidth: lDiv.clientWidth,
      dContentHeight: lDiv.clientHeight,
    };
    document.body.removeChild(lDiv);
    lDiv = "";
    return lResult;
  };
  const GetProColumn = (strColumName, ProductList) => {
    let strValue = "";
    let strColumn = "";
    switch (strColumName) {
      case "ProCode":
        strValue = ProductList.strProCode;
        strColumn = "strProCode";
        break;
      case "ProName":
        strValue = ProductList.strProName;
        strColumn = "strProName";
        break;
      case "PrintName":
        strValue = ProductList.strPrintName;
        strColumn = "strPrintName";
        break;
      case "CommodityCode":
        strValue = ProductList.strCommodityCode;
        strColumn = "strCommodityCode";
        break;
      case "PriceRate":
        strValue = parseFloat(ProductList.dPriceRate).toFixed(2);
        strColumn = "dPriceRate";
        break;
      case "Discount":
        strValue = parseFloat(ProductList.dDiscount).toFixed(2);
        strColumn = "dDiscount";
        break;
      case "UnitName":
        strValue = ProductList.strUnitName;
        strColumn = "strUnitName";
        break;
      case "Remarks":
        strValue = ProductList.Remarks;
        strColumn = "Remarks";
        break;
      default:
        break;
    }
    return { Value: strValue, Column: strColumn };
  };
  const GetCompanyColumn = (strColumName, CompanyInfo, State) => {
    let strValue = "";
    let strColumn = "";
    switch (strColumName) {
      case "CompanyName":
        strValue = CompanyInfo.strCompName;
        strColumn = "strCompName";
        break;
      case "Address1":
        strValue = CompanyInfo.strAdd1;
        strColumn = "strAdd1";
        break;
      case "Address2":
        strValue = CompanyInfo.strAdd2;
        strColumn = "strAdd2";
        break;
      case "Address3":
        strValue = CompanyInfo.strAdd3;
        strColumn = "strAdd3";
        break;
      case "Address4":
        strValue = CompanyInfo.strAdd4;
        strColumn = "strAdd4";
        break;
      case "State":
        strValue = State.filter(
          (DT) => DT.iSId === CompanyInfo.iSName.toString()
        )[0].strSName;
        strColumn = "strSName";
        break;
      case "Phone":
        strValue = CompanyInfo.strPhone;
        strColumn = "strPhone";
        break;
      case "Pincode":
        strValue = CompanyInfo.iPincode;
        strColumn = "iPincode";
        break;
      case "Mobile":
        strValue = CompanyInfo.strMobile;
        strColumn = "strMobile";
        break;
      case "Email":
        strValue = CompanyInfo.strEmail;
        strColumn = "strEmail";
        break;
      case "TIN":
        strValue = CompanyInfo.strTIN;
        strColumn = "strTIN";
        break;
      case "GST":
        strValue = CompanyInfo.strGST;
        strColumn = "strGST";
        break;
      case "Remarks":
        strValue = CompanyInfo.strRemarks;
        strColumn = "strRemarks";
        break;
      case "Logo":
        strValue = CompanyInfo.imgData;
        strColumn = "imgData";
        break;
      default:
        break;
    }
    return { Value: strValue, Column: strColumn };
  };
}

export default BarcodePreview;
