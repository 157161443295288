import ExportImport from "./ExportImport";
import $ from "jquery";

import { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import MasterList from "./MasterListItem";
import AddressPopup from "../Transaction/AddressPopup";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import Messagedialogbox, { Ask_Question } from "../General/Messagedialogbox";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import MasterCodeSetting from "./MasterCodeSetting";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import { Country_OnChange } from "./MasterTransaction";
import Button from "./MasterButton";
function VendorMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const iScrId = 50;
  const [iVenId, setiVenId] = useState(0);
  const [strVenCode, setstrVenCode] = useState("");
  const [strVenName, setstrVenName] = useState("");
  const [strAliasName, setstrAliasName] = useState("");
  const [strOfficeNo, setstrOfficeNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [strCountryId, setstrCountryId] = useState("1");
  const [strStateId, setstrStateId] = useState(32);
  const [strPhone, setstrPhone] = useState("");
  const [strPhone1, setstrPhone1] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strMobile1, setstrMobile1] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strTinNo, setstrTinNo] = useState("");
  const [strGst, setstrGst] = useState("");
  const [ExpImpOpen, setExpImpOpen] = useState(false);
  const [iStatusId, setiStatusId] = useState(1);
  const [dOpBal, setdOpBal] = useState((0.0).toFixed());
  const [CreditTerm, setCreditTerm] = useState(30);
  const [iVenCastId, setiVenCastId] = useState(1);
  const [iBalTypeId, setiBalTypeId] = useState(3);
  const [strFilterColumn, setstrFilterColumn] = useState("All");
  const [strFilter, setstrFilter] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntStatus, setlistEntStatus] = useState([]);
  const [listEntCountry, setlistEntCountry] = useState([]);
  const [listEntState, setlistEntState] = useState([]);
  const [listEntVentCast, setlistEntVentCast] = useState([]);
  const [listEntBalTyp, setlistEntBalTyp] = useState([]);
  const [listEntVenList, setlistEntVenList] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [ListValidateProperties, setListValidateProperties] = useState([]);
  const [isAddress, setisAddress] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  useEffect(() => {
    Load_VendoreMaster();
  }, []);
  const Load_VendoreMaster = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        "VendoreMaster/Load_VendoreMaster/" +
          Number(_LocalParems.Branch) +
          "/" +
          _LocalParems.DB,
        dispatch
      );
      if (res) {
        setlistEntStatus(res.objEntStatus);
        setlistEntCountry(res.objEntCountry);
        setlistEntState(res.objEntState);
        setlistEntBalTyp(res.objEntBalTyp);
        setlistEntVentCast(res.objVenCast);
        setlistEntVenList(res.objEntVenList);
        setlistCodeFormat(res.objCodeFormat);
        setManualCode(!res.objCodeFormat[0].isManualNo);
        setstrVenCode(res.objCodeFormat[0].strCode);
        setListValidateProperties(res.Transtab);
        TabOrderAssign(res.Transtab);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ExportImportPopup = () => {
    try {
      setExpImpOpen(!ExpImpOpen);
      ClearClick();
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const listEntVenInsUpd = {
        iVenId: iVenId,
        iBranchId: Number(_AuthParems.Branch),
        strVenCode: strVenCode,
        strVenTag: "",
        strVenName: strVenName,
        strAliasName: strAliasName,
        strAdd1: strOfficeNo,
        strAdd2: strStreet,
        strAdd3: strArea,
        strAdd4: strCity,
        strPincode: strPinCode,
        iCountry: Number(strCountryId),
        iState: Number(strStateId),
        strPhone1: strPhone,
        strPhone2: strPhone1,
        strMobile1: strMobile,
        strMobile2: strMobile1,
        strEmail: strEmail,
        strGST: strGst,
        strRemarks: strRemarks,
        TinNo: strTinNo,
        iStatusId: Number(iStatusId),
        dOpBal: Number(dOpBal),
        CreditTerm: CreditTerm,
        iBalTypeId: Number(iBalTypeId),
        iVenCastId: Number(iVenCastId),
        iTitleTag: iScrId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strVenCodeTag: "",
        strFilterColumn: strFilterColumn,
        strFilter: strFilter,
        strSearch: strSearch,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "VendoreMaster/InsUpd_VendoreMaster/",
        listEntVenInsUpd,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        ClearClick();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_DELETE(
        "VendoreMaster/Delete_Vendor/" +
          iVenId +
          "/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = () => {
    try {
      setiVenId(0);
      setstrVenCode("");
      setstrVenName("");
      setstrAliasName("");
      setstrOfficeNo("");
      setstrStreet("");
      setstrCity("");
      setstrArea("");
      setstrPinCode("");
      setstrCountryId(100);
      setstrStateId(32);
      setstrPhone("");
      setstrPhone1("");
      setstrMobile("");
      setstrMobile1("");
      setstrEmail("");
      setstrRemarks("");
      setstrTinNo("");
      setstrGst("");
      setiStatusId(1);
      setdOpBal((0.0).toFixed());
      setCreditTerm(30);
      setiVenCastId(1);
      setiBalTypeId(3);
      setstrFilterColumn("All");
      setstrFilter("");
      setstrSearch("");
      Load_VendoreMaster();
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_Vendor = async (iVenId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `VendoreMaster/View_Vendor/${iVenId}/${_AuthParems.Branch}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        const EntVen = res.objEntVen[0];
        setiVenId(EntVen.iVenId);
        setstrVenCode(EntVen.strVenCode);
        setstrVenName(EntVen.strVenName);
        setstrAliasName(EntVen.strAliasName);
        setstrOfficeNo(EntVen.strAdd1);
        setstrStreet(EntVen.strAdd2);
        setstrArea(EntVen.strAdd3);
        setstrCity(EntVen.strAdd4);
        setstrPinCode(EntVen.strPincode);
        setstrCountryId(EntVen.iCountry.toString());
        setstrPhone(EntVen.strPhone1);
        setstrPhone1(EntVen.strPhone2);
        setstrMobile(EntVen.strMobile1);
        setstrMobile1(EntVen.strMobile2);
        setstrEmail(EntVen.strEmail);
        setstrGst(EntVen.strGST);
        setstrTinNo(EntVen.tinNo);
        setiStatusId(EntVen.iStatusId);
        setstrRemarks(EntVen.strRemarks);
        setiBalTypeId(EntVen.iBalTypeId);
        setdOpBal(EntVen.dOpBal);
        setiVenCastId(EntVen.iVenCastId);
        setCreditTerm(EntVen.creditTerm);
        setstrCountryId(EntVen.iCountry);
        setlistEntState(res.objEntState);
        setstrStateId(EntVen.iState);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Vendor = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strSearch: strtxt,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        `VendoreMaster/Search_Vendor/`,
        objSearch,
        dispatch
      );
      if (res) {
        setlistEntVenList(res.EntVen);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };

  //#region TabSetting
  const [ListTabOrder, setListTabOrder] = useState([
    {
      VenCodeTab: "",
      AliasTab: "",
      NameTab: "",
      OfficeNoTab: "",
      StreetTab: "",
      AreaTab: "",
      CityTab: "",
      PinCodeTab: "",
      CountryTab: "",
      StateTab: "",
      Phone1Tab: "",
      Phone2Tab: "",
      Mobile1Tab: "",
      Mobile2Tab: "",
      Emailtab: "",
      GstTab: "",
      TinNoTab: "",
      RemarksTab: "",
      CreditLimitTab: "",
      CreditTermTab: "",
      StatusTab: "",
      OpeningBalTab: "",
      BalTypeTab: "",
      VenCastTab: "",
      VendoreListTab: "",
      VendoreFIlterTab: "",
      VendoreSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtCode":
          TabOrder[0].VenCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtName":
          TabOrder[0].NameTab = EntProperties[i].iTabIndex;
          break;
        case "txtAlias":
          TabOrder[0].AliasTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd1":
          TabOrder[0].OfficeNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd2":
          TabOrder[0].StreetTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd3":
          TabOrder[0].AreaTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd4":
          TabOrder[0].CityTab = EntProperties[i].iTabIndex;
          break;
        case "cbCountry":
          TabOrder[0].CountryTab = EntProperties[i].iTabIndex;
          break;
        case "txtPinCode":
          TabOrder[0].PinCodeTab = EntProperties[i].iTabIndex;
          break;
        case "cbState":
          TabOrder[0].StateTab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone1":
          TabOrder[0].Phone1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone2":
          TabOrder[0].Phone2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile1":
          TabOrder[0].Mobile1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile2":
          TabOrder[0].Mobile2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtEmail":
          TabOrder[0].Emailtab = EntProperties[i].iTabIndex;
          break;
        case "txtTin":
          TabOrder[0].TinNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtGst":
          TabOrder[0].GstTab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].StatusTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarksTab = EntProperties[i].iTabIndex;
          break;
        case "txtOpBalance":
          TabOrder[0].OpeningBalTab = EntProperties[i].iTabIndex;
          break;
        case "cbBalType":
          TabOrder[0].BalTypeTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditLimit":
          TabOrder[0].CreditLimitTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditTerm":
          TabOrder[0].CreditTermTab = EntProperties[i].iTabIndex;
          break;
        case "cbVenCast":
          TabOrder[0].VenCastTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].VendoreFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].VendoreSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbVendorName":
          TabOrder[0].VendoreListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].VendoreListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].VendoreListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].VendoreListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].VendoreListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].VendoreListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].VendoreListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].VendoreListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtAlias":
          ErrorMsg = strAliasName === "" ? false : true;
          break;
        case "txtName":
          ErrorMsg = strVenName === "" ? false : true;
          break;
        case "txtAdd1":
          ErrorMsg = strOfficeNo === "" ? false : true;
          break;
        case "txtAdd2":
          ErrorMsg = strStreet === "" ? false : true;
          break;
        case "txtAdd3":
          ErrorMsg = strArea === "" ? false : true;
          break;
        case "txtAdd4":
          ErrorMsg = strCity === "" ? false : true;
          break;
        case "txtPinCode":
          ErrorMsg = strPinCode === "" ? false : true;
          break;
        case "txtPhone1":
          ErrorMsg = strPhone === "" ? false : true;
          break;
        case "txtPhone2":
          ErrorMsg = strPhone1 === "" ? false : true;
          break;
        case "txtMobile1":
          ErrorMsg = strMobile === "" ? false : true;
          break;
        case "txtMobile2":
          ErrorMsg = strMobile1 === "" ? false : true;
          break;
        case "txtEmail":
          ErrorMsg = strEmail === "" ? false : true;
          break;
        case "txtTin":
          ErrorMsg = strTinNo === "" ? false : true;
          break;
        case "txtGst":
          ErrorMsg = strGst === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "txtOpBalance":
          ErrorMsg = dOpBal === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  //#endregion

  return (
    <main className="main-container">
      <SideMenu ScrId={iScrId} />
      <div className="content">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          tabOrder={ListTabOrder[0].CustomerSearchTab}
          Search={(val) => Search_Vendor(val)}
          arrMasterData={listEntVenList}
          isImg={false}
          displayName="strVenName"
          Parem1="iVenId"
          Parem2="iVenTypeId"
          View_Data={View_Vendor}
          placeholder="Vendor"
        />
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-2">
              <label>
                Name <span className="danger">*</span>
              </label>
              <input
                type="text"
                aria-label="First name"
                spellCheck={false}
                className="form-control"
                placeholder="Enter Vendor Name"
                tabIndex={ListTabOrder[0].NameTab}
                onKeyDown={(e) => TabIndex(e)}
                value={strVenName}
                onChange={(e) => setstrVenName(e.target.value)}
                autoFocus
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>
                Code <span className="danger">*</span>
              </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  id="txtProductCode"
                  readOnly={ManualCode}
                  value={strVenCode}
                  tabIndex={ListTabOrder[0].VenCodeTab}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setstrVenCode(e.target.value)}
                  name="strProCode"
                />
                <span
                  className="inv-no"
                  onClick={(e) => setIsMasterCode(!IsMasterCode)}
                >
                  <i className="bx bx-cog"></i>
                </span>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Alias Name</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Alias Name"
                value={strAliasName}
                tabIndex={ListTabOrder[0].AliasTab}
                onKeyDown={(e) => TabIndex(e)}
                onChange={(e) => setstrAliasName(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Office /Door No </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Address No"
                tabIndex={ListTabOrder[0].OfficeNoTab}
                onKeyDown={(e) => TabIndex(e)}
                value={strOfficeNo}
                onChange={(e) => setstrOfficeNo(e.target.value)}
              />
              <span
                className="btn-shipadd"
                onClick={(e) => {
                  if (iVenId === 0)
                    Show_Message(dispatch, "Please Select Party", "info");
                  else setisAddress(!isAddress);
                }}
              >
                <i className="fa-solid fa-location-dot"></i>
                Add Secountary Address
              </span>
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Street</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Street Name"
                tabIndex={ListTabOrder[0].StreetTab}
                onKeyDown={(e) => TabIndex(e)}
                value={strStreet}
                onChange={(e) => setstrStreet(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Area </label>
              <input
                type="text"
                aria-label="First name"
                placeholder="Enter Area Name"
                className="form-control"
                value={strArea}
                tabIndex={ListTabOrder[0].AreaTab}
                onKeyDown={(e) => TabIndex(e)}
                onChange={(e) => setstrArea(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>City</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter City Name"
                tabIndex={ListTabOrder[0].CityTab}
                onKeyDown={(e) => TabIndex(e)}
                value={strCity}
                onChange={(e) => setstrCity(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Pincode</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Pincode"
                value={strPinCode}
                tabIndex={ListTabOrder[0].PinCodeTab}
                onKeyDown={(e) => TabIndex(e)}
                onChange={(e) => setstrPinCode(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-2">
              <label>
                Country <span className="danger">*</span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selecrparem.isoptionShow}
                placeholder="Select Country"
                select_value={async (val) => {
                  await setstrCountryId(val);
                  await setlistEntState(
                    await Country_OnChange(val, _AuthParems.DB, dispatch)
                  );
                }}
                keydown={(e) => TabIndex(e)}
                taborder={ListTabOrder[0].CountryTab}
                btnname="Country"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={strCountryId}
                displayName="strCountryName"
                disvalue="iCountryId"
                arrydata={listEntCountry}
                disabled={false}
                EmptVal="Country "
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-2">
              <label>
                State <span className="danger">*</span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_selecrparem.isoptionShow1}
                placeholder="Select State"
                select_value={(val) => setstrStateId(val)}
                keydown={(e) => TabIndex(e)}
                taborder={ListTabOrder[0].StateTab}
                btnname="State"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={strStateId}
                displayName="strSName"
                disvalue="iSId"
                arrydata={listEntState}
                disabled={false}
                EmptVal="State "
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Phone </label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Phone Number"
                tabIndex={ListTabOrder[0].Phone1Tab}
                onKeyDown={(e) => TabIndex(e)}
                value={strPhone}
                onChange={(e) => setstrPhone(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Phone 1</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Phone Number"
                tabIndex={ListTabOrder[0].Phone2Tab}
                onKeyDown={(e) => TabIndex(e)}
                value={strPhone1}
                onChange={(e) => setstrPhone1(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Mobile</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Mobile Number"
                value={strMobile}
                tabIndex={ListTabOrder[0].Mobile1Tab}
                onKeyDown={(e) => TabIndex(e)}
                onChange={(e) => setstrMobile(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Mobile 1</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Mobile Number"
                tabIndex={ListTabOrder[0].Mobile2Tab}
                onKeyDown={(e) => TabIndex(e)}
                value={strMobile1}
                onChange={(e) => setstrMobile1(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>E-Mail </label>
              <input
                type="email"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Email"
                tabIndex={ListTabOrder[0].Emailtab}
                onKeyDown={(e) => TabIndex(e)}
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>GST</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                tabIndex={ListTabOrder[0].GstTab}
                onKeyDown={(e) => TabIndex(e)}
                placeholder="Enter GST No"
                value={strGst}
                onChange={(e) => setstrGst(e.target.value)}
                maxLength="15"
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>TIN</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter TIN Number"
                value={strTinNo}
                tabIndex={ListTabOrder[0].TinNoTab}
                onKeyDown={(e) => TabIndex(e)}
                onChange={(e) => setstrTinNo(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>Status</label>
              <CustomizedSelectoption
                optionparem="isoptionShow2"
                showoption={_selecrparem.isoptionShow2}
                placeholder="Select Status"
                select_value={(val) => setiStatusId(val)}
                keydown={(e) => TabIndex(e)}
                taborder={ListTabOrder[0].StatusTab}
                btnname="State"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iStatusId}
                displayName="strStatusName"
                disvalue="iStatusId"
                arrydata={listEntStatus}
                disabled={false}
                EmptVal="Status "
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>Vendor Cast</label>
              <CustomizedSelectoption
                optionparem="isoptionShow3"
                showoption={_selecrparem.isoptionShow3}
                placeholder="Select Vendor Cast"
                select_value={(val) => setiVenCastId(val)}
                keydown={(e) => TabIndex(e)}
                taborder={ListTabOrder[0].StatusTab}
                btnname="Vendor Cast"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iVenCastId}
                displayName="strVenCastName"
                disvalue="iVenCastId"
                arrydata={listEntVentCast}
                disabled={false}
                EmptVal="Vendor Cast "
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Opening Balance</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Opening Balance"
                tabIndex={ListTabOrder[0].OpeningBalTab}
                onKeyDown={(e) => TabIndex(e)}
                value={dOpBal}
                onChange={(e) => setdOpBal(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-2">
              <label>Balance Type</label>{" "}
              <CustomizedSelectoption
                optionparem="isoptionShow4"
                showoption={_selecrparem.isoptionShow4}
                placeholder="Select Balance Type"
                select_value={(val) => setiBalTypeId(val)}
                keydown={(e) => TabIndex(e)}
                taborder={ListTabOrder[0].BalTypeTab}
                btnname="Balance Type"
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iBalTypeId}
                displayName="strBalType"
                disvalue="iBalTypeId"
                arrydata={listEntBalTyp}
                disabled={false}
                EmptVal="Balance Type "
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Credit Term Days</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                tabIndex={ListTabOrder[0].CreditTermTab}
                onKeyDown={(e) => TabIndex(e)}
                value={CreditTerm}
                onChange={(e) => setCreditTerm(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Remarks</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                tabIndex={ListTabOrder[0].RemarksTab}
                onKeyDown={(e) => TabIndex(e)}
                placeholder="Enter Remarks"
                value={strRemarks}
                onChange={(e) => setstrRemarks(e.target.value)}
              />
            </div>
          </div>
          {ExpImpOpen && (
            <ExportImport Close={ExportImportPopup} iScrId={iScrId} />
          )}
          {isAddress && (
            <AddressPopup
              ScrId={iScrId}
              PartyId={iVenId}
              PartyName={strVenName}
              Close={(e) => setisAddress(!isAddress)}
            />
          )}
          {IsMasterCode && (
            <MasterCodeSetting
              Close={(e) => setIsMasterCode(!IsMasterCode)}
              iScrId={iScrId}
            />
          )}
          <Messagedialogbox />
        </div>
        <Button
          Save={(e) => {
            if (PropertiesValidation()) {
              if (strGst.length > 0 && strGst.length < 15)
                Show_Message(dispatch, "Please Enter Valid GST Number", "info");
              else
                Ask_Question(
                  dispatch,
                  SaveClick,
                  "Do You Want to  Save Supplier?"
                );
            }
          }}
          Export={(e) => setExpImpOpen(!ExpImpOpen)}
          Clear={ClearClick}
          Delete={(e) => {
            if (iVenId === 0) {
              Show_Message(dispatch, "Please Select Vendor", "info");
            } else {
              Ask_Question(
                dispatch,
                DeleteClick,
                "Do You Want to  Delete Supplier?"
              );
            }
          }}
          ScrId={iScrId}
          Iid={iVenId}
          SaveIndex={ListTabOrder[0].BtnSavtab}
          DeleteIndex={ListTabOrder[0].BtnDeletetab}
          CloseIndex={ListTabOrder[0].BtnClosetab}
          ClearIndex={ListTabOrder[0].BtnCleartab}
          ExportIndex={ListTabOrder[0].BtnExporttab}
          onKeyDown={(e) => TabIndex(e)}
        />
      </div>
    </main>
  );
}
export default VendorMaster;
