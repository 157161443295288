import React, { useEffect, useState } from "react";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import Messagedialogbox, {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import {
  Add_AdjustmentReason,
  Add_StockTransProduct,
  Clear_Product,
  Clear_Transaction,
  Input_Onchange,
  Modify_InventoryProduct,
  Modify_StockTransAdjustment,
  ProductInput_Onchange,
  ProductRate_LostFocus,
  ProductRate_Onchange,
  Select_Unit,
  ShowHide_Popup,
  Stock_Parems,
} from "../features/Transactionstates";
import SideMenu from "../General/SideMenu";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import ReactTable from "../General/ReactTable";
import StockTransView from "./StockTransView";
import ItemRemarks from "../Transaction/ItemRemarks";
import StockTransConvertion from "./StockTransConvertion";
function TransferAdjustment() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const _Product = useSelector((store) => store.Transstates.Product);
  const _Summary = useSelector((store) => store.Transstates.Summary);
  const _ScrId = 105;
  const [WHId, setWHId] = useState(0);

  const [IsAdjustment, setIsAdjustment] = useState(false);
  const [isView, setisView] = useState(false);
  const [isVouchNoSett, setisVouchNoSett] = useState(false);
  const [AdjScrId, setAdjScrId] = useState(63);

  const [arrEntBranch, setarrEntBranch] = useState([]);
  const [arrEntWH, setarrEntWH] = useState([]);
  const [arrEntReason, setarrEntReason] = useState([]);

  useEffect(() => {
    Load_StockTransAdjustment();
  }, []);

  const Load_StockTransAdjustment = async () => {
    try {
      const _LocalParem = await DecryptData(localStorage.jbctrlparams);
      const parems = {
        iUserId: Number(_LocalParem.User),
        iRoleId: Number(_LocalParem.Role),
        iClientId: Number(_LocalParem.Client),
        iBranchId: Number(_LocalParem.Branch),
        strDBName: _LocalParem.DB,
      };
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `StocktransAdjustment/Load_StockTransAdjustment`,
        parems,
        dispatch
      );
      if (res) {
        setarrEntBranch(res.EntBranch);
        setarrEntWH(res.EntWareHouse);
        setarrEntReason(res.EntReason);
        dispatch(
          Stock_Parems({
            iScrId: _ScrId, // Sales Invoice Screen Id For Get Sales Rate
            strScrName: "Stock Trans Adjustment",
            strScrType: "Sales",
            iPartyId: 0,
            iTaxRelId: 1,
            NoFormat: res.EntNoFormat[0],
          })
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const tab_click = async (tabindex, ScrId) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    await $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    setAdjScrId(ScrId);
  };
  const Clear_Click = async () => {
    setisVouchNoSett(false);
    setisView(false);
    setWHId(0);
    setarrEntWH([]);
    setarrEntBranch([]);
    await dispatch(Clear_Transaction());
    await Load_StockTransAdjustment();
  };
  const InsUpd_StockAdjustment = async () => {
    try {
      const parems = {
        iTitleTag: _ScrId,
        iAdjScrId: Number(AdjScrId),
        iNumSeqNo: _HT.iNumSeqNo,
        strPrefix: _HT.strPrefix,
        strCompCode: _HT.strCompCode,
        strFinanFrom: _HT.strFinanFrom,
        iStoAdjId: _HT.iVouchId,
        strTransNo: _HT.strTransNo,
        strVouchNo: _HT.strVouchNo,
        strVouchDate: _HT.strVouchDate,
        strConvertVoucher: _HT.strConvertDCVouch,
        strRemark1: _HT.strRemark1,
        strRemark3: _HT.strRemark2,
        strRemark2: _HT.strRemark3,
        strRemark4: _HT.strRemark4,
        dTQty: _Summary.Qty,
        dTGoodsValue: _Summary.GoodsValue,
        dRoundOff: _Summary.RoundOff,
        dNetAmt: _Summary.NetAmt,
        iUserId: Number(_AuthParems.User),
        iBranchId: Number(_AuthParems.Branch),
        iWHId: WHId,
        strDBName: _AuthParems.DB,
      };
      const objParems = {
        EntHT: parems,
        BaseTransDT: _DT.arrEntDT,
        EntPenCon: _DT.arrEntPenCon,
      };
      const res = await API_POST(
        "StocktransAdjustment/InsUpd_StockAdjustment",
        objParems,
        dispatch
      );
      if (res) {
        await Show_Message(dispatch, res.strMessage, "success");
        await Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const View_StockAdjustment = async (Vouch) => {
    try {
      setisView(false);
      const parems = {
        iVouchId: Number(Vouch.vouchId),
        iBranchId: Number(_AuthParems.Branch),
        iUserId: Number(_AuthParems.User),
        iRoleId: Number(_AuthParems.Role),
        iClientId: Number(_AuthParems.Client),
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `StocktransAdjustment/View_StockAdjustment`,
        parems,
        dispatch
      );
      if (res) {
        let Data = res.EntInvoice.entHT[0];
        setWHId(Data.iWHId);
        setarrEntWH(res.EntWH);
        await dispatch(Modify_StockTransAdjustment(res.EntInvoice));
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Delete_StockAdjustment = async () => {
    try {
      const parems = `StocktransAdjustment/Delete_StockAdjustment/${_HT.iVouchId}/${_AuthParems.DB}`;
      const res = await API_DELETE(parems, dispatch);
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Unit_Onchange =async (UnitId) => {
    const res =await API_FETCH(
      `ProductTrans/Unit_Onchange/${Number(UnitId)}/${_Product.iProId}/ ${
        _HT.iPartyId
      }/${_HT.iTaxRelId}/${_Common.strScrType}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      dispatch(Select_Unit({ dPriceRate: res.dPriceRate, UnitId: UnitId }));
    }
  };
  const Input_ValueChange = async (e) => {
    await dispatch(
      Input_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const ProInput_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const AddProductClick = async () => {
    try {
      const Parems = {
        objItem: {
          ProId: _Product.iProId,
          ProName: _Product.strProName,
          AttValues: _Product.strAttValue,
          CommodityCode: "",
          ProCode: _Product.strProCode,
          Qty: Number(_Product.dQty),
          RateWithoutTax: Number(_Product.dRate),
          RateWithTax: Number(_Product.dRatewithTax),
          UnitId: Number(_Product.iUnitId),
          UnitName: _Product.strUnitName,
          BatchNo: _Product.BatchNo,
          ManufactureDate: _Product.MftDate,
          ExpiryDate: _Product.ExpDate,
          Remark1: _Product.Remarks.strRemark1,
          Remark2: _Product.Remarks.strRemark2,
          Remark3: _Product.Remarks.strRemark3,
          Remark4: _Product.Remarks.strRemark4,
          isComposPro: Number(_Product.isComposPro),
          isBatchPro: Number(_Product.isBatchPro),
          BranchId: _AuthParems.Branch,
          Reason: _Product.Reason,
          ReasonId: _Product.iReasonId,
          WHId: 0,
          TBranchId: 0,
        },
        BaseTransDT: _DT.arrEntDT,
        iRowIndex: _Product.iRowIndex,
        bUpdateStatus: _Product.bUpdateStatus,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "StocktransAdjustment/Add_Product",
        Parems,
        dispatch
      );
      if (res) {
        await dispatch(Add_StockTransProduct(res));
        await dispatch(Clear_Product());
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Assign_Product = async (ProInfo, Index) => {
    try {
      const objTrans = {
        itemId: ProInfo.proId,
        iTaxRelId: 1,
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
      };
      let Unit = [
        {
          bIsChecked: false,
          iLeastUnitId: 0,
          iUnitId: ProInfo.unitId,
          strUnitName: ProInfo.unitName,
        },
      ];
      if (ProInfo.isComposPro !== 1) {
        const res = await API_POST(
          `InventoryTrans/View_Unit/`,
          objTrans,
          dispatch
        );
        if (res) Unit = res.EntUnit;
      }
      await dispatch(
        Modify_InventoryProduct({
          ProInfo: ProInfo,
          Unit: Unit,
          Index: Index,
        })
      );
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Delete_Product = async (Index) => {
    try {
      const objParems = {
        _objItem: {
          ProId: _Product.iProId,
          ProName: _Product.strProName,
          AttValues: _Product.strAttValue,
          CommodityCode: "",
          ProCode: _Product.strProCode,
          Qty: Number(_Product.dQty),
          FQty: Number(_Product.FQty),
          RateWithoutTax: Number(_Product.dRate),
          RateWithTax: Number(_Product.dRatewithTax),
          UnitId: Number(_Product.iUnitId),
          UnitName: _Product.strUnitName,
          BatchNo: _Product.BatchNo,
          ManufactureDate: _Product.MftDate,
          ExpiryDate: _Product.ExpDate,
          BranchId: _AuthParems.Branch,
          WHId: WHId,
        },
        _objBaseTransDT: _DT.arrEntDT,
        iRowIndex: Index,
        bUpdateStatus: _Product.bUpdateStatus,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "StockReceive/Delete_Product",
        objParems,
        dispatch
      );
      if (res) {
        await dispatch(Add_StockTransProduct(res));
        await dispatch(Clear_Product());
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const Rate_Onchange = (e) => {
    dispatch(
      ProductRate_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Rate_LostFocus = (e) => {
    dispatch(
      ProductRate_LostFocus({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Columns = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Product Name",
      accessor: "proName",
      style: { justifyContent: "left" },
      minWidth: 200,
    },
    {
      Header: "Attributes",
      accessor: "attValues",
      style: { justifyContent: "left", color: "gray" },
      minWidth: 100,
      maxWidth: 200,
    },

    {
      Header: "Unit",
      accessor: "unitName",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Qty",
      accessor: "qty",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Rate Without Tax",
      accessor: "rateWithoutTax",
      minWidth: 120,
      maxWidth: 120,
      style: { justifyContent: "right" },
    },
    {
      Header: "Item Amount",
      accessor: "itemAmt",
      minWidth: 120,
      maxWidth: 120,
      style: { justifyContent: "right" },
    },
    {
      Header: "Reason",
      accessor: "reason",
      minWidth: 120,
      maxWidth: 120,
      style: { justifyContent: "right" },
    },
    {
      Header: "Action",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={() => {
            Delete_Product(rowinfo.sNo);
          }}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  return (
    <>
      <SideMenu ScrId={_ScrId} />
      <div className="content">
        <div className="Master-card1">
          <div className="main-content">
            <div className="fg-card shadow-base">
              <div className="trans-tab">
                <div className="trans-buttons">
                  <label onClick={(e) => tab_click(0, 63)} className="active">
                    Stock Transfer
                  </label>
                  <label onClick={(e) => tab_click(1, 104)}>
                    Stock Receive
                  </label>
                  <div id="tab-line"></div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-sm-6 col-md-3">
                  <label>Adjustment No</label>
                  <div>
                    <input
                      type="text"
                      name="strVouchNo"
                      className="form-control"
                      placeholder="Transfer No"
                      readOnly={!_HT.isManualCode}
                      value={_HT.strVouchNo}
                      onClick={(e) => e.target.select()}
                      onChange={(e) => Input_ValueChange(e)}
                    />
                    <span
                      className="inv-no"
                      onClick={(e) => setisVouchNoSett(!isVouchNoSett)}
                    >
                      <i className="bx bx-cog"></i>
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>Adjustment Date</label>
                  <input
                    type="date"
                    name="strVouchDate"
                    className="form-control"
                    placeholder="Transfer No"
                    value={_HT.strVouchDate}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => Input_ValueChange(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>Branch</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_selecrparem.isoptionShow}
                    placeholder="Select Branch"
                    btnname="Branch"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={_AuthParems.Branch}
                    displayName="strBranchName"
                    disvalue="iBranchId"
                    arrydata={arrEntBranch}
                    disabled={true}
                    EmptVal="Branch"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>Warehouse</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_selecrparem.isoptionShow1}
                    placeholder="Select From Warehouse"
                    select_value={(val) => {
                      setWHId(Number(val));
                      setIsAdjustment(!IsAdjustment);
                    }}
                    btnname="Warehouse"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={WHId}
                    displayName="strWHName"
                    disvalue="iWHId"
                    arrydata={arrEntWH}
                    disabled={false}
                    EmptVal="Warehouse"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>Converted Voucher</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    name="strConvertDCVouch"
                    value={_HT.strConvertDCVouch}
                    disabled
                    readOnly
                    style={{ minHeight: "100px" }}
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>Remarks 1</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    name="strRemark1"
                    value={_HT.strRemark1}
                    onChange={(e) => Input_ValueChange(e)}
                    spellCheck="false"
                    autoComplete="off"
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Remarks 2</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    name="strRemark2"
                    value={_HT.strRemark2}
                    onChange={(e) => Input_ValueChange(e)}
                    spellCheck="false"
                    autoComplete="off"
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Remarks 3</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    name="strRemark3"
                    value={_HT.strRemark3}
                    onChange={(e) => Input_ValueChange(e)}
                    spellCheck="false"
                    autoComplete="off"
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Remarks 4</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    name="strRemark4"
                    value={_HT.strRemark4}
                    onChange={(e) => Input_ValueChange(e)}
                    spellCheck="false"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="fg-card shadow-base mt-2">
              <div className="row p-2">
                <div className="col-sm-12 col-md-4">
                  <label>Product Name</label>
                  <div style={{ position: "relative" }}>
                    <div className="custom-select">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Press Enter to Select Product "
                        defaultValue={_Product.strProName}
                        onClick={(e) => setIsAdjustment(!IsAdjustment)}
                        disabled={true}
                      />
                      <i className="fa-solid fa-caret-down"></i>
                    </div>
                    {_Product.strProName.length > 0 && (
                      <div className="tran-calicon">
                        <i
                          className="fa-solid fa-message"
                          onClick={(e) =>
                            dispatch(ShowHide_Popup("IsProRemark"))
                          }
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Qty</label>
                  <input
                    type="number"
                    className="form-control"
                    name="dQty"
                    autoComplete="off"
                    id="txtproQty"
                    disabled={_Product.iProId === 0 ? true : false}
                    value={_Product.dQty}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => {
                      ProInput_Onchange(e);
                    }}
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Unit</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_selecrparem.isoptionShow4}
                    placeholder="Unit"
                    select_value={(val) => Unit_Onchange(val)}
                    btnname="Unit"
                    btnshow={false}
                    show_popup=""
                    valueonly={false}
                    defaultval={_Product.iUnitId}
                    displayName="strUnitName"
                    disvalue="iUnitId"
                    arrydata={_Product.Unit}
                    disabled={_Product.iProId === 0 ? true : false}
                    EmptVal="Unit"
                  />
                </div>
                <div className="col-sm-6 col-md-2">
                  <label>Rate Without Tax</label>
                  <input
                    type="number"
                    className="form-control"
                    name="dRate"
                    autoComplete="off"
                    id="txtproRate"
                    value={_Product.dRate}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => Rate_Onchange(e)}
                    onBlur={(e) => Rate_LostFocus(e)}
                    disabled={_Product.iProId === 0 ? true : false}
                  />
                </div>
                {_Product.isBatchPro && (
                  <div className="col-sm-6 col-md-2">
                    <label>Batch No</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Batch No"
                      autoComplete="off"
                      name="BatchNo"
                      value={_Product.BatchNo}
                      onClick={(e) => e.target.select()}
                      onChange={(e) => ProInput_Onchange(e)}
                      disabled={_Product.iProId === 0 ? true : false}
                    />
                  </div>
                )}
                {_Product.isBatchPro && (
                  <div className="col-sm-6 col-md-2">
                    <label>Manufacture Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="MftDate"
                      value={_Product.MftDate}
                      onClick={(e) => e.target.select()}
                      onChange={(e) => ProInput_Onchange(e)}
                      disabled={_Product.iProId === 0 ? true : false}
                    />
                  </div>
                )}
                {_Product.isBatchPro && (
                  <div className="col-sm-6 col-md-2">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="ExpDate"
                      value={_Product.ExpDate}
                      onClick={(e) => e.target.select()}
                      onChange={(e) => ProInput_Onchange(e)}
                      disabled={_Product.iProId === 0 ? true : false}
                    />
                  </div>
                )}
                <div className="col-sm-6 col-md-2">
                  <label>Reason</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow5"
                    showoption={_selecrparem.isoptionShow5}
                    placeholder="Select Reason"
                    select_value={(val) =>
                      dispatch(
                        Add_AdjustmentReason({
                          Reason: val.strReason,
                          iReasonId: val.iReasonId,
                        })
                      )
                    }
                    btnname="Unit"
                    btnshow={false}
                    show_popup=""
                    valueonly={false}
                    defaultval={_Product.iReasonId}
                    displayName="strReason"
                    disvalue="iReasonId"
                    arrydata={arrEntReason}
                    disabled={_Product.iProId === 0 ? true : false}
                    EmptVal="Reason"
                  />
                </div>
                {Number(_Product.iProId) > 0 && (
                  <div className="col-sm-6 col-md-2 mt-2">
                    <div className="btn-sub-section">
                      <button
                        className="btn-fabgreen"
                        onClick={AddProductClick}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") AddProductClick();
                        }}
                      >
                        Add&nbsp;<i className="bx bx-layer-plus"></i>
                      </button>
                      <button
                        className="btn-fabgreen"
                        onClick={(e) => dispatch(Clear_Product())}
                      >
                        Clear&nbsp;<i className="fas fa-eraser"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <ReactTable
                columns={Columns}
                data={_DT.arrEntDT}
                minRows={5}
                row_click={(ProInfo, Index) => {}}
                row_doubleclick={(ProInfo, Index) =>
                  Assign_Product(ProInfo, Index)
                }
                background={true}
                className="full-table"
              />
              <div className="fg-card shadow-base mt-2">
                <div className="row p-2">
                  <div className="col-md-9 col-sm-12">
                    <div className="row">
                      <div className="col-md-3 col-sm-12">
                        <label>Total Qty</label>
                        <input
                          className="form-control"
                          style={{ textAlign: "right" }}
                          value={Number(_Summary.Qty).toFixed(2)}
                          disabled
                        />
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <label>Total Goods Value</label>
                        <input
                          className="form-control"
                          style={{ textAlign: "right" }}
                          value={Number(_Summary.GoodsValue).toFixed(2)}
                          disabled
                        />
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <label>Round Off</label>
                        <input
                          className="form-control"
                          style={{ textAlign: "right" }}
                          value={Number(_Summary.RoundOff).toFixed(2)}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <div className="net-amount">
                      <h1>NET AMOUNT</h1>
                      <h2>
                        {new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "INR",
                        }).format(Number(_Summary.NetAmt).toFixed(2))}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {_Common.IsProRemark && <ItemRemarks />}
            {IsAdjustment && (
              <StockTransConvertion
                Close={() => setIsAdjustment(!IsAdjustment)}
                WHId={WHId}
                ScrId={_ScrId}
                AdjScrId={AdjScrId}
              />
            )}
            {isView && (
              <StockTransView
                Close={(e) => setisView(!isView)}
                View_Voucher={View_StockAdjustment}
                WHId={WHId}
              />
            )}
          </div>
          <Messagedialogbox />
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  InsUpd_StockAdjustment,
                  "Do You Want to Adjust Stock?"
                );
              }}
            >
              Save&nbsp;
              <i className="fa-solid fa-arrow-up-from-bracket"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setisView(!isView)}
            >
              View&nbsp;<i className="fas fa-eye"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (_HT.iVouchId === 0)
                  Show_Message(dispatch, "Please Select Adjustment", "info");
                else
                  Ask_Question(
                    dispatch,
                    Delete_StockAdjustment,
                    "Do You Want to Delete Stock Adjustment ?"
                  );
              }}
            >
              Delete&nbsp; <i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={Clear_Click}>
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                window.location.href = `/JustBill/DashBoard`;
              }}
            >
              Close&nbsp; <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransferAdjustment;
