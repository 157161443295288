import React, { useEffect, useState } from "react";
import Logo from "../Image/JustBill.png";
import $ from "jquery";
import { Buffer } from "buffer";
import { Authorize, Domain, EncryptData } from "./Utility";
import JBLogo from "../Image/justBillRound.png";
import axios from "axios";
function UserLogin() {
  const [strErrMsg, setstrErrMsg] = useState("");
  const [strClassName, setstrClassName] = useState("fa-solid fa-eye-slash");
  const [strUserName, setstrUserName] = useState("");
  const [strPassword, setstrPassword] = useState("");
  useEffect(() => {
    async function Check_JwtToken() {
      let IsValid = await Authorize();
      if (IsValid)
        // Redirect to Home Page
        window.location.href = `/JustBill/DashBoard`;
    }
    Check_JwtToken();
  }, []);
  const PassVisible = () => {
    var x = document.getElementById("password-field");
    if (x.type === "password") {
      x.type = "text";
      setstrClassName("fa-solid fa-eye");
    } else {
      setstrClassName("fa-solid fa-eye-slash");
      x.type = "password";
    }
  };
  const SignIn = async () => {
    try {
      if (strUserName === "") {
        setstrErrMsg("Invalid User Name");
      } else if (strPassword === "") {
        setstrErrMsg("Invalid Password");
      } else {
        var isLoading = false;
        var interval = setInterval(function () {
          isLoading = !isLoading;
          Loginloading(isLoading);
        }, 800);
        let IPConfig = {};
        IPConfig.strUserName = strUserName;
        IPConfig.strPassword = strPassword;
        await axios
          .post(`${Domain}Admin/User_Login/`, IPConfig)
          .then(async (res) => {
            res = res.data;
            await clearInterval(interval);
            await Loginloading(false);
            if (res.strStatus === "Success") {
              let user = res.User; // Loged in user Detailes
              const TransPerm = res.EntTransPer;
              localStorage.jbparetkau = await res.Token;
              const EntParems = await {
                Client: user.iClientId,
                User: user.iUserId,
                Role: user.iUserRole,
                Rolename: user.strRole,
                Company: user.iCompId,
                OrgName: user.strCompanyName.replace(/ /g, "_"),
                Logo: res.Branch
                  ? res.Branch.logo.length > 0
                    ? Convert_Bytotoimage(res.Branch.logo)
                    : JBLogo
                  : JBLogo,
                Year: user.iYearId,
                Fyear: user.strYear,
                Branch: user.iBranchId,
                Branchname: res.Branch ? res.Branch.strBranchName : "",
                Counter: user.iCounterId,
                Session: 0,
                DB: user.strDBName,
                NxtFY: user.strYearTo,
                MasterPer: await View_Permission(TransPerm, 1),
                PurchasePer: await View_Permission(TransPerm, 2),
                SalesPer: await View_Permission(TransPerm, 3),
                GroupsPer: await View_Permission(TransPerm, 4),
                JobworkPer: await View_Permission(TransPerm, 5),
                AccountsPer: await View_Permission(TransPerm, 6),
                ReportscrPer: await View_Permission(TransPerm, 7),
                ToolsPer: await View_Permission(TransPerm, 8),
                OthersPer: await View_Permission(TransPerm, 9),
                PosPer: await View_Permission(TransPerm, 10),
                InventoryPer: await View_Permission(TransPerm, 11),
                ReportPer: await View_Permission(TransPerm, 12),
                CompanyPer: await View_Permission(TransPerm, 13),
                BranchPer: await View_Permission(TransPerm, 14),
                WarehousePer: await View_Permission(TransPerm, 15),
                CounterPer: await View_Permission(TransPerm, 16),
                IsDirect: 0,
              };
              localStorage.jbctrlparams = await EncryptData(EntParems);
              if (user.iCompId === 0)
                window.location.href = `/CompanyMaster`; // Redirect to Home Page
              else if (user.bIsPos)
                window.location.href = `/POSInvoice`; // Redirect to POS Page
              else window.location.href = `/JustBill/DashBoard`; // Redirect to Home Page
            } else setstrErrMsg(res.strMessage);
          });
      }
    } catch (err) {
      clearInterval(interval);
      setstrErrMsg(err.message);
    }
  };
  const Convert_Bytotoimage = (logo) => {
    var UnitBitArray = new Uint8Array(logo);
    var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
    return "data:image/png;base64," + ArrayToString;
  };
  const View_Permission = (arrPermission, Id) => {
    let EntMenu = arrPermission.filter((per) => per.iScrCatId === Id);
    return EntMenu.sort((a, b) => a.iInOrder - b.iInOrder);
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowDown") {
      if (ActiveIndex > 1 && ActiveIndex <= 7)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 6 + "']").focus();
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex >= 1 && ActiveIndex < 7)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 7) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else if (ActiveIndex === 3) SignIn();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  const Loginloading = (isLoading) => {
    var element = document.getElementById("Loginloading");
    if (isLoading) {
      element.classList.add("login_progress");
    } else {
      element.classList.remove("login_progress");
    }
  };
  return (
    <div className="login-container">
      <div className="login-content">
        <div className="login-Img">
          <img src={Logo} alt="JustBill"></img>
        </div>
        <div className="login-input">
          <div className="login-header">
            <h6>Let's get Started</h6>
            <label>Sign In To Continue</label>
          </div>
          {strErrMsg.length > 0 && <p className="Err-Msg">{strErrMsg}</p>}
          <div className="login-control">
            <label>User Name</label>
            <div className="login-usepass">
              <i className="fa-solid fa-user"></i>
              <input
                spellCheck={false}
                value={strUserName}
                onChange={(e) => setstrUserName(e.target.value)}
                type="text"
                placeholder="Username"
                autoFocus
                tabIndex={1}
                onKeyDown={(e) => TabIndex(e)}
                onClick={(e) => e.target.select()}
              />
            </div>
          </div>
          <div className="login-control">
            <label>Password</label>
            <div className="login-usepass">
              <i className="fa-solid fa-lock"></i>
              <input
                spellCheck={false}
                value={strPassword}
                onChange={(e) => setstrPassword(e.target.value)}
                id="password-field"
                type="password"
                tabIndex={2}
                onClick={(e) => e.target.select()}
                onKeyDown={(e) => TabIndex(e)}
                placeholder="Password"
              />
              <span>
                <i className={strClassName} onClick={PassVisible}></i>
              </span>
            </div>
          </div>
        </div>
        <div className="login-btn">
          <button onKeyDown={(e) => TabIndex(e)} tabIndex={3} onClick={SignIn}>
            Sign In
          </button>
        </div>
        <div id="Loginloading"></div>
      </div>
    </div>
  );
}
export default UserLogin;
