import React from "react";
import { API_FETCH, API_POST } from "../General/Utility";
import { useEffect, useState } from "react";
import PrintScreen from "../Print/PrintScreen";
import $ from "jquery";
import PrinterSetting from "../Print/PrinterSetting";
import PrintInvoicDesign from "../General/PrintInvoice";
import WhatsApp from "../General/WhatsApp";
import EmailPopup from "../General/EmailPopup";
import { useDispatch, useSelector } from "react-redux";

import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_Message } from "../General/Messagedialogbox";
function StockTransView(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.Transstates.Common);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strSearch, setstrSearch] = useState("");
  const [iVouchId, setiVouchId] = useState(0);
  const [bIsPrintOpen, setbIsPrintOpen] = useState(false);

  const [IsPrintSetting, setIsPrintSetting] = useState(false);
  const [IsOpenEmail, setIsOpenEmail] = useState(false);
  const [IsOpenwhatsapp, setIsOpenwhatsapp] = useState(false);
  const [WHId, setWHId] = useState(0);
  const [arrEntWH, setarrEntWH] = useState([]);
  const [arrEntVoucher, setarrEntVoucher] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_Voucher();
  }, []);
  const Load_Voucher = async () => {
    const objSearch = {
      iPartyId: 0,
      iScrId: _Common.iScrId,
      strFromDate: "",
      strToDate: "",
      strSearch: "",
      WHId: 0,
      iBranchId: Number(_AuthParems.Branch),
      iUserId: Number(_AuthParems.User),
      iRoleId: Number(_AuthParems.Role),
      iClientId: Number(_AuthParems.Client),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      `InventoryTrans/Load_Voucher/`,
      objSearch,
      dispatch
    );
    if (res) {
      setstrFromDate(res.strFromDate);
      setstrToDate(res.strToDate);
      setarrEntVoucher(res.EntStock);
      setarrEntTableCol(res.EntTableDesign);
      setarrEntWH(res.EntWH);
      setWHId(res.WHId);
    }
  };
  const Search_Voucher = async (val, type) => {
    let FromDate = strFromDate;
    let ToDate = strToDate;
    let Search = strSearch;
    let WH = WHId;
    switch (type) {
      case "Search":
        Search = val;
        setstrSearch(Search);
        break;
      case "FromDate":
        FromDate = val;
        strFromDate(FromDate);
        break;
      case "ToDate":
        ToDate = val;
        strToDate(ToDate);
        break;
      case "WH":
        WH = val;
        setWHId(Number(WH));
        break;
      default:
        break;
    }
    const objSearch = {
      iPartyId: 0,
      iScrId: _Common.iScrId,
      strFromDate: FromDate,
      strToDate: ToDate,
      strSearch: Search,
      iWHId: Number(WH),
      iBranchId: Number(_AuthParems.Branch),
      iUserId: Number(_AuthParems.User),
      iRoleId: Number(_AuthParems.Role),
      iClientId: Number(_AuthParems.Client),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      `InventoryTrans/Search_Voucher/`,
      objSearch,
      dispatch
    );
    if (res) {
      setarrEntVoucher(res.EntStock);
    }
  };
  const TableKeyDow = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter")
      if (ActiveIndex === 52) {
        if (arrEntVoucher.length > 0) {
          props.View(arrEntVoucher[0].vouchId);
        }
      }
    if (e.key === "ArrowDown") {
      $(".Invoice_table .rt-tbody .rt-tr:eq(1)").focus();
    }
    if (e.key === "ArrowUp") {
      $(".Invoice_table .rt-tbody .rt-tr:eq(1)").focus();
    }
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 1 && ActiveIndex <= 3)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 4 + "']").focus();
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    } else if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  const PrintPdfClick = async (PrintFor) => {
    try {
      if (iVouchId === 0)
        Show_Message(dispatch, dispatch, "Please Select Voucher", "info");
      else {
        let VoucherId = iVouchId === undefined ? 0 : iVouchId;
        let TempId = 0;
        const res = API_FETCH(
          `PrintDesing/PrintPreView/${
            _Common.iScrId
          }/${TempId}/${VoucherId}/${Number(_AuthParems.Branch)}/${
            _AuthParems.DB
          }`,
          dispatch
        );
        if (res) {
          PrintInvoicDesign(res, _Common.iScrId, PrintFor);
        }
      }
    } catch (err) {
      Show_Message(dispatch, dispatch, err.strMessage, "info");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>View {_Common.strScrName}</h5>
          <div className="icon-popup-btn-grp">
            <span
              className=" icon-btn"
              onClick={(e) => setIsPrintSetting(!IsPrintSetting)}
            >
              <i className="fa-solid fa-gear"></i>
            </span>
            <span className=" icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-1">
          <div className="row">
            <div className="col-md-6 col-sm-12 mt-2">
              <div className="m-hsearch">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  autoFocus
                  autoComplete="off"
                  id="Invoice_Search"
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" ||
                      e.key === "ArrowUp" ||
                      e.key === "ArrowDown"
                    )
                      TableKeyDow(e);
                  }}
                  tabIndex={52}
                  name="strSearch"
                  value={strSearch}
                  onChange={(e) => Search_Voucher(e.target.value, "Search")}
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Voucher(strSearch, "Search")}
                ></i>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mt-2">
              <input
                type="date"
                className="form-control "
                value={strFromDate}
                name="strFromDate"
                tabIndex={2}
                onKeyDown={(e) => TabIndex(e)}
                style={{
                  marginRight: "10px",
                  borderRadius: "3px 0 0 3px !important",
                }}
                onChange={(e) => Search_Voucher(e.target.value, "FromDate")}
              />
            </div>
            <div className="col-md-3 col-sm-12 mt-2">
              <input
                type="date"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={3}
                className="form-control"
                value={strToDate}
                name="strToDate"
                onChange={(e) => Search_Voucher(e.target.value, "ToDate")}
              />
            </div>
            <div className="col-sm-12 col-md-6 mb-1">
              <label>Warehouse</label>
              <CustomizedSelectoption
                optionparem="isoptionShow7"
                showoption={_selecrparem.isoptionShow7}
                placeholder="Select From Warehouse"
                select_value={(val) => Search_Voucher(val, "WH")}
                btnname="Warehouse"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={WHId}
                displayName="strWHName"
                disvalue="iWHId"
                arrydata={arrEntWH}
                disabled={false}
                EmptVal="Warehouse"
              />
            </div>
          </div>
          <div className="table-card mt-1">
            <table className="option-list">
              <thead>
                <tr>
                  {arrEntTableCol.map(
                    (ht, Index) =>
                      ht.isShow && <th key={Index}>{ht.strAliasName}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {arrEntVoucher.map((voucher, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrEntVoucher.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "ArrowLeft") $("#to_date").focus();
                      if (e.key === "ArrowRight") $("#Invoice_Search").focus();
                      if (e.key === "Enter") props.View_Voucher(voucher);
                    }}
                    onClick={(e) => setiVouchId(voucher.vouchId)}
                    onDoubleClick={(e) => props.View_Voucher(voucher)}
                  >
                    {arrEntTableCol.map(
                      (dt, Index) =>
                        dt.isShow && (
                          <td
                            key={Index}
                            style={{
                              textAlign: dt.strTextAlign,
                              width: dt.strWidth.includes("%")
                                ? dt.strWidth
                                : dt.strWidth + "px",
                              maxWidth: dt.strWidth.includes("%")
                                ? dt.strWidth
                                : dt.strWidth + "px",
                              color: "black",
                            }}
                          >
                            {dt.strDataType === "number"
                              ? Number(voucher[dt.strColName]).toFixed(2)
                              : voucher[dt.strColName]}
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <div className="PTip" onClick={(e) => PrintPdfClick("Print")}>
              <a className="icon-btn" href="##">
                <i className="fa-solid fa-print" tabIndex="1"></i>
              </a>
            </div>
            <div className="PTip" onClick={(e) => PrintPdfClick("PDF")}>
              <a className="icon-btn" href="##">
                <i className="fa-solid fa-download"></i>
              </a>
            </div>
            <div
              className="PTip"
              onClick={(e) => setIsOpenwhatsapp(!IsOpenwhatsapp)}
            >
              <a className="icon-btn" href="##">
                <i className="fa-brands fa-whatsapp" tabIndex="3"></i>
              </a>
            </div>
            <div className="PTip" onClick={(e) => setIsOpenEmail(!IsOpenEmail)}>
              <a className="icon-btn" href="##">
                <i className="fa-solid fa-envelope" tabIndex="4"></i>
              </a>
            </div>
          </div>
        </div>
        {bIsPrintOpen && (
          <PrintScreen
            Close={(e) => setbIsPrintOpen(!bIsPrintOpen)}
            iScrId={_Common.iScrId}
            iFormateId={0}
            iVouchId={iVouchId}
          />
        )}
        {IsPrintSetting && (
          <PrinterSetting
            Close={(e) => setIsPrintSetting(!IsPrintSetting)}
            iScrId={_Common.iScrId}
          />
        )}
        {IsOpenEmail && (
          <EmailPopup
            Close={(e) => setIsOpenEmail(!IsOpenEmail)}
            iVouchId={iVouchId}
            VouchersList={arrEntVoucher}
            iScrId={_Common.iScrId}
            ScrName={_Common.strScrName}
          />
        )}
        {IsOpenwhatsapp && (
          <WhatsApp
            Close={(e) => setIsOpenwhatsapp(!IsOpenwhatsapp)}
            iVouchId={iVouchId}
            iScrId={_Common.iScrId}
            ScrName={_Common.strScrName}
          />
        )}
      </div>
    </div>
  );
}

export default StockTransView;
