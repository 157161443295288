import React, { useEffect, useState } from "react";

import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";

import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function OpenStock() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [selected, setselected] = useState(0);

  const [strSearch, setstrSearch] = useState("");
  const [iProId, setiProId] = useState(0);
  const [iUnitId, setiUnitId] = useState(0);
  const [strProName, setstrProName] = useState("");
  const [dQty, setdQty] = useState(0);
  const [bUpdateStatus, setbUpdateStatus] = useState(false);
  const [isOption, setisOption] = useState(false);

  const [strNewProName, setstrNewProName] = useState("");
  const [strAttValues, setstrAttValues] = useState("");
  const [strBatchNo, setstrBatchNo] = useState("");
  const [dpManufactureDate, setdpManufactureDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dpExpiryDate, setdpExpiryDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [arrUnit, setarrUnit] = useState([]);
  const [arrProduct, setarrProduct] = useState([]);
  const [arrNewProduct, setarrNewProduct] = useState([]);
  useEffect(() => {
    Load_OpenStock();
  }, []);
  const Load_OpenStock = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);

    const res = await API_FETCH(
      `OpenStock/Load_OpenStocks/${Number(_LocalParems.Branch)}/${
        _LocalParems.DB
      }`,
      dispatch
    );
    if (res) {
      setarrProduct(res.objEntProduct);
    }
  };
  const Search_Product = async (SearchText) => {
    setstrSearch(SearchText);
    const res = await API_POST(
      "Transaction/Search_Product/",
      {
        iScrId: 37,
        strSearchBasedOn: "All",
        strAttDTList: "",
        iPartyId: 0,
        iAttGroupId: 0,
        iBillId: 0,
        strSearch: SearchText,
        iProId: 0,
        strScreen: "Purchase",
        strDBName: _AuthParems.DB,
      },
      dispatch
    );
    if (res) {
      setarrProduct(res.EntProduct);
    }
  };
  const Search_NewProduct = async (SearchText) => {
    setstrSearch(SearchText);
    const res = await API_POST(
      "Transaction/Search_Product/",
      {
        iScrId: 37,
        strSearchBasedOn: "All",
        strAttDTList: "",
        iPartyId: 0,
        iAttGroupId: 0,
        iBillId: 0,
        strSearch: SearchText,
        iProId: 0,
        strScreen: "Purchase",
        strDBName: _AuthParems.DB,
      },
      dispatch
    );
    if (res) {
      setarrNewProduct(res.EntProduct);
    }
  };
  const Save_OpenStock = async () => {
    const res = await API_POST(
      "OpenStock/Update_OpenStock/",
      {
        objModel: arrProduct,
        strDBName: _AuthParems.DB,
        iUserid: Number(_AuthParems.User),
        iBranchId: Number(_AuthParems.Branch),
      },
      dispatch
    );
    if (res) {
      Clear_Product();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const Delete_OpenStock = (Row) => {
    let Product = arrProduct;
    Product = Product.filter((dt) => dt.proId !== Row.proId);
    Product = Product.map((dt, Index) => {
      dt.sNo = Index + 1;
      return dt;
    });
    setarrProduct(Product);
  };
  const Select_Product = async (ProId) => {
    try {
      setisOption(!isOption);
      const objTrans = {
        iProId: ProId,
        iPartyId: 0,
        iScrId: 37,
        strScrType: "Purchase",
        iTaxRelId: 0,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `ProductTrans/View_Product/`,
        objTrans,
        dispatch
      );
      if (res) {
        const Proinfo = res.EntProInfo[0];
        setiProId(Proinfo.iProId);
        setstrProName(Proinfo.strProName);
        setstrAttValues(Proinfo.strAttValues);
        setdQty(1);
        setiUnitId(Proinfo.iUnitId);
        setarrUnit(res.ProUnit);
        $("#dQty").focus();
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    }
  };
  const Assign_Product = async (proinfo) => {
    try {
      const res = await API_FETCH(
        `ProductTrans/View_Product/${proinfo.proId}/
          37
       /0/Purchase/0/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setarrUnit(res.ProUnit);
        setbUpdateStatus(true);
        setstrProName(proinfo.proName);
        setiProId(proinfo.proId);
        setdQty(proinfo.qty);
        setiUnitId(proinfo.unitId);
        setstrBatchNo(proinfo.batchNo);
        setdpManufactureDate(proinfo.manufactureDate);
        setdpExpiryDate(proinfo.expiryDate);
        $("#dQty").focus();
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    }
  };
  const Add_Product = async () => {
    if (strProName === "" && strNewProName === "") {
      Show_Message(dispatch, "Please select Product", "info");
    } else if (dQty === 0) {
      Show_Message(dispatch, "Minimum Qty is Required", "info");
    } else {
      const res = await API_POST(
        "OpenStock/AddStock/",
        {
          bUpdateStatus: bUpdateStatus,
          iRowIndex: selected,
          iProId: iProId,
          dQty: dQty,
          iUnitId: iUnitId,
          strBatchNo: strBatchNo,
          strManufactureDate: dpManufactureDate,
          strExpiryDate: dpExpiryDate,
          strProAttDTId: "0",
          strStoAttDTId: "0",
          strTraAttDTId: "0",
          strDBName: _AuthParems.DB,
          objEntDT: arrProduct,
        },
        dispatch
      );
      if (res) {
        setarrProduct(res.objEntDT);
        setselected(0);
        setstrProName("");
        setstrNewProName("");
        setiProId(0);
        setdQty(0);
        setiUnitId(0);
        setstrBatchNo("");
        setdpManufactureDate(new Date().toISOString().split("T")[0]);
        setdpExpiryDate(new Date().toISOString().split("T")[0]);
        setbUpdateStatus(false);
      }
    }
  };
  const Clear_Product = () => {
    setselected(0);
    setstrProName("");
    setiProId(0);
    setdQty(0);
    setiUnitId(0);
    setstrBatchNo("");
    setdpManufactureDate(new Date().toISOString().split("T")[0]);
    setdpExpiryDate(new Date().toISOString().split("T")[0]);
    setbUpdateStatus(false);
    Load_OpenStock();
  };
  const Column = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
      style: { justifyContent: "center" },
    },

    {
      Header: "Product Name",
      accessor: "proName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Attributes",
      accessor: "attValues",
      style: { justifyContent: "left", color: "gray" },
    },
    {
      Header: "Batch No",
      accessor: "batchNo",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Manufacure Date",
      accessor: "manufactureDate",
      maxWidth: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: "Expiry Date",
      accessor: "expiryDate",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Unit",
      accessor: "unitName",
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Qty",
      accessor: "qty",
      maxWidth: 100,
      Cell: (props) => props.value.toFixed(2),
      style: { justifyContent: "right" },
    },
    {
      Header: "Action",
      id: "openStockId",
      maxWidth: 70,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div>
          <i
            onClick={() => Delete_OpenStock(rowInfo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  return (
    <>
      <SideMenu ScrId={37} />
      <div className="Trans-container">
        <div className="Trans-card">
          <div className="Trans-wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="m-hsearch">
                  <input
                    type="search"
                    placeholder="Search Product"
                    value={strSearch}
                    onChange={(e) => Search_Product(e.target.value)}
                    autoFocus
                  />
                  <i
                    className="fas fa-search"
                    onClick={(e) => Search_Product(strProName)}
                  ></i>
                </div>
              </div>
              <div className="col-sm-12 col-md-8">
                <div
                  className="custom-select"
                  onMouseLeave={(e) => setisOption(false)}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Select Product"
                    defaultValue={strNewProName}
                    onClick={(e) => {
                      setisOption(true);
                      Search_NewProduct("");
                    }}
                  />
                  <i className="fa-solid fa-caret-down"></i>
                  {isOption && (
                    <div className="custom-option">
                      <div className="search-option">
                        <input
                          className="form-control"
                          type="text"
                          autoFocus
                          placeholder="Search Party"
                          value={strSearch}
                          onChange={(e) => Search_NewProduct(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "ArrowDown") {
                              $(".option-list [tabindex=0]").focus();
                            } else if (e.key === "Enter") {
                              Select_Product(arrNewProduct[0].proId);
                            }
                          }}
                        />
                        {arrNewProduct.length === 0 && (
                          <span> product Not Found</span>
                        )}
                      </div>
                      <div className="option-list">
                        {arrNewProduct.map((product, Index) => (
                          <button
                            key={Index}
                            tabIndex={Index}
                            onClick={(e) => Select_Product(product.proId)}
                            onKeyDown={(e) => {
                              let arrlength = arrNewProduct.length - 1;
                              if (e.key === "ArrowDown") {
                                if (arrlength === Index)
                                  $(`.option-list [tabindex=0]`).focus();
                                else
                                  $(
                                    `.option-list [tabindex=${Index + 1}]`
                                  ).focus();
                              }
                              if (e.key === "ArrowUp") {
                                if (Index === 0)
                                  $(
                                    `.option-list [tabindex=${arrlength}]`
                                  ).focus();
                                else
                                  $(
                                    `.option-list [tabindex=${Index - 1}]`
                                  ).focus();
                              }
                              if (e.key === "Enter")
                                Select_Product(product.proId);
                            }}
                            className="pro-con"
                          >
                            <div className="sub-con ">
                              <label>{product.proName} </label>
                              <label style={{ color: "blue" }}>
                                {product.attValues}
                              </label>
                            </div>
                            <div className="sub-con">
                              <label>Stock In Hand </label>
                              <label
                                style={{
                                  color: product.tQty >= 0 ? "gray" : "red",
                                  fontWeight: "600",
                                }}
                              >
                                {product.tQty} {product.unitName}
                              </label>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <label>
                  Product Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={strProName}
                  readOnly
                />
              </div>
              <div className="col-sm-12 col-md-1">
                <label>
                  Qty <span className="danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="dQty"
                  value={dQty}
                  onChange={(e) => setdQty(Number(e.target.value))}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-sm-12 col-md-1">
                <label>Unit</label>
                <div>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_selecrparem.isoptionShow}
                    placeholder="Unit"
                    select_value={(val) => setiUnitId(Number(val))}
                    btnname="Unit"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={iUnitId}
                    displayName="strUnitName"
                    disvalue="iUnitId"
                    arrydata={arrUnit}
                    disabled={false}
                    EmptVal="Unit"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-1">
                <label>Batch No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Batch No"
                  value={strBatchNo}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrBatchNo(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2">
                <label>Manufacture Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={dpManufactureDate}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => {
                    setdpManufactureDate(e.target.value);
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-2">
                <label>Expiry Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={dpExpiryDate}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => {
                    setdpExpiryDate(e.target.value);
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-1">
                <br />
                <button
                  type="button"
                  onClick={Add_Product}
                  className="btn-fabgreen"
                >
                  Add
                </button>
              </div>
              <div className="col-sm-6 col-md-1">
                <br />
                <button
                  type="button"
                  onClick={Clear_Product}
                  className="btn-fabgreen"
                >
                  Clear
                </button>
              </div>
              <ReactTable
                columns={Column}
                data={arrProduct}
                minRows={10}
                row_click={(rowInfo, Index) => {
                  setselected(Index);
                }}
                row_doubleclick={(rowInfo) => {
                  Assign_Product(rowInfo);
                }}
                background={true}
                tabIndex={12}
                className="full-table"
              />
            </div>
          </div>
          <div className="btn-section">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={Save_OpenStock}
            >
              Update &nbsp; <i className="fas fa-save"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={Load_OpenStock}
            >
              Clear &nbsp; <i className="fas fa-eraser"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = `/JustBill/DashBoard`)}
            >
              Close &nbsp; <i className="fas fa-eraser"></i>
            </button>
          </div>
          <Messagedialogbox />
        </div>
      </div>
    </>
  );
}

export default OpenStock;
