import { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_POST, DecryptData } from "../General/Utility";
import ReportTable from "./ReportTable";
function GeneralReports() {
  const dispatch = useDispatch();
  const _AuthParem = useSelector((state) => state.Common.Admin);
  const [iRptId, setiRptId] = useState(0);
  const [iScrId, setiScrId] = useState(0);
  const [strPartyName, setstrPartyName] = useState("");
  const [iPartyid, setiPartyid] = useState(0);
  const [iTotalRows, setiTotalRows] = useState(0);
  const [RowPerPage, setRowPerPage] = useState(0);
  const [CurrPage, setCurrPage] = useState(1);
  const [strPartyScrColName, setstrPartyScrColName] = useState("");

  const [strFilterColName, setstrFilterColName] = useState("");
  const [strReportName, setstrReportName] = useState("General Reports");
  const [isPartyShow, setisPartyShow] = useState(false);
  const [iVenTypeId, setiVenTypeId] = useState(5);

  const [arrReportDesing, setarrReportDesing] = useState([]);
  const [arrReports, setarrReports] = useState([]);
  const [arrReportData, setarrReportData] = useState([]);
  const [arrSummarydata, setarrSummarydata] = useState([]);

  const [iBranchId, setiBranchId] = useState(0);
  const [iWHId, setiWHId] = useState(0);
  const [arrBranch, setarrBranch] = useState([]);
  const [arrWH, setarrWH] = useState([]);
  const [arrParty, setarrParty] = useState([]);
  const [IsWHdisable, setIsWHdisable] = useState(true);

  const [strSearch, setstrSearch] = useState("");
  const [strcolSearch, setstrcolSearch] = useState("");
  const [isPartyoption, setisPartyoption] = useState(false);

  useEffect(() => {
    Load_Reports("", "");
  }, []);

  const Load_Reports = async (strtxt) => {
    try {
      await Pre_Loading(dispatch, true);
      const AuthParems = await DecryptData(localStorage.jbctrlparams);
      setstrSearch(strtxt);
      const parems = {
        iScrId: 0,
        iPartyId: 0,
        strColName: "Report",
        strSearch: strtxt,
        strDBName: AuthParems.DB,
        iUserId: Number(AuthParems.User),
        iClientId: Number(AuthParems.Client),
        iRoleId: Number(AuthParems.Role),
        iBranchId: Number(AuthParems.Branch),
      };
      const res = await API_POST(`Reports/Load_Reports/`, parems, dispatch);
      if (res) {
        setarrReports(res.objEntRpt);
        setarrBranch(res.EntBranch);
        setarrWH(res.EntWareHouse);
        setiBranchId(AuthParems.Branch);
      }
    } catch (e) {
      await Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Report = async (iId, strName, Branch, WH) => {
    try {
      await Pre_Loading(dispatch, true);
      setstrReportName(strName);
      await setiRptId(Number(iId));
      await setiPartyid(0);
      await setiTotalRows(0);
      await setRowPerPage(0);
      await setstrPartyName("");
      if (!fnOpenParty(iId)) {
        setisPartyShow(false);
        if (Number(iId) === 49 || Number(iId) === 50) setIsWHdisable(false);
        const parems = {
          iRptId: Number(iId),
          iPartyId: iPartyid,
          strColName: "",
          strPartyName: strPartyName,
          strSearch: "",
          strDBName: _AuthParem.DB,
          iUserId: Number(_AuthParem.User),
          iBranchId: Number(Branch),
          iWHId: WH,
        };
        const res = await API_POST(
          `Reports/Load_GeneralReports/`,
          parems,
          dispatch
        );
        if (res) {
          let RptDesign = res.EntDesign.sort(
            (a, b) => a.inOrderTo - b.inOrderTo
          );
          RptDesign = RptDesign.filter((rdt) => rdt.isShowInReport);
          setarrReportDesing(RptDesign);
          let RptData = JsontoArray(res.EntReport);
          setarrReportData(RptData);
          setstrFilterColName(res.strSearchCol);
          setiTotalRows(res.TotalRows);
          setRowPerPage(res.RowPerPage);
          if (res.EntSummary !== undefined) {
            let Summary = JSON.parse(res.EntSummary);
            if (Summary.length > 0) {
              setarrSummarydata(Summary);
            }
          } else {
            setarrSummarydata([]);
          }
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Report = async (strtxt, strFilterCol) => {
    try {
      await Pre_Loading(dispatch, true);
      const objSearch = {
        iRptId: Number(iRptId),
        iPartyId: iPartyid,
        strColName: strFilterCol,
        strPartyName: strPartyName,
        strSearch: strtxt,
        strDBName: _AuthParem.DB,
        iUserId: Number(_AuthParem.User),
        iBranchId: Number(_AuthParem.Branch),
      };
      const res = await API_POST(
        `Reports/Load_GeneralReports/`,
        objSearch,
        dispatch
      );
      if (res) {
        let RptData = res.EntReport === null ? [] : JSON.parse(res.EntReport);
        setarrReportData(RptData);
        if (RptData.length > 0 && res.EntSummary !== undefined) {
          let Summary = JSON.parse(res.EntSummary);
          if (Summary.length > 0) {
            setarrSummarydata(Summary);
          }
        } else {
          if (RptData.length === 0)
            Show_Message(dispatch, "No Row's Found", "success");
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_PageReport = async (Page) => {
    try {
      await Pre_Loading(dispatch, true);
      setCurrPage(Page);
      const parems = {
        iRptId: Number(iRptId),
        iPartyId: iPartyid,
        strColName: strFilterColName,
        strPartyName: strPartyName,
        strSearch: "",
        strDBName: _AuthParem.DB,
        iUserId: Number(_AuthParem.User),
        iBranchId: Number(_AuthParem.Branch),
        CurrtPage: Page,
      };
      const res = await API_POST(
        `Reports/Load_GeneralReports/`,
        parems,
        dispatch
      );
      if (res) {
        let RptDesign = res.EntDesign.sort((a, b) => a.inOrderTo - b.inOrderTo);
        RptDesign = RptDesign.filter((rdt) => rdt.isShowInReport);
        setarrReportDesing(RptDesign);
        let RptData = JsontoArray(res.EntReport);
        setarrReportData(RptData);
        setstrFilterColName(res.strSearchCol);
        setiTotalRows(res.TotalRows);
        setRowPerPage(res.RowPerPage);

        if (res.EntSummary !== undefined) {
          let Summary = JSON.parse(res.EntSummary);
          if (Summary.length > 0) {
            setarrSummarydata(Summary);
          }
        } else {
          setarrSummarydata([]);
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const fnOpenParty = (RptId) => {
    let isOpen = false;
    let strColName = "";
    let ScrId = 0;
    let VenType = 0;
    switch (Number(RptId)) {
      case 96:
      case 97:
        strColName = "SuppName";
        ScrId = 3;
        VenType = 1;
        isOpen = true;
        break;
      case 95:
      case 100:
      case 101:
        strColName = "CustName";
        ScrId = 2;
        VenType = 4;
        isOpen = true;
        break;
      case 102:
        strColName = "VenName";
        ScrId = 50;
        VenType = 5;
        isOpen = true;
        break;
      default:
        break;
    }
    if (isOpen) {
      setstrPartyScrColName(strColName);
      setiScrId(ScrId);
      setiVenTypeId(VenType);
      setstrFilterColName("");
      setiPartyid(0);
      setstrPartyName("");
      setstrcolSearch("");
      setarrReportData([]);
      setarrReportDesing([]);
      setarrReportData([]);
      setarrReportDesing([]);
      setarrParty([]);
      setisPartyShow(true);
      setisPartyoption(true);
      Search_Party("", strColName, ScrId, VenType);
    }
    return isOpen;
  };
  const Branch_Onchange = async (branch) => {
    try {
      await Pre_Loading(dispatch, true);
      setiBranchId(Number(branch.iBranchId));
      setarrReportData([]);
      setiTotalRows(0);
      setRowPerPage(0);
      setarrSummarydata([]);
      setiWHId(0);
      if (iRptId === 49 || iRptId === 50) {
        const parems = {
          iUserId: Number(_AuthParem.User),
          iRoleId: Number(_AuthParem.Role),
          iClientId: Number(_AuthParem.Client),
          iBranchId: Number(branch.iBranchId),
          strDBName: _AuthParem.DB,
        };
        const res = await API_POST(
          `WarehouseMaster/Load_Warehouse/`,
          parems,
          dispatch
        );
        if (res) {
          setarrWH(res.EntWarehouse);
        }
      } else {
        View_Report(iRptId, strReportName, branch.iBranchId, iWHId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_VendoreOutstanding = async (party) => {
    try {
      await Pre_Loading(dispatch, true);
      setisPartyoption(!isPartyoption);
      setisPartyoption(!isPartyoption);
      setiPartyid(Number(party.partyId));
      setstrPartyName(party.partyName);
      const parems = {
        iRptId: Number(iRptId),
        iPartyId: Number(party.partyId),
        strColName: strFilterColName,
        strPartyName: party.partyName,
        strSearch: strSearch,
        strDBName: _AuthParem.DB,
        iUserId: Number(_AuthParem.User),
        iBranchId: Number(_AuthParem.Branch),
      };
      const res = await API_POST(
        `Reports/Load_GeneralReports/`,
        parems,
        dispatch
      );
      if (res) {
        let RptDesign = res.EntDesign.sort((a, b) => a.inOrderTo - b.inOrderTo);
        setarrReportDesing(RptDesign);
        let RptData = JsontoArray(res.EntReport);
        setarrReportData(RptData);
        setstrFilterColName(res.strSearchCol);
        if (RptData.length > 0 && res.EntSummary !== undefined) {
          let Summary = JSON.parse(res.EntSummary);
          if (Summary.length > 0) {
            setarrSummarydata(Summary);
          }
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Party = async (SearchText, ColName, ScrId, VenType) => {
    try {
      await Pre_Loading(dispatch, true);
      setstrSearch(SearchText);
      const parems = {
        iScrId: ScrId !== undefined ? ScrId : iScrId,
        iPartyId: VenType !== undefined ? VenType : iVenTypeId,
        strColName: ColName !== undefined ? ColName : strPartyScrColName,
        strSearch: SearchText,
        strDBName: _AuthParem.DB,
        iBranchId: Number(_AuthParem.Branch),
      };
      const res = await API_POST(`PartyTrans/Search_Party`, parems, dispatch);
      if (res) {
        setarrParty(res.EntParty);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const JsontoArray = (DataArray) => {
    return DataArray !== null ? JSON.parse(DataArray) : [];
  };

  return (
    <main className="main-container">
      <SideMenu ScrId={86} />
      <ReportTable
        ReportData={arrReportData}
        ReportDesing={arrReportDesing}
        EntReports={arrReports}
        View_Report={View_Report}
        Load_Reports={Load_Reports}
        Search_Report={Search_Report}
        View_VendoreOutstanding={View_VendoreOutstanding}
        Search_Party={Search_Party}
        EntSum={arrSummarydata}
        iRptId={iRptId}
        strReportName={strReportName}
        EntParty={arrParty}
        IsPartyOption={isPartyoption}
        IsParty={isPartyShow}
        Party_OptionShow={(e) => setisPartyoption(e)}
        PartyName={strPartyName}
        strcolSearch={strcolSearch}
        FilterColName={strFilterColName}
        setstrFilterColName={(e) => setstrFilterColName(e)}
        IsAccounts={false}
        TotalRows={iTotalRows}
        RowPerPage={RowPerPage}
        View_PageReport={View_PageReport}
        CurrPage={CurrPage}
        arrWH={arrWH}
        arrBranch={arrBranch}
        iBranchId={iBranchId}
        iWHId={iWHId}
        Branch_Onchange={Branch_Onchange}
        setiWHId={(WHId) => {
          setiWHId(WHId);
          View_Report(iRptId, strReportName, iBranchId, WHId);
        }}
        IsWHdisable={IsWHdisable}
      />
    </main>
  );
}

export default GeneralReports;
