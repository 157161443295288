import { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import SideMenu from "../General/SideMenu";

import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
import { useSelector } from "react-redux";
function AttributeGroup() {
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const iScrId = 61;
  const [iAttGrpId, setiAttGrpId] = useState(0);
  const [iAttId, setiAttId] = useState(0);
  const [strAttGroupName, setstrAttGroupName] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [iDimensionId, setiDimensionId] = useState(1);
  const [iAttGrpCount, setiAttGrpCount] = useState(0);
  const [iAttDtId, setiAttDtId] = useState(0);

  const [ListFilter, setListFilter] = useState([]);
  const [ListDimension, setListDimension] = useState([]);
  const [ListAttGrp, setListAttGrp] = useState([]);
  const [ListAttHT, setListAttHT] = useState([]);
  const [ListAttDT, setListAttDT] = useState([]);
  const [ListAttLevel, setListAttLevel] = useState([]);
  const [ListAddAtt, setListAddAtt] = useState([]);
  const [ListAttValue, setListAttValue] = useState([]);
  const [IsLoding, setIsLoding] = useState(true);
  const [strQuestion, setstrQuestion] = useState("");
  const [IsDialogOpen, setIsDialogOpen] = useState(false);
  const [IsDialogFor, setIsDialogFor] = useState("");
  const [strMessage, setstrMessage] = useState("");
  const [strIcon, setstrIcon] = useState("");
  const [IsNotification, setIsNotification] = useState(false);
  useEffect(() => {
    GetDefaultAttributGrp();
  }, []);
  const GetDefaultAttributGrp = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const res = await API_FETCH(
      "AttributeGroup/GetDefaultData/" + _LocalParems.DB
    );
    if (res) {
      setListFilter(res.objFilter);
      setListDimension(res.objDimension);
      setListAttGrp(res.objAttGroup);
      setiAttGrpCount(res.objAttGroup.length);
      setListAttLevel(res.objAttLevel);
      setiDimensionId(res.objDimension[0].iAttDimId);
      GetAttribute(iDimensionId);
      GetAttValue(iAttId);
      setIsLoding(false);
    }
  };
  const SaveClick = async () => {
    var FormMode = Number(iAttGrpId) > 0 ? "Update" : "New";
    const InsAttGrp = {
      strAttGroupName: strAttGroupName,
      strDescription: strDescription,
      strFormMode: FormMode,
      iAttDimId: Number(iDimensionId),
      strRemark: strRemarks,
      iTitleTag: iScrId,
      iAttGrpId: Number(iAttGrpId),
      strAttDimName: "",
      strFilter: "",
      strFilterColumn: "",
      strSearch: "",
    };
    const res = await API_POST("AttributeGroup/InsUpdAttGrp/", {
      objEntAGM: InsAttGrp,
      _objEntAGDT: ListAddAtt,
      objGrpLevelDt: ListAttValue,
      strDBName: _AuthParems.DB,
    });
    if (res) {
      ClearClick();
      MsgBox("Success", res.strMessage, "success");
    }
  };
  const DeleteClick = async () => {
    const res = await API_POST(
      "AttributeGroup/Delete/" + iScrId + "/" + iAttGrpId + "/" + _AuthParems.DB
    );
    if (res) {
      ClearClick();
      MsgBox("Success", res.strMessage, "success");
    }
  };
  const ClearClick = () => {
    setiAttGrpId(0);
    setiAttId(0);
    setstrAttGroupName("");
    setstrDescription("");
    setstrRemarks("");
    setiDimensionId(iDimensionId);
    setiAttGrpCount(0);
    setiAttDtId(0);
    setListAttGrp([]);
    setListAttHT([]);
    setListAttDT([]);
    setListAttLevel([]);
    setListAddAtt([]);
    setListAttValue([]);
    GetDefaultAttributGrp();
  };
  const DeleteIcon = async (AttGrpId) => {
    const res = await API_POST(
      "AttributeGroup/Delete/" + iScrId + "/" + AttGrpId + "/" + _AuthParems.DB
    );
    if (res) {
      ClearClick();
      MsgBox("Success", res.strMessage, "success");
    }
  };
  const GetAttributGroup = async (AttGrpId) => {
    const res = await API_FETCH(
      "AttributeGroup/LoadAttGroup/" +
        AttGrpId +
        "/" +
        iScrId +
        "/" +
        _AuthParems.DB
    );
    if (res) {
      let AttGrpModify = ListAttGrp.filter((D) => D.iAttGrpId === AttGrpId);
      if (res.objAttribute.length > 0) {
        GetAttribute(AttGrpModify[0].iAttDimId);
        setstrAttGroupName(AttGrpModify[0].strAttGroupName);
        setstrDescription(AttGrpModify[0].strDescription);
        setstrRemarks(AttGrpModify[0].strRemark);
        setiAttGrpId(AttGrpModify[0].iAttGrpId);
        setiDimensionId(AttGrpModify[0].iAttDimId);
        setiAttId(res.objAttribute[0].attId);
        setListAddAtt(res.objAttribute);
        if (res.objAttributeValue.length > 0) {
          setListAttValue(res.objAttributeValue);
        }
      }
    }
  };
  const GetAttribute = async (iDimId) => {
    setIsLoding(true);
    setiDimensionId(Number(iDimId));
    setListAttHT([]);
    setListAddAtt([]);
    setListAttValue([]);
    const res = await API_FETCH(
      "AttributeGroup/GetAttribute/" +
        Number(iDimId) +
        "/" +
        Number(_AuthParems.Role) +
        "/" +
        _AuthParems.DB
    );
    if (res) {
      if (res.length > 0) {
        setListAttHT(res);
        setiAttId(Number(res[0].iId));
        if (Number(iDimensionId) === 2) {
          GetAttValue(res[0].iId);
        }
      }
    }
  };
  const GetAttValue = async (iAttId) => {
    setiAttId(Number(iAttId));
    setListAttDT([]);
    setIsLoding(true);
    const res = await API_FETCH(
      "AttributeGroup/GetAttValue/" +
        Number(iAttId) +
        "/" +
        Number(_AuthParems.Role) +
        "/" +
        _AuthParems.DB
    );
    if (res) {
      if (res.length > 0) {
        setListAttDT(res);
        setiAttDtId(res[0].iId);
      }
    }
  };
  const AddTable = () => {
    let ListFilterDt = ListAddAtt.filter((D) => D.attId === Number(iAttId));
    if (ListFilterDt.length > 0 && Number(iDimensionId) !== 2) {
      MsgBox("Validation", "Attribute already Exist", "info");
    } else if (Number(iDimensionId === 2 && ListFilterDt.length > 0)) {
      AddDt();
    } else {
      let ListFilterAtt = ListAttHT.filter((D) => Number(D.iId) === iAttId);
      ListFilterAtt.map((Data) => {
        for (var i = 0; i <= ListAddAtt.length; i++) {
          const AddItem = {
            sNo: i + 1,
            attLevelType: ListAttLevel[i].attLevelType,
            attLevelId: ListAttLevel[i].attLevelId,
            attCode: Data.strCode,
            attName: Data.strName,
            attId: Number(Data.iId),
            attGroupDTId: 0,
            attGroupId: 0,
            rowAccess: 1,
          };
          if (ListAddAtt.length === 0) {
            setListAddAtt([AddItem]);
          } else {
            setListAddAtt([...ListAddAtt, AddItem]);
          }
          if (Number(iDimensionId === 2)) {
            AddDt();
          }
        }
        return Data;
      });
    }
  };
  const AddDt = () => {
    let ListFilterDt = ListAttValue.filter(
      (D) => D.attDTId === Number(iAttDtId)
    );
    if (ListFilterDt.length > 0) {
      MsgBox("Validation", "Attribute Value Exist", "info");
    } else {
      let ListFilterDt = ListAttDT.filter(
        (D) => Number(D.iId) === Number(iAttDtId)
      );
      ListFilterDt.map((Data) => {
        for (var i = 0; i <= ListAttValue.length; i++) {
          const AddItem = {
            sNo: i + 1,
            attLevelType: ListAttLevel[i].attLevelType,
            attLevelId: ListAttLevel[i].attLevelId,
            attValue: Data.strName,
            attId: Number(iAttId),
            attDTId: Number(Data.iId),
            attGroupId: Number(iAttGrpId),
          };
          if (ListAttValue.length === 0) {
            setListAttValue([AddItem]);
          } else {
            setListAttValue([...ListAttValue, AddItem]);
          }
        }
        return Data;
      });
    }
  };
  const RemoveAttRow = (rowinfo) => {
    const newList = ListAttValue.filter(
      (item) => item.attDTId !== rowinfo.attDTId
    );
    newList.map((Data, Index) => {
      Data.sNo = Index + 1;
      return Data;
    });
    setListAttValue(newList);
  };
  const RemoveRow = (rowinfo) => {
    const newList = ListAddAtt.filter((item) => item.attId !== rowinfo.attId);
    newList.map((Data, Index) => {
      Data.sNo = Index + 1;
      return Data;
    });
    setListAddAtt(newList);
    if (Number(iDimensionId === 2)) {
      const newList = ListAttValue.filter(
        (item) => item.attId !== rowinfo.attId
      );
      newList.map((Data, Index) => {
        Data.sNo = Index + 1;
        return Data;
      });
      setListAttValue(newList);
    }
  };
  const Search = async (Responce) => {
    if (Responce.strSearch === "") {
      Responce.strSearch = "null";
    }
    const res = await API_FETCH(
      "AttributeGroup/Search/" +
        Responce.strFilterColumn +
        "/" +
        Responce.strSearch +
        "/" +
        "null" +
        "/" +
        _AuthParems.DB
    );
    if (res) {
      setListAttGrp(res);
    }
  };
  const Filter = async (Responce) => {
    if (Responce.strFilter === "") {
      Responce.strFilter = "null";
    }
    const res = await API_FETCH(
      "AttributeGroup/Search/" +
        Responce.strFilterColumn +
        "/" +
        "null" +
        "/" +
        Responce.strFilter +
        "/" +
        _AuthParems.DB
    );
    if (res) {
      setListAttGrp(res);
    }
  };
  const AttHt = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "Level",
      id: "attLevelId",
      accessor: "attLevelType",
    },
    {
      Header: "AttCode",
      accessor: "attCode",
    },
    {
      Header: "Attribute",
      accessor: "attName",
    },
    {
      Header: "Action",
      id: "attId",
      maxWidth: 100,
      accessor: (rowinfo) => (
        <div>
          <i
            onClick={() => RemoveRow(rowinfo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  const AttDt = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "Level",
      accessor: "attLevelType",
    },
    {
      Header: "Attribute",
      accessor: "attValue",
    },
    {
      Header: "Action",
      id: "attDTId",
      maxWidth: 100,
      accessor: (rowinfo) => (
        <div>
          <i
            onClick={() => RemoveAttRow(rowinfo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  const MsgBox = (Header, Message, Icon) => {
    setIsNotification(!IsNotification);
    setstrMessage(Message);
    setstrIcon(Icon);
  };
  return (
    <div>
      <SideMenu ScrId={iScrId} />
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-sm-12 ">
              <div className="fg-card  mb-3">
                <div className="container p-2" id="ClearInput">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>
                        Name <span className="danger">*</span>
                      </label>
                      <input
                        type="text"
                        aria-label="First name"
                        placeholder="Enter Attribute Group Name"
                        className="form-control"
                        value={strAttGroupName}
                        onChange={(e) => setstrAttGroupName(e.target.value)}
                        autoFocus
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>
                        Dimension<span className="danger">*</span>
                      </label>
                      <br />
                      <div className="btn-group">
                        <select
                          className="select"
                          value={iDimensionId}
                          onChange={(e) => GetAttribute(e.target.value)}
                        >
                          {ListDimension.map((Dimension, Index) => (
                            <option key={Index} value={Dimension.iAttDimId}>
                              {Dimension.strAttDimName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Remark</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        placeholder="Enter Remarks"
                        value={strRemarks}
                        onChange={(e) => setstrRemarks(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <label>Description</label>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        placeholder="Enter Description"
                        value={strDescription}
                        onChange={(e) => setstrDescription(e.target.value)}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-2">
                      <label>
                        Attribute<span className="danger">*</span>
                      </label>
                      <br />
                      <div className="btn-group">
                        <select
                          className="select"
                          value={iAttId}
                          onChange={(e) => GetAttValue(e.target.value)}
                        >
                          {ListAttHT.map((Attribute, Index) => (
                            <option key={Index} value={Attribute.iId}>
                              {Attribute.strName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {Number(iDimensionId) === 2 && (
                      <div className="col-md-6 col-sm-12 mb-2" id="AttValueCmb">
                        <label>
                          Attribute Value<span className="danger">*</span>
                        </label>
                        <br />
                        <div className="btn-group">
                          <select
                            className="select"
                            value={iAttDtId}
                            onChange={(e) => setiAttDtId(e.target.value)}
                          >
                            {ListAttDT.map((AttValue, Index) => (
                              <option key={Index} value={AttValue.iId}>
                                {AttValue.strName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="col-md-1 col-sm-12 mb-2">
                      <br />
                      <button
                        type="button"
                        onClick={AddTable}
                        className="btn-fabgreen mt-3"
                      >
                        Add
                      </button>
                    </div>
                    <div className="col-md-12 col-sm-12  mt-3 ">
                      <ReactTable
                        columns={AttHt}
                        data={ListAddAtt}
                        minRows={5}
                        row_click={(ProInfo, Index) => {}}
                        row_doubleclick={(ProInfo, Index) => {}}
                        background={true}
                        className="full-table"
                      />
                    </div>
                    {Number(iDimensionId) === 2 && (
                      <div className="col-md-12 col-sm-12  mt-3">
                        <ReactTable
                          columns={AttDt}
                          data={ListAttValue}
                          minRows={5}
                          row_click={(ProInfo, Index) => {}}
                          row_doubleclick={(ProInfo, Index) => {}}
                          background={true}
                          className="full-table"
                        />
                      </div>
                    )}
                    <Button
                      Save={(e) => {
                        if (strAttGroupName === "") {
                          MsgBox("Validation", "Please Enter Name", "info");
                        } else if (Number(ListAddAtt.length) === 0) {
                          MsgBox(
                            "Validation",
                            "Atleast One Attribute Required",
                            "info"
                          );
                        } else {
                          if (iAttGrpId > 0) {
                            setIsDialogFor("Save");
                            setstrQuestion("Do You Want to Update ?");
                            setIsDialogOpen(!IsDialogOpen);
                          } else {
                            setIsDialogFor("Save");
                            setstrQuestion("Do You Want to Save ?");
                            setIsDialogOpen(!IsDialogOpen);
                          }
                        }
                      }}
                      Clear={ClearClick}
                      Delete={(e) => {
                        if (iAttGrpId === 0) {
                          MsgBox(
                            "Validation",
                            "Please Select Attribute Group",
                            "info"
                          );
                        } else {
                          setIsDialogFor("Delete");
                          setstrQuestion("Do You Want to Delete ?");
                          setIsDialogOpen(!IsDialogOpen);
                        }
                      }}
                      ScrId={iScrId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <MasterList
              ComBoxValue={ListFilter}
              EntList={ListAttGrp}
              GetDetails={GetAttributGroup}
              Delete={DeleteIcon}
              Count={iAttGrpCount}
              Filter={Filter}
              Search={Search}
              ScreenID={iScrId}
              accessor="strAttGroupName"
              accessorid="iAttGrpId"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default AttributeGroup;
