import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST } from "../General/Utility";
import ReactTable from "../General/ReactTable";

function NewTemplate(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _parem = useSelector((state) => state.SelectOption.parems);

  const [iPrinterTypeId, setiPrinterTypeId] = useState(1);
  const [iScrId, setiScrId] = useState(17);
  const [iLineFirstId, setiLineFirstId] = useState(0);
  const [iLineAlterId, setiLineAlterId] = useState(0);
  const [iLineMiddleId, setiLineMiddleId] = useState(0);
  const [iLineLastId, setiLineLastId] = useState(0);
  const [iPrintTempId, setiPrintTempId] = useState(0);

  const [iFilterTemplate, setiFilterTemplate] = useState(0);
  const [selected, setselected] = useState(0);
  const [strSearch, setstrSearch] = useState("");
  const [strTempCode, setstrTempCode] = useState("");
  const [bIsDefaut, setbIsDefaut] = useState(false);
  const [strTempName, setstrTempName] = useState("");

  const [ListTemplate, setListTemplate] = useState([]);
  const [ListFilterTemp, setListFilterTemp] = useState([]);
  const [ListPrinterType, setListPrinterType] = useState([]);
  const [ListScreen, setListScreen] = useState([]);
  const [ListLineFirst, setListLineFirst] = useState([]);
  const [ListLineAlter, setListLineAlter] = useState([]);
  const [ListLineMiddle, setListLineMiddle] = useState([]);
  const [ListLineLast, setListLineLast] = useState([]);

  useEffect(() => {
    Load_PrintTemplate();
  }, []);
  const Load_PrintTemplate = async () => {
    try {
      Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        "PrintDesing/Load_PrintTemplate/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        setListTemplate(res.EntTemplate);
        setListPrinterType(res.EntPrinter);
        setListScreen(res.EntScreen);
        setListFilterTemp(res.EntFilter);
        setListLineFirst(res.EntLineFirst);
        setListLineAlter(res.EntLineAlter);
        setListLineMiddle(res.EntLineMiddle);
        setListLineLast(res.EntLineLast);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const InsUpd_PrintTemplate = async () => {
    Pre_Loading(dispatch, true);
    const SaveTemp = {
      iPrintTypeId: Number(iPrinterTypeId),
      strPrintTempCode: strTempCode,
      strPrintTempName: strTempName,
      iLineTempIdFirst: Number(iLineFirstId),
      iLineTempIdFirstAlter: Number(iLineAlterId),
      iLineTempIdMiddle: Number(iLineMiddleId),
      iLineTempIdLast: Number(iLineLastId),
      bSetDefault: bIsDefaut,
      iScrId: Number(iScrId),
      iPrintTempId: Number(iPrintTempId),
      strDBName: _AuthParems.DB,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(
      "PrintDesing/InsUpd_PrintTemplate/",
      SaveTemp,
      dispatch
    );
    if (res) {
      Clear_Template();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const View_PrintTemplate = async (TempInfo) => {
    try {
      Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        "PrintDesing/View_PrintTemplate/" +
          Number(TempInfo.iPrintTempId) +
          "/" +
          Number(_AuthParems.Branch) +
          "/" +
          _AuthParems.DB,
        dispatch
      );
      if (res) {
        let EntPrintTemplate = res.EntPrintTemplate[0];
        setiPrinterTypeId(EntPrintTemplate.iPrintTypeId);
        setiScrId(EntPrintTemplate.iScrId);
        setiPrintTempId(EntPrintTemplate.iPrintTempId);
        setstrTempCode(EntPrintTemplate.strPrintTempCode);
        setstrTempName(EntPrintTemplate.strPrintTempName);
        setiLineFirstId(EntPrintTemplate.iLineTempIdFirst);
        setiLineAlterId(EntPrintTemplate.iLineTempIdFirstAlter);
        setiLineMiddleId(EntPrintTemplate.iLineTempIdMiddle);
        setiLineLastId(EntPrintTemplate.iLineTempIdLast);
        setbIsDefaut(EntPrintTemplate.bSetDefault);
      }
    } catch (err) {
      Pre_Loading(dispatch, false);
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Clear_Template = () => {
    setiPrinterTypeId(0);
    setiScrId(0);
    setiPrintTempId(0);
    setiFilterTemplate(0);
    setstrTempCode("");
    setstrTempName("");
    setiLineFirstId(0);
    setiLineAlterId(0);
    setiLineMiddleId(0);
    setiLineLastId(0);
    setbIsDefaut(false);
    Load_PrintTemplate();
  };
  const Delete_PrintTemplate = async (iPrintTempId) => {
    Pre_Loading(dispatch, true);
    const res = await API_POST(
      "PrintDesing/Delete_PrintTemplate/" +
        Number(iPrintTempId) +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      Show_Message(dispatch, res.strMessage, "success");
      Clear_Template();
    }
  };
  const Search_Template = async (val, valFor) => {
    const objSearch = {
      strSearch: strSearch,
      itemId: iFilterTemplate,
      strDBName: _AuthParems.DB,
      iBranchId: Number(_AuthParems.Branch),
    };
    switch (valFor) {
      case "Text":
        setstrSearch(val);
        objSearch.strSearch = val;
        break;
      case "Filter":
        setiFilterTemplate(val);
        objSearch.itemId = Number(val);
        break;
      default:
        break;
    }
    const res = await API_POST(
      "PrintDesing/Search_PrintTemplate/",
      objSearch,
      dispatch
    );
    if (res) {
      setListTemplate(res.EntTemplate);
    } else Show_Message(dispatch, res.strMessage, "error");
  };
  const KeyDown = (e) => {
    var RowCount = ListTemplate.length - 1;
    if (e.key === "ArrowDown") {
      if (RowCount === selected) setselected(0);
      else setselected(selected + 1);
    } else if (e.key === "ArrowUp") {
      if (selected === 0) setselected(RowCount);
      else setselected(selected - 1);
    } else if (e.key === "Enter") {
      View_PrintTemplate(ListTemplate[selected]);
    }
  };
  const TemplateColum = [
    {
      accessor: "strPrintTempName",
    },
    {
      id: "DeleteId",
      maxWidth: 50,
      accessor: (RowInfo) => (
        <div>
          <i
            onClick={() =>
              Ask_Question(
                dispatch,
                Delete_PrintTemplate,
                "Do You Want to  Delete Template?",
                RowInfo.iPrintTempId
              )
            }
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Print Template </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row p-2">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search Template"
                value={strSearch}
                onChange={(e) => Search_Template(e.target.value, "Text")}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Search_Template(strSearch, "Text")}
              ></i>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12 mt-2">
                <CustomizedSelectoption
                  optionparem="isoptionShow9"
                  showoption={_parem.isoptionShow9}
                  placeholder="Select Screen"
                  select_value={(val) => Search_Template(Number(val), "Filter")}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iFilterTemplate}
                  displayName="strScrName"
                  disvalue="iScrId"
                  arrydata={ListFilterTemp}
                  EmptVal="Screen Name"
                />
                <ReactTable
                  columns={TemplateColum}
                  data={ListTemplate}
                  minRows={5}
                  row_click={(Template, Index) => {}}
                  row_doubleclick={(Template, Index) => {
                    View_PrintTemplate(Template);
                  }}
                  background={true}
                  className="full-table"
                />
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>Printer Type</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow10"
                      showoption={_parem.isoptionShow10}
                      placeholder="Select Printer Type"
                      select_value={(val) => setiPrinterTypeId(Number(val))}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iPrinterTypeId}
                      displayName="strPrinterTypeName"
                      disvalue="iPrinterTypeId"
                      arrydata={ListPrinterType}
                      EmptVal="Printer Type"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>Screen Name</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow11"
                      showoption={_parem.isoptionShow11}
                      placeholder="Select Screen"
                      select_value={(val) => setiScrId(Number(val))}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iScrId}
                      displayName="strScrName"
                      disvalue="iScrId"
                      arrydata={ListScreen}
                      EmptVal="Screen Name"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>First Line Template</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow12"
                      showoption={_parem.isoptionShow12}
                      placeholder="Select Template"
                      select_value={(val) => setiLineFirstId(Number(val))}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iLineFirstId}
                      displayName="strLineTempName"
                      disvalue="iLineTempId"
                      arrydata={ListLineFirst}
                      EmptVal="Line Template"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>Alter Line Template</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow13"
                      showoption={_parem.isoptionShow13}
                      placeholder="Select Template"
                      select_value={(val) => setiLineAlterId(Number(val))}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iLineAlterId}
                      displayName="strLineTempName"
                      disvalue="iLineTempId"
                      arrydata={ListLineAlter}
                      EmptVal="Line Template"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>Middle Line Template</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow14"
                      showoption={_parem.isoptionShow14}
                      placeholder="Select Template"
                      select_value={(val) => setiLineMiddleId(Number(val))}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iLineMiddleId}
                      displayName="strLineTempName"
                      disvalue="iLineTempId"
                      arrydata={ListLineMiddle}
                      EmptVal="Line Template"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>Laste Line Template</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow15"
                      showoption={_parem.isoptionShow15}
                      placeholder="Select Template"
                      select_value={(val) => setiLineLastId(Number(val))}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iLineLastId}
                      displayName="strLineTempName"
                      disvalue="iLineTempId"
                      arrydata={ListLineLast}
                      EmptVal="Line Template"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>Template Name</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Enter Template Name"
                      value={strTempName}
                      onChange={(e) => setstrTempName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>Template Code</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Enter Template Name"
                      value={strTempCode}
                      onChange={(e) => setstrTempCode(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <label>Defaut Template</label>
                    <br />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={bIsDefaut}
                        onChange={(e) => setbIsDefaut(!bIsDefaut)}
                        id="flexCheckDefault"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={InsUpd_PrintTemplate}>
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={Clear_Template}>
              Clear &nbsp; <i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTemplate;
