import React, { useEffect, useState } from "react";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import $ from "jquery";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import { Country_OnChange } from "./MasterTransaction";
function BrandLocationManufacturermaster(props) {
  const dispatch = useDispatch();
  const _select = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [iId, setiId] = useState(0);
  const [ScrName, setScrName] = useState("");
  const [strSearch, setstrSearch] = useState("");

  const [strBrandName, setstrBrandName] = useState("");
  const [strBrandCode, setstrBrandCode] = useState("");
  const [strbrandRemark, setstrbrandRemark] = useState("");

  const [strManufacturerName, setstrManufacturerName] = useState("");
  const [strManufacturerCode, setstrManufacturerCode] = useState("");
  const [strAdd1, setstrAdd1] = useState("");
  const [strAdd2, setstrAdd2] = useState("");
  const [strAdd3, setstrAdd3] = useState("");
  const [strAdd4, setstrAdd4] = useState("");
  const [Pincode, setPincode] = useState("");
  const [State, setState] = useState(32);
  const [Country, setCountry] = useState(100);
  const [GST, setGST] = useState("");
  const [strLicense, setstrLicense] = useState("");
  const [iStatus, setiStatus] = useState(1);
  const [strMaufRemark, setstrMaufRemark] = useState("");

  const [strLocation, setstrLocation] = useState("");
  const [strLocationcode, setstrLocationcode] = useState("");
  const [strlocRemark, setstrlocRemark] = useState("");
  const [strlocationtype, setstrlocationtype] = useState("");
  const [ListLocationtype, setListLocationtype] = useState([]);

  const [arrEntCountry, setarrEntCountry] = useState([]);
  const [arrEntState, setarrEntState] = useState([]);
  const [arrEntData, setarrEntData] = useState([]);
  const [arrEntStatus, setarrEntStatus] = useState([]);
  const [strColName, sestrColName] = useState("strbrandname");

  useEffect(() => {
    Load_BMftloc();
  }, []);

  const Load_BMftloc = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `ProductMaster/Load_BranMftloc/${props.screenttype}/${_LocalParems.DB}`,
        dispatch
      );
      if (res) {
        setarrEntStatus(res.EntStatus);
        switch (props.screenttype) {
          case 2:
            setarrEntData(res.Entmanufacturer);
            setarrEntState(res.EntState);
            setarrEntCountry(res.EntCountry);
            setScrName("Manufacturer");
            sestrColName("strMftrName");
            break;
          case 3:
            setScrName("Location");
            setarrEntData(res.Entlocation);
            setListLocationtype(res.Entlocationtype);
            sestrColName("strlocationname");
            break;
          default:
            setScrName("Brand");
            setarrEntData(res.Entbrand);
            sestrColName("strbrandname");
            break;
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  //Click Event
  const SaveBrandClick = async () => {
    try {
      const InsUpdBrand = {
        ibrandid: iId,
        strbrandname: strBrandName,
        strbrandcode: strBrandCode,
        strremark: strbrandRemark,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "ProductMaster/InsUpd_BrandMaster/",
        InsUpdBrand,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveManufacturer = async () => {
    try {
      const InsUpdManufacturer = {
        iMftrId: iId,
        strMftrName: strManufacturerName,
        strMftrCode: strManufacturerCode,
        strAdd1: strAdd1,
        strAdd2: strAdd2,
        strAdd3: strAdd3,
        strAdd4: strAdd4,
        strPincode: Pincode,
        iState: State,
        iCountry: Country,
        strGSTNo: GST,
        strLicenseNo: strLicense,
        strRemark: strMaufRemark,
        iStatus: iStatus,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "ProductMaster/InsUpd_Manufacturer/",
        InsUpdManufacturer,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveLocation = async () => {
    try {
      const InsUpdLocation = {
        ilocationid: iId,
        strlocationname: strLocation,
        strlocationcode: strLocationcode,
        strlocationtype: strlocationtype,
        strremarks: strlocRemark,
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        "ProductMaster/InsUpd_Location/",
        InsUpdLocation,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Delete_BMftloc = async () => {
    try {
      const res = await API_POST(
        `ProductMaster/Delete_BMftloc/${props.screenttype}/${iId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const ClearClick = () => {
    setiId(0);

    setstrBrandName("");
    setstrBrandCode("");
    setstrbrandRemark("");

    setstrLocation("");
    setstrLocationcode("");
    setstrlocationtype("");
    setstrlocRemark("");

    setstrManufacturerName("");
    setstrManufacturerCode("");
    setstrAdd1("");
    setstrAdd2("");
    setstrAdd3("");
    setstrAdd4("");
    setPincode("");
    setState(32);
    setCountry(100);
    setGST("");
    setstrLicense("");
    setiStatus(1);
    setstrMaufRemark("");

    setListLocationtype([]);
    setarrEntCountry([]);
    setarrEntState([]);
    setarrEntData([]);
    setarrEntStatus([]);
    Load_BMftloc();
  };
  const View_BML = (dt) => {
    switch (props.screenttype) {
      case 2:
        setiId(dt.imanufacid);
        setstrManufacturerName(dt.strmanufactname);
        setstrManufacturerCode(dt.strmanufactcode);
        setstrAdd1(dt.stradd1);
        setstrAdd2(dt.stradd2);
        setstrAdd3(dt.stradd3);
        setstrLicense(dt.strlicenseno);
        setstrMaufRemark(dt.strremark);
        break;
      case 3:
        setiId(dt.ilocationid);
        setstrLocation(dt.strlocationname);
        setstrLocationcode(dt.strlocationcode);
        setstrlocRemark(dt.strremarks);
        setstrlocationtype(dt.strlocationtype);
        break;
      default:
        setiId(dt.ibrandid);
        setstrBrandName(dt.strbrandname);
        setstrBrandCode(dt.strbrandcode);
        setstrbrandRemark(dt.strremark);
        break;
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{ScrName}</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-sm-12 col-md-3 popup-card">
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder={"Search " + ScrName + " Name"}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                      $(".data-list [tabindex=0]").focus();
                    }
                  }}
                  value={strSearch}
                  onChange={(e) => setstrSearch(e.target.value)}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => props.Search(props.strSearchtxt)}
                ></i>
              </div>
              <div className="data-list">
                {arrEntData.map((dt, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrEntData.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_BML(dt, Index);
                      }
                    }}
                  >
                    <label onClick={(e) => View_BML(dt)}>
                      {dt[strColName]}
                    </label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-sm-12 col-md-9">
              {props.screenttype === 1 && (
                <div className="row">
                  <div className="col-sm-12 col-md-8">
                    <label>Brand Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={strBrandName}
                      placeholder="Enter Brand Name"
                      onChange={(e) => setstrBrandName(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <label>Brand Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Brand Code"
                      value={strBrandCode}
                      onChange={(e) => setstrBrandCode(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <label>Remark</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Remark"
                      value={strbrandRemark}
                      onChange={(e) => setstrbrandRemark(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                </div>
              )}
              {props.screenttype === 2 && (
                <div className="row pb-2">
                  <div className="col-sm-12 col-md-8">
                    <label>Munufacturer Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Manufacturer Name"
                      value={strManufacturerName}
                      onChange={(e) => setstrManufacturerName(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <label>Munufacturer Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={strManufacturerCode}
                      placeholder="Enter Code"
                      onChange={(e) => setstrManufacturerCode(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <label>Door/Office No</label>
                    <input
                      type="text"
                      className="form-control"
                      value={strAdd1}
                      placeholder="Enter Door/Office No Detailes"
                      onChange={(e) => setstrAdd1(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <label>Street/Area</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Street/Area Detailes"
                      value={strAdd2}
                      onChange={(e) => setstrAdd2(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <label>Village/Town</label>
                    <input
                      type="text"
                      placeholder="Enter Village/Town Detailes"
                      className="form-control"
                      value={strAdd3}
                      onChange={(e) => setstrAdd3(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <label>City</label>
                    <input
                      type="text"
                      placeholder="Enter City"
                      className="form-control"
                      value={strAdd4}
                      onChange={(e) => setstrAdd4(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <label>Pincode</label>
                    <input
                      type="number"
                      placeholder="Enter Pincode Detailes"
                      className="form-control"
                      value={Pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <label>
                      Country <span className="danger">*</span>
                    </label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow"
                      showoption={_select.isoptionShow}
                      placeholder="Select Country"
                      select_value={async (val) => {
                        await setCountry(val);
                        await setarrEntState(
                          await Country_OnChange(val, _AuthParems.DB, dispatch)
                        );
                      }}
                      btnname="Country"
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={Country}
                      displayName="strCountryName"
                      disvalue="iCountryId"
                      arrydata={arrEntCountry}
                      disabled={false}
                      EmptVal="Country "
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <label>
                      State <span className="danger">*</span>
                    </label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow1"
                      showoption={_select.isoptionShow1}
                      placeholder="Select State"
                      select_value={(val) => setState(val)}
                      btnname="State"
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={State}
                      displayName="strSName"
                      disvalue="iSId"
                      arrydata={arrEntState}
                      disabled={false}
                      EmptVal="State "
                    />
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <label>Status</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow13"
                      showoption={_select.isoptionShow13}
                      placeholder="Select Status"
                      select_value={(val) => setiStatus(val)}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={iStatus}
                      displayName="strStatusName"
                      disvalue="iStatusId"
                      arrydata={arrEntStatus}
                      EmptVal="Status "
                    />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <label>Licence Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter License No"
                      value={strLicense}
                      onChange={(e) => setstrLicense(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <label>GSTN Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter GSTN No"
                      value={GST}
                      onChange={(e) => setGST(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <label>Remark</label>
                    <input
                      type="text"
                      className="form-control"
                      value={strMaufRemark}
                      placeholder="Enter Remark"
                      onChange={(e) => setstrMaufRemark(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                </div>
              )}
              {props.screenttype === 3 && (
                <div className="row">
                  <div className="col-sm-12 col-md-8">
                    <label>Location Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Product Location"
                      value={strLocation}
                      onChange={(e) => setstrLocation(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <label>Location Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={strLocationcode}
                      onChange={(e) => setstrLocationcode(e.target.value)}
                      onClick={(e) => e.target.select()}
                      placeholder="Enter Location Code"
                    />
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <label>Location type</label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow18"
                      showoption={_select.isoptionShow18}
                      placeholder="Select Location Type"
                      select_value={(val) => setstrlocationtype(val)}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={strlocationtype}
                      displayName="strlocationtype"
                      disvalue="ilocatistrlocationtypeonid"
                      arrydata={ListLocationtype}
                      EmptVal="Location"
                    />
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <label>Remark</label>
                    <textarea
                      className="form-control"
                      value={strlocRemark}
                      onChange={(e) => setstrlocRemark(e.target.value)}
                      onClick={(e) => e.target.select()}
                      placeholder="Enter Remark"
                      style={{ minHeight: "100px" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                switch (props.screenttype) {
                  case 2:
                    Ask_Question(
                      dispatch,
                      SaveManufacturer,
                      "Do You Want to Save Manufacturer?"
                    );
                    break;
                  case 3:
                    Ask_Question(
                      dispatch,
                      SaveLocation,
                      "Do You Want to Save Location?"
                    );
                    break;
                  default:
                    Ask_Question(
                      dispatch,
                      SaveBrandClick,
                      "Do You Want to Save Brand?"
                    );
                    break;
                }
              }}
            >
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (iId === 0)
                  Show_Message(dispatch, "Please Select" + ScrName, "info");
                else
                  Ask_Question(
                    dispatch,
                    Delete_BMftloc,
                    "Do You Want to Delete " + ScrName + "?"
                  );
              }}
            >
              Delete&nbsp; <i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={(e) => ClearClick()}>
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandLocationManufacturermaster;
