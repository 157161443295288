import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddProduct,
  Clear_Product,
  ProductInput_Onchange,
  ProductRate_LostFocus,
  ProductRate_Onchange,
  Select_Unit,
  ShowHide_Popup,
} from "../features/Transactionstates";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST } from "../General/Utility";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import $ from "jquery";
function PosproductInput(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _Product = useSelector((state) => state.Transstates.Product);
  const _Select = useSelector((state) => state.SelectOption.parems);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((state) => state.Transstates.Data);
  const _Disc = useSelector((state) => state.Pointofsales.BillDiscount);

  const Upd_Product = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const parems = {
        bUpdateStatus: _Product.bUpdateStatus,
        iScrId: _Common.iScrId,
        iTaxRelId: _HT.iTaxRelId,
        iPartyId: _HT.iPartyId,
        iVouchId: _HT.iVouchId,
        iTaxCalcOnId: _Common.iTaxCalcOnId,
        iProId: _Product.iProId,
        strJobId: "0",
        iSerId: 0,
        iRowIndex: _Product.iRowIndex,
        iUnitId: Number(_Product.iUnitId),
        dQty: Number(_Product.dQty),
        FQty: Number(_Product.FQty),
        dPriceRate: Number(_Product.dRate),
        dPriceRateWithTax: Number(_Product.dRatewithTax),
        dDiscountPer: _Product.isItemDiscPer ? Number(_Product.dItemDisc) : 0,
        dDiscountAmt: _Product.isItemDiscPer ? 0 : Number(_Product.dItemDisc),
        dCashDiscountPer:
          _Product.isCashDiscPer === false ? Number(_Product.dCashDisc) : 0,
        dCashDiscountAmt:
          _Product.isCashDiscPer === true ? Number(_Product.dCashDisc) : 0,
        BatchNo: _Product.BatchNo,
        ManufactureDate: _Product.MftDate,
        ExpiryDate: _Product.ExpDate,
        isComposPro: Number(_Product.isComposPro),
        isBatchPro: Number(_Product.isBatchPro),
        objBaseTransDT: _DT.arrEntDT,
        objItemRemark: _Product.Remarks,
        objRootCalc: _Product.Calculation,
        objPenCon: _DT.arrEntPenCon,
        objEntAddLess: _DT.arrEntAL,
        objEntConRow: [],
        objEntTax: _DT.arrEntTax,
        strSearch: "",
        strColumn: "",
        Reason: "",
        DimAttDTId: "0",
        iALBillDiscId: _Common.iALBillDiscId,
        strSetCurrentStatus: _Common.strCurrentStatus,
        dAddLessDiscValue: Number(_Disc.dALBillDisValue),
        dTBillDiscPer: Number(_Disc.dBillDisPer),
        dTBillDiscAmt: Number(_Disc.dBillDisAmt),
        dCouponDiscPer: Number(_Disc.dCouponDiscPer),
        dRewardPointper: Number(_Disc.dRewardPointper),
        dCouponDiscAmt: Number(_Disc.dCouponDiscAmt),
        dRewardPointAmt: Number(_Disc.dRewardPointAmt),
        iYearId: Number(_AuthParems.Year),
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
        iUserid: Number(_AuthParems.User),
      };
      const res = await API_POST(
        `ProductTrans/AddUpd_Product/`,
        parems,
        dispatch
      );
      if (res) {
        await dispatch(ShowHide_Popup("IsPosPro"));
        await dispatch(AddProduct(res));
        $(`.product-table tbody tr:eq(${res.EntDT.length - 1}) input:eq(0)`)
          .focus()
          .select();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Unit_Onchange = async (UnitId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `ProductTrans/Unit_Onchange/${Number(UnitId)}/${_Product.iProId}/ ${
          _HT.iPartyId
        }/${_HT.iTaxRelId}/${_Common.strScrType}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        dispatch(Select_Unit({ dPriceRate: res.dPriceRate, UnitId: UnitId }));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Input_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Rate_Onchange = (e) => {
    dispatch(
      ProductRate_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Rate_LostFocus = (e) => {
    dispatch(
      ProductRate_LostFocus({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Disc_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.name,
        Value: e.value,
      })
    );
  };
  const KeyUpDown = async (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    let tabletab = 50;
    let tablerows = 10;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === tabletab && tablerows > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex - 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        if (maxTab - 1 === tabletab && tablerows > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + maxTab + "']").focus();
          $("[tabindex='" + maxTab + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === tabletab && tablerows > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex + 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        $("[tabindex='" + 0 + "']").focus();
        $("[tabindex='" + 0 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === tabletab && tablerows > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex + 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        $("[tabindex='" + 0 + "']").focus();
        $("[tabindex='" + 0 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 0 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === tabletab && tablerows > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex + 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        $("[tabindex='" + 0 + "']").focus();
        $("[tabindex='" + 0 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === tabletab && tablerows > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          ActiveIndex = ActiveIndex - 1;
          $("[tabindex='" + ActiveIndex + "']").focus();
          $("[tabindex='" + ActiveIndex + "']").select();
        }
      } else {
        if (maxTab - 1 === tabletab && tablerows > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + maxTab + "']").focus();
          $("[tabindex='" + maxTab + "']").select();
        }
      }
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Trans Product</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => dispatch(ShowHide_Popup("IsPosPro"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-1">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <label>Product Name</label>
              <div className="custom-select">
                <input
                  type="text"
                  className="form-control"
                  id="txtProName"
                  name="strProName"
                  spellCheck={false}
                  autoComplete="off"
                  placeholder="Press Enter to Select Product "
                  defaultValue={_Product.strProName}
                  tabIndex={_Common.arrtaborder.txtProductName}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Qty</label>
              <input
                type="number"
                className="form-control"
                name="dQty"
                autoComplete="off"
                value={_Product.dQty}
                onClick={(e) => e.target.select()}
                onChange={(e) => {
                  Input_Onchange(e);
                }}
                tabIndex={_Common.arrtaborder.txtQty}
                onKeyDown={(e) => KeyUpDown(e)}
                autoFocus
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Unit</label>
              <CustomizedSelectoption
                optionparem="isoptionShow3"
                showoption={_Select.isoptionShow3}
                placeholder="Select Unit"
                select_value={(val) => Unit_Onchange(val)}
                taborder={_Common.arrtaborder.cbUnit}
                keydown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown");
                  else KeyUpDown(e);
                }}
                btnname="Unit"
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={_Product.iUnitId}
                displayName="strUnitName"
                disvalue="iUnitId"
                arrydata={_Product.Unit}
                disabled={_Product.iProId === 0 ? true : false}
                EmptVal="Unit"
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Rate Without Tax</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                name="dRate"
                autoComplete="off"
                id="txtproRate"
                value={_Product.dRate}
                onClick={(e) => e.target.select()}
                onChange={(e) => Rate_Onchange(e)}
                tabIndex={_Common.arrtaborder.txtRate}
                onKeyDown={(e) => KeyUpDown(e)}
                onBlur={(e) => Rate_LostFocus(e)}
                disabled={_Product.iProId === 0 ? true : false}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Rate With Tax</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                name="dRatewithTax"
                id="dPriceRatewithTax"
                autoComplete="off"
                value={_Product.dRatewithTax}
                onClick={(e) => e.target.select()}
                onChange={(e) => Rate_Onchange(e)}
                tabIndex={_Common.arrtaborder.txtRate}
                onKeyDown={(e) => KeyUpDown(e)}
                onBlur={(e) => Rate_LostFocus(e)}
                disabled={_Product.iProId === 0 ? true : false}
              />
            </div>
            {_Common.TransPer.IsItemDisc && (
              <div className="col-sm-12 col-md-12">
                <label>Discount</label>
                <div className="trancdisc">
                  <input
                    type="number"
                    className="form-control"
                    name="dItemDisc"
                    id="txtproItemDis"
                    value={_Product.dItemDisc}
                    autoComplete="off"
                    onClick={(e) => e.target.select()}
                    onChange={(e) => Input_Onchange(e)}
                    tabIndex={_Common.arrtaborder.txtDiscount}
                    onKeyDown={(e) => KeyUpDown(e)}
                    onBlur={(e) => {
                      e.target.value =
                        e.target.value === "" ? (0).toFixed(2) : e.target.value;
                      Input_Onchange(e);
                    }}
                  />
                  <button
                    className="btn-fabgreen active"
                    onClick={(e) => {
                      $(".trancdisc .btn-fabgreen:eq(0)").toggleClass("active");
                      Disc_Onchange({
                        name: "isItemDiscPer",
                        value: !_Product.isItemDiscPer,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                        $(".trancdisc .btn-fabgreen:eq(0)").toggleClass(
                          "active"
                        );
                        Disc_Onchange({
                          name: "isItemDiscPer",
                          value: !_Product.isItemDiscPer,
                        });
                      } else KeyUpDown(e);
                    }}
                  >
                    {_Product.isItemDiscPer ? "%" : "₹"}
                  </button>
                </div>
              </div>
            )}
            {_Common.TransPer.IsCashDisc && (
              <div className="col-sm-12 col-md-12">
                <label>Cash Discount</label>
                <div className="trancdisc">
                  <input
                    type="text"
                    className="form-control"
                    name="dCashDisc"
                    id="dCashDisc"
                    autoComplete="off"
                    value={_Product.dCashDisc}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => Input_Onchange(e)}
                    tabIndex={_Common.arrtaborder.txtDiscount}
                    onKeyDown={(e) => KeyUpDown(e)}
                    onBlur={(e) => {
                      e.target.value =
                        e.target.value === "" ? (0).toFixed(2) : e.target.value;
                      Input_Onchange(e);
                    }}
                  />
                  <button
                    className="btn-fabgreen active"
                    onClick={(e) => {
                      $(".trancdisc .btn-fabgreen:eq(1)").toggleClass("active");
                      Disc_Onchange({
                        name: "isCashDiscPer",
                        value: !_Product.isCashDiscPer,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                        $(".trancdisc .btn-fabgreen:eq(1)").toggleClass(
                          "active"
                        );
                        Disc_Onchange({
                          name: "isCashDiscPer",
                          value: !_Product.isCashDiscPer,
                        });
                      } else KeyUpDown(e);
                    }}
                  >
                    {_Product.isCashDiscPer ? "%" : "₹"}
                  </button>
                </div>
              </div>
            )}
            {_Common.TransPer.IsFQty && (
              <div className="col-sm-12 col-md-12">
                <label>FQTY</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  name="FQty"
                  id="FQty"
                  autoComplete="off"
                  value={_Product.FQty}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => Input_Onchange(e)}
                  tabIndex={_Common.arrtaborder.txtDiscount}
                  onKeyDown={(e) => KeyUpDown(e)}
                  onBlur={(e) => {
                    e.target.value = e.target.value === "" ? 0 : e.target.value;
                    Input_Onchange(e);
                  }}
                  disabled={_Product.iProId === 0 ? true : false}
                />
              </div>
            )}
            {_Common.TransPer.IsBatchNo && _Product.isBatchPro && (
              <div className="col-sm-12 col-md-12">
                <label>Batch No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Batch No"
                  autoComplete="off"
                  name="BatchNo"
                  value={_Product.BatchNo}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => Input_Onchange(e)}
                  onKeyDown={(e) => KeyUpDown(e)}
                  disabled={_Product.iProId === 0 ? true : false}
                />
              </div>
            )}
            {_Common.TransPer.IsMftDate && _Product.isBatchPro && (
              <div className="col-sm-12 col-md-12">
                <label>Manufacture Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="MftDate"
                  value={_Product.MftDate}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => Input_Onchange(e)}
                  disabled={_Product.iProId === 0 ? true : false}
                  onKeyDown={(e) => KeyUpDown(e)}
                />
              </div>
            )}
            {_Common.TransPer.IsExpDate && _Product.isBatchPro && (
              <div className="col-sm-12 col-md-12">
                <label>Expiry Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="ExpDate"
                  value={_Product.ExpDate}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => Input_Onchange(e)}
                  disabled={_Product.iProId === 0 ? true : false}
                  onKeyDown={(e) => KeyUpDown(e)}
                />
              </div>
            )}
            <div className="col-sm-4 col-md-2"></div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {_Product.strProName.length > 0 && (
              <button
                className="btn-fabgreen"
                onClick={() => Upd_Product()}
                tabIndex={_Common.arrtaborder.btnAdd}
                onKeyDown={(e) => {
                  if (e.key === "Enter") Upd_Product();
                  else KeyUpDown(e);
                }}
              >
                Add&nbsp;<i className="bx bx-layer-plus"></i>
              </button>
            )}
            {_Product.strProName.length > 0 && (
              <button
                className="btn-fabgreen"
                onClick={() => dispatch(Clear_Product())}
              >
                Clear&nbsp;<i className="fas fa-eraser"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default PosproductInput;
