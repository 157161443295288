import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ask_Question } from "../General/Messagedialogbox";
import { Backup_Data } from "../General/Utility";

function AutoBackup(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((state) => state.Common.Admin);
  const Backup = async () => {
    let Backup = await Backup_Data(dispatch, _Common.Client, _Common.OrgName);
    if (Backup) Backup.click();
    await props.Close();
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Immediate Backup Required !</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body Autobackup">
          <p>
            Dear <strong>{_Common.OrgName}</strong>,
          </p>
          <p>
            It has come to our attention that data has not been backed up for
            the past two days. please perform a backup immediately.
          </p>
          <p>
            Don't Close The Application or Don't Reresh The Page When Backup is
            Progress.
          </p>
          <p>
            <strong>Secure the Backup:</strong> Verify that the backup was
            successful., ensure it is stored in a secure location, such as an
            encrypted
            <strong> External drive</strong> or <strong>Google Drive </strong>
            with robust security measures.
          </p>
          <p>
            Regular backups are essential to prevent data loss and maintain the
            integrity of your information.
          </p>
          <p className="aub-battom">
            Best regards, <br />
            <strong>Fabgreen Technologies</strong> <br />
            Vadavalli,Coimbatore
          </p>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                Ask_Question(dispatch, Backup, `Do You Want to Backup Data?`)
              }
            >
              Backup Data&nbsp;<i className="bx bx-server"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutoBackup;
