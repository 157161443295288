import React, { useState, useEffect } from "react";
import Messagedialogbox, {
  Ask_Question,
  Show_Message,
} from "../General/Messagedialogbox";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Buffer } from "buffer";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import {
  API_FETCH,
  API_POST,
  DecryptData,
  EncryptData,
} from "../General/Utility";
import { Update_AdminCntrl } from "../features/CommonStates";
function CompanyMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((state) => state.Common.Admin);

  const [iCompId, setiCompId] = useState(0);

  const [iActiveCompId, setiActiveCompId] = useState(0);
  const [iActiveFyId, setiActiveFyId] = useState(0);
  const [iActiveBranchId, setiActiveBranchId] = useState(0);

  const [iBranchId, setiBranchId] = useState(0);
  const [iYearId, setiYearId] = useState(0);
  const [DBName, setDBName] = useState("");
  const [YearName, setYearName] = useState("");
  const [CompName, setCompName] = useState("");
  const [isMoreinfo, setisMoreinfo] = useState(false);

  const [isCreate, setisCreate] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const [isModify, setisModify] = useState(false);
  const [isView, setisView] = useState(false);

  const [IsMaster, setIsMaster] = useState(true);
  const [IsCompany, setIsCompany] = useState(false);
  const [IsBranch, setIsBranch] = useState(false);

  const [Code, setCode] = useState("");
  const [Name, setName] = useState("");
  const [AddNo, setAddNo] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Street, setStreet] = useState("");
  const [Area, setArea] = useState("");
  const [City, setCity] = useState("");
  const [Country, setCountry] = useState(100);
  const [State, setState] = useState(32);
  const [MobileNo, setMobileNo] = useState("");
  const [GSTNo, setGSTNo] = useState("");
  const [Email, setEmail] = useState("");
  const [Remark, setRemark] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [TinNo, setTinNo] = useState("");
  const [LicenceNo, setLicenceNo] = useState("");
  const [FinanFrom, setFinanFrom] = useState("");
  const [FinanTo, setFinanTo] = useState("");
  const [FromTo, setFromTo] = useState("");

  const [strImage, setstrImage] = useState("");
  const [ImgExd, setImgExd] = useState("");
  const [ImgName, setImgName] = useState("");
  const [EntImgByte, setEntImgByte] = useState([]);

  const [Branchtype, setBranchtype] = useState("SUB");
  const [strPassword, setstrPassword] = useState("");
  const [SmtpPort, setSmtpPort] = useState("0");
  const [SmtpHost, setSmtpHost] = useState("");
  const [StockType, setStockType] = useState(2);
  const [isPortHostReadOnly, setisPortHostReadOnly] = useState(false);
  const [isEmailChanged, setisEmailChanged] = useState(false);

  const [arrEntCompany, setarrEntCompany] = useState([]);
  const [arrEntfyyear, setarrEntfyyear] = useState([]);
  const [arrEntBranch, setarrEntBranch] = useState([]);
  const [arrstockType, setarrstockType] = useState([]);
  const [arrCountry, setarrCountry] = useState([]);
  const [arrState, setarrState] = useState([]);

  useEffect(() => {
    Load_Company();
    tab_click(0);
  }, []);
  const tab_click = async (tabindex) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.common-tab label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    await $(`.common-tab label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    if (tabindex === 0) {
      await setIsMaster(true);
      await setIsCompany(false);
      await setIsBranch(false);
    } else {
      await setIsCompany(true);
      await setIsMaster(false);
      await setIsBranch(false);
    }
  };
  const Company_Click = (company, Index) => {
    let isactive = $(`.dt-button:eq(${Index})`).hasClass("active");
    if (isactive) {
      setiCompId(0);
      setarrEntfyyear([]);
      setarrEntBranch([]);
    } else Load_Financialyear(company);
    $(`.dt-button:eq(${Index})`)
      .toggleClass("active")
      .siblings()
      .removeClass("active");
  };
  const Year_Click = (year, Index) => {
    let isactive = $(`.fy-button:eq(${Index})`).hasClass("active");
    if (isactive) {
      setiYearId(0);
      setDBName("");
      setYearName("");
      setiBranchId(0);
      setarrEntBranch([]);
    } else {
      setarrEntBranch([]);
      Load_Branch(year);
    }
    $(`.fy-button:eq(${Index})`)
      .toggleClass("active")
      .siblings()
      .removeClass("active");
  };
  const Load_Company = async () => {
    try {
      const AuthParems = await DecryptData(localStorage.jbctrlparams);
      await dispatch(Update_AdminCntrl(AuthParems));
      let Company = AuthParems.CompanyPer;
      Company.forEach((per) => {
        if (per.iCompId === AuthParems.Company) {
          setisCreate(per.isCreate);
          setisDelete(per.isDelete);
          setisModify(per.isModify);
          setisView(per.isView);
        }
      });
      var currentDate = new Date().toISOString().split("T")[0].split("-");
      var Year = parseFloat(currentDate[0]);
      var Month = parseFloat(currentDate[1]);
      if (Month >= 1 && Month <= 3) {
        Year = Year - 1;
      }
      var FROM = Year + "-04-01";
      var TO = Year + 1 + "-03-31";
      var FROMTO = Year + "-" + (Year + 1);
      await setFinanFrom(FROM);
      await setFinanTo(TO);
      await setFromTo(FROMTO);
      const res = await API_FETCH(
        `CompanyMaster/Load_Company${Number(AuthParems.User)}`,
        dispatch
      );
      if (res) {
        setarrEntCompany(res.EntCompany);
        setarrstockType(res.EntStockType);
        setarrCountry(res.EntCountry);
        setarrState(res.EntState);
        setiActiveCompId(Number(AuthParems.Company));
        setiActiveFyId(Number(AuthParems.Year));
        setiActiveBranchId(Number(AuthParems.Branch));
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Load_Financialyear = async (comp) => {
    try {
      await setiCompId(comp.iCompId);
      await setCompName(comp.strCompanyName);
      const res = await API_FETCH(
        `CompanyMaster/Load_FinancialYear/${Number(comp.iCompId)}/${Number(
          _Common.Client
        )}`,
        dispatch
      );
      if (res) {
        setarrEntfyyear(res.EntFinYear);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Load_Branch = async (year) => {
    try {
      await setYearName(year.strYear);
      await setiYearId(year.iYearId);
      await setDBName(year.dbName);
      const res = await API_FETCH(
        `BranchMaster/Load_Branch/${Number(_Common.User)}/${Number(
          _Common.Role
        )}/${Number(_Common.Client)}/${year.dbName}`,
        dispatch
      );
      if (res) {
        setarrEntBranch(res.EntBranch);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_Company = async (company) => {
    try {
      await tab_click(1);
      setIsCompany(true);
      setIsMaster(false);
      setIsBranch(false);
      setiCompId(company.iCompId);
      setCode(company.strCompanyCode);
      setName(company.strCompanyName);
      setAddNo(company.strOfficeNo);
      setStreet(company.strStreet);
      setArea(company.strArea);
      setCity(company.strCity);
      setPincode(company.strPinCode);
      setMobileNo(company.strMobile);
      setGSTNo(company.strGST);
      setEmail(company.strEmail);
      setState(company.iState);
      setCountry(company.iCountry);
      setRemark(company.strRemark);
      setPhoneNo(company.strPhone);
      setTinNo(company.strTIN);
      setLicenceNo(company.strLicenceNo);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_Branch = async (Branch) => {
    await setIsBranch(true);
    await setIsMaster(false);
    await setIsCompany(false);
    $(".img-wrapper").addClass("active"); // For Image Active
    setiBranchId(Branch.iBranchId);
    setName(Branch.strBranchName);
    setCode(Branch.strBranchCode);
    setBranchtype(Branch.strBranchType);
    setAddNo(Branch.strAdd1);
    setStreet(Branch.strAdd2);
    setArea(Branch.strAdd3);
    setCity(Branch.strAdd4);
    setPincode(Branch.strPincode);
    setState(Branch.iState);
    setCountry(Branch.iCountry);
    setMobileNo(Branch.strMobile);
    setPhoneNo(Branch.strPhone);
    setGSTNo(Branch.strGST);
    setEmail(Branch.strEmail);
    setLicenceNo(Branch.strLicenceNo);
    setTinNo(Branch.strTinNo);
    setRemark(Branch.strRemarks);
    setSmtpHost(Branch.smtpHost);
    setSmtpPort(Branch.smtpPort);
    setstrPassword(Branch.password);
    setStockType(Branch.stockType);
    setImgExd(Branch.strLogoType);
    setImgName(Branch.strLogoName);

    if (Branch.logo.length > 0) {
      var UnitBitArray = new Uint8Array(Branch.logo);
      var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
      setstrImage("data:image/png;base64," + ArrayToString);
      setEntImgByte(Branch.logo);
    }
  };
  const Delete_Company = async (RowInfo) => {
    try {
      var CompId = RowInfo.iCompId;
      var iYearId = RowInfo.iYearId;
      var DBName = RowInfo.strDbName;
      const res = await API_POST(
        `Master/DeleteCompany/${CompId}/${iYearId}/${DBName}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Open_Branch = async (branch) => {
    try {
      const AuthParems = await DecryptData(localStorage.jbctrlparams);
      AuthParems.Company = iCompId;
      AuthParems.OrgName = CompName.replace(/ /g, "_");
      AuthParems.Year = iYearId;
      AuthParems.DB = DBName;
      AuthParems.Fyear = YearName;
      AuthParems.Branch = branch.iBranchId;
      AuthParems.Branchname = branch.strBranchName;
      var Image = "";
      if (branch.logo.length > 0) {
        var UnitBitArray = new Uint8Array(branch.logo);
        var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
        Image = "data:image/png;base64," + ArrayToString;
      }
      AuthParems.Logo = Image;
      localStorage.jbctrlparams = await EncryptData(AuthParems);
      const res = await API_FETCH(
        `BranchMaster/Open_Branch/${Number(branch.iBranchId)}/${Number(
          iYearId
        )}/${Number(iCompId)}/${Number(AuthParems.User)}/${Number(
          AuthParems.Client
        )}`,
        dispatch
      );
      if (res) window.location.href = `/JustBill/DashBoard`;
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Clear_Click = async () => {
    setiCompId(0);

    setiActiveCompId(0);
    setiActiveFyId(0);
    setiActiveBranchId(0);

    setiBranchId(0);
    setiYearId(0);
    setDBName("");
    setYearName("");
    setCompName("");

    setCode("");
    setName("");
    setBranchtype("SUB");
    setAddNo("");
    setPincode("");
    setStreet("");
    setArea("");
    setCity("");
    setCountry(100);
    setState(32);
    setMobileNo("");
    setGSTNo("");
    setEmail("");
    setRemark("");
    setPhoneNo("");
    setTinNo("");
    setLicenceNo("");
    setFinanFrom("");
    setFinanTo("");
    setFromTo("");
    setstrImage("");
    setImgExd("");
    setImgName("");
    setEntImgByte([]);
    setstrPassword("");
    setSmtpPort("0");
    setSmtpHost("");
    setStockType(2);

    setisEmailChanged(false);
    setisMoreinfo(false);
    setisCreate(false);
    setisDelete(false);
    setisModify(false);
    setisView(false);
    setIsMaster(true);
    setIsCompany(false);
    setIsBranch(false);

    await setarrEntCompany([]);
    await setarrEntfyyear([]);
    await setarrEntBranch([]);
    await setarrstockType([]);
    await setarrCountry([]);
    await setarrState([]);

    $(`.dt-button`).removeClass("active");
    $(`.fy-button`).removeClass("active");
    await tab_click(0);
    await Load_Company();
  };
  const LogoutClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "";
  };
  const InsUpd_Organization = async () => {
    try {
      const Company = {
        iCompId: iCompId,
        strCompanyName: Name,
        strCompanyCode: Code,
        strOfficeNo: AddNo,
        strStreet: Street,
        strArea: Area,
        strCity: City,
        strPinCode: Pincode,
        iState: State,
        iCountry: Country,
        strMobile: MobileNo,
        strPhone: PhoneNo,
        strGST: GSTNo,
        strTIN: TinNo,
        strLicenceNo: LicenceNo,
        strEmail: Email,
        strRemark: Remark,

        strYear: FromTo,
        strYearFrom: FinanFrom,
        strYearTo: FinanTo,
        strDBName: DBName,
        iDBId: 0,

        strRole: "",
        strCreatedOn: "",
        strStatus: "Active",
        iClientId: Number(_Common.Client),
        iUserId: Number(_Common.User),
        iRoleId: Number(_Common.Role),
      };
      const res = await API_POST(
        `CompanyMaster/InsUpd_Company`,
        Company,
        dispatch
      );
      if (res) {
        await Show_Message(dispatch, res.strMessage, "success");
        await Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const InsUpd_SubOrganization = async () => {
    try {
      if (await Validate_Image()) {
        const Branch = {
          iClientId: Number(_Common.Client),
          iCompId: iCompId,
          iBranchId: iBranchId,
          strBranchCode: Code,
          strBranchName: Name,
          strBranchType: Branchtype,
          strAdd1: AddNo,
          strAdd2: Street,
          strAdd3: Area,
          strAdd4: City,
          strPincode: Pincode,
          iState: State,
          iCountry: Country,
          strMobile: MobileNo,
          strPhone: PhoneNo,
          strGST: GSTNo,
          strTinNo: TinNo,
          strRemarks: Remark,
          strLicenceNo: LicenceNo,
          strEmail: Email,
          SmtpPort: SmtpPort,
          SmtpHost: SmtpHost,
          Password: strPassword,
          StockType: StockType,

          Logo: EntImgByte,
          strLogoType: ImgExd,
          strLogoName: ImgName,

          strDBName: DBName,
        };
        const res = await API_POST(
          `BranchMaster/InsUpd_Branch`,
          Branch,
          dispatch
        );
        if (res) {
          await Show_Message(dispatch, res.strMessage, "success");
          await Clear_Click();
        }
      } else {
        Show_Message(
          dispatch,
          "Only jpg/jpeg and png files are allowed!",
          "error"
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Upload_Image = () => {
    const defaultBtn = document.getElementById("default-btn");
    // eslint-disable-next-line no-useless-escape
    let regExp = /[0-9a-zA-Z\^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~\_ ]+$/;
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      const file = this.files[0];
      if (file) {
        const reader = new FileReader();
        const Bytreader = new FileReader();
        reader.readAsDataURL(file);
        Bytreader.readAsArrayBuffer(file);

        reader.onload = function () {
          setstrImage(reader.result);
        };
        Bytreader.onload = function () {
          var fileByteArray = [];
          var array = new Uint8Array(Bytreader.result);
          for (var i = 0; i < array.length; i++) fileByteArray.push(array[i]);
          setEntImgByte(fileByteArray);
        };
        if (this.value) {
          setImgName(this.value.match(regExp)[0]);
        }
        $(".img-wrapper").addClass("active"); // For Image Active
      }
    });
  };
  const Name_OnChange = (value) => {
    var SplitName = value.split(" ");
    var strCode = "";
    if (SplitName.length > 1) {
      strCode = "";
      for (var i = 0; i < SplitName.length; i++) {
        strCode = strCode.concat(SplitName[i].substring(0, 1));
      }
      strCode = strCode + "001";
    } else {
      strCode = SplitName[0].substring(0, 3);
      strCode = strCode + "001";
    }
    setCode(strCode.toUpperCase());
  };
  const Country_OnChange = async (countryid) => {
    try {
      await setCountry(countryid);
      const res = await API_FETCH(
        `Master/Country_Onchange/${Number(countryid)}`,
        dispatch
      );
      if (res) {
        setarrState(res.EntState);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Email_Onchange = async () => {
    setisEmailChanged(false);
    let domain = Email.split("@")[1];
    domain = domain.toLowerCase();
    if (Email.length > 1 && !Email.includes(".com")) {
      setSmtpHost("");
      setSmtpPort(0);
      setisPortHostReadOnly(false);
      Show_Message(dispatch, "Please Enter Valid Email", "info");
    } else if (domain.length < 5) {
      setSmtpHost("");
      setSmtpPort(0);
      setisPortHostReadOnly(false);
      Show_Message(dispatch, "Please Enter Valid Email", "info");
    } else {
      switch (domain) {
        case "gmail.com":
          setSmtpHost("smtp.gmail.com");
          setSmtpPort(587);
          setisPortHostReadOnly(true);
          break;
        case "yahoo.com":
          setSmtpHost("smtp.mail.yahoo.com");
          setSmtpPort(587);
          setisPortHostReadOnly(true);
          break;
        case "outlook.com":
          setSmtpHost("smtp.office365.com");
          setSmtpPort(587);
          setisPortHostReadOnly(true);
          break;
        case "zoho.com":
          setSmtpHost("smtp.zoho.com");
          setSmtpPort(587);
          setisPortHostReadOnly(true);
          break;
        case "aol.com":
          setSmtpHost("smtp.aol.com");
          setSmtpPort(587);
          setisPortHostReadOnly(true);
          break;
        case "protonmail.ch":
          setSmtpHost("smtp.protonmail.ch");
          setSmtpPort(587);
          setisPortHostReadOnly(true);
          break;
        default:
          setSmtpHost(`smtp.${domain}`);
          setSmtpPort(0);
          setisPortHostReadOnly(false);
          break;
      }
    }
  };
  const Validate_Image = () => {
    var Image = document.getElementById("default-btn").value;
    var idxDot = Image.lastIndexOf(".") + 1;
    var ImageType = Image.substr(idxDot, Image.length).toLowerCase();
    if (
      ImageType === "jpg" ||
      ImageType === "jpeg" ||
      ImageType === "png" ||
      Image === ""
    )
      return true;
    else return false;
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 1 && ActiveIndex <= 20) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + 19 + "']").focus();
        $("[tabindex='" + 19 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
  };
  return (
    <div className="Company-container">
      <div className="Company-content">
        {!IsBranch && (
          <div className="common-tab">
            <label onClick={(e) => tab_click(0)} className="active">
              My Company
            </label>
            {isCreate && <label onClick={(e) => tab_click(1)}>Company</label>}
            <div id="tab-line"></div>
          </div>
        )}
        {IsMaster && (
          <div className="company-wrapper">
            <div className="dt-company">
              {arrEntCompany.map((company, Index) => (
                <div
                  key={Index}
                  className={
                    iActiveCompId === company.iCompId
                      ? "dt-button selected"
                      : "dt-button"
                  }
                >
                  <h1>
                    <span>{company.strCompanyName}</span>
                    <div className="cmp-btns">
                      <i
                        className="fa-regular fa-pen-to-square"
                        onClick={(e) => View_Company(company)}
                      ></i>
                      <i
                        className="fa-solid fa-caret-down"
                        onClick={(e) => Company_Click(company, Index)}
                      ></i>
                    </div>
                  </h1>
                  {iCompId === company.iCompId && (
                    <div className="fyear-container">
                      <h3 className="ht-fyear">
                        <span>Financial Year</span>
                        <span>From</span>
                        <span>To</span>
                        <span>Last Backup</span>
                      </h3>
                      <div className="dt-fyear">
                        {arrEntfyyear.map((year, Index) => (
                          <>
                            <div
                              key={Index}
                              className={
                                iActiveFyId === year.iYearId
                                  ? "fy-button selected"
                                  : "fy-button"
                              }
                            >
                              <span>{year.strYear}</span>
                              <span>{year.strYearFrom}</span>
                              <span>{year.strYearTo}</span>
                              <span>{year.strBackupedOn}</span>
                              <span className="fy-btn">
                                <span>
                                  <i className="fa-solid fa-trash"></i>
                                </span>
                                <span onClick={(e) => Year_Click(year, Index)}>
                                  <i className="fa-solid fa-caret-down"></i>
                                </span>
                              </span>
                            </div>
                            {iYearId === year.iYearId && (
                              <div className="branch-container">
                                <div className="ht-branch">
                                  <span>Sub Organization</span>
                                </div>
                                <div className="dt-branch">
                                  {arrEntBranch.map((branch, Index) => (
                                    <div
                                      key={Index}
                                      className={
                                        iActiveFyId === year.iYearId &&
                                        iActiveBranchId === branch.iBranchId
                                          ? "br-button selected"
                                          : "br-button"
                                      }
                                    >
                                      <span className="br-name">
                                        <h2>{branch.strBranchName}</h2>
                                        <span>{branch.strBranchType}</span>
                                      </span>
                                      <span className="br-btn">
                                        {isView && (
                                          <span
                                            onClick={(e) => Open_Branch(branch)}
                                          >
                                            <i className="fa-regular fa-folder-open"></i>
                                          </span>
                                        )}
                                        {isModify && (
                                          <span
                                            onClick={(e) => View_Branch(branch)}
                                          >
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </span>
                                        )}
                                        <span>
                                          <i className="fa-solid fa-trash"></i>
                                        </span>
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {IsCompany && (
          <div className="company-wrapper">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <label>Name</label>
                <span className="danger">*</span>
                <input
                  autoFocus
                  type="text"
                  onKeyDown={(e) => TabIndex(e)}
                  className="form-control"
                  placeholder="Enter Company Name"
                  spellCheck={false}
                  tabIndex={1}
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={(e) => Name_OnChange(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Address No</label> <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  spellCheck={false}
                  placeholder="Enter Address No"
                  tabIndex={2}
                  value={AddNo}
                  onChange={(e) => setAddNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Street</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Street Name"
                  tabIndex={8}
                  value={Street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Town / Village</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Town Name"
                  tabIndex={9}
                  value={Area}
                  onChange={(e) => setArea(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>City</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter City Name"
                  tabIndex={10}
                  value={City}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Country</label> <span className="danger">*</span>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select Country"
                  select_value={(val) => Country_OnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={Country}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={arrCountry}
                  EmptVal="Country"
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>State</label> <span className="danger">*</span>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select State"
                  select_value={(val) => setState(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={State}
                  displayName="strSName"
                  disvalue="iSId"
                  arrydata={arrState}
                  EmptVal="State"
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Pin Code</label> <span className="danger">*</span>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="number"
                  className="form-control"
                  placeholder="Enter Pincode"
                  maxLength={6}
                  tabIndex={3}
                  value={Pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Mobile No</label> <span className="danger">*</span>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="number"
                  maxLength={12}
                  className="form-control"
                  placeholder="Enter Mobile No"
                  tabIndex={4}
                  value={MobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Phone No</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone No"
                  tabIndex={11}
                  value={PhoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Email</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={6}
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>GST No</label>
                <span className="danger">*</span>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  spellCheck={false}
                  placeholder="Enter GST No"
                  maxLength={15}
                  tabIndex={5}
                  value={GSTNo}
                  onChange={(e) => setGSTNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>License No</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter License No"
                  tabIndex={12}
                  value={LicenceNo}
                  onChange={(e) => setLicenceNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>TIN No</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter TIN No"
                  tabIndex={13}
                  value={TinNo}
                  onChange={(e) => setTinNo(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Remark</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Remark"
                  tabIndex={14}
                  value={Remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Financial Year From</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="date"
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={17}
                  value={FinanFrom}
                  readOnly={iCompId > 0 ? true : false}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>To</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="date"
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={18}
                  value={FinanTo}
                  readOnly={iCompId > 0 ? true : false}
                />
              </div>
            </div>
          </div>
        )}
        {IsBranch && (
          <div className="common-tab">
            <label onClick={(e) => tab_click(0)} className="active">
              Branch
            </label>
            <div id="tab-line"></div>
          </div>
        )}
        {IsBranch && (
          <div className="company-wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-12 p-2">
                <center>
                  <div className="img-container">
                    <div className="img-content">
                      <div className="img-wrapper">
                        <div className="image">
                          {strImage !== "" && <img src={strImage} alt="" />}
                        </div>
                        <div className="content">
                          <div id="custom-btn" onClick={Upload_Image}>
                            <i className="fas fa-cloud-upload-alt"></i>
                          </div>
                        </div>
                        <div
                          id="cancel-btn"
                          onClick={(e) => {
                            setstrImage("");
                            setEntImgByte([]);
                            setImgName("");
                            $(".img-wrapper").removeClass("active");
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </div>
                        <div className="file-name">{ImgName}</div>
                      </div>
                      <input
                        id="default-btn"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        hidden
                      />
                    </div>
                  </div>
                </center>
              </div>
              <div className="col-md-9 col-sm-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 mt-1">
                    <label>Name</label>
                    <span className="danger">*</span>
                    <input
                      autoFocus
                      type="text"
                      onKeyDown={(e) => TabIndex(e)}
                      className="form-control"
                      placeholder="Enter Branch Name"
                      spellCheck={false}
                      tabIndex={1}
                      value={Name}
                      onChange={(e) => setName(e.target.value)}
                      onBlur={(e) => Name_OnChange(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-1">
                    <label>Address No</label> <span className="danger">*</span>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={(e) => TabIndex(e)}
                      spellCheck={false}
                      placeholder="Enter Address No"
                      tabIndex={2}
                      value={AddNo}
                      onChange={(e) => setAddNo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-1">
                    <label>PIN Code</label> <span className="danger">*</span>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="number"
                      className="form-control"
                      placeholder="Enter Pincode"
                      maxLength={6}
                      tabIndex={3}
                      value={Pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-1">
                    <label>Mobile No</label> <span className="danger">*</span>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="number"
                      maxLength={12}
                      className="form-control"
                      placeholder="Enter Mobile No"
                      tabIndex={4}
                      value={MobileNo}
                      onChange={(e) => setMobileNo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-1">
                    <label>GST No</label>
                    <span className="danger">*</span>
                    <input
                      onKeyDown={(e) => TabIndex(e)}
                      type="text"
                      className="form-control"
                      spellCheck={false}
                      placeholder="Enter GST No"
                      maxLength={15}
                      tabIndex={5}
                      value={GSTNo}
                      onChange={(e) => setGSTNo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6  mt-1">
                    <label>Country</label> <span className="danger">*</span>
                    <CustomizedSelectoption
                      optionparem="isoptionShow"
                      showoption={_selecrparem.isoptionShow}
                      placeholder="Select Country"
                      select_value={(val) => Country_OnChange(val)}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={Country}
                      displayName="strCountryName"
                      disvalue="iCountryId"
                      arrydata={arrCountry}
                      EmptVal="Country"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6  mt-1">
                    <label>State</label> <span className="danger">*</span>
                    <CustomizedSelectoption
                      optionparem="isoptionShow1"
                      showoption={_selecrparem.isoptionShow1}
                      placeholder="Select State"
                      select_value={(val) => setState(val)}
                      btnname=""
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={State}
                      displayName="strSName"
                      disvalue="iSId"
                      arrydata={arrState}
                      EmptVal="State"
                    />
                  </div>
                </div>
              </div>
            </div>
            <label className="btn-More">
              <i className="fa-solid fa-circle-plus mr-1"></i>
              <span
                onClick={(e) => {
                  setisMoreinfo(!isMoreinfo);
                }}
              >
                Show Addditional Information
              </span>
            </label>
            {isMoreinfo && (
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label>Street</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Street Name"
                    tabIndex={8}
                    value={Street}
                    onChange={(e) => setStreet(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label>Town / Village</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Town Name"
                    tabIndex={9}
                    value={Area}
                    onChange={(e) => setArea(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label>City</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter City Name"
                    tabIndex={10}
                    value={City}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label>Phone No</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Phone No"
                    tabIndex={11}
                    value={PhoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label>Email</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    tabIndex={6}
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label>License No</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter License No"
                    tabIndex={12}
                    value={LicenceNo}
                    onChange={(e) => setLicenceNo(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label>TIN No</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter TIN No"
                    tabIndex={13}
                    value={TinNo}
                    onChange={(e) => setTinNo(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label>Remark</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Remark"
                    tabIndex={14}
                    value={Remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <label>Stock Method</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_selecrparem.isoptionShow2}
                    placeholder="Select Stock Method"
                    select_value={(val) => setStockType(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={StockType}
                    displayName="strStkType"
                    disvalue="istkId"
                    arrydata={arrstockType}
                    EmptVal="Stock Method"
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <label>Email</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    tabIndex={6}
                    value={Email}
                    onChange={(e) => {
                      setisEmailChanged(true);
                      setEmail(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmailChanged) Email_Onchange();
                    }}
                  />
                </div>
                <div className="col-md-4 col-sm-6 mt-2">
                  <label>Email Password / App Key</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Password"
                    tabIndex={7}
                    value={strPassword}
                    onChange={(e) => setstrPassword(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-6 mt-2">
                  <label>Host</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Provider Host"
                    tabIndex={8}
                    value={SmtpHost}
                    onChange={(e) => setSmtpHost(e.target.value)}
                    readOnly={isPortHostReadOnly}
                  />
                </div>
                <div className="col-md-4 col-sm-6 mt-2">
                  <label>Port</label>
                  <input
                    onKeyDown={(e) => TabIndex(e)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Provider Port"
                    tabIndex={9}
                    value={SmtpPort}
                    onChange={(e) => setSmtpPort(e.target.value)}
                    readOnly={isPortHostReadOnly}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="btn-section">
          {!IsMaster && (
            <button
              tabIndex={20}
              className="btn-fabgreen"
              onClick={(e) => {
                if (Name === "") {
                  Show_Message(dispatch, "Please Enter Name", "error");
                } else if (Code === "") {
                  Show_Message(dispatch, "Please Enter  Code", "error");
                } else if (AddNo === "") {
                  Show_Message(dispatch, "Please Enter Address No ", "error");
                } else if (MobileNo === "") {
                  Show_Message(dispatch, "Please Enter Mobile No ", "error");
                } else {
                  Ask_Question(
                    dispatch,
                    IsBranch ? InsUpd_SubOrganization : InsUpd_Organization,
                    `Do You Want to ${
                      IsBranch
                        ? iBranchId === 0
                          ? "Create"
                          : "Update"
                        : iCompId === 0
                        ? "Create"
                        : "Update"
                    } ${IsBranch ? "Branch" : "Company"}?`
                  );
                }
              }}
            >
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
          )}
          {!IsMaster && (
            <button
              className="btn-fabgreen"
              onClick={async () => await Clear_Click()}
            >
              Clear&nbsp; <i className="fas fa-eraser"></i>
            </button>
          )}
          {IsCompany && isDelete && (
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  Delete_Company,
                  "Do You Want to Delete Branch Year?"
                );
              }}
            >
              Delete&nbsp; <i className="fa-solid fa-trash"></i>
            </button>
          )}
          {!IsMaster && (
            <button
              className="btn-fabgreen"
              onClick={async () => await Clear_Click()}
            >
              Close&nbsp; <i className="fas fa-times"></i>
            </button>
          )}
          <button onClick={LogoutClick} className="btn-fabgreen">
            <i className="fa-solid fa-right-from-bracket"></i> Sign Out
          </button>
        </div>
        <Messagedialogbox />
      </div>
    </div>
  );
}

export default CompanyMaster;
