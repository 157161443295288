import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { Select_Product, ShowHide_Popup } from "../features/Transactionstates";
import { useEffect } from "react";
import { API_POST } from "../General/Utility";
import PopupSearch from "./PopupSearch";

function MasterProductSearch(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [arrProduct, setarrProduct] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_StockbyProduct("");
  }, []);
  const Load_StockbyProduct = async (strtxt) => {
    try {
      await Pre_Loading(dispatch, true);
      setstrSearch(strtxt);
      const res = await API_POST(
        `ProductTrans/Load_StockbyProduct/`,
        {
          iScrId: props.iScrId,
          ScrType: props.strScrType,
          iPartyId: props.iPartyId,
          TaxRelId: props.iTaxRelId,
          strSearch: strtxt,
          iBranchId: _AuthParems.Branch,
          iUserId: Number(_AuthParems.User),
          iRoleId: Number(_AuthParems.Role),
          iClientId: Number(_AuthParems.Client),
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        setarrEntTableCol(res.EntTablDesign);
        setarrProduct(res.EntProduct);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Product = async (product) => {
    try {
      await Pre_Loading(dispatch, true);
      const objTrans = {
        iScrId: props.iScrId,
        ScrType: props.strScrType,
        iPartyId: props.iPartyId,
        iTaxRelId: props.iTaxRelId,
        Search: strSearch,
        iBranchId: _AuthParems.Branch,
        strDBName: _AuthParems.DB,
        iProId: product.proId,
        isComposPro: product.isComposPro,
        isBatchPro: product.isBatchPro,
        isAttPro: product.isAttPro,
        iAttGrpId: product.iAttGrpId,
        TransType: "Master",
      };
      const res = await API_POST(
        `ProductTrans/View_Product/`,
        objTrans,
        dispatch
      );
      if (res) {
        res.isComposPro = product.isBatchPro;
        res.isBatchPro = product.isAttPro;
        await Popup_Close();
        await dispatch(Select_Product(res));
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Popup_Close = async () => {
    await dispatch(ShowHide_Popup("IsProSearch"));
  };
  return (
    <PopupSearch
      ScrName="PRODUCT SEARCH "
      FilterCol={[]}
      FilterVal={""}
      FilterShow={false}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrProduct}
      UpdateFilterCol={""}
      Search_Data={(e) => Load_StockbyProduct(e)}
      View_Data={(e) => View_Product(e)}
      Close={() => Popup_Close()}
    />
  );
}
export default MasterProductSearch;
