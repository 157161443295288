import { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import SideMenu from "../General/SideMenu";
import BOMProductSearch from "./BOMProductSearch";

import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Messagedialogbox, { Ask_Question } from "../General/Messagedialogbox";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function BillOfMaterial() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _SelectParem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 68;
  const [iBOMId, setiBOMId] = useState(0);
  const [strBomName, setstrBomName] = useState("");
  const [iProductId, setiProductId] = useState(0);
  const [strAlias, setstrAlias] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strProductName, setstrProductName] = useState("");
  const [iUnitId, setiUnitId] = useState(0);
  const [iQty, setiQty] = useState("");
  const [ListProduct, setListProduct] = useState([]);
  const [ListBOMModel, setListBOMModel] = useState([]);
  const [ListUnit, setListUnit] = useState([]);
  const [ListBOMProduct, setListBOMProduct] = useState([]);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_BOM();
  }, []);
  const Load_BOM = async () => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);
    const res = await API_FETCH(
      `BOM/Load_BOM/${Number(_LocalParems.Branch)}/${_LocalParems.DB}`,
      dispatch
    );
    if (res) {
      setListBOMModel(res.objBomList);
    }
  };
  const Save_BOM = async () => {
    const InsBomModel = {
      iScrId: iScrId,
      iBOMId: Number(iBOMId),
      strBOMName: strBomName,
      strAliasName: strAlias,
      strPrintName: strPrintName,
      strRemarks: strRemarks,
      iBranchId: Number(_AuthParems.Branch),
    };
    const res = await API_POST(
      "BOM/InsUpd_BOM/",
      {
        objEntBOM: InsBomModel,
        _objEntBOM: ListProduct,
        strDBName: _AuthParems.DB,
      },
      dispatch
    );
    if (res) {
      Show_Message(dispatch, res.strMessage, "success");
      Clear_BOM();
    }
  };
  const Clear_BOM = () => {
    setstrBomName("");
    setstrPrintName("");
    setstrRemarks("");
    setstrAlias("");
    setstrProductName("");
    setstrBomName("");
    setiUnitId(0);
    setiQty("");
    setiProductId(0);
    setListProduct([]);
    setListBOMModel([]);
    setListUnit([]);
    Load_BOM();
  };
  const Delete_BOM = async () => {
    const res = await API_POST(
      "BOM/Delete_BOM/" +
        Number(_AuthParems.Branch) +
        "/" +
        Number(iBOMId) +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      Clear_BOM();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const View_BOM = async (iBomId) => {
    if (Number(iBomId) === 0) {
      Show_Message(dispatch, "Please Select BOM", "info");
    } else {
      const res = await API_FETCH(
        "BOM/View_BOM/" + Number(iBomId) + "/" + _AuthParems.DB,
        dispatch
      );
      if (res) {
        setListProduct(res.objEntBOM);
        let objBom = ListBOMModel.filter((D) => D.iBOMId === Number(iBomId))[0];
        setiBOMId(objBom.iBOMId);
        setstrBomName(objBom.strBOMName);
        setstrAlias(objBom.strAliasName);
        setstrPrintName(objBom.strPrintName);
        setstrRemarks(objBom.strRemarks);
      } else Show_Message(dispatch, res.strMessage, "error");
    }
  };
  const AddProdcut = () => {
    var ExistProduct = ListProduct.filter((Data) => Data.proId === iProductId);
    if (Number(iProductId) === 0) {
      Show_Message(dispatch, "Select Product", "info");
    } else if (ExistProduct.length > 0) {
      var ExistUnit = ListUnit.filter((Data) => Data.iUnitId === iUnitId);
      ListProduct.map((Data) => {
        if (Data.proId === iProductId) {
          Data.unitName = ExistUnit[0].strUnitName;
          Data.unitId = Number(iUnitId);
          Data.qty = Number(iQty);
        }
        return Data;
      });
      setListProduct(ListProduct);
      setstrProductName("");
      setiUnitId(0);
      setiQty(0);
      setiProductId(0);
    } else {
      var UnitName = ListUnit.filter((Data) => Data.iUnitId === iUnitId);
      const AddItem = {
        sNo: ListProduct.length + 1,
        proName: strProductName,
        unitName: UnitName[0].strUnitName,
        unitId: Number(iUnitId),
        qty: Number(iQty),
        proId: iProductId,
        stockMaintain: 1,
        productDimAttGroupId: 0,
        storageDimAttGroupId: 0,
        trackingDimAttGroupId: 0,
        productDimAttId: "0",
        storageDimAttId: "0",
        trackingDimAttId: "0",
        productDimAttDTId: "0",
        storageDimAttDTId: "0",
        trackingDimAttDTId: "0",
      };
      if (ListProduct.length === 0) {
        setListProduct([AddItem]);
      } else {
        setListProduct([...ListProduct, AddItem]);
      }
      setstrProductName("");
      setiUnitId(0);
      setiQty(0);
      setiProductId(0);
    }
  };
  const AssignProduct = async (ProInfo) => {
    const res = await API_FETCH(
      "BOM/View_Unit/" + ProInfo.proId + "/" + _AuthParems.DB,
      dispatch
    );
    if (res) {
      setListBOMProduct([...ListBOMProduct, ProInfo]);
      setstrProductName(ProInfo.proName);
      setiProductId(ProInfo.proId);
      setiUnitId(Number(ProInfo.unitId));
      setListUnit(res.objUnit);
      setiQty(ProInfo.qty);
    }
  };
  const RemoveRow = (rowInfo) => {
    const NewList = ListProduct.filter((D) => D.proId !== rowInfo.proId);
    NewList.map((Data) => {
      if (Data.sNo > 1 && rowInfo.sNo < Data.sNo) {
        Data.sNo = Data.sNo - 1;
      }
      return Data;
    });
    setListProduct(NewList);
  };
  const View_Product = async (proId) => {
    const res = await API_FETCH(
      `BOM/View_Product/${iScrId}/${proId}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      let ProInfo = res.objEntProduct[0];
      setListBOMProduct([...ListBOMProduct, res.objEntProduct]);
      setstrProductName(ProInfo.strProName);
      setiProductId(ProInfo.iProId);
      setiQty(1);
      setiUnitId(Number(ProInfo.iUnitId));
      setListUnit(res.objUnit);
    }
  };
  const Search_BOM = async (strtxt) => {
    setstrSearch(strtxt);
    const objSearch = {
      strSearch: strtxt,
      strColName: "All",
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
    };

    const res = await API_POST("BOM/SearchBom/", objSearch, dispatch);
    if (res) {
      setListBOMModel(res);
    }
  };
  const Product = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "Name",
      accessor: "proName",
    },
    {
      Header: "Unit",
      id: "unitId",
      accessor: "unitName",
    },
    {
      Header: "Qty",
      accessor: "qty",
    },
    {
      Header: "Action",
      id: "proId",
      maxWidth: 100,
      accessor: (rowInfo) => (
        <div>
          <i
            onClick={() => RemoveRow(rowInfo)}
            className="far fa-trash-alt"
          ></i>
        </div>
      ),
    },
  ];
  return (
    <main className="main-container">
      <SideMenu ScrId={iScrId} />
      <div className="content">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          Search={(val) => Search_BOM(val)}
          arrMasterData={ListBOMModel}
          isImg={false}
          displayName="strBOMName"
          Parem1="iBOMId"
          View_Data={(iBOMId) => View_BOM(iBOMId)}
          placeholder="Bill of Materisl"
        />
        <div className="main-content">
          <div className="row">
            <div className="col-md-3 col-sm-12 mb-2">
              <label>
                Name <span className="danger">*</span>
              </label>
              <input
                type="text"
                onClick={(e) => e.target.select()}
                aria-label="First name"
                placeholder="Enter BOM Name"
                className="form-control"
                value={strBomName}
                onChange={(e) => setstrBomName(e.target.value)}
                autoFocus
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Alias</label>
              <input
                placeholder="Enter Alias Name"
                type="text"
                aria-label="First name"
                onClick={(e) => e.target.select()}
                className="form-control"
                value={strAlias}
                onChange={(e) => setstrAlias(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label>Print Name</label>
              <input
                type="text"
                onClick={(e) => e.target.select()}
                placeholder="Enter Print Name"
                aria-label="First name"
                className="form-control"
                value={strPrintName}
                onChange={(e) => setstrPrintName(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-1">
              <label>Remark</label>
              <input
                type="text"
                aria-label="First name"
                onClick={(e) => e.target.select()}
                className="form-control"
                placeholder="Enter Remark"
                value={strRemarks}
                onChange={(e) => setstrRemarks(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <label>Product Name</label>
              <BOMProductSearch
                iScrId={iScrId}
                View_Product={View_Product}
                strProName={strProductName}
              />
            </div>
            <div className="col-sm-6 col-md-2">
              <label>Qty</label>
              <input
                type="number"
                placeholder="Enter Qty"
                aria-label="First name"
                className="form-control"
                onClick={(e) => e.target.select()}
                value={iQty}
                onChange={(e) => setiQty(e.target.value)}
              />
            </div>
            <div className="col-sm-6 col-md-2">
              <label>
                Unit<span className="danger">*</span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_SelectParem.isoptionShow}
                placeholder="Select Unit"
                select_value={(val) => setiUnitId(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iUnitId}
                displayName="strUnitName"
                disvalue="iUnitId"
                arrydata={ListUnit}
                EmptVal="Unit"
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <div className="btn-sub-section mt-3">
                <button
                  className="btn-fabgreen"
                  onClick={AddProdcut}
                  onKeyDown={(e) => AddProdcut()}
                >
                  Add&nbsp;<i className="bx bx-layer-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <ReactTable
            columns={Product}
            data={ListProduct}
            minRows={5}
            row_click={(ProInfo, Index) => {}}
            row_doubleclick={(ProInfo, Index) => {
              AssignProduct(ProInfo);
            }}
            background={true}
            className="full-table"
          />
          <Messagedialogbox />
        </div>
        <Button
          Save={(e) => {
            if (strBomName === "") {
              Show_Message(dispatch, "Please Enter Name", "info");
            } else if (ListProduct.length === 0) {
              Show_Message(dispatch, "Atleast One Product Required", "info");
            } else {
              Ask_Question(
                dispatch,
                Save_BOM,
                iBOMId > 0
                  ? "Do You Want to Update BOM?"
                  : "Do You Want to  Save BOM?"
              );
            }
          }}
          Clear={Clear_BOM}
          Delete={(e) => {
            if (Number(iBOMId) === 0) {
              Show_Message(dispatch, "Please Select BOM", "info");
            } else {
              Ask_Question(dispatch, Delete_BOM, "Do You Want to  Delete BOM?");
            }
          }}
          ScrId={iScrId}
        />
      </div>
    </main>
  );
}
export default BillOfMaterial;
