import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import Transaction, { Load_Transaction } from "../Transaction/Transaction";
import { API_POST } from "../General/Utility";
import { Clear_Transaction } from "../features/Transactionstates";

function SalesReturn() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _DT = useSelector((store) => store.Transstates.Data);
  const _Sum = useSelector((store) => store.Transstates.Summary);
  const _Disc = useSelector((store) => store.Transstates.Discount);

  const _iScrId = 18;

  useEffect(() => {
    Load_Transaction(dispatch, _Common, _iScrId);
  }, []);

  const SaveClick = async () => {
    try {
      if (_DT.arrEntDT.length === 0) {
        Show_Message(dispatch, "Atleast One Item is Required ", "error");
      } else {
        await Pre_Loading(dispatch, true);
        const SalRetListHT = {
          iSalRetId: _HT.iVouchId,
          iBranchId: Number(_AuthParems.Branch),
          iTitleTag: _Common.iScrId,
          iPartyId: _HT.iPartyId,
          strShippingAdd:
            _HT.strShippingAdd === "" ? _HT.strPartyAdd : _HT.strShippingAdd,
          iNumSeqNo: _HT.iNumSeqNo,
          strPrefix: _HT.strPrefix,
          strCompCode: _HT.strCompCode,
          strFinanFrom: _HT.strFinanFrom,
          strTransNo: _HT.strTransNo,
          strVouchNo: _HT.strVouchNo,
          strVouchDate: _HT.strVouchDate,
          iAddLessBillDiscId: _HT.iALBillDiscId,
          iBillTypeId: _HT.iBillTypeId,
          iTaxCalcOnId: _HT.iTaxCalcOnId,
          iTaxTypeId: _HT.iTaxRelId,
          iDMId: _HT.iDMId,
          strConvertDCVouch: _HT.strConvertDCVouch,
          strPartyDCVouch: _HT.strPartyDCVouch,
          strRefNum1: _HT.strRefNum1,
          dpRefNum1Date: _HT.dpRefNum1Date,
          strRefNum2: _HT.strRefNum2,
          dpRefNum2Date: _HT.dpRefNum2Date,
          strRefNum3: _HT.strRefNum3,
          dpRefNum3Date: _HT.dpRefNum3Date,
          strRefNum4: _HT.strRefNum4,
          dpRefNum4Date: _HT.dpRefNum4Date,
          strRemark1: _HT.strRemark1,
          strRemark2: _HT.strRemark2,
          strRemark3: _HT.strRemark3,
          strRemark4: _HT.strRemark4,
          strValidity: _HT.strValidity,
          dpValDateUpto: _HT.dpValDateUpto,
          strPayTerm: _HT.strPayTerm,
          dpPayTermDateUpto: _HT.dpPayTermDateUpto,
          strDeliTerm: _HT.strDeliTerm,
          dpDelTermDateUpto: _HT.dpDelTermDateUpto,
          strEComGstNo: _HT.strEComGstNo,
          strTransport: _HT.strTransport,
          strVehicle: _HT.strVehicle,
          strDespatch: _HT.strDespatch,
          dpDespatchDate: _HT.dpDespatchDate,
          strLRNNumber: _HT.strLRNNumber,
          dpLRNoDate: _HT.dpLRNoDate,
          strAgent: _HT.strAgent,
          strReference: _HT.strReference,
          strDestination: _HT.strDestination,

          dTBillDiscPer: Number(_Disc.dBillDisPer),
          dTBillDiscAmt: Number(_Disc.dBillDisAmt),
          dTBillDiscValue: Number(_Disc.dBillDisValue),
          dTAddLessDiscPer: Number(_Disc.dALBillDisPer),
          dTAddLessDiscAmt: Number(_Disc.dALBillDisAmt),
          dTAddLessDiscValue: Number(_Disc.dALBillDisValue),

          dTotQty: Number(_Sum.Qty),
          dTotGoodval: Number(_Sum.GoodsValue),
          dTotDiscount: Number(_Sum.Discount),
          dTotTax: Number(_Sum.Tax),
          dAddLess: Number(_Sum.AddLess),
          dTotTCSTax: Number(_Sum.TCSTax),
          dRoundOff: Number(_Sum.RoundOff),
          dNetAmt: Number(_Sum.NetAmt),
        };
        const res = await API_POST(
          "SalesReturn/InsUpd_SalesReturn/",
          {
            objEntSI: SalRetListHT,
            objBaseTransDT: _DT.arrEntDT,
            objEntALDT: _DT.arrEntAL,
            objDTHelp: _DT.arrEntDTHelp,
            objEntTax: _DT.arrEntTax,
            iUserId: Number(_AuthParems.User),
            FYid: Number(_AuthParems.Year),
            strDBName: _AuthParems.DB,
          },
          dispatch
        );
        if (res) {
          await Show_Message(dispatch, res.strMessage, "success");
          await Clear_Click();
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Clear_Click = async () => {
    try {
      await Pre_Loading(dispatch, true);
      await dispatch(Clear_Transaction());
      await Load_Transaction(dispatch, _Common, _Common.iScrId);
    } catch (e) {
      await Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  return <Transaction Save={SaveClick} ScrId={_iScrId} />;
}
export default SalesReturn;
