import { useEffect, useState } from "react";
import $ from "jquery";
import Print_DesignCaller from "../General/Print_DesignCaller";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import {
  Modify_Vouchers,
  Selecte_Voucher,
  ShowHide_Popup,
} from "../features/Transactionstates";
import { API_FETCH, API_POST, View_ScreenName } from "../General/Utility";
function TransactionView() {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.Transstates.Common);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strFromDate, setstrFromDate] = useState("");
  const [strToDate, setstrToDate] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [arrEntVoucher, setarrEntVoucher] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_Vouchers();
  }, []);

  const Load_Vouchers = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `Transaction/Load_Vouchers`,
        {
          iBranchId: _AuthParems.Branch,
          strColName: "Vouchers",
          iScrId: _Common.iScrId,
          iPartyId: 0,
          strDBName: _AuthParems.DB,
          strScrName: _Common.strScrName,
          iUserId: Number(_AuthParems.User),
        },
        dispatch
      );
      if (res) {
        setstrFromDate(res.strFromDate);
        setstrToDate(res.strToDate);
        setarrEntVoucher(res.objVouchers);
        setarrEntTableCol(res.EntTablDesign);
        if (res.objVouchers.length > 0)
          dispatch(Selecte_Voucher(res.objVouchers[0].vouchId));
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_Vouchers = async (Value, SearchFor) => {
    try {
      await Pre_Loading(dispatch, true);
      const objSearch = {
        iScrId: _Common.iScrId,
        dpFrom: strFromDate,
        dpTo: strToDate,
        strSearch: strSearch,
        iPartyId: 0,
        strDBName: _AuthParems.DB,
        strScrName: _Common.strScrName,
        iUserId: Number(_AuthParems.User),
        iBranchId: Number(_AuthParems.Branch),
      };
      switch (SearchFor) {
        case "From":
          setstrFromDate(Value);
          objSearch.dpFrom = Value;
          break;
        case "To":
          setstrToDate(Value);
          objSearch.dpTo = Value;
          break;
        case "Search":
          setstrSearch(Value);
          objSearch.strSearch = Value;
          break;
        default:
          break;
      }
      const res = await API_POST(
        `Transaction/Search_Vouchers/`,
        objSearch,
        dispatch
      );
      if (res) {
        setarrEntVoucher(res.EntVouchers);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const PrintPdfClick = async (PrintFor) => {
    try {
      if (_HT.SelectedVouchId === 0) {
        Show_Message(dispatch, "Please Select Screen", "info");
      } else {
        await Pre_Loading(dispatch, true);
        await Print_DesignCaller(
          _AuthParems,
          _Common.iScrId,
          "Transaction",
          _HT.SelectedVouchId,
          0,
          PrintFor,
          dispatch,
          false
        );
      }
    } catch (err) {
      Show_Message(dispatch, err.strMessage, "info");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Voucher = async (Voucher) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `${_Common.Controller}/View_Voucher/${Voucher.vouchId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        await dispatch(ShowHide_Popup("IsTransView"));
        await dispatch(
          Modify_Vouchers({ Data: res, VouchId: Voucher.vouchId })
        );
      }
    } catch (err) {
      await Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Key_Down = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 1 && ActiveIndex <= 3)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 4 + "']").focus();
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    } else if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  const Popup_OpenClose = async (IsShow) => {
    await dispatch(ShowHide_Popup(IsShow));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{View_ScreenName(_Common.iScrId)} VIEW </h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => Popup_OpenClose("IsPrintSett")}
            >
              <i className="fa-solid fa-gear"></i>
            </span>
            <span
              className="icon-btn"
              onClick={() => Popup_OpenClose("IsTransView")}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-1">
          <div className="row ">
            <div className="col-md-6 col-sm-12 mb-1 pl-1">
              <div className="m-hsearch ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  autoFocus
                  autoComplete="off"
                  id="Invoice_Search"
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" ||
                      e.key === "ArrowUp" ||
                      e.key === "ArrowDown"
                    );
                  }}
                  tabIndex={52}
                  name="strSearch"
                  value={strSearch}
                  onChange={(e) => Search_Vouchers(e.target.value, "Search")}
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Vouchers(strSearch, "Search")}
                ></i>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-1">
              <input
                type="date"
                className="form-control"
                value={strFromDate}
                name="strFromDate"
                tabIndex={2}
                onKeyDown={(e) => Key_Down(e)}
                onChange={(e) => Search_Vouchers(e.target.value, "From")}
              />
            </div>
            <div className="col-md-3 col-sm-6 mb-1">
              <input
                type="date"
                onKeyDown={(e) => Key_Down(e)}
                tabIndex={3}
                className="form-control"
                value={strToDate}
                name="strToDate"
                onChange={(e) => Search_Vouchers(e.target.value, "To")}
              />
            </div>
            <div className="table-card mt-1">
              <table className="option-list">
                <thead>
                  <tr>
                    {arrEntTableCol.map(
                      (ht, Index) =>
                        ht.isShow && <th key={Index}>{ht.strAliasName}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {arrEntVoucher.map((voucher, Index) => (
                    <tr
                      key={Index}
                      tabIndex={Index}
                      onKeyDown={(e) => {
                        const arrlength = arrEntVoucher.length - 1;
                        if (e.key === "ArrowDown") {
                          if (Index === arrlength)
                            $(`.option-list [tabindex=${0}]`).focus();
                          else
                            $(`.option-list [tabindex=${Index + 1}]`).focus();
                        }
                        if (e.key === "ArrowUp") {
                          if (Index === 0)
                            $(`.option-list [tabindex=${arrlength}]`).focus();
                          else
                            $(`.option-list [tabindex=${Index - 1}]`).focus();
                        }
                        if (e.key === "ArrowLeft") $("#to_date").focus();
                        if (e.key === "ArrowRight")
                          $("#Invoice_Search").focus();
                        if (e.key === "Enter") View_Voucher(voucher);
                      }}
                      onClick={(e) =>
                        dispatch(Selecte_Voucher(voucher.vouchId))
                      }
                      onDoubleClick={(e) => View_Voucher(voucher)}
                    >
                      {arrEntTableCol.map(
                        (dt, Index) =>
                          dt.isShow && (
                            <td
                              key={Index}
                              style={{
                                textAlign: dt.strTextAlign,
                                width: dt.strWidth.includes("%")
                                  ? dt.strWidth
                                  : dt.strWidth + "px",
                                maxWidth: dt.strWidth.includes("%")
                                  ? dt.strWidth
                                  : dt.strWidth + "px",
                                color: "black",
                              }}
                            >
                              {dt.strDataType === "number"
                                ? Number(voucher[dt.strColName]).toFixed(2)
                                : voucher[dt.strColName]}
                            </td>
                          )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (_HT.SelectedVouchId === 0)
                  Show_Message(dispatch, "Please Select Voucher", "info");
                PrintPdfClick("Print");
              }}
            >
              Print&nbsp;<i className="fa-solid fa-print"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (_HT.SelectedVouchId === 0)
                  Show_Message(dispatch, "Please Select Voucher", "info");
                else PrintPdfClick("PDF");
              }}
            >
              PDF&nbsp;<i className="far fa-file-pdf"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (_HT.SelectedVouchId === 0)
                  Show_Message(dispatch, "Please Select Voucher", "info");
                else dispatch(ShowHide_Popup("IsWhatsApp"));
              }}
            >
              WhatsApp&nbsp;<i className="fa-brands fa-whatsapp"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (_HT.SelectedVouchId === 0)
                  Show_Message(dispatch, "Please Select Voucher", "info");
                else dispatch(ShowHide_Popup("IsEmail"));
              }}
            >
              Email&nbsp;<i className="fa-solid fa-envelope"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (_HT.SelectedVouchId === 0)
                  Show_Message(dispatch, "Please Select Voucher", "info");
                else dispatch(ShowHide_Popup("IsEInvoice"));
              }}
            >
              E-Invoice&nbsp;<i className="bx bx-receipt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TransactionView;
