import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import {
  Add_Transdata,
  isMultipleOption,
  Add_TransSummary,
} from "../features/POS";
import Calculation from "./Calculation";
import { Buffer } from "buffer";
function MobileProductSearch() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _General = useSelector((state) => state.Pointofsales.default);
  const _DT = useSelector((state) => state.Pointofsales.CommonDT);
  const _POS = useSelector((state) => state.Pointofsales.Pos);
  const _Disc = useSelector((state) => state.Pointofsales.BillDiscount);
  const _Party = useSelector((state) => state.Pointofsales.Party);
  const _Service = useSelector((state) => state.Pointofsales.OtherCharges);

  const Add_Selectedproduct = async (item) => {
    dispatch(isMultipleOption(false));
    let TransDT = [
      ..._DT.EntTransDT,
      {
        ProId: item.proId,
        AttGrpId: item.attGrpId,
        ProCode: item.proCode,
        ProName: item.proName,
        CommodityCode: item.commodityCode,
        AttValues: item.attValues,
        UnitId: item.unitId,
        UnitName: item.unitName,
        RateWithoutTax: item.rateWithouttax,
        RateWithTax: 0,
        dMinMargin: item.minMargin,
        ItemDiscPer: item.discount,
        ItemDiscAmt: 0,
        ItemDiscValue: 0,
        CashDiscPer: 0,
        CashDiscAmt: 0,
        CashDiscValue: 0,
        BillDiscValue: 0,
        Qty: 1,
        BatchNo: "",
        ManufactureDate: "",
        ExpiryDate: "",
        iTaxRelId: _Party.iTaxRelId,
        TaxPer: item.igstPer,
        iSGST: item.sgstId,
        iCGST: item.cgstId,
        iIGST: item.igstId,
        iTCS: item.tcsId,
        strSGST: item.sgstDesc,
        strCGST: item.cgstDesc,
        strIGST: item.igstDesc,
        strTCS: item.tcsDesc,
        strSGSTPer: item.sgstPer,
        strCGSTPer: item.cgstPer,
        strIGSTPer: item.igstPer,
        strTCSPer: item.tcstPer,
        TaxLvl: item.taxLvl,
        taxableOn: item.taxableOn,
        TcsTaxRelId: item.tcsTaxRelId,
        totalVal: item.totalVal,
        iIndex: _DT.EntTransDT.length,
        IsNewItem: 1,
        GoodsValue: 1 * item.rateWithouttax,
        TaxableAmt: 0,
        TaxAmt: 0,
        ItemAmt: 0,
        FQty: 0,
        Value1: 0,
        TotSqureValue: 0,
        Value2: 0,
        Value3: 0,
        ConstantValue: 0,
        FinalValue: 0,
        SNo: _DT.EntTransDT.length + 1,
        SACCode: "",
        YearId: Number(_AuthParems.Year),
        RGName: "",
        RGId: 0,
        JobId: "",
        JobCode: "",
        JobName: "",
        SerId: 0,
        SerCode: "",
        SerName: "",
        Remark1: "",
        Remark2: "",
        Remark3: "",
        Remark4: "",
        RowStatus: 0,
        IsSerProduct: 0,
        ConScrId: 0,
        DimAttDTId: "",
        InvDTId: 0,
        IsConvertable: false,
        ConvertedQty: 0,
        RowId: 0,
        Reason: "",
        BranchId: Number(_AuthParems.Branch),
        DecimalQty: item.decimalQty,
        StockMaintain: item.stockMaintain,
      },
    ];
    let result = await Calculation(
      TransDT,
      _DT.EntTransAL,
      _POS,
      _Disc,
      _Service.EntALTax
    );
    let summary = result.Summary;
    dispatch(
      Add_Transdata({
        EntTransDT: result.EntItems,
        EntTransPenConDT: [],
        EntTransTax: result.TaxGroup,
        EntTransAL: result.EntAddLess,
        EntTransDTHelp: [],
      })
    );

    dispatch(
      Add_TransSummary({
        dnetAmt: summary.NetAmt,
        dqty: summary.Qty,
        dtotalDiscount: summary.TotalDiscount,
        dgoodsValue: summary.GoodsValue,
        dtotalTax: summary.TotalTax,
        dtotalAddLess: summary.TotalAddLess,
        dtotalTCSTax: summary.TotalTCSTax,
        droundOff: summary.RoundOff,
        dBillDisVal: result.BillDiscValue,
      })
    );
    $("#pro-search").focus();
    $("#pro-search").select();
  };
  const ConvertbytetoImgae = (byte) => {
    if (byte.length > 0) {
      var UnitBitArray = new Uint8Array(byte);
      var ArrayToString = Buffer.from(UnitBitArray).toString("base64");
      return "data:image/png;base64," + ArrayToString;
    } else return "";
  };
  return (
    <div className="pro_mobile_view">
      <div className="mobile_view">
        {_General.EntProduct.map((item, Index) => (
          <button
            key={Index}
            tabIndex={Index}
            onClick={(e) => Add_Selectedproduct(item)}
            onKeyDown={(e) => {
              let arrlength = _General.EntProduct.length - 1;
              var ActiveIndex = document.activeElement.tabIndex;
              if (e.key === "ArrowDown") {
                if (ActiveIndex === arrlength) {
                  $("[tabindex=0]").focus();
                  $("[tabindex=0]").select();
                  document.getElementsByClassName("pro-opt").scrollTop = 0;
                } else {
                  $(`[tabindex=${ActiveIndex + 1}]`).focus();
                  $(`[tabindex=${ActiveIndex + 1}]`).select();
                  document.getElementsByClassName("pro-opt").scrollTop =
                    (ActiveIndex + 1) * 100;
                }
              }
              if (e.key === "ArrowUp") {
                if (ActiveIndex === 0) {
                  $(`[tabindex=${arrlength}]`).focus();
                  $(`[tabindex=${arrlength}]`).select();
                  document.getElementsByClassName("pro-opt").scrollTop =
                    arrlength * 100;
                } else {
                  $(`[tabindex=${ActiveIndex - 1}]`).focus();
                  $(`[tabindex=${ActiveIndex - 1}]`).select();
                  document.getElementsByClassName("pro-opt").scrollTop =
                    (ActiveIndex - 1) * 100;
                }
              }
              if (e.key === "Enter") {
                Add_Selectedproduct(item);
              }
              if (e.key === "Escape") {
                dispatch(isMultipleOption(false));
              }
            }}
          >
            <div className="pro-img">
              <img src={ConvertbytetoImgae(item.proImage)} alt={item.proName} />
              <label style={{ color: "darkgreen", fontWeight: "500" }}>
                {item.attValues.replace(new RegExp("/", "g"), "-")}
              </label>
              <label style={{ color: "black", fontWeight: "400" }}>
                <span>
                  Price :
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(item.rateWithouttax.toFixed(2))}
                </span>
                <span> GST : {Number(item.igstPer).toFixed(2)} %</span>
              </label>
              <label>
                <span> Stock on Hand </span>
                <span style={{ color: item.qty >= 0 ? "gray" : "red" }}>
                  {Number(item.qty).toFixed(2)} {item.unitName}
                </span>
              </label>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
export default MobileProductSearch;
