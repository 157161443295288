import React, { useEffect, useState } from "react";
import PrintInvoicDesign from "./PrintInvoice";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST } from "./Utility";
function EmailPopup(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [strpassWord, setstrpassWord] = useState("");
  const [strHost, setstrHost] = useState("");
  const [iPort, setiPort] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strToEmail, setstrToEmail] = useState("");
  const [strMessage, setstrMessage] = useState("");
  const [objEntPdfContent, setobjEntPdfContent] = useState("");

  useEffect(() => {
    Load_parytyinfo();
  }, []);
  const Load_parytyinfo = async () => {
    if (props.iVouchId === 0) {
      Show_Message(dispatch, "Please Select Voucher", "info");
    } else {
      let VoucherId = props.iVouchId === undefined ? 0 : props.iVouchId;
      Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `PrintDesing/PrintPreView/${props.iScrId}/0/${VoucherId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        var objEntPdf = PrintInvoicDesign(res, props.ScrId, "PDF", true);
        setobjEntPdfContent(objEntPdf);
        let Email = objEntPdf.PartyInfo[0].email.replace("E-Mail: ", "");
        let company = objEntPdf.CompanyInfo[0];
        setstrToEmail(Email);
        setstrEmail(company.compEMail);
        setstrpassWord(company.password);
        setiPort(company.smtpPort);
        setstrHost(company.smtpHost);
      }
    }
  };
  const SentEmail = async (Info) => {
    const Summary = Info.Summary[0];
    const formdata = new FormData();
    var strCompanyName = _AuthParems.Branchname;
    var partyinfo = Info.PartyInfo[0];

    var Message = `
      Dear ${partyinfo.custName},
      Thank You for Your Recent Purchase Invoice-#${partyinfo.salInvNo}
      
      We hope this message finds you well. We want to express our sincere gratitude for choosing ${strCompanyName} for your recent purchase. We appreciate your trust in our Products.
      
      Your order details are as follows:
      
      Invoice Number: ${partyinfo.salInvNo}
      Date of Purchase: ${partyinfo.salInvDate}
      Total Amount: ₹${stringtodecimal(Summary.netAmt)}
      
      We understand that you have a choice when it comes to your Product needs, and we are thrilled to have had the opportunity to serve you. Our team is committed to providing top-notch quality and excellent customer service, and we hope your recent experience reflects that commitment.
      
      If you have any questions about your purchase or if there's anything else we can assist you with, please don't hesitate to reach out to our customer support team at ${
        Info.mobile
      }.
      
      Once again, thank you for choosing ${strCompanyName}. We truly value your business and look forward to serving you again in the future.
      
      Best Regards,
      ${strCompanyName}`;
    formdata.append("strHost", strHost);
    formdata.append("iPort", iPort);
    formdata.append("strFromEmail", strEmail);
    formdata.append("strPassword", strpassWord);
    formdata.append("strToEmail", strToEmail);

    formdata.append("strSubject", strMessage);
    formdata.append("strBody", Message);
    formdata.append("Attachment", UrlToFile(Info.PdfURL, Info.FileName));
    formdata.append("FileName", Info.FileName);
    const res = await API_POST(`Transaction/SendEmail`, formdata, dispatch);
    if (res) {
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const stringtodecimal = (num) => {
    return Number(num).toFixed(2);
  };
  const UrlToFile = (dataUrl, fileName) => {
    // Split the Data URL to get the content type and data
    const data = dataUrl.split(",");

    // Convert base64 to a Blob
    const blob = new Blob([atob(data[1])], { type: data[0] });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: data[0] });

    return file;
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Sent E-mail</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div>
              <label>From</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email Address"
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
              />
            </div>
            <div>
              <label>To</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email Address"
                value={strToEmail}
                onChange={(e) => setstrToEmail(e.target.value)}
              />
            </div>
            <div>
              <label>Message</label>
              <input
                type="text"
                className="form-control"
                style={{ height: "60px" }}
                value={strMessage}
                onChange={(e) => setstrMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                if (strEmail.length <= 1) {
                  Show_Message(dispatch, "Please Enter From E-Mail", "info");
                } else if (strToEmail.length <= 1) {
                  Show_Message(dispatch, "Please Enter To E-Mail", "info");
                } else {
                  SentEmail(objEntPdfContent);
                }
              }}
            >
              Sent Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailPopup;
