export const fnGoodsValue = (dQty, dRate) => {
  return dQty * dRate;
};
export const fnGoodsValueWithSqtValue = (dQty, dRate, dFinalVal) => {
  return dQty * dFinalVal * dRate;
};
export const fnTaxAmt = (dTaxPer, dTaxableAmt) => {
  return (dTaxableAmt * dTaxPer) / 100;
};
export const fnTaxableAmt = (
  iTaxCalcOnId,
  dGoodsValue,
  dItemDiscValue,
  dCashDiscValue,
  dBillDiscValue
) => {
  switch (iTaxCalcOnId) {
    case 1:
      break;
    case 2:
      return dGoodsValue - dItemDiscValue - dCashDiscValue - dBillDiscValue;
    case 3:
      return dGoodsValue - dItemDiscValue;
    case 4:
      return dGoodsValue;
    case 5:
      break;
    case 6:
      break;
    case 7:
      return dGoodsValue - dItemDiscValue - dCashDiscValue;
    default:
      break;
  }
  return 0;
};
export const fnItemAmt = (
  iTaxCalcOnId,
  dTaxableAmt,
  dTaxAmt,
  dItemDiscValue,
  dCashDiscValue,
  dBillDiscValue
) => {
  switch (iTaxCalcOnId) {
    case 1:
      break;
    case 2:
      return dTaxableAmt + dTaxAmt;
    case 3:
      return dTaxableAmt + dTaxAmt - dCashDiscValue - dBillDiscValue;
    case 4:
      return (
        dTaxableAmt + dTaxAmt - dItemDiscValue - dCashDiscValue - dBillDiscValue
      );
    case 5:
      break;
    case 6:
      break;
    case 7:
      return dTaxableAmt + dTaxAmt - dBillDiscValue;
    default:
      break;
  }
  return 0;
};
export const fnRateWithOutTax = (dTaxPer, dRateWithTax) => {
  return dRateWithTax / (1 + dTaxPer / 100);
};
export const fnItemDiscValue = (dItemDiscPer, dGoodsValue) => {
  return (dGoodsValue * dItemDiscPer) / 100;
};
export const fnBillDiscValue = (dBillDiscPer, dTAfterItemDiscAmt) => {
  return (dTAfterItemDiscAmt * dBillDiscPer) / 100;
};
export const fnBillDiscPer = (dBillDiscValue, dTAfterItemDiscAmt) => {
  if (dTAfterItemDiscAmt === 0) return 0;
  else return (dBillDiscValue * 100) / dTAfterItemDiscAmt;
};
export const fnCashDiscValue = (
  strSortMemberPath,
  dCashDisPer,
  dCashDisAmt,
  dGoodsValue
) => {
  if (strSortMemberPath === "CashDiscPer") {
    if (!(dCashDisPer >= 0 && dCashDisPer < 100)) dCashDisPer = 0;
    if (dCashDisPer > 0) dCashDisAmt = 0;
  } else {
    if (dCashDisAmt > 0) dCashDisPer = 0;
    if (DecimalOrganizer(dCashDisAmt) >= dGoodsValue) dCashDisAmt = 0;
  }

  if (dCashDisPer === 0 && dCashDisAmt === 0) return 0;
  else if (dCashDisPer > 0) return fnItemDiscValue(dCashDisPer, dGoodsValue);
  else return dCashDisAmt;
};
export const fnItemDiscValuecal = (
  strSortMemberPath,
  dItemDiscPer,
  dItemDiscAmt,
  dGoodsValue
) => {
  if (strSortMemberPath === "ItemDiscPer") {
    if (!(dItemDiscPer >= 0 && dItemDiscPer < 100)) dItemDiscPer = 0;
    if (dItemDiscPer > 0) dItemDiscAmt = 0;
  } else {
    if (dItemDiscAmt > 0) dItemDiscPer = 0;
    if (DecimalOrganizer(dItemDiscAmt) >= dGoodsValue) dItemDiscAmt = 0;
  }
  if (dItemDiscPer === 0 && dItemDiscAmt === 0) return 0;
  else if (dItemDiscPer > 0) return fnItemDiscValue(dItemDiscPer, dGoodsValue);
  else return dItemDiscAmt;
};
export const DecimalOrganizer = (num) => {
  Number(num);
};
