import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import $ from "jquery";
import { useEffect } from "react";
import {
  Show_MsgPopup,
  Hide_AlertMsgBox,
  Hide_DialogMsgBox,
  setload_Screen,
} from "../features/Message";

export const Show_Message = async (dispatch, Msg, type) => {
  await dispatch(Show_MsgPopup({ Message: Msg, Type: type, isMsg: true }));
};
export const Ask_Question = async (dispatch, Method, Question, Val) => {
  await dispatch(
    Show_MsgPopup({
      Button: Method,
      Question: Question,
      isdialog: true,
      Id: Val,
    })
  );
};
export const Pre_Loading = async (dispatch, IsLoading) => {
  await dispatch(setload_Screen(IsLoading));
};
function Messagedialogbox() {
  const dispatch = useDispatch();
  const _Msgparem = useSelector((state) => state.Message.Msg_Parem);
  const [strIcon, setstrIcon] = useState("");

  useEffect(() => {
    if (_Msgparem.isMsg) {
      Message();
    }
  }, [_Msgparem.isMsg]);
  useEffect(() => {
    if (_Msgparem.isdialog) {
      Show_Dialog();
    }
  }, [_Msgparem.isdialog]);

  const Message = () => {
    switch (_Msgparem.Type) {
      case "error":
        setstrIcon("fa-solid fa-triangle-exclamation");
        break;
      case "success":
        setstrIcon("fa-solid fa-circle-check");
        break;
      case "info":
        setstrIcon("fa-solid fa-circle-exclamation");
        break;
      default:
        break;
    }
    $(".alertMsg").removeClass("hide");
    $(".alertMsg").addClass("Show");
    $("html, body").animate({
      scrollTop: $(".alertMsg").offset().top - 100,
    });
    $(".close-btn").on("click", async function () {
      $(".alertMsg").addClass("hide");
      $(".alertMsg").removeClass("Show");
      await dispatch(Hide_AlertMsgBox());
    });
    setTimeout(async function () {
      if ($(".alertMsg").hasClass("Show")) {
        $(".alertMsg").addClass("hide");
        $(".alertMsg").removeClass("Show");
        await dispatch(Hide_AlertMsgBox());
      }
    }, 10000);
  };
  const Show_Dialog = () => {
    $("#Msgtp").on("mouseover", function () {
      $(this).toggleClass("mouseover");
      $(".MsgTooltip").toggleClass("active");
    });
    $("#Msgtp").on("mouseout", function () {
      $(".MsgTooltip").removeClass("active");
    });
    $("#CloseMsg").on("mouseover", function () {
      $(this).toggleClass("mouseover");
      $(".MsgClose").toggleClass("Close");
    });
    $("#CloseMsg").on("mouseout", function () {
      $(".MsgClose").removeClass("Close");
    });
    $("#CloseMsg").on("click", function () {
      $(".Msg-Popup").addClass("PopupClose");
    });
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowDown") {
      if (ActiveIndex > 1 && ActiveIndex <= 3)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 3 + "']").focus();
    } else if (e.key === "ArrowUp") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    } else if (e.key === "ArrowLeft") {
      if (ActiveIndex > 1 && ActiveIndex <= 3)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 3 + "']").focus();
    } else if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    } else if (e.key === "Enter") {
      if (ActiveIndex === 1) {
        dispatch(Hide_DialogMsgBox());
        if (_Msgparem.Id !== undefined) {
          _Msgparem.Button(_Msgparem.Id);
        } else {
          _Msgparem.Button();
        }
      } else dispatch(Hide_DialogMsgBox());
    }
  };
  return (
    <>
      {/* Show Message */}
      {_Msgparem.isMsg && (
        <div className="alertMsg hide">
          <i className={strIcon}></i>
          <span className="msg">{_Msgparem.Message}</span>
          <i
            className="fa fa-times close-btn"
            onClick={(e) => dispatch(Hide_AlertMsgBox())}
          ></i>
        </div>
      )}
      {/* Ask Question */}
      {_Msgparem.isdialog && (
        <div className="Msg-Popup">
          <div className="Msg-Container">
            <div className="Msg-Header">
              <i id="Msgtp" className="fa-solid fa-circle-exclamation"></i>
              <i
                id="CloseMsg"
                className="fa-regular fa-circle-xmark"
                onClick={(e) => dispatch(Hide_DialogMsgBox())}
                tabIndex="3"
                onKeyDown={(e) => TabIndex(e)}
              ></i>
            </div>
            <div className="Msg-Body">
              <p>{_Msgparem.Question}</p>
            </div>
            <div className="Msg-Footer">
              <button
                type="button"
                onClick={(e) => {
                  dispatch(Hide_DialogMsgBox());
                  if (_Msgparem.Id !== undefined) {
                    _Msgparem.Button(_Msgparem.Id);
                  } else {
                    _Msgparem.Button();
                  }
                }}
                className="px-3"
                tabIndex="1"
                onKeyDown={(e) => TabIndex(e)}
                autoFocus
              >
                <i className="fa-solid fa-thumbs-up"></i>
                Yes
              </button>
              <button
                type="button"
                onClick={(e) => dispatch(Hide_DialogMsgBox())}
                className="px-3"
                tabIndex="2"
                onKeyDown={(e) => TabIndex(e)}
              >
                <i className="fa-solid fa-xmark"></i>
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Pre Loading */}
      {_Msgparem.isloading && (
        <div className="Loading-Popup">
          <div className="Loading-Design">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      )}
    </>
  );
}
export default Messagedialogbox;
