import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import {
  Select_BatchProduct,
  Select_Product,
  ShowHide_Popup,
} from "../features/Transactionstates";
import { useEffect } from "react";
import { API_POST } from "../General/Utility";
import PopupSearch from "./PopupSearch";

function AttributeProductSearch(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.Transstates.Common);
  const _Product = useSelector((store) => store.Transstates.Product);
  const _HT = useSelector((store) => store.Transstates.Header);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [arrProduct, setarrProduct] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_AttributeProduct("");
  }, []);
  const Load_AttributeProduct = async (strtxt) => {
    try {
      await Pre_Loading(dispatch, true);
      setstrSearch(strtxt);
      const res = await API_POST(
        `ProductTrans/Load_ProductAttribute/`,
        {
          iAttGrpId: _Product.iAttGrpId,
          iBranchId: _AuthParems.Branch,
          iPartyId: _HT.iPartyId,
          iTaxRelId: _HT.iTaxRelId,
          strDBName: _AuthParems.DB,
          iWHId: _Product.WHId,
        },
        dispatch
      );
      if (res) {
        setarrProduct(res.EntProduct);
        setarrEntTableCol(res.EntTablDesign);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const View_Product = async (Product) => {
    try {
      await Pre_Loading(dispatch, true);
      const objTrans = {
        iScrId: _Common.iScrId,
        ScrType: _Common.strScrType,
        iPartyId: _HT.iPartyId,
        iTaxRelId: _HT.iTaxRelId,
        Search: strSearch,
        iBranchId: _AuthParems.Branch,
        strDBName: _AuthParems.DB,
        iProId: Product.iProId,
        isComposPro: Product.isComposPro,
        isBatchPro: Product.isBatchPro,
        isAttPro: Product.isAttPro,
        iAttGrpId: Product.iAttGrpId,
        iWHId: Product.WHId,
        TransType: "Transaction",
      };
      const res = await API_POST(
        `ProductTrans/View_Product/`,
        objTrans,
        dispatch
      );
      if (res) {
        res.WHId = _Product.WHId;
        if (Product.isAttPro === 0 && Product.isBatchPro === 1) {
          await dispatch(Select_BatchProduct(res));
          await dispatch(ShowHide_Popup("IsBatchPro"));
        } else {
          await dispatch(ShowHide_Popup("IsAttPro"));
          if (props.View_Product) {
            Product.dQty = 1;
            await props.View_Product(Product);
          } else await dispatch(Select_Product(res));
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  return (
    <PopupSearch
      ScrName="Product By Attribute"
      FilterCol={[]}
      FilterVal={""}
      FilterShow={false}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrProduct}
      UpdateFilterCol={""}
      Search_Data={(e) => Load_AttributeProduct(e)}
      View_Data={(e) => View_Product(e)}
      Close={() => dispatch(ShowHide_Popup("IsAttPro"))}
      IsProduct={true}
    />
  );
}

export default AttributeProductSearch;
