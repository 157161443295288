import CryptoJS from "crypto-js";
import axios from "axios";
import { Pre_Loading, Show_Message } from "./Messagedialogbox";
import { jwtDecode } from "jwt-decode";
import { Update_AdminCntrl } from "../features/CommonStates";
import moment from "moment";

const Axios = axios;
// Local Machine Development Domain
const DevDomain = "localhost:7295";
const ProDomain = "posserver.justbillsoftware.com";
const TestDomain = "postestserver.justbillsoftware.com";
// Live Domain
export const Domain = `https://${ProDomain}/api/`;

// Function to encrypt data
export const EncryptData = async (data) => {
  const encryptedData = await CryptoJS.AES.encrypt(
    JSON.stringify(data),
    localStorage.jbparetkau
  ).toString();
  return encryptedData;
};
// Function to decrypt data
export const DecryptData = async (data) => {
  if (data) {
    const bytes = await CryptoJS.AES.decrypt(data, localStorage.jbparetkau);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else return "";
};
// Function to Validate Token and Authorize User
export const Authorize = () => {
  try {
    // Verify the token
    const decoded = jwtDecode(localStorage.jbparetkau);
    // Check if token is expired
    const isExpired = new Date(decoded.exp * 1000) < new Date();
    if (isExpired) {
      // Token is expired
      return false;
    }
    // Token is valid
    return true;
  } catch (error) {
    // Token is invalid
    return false;
  }
};
// axios request interceptor or Authorize requests making
Axios.interceptors.request.use(
  function (config) {
    const isAuthorize = Authorize(localStorage.jbparetkau);
    config.headers["Authorization"] = "Bearer " + localStorage.jbparetkau;
    config.baseURL = Domain;
    if (
      !isAuthorize &&
      !window.location.pathname.includes("/User-Login") &&
      !window.location.pathname.includes("/Admin/Control-Panel")
    ) {
      Logout_User();
      return;
    }

    return config;
  },
  // Handle Errors if Any Exception happen
  async function (e) {
    if (e.response && e.response.status === 401) {
      if (
        !window.location.pathname.includes("/User-Login") &&
        !window.location.pathname.includes("/Admin/Control-Panel")
      ) {
        Logout_User();
        return;
      }
    }
    return Promise.reject(e);
  }
);
// axios response interceptor or Authorize requests making
Axios.interceptors.response.use(
  async function (res) {
    const isAuthorize = Authorize(localStorage.jbparetkau);
    if (
      !isAuthorize &&
      !window.location.pathname.includes("/User-Login") &&
      !window.location.pathname.includes("/Admin/Control-Panel")
    ) {
      Logout_User();
      return;
    }
    return res;
  },
  // Handle Errors if Any Exception happen
  async function (e) {
    if (e.response && e.response.status === 401) {
      if (
        !window.location.pathname.includes("/User-Login") &&
        !window.location.pathname.includes("/Admin/Control-Panel")
      )
        Logout_User();
      return;
    }
    return Promise.reject(e);
  }
);
// API GET Method
export const API_FETCH = async (parem, dispatch) => {
  try {
    const res = await Axios.get(`${parem}`).catch(async (e) => {
      await console.error(e);
      await Show_ExceptionMsg(e, dispatch);
      return;
    });
    if (res) return await validate_response(res, dispatch);
  } catch (e) {
    await Show_Message(dispatch, e.message, "error");
  }
};
// API POST Method
export const API_POST = async (url, parem, dispatch) => {
  try {
    const res = await Axios.post(`${url}`, parem).catch(async (e) => {
      await console.error(e);
      await Show_ExceptionMsg(e, dispatch);
      return;
    });
    if (res) return await validate_response(res, dispatch);
  } catch (e) {
    await Show_Message(dispatch, e.message, "error");
  }
};
// API POST Method
export const API_POSTParem = async (parem, dispatch) => {
  try {
    const res = await Axios.post(`${parem}`).catch(async (e) => {
      await console.error(e);
      await Show_ExceptionMsg(e, dispatch);
      return;
    });
    if (res) return await validate_response(res, dispatch);
  } catch (e) {
    await Show_Message(dispatch, e.message, "error");
  }
};
// API UPDATE Method
export const API_PUT = async (parem, dispatch) => {
  try {
    const res = await Axios.post(`${parem}`).catch(async (e) => {
      await console.error(e);
      await Show_ExceptionMsg(e, dispatch);
      return;
    });
    if (res) return await validate_response(res, dispatch);
  } catch (e) {
    await Show_Message(dispatch, e.message, "error");
  }
};
// API DELETE Method
export const API_DELETE = async (parem, dispatch) => {
  try {
    const res = await Axios.delete(`${parem}`).catch(async (e) => {
      await console.error(e);
      await Show_ExceptionMsg(e, dispatch);
      return;
    });
    if (res) return await validate_response(res, dispatch);
  } catch (e) {
    await Show_Message(dispatch, e.message, "error");
  }
};
// Validate API Response
const validate_response = async (res, dispatch) => {
  let status = res.data.strStatus.toLowerCase();
  status = status === "validation" ? "info" : status;
  if (status === "error" || status === "info") {
    await Show_Message(dispatch, res.data.strMessage, status);
    return;
  }
  return res.data;
};
// Show Exception Message
const Show_ExceptionMsg = async (res, dispatch) => {
  if (res.response) {
    if (res.response.status === 400) {
      await Show_Message(
        dispatch,
        "Please Verify the Values Data Type",
        "info"
      );
      return;
    } else if (res.response.status === 404) {
      await Show_Message(
        dispatch,
        "Your Requested Method is Not Found ",
        "info"
      );
      return;
    } else if (res.response.status === 405) {
      await Show_Message(
        dispatch,
        "Please Check the Request Method Type",
        "info"
      );
      return;
    } else {
      await Show_Message(dispatch, res.message, "error");
      return;
    }
  } else {
    await Show_Message(dispatch, res.message, "error");
    return;
  }
};
// Financial Year Validation
export const Validate_Financialyear = async (NxtFY, dispatch) => {
  try {
    var currentDate = new Date().toISOString().split("T")[0];
    const x = new Date(currentDate);
    const y = new Date(NxtFY);
    if (y < x) {
      dispatch(Update_AdminCntrl(true));
    }
  } catch (e) {
    await Show_Message(dispatch, e.message, "error");
  }
};
//Logout User When Session if Expired
export const Logout_User = async () => {
  const _LocalParem = await DecryptData(localStorage.jbctrlparams);
  let IPConfig = {};
  IPConfig.iUserId = Number(_LocalParem.User);
  await Axios.post(`Admin/Logout_User`, IPConfig);
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/";
  window.location.reload();
};
export const Backup_Data = async (dispatch, Client, Org) => {
  var link = false;
  try {
    await Pre_Loading(dispatch, true);
    await fetch(`${Domain}Admin/Backup_Data/${Client}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.jbparetkau}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const url = window.URL.createObjectURL(res);
        link = document.createElement("a");
        link.href = url;
        const now = moment();
        const curr_date = now.format("D-MMM-YYYY");
        const FileName = `${Org}_${curr_date}.zip`;
        link.setAttribute("download", FileName);
      })
      .catch((err) => {
        Show_Message(dispatch, err.stack, "error");
      });
  } catch (err) {
    Show_Message(dispatch, err.message, "error");
  } finally {
    await Pre_Loading(dispatch, false);
    return link;
  }
};
export const View_ScreenName = async (ScrId) => {
  switch (ScrId) {
    case 1:
      return "Product Master";
    case 2:
      return "Customer Master";
    case 3:
      return "Supplier Master";
    case 4:
      return "Category Master";
    case 5:
      return "OtherHead Master";
    case 6:
      return "Tax Master";
    case 7:
      return "Purchase Enquiry";
    case 8:
      return "Purchase Quotation";
    case 9:
      return "Purchase Order";
    case 10:
      return "Purchase Challan";
    case 11:
      return "Purchase Invoice";
    case 12:
      return "Purchase Return";
    case 13:
      return "Sales Enquiry";
    case 14:
      return "Sales Quotation";
    case 15:
      return "Sales Order";
    case 16:
      return "Sales Challan";
    case 17:
      return "Sales Invoice";
    case 18:
      return "Sales Return";
    case 29:
      return "BankAccount";
    case 32:
      return "Print Design";
    case 152:
      return "Print Line Design";
    case 37:
      return "OpenStock";
    case 38:
      return "Stock Adjustment";
    case 41:
      return "Payment";
    case 42:
      return "Receipt";
    case 43:
      return "Debit Note";
    case 44:
      return "Credit Note";
    case 50:
      return "Vendor Master";
    case 51:
      return "JobWork Master";
    case 59:
      return "Service Master";
    case 60:
      return "Attribute";
    case 61:
      return "Attribute Group";
    case 62:
      return "Price Master";
    case 63:
      return "Stock Transfer";
    case 64:
      return "Unit Master ";
    case 66:
      return "Expense Income";
    case 67:
      return "Sales Estimate";
    case 68:
      return "Bill Of Material";
    case 69:
      return "Route Group";
    case 70:
      return "Production Order";
    case 71:
      return "Inward Challan";
    case 72:
      return "Outward Challan";
    case 73:
      return "Outward Invoice";
    case 74:
      return "Outsource Challan";
    case 75:
      return "Insource Challan";
    case 76:
      return "Insource Invoice";
    case 77:
      return "Transaction Adjustment";
    case 78:
      return "Purchase Estimate";
    case 79:
      return "JournalEntry";
    case 80:
      return "Price Group Master";
    case 83:
      return "Production Forcast";
    case 86:
      return "General Reports";
    case 87:
      return "Accounts Reports";
    case 93:
      return "Point of Sales";
    case 96:
      return "Return";
    case 98:
      return "Discount Master";
    case 100:
      return "Manual Invoice";
    case 101:
      return "Doctor Master";
    case 102:
      return "Appoinment Booking";
    case 103:
      return "Doctor Session";
    case 104:
      return "Stock Receive";
    case 105:
      return "Stock Trans Adjustment";
    case 120:
      return "Barcode Generator";
    case 130:
      return "Receipt Settlement";
    case 131:
      return "Payment Settlement";
    case 151:
      return "E-Way Billing";
    case "0":
      sessionStorage.removeItem("maxTab");
      return "Configuration";
    default:
      sessionStorage.removeItem("maxTab");
      let _AuthParems = await DecryptData(localStorage.jbctrlparams);
      return _AuthParems.Branchname;
  }
};
