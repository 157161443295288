import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Clear_RemarkParems,
  Input_Onchange,
} from "../features/Transactionstates";
function Notes(props) {
  const dispatch = useDispatch();
  const _HT = useSelector((store) => store.Transstates.Header);

  useEffect(() => {
    short_cut_keypress();
  }, []);

  const short_cut_keypress = () => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        props.Close();
      }
    });
  };
  const Input_ValueChange = async (e) => {
    await dispatch(
      Input_Onchange({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Notes </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body mb-2">
          <div className="row p-0">
            <div className="col-sm-12 col-md-12">
              <label>Note 1</label>
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Message "
                name="strRemark1"
                value={_HT.strRemark1}
                onChange={(e) => Input_ValueChange(e)}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Note 2</label>
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Message "
                name="strRemark2"
                value={_HT.strRemark2}
                onChange={(e) => Input_ValueChange(e)}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Note 3</label>
              <input
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Message "
                name="strRemark3"
                value={_HT.strRemark3}
                onChange={(e) => Input_ValueChange(e)}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Note 4</label>
              <textarea
                type="text"
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Message"
                name="strRemark4"
                value={_HT.strRemark4}
                onChange={(e) => Input_ValueChange(e)}
                style={{ minHeight: "100px" }}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => dispatch(Clear_RemarkParems())}
              tabIndex={12}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notes;
