import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Show_Message } from "../General/Messagedialogbox";
import {
  API_FETCH,
  API_POST,
  DecryptData,
  EncryptData,
} from "../General/Utility";
import { Update_AdminCntrl } from "../features/CommonStates";

function CustomizeScreen() {
  const _Config = useSelector((state) => state.Config.Config);
  const _Auth = useSelector((state) => state.Common.Admin);
  const dispatch = useDispatch();
  const [TabName, setTabName] = useState("Order");
  const [arrEntData, setarrEntData] = useState([]);
  const [arrTabBtnshow, setarrTabBtnshow] = useState([true, false, false]);
  const [Tab, setTab] = useState(0);

  useEffect(() => {
    tab_click(0, "Field");
  }, [_Config.value]);
  const TabChecked = (Value, RowInfo) => {
    let ColumList = arrEntData;
    ColumList.map((Data, Index) => {
      if (Data.iCtrlId === RowInfo.iCtrlId) {
        Data.isTabStop = Value;
      }
      return Data;
    });
  };
  const VisibleChecked = (Value, RowInfo) => {
    let ColumList = arrEntData;
    ColumList.map((Data, Index) => {
      if (Data.iCtrlId === RowInfo.iCtrlId) {
        Data.isValidate = Value;
      }
      return Data;
    });
  };
  const TabOrder_OnChange = (Value, RowInfo) => {
    let ColumList = arrEntData;
    ColumList.map((Data, Index) => {
      if (Data.iCtrlId === RowInfo.iCtrlId) {
        Data.iTabIndex = Number(Value);
      }
      return Data;
    });
  };
  const ColumnValue_Onchange = (Value, RowInfo, ValFor) => {
    let ColumList = arrEntData;
    switch (ValFor) {
      case "Show":
        ColumList.map((Data, Index) => {
          if (Data.columnName === RowInfo.columnName) {
            Data.isShowInScr = Value;
          }
          return Data;
        });
        break;
      case "ReadOnly":
        ColumList.map((Data, Index) => {
          if (Data.columnName === RowInfo.columnName) {
            Data.readOnly = Value;
          }
          return Data;
        });
        break;
      case "AliasName":
        ColumList.map((Data, Index) => {
          if (Data.columnName === RowInfo.columnName) {
            Data.aliasName = Value;
          }
          return Data;
        });
        break;
      case "InOrder":
        ColumList.map((Data, Index) => {
          if (Data.columnName === RowInfo.columnName) {
            Data.inOrderTO = Value;
          }
          return Data;
        });
        break;
      case "Width":
        ColumList.map((Data, Index) => {
          if (Data.columnName === RowInfo.columnName) {
            Data.colLen = Value;
          }
          return Data;
        });
        break;
      case "TextAlign":
        ColumList.map((Data, Index) => {
          if (Data.columnName === RowInfo.columnName) {
            Data.textAlign = Value;
          }
          return Data;
        });
        break;
      case "WordWrap":
        ColumList.map((Data, Index) => {
          if (Data.isWordwrap === RowInfo.columnName) {
            Data.readOnly = Value;
          }
          return Data;
        });
        break;
      default:
        break;
    }
    setarrEntData(ColumList);
  };
  const ClearClick = () => {
    setarrEntData([]);
    Tab_OnChange(TabName);
  };
  const SaveClick = async () => {
    try {
      if (TabName === "Field") {
        SaveFieldSetting();
      } else {
        const UpdateSetting = {
          objEntBTDT: arrEntData,
          objEntTSDT: arrEntData,
          strTag: TabName,
          iScrId: _Config.value,
          strDBName: _Auth.DB,
        };
        const res = await API_POST(
          "Home/Update_DefaultProperties",
          UpdateSetting,
          dispatch
        );
        if (res) {
          if (TabName === "Order") {
            const AuthParems = await DecryptData(localStorage.jbctrlparams);
            AuthParems.TabOrder = JSON.stringify(res.objProperties);
            localStorage.jbctrlparams = await EncryptData(AuthParems);
          }
          ClearClick();
          Show_Message(dispatch, res.strMessage, "success");
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const SaveFieldSetting = async () => {
    try {
      const res = await API_POST(
        "Home/Update_CustomFieldSetting",
        {
          objEntPerm: arrEntData,
          strDBName: _Auth.DB,
        },
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Tab_OnChange = async (TabName) => {
    setTabName(TabName);
    try {
      if (Number(_Config.value) > 0) {
        const AuthParems = await DecryptData(localStorage.jbctrlparams);
        await dispatch(Update_AdminCntrl(AuthParems));
        const res = await API_FETCH(
          `Home/Load_DefaultProperties/${_Config.value}/${TabName}/${AuthParems.DB}`,
          dispatch
        );
        if (res) {
          setarrEntData(res.EntProperties);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const FieldShow_Checked = (value, fieldInfo) => {
    let ColumList = arrEntData;
    ColumList.map((Data, Index) => {
      if (Data.iFieldId === fieldInfo.iFieldId) {
        Data.bIsVissible = value;
      }
      return Data;
    });
    setarrEntData(ColumList);
  };
  const FieldName_Change = (value, fieldInfo) => {
    let ColumList = arrEntData;
    ColumList.map((Data, Index) => {
      if (Data.iFieldId === fieldInfo.iFieldId) {
        Data.strFieldName = value;
      }
      return Data;
    });
    setarrEntData(ColumList);
  };
  const Field = [
    {
      Header: "Field Name",
      style: { justifyContent: "left" },
      accessor: "",
      cell: ({ rowinfo }) => (
        <input
          className="form-control"
          type="text"
          defaultValue={rowinfo.strFieldName}
          spellCheck={false}
          onChange={(e) => FieldName_Change(e.target.value, rowinfo)}
        />
      ),
    },

    {
      Header: "Show",
      accessor: "",
      style: { justifyContent: "center" },
      maxWidth: 50,
      minWidth: 50,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.bIsVissible}
          className="form-check-input"
          onChange={(e) => FieldShow_Checked(e.target.checked, rowinfo)}
        />
      ),
    },
  ];
  const Validation = [
    {
      Header: "SNo",
      accessor: "iSNo",
      maxWidth: 50,
      minWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Field Name",
      id: "iCtrlId",
      accessor: "strCtrlName",
      style: { justifyContent: "left" },
    },
    {
      Header: "In Order",
      accessor: "",
      maxWidth: 100,
      minWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.iTabIndex}
          onClick={(e) => e.target.select()}
          onBlur={(e) => TabOrder_OnChange(e.target.value, rowinfo)}
        />
      ),
    },
    {
      Header: "Show",
      accessor: "",
      maxWidth: 70,
      minWidth: 70,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isTabStop}
          className="form-check-input"
          onChange={(e) => TabChecked(e.target.checked, rowinfo)}
        />
      ),
    },
    {
      Header: "Validation",
      accessor: "",
      maxWidth: 70,
      minWidth: 70,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isValidate}
          className="form-check-input"
          onChange={(e) => VisibleChecked(e.target.checked, rowinfo)}
        />
      ),
    },
  ];
  const Column = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
      minWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Column Name",
      accessor: "columnName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Display Name",
      accessor: "",
      minWidth: 200,
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.aliasName}
          onClick={(e) => e.target.select()}
          onChange={(e) =>
            ColumnValue_Onchange(e.target.value, rowinfo, "AliasName")
          }
        />
      ),
    },
    {
      Header: "Data Type",
      accessor: "datatype",
      minWidth: 100,
    },

    {
      Header: "In Order",
      accessor: "",
      minWidth: 70,
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.inOrderTO}
          onClick={(e) => e.target.select()}
          onChange={(e) =>
            ColumnValue_Onchange(e.target.value, rowinfo, "InOrder")
          }
        />
      ),
    },
    {
      Header: "Width",
      accessor: "",
      minWidth: 70,
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.colLen}
          onClick={(e) => e.target.select()}
          onChange={(e) =>
            ColumnValue_Onchange(e.target.value, rowinfo, "Width")
          }
        />
      ),
    },
    {
      Header: "Text Align",
      accessor: "",
      minWidth: 70,
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.textAlign}
          onClick={(e) => e.target.select()}
          onChange={(e) =>
            ColumnValue_Onchange(e.target.value, rowinfo, "TextAlign")
          }
        />
      ),
    },
    {
      Header: "Word Wrap",
      accessor: "",
      minWidth: 70,
      cell: ({ rowinfo }) => (
        <input
          type="text"
          className="form-control"
          defaultValue={rowinfo.isWordwrap}
          onClick={(e) => e.target.select()}
          onChange={(e) =>
            ColumnValue_Onchange(e.target.value, rowinfo, "WordWrap")
          }
        />
      ),
    },
    {
      Header: "Show",
      accessor: "",
      minWidth: 70,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isShowInScr}
          className="form-check-input"
          onChange={(e) =>
            ColumnValue_Onchange(e.target.checked, rowinfo, "Show")
          }
        />
      ),
    },
    {
      Header: "Read Only",
      accessor: "",
      minWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.readOnly}
          className="form-check-input"
          onChange={(e) =>
            ColumnValue_Onchange(e.target.checked, rowinfo, "ReadOnly")
          }
        />
      ),
    },
  ];
  const tab_click = (tabindex, tabname) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    let EntTab = arrTabBtnshow.map((Dt, Index) => {
      if (Index === tabindex) {
        Dt = true;
      } else {
        Dt = false;
      }
      return Dt;
    });
    setarrTabBtnshow(EntTab);
    setTab(tabindex);
    Tab_OnChange(tabname);
  };
  const View_tableColumns = (Index) => {
    let DT = [];
    switch (Index) {
      case 0:
        DT = Field;
        break;
      case 1:
        DT = Validation;
        break;
      case 2:
        DT = Column;
        break;

      default:
        break;
    }
    return DT;
  };
  return (
    <section className="Config-card">
      <div className="config-h">
        <h3>{_Config.Header}</h3>
      </div>
      <div className="trans-tab">
        <div className="trans-buttons">
          <label onClick={(e) => tab_click(0, "Field")} className="active">
            Custom Field
          </label>
          <label onClick={(e) => tab_click(1, "Order")}>
            Custom Validation
          </label>
          <label onClick={(e) => tab_click(2, "Column")}>Trans Columns</label>
          <div id="tab-line"></div>
        </div>
      </div>
      <div className="Config-Wrapper">
        <ReactTable
          columns={View_tableColumns(Tab)}
          data={arrEntData}
          minRows={10}
          row_click={(ProInfo, Index) => {}}
          row_doubleclick={(ProInfo, Index) => {}}
          background={true}
          className="auto-y"
        />
      </div>
      <div className="btn-section">
        <button className="btn-fabgreen" onClick={SaveClick}>
          Save &nbsp;<i className="bx bxs-save"></i>
        </button>
        <button className="btn-fabgreen" onClick={ClearClick}>
          Clear&nbsp;<i className="fas fa-eraser"></i>
        </button>
      </div>
    </section>
  );
}

export default CustomizeScreen;
