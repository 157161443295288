import { useState, useEffect } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_POST } from "../General/Utility";
function WarehouseMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Config = useSelector((state) => state.Config.Config);
  const [iWHId, setiWHId] = useState(0);

  const [strWHName, setstrWHName] = useState("");
  const [strWHType, setstrWHType] = useState("");
  const [iStatus, setiStatus] = useState(1);
  const [strOffNo, setstrOffNo] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strMobileNo, setstrMobileNo] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [iState, setiState] = useState(32);
  const [iCountry, setiCountry] = useState(100);
  const [IsDefault, setIsDefault] = useState(false);
  const [arrWarehouse, setarrWarehouse] = useState([]);
  const [arrCountry, setarrCountry] = useState([]);
  const [arrState, setarrState] = useState([]);
  const [IsEdit, setIsEdit] = useState(false);
  const [IsMultiWH, setIsMultiWH] = useState(true);
  const [arrStatus, setarrStatus] = useState([]);

  useEffect(() => {
    Load_warehouse();
  }, []);
  const Load_warehouse = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const parems = {
        IWHId: 0,
        iUserId: Number(_AuthParems.User),
        iRoleId: Number(_AuthParems.Role),
        iClientId: Number(_AuthParems.Client),
        iBranchId: Number(_AuthParems.Branch),
        strDBName: _AuthParems.DB,
      };
      const res = await API_POST(
        `WarehouseMaster/Load_WarehouseMaster/`,
        parems,
        dispatch
      );
      if (res) {
        if (res.IsMultiWarehouse) {
          setarrWarehouse(res.EntWarehouse);
          setarrCountry(res.EntCountry);
          setarrState(res.EntState);
          setarrStatus(res.EntStatus);
        }
        setIsMultiWH(res.IsMultiWarehouse);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Save_Warehouse = async () => {
    try {
      const InsUpdwarehouse = {
        iWHId: iWHId,
        strWHName: strWHName,
        strWHType: strWHType,
        Address1: strStreet,
        Address2: strStreet,
        Address3: strArea,
        Address4: strCity,
        Pincode: strPinCode,
        State: iState,
        StateName: "",
        Country: iCountry,
        CountryName: "",
        Mobile: strMobileNo,
        Email: strEmail,
        iBranchId: _AuthParems.Branch,
        strDBName: _AuthParems.DB,
        iStatus: iStatus,
        IsDefault: IsDefault,
      };
      const res = await API_POST(
        `WarehouseMaster/InsUpd_Warehouse`,
        InsUpdwarehouse,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Enable_MultiWarehouse = async () => {
    try {
      const parems = {
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
        IsDefault: true,
      };
      const res = await API_POST(
        `WarehouseMaster/Enable_MultiWarehouse`,
        parems,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    }
  };
  const Update_DefaultWarehouse = async (WH) => {
    try {
      const parems = {
        strDBName: _AuthParems.DB,
        iBranchId: Number(_AuthParems.Branch),
        iWHId: Number(WH.iWHId),
      };
      const res = await API_POST(
        `WarehouseMaster/Update_DefaultWarehouse`,
        parems,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    }
  };
  const Delete_Warehouse = async (WH) => {
    try {
      const res = await API_POST(
        `WarehouseMaster/Delete_Warehouse/${Number(WH.iWHId)}/${
          _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    }
  };
  const Clear_Click = () => {
    setiWHId(0);
    setstrWHName("");
    setstrWHType("");
    setiStatus(1);
    setstrOffNo("");
    setstrPinCode("");
    setstrStreet("");
    setstrArea("");
    setstrCity("");
    setstrMobileNo("");
    setstrEmail("");
    setiState(32);
    setiCountry(100);
    setIsDefault(false);
    setarrWarehouse([]);
    setarrCountry([]);
    setarrState([]);
    setarrStatus([]);
    setIsEdit(false);
    setIsMultiWH(true);
    Load_warehouse();
  };
  const View_Warehouse = async (WH) => {
    try {
      setiWHId(WH.iWHId);
      setstrWHName(WH.strWHName);
      setstrWHType(WH.strWHType);
      setiStatus(WH.iStatus);
      setstrOffNo(WH.address1);
      setstrStreet(WH.address2);
      setstrArea(WH.address3);
      setstrCity(WH.address4);
      setstrPinCode(WH.pincode);
      setstrMobileNo(WH.mobile);
      setstrEmail(WH.email);
      setiState(WH.state);
      setIsDefault(WH.isDefault);
      setiCountry(WH.country);
      setIsEdit(true);
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Add_NewWarehouse = () => {
    setiWHId(0);
    setstrWHName("");
    setstrWHType("");
    setiStatus(1);
    setstrOffNo("");
    setstrPinCode("");
    setstrStreet("");
    setstrArea("");
    setstrCity("");
    setstrMobileNo("");
    setstrEmail("");
    setiState(32);
    setiCountry(100);
    setIsDefault(false);
    setarrWarehouse([]);
    setIsEdit(true);
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 1 && ActiveIndex <= 20) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + 19 + "']").focus();
        $("[tabindex='" + 19 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
  };
  return (
    <div className="Config-card">
      <div className="config-h">
        <h3>{_Config.Header}</h3>
        <div className="Config-btn">
          {!IsEdit && (
            <button
              tabIndex={20}
              className="btn-fabgreen"
              onClick={(e) => {
                Ask_Question(
                  dispatch,
                  Add_NewWarehouse,
                  "Do You Want to Create New Warehouse?"
                );
              }}
            >
              Add New&nbsp;<i className="fas fa-save"></i>
            </button>
          )}
        </div>
      </div>
      {!IsMultiWH && (
        <div className="Disable-MultiWh">
          <h5>Multi Warehouse</h5>
          <button
            onClick={(e) => {
              Ask_Question(
                dispatch,
                Enable_MultiWarehouse,
                "Do You Want to Enable Multi Warehouse?"
              );
            }}
          >
            Enable
          </button>
        </div>
      )}
      {IsMultiWH && (
        <div className="Config-Wrapper">
          {!IsEdit && (
            <div className="wh-container">
              {arrWarehouse.map((WH, Index) => (
                <div className="wh-cart" key={Index}>
                  <div className="wh-header">
                    <h5>{WH.strWHName}</h5>
                    <div className="wh-cartbtn">
                      <button onClick={(e) => View_Warehouse(WH)}>Edit</button>
                      <button onClick={(e) => Update_DefaultWarehouse(WH)}>
                        <i className="fa-regular fa-star"></i>
                      </button>
                      <button
                        onClick={(e) => {
                          Ask_Question(
                            dispatch,
                            Delete_Warehouse,
                            "Do You Want to Delete Warehouse?",
                            WH
                          );
                        }}
                      >
                        <i className="bx bx-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                  {WH.isDefault && (
                    <h4 className="primary">
                      <i className="fa-solid fa-star"></i>
                      Primary Warehouse
                    </h4>
                  )}
                  <h6>
                    <i className="fa-solid fa-location-dot mr-2"></i>
                    {`${WH.address1},${WH.address2}`}
                  </h6>
                  <h6>{`${WH.address3},${WH.address4}`}</h6>
                  <h6>{`${WH.stateName},${WH.countryName}-${WH.pincode}`}</h6>
                  <h6 className="wh-contact">
                    <span>
                      <i className="fa-solid fa-phone mr-1"></i>
                      {WH.mobile}
                    </span>
                    <span className="ml-2">
                      <i className="fa-regular fa-envelope mr-1"></i>
                      {WH.email}
                    </span>
                  </h6>
                </div>
              ))}
            </div>
          )}
          {IsEdit && (
            <div className="row p-2">
              <div className="col-md-6 col-sm-6 mt-2">
                <label>WareHouse Name</label>
                <input
                  value={strWHName}
                  placeholder="WareHouse Name"
                  autoComplete="off"
                  spellCheck={false}
                  className="form-control"
                  onChange={(e) => setstrWHName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Door / office No</label>
                <span className="danger">*</span>
                <input
                  onClick={(e) => e.target.select()}
                  type="text"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  spellCheck={false}
                  autoComplete="off"
                  placeholder="Door / office No"
                  tabIndex={2}
                  value={strOffNo}
                  onChange={(e) => setstrOffNo(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Street</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="Street"
                  autoComplete="off"
                  spellCheck={false}
                  tabIndex={8}
                  value={strStreet}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrStreet(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Area/Town</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder=" Area / Town"
                  autoComplete="off"
                  spellCheck={false}
                  tabIndex={9}
                  value={strArea}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setstrArea(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>City</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  className="form-control"
                  placeholder="City "
                  tabIndex={10}
                  autoComplete="off"
                  spellCheck={false}
                  onClick={(e) => e.target.select()}
                  value={strCity}
                  onChange={(e) => setstrCity(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Pin Code</label>
                <input
                  className="form-control"
                  type="number"
                  autoComplete="off"
                  placeholder="Pincode"
                  value={strPinCode}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => {
                    setstrPinCode(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  State <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_parem.isoptionShow}
                  placeholder="Select State"
                  select_value={(val) => setiState(val.iSId)}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iState}
                  displayName="strSName"
                  disvalue="iSId"
                  arrydata={arrState}
                  disabled={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  Country <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_parem.isoptionShow1}
                  placeholder="Select Country"
                  select_value={(emp) => setiCountry(emp.iCountryId)}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iCountry}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={arrCountry}
                  disabled={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Mobile No</label> <span className="danger">*</span>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  onClick={(e) => e.target.select()}
                  type="number"
                  maxLength={12}
                  className="form-control"
                  spellCheck={false}
                  autoComplete="off"
                  placeholder="Mobile No"
                  tabIndex={4}
                  value={strMobileNo}
                  onChange={(e) => setstrMobileNo(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Email</label>
                <input
                  onKeyDown={(e) => TabIndex(e)}
                  type="text"
                  onClick={(e) => e.target.select()}
                  className="form-control"
                  placeholder="Example@gmail.com"
                  tabIndex={6}
                  autoComplete="off"
                  spellCheck={false}
                  value={strEmail}
                  onChange={(e) => setstrEmail(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-2">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_parem.isoptionShow3}
                  placeholder="Select Status"
                  select_value={(val) => setiStatus(val)}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatus}
                  displayName="strStatusName"
                  disvalue="iStatusId"
                  arrydata={arrStatus}
                  disabled={false}
                  EmptVal="Status "
                />
              </div>
            </div>
          )}
        </div>
      )}
      {IsMultiWH && IsEdit && (
        <div className="btn-section">
          <button
            tabIndex={20}
            className="btn-fabgreen"
            onClick={(e) => {
              if (strWHName === "") {
                Show_Message(dispatch, "Please Enter Warehouse Name", "error");
              } else {
                Ask_Question(
                  dispatch,
                  Save_Warehouse,
                  "Do You Want to  Save Warehouse?"
                );
              }
            }}
          >
            Save&nbsp;<i className="fas fa-save"></i>
          </button>
          <button
            tabIndex={22}
            className="btn-fabgreen"
            onClick={(e) => Clear_Click()}
          >
            Close&nbsp;<i className="fas fa-times"></i>
          </button>
        </div>
      )}
    </div>
  );
}

export default WarehouseMaster;
