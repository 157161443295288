import React, { useEffect, useState } from "react";
import Logo from "../Image/JustBill.png";
import JB from "../Image/JB.png";
import StockInventory from "../Image/StockInventory.png";
import Restaurant from "../Image/Restaurant.png";
import Barcode from "../Image/Barcode.jpg";
import JobWork from "../Image/JobWork.png";
import Invoice from "../Image/Invoice.png";
import ExportImport from "../Image/ExportImport.png";
import MobileStore from "../Image/MobileStore.png";
import GarmentsStore from "../Image/GarmentsStore.png";
import Electrical from "../Image/Electrical.png";
import Manufacturing from "../Image/Manufacturing.png";
import Devices from "../Image/Devices.png";
import Experience from "../Image/Experience.png";
import Books from "../Image/Books.png";
import Grocery from "../Image/Grocery.png";
import Retail from "../Image/Retail.png";
import AutoMobile from "../Image/AutoMobile.png";
import Footwear from "../Image/Footwear.png";
import Accounting from "../Image/Accounting.png";
import chrome from "../Image/chrome.png";
import $ from "jquery";

function LandingPage() {
  useEffect(() => {
    var xCount = 0;
    setInterval(() => {
      var SliderMove = document.getElementById("JB_Modules");
      var FirstDiv = SliderMove.querySelectorAll(".mdl-card")[0];
      var FirstDivWidth = FirstDiv.clientWidth + 20;
      if (xCount >= 6) {
        SliderMove.scrollLeft += -FirstDivWidth;
      } else if (xCount < 6) {
        SliderMove.scrollLeft += FirstDivWidth;
      }
      if (xCount === 12) {
        xCount = 0;
      }

      let prevpageX, prevScroLeft;
      SliderMove.addEventListener("load", (e) => {
        e.preventDefault();
        prevpageX = e.pageX;
        prevScroLeft = SliderMove.scrollLeft;
        let PositionDiff = e.pageX - prevpageX;
        SliderMove.scrollLeft = prevScroLeft - PositionDiff;
      });
      xCount += 1;
    }, 1500);
    BarClick();
  }, []);
  const BarClick = () => {
    $("#togglebar").on("click", function () {
      $(this).toggleClass("click");
      $(".Navmenu-item").toggleClass("show");
    });
    $(".Navmenu-item li").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
  };
  return (
    <div className="landing-page">
      <div className="Nav-Menu">
        <img src={Logo} alt="GST Billing Software"></img>
        <ul className="Navmenu-item">
          <li className="active">
            <a href="#Home">Home</a>
            <hr />
          </li>
          <li>
            <a href="#JB_Modules">Modules</a> <hr />
          </li>
          <li>
            <a href="#Features">Features</a> <hr />
          </li>
          <li>
            <a href="#Supported_Buiness">Industries</a> <hr />
          </li>
          <li>
            <a href="#Contact">Contact</a> <hr />
          </li>
          <li>
            <button
              className="btn-login"
              onClick={(e) => (window.location.href = "/User-Login")}
            >
              <i className="fa-solid fa-right-from-bracket mr-2"></i> Sign In
            </button>
          </li>
        </ul>
        <div id="togglebar">
          <span className="fas fa-bars"></span>
        </div>
      </div>
      <div className="page-content">
        <div id="Home" className="page-sec1">
          <div className="cont">
            <h1>POS GST Billing Software</h1>
            <h2>For Small & Medium Businesses</h2>
            <p>
              FabGreen Technologies provides JustBill billing software to a wide
              range of customers.
            </p>
            <p>
              JustBill ensures full security for your data. JustBill is an
              extensive product for any small and medium-sized business.
            </p>
            <p>
              This is specially designed for retail and wholesale traders,
              manufacturers, and job-work customers.
            </p>
            <p>Justbillsoftware can be used online and offline.</p>
          </div>
          <div className="title-img">
            <div className="exp-img">
              <img src={Experience} alt=""></img>
              <label>Since-2013</label>
            </div>
            <div className="billimg">
              <img src={Devices} alt=""></img>
            </div>
          </div>
        </div>
        <h3 className="page-sec2">Modules</h3>
        <div id="JB_Modules" className="page-sec3">
          <div className="mdl-card">
            <h1>Sales/Billing</h1>
            <div className="mdl-cont">
              <h6>Sales Enquiry,Quotation,Ordere</h6>
              <h6>Sales Challan,Invoice,Estimate</h6>
              <h6>Point of Sale (POS)</h6>
              <h6>Shift and cash drawer management</h6>
              <h6>Tender declaration & removal</h6>
              <h6>Z Report,X Report</h6>
              <h6>Email & SMS format for Sales Process</h6>
              <h6>Backup Restore</h6>
              <h6>Year End Process</h6>
            </div>
          </div>
          <div className="mdl-card">
            <h1>Purchase & Inventory</h1>
            <div className="mdl-cont">
              <h6>Purchase Order,Enquiry,Quotation,Order</h6>
              <h6> Purchase Challan,Invoice,Return </h6>
              <h6>Opening Stock/Stock Transfer </h6>
              <h6>Stock Adjustment-Damage, Scrap, Missing</h6>
              <h6>Email & SMS format for Purchase Process</h6>
            </div>
          </div>
          <div className="mdl-card">
            <h1>Job Work</h1>
            <div className="mdl-cont">
              <h6> Jobwork User defined Service master</h6>
              <h6>Vendor master </h6>
              <h6>Job Order/Work Material Inward & Outward </h6>
              <h6>Service Invoice against the Job order </h6>
              <h6>Automatic GST Calculation as per Job Id </h6>
              <h6>Automatic Alerts/Reminders</h6>
              <h6>Option to track status of Job work</h6>
              <h6>Report - Jobber wise / Product wise /</h6>
              <h6>Jobwise ,Vendor wise Jobber performance</h6>
              <h6>Report based on Due date</h6>
              <h6>Quality & Grade</h6>
            </div>
          </div>
          <div className="mdl-card">
            <h1>Accounts</h1>
            <div className="mdl-cont">
              <h6>Payment/Receipt</h6>
              <h6>Debit Note/Credit Note</h6>
              <h6>Expense/Journal / Service Invoice</h6>
              <h6>Ledger Account/Day Book / Trial Balance</h6>
              <h6>Profit and Loss Statement/Balance Sheet</h6>
              <h6>Customer Aging/Supplier Aging</h6>
            </div>
          </div>
          <div className="mdl-card">
            <h1>Master</h1>
            <div className="mdl-cont">
              <h6>Product & Category Master</h6>
              <h6>Customer & Supplier & Service Vendor</h6>
              <h6>Ledger Master & Tax Master</h6>
              <h6>HSN Code</h6>
              <h6>Price Master & Discount Master</h6>
              <h6>Bank Account Master</h6>
              <h6>Email & SMS Format</h6>
            </div>
          </div>
          <div className="mdl-card">
            <h1>Reports</h1>
            <div className="mdl-cont">
              <h6>Category wise Product Report</h6>
              <h6>Product Detail Report</h6>
              <h6>Customer Detail Report</h6>
              <h6>Supplier Detail Report</h6>
              <h6>Ledger Detail Report</h6>
              <h6>On-Hand Report - Daily/Monthly</h6>
              <h6>Category wise Purchase / Sales Reports.</h6>
              <h6>Customer Statement</h6>
              <h6>Open Transaction Statement</h6>
              <h6>GST Detail Report/GST Summary Report </h6>
              <h6>GST B2B/B2CL/B2CS Reports.</h6>
              <h6>GST Tax Detail Report</h6>
            </div>
          </div>
          <div className="mdl-card">
            <h1> Sales & Purchase Reports</h1>
            <div className="mdl-cont">
              <h6>Day sise Details/Day wise summary</h6>
              <h6>Month wise Details/Month wise summary</h6>
              <h6>Monthly Reports</h6>
              <h6>Party wise Detail/Party wise summary </h6>
              <h6>Product wise Detail/Product wise summary</h6>
              <h6>Supplier & Customer Aging</h6>
            </div>
          </div>
          <div className="mdl-card">
            <h1>Tools & Security Management</h1>
            <div className="mdl-cont">
              <h6>User Creation/User Role</h6>
              <h6>Privilege (View, Edit, Export, Delete)</h6>
              <h6>Configuration Settings</h6>
              <h6>Display Settings-Tab Order, Required Fields</h6>
              <h6>Bill No Setting- Number Sequence</h6>
              <h6> Print Format - Laser / Dot matrix</h6>
            </div>
          </div>
          <div className="mdl-card">
            <h1>Organization Management</h1>
            <div className="mdl-cont">
              <h6>Company Master</h6>
              <h6>Multiple Company</h6>
              <h6>Backup & Schedules</h6>
              <h6>Backup Restore</h6>
              <h6>Yearend Process</h6>
            </div>
          </div>
        </div>
        <h3 className="page-sec4">Features of JustBill</h3>
        <div id="Features" className="page-sec5">
          <div className="ft-box">
            <div className="la-img-wrapper">
              <img src={JB} alt="Billing"></img>
            </div>
            <h5>Easy & Fast Billing</h5>
            <p>
              Speed up your entire billing process by using Quick Searches,
              Shortcuts etc.
            </p>
          </div>
          <div className="ft-box">
            <div className="la-img-wrapper">
              <img src={StockInventory} alt="Stock & Inventory"></img>
            </div>
            <h5>Stock & Inventory</h5>
            <p>
              Stock in-out, FIFO keep track of everything & streamline your
              entire inventory with JustBill Software.
            </p>
          </div>
          <div className="ft-box">
            <div className="la-img-wrapper">
              <img src={Invoice} alt="Invoice Customization"></img>
            </div>
            <h5>Invoice Customization</h5>
            <p>
              The fully customizable invoice templates. Customize your invoices
              with company logo, name, brand Terms
            </p>
          </div>
          <div className="ft-box">
            <div className="la-img-wrapper">
              <img src={Barcode} alt="Barcode Scanner & Generator"></img>
            </div>
            <h5> Barcode Scanner & Generator</h5>
            <p>
              bill products instantly by scanning barcodes during billing. Track
              & trace products easily with barcode scanning
            </p>
          </div>
          <div className="ft-box">
            <div className="la-img-wrapper">
              <img src={ExportImport} alt="Import & Export"></img>
            </div>
            <h5>Import & Export</h5>
            <p>
              Import Product,Customer,Supplier,Vendor List from system in Excel
              format and upload directly. Save time with no manual data entry,
              zero errors,
            </p>
          </div>
          <div className="ft-box">
            <div className="la-img-wrapper">
              <img src={Accounting} alt="Easy Accounding"></img>
            </div>
            <h5> Easy Accounting</h5>
            <p>
              Manage your finances & accounts from ‘Billing till Balance Sheet’.
              Get an accurate reflection of your expenses during audits. Stay
              organized & keep track of your spending with JustBill
            </p>
          </div>
        </div>
        <h3 className="page-sec6">Business supported by JustBill</h3>
        <div id="Supported_Buiness" className="page-sec7">
          <div className="sup-img">
            <img src={Retail} alt="Retail Billing Software"></img>
            <label>Retail Stores</label>
          </div>
          <div className="sup-img">
            <img src={Grocery} alt="Super Market Billing Software"></img>
            <label>Grocery & Provisions</label>
          </div>
          <div className="sup-img">
            <img src={Restaurant} alt="Restarunts Billing Software"></img>
            <label>Restaurants</label>
          </div>
          <div className="sup-img">
            <img src={GarmentsStore} alt="Garments Store"></img>
            <label>Lifestyle & Fashion</label>
          </div>
          <div className="sup-img">
            <img src={Footwear} alt="Billing Software For FootWear Shop"></img>
            <label>Apparel & Footwear</label>
          </div>
          <div className="sup-img">
            <img
              src={MobileStore}
              alt="Billing Software for Mobile Sales and Services"
            ></img>
            <label>Mobile & Accessories</label>
          </div>
          <div className="sup-img">
            <img src={Books} alt="Billing Software Fro Stationary Shop"></img>
            <label>Books & Stationery</label>
          </div>
          <div className="sup-img">
            <img src={Electrical} alt=">Electrical & Electronics Stores"></img>
            <label>Electrical & Electronics</label>
          </div>
          <div className="sup-img">
            <img
              src={AutoMobile}
              alt="Billing Software For AutomMobile Service"
            ></img>
            <label>Auto Mobile</label>
          </div>
          <div className="sup-img">
            <img
              src={JobWork}
              alt="Billing Software For JobWork Industries"
            ></img>
            <label>Job Work</label>
          </div>
          <div className="sup-img">
            <img
              src={Manufacturing}
              alt="Billing Software for Manufacturing  Industries"
            ></img>
            <label>Small & Micro level Manufacturing</label>
          </div>
        </div>
        <div id="Contact" className="page-sec8">
          <ul className="footer-cont">
            <li>
              Product
              <ul>
                <li>
                  <a href="##">Retail Billing Software</a>
                </li>
                <li>
                  <a href="##">JobWork Management</a>{" "}
                </li>
                <li>
                  <a href="##">Accounding Software</a>{" "}
                </li>
                <li>
                  <a href="##">Inventory Management</a>{" "}
                </li>
              </ul>
            </li>
            <li>
              Careers
              <ul>
                <li>
                  <a href="##">Office Admin</a>
                </li>
                <li>
                  <a href="##">Marketing Executive</a>
                </li>
                <li>
                  <a href="##">Web Developer</a>
                </li>{" "}
                <li>
                  <a href="##">Software Developer</a>
                </li>
              </ul>
            </li>
            <li>
              <h1>FabGreen Technologies</h1>
              <p>
                <i className="fa-solid fa-location-dot mr-2"></i> 44, 5th
                Street,Anna Nagar Linganoor,
              </p>
              <p>Vadavalli(PO),Coimbatore,Tamil Nadu</p>
              <p>India-641041.</p>
              <p>
                <i className="fa-solid fa-envelope-open mr-2"></i>
                info@fabgreentechnologies.com
              </p>
              <p>
                <i className="fa-solid fa-phone mr-2"></i>+91-96293
                25653,+91-82202 85653
              </p>
              <div className="Social-Icon">
                <div className="PTip">
                  <a
                    className="icon-btn"
                    href="https://www.facebook.com/FabGreenTechnologies?mibextid=ZbWKwL"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                  <div className="tooltiptext">facebook</div>
                </div>
                <div className="PTip">
                  <a
                    className="icon-btn"
                    href="https://instagram.com/fabgreentechnologies?igshid=YmMyMTA2M2Y="
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <div className="tooltiptext">instagram</div>
                </div>
                <div className="PTip">
                  <a
                    className="icon-btn"
                    href="https://www.youtube.com/@fabgreentechnologies9437 "
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                  <div className="tooltiptext">youtube</div>
                </div>
                <div className="PTip">
                  <a
                    className="icon-btn"
                    href="https://www.linkedin.com/company/fabgreentechnologies"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                  <div className="tooltiptext">linked In</div>
                </div>
                <div className="PTip">
                  <a
                    className="icon-btn"
                    href="https://www.fabgreentechnologies.com"
                  >
                    <img src={chrome} alt="JustBill Biling Software"></img>
                  </a>
                  <div className="tooltiptext">Web</div>
                </div>
              </div>
            </li>
          </ul>
          <div className="Copy-rights">
            © 2023, FabGreen Technologies. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
