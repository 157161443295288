import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import Messagedialogbox, {
  Ask_Question,
  Show_Message,
} from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import {
  API_DELETE,
  API_FETCH,
  API_POST,
  DecryptData,
} from "../General/Utility";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import Button from "../Master/MasterButton";
function OrderBooking() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((state) => state.Common.Admin);
  const _ScrId = 102;
  const [AppmentId, setAppmentId] = useState(0);
  const [NumSeqNo, setNumSeqNo] = useState("");
  const [Prefix, setPrefix] = useState("");
  const [TransNo, setTransNo] = useState("");
  const [ManualCode, setManualCode] = useState(true);
  const [IsVouchnoSett, setIsVouchnoSett] = useState(true);

  const [SessionId, setSessionId] = useState(0);
  const [DocId, setDocId] = useState(0);
  const [AppmentType, setAppmentType] = useState(1);
  const [AppmentNo, setAppmentNo] = useState("");
  const [AppmentDate, setAppmentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [PatientName, setPatientName] = useState("");
  const [Gender, setGender] = useState(1);
  const [Address, setAddress] = useState("");
  const [DOB, setDOB] = useState(new Date().toISOString().split("T")[0]);
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [EmergencyContact, setEmergencyContact] = useState("");
  const [Notes, setNotes] = useState("");
  const [Reason, setReason] = useState("");
  const [Status, setStatus] = useState(1);

  const [dpFromDate, setdpFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dpToDate, setdpToDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [arrEntStatus, setarrEntStatus] = useState([]);
  const [arrEntDoctor, setarrEntDoctor] = useState([]);
  const [arrEntAppoinment, setarrEntAppoinment] = useState([]);
  const [arrAppmentDesing, setarrAppmentDesing] = useState([]);
  const [arrEntProperties, setarrEntProperties] = useState([]);
  const [arrEntSession, setarrEntSession] = useState([]);
  const [arrGender, setarrGender] = useState([]);
  const [arrAppnmtType, setarrAppnmtType] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [arrEntFields, setarrEntFields] = useState([
    { ColName: "AppmentNo", Name: "Appoinment No", isShow: true },
    { ColName: "AppmentDate", Name: "Appoinment Date", isShow: true },
    { ColName: "DoctorName", Name: "Doctor Name", isShow: true },
    { ColName: "Session", Name: "Session", isShow: true },
    { ColName: "AppmentType", Name: "Appoinment Type", isShow: true },
    { ColName: "PatientName", Name: "Patient Name", isShow: true },
    { ColName: "Gender", Name: "Gender", isShow: true },
    { ColName: "DOB", Name: "Date of Birth", isShow: true },
    { ColName: "Address", Name: "Address", isShow: true },
    { ColName: "Mobile", Name: "Mobile", isShow: true },
    { ColName: "EmergencyContact", Name: "Emergency Contact", isShow: true },
    { ColName: "Email", Name: "Email", isShow: true },
    { ColName: "Notes", Name: "Notes", isShow: true },
    { ColName: "Reason", Name: "Reason", isShow: true },
    { ColName: "Status", Name: "Status", isShow: true },
  ]);
  const [arrTabOrder, setarrTabOrder] = useState({
    txtAppmentNo: "",
    dpAppmentDate: "",
    cbDoctor: "",
    cbSession: "",
    cbAppmentType: "",
    txtPatientName: "",
    txtAddress: "",
    txtDOB: "",
    txtMobile: "",
    txtEmail: "",
    txtEmergencyContact: "",
    txtNotes: "",
    txtReason: "",
    cbGender: "",
    cbStatus: "",
  });
  const [maxTab, setmaxTab] = useState(0);
  const [isExportImport, setisExportImport] = useState(false);
  const [isNewAppoinment, setisNewAppoinment] = useState(false);

  useEffect(() => {
    Load_Appoinment(isNewAppoinment);
  }, []);

  const Load_Appoinment = async (NewAppmnt) => {
    try {
      const AuthParems = await DecryptData(localStorage.jbctrlparams);
      const parems =
        await `AppoinmentBooking/Load_Appoinment/${_ScrId}/${Number(
          AuthParems.Branch
        )}/${NewAppmnt}/${AuthParems.DB}`;
      const res = await API_FETCH(parems, dispatch);
      if (res) {
        if (NewAppmnt) {
          const NoFormat = res.NoFormat[0];
          setarrEntStatus(res.EntStatus);
          setarrEntProperties(res.EntProperties);
          setarrEntDoctor(res.EntDocSess._Doctor);
          setarrEntSession(res.EntDocSess._DoctorSession);
          setarrGender(res.EntGender);
          setarrAppnmtType(res.EntAppType);
          setNumSeqNo(NoFormat.iNumSeqNo);
          setPrefix(NoFormat.strPrefix);
          setManualCode(!NoFormat.isManualNo);
          setAppmentNo(NoFormat.strUserNo);
          setTransNo(NoFormat.strTransNo);
          await Assign_TabOrder(res.EntProperties);
          await Assign_FieldName(res.EntFields);
        } else {
          res.EntTransPer = res.EntTransPer.filter((dt) => dt.isShowInScr);
          setarrAppmentDesing(res.EntTransPer);
          setarrEntAppoinment(res.EntAppoinment);
        }
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Save_Appoinment = async () => {
    try {
      const objAppoinment = {
        AppmentId: AppmentId,
        SessionId: SessionId,
        DocId: DocId,
        AppmentType: AppmentType,
        AppmentNo: AppmentNo,
        AppmentDate: AppmentDate,
        TransNo: TransNo,
        NumSeqNo: NumSeqNo,
        Prefix: Prefix,
        Status: Status,
        PatientName: PatientName,
        Gender: Gender,
        Address: Address,
        DOB: DOB,
        Mobile: Mobile,
        Email: Email,
        EmergencyContact: EmergencyContact,
        Notes: Notes,
        Reason: Reason,
        BranchId: Number(_Common.Branch),
        strDBName: _Common.DB,
      };
      const res = await API_POST(
        `AppoinmentBooking/InsUpd_Appoinment`,
        objAppoinment,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const View_Appoinment = async (doc) => {
    try {
      const res = await API_FETCH(
        `AppoinmentBooking/View_Appoinment/${doc.appmentId}/${_Common.DB}`,
        dispatch
      );
      if (res) {
        setisNewAppoinment(true);
        const _AppMent = res.EntAppoinment;
        setDocId(_AppMent.docId);
        setNumSeqNo(_AppMent.numSeqNo);
        setPrefix(_AppMent.prefix);
        setAppmentNo(_AppMent.appmentNo);
        setTransNo(_AppMent.transNo);
        setManualCode(true);
        setAppmentId(_AppMent.appmentId);
        setSessionId(_AppMent.sessionId);
        setAppmentType(_AppMent.appmentType);
        setAppmentDate(_AppMent.appmentDate);
        setPatientName(_AppMent.patientName);
        setGender(_AppMent.gender);
        setAddress(_AppMent.address);
        setDOB(_AppMent.dob);
        setMobile(_AppMent.mobile);
        setEmail(_AppMent.email);
        setEmergencyContact(_AppMent.emergencyContact);
        setNotes(_AppMent.notes);
        setReason(_AppMent.reason);
        setStatus(_AppMent.status);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Delete_Appoinment = async () => {
    try {
      const res = await API_DELETE(
        `AppoinmentBooking/Delete_Appoinment/${Number(AppmentId)}/${
          _Common.DB
        }`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  const Clear_Click = () => {
    setAppmentId(0);
    setNumSeqNo("");
    setPrefix("");
    setTransNo("");
    setManualCode(false);
    setIsVouchnoSett(false);
    setSessionId(0);
    setDocId(0);
    setAppmentType(1);
    setAppmentNo("");
    setAppmentDate(new Date().toISOString().split("T")[0]);
    setPatientName("");
    setGender(1);
    setAddress("");
    setDOB(new Date().toISOString().split("T")[0]);
    setMobile("");
    setEmail("");
    setEmergencyContact("");
    setNotes("");
    setReason("");
    setStatus(1);
    setarrEntStatus([]);
    setarrEntDoctor([]);
    setarrEntAppoinment([]);
    setarrAppmentDesing([]);
    setarrEntProperties([]);
    setarrEntSession([]);
    setarrGender([]);
    setarrAppnmtType([]);
    setSortBy(null);
    setSortOrder("asc");
    setmaxTab(8);
    Load_Appoinment(isNewAppoinment);
  };
  const Assign_TabOrder = async (EntProperties) => {
    for (var i = 0; i < EntProperties.length; i++) {
      arrTabOrder[EntProperties[i].strCtrlName] = EntProperties[i].iTabIndex;
    }
    var TotalTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    );
    setarrTabOrder(arrTabOrder);
    setmaxTab(TotalTab.iTabIndex);
  };
  const Assign_FieldName = async (EntFields) => {
    let EntPro = arrEntFields;
    EntFields.forEach((dt) => {
      EntPro.forEach((Data) => {
        if (Data.ColName === dt.strColName) {
          Data.Name = dt.strFieldName;
          Data.isShow = dt.bIsVissible;
        }
      });
    });
    setarrEntFields(EntPro);
  };
  const Key_Down = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === arrTabOrder.listDoc) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const Properties_Validation = async () => {
    var ErrorMsg = true;
    let ValidateItem = arrEntProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "cbDoctor":
          ErrorMsg = Number(DocId) === 0 ? false : true;
          break;
        case "cbAppmentType":
          ErrorMsg = Number(AppmentType) === 0 ? false : true;
          break;
        case "txtAppmentNo":
          ErrorMsg = AppmentNo.length === 0 ? false : true;
          break;
        case "dpAppmentDate":
          ErrorMsg = AppmentDate.length === 0 ? false : true;
          break;
        case "txtPatientName":
          ErrorMsg = PatientName.length === 0 ? false : true;
          break;
        case "txtAddress":
          ErrorMsg = Address.length === 0 ? false : true;
          break;
        case "txtDOB":
          ErrorMsg = DOB.length === 0 ? false : true;
          break;
        case "txtMobile":
          ErrorMsg = Mobile.length === 0 ? false : true;
          break;
        case "txtEmail":
          ErrorMsg = Email.length === 0 ? false : true;
          break;
        case "txtEmergencyContact":
          ErrorMsg = EmergencyContact.length === 0 ? false : true;
          break;
        case "txtNotes":
          ErrorMsg = Notes.length === 0 ? false : true;
          break;
        case "txtReason":
          ErrorMsg = Reason.length === 0 ? false : true;
          break;
        case "cbSession":
          ErrorMsg = Number(SessionId) === 0 ? false : true;
          break;
        case "cbGender":
          ErrorMsg = Number(Gender) === 0 ? false : true;
          break;
        case "cbStatus":
          ErrorMsg = Number(Status) === 0 ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const Sort_Report = (key) => {
    if (sortBy === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };
  const Sorted_Report = sortBy
    ? arrEntAppoinment.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortOrder === "asc" ? -1 : 1;
        if (a[sortBy] > b[sortBy]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      })
    : arrEntAppoinment;
  const textAlign = ["left", "center", "right"];

  const converttodecimal = (Value, type) => {
    if (type === "decimal") Value = Number(Value).toFixed(2);
    return Value;
  };
  const Date_onchange = async (val, date) => {
    try {
      let Fromdate = dpFromDate;
      let Todate = dpToDate;
      switch (val) {
        case "From":
          setdpFromDate(date);
          Fromdate = date;
          break;
        case "To":
          setdpToDate(date);
          Todate = date;
          break;
        default:
          break;
      }
      const parems =
        await `AppoinmentBooking/Search_Appoinment/${Fromdate}/${Todate}/${Number(
          _Common.Branch
        )}/${_Common.DB}`;
      const res = await API_FETCH(parems, dispatch);
      if (res) {
        setarrEntAppoinment(res.EntAppoinment);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    }
  };
  return (
    <>
      <SideMenu ScrId={_ScrId} />
      <div className="Trans-container">
        {!isNewAppoinment && (
          <div className="Trans-card">
            <div className="Trans-wrapper">
              <div className="order-ht">
                <div className="col-sm-12 col-md-3">
                  <input
                    type="date"
                    className="form-control"
                    value={dpFromDate}
                    onChange={(e) => Date_onchange("From", e.target.value)}
                  />
                </div>
                <div className="col-sm-12 col-md-3">
                  <input
                    type="date"
                    className="form-control"
                    value={dpToDate}
                    onChange={(e) => Date_onchange("To", e.target.value)}
                  />
                </div>
                <button
                  className="btn-fabgreen"
                  onClick={async () => {
                    if (isNewAppoinment === false) await Load_Appoinment(true);
                    await setisNewAppoinment(!isNewAppoinment);
                  }}
                >
                  <i className="bx bx-plus-medical"></i>&nbsp;New Appoinment
                </button>
              </div>
              <div className="Appmnt-Booking">
                <table id="ordertable">
                  <thead>
                    <tr>
                      {arrAppmentDesing.map((design, Index) => (
                        <th
                          key={Index}
                          onClick={(e) => {
                            Sort_Report(design.columnName);
                            $(`#ordertable th:eq(${Index})`)
                              .toggleClass("active")
                              .siblings()
                              .removeClass("active");
                          }}
                        >
                          {design.aliasName}
                        </th>
                      ))}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Sorted_Report.map((appmnt, trIndex) => (
                      <tr key={trIndex}>
                        {arrAppmentDesing.map((design, tdindex) => (
                          <td
                            key={tdindex}
                            style={{
                              textAlign: textAlign[design.textAlign - 1],
                              minWidth: design.colLen + "px",
                            }}
                          >
                            {converttodecimal(
                              appmnt[design.columnName],
                              design.datatype
                            )}
                          </td>
                        ))}
                        <td
                          onClick={(e) => View_Appoinment(appmnt)}
                          style={{ textAlign: "center" }}
                        >
                          <i className="fa-regular fa-pen-to-square"></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Messagedialogbox />
            </div>
          </div>
        )}
        {isNewAppoinment && (
          <div className="Trans-card">
            <div className="Trans-wrapper">
              <div className="row">
                <div className="col-sm-12 col-md-3 mb-2">
                  <label>{arrEntFields[0].Name}</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Appoinment No"
                      spellCheck={false}
                      autoComplete="off"
                      value={AppmentNo}
                      onChange={(e) => setAppmentNo(e.target.value)}
                      disabled={ManualCode}
                      tabIndex={arrTabOrder.txtAppmentNo}
                    />
                    <span
                      className="inv-no"
                      onClick={(e) => setIsVouchnoSett(!IsVouchnoSett)}
                    >
                      <i className="bx bx-cog"></i>
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 mb-2">
                  <label>{arrEntFields[1].Name}</label>
                  <input
                    type="date"
                    className="form-control"
                    value={AppmentDate}
                    onChange={(e) => setAppmentDate(e.target.value)}
                    tabIndex={arrTabOrder.dpAppmentDate}
                  />
                </div>
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>
                    {arrEntFields[2].Name} <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_selecrparem.isoptionShow1}
                    placeholder="Select Doctor"
                    tabIndex={arrTabOrder.cbDoctor}
                    select_value={(val) => setDocId(val)}
                    btnname="Doctor"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={DocId}
                    displayName="docName"
                    disvalue="docId"
                    arrydata={arrEntDoctor}
                    disabled={false}
                    EmptVal="Doctor"
                  />
                </div>
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>
                    {arrEntFields[3].Name} <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_selecrparem.isoptionShow2}
                    placeholder="Select Session"
                    tabIndex={arrTabOrder.cbSession}
                    select_value={(val) => setSessionId(val)}
                    btnname="Session"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={SessionId}
                    displayName="session"
                    disvalue="sessionId"
                    arrydata={arrEntSession}
                    disabled={false}
                    EmptVal="Session"
                  />
                </div>
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>
                    {arrEntFields[4].Name} <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow3"
                    showoption={_selecrparem.isoptionShow3}
                    placeholder="Select Appment Type"
                    tabIndex={arrTabOrder.cbAppmentType}
                    select_value={(val) => setAppmentType(val)}
                    btnname="Appment Type"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={AppmentType}
                    displayName="appmntName"
                    disvalue="appmntTypeId"
                    arrydata={arrAppnmtType}
                    disabled={false}
                    EmptVal="Appment Type"
                  />
                </div>
                <div className="col-sm-12 col-md-3 mb-2">
                  <label>{arrEntFields[5].Name}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Patient Name"
                    spellCheck={false}
                    autoComplete="off"
                    value={PatientName}
                    onChange={(e) => setPatientName(e.target.value)}
                    tabIndex={arrTabOrder.txtPatientName}
                  />
                </div>
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>
                    {arrEntFields[6].Name} <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_selecrparem.isoptionShow4}
                    placeholder="Select Gender"
                    tabIndex={arrTabOrder.cbGender}
                    select_value={(val) => setGender(val)}
                    btnname="Gender"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={Gender}
                    displayName="strGenderName"
                    disvalue="iGenderId"
                    arrydata={arrGender}
                    disabled={false}
                    EmptVal="Gender"
                  />
                </div>
                {arrEntFields[7].isShow && (
                  <div className="col-sm-12 col-md-3">
                    <label>{arrEntFields[7].Name}</label>
                    <input
                      type="date"
                      className="form-control"
                      value={DOB}
                      onChange={(e) => setDOB(e.target.value)}
                      tabIndex={arrTabOrder.txtDOB}
                    />
                  </div>
                )}
                {arrEntFields[8].isShow && (
                  <div className="col-sm-12 col-md-3">
                    <label>{arrEntFields[8].Name}</label>
                    <textarea
                      style={{ minHeight: "70px" }}
                      className="form-control"
                      placeholder="Enter Patient Address"
                      spellCheck={false}
                      autoComplete="off"
                      value={Address}
                      onChange={(e) => setAddress(e.target.value)}
                      tabIndex={arrTabOrder.txtAddress}
                    />
                  </div>
                )}
                {arrEntFields[9].isShow && (
                  <div className="col-sm-12 col-md-3">
                    <label>{arrEntFields[9].Name}</label>
                    <input
                      type="text"
                      className="form-control"
                      spellCheck={false}
                      autoComplete="off"
                      placeholder="Enter Enter Mobile Number"
                      value={Mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      tabIndex={arrTabOrder.txtMobile}
                    />
                  </div>
                )}
                {arrEntFields[10].isShow && (
                  <div className="col-sm-12 col-md-3">
                    <label>{arrEntFields[10].Name}</label>
                    <input
                      type="text"
                      className="form-control"
                      spellCheck={false}
                      autoComplete="off"
                      placeholder="Enter Enter Emergency Contact Number"
                      value={EmergencyContact}
                      onChange={(e) => setEmergencyContact(e.target.value)}
                      tabIndex={arrTabOrder.txtEmergencyContact}
                    />
                  </div>
                )}
                {arrEntFields[11].isShow && (
                  <div className="col-sm-12 col-md-3">
                    <label>{arrEntFields[11].Name}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      spellCheck={false}
                      autoComplete="off"
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                      tabIndex={arrTabOrder.txtEmail}
                    />
                  </div>
                )}
                {arrEntFields[12].isShow && (
                  <div className="col-sm-12 col-md-3">
                    <label>{arrEntFields[12].Name}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Doctor Note"
                      spellCheck={false}
                      autoComplete="off"
                      value={Notes}
                      onChange={(e) => setNotes(e.target.value)}
                      tabIndex={arrTabOrder.txtNotes}
                    />
                  </div>
                )}
                {arrEntFields[13].isShow && (
                  <div className="col-sm-12 col-md-3">
                    <label>{arrEntFields[13].Name}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Reason For Visit"
                      spellCheck={false}
                      autoComplete="off"
                      value={Reason}
                      onChange={(e) => setReason(e.target.value)}
                      tabIndex={arrTabOrder.txtReason}
                    />
                  </div>
                )}
                {arrEntFields[14].isShow && (
                  <div className="col-md-3 col-sm-6 mb-2">
                    <label>
                      {arrEntFields[14].Name} <span className="danger">*</span>
                    </label>
                    <CustomizedSelectoption
                      optionparem="isoptionShow5"
                      showoption={_selecrparem.isoptionShow5}
                      placeholder="Select Status"
                      tabIndex={arrTabOrder.cbStatus}
                      select_value={(val) => setStatus(val)}
                      btnname="Status"
                      btnshow={false}
                      show_popup={""}
                      valueonly={true}
                      defaultval={Status}
                      displayName="strStatusName"
                      disvalue="iStatusId"
                      arrydata={arrEntStatus}
                      disabled={false}
                      EmptVal="Status"
                    />
                  </div>
                )}
              </div>
            </div>
            <Messagedialogbox />
            <Button
              Save={(e) => {
                if (DocId.length === 0)
                  Show_Message(dispatch, "Please Select Doctor", "info");
                else if (AppmentNo.length === 0)
                  Show_Message(dispatch, "Please Enter Appoinment No", "info");
                else if (Properties_Validation())
                  if (DocId > 0) {
                    Ask_Question(
                      dispatch,
                      Save_Appoinment,
                      "Do You Want to Update Appoinment?"
                    );
                  } else {
                    Ask_Question(
                      dispatch,
                      Save_Appoinment,
                      "Do You Want to Save Appoinment?"
                    );
                  }
              }}
              SaveIndex={arrTabOrder.btnSave}
              DeleteIndex={arrTabOrder.btnDelete}
              CloseIndex={arrTabOrder.btnClose}
              ClearIndex={arrTabOrder.btnClear}
              ExportIndex={arrTabOrder.btnExpImp}
              Export={(e) => setisExportImport(!isExportImport)}
              onKeyDown={(e) => Key_Down(e)}
              Clear={() => Clear_Click()}
              ScrId={_ScrId}
              Iid={AppmentId}
              Delete={(e) => {
                if (Number(AppmentId) === 0) {
                  Show_Message(dispatch, "Please Select Appoinment", "info");
                } else
                  Ask_Question(
                    dispatch,
                    Delete_Appoinment,
                    "Do You Want to Delete Appoinment?"
                  );
              }}
              Close={async () => {
                await Clear_Click();
                setisNewAppoinment(!isNewAppoinment);
                await Load_Appoinment(false);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
export default OrderBooking;
