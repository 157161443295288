import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_POST } from "../General/Utility";
import { Show_Message } from "../General/Messagedialogbox";
import { ShowHide_Popup } from "../features/Transactionstates";
function VouchernoSetting(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.Transstates.Common);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [isAutoNo, setisAutoNo] = useState(false);
  const [strNoFormat, setstrNoFormat] = useState("");
  const [iNextNo, setiNextNo] = useState("");
  const [iMaxDegit, setiMaxDegit] = useState(0);
  const [strInvNo, setstrInvNo] = useState("");
  const [isChanged, setisChanged] = useState(false);

  useEffect(() => {
    Load_VoucherNo();
  }, []);
  useEffect(() => {
    if (isAutoNo) View_VoucherNo(); // Update Invoice No if Auto Generated True
    else {
      setstrInvNo("");
      setstrNoFormat("");
      setiNextNo(0);
      setiMaxDegit(0);
    }
  }, [isAutoNo]);

  const Load_VoucherNo = async () => {
    try {
      const res = await API_POST(
        "ConfigurationSetting/View_VoucherNumber",
        {
          iScrId: props.Close ? props.iScrId : _Common.iScrId,
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        let VouchNo = res.EntVouch[0];
        setisAutoNo(!VouchNo.isManualNumber);
        if (!VouchNo.isManualNumber) {
          setstrInvNo(VouchNo.userNo);
          setstrNoFormat(VouchNo.userNoFormat);
          setiNextNo(VouchNo.nextNumber);
          setiMaxDegit(VouchNo.numSeqMaxDigit);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_VoucherNo = async () => {
    try {
      const res = await API_POST(
        "ConfigurationSetting/View_VoucherNumber",
        {
          iScrId: props.Close ? props.iScrId : _Common.iScrId,
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        let VouchNo = res.EntVouch[0];
        setstrInvNo(VouchNo.userNo);
        setstrNoFormat(VouchNo.userNoFormat);
        setiNextNo(VouchNo.nextNumber);
        setiMaxDegit(VouchNo.numSeqMaxDigit);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const UpdateVoucherNo = async () => {
    try {
      if (strNoFormat.length > 0 && !strNoFormat.includes("#N"))
        Show_Message(dispatch, "#N is Mandatory", "info");
      else {
        const res = await API_POST(
          "ConfigurationSetting/Update_VoucherNumber/",
          {
            NumSeqMaxDigit: iMaxDegit,
            NextNumber: iNextNo,
            UserNoFormat: strNoFormat,
            ScrId: props.Close ? props.iScrId : _Common.iScrId,
            strDBName: _AuthParems.DB,
            IsManualNumber: !isAutoNo,
            iBranchId: Number(_AuthParems.Branch),
          },
          dispatch
        );
        if (res) {
          Load_VoucherNo();
          Show_Message(dispatch, res.strMessage, "success");
          setisChanged(true);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Popup_Close = () => {
    if (props.Close) props.Close();
    dispatch(ShowHide_Popup("IsVouchNo"));
  };
  return (
    <div className="popup-container">
      <div className="popup-content config-popup">
        <div className="popup-header">
          <h5>Configure Invoice No</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => {
                if (isChanged) window.location.reload();
                else Popup_Close();
              }}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <p className="config-text">
              {!isAutoNo
                ? "Your invoice numbers are set on auto-generate mode to save your time. Are you sure about changing this setting?"
                : "You have selected manual invoice numbering. Do you want us to auto-generate it for you?"}
            </p>
            {isAutoNo && (
              <p className="config-text1">
                <strong>#C-Company Code </strong>
                <strong>#F-Financial Year</strong>
                <strong>#V-Bill Type Like Sales,Purchase</strong>
                <strong>#N-Bill No</strong>
              </p>
            )}
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="inv-check">
                  <input
                    type="checkbox"
                    checked={isAutoNo}
                    onChange={(e) => setisAutoNo(!isAutoNo)}
                  />
                  <label style={{ fontWeight: isAutoNo ? "bold" : "normal" }}>
                    Continue auto-generating invoice numbers
                  </label>
                </div>
              </div>
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Max Degit</label>
                  <input
                    type="number"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={iMaxDegit}
                    onChange={(e) => setiMaxDegit(e.target.value)}
                    placeholder="0"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Next No</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={iNextNo}
                    placeholder="000"
                    onChange={(e) => setiNextNo(e.target.value)}
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Inv Formate</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={strNoFormat}
                    onChange={(e) => setstrNoFormat(e.target.value)}
                    placeholder="Inv-"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Inv No</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={strInvNo}
                    readOnly
                  />
                </div>
              )}
              <div className="col-sm-12 col-md-12 ">
                <div className="inv-check">
                  <input
                    type="checkbox"
                    checked={!isAutoNo}
                    onChange={(e) => setisAutoNo(!isAutoNo)}
                  />
                  <label style={{ fontWeight: !isAutoNo ? "bold" : "normal" }}>
                    Enter invoice numbers manually
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={(e) => UpdateVoucherNo()}>
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={Popup_Close}>
              Cancel&nbsp;<i className="bx bx-block"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VouchernoSetting;
