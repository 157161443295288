import { fnBillDiscValue, fnBillDiscPer, fnItemDiscValuecal } from "../General/Formula";

const fn_BillDiscount = (strName, dBillDiscPer, dBillDiscAmt, objItem) => {
  let dValue = 0;
  let dBillDiscValue = 0;
  let dSumGoodsValue = 0;
  let dSumItemDiscValue = 0;
  dBillDiscPer = Number(dBillDiscPer);
  dBillDiscAmt = Number(dBillDiscAmt);

  var Items = objItem.filter((item) => item.ProId !== 0 || item.SerId !== 0);

  /// Don't change the code order in this method

  // iterate over each item in the array

  for (let i = 0; i < objItem.length; i++) {
    let dRateWithOutTax = 0;
    let dGoodsVal = 0;
    let dQty = Math.abs(objItem[i].Qty);
    let dItemDiscVal = 0;
    // Calculate Without Tax Rate

    if (objItem[i].RateWithTax > 0 && objItem[i].RateWithoutTax === 0)
      dRateWithOutTax = objItem[i].RateWithTax / (1 + objItem[i].TaxPer / 100);
    else dRateWithOutTax = objItem[i].RateWithoutTax;
    dGoodsVal = dQty * dRateWithOutTax;
    dItemDiscVal = fnItemDiscValuecal(
      "ItemDiscPer",
      objItem[i].ItemDiscPer,
      objItem[i].ItemDiscAmt,
      dGoodsVal
    );
    dSumGoodsValue += dGoodsVal;
    dSumItemDiscValue += dItemDiscVal;
  }
  //#region Update Two Textbox Value
  if (strName === "BillDiscPer") {
    dValue = dBillDiscPer;
    if (!(dValue >= 0 && dValue <= 100 && Items.length > 0)) dBillDiscPer = 0;
    else {
      dBillDiscPer = ConvertstringtoDecimal(Number(dValue).toFixed(2));
      if (dValue > 0) dBillDiscAmt = 0;
    }
  } else {
    dValue = dBillDiscAmt;
    if (!(dValue >= 0 && dSumGoodsValue - dSumItemDiscValue > dValue))
      dBillDiscAmt = 0;
    else {
      if (dValue > 0) dBillDiscPer = 0;
      dBillDiscAmt = ConvertstringtoDecimal(Number(dValue).toFixed(2));
    }
  }
  //#endregion

  //#region Calculate BillDiscValue
  if (dBillDiscPer === 0 && dBillDiscAmt === 0) dBillDiscValue = 0;
  else if (dBillDiscPer > 0)
    dBillDiscValue = fnBillDiscValue(
      dBillDiscPer,
      dSumGoodsValue - dSumItemDiscValue
    );
  else dBillDiscValue = dBillDiscAmt;
  //#endregion

  //#region Update BillDiscValue dtItem
  if (dBillDiscValue === 0) {
    Items = Items.map((Item) => {
      Item.BillDiscValue = 0;
      return Item;
    });
  } else {
    dBillDiscPer = fnBillDiscPer(
      dBillDiscValue,
      dSumGoodsValue - dSumItemDiscValue
    );
    Items = Items.map((Item) => {
      Item.BillDiscValue =
        ((Math.abs(Item.Qty) * Item.RateWithoutTax - Item.ItemDiscValue) *
          dBillDiscPer) /
        100;
      return Item;
    });
  }
  //#endregion
  return { _EntBaseTransDt: Items, BillDiscValue: dBillDiscValue };
};
const ConvertstringtoDecimal = (stringnum) => {
  return Number(stringnum);
};
export default fn_BillDiscount;
