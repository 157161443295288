import React from "react";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import $ from "jquery";
import { useEffect } from "react";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";

import { API_FETCH, API_POST } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function UserRole(props) {
  const dispatch = useDispatch();
  const _Auth = useSelector((state) => state.Common.Admin);
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [iUserRole, setiUserRole] = useState(0);
  const [iScrTypeId, setiScrTypeId] = useState(0);
  const [selected, setselected] = useState(0);
  const [strUserRole, setstrUserRole] = useState("");
  const [bIsNew, setbIsNew] = useState(false);
  const [bIsView, setbIsView] = useState(false);
  const [bIsModify, setbIsModify] = useState(false);
  const [bIsExport, setbIsExport] = useState(false);
  const [bIsDelete, setbIsDelete] = useState(false);
  const [bIsNoAccess, setbIsNoAccess] = useState(false);
  const [bIsPrint, setbIsPrint] = useState(false);
  const [bIsConvert, setbIsConvert] = useState(false);
  const [bIsBackup, setbIsBackup] = useState(false);
  const [bIsRestore, setbIsRestore] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [isMaster, setisMaster] = useState(true);
  const [isTransaction, setisTransaction] = useState(false);
  const [isOthers, setisOthers] = useState(false);
  const [isReport, setisReport] = useState(false);
  const [isCompany, setisCompany] = useState(false);
  const [arrUserRole, setarrUserRole] = useState([]);
  const [arrEntScreen, setarrEntScreen] = useState([]);
  const [arrEntPermission, setarrEntPermission] = useState([]);
  useEffect(() => {
    Load_UserAcccount();
  }, []);
  const Load_UserAcccount = async () => {
    try {
      const res = await API_FETCH(
        `User/View_User/${Number(_Auth.Client)}`,
        dispatch
      );
      if (res) {
        setarrEntScreen(res.EntScrType);
        setarrUserRole(res.EntUserRole);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "info");
    }
  };
  const View_UserPermission = async (UserId, iScrType, UserRole) => {
    try {
      Pre_Loading(dispatch, true);
      setiScrTypeId(iScrType);
      setiUserRole(UserRole);
      const res = await API_FETCH(
        `User/View_UserPermission/${iScrType}/${UserId}/${Number(
          UserRole
        )}/${Number(_Auth.Client)}`,
        dispatch
      );
      if (res) {
        Asssign_UserPermission(res.EntPermission, iScrType);
      }
    } catch (err) {
      Show_Message(dispatch, JSON.stringify(err), "info");
    }
  };
  const Asssign_UserPermission = (EntPermission, ScrId) => {
    setisMaster(false);
    setisCompany(false);
    setisTransaction(false);
    setisReport(false);
    setisOthers(false);
    setarrEntPermission(EntPermission);
    switch (ScrId) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        break;
      case 12:
        setisCompany(true);
        break;
      case 13:
      case 14:
      case 15:
        setisOthers(true);
        break;

      default:
        break;
    }
  };
  const Update_UserPermission = async () => {
    try {
      if (Number(iUserRole) === 0) {
        Show_Message(dispatch, "Please Select Role", "info");
      } else {
        const objUser = {
          iScrBatchId: iScrTypeId,
          iUserId: 0,
          iUserRole: iUserRole,
          iClientId: Number(_Auth.Client),
          objEntPerm: arrEntPermission,
        };
        const res = await API_POST(
          "User/Update_UserPermission/",
          objUser,
          dispatch
        );
        if (res) {
          Show_Message(dispatch, res.strMessage, "success");
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Permission_OnChange = (Value, key, objPermission, Type) => {
    let arrPermission = arrEntPermission;
    if (Type === "All")
      arrPermission = arrPermission.map((Data) => {
        Data[key] = Value;
        return Data;
      });
    else
      arrPermission = arrPermission.map((Data) => {
        if (objPermission.iPermId === Data.iPermId) {
          Data[key] = Value;
        }
        return Data;
      });
    setarrEntPermission(arrPermission);
  };
  const TransColumn = [
    {
      Header: "SNO",
      accessor: "sNo",
      width: 70,
      style: { justifyContent: "center" },
    },
    {
      Header: "NAME",
      accessor: "strName",
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            checked={bIsNew}
            onChange={(e) => {
              setbIsNew(!bIsNew);
              Permission_OnChange(!bIsNew, "iCreate", {}, "All");
            }}
          />
          <span>Create</span>
        </div>
      ),
      id: "iCreate",
      width: 100,
      show: !isReport,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iCreate)}
            onChange={(e) => {
              Permission_OnChange(e.target.checked, "NEW", rowInfo);
            }}
          />
        </div>
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            checked={bIsView}
            onChange={(e) => {
              setbIsView(!bIsView);
              Permission_OnChange(!bIsView, "iView", {}, "All");
            }}
          />
          <span>View</span>
        </div>
      ),
      id: "iView",
      width: 100,
      show: !isReport,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iView)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iView),
                "iView",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsModify}
            onChange={(e) => {
              setbIsModify(!bIsModify);
              Permission_OnChange(!bIsModify, "iModify", {}, "All");
            }}
          />
          <span>Edit</span>
        </div>
      ),
      id: "iModify",
      width: 100,
      show: !isReport,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iModify)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iModify),
                "iModify",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsDelete}
            onChange={(e) => {
              setbIsDelete(!bIsDelete);
              Permission_OnChange(!bIsDelete, "iDelete", {}, "All");
            }}
          />
          <span>Delete</span>
        </div>
      ),
      id: "iDelete",
      width: 100,
      show: !isReport,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iDelete)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iDelete),
                "iDelete",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsExport}
            onChange={(e) => {
              setbIsExport(!bIsExport);
              Permission_OnChange(!bIsExport, "iExport", {}, "All");
            }}
          />
          <span>Export</span>
        </div>
      ),
      id: "iExport",
      width: 100,
      show: isMaster,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iExport)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iExport),
                "iExport",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsPrint}
            onChange={(e) => {
              setbIsPrint(!bIsPrint);
              Permission_OnChange(!bIsPrint, "iPrint", {}, "All");
            }}
          />
          <span>Print</span>
        </div>
      ),
      id: "print",
      width: 100,
      show: isTransaction,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iPrint)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iPrint),
                "iPrint",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsConvert}
            onChange={(e) => {
              setbIsConvert(!bIsConvert);
              Permission_OnChange(!bIsConvert, "iConvert", {}, "All");
            }}
          />
          <span>Convert</span>
        </div>
      ),
      id: "convert",
      width: 100,
      show: isTransaction,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iConvert)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iConvert),
                "iConvert",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
    {
      Header: "In Order",
      id: "order",
      width: 100,
      show: !isOthers,
      accessor: (rowInfo) => (
        <input
          type="text"
          className="form-control"
          value={rowInfo.iInOrder}
          onChange={(e) =>
            Permission_OnChange(e.target.value, "iInOrder", rowInfo, "row")
          }
        />
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsBackup}
            onChange={(e) => {
              setbIsBackup(!bIsBackup);
              Permission_OnChange(!bIsBackup, "iBackup", {}, "All");
            }}
          />
          <span>Backup</span>
        </div>
      ),
      id: "iBackup",
      width: 100,
      show: isCompany,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iBackup)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iBackup),
                "iBackup",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsRestore}
            onChange={(e) => {
              setbIsRestore(!bIsRestore);
              Permission_OnChange(!bIsRestore, "iRestore", {}, "All");
            }}
          />
          <span>Restore</span>
        </div>
      ),
      id: "iRestore",
      width: 100,
      show: isCompany,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iRestore)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iRestore),
                "iRestore",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
    {
      Header: (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            defaultChecked={bIsNoAccess}
            onChange={(e) => {
              setbIsNoAccess(!bIsNoAccess);
              Permission_OnChange(!bIsNoAccess, "iNoAccess", {}, "All");
            }}
          />
          <span>No Access</span>
        </div>
      ),
      id: "noAccess",
      width: 100,
      style: { justifyContent: "center" },
      accessor: (rowInfo) => (
        <div className="td-check">
          <input
            type="checkbox"
            checked={Boolean(rowInfo.iNoAccess)}
            onChange={(e) =>
              Permission_OnChange(
                !Boolean(rowInfo.iNoAccess),
                "iNoAccess",
                rowInfo,
                "row"
              )
            }
          />
        </div>
      ),
    },
  ];
  const View_userrole = (role) => {
    setiUserRole(role.iRoleId);
    setstrUserRole(role.strRole);
    View_UserPermission(0, 13, role.iRoleId);
  };
  const Clear_Role = () => {
    setiUserRole(0);
    setstrUserRole("");
    setarrUserRole([]);
  };
  const arrTableCol = [
    {
      strAliasName: "User Role",
      strColName: "strRole",
      strTextAlign: "left",
      strWidth: "100%",
      color: "black",
    },
    {
      strAliasName: "Update",
      strTextAlign: "center",
      strWidth: "50",
      color: "black",
      cell: ({ userRole }) => (
        <i
          className="fa-solid fa-pen-to-square"
          onClick={(e) => {
            setisUpdate(true);
            View_userrole(userRole);
          }}
        ></i>
      ),
    },
  ];
  return (
    <div className="Config-card">
      <div className="config-h">
        <h3>User Role</h3>
        <div className="Config-btn">
          {!isUpdate && (
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                Clear_Role();
                setisUpdate(true);
              }}
            >
              New User Role<i className="fa-solid fa-user-plus"></i>
            </button>
          )}
          {isUpdate && (
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                setisUpdate(false);
              }}
            >
              Back to User Role <i className="fa-solid fa-xmark"></i>
            </button>
          )}
          <button onClick={props.Close} className="btn-fabgreen">
            Close <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
      <div className="Config-Wrapper">
        {!isUpdate && (
          <div className="table-card">
            <table className="option-list">
              <thead>
                <tr>
                  {arrTableCol.map((ht, Index) => (
                    <th key={Index}>{ht.strAliasName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {arrUserRole.map((Role, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrUserRole.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") View_userrole(Role);
                    }}
                    onClick={(e) => View_userrole(Role)}
                    onDoubleClick={(e) => View_userrole(Role)}
                  >
                    {arrTableCol.map((dt, Index) =>
                      dt.cell ? (
                        <td
                          key={Index}
                          style={{
                            textAlign: dt.strTextAlign,
                            minWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            width: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            maxWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            color: "black",
                          }}
                        >
                          <dt.cell key={Index} userRole={Role} />
                        </td>
                      ) : (
                        <td
                          key={Index}
                          style={{
                            textAlign: dt.strTextAlign,
                            minWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            width: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            maxWidth: dt.strWidth.includes("%")
                              ? dt.strWidth
                              : dt.strWidth + "px",
                            color: "black",
                          }}
                        >
                          {Role[dt.strColName]}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {isUpdate && (
          <div className="col-sm-12 col-md-6">
            <label>Screen</label>
            <CustomizedSelectoption
              optionparem="isoptionShow1"
              showoption={_parem.isoptionShow1}
              placeholder="Select Screen"
              select_value={(val) =>
                View_UserPermission(0, Number(val), iUserRole)
              }
              btnname=""
              btnshow={false}
              show_popup=""
              valueonly={true}
              defaultval={iScrTypeId}
              displayName="strScrCatName"
              disvalue="iScrCatId"
              arrydata={arrEntScreen}
              EmptVal="Screen"
            />
          </div>
        )}
        {isUpdate && (
          <div className="col-sm-12 col-md-12 mt-2">
            <ReactTable
              columns={TransColumn}
              data={arrEntPermission}
              minRows={10}
              row_click={(rowInfo, Index) => {
                setselected(Index);
              }}
              row_doubleclick={(rowInfo, Index) => {
                setselected(Index);
              }}
              background={true}
              tabIndex={12}
              className="full-table"
            />
          </div>
        )}
      </div>
      {isUpdate && (
        <div className="btn-section mt-1">
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (strUserRole === "") {
                Show_Message(dispatch, "Please Enter UserRole", "info");
              } else {
                Ask_Question(dispatch, "", "Do You Want to  Save User?");
              }
            }}
          >
            Save &nbsp; <i className="fas fa-Save"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={(e) => Update_UserPermission()}
          >
            Update &nbsp; <i className="fas fa-Save"></i>
          </button>
          <button className="btn-fabgreen">
            Clear &nbsp; <i className="fas fa-eraser"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={(e) => {
              if (iUserRole === 0) {
                Show_Message(dispatch, "Please Select Role", "info");
              } else {
                Ask_Question(dispatch, "", "Do You Want to  Delete User?");
              }
            }}
          >
            Delete &nbsp; <i className="fas fa-trase"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={(e) => setisUpdate(!isUpdate)}
          >
            Close &nbsp; <i className="fas fa-times"></i>
          </button>
        </div>
      )}
    </div>
  );
}

export default UserRole;
