import $ from "jquery";
import { useDispatch } from "react-redux";
import { hide_Options } from "../features/Customeselect";
import { useEffect, useState } from "react";

function CustomeIndexSelectOption(props) {
  const dispatch = useDispatch();
  const [arrOption, setarrOption] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [strdefaultval, setstrdefaultval] = useState("");
  useEffect(() => {
    if (props.arrydata !== undefined) {
      let defaultval = props.arrydata.filter((dt) => dt === props.defaultval);
      if (defaultval.length > 0) setstrdefaultval(defaultval);
    } else setstrdefaultval("");
  }, [props]);
  const input_Onchange = (val) => {
    if (props.onChange !== undefined) {
      setstrSearch(val);
      props.onChange(val);
    }
    if (props.arrydata !== undefined) {
      const EntOption = props.arrydata.filter((dt) =>
        dt.toLowerCase().includes(val.toLowerCase())
      );
      setarrOption(EntOption);
    }
    setstrSearch(val);
  };
  const Select_Value = (val) => {
    dispatch(
      hide_Options({
        [props.optionparem]: !props.showoption,
      })
    );
    setstrSearch(val);
    props.select_value(val);
  };
  return (
    <div
      className="custom-select"
      onMouseLeave={(e) =>
        dispatch(
          hide_Options({
            [props.optionparem]: false,
          })
        )
      }
    >
      <input
        className="form-control"
        disabled={props.disabled ? true : false}
        type="text"
        placeholder={props.placeholder}
        defaultValue={strdefaultval}
        onClick={(e) => {
          dispatch(hide_Options({ [props.optionparem]: !props.showoption }));
          input_Onchange("");
        }}
        tabIndex={props.taborder}
      />
      <i className="fa-solid fa-caret-down"></i>
      {props.showoption && (
        <div className="custom-option">
          <div className="option-list">
            <div className="search-option">
              <input
                className="form-control"
                type="text"
                autoFocus
                placeholder={"Search " + props.EmptVal}
                value={strSearch}
                onChange={(e) => input_Onchange(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown") {
                    $(".option-list [tabindex=0]").focus();
                  } else if (e.key === "Enter") {
                    props.keydown(e);
                  }
                }}
              />
              {arrOption.length === 0 && (
                <span> {props.EmptVal} Not Found</span>
              )}
            </div>
            {arrOption.map((value, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onClick={(e) => Select_Value(value)}
                onKeyDown={(e) => {
                  let arrlength = arrOption.length - 1;
                  if (e.key === "ArrowDown") {
                    if (arrlength === Index)
                      $(`.option-list [tabindex=0]`).focus();
                    else $(`.option-list [tabindex=${Index + 1}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.option-list [tabindex=${arrlength}]`).focus();
                    else $(`.option-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") Select_Value(value);
                }}
              
              >
                {value}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomeIndexSelectOption;
