import React, { useEffect, useState } from "react";
import AccountsButton from "../Accounts/AccountsButton";
import $ from "jquery";
import MasterList from "../Master/MasterListItem";
import DebitCreditBody from "./DebitCreditBody";
import SideMenu from "../General/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import {
  refresh_AccDebCrd,
  setAccDeCr_Taborder,
  setDebCrdDeafult,
  setDebCrdHT,
  setEntDebCrdHT,
} from "../features/Accounts";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";
function CreditNote() {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _AccParem = useSelector((state) => state.Accounts.DebitCredit);
  const _TabParem = useSelector((state) => state.Accounts.DebCrdTab);
  const _HTParem = useSelector((state) => state.Accounts.DebCrdHT);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    Load_CreditNote();
  }, []);
  const Load_CreditNote = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);
      const parems = `CreditNote/Load_CreditNote/${Number(
        _LocalParems.Branch
      )}/${_LocalParems.DB}`;
      const res = await API_FETCH(parems, dispatch);
      if (res) {
        dispatch(
          setDebCrdHT({
            iScrId: 44,
            iBranchId: Number(_LocalParems.Branch),
            iVenTypeId: 1,
            strTransNo: res.objEntNoFormat[0].strTransNo,
            strVouchNo: res.objEntNoFormat[0].strUserNo,
            arrNoFormat: res.objEntNoFormat,
            arrEntHT: res.objEntCreditNote,
            arrVenType: res.objEntVenType,
            arrValidation: res.Transtab,
          })
        );
        TabOrderAssign(res.Transtab);
      }
    } catch (e) {
      Show_Message(dispatch, e.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Search_CreditNote = async (strtext) => {
    setstrSearch(strtext);
    const objSearch = {
      iBranchId: Number(_AuthParems.Branch),
      iScrId: "",
      iPartyId: "",
      strColName: "All",
      strSearch: strtext,
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST("CreditNote/Search_CreditNote/", objSearch);
    if (res) dispatch(setEntDebCrdHT(res.objEntCreditNote));
  };
  const ClearClick = () => {
    dispatch(refresh_AccDebCrd());
    Load_CreditNote();
  };
  const Cancel_CreditNote = async () => {
    Pre_Loading(dispatch, true);
    const res = await API_POST(
      `CreditNote/Cancel_CreditNote/${_AccParem.iDebCrdId}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      Show_Message(dispatch, res.strMessage, "success");
      ClearClick();
    }
  };
  const Save_CreditNote = async () => {
    Pre_Loading(dispatch, true);
    const objInsUpdCre = {
      strPrefix: _HTParem.arrNoFormat[0].strPrefix,
      iBranchId: _AccParem.iBranchId,
      iNumSeqNo: _HTParem.arrNoFormat[0].iNumSeqNo,
      strCompCode: _HTParem.arrNoFormat[0].strCompCode,
      strFinanFrom: _HTParem.arrNoFormat[0].strFinanFrom,
      strCreNo: _AccParem.strVouchNo,
      strTransNo: _AccParem.strTransNo,
      dpCreDate: _AccParem.strVouchDate,
      iVenType: _AccParem.iVenTypeId,
      iVenId: _AccParem.iVenId,
      iCreId: _AccParem.iDebCrdId,
      dAmount: _AccParem.dAmount,
      strRemark: _AccParem.strRemark,
      iVouchId: 0,
      iCallerScrId: 0,
      strFilter: "",
      strFilterColumn: "All",
      strSearch: "",
      strVenName: "",
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      "CreditNote/InsUpd_CreditNote/",
      objInsUpdCre,
      dispatch
    );
    if (res) {
      ClearClick();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  const View_CreditNote = async (CreId) => {
    const res = await API_FETCH(
      `CreditNote/View_CreditNote/${CreId}/${_AuthParems.DB}`
    );
    if (res) {
      dispatch(
        setDebCrdDeafult({
          iDebCrdId: CreId,
          iScrId: _AccParem.iScrId,
          iBranchId: _AccParem.iBranchId,
          strTransNo: res.objCreDetails.strTransNo,
          strVouchNo: res.objCreDetails.strCreNo,
          strVouchDate: res.objCreDetails.dpCreDate,
          iVenTypeId: res.objCreDetails.iVenTyp,
          iVenId: res.objCreDetails.iVenId,
          strVenName: res.objCreDetails.strVenName,
          strRemark: res.objCreDetails.strRemark,
          dAmount: res.objCreDetails.dAmount,
          isCancel: res.objCreDetails.bCancelStatus,
        })
      );
    }
  };
  const TabOrderAssign = (EntProperties) => {
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      let colname = EntProperties[i].strCtrlName;
      let Index = EntProperties[i].iTabIndex;
      dispatch(
        setAccDeCr_Taborder({
          TransNoTab: colname === "txtEntNo" ? Index : _TabParem.VoucherTab,
          TranDate: colname === "dpEntDate" ? Index : _TabParem.VoucherTab,
          VoucherTab: colname === "txtVouchNo" ? Index : _TabParem.VoucherTab,
          VouchDateTap:
            colname === "dpVouchDate" ? Index : _TabParem.VoucherTab,
          VenNameTab: colname === "txtVenName" ? Index : _TabParem.VoucherTab,
          VenTypeTab: colname === "cbVenType" ? Index : _TabParem.VoucherTab,
          AmountTab: colname === "txtAmt" ? Index : _TabParem.VoucherTab,
          Remarktab: colname === "txtRemark" ? Index : _TabParem.VoucherTab,
          ArrTab: colname === "ArrTab" ? Index : _TabParem.VoucherTab,
          FilterTab: colname === "FilterTab" ? Index : _TabParem.VoucherTab,
          SearchTab: colname === "SearchTab" ? Index : _TabParem.VoucherTab,
          BtnSavtab: colname === "btnSave" ? Index : _TabParem.VoucherTab,
          BtnCleartab: colname === "btnClear" ? Index : _TabParem.VoucherTab,
          BtnDeletetab:
            colname === "BtnDeletetab" ? Index : _TabParem.VoucherTab,
          BtnClosetab: colname === "BtnClosetab" ? Index : _TabParem.VoucherTab,
          BtnCancel: colname === "btnCancel" ? Index : _TabParem.VoucherTab,
        })
      );
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    );
    sessionStorage.maxTab = maxTab.iTabIndex;
  };
  const KeyDown = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === _TabParem.ArrTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === _TabParem.ArrTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === _TabParem.ArrTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === _TabParem.ArrTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = _HTParem.arrValidation.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtVouchNo":
          ErrorMsg = _AccParem.strVouchNo === "" ? false : true;
          break;
        case "dpVouchDate":
          ErrorMsg = _AccParem.strVouchDate === "" ? false : true;
          break;
        case "cbVenType":
          ErrorMsg = _AccParem.iVenTypeId.toString() === "0" ? false : true;
          break;
        case "txtVenName":
          ErrorMsg = _AccParem.strVenName === "" ? false : true;
          break;
        case "txtRemark":
          ErrorMsg = _AccParem.strRemark === "" ? false : true;
          break;
        case "txtAmt":
          ErrorMsg = _AccParem.dAmount === 0 ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  return (
    <main className="main-container">
      <SideMenu ScrId={44} />
      <div className="content">
        <MasterList
          ScrId={44}
          strSearchtxt={strSearch}
          tabOrder={_TabParem.SearchTab}
          Search={(val) => Search_CreditNote(val)}
          arrMasterData={_HTParem.arrEntHT}
          isImg={false}
          displayName="strCreNo"
          Parem1="iCreId"
          View_Data={(iCreId) => View_CreditNote(iCreId)}
          placeholder="Credit Note"
        />
        <div className="main-content">
          <DebitCreditBody
            PartyUrl="CreditNote/View_Party/"
            KeyDown={KeyDown}
          />
          <Messagedialogbox />
        </div>
        <AccountsButton
          iVouchId={_AccParem.iDebCrdId}
          iScrId={44}
          Save={(e) => {
            if (PropertiesValidation()) {
              Ask_Question(
                dispatch,
                Save_CreditNote,
                "Do You Want to  Save CreditNote?"
              );
            }
          }}
          Clear={ClearClick}
          Cancel={(e) => {
            if (_AccParem.iDebCrdId === 0) {
              Show_Message(dispatch, "Please Select CreditNote", "info");
            } else {
              Ask_Question(
                dispatch,
                Cancel_CreditNote,
                "Do You Want to  Cancel CreditNote?"
              );
            }
          }}
          KeyDown={KeyDown}
        />
      </div>
    </main>
  );
}
export default CreditNote;
