import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_Message } from "../General/Messagedialogbox";
import $ from "jquery";

import { API_FETCH, Backup_Data } from "../General/Utility";
function ControlPanel() {
  const dispatch = useDispatch();
  const [arrShowtab, setarrShowtab] = useState([true, false, false, false]);
  const [arrPlans, setarrPlans] = useState([]);
  const [arrEntClient, setarrEntClient] = useState([]);
  const [arrEntEnquirys, setarrEntEnquirys] = useState([]);
  const [arrStockType, setarrStockType] = useState([]);
  const [arrCountry, setarrCountry] = useState([]);
  const [arrState, setarrState] = useState([]);

  useEffect(() => {
    Load_AdminPanel();
  }, []);
  const Load_AdminPanel = async () => {
    try {
      const res = await API_FETCH(`Admin/Load_AdminPanel`, dispatch);
      if (res) {
        setarrEntClient(res.EntClient);
        setarrPlans(res.EntPlan);
        setarrStockType(res.EntStockType);
        setarrCountry(res.EntCountry);
        setarrState(res.EntState);
        setarrEntEnquirys(res.EntEnquirys);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const tab_click = (tabindex) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    setarrShowtab(
      arrShowtab.map((dt, Index) => {
        if (Index === tabindex) dt = true;
        else dt = false;
        return dt;
      })
    );
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
  };
  const Backup_Server = async () => {
    let Backup = await Backup_Data(dispatch, 0, "JustBill");
    if (Backup) Backup.click();
  };
  return (
    <section className="dashb-container p-2">
      <div className="dashb-header">
        <h1>Admin Panel</h1>
      </div>
      <div className="fg-card shadow-base">
        <div className="trans-buttons">
          <label onClick={(e) => tab_click(0)} className="active">
            Dashboard
          </label>
          <label onClick={(e) => tab_click(1)}>Enquirys</label>
          <label onClick={(e) => tab_click(2)}>Users</label>
          <label onClick={(e) => tab_click(3)}>Script</label>
          <label onClick={(e) => tab_click(4)}>DataBase</label>
          <div id="tab-line"></div>
        </div>
        {arrShowtab[0] && (
          <section className="row">
            <div className="col-sm-12 col-md-9">
              <div className="Admin-container">
                <table className="client-list">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrEntClient.map((client, Index) => (
                      <tr key={Index}>
                        <td className="cmpname">{client.strCompanyName}</td>
                        <td>{client.strCity}</td>
                        <td>{client.strMobile}</td>
                        <td className="expiry">{client.strExpirDays}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="Admin-container">
                <h2 className="back-hdr">Server Backup</h2>
                <button onClick={(e) => Backup_Server()}>
                  <i className="fa-solid fa-server"></i> Backup
                </button>
              </div>
            </div>
          </section>
        )}
        {arrShowtab[1] && (
          <section className="row">
            <div className="col-sm-12 col-md-12">
              <div className="Admin-container">
                <table className="client-list">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrEntEnquirys.map((enq, Index) => (
                      <tr key={Index}>
                        <td>{enq.strClientName}</td>
                        <td>{enq.strCity}</td>
                        <td>{enq.strState}</td>
                        <td>{enq.strMobile}</td>
                        <td>{enq.strEmail}</td>
                        <td>{enq.strRequestDate}</td>
                        <td>{enq.strStatus}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        )}
        {arrShowtab[3] && (
          <section className="Script_Note">
            <label>Script is Included All Below SQL Files</label>
            <p>
              <span className="danger">*</span>Tables Script
            </p>
            <p>
              <span className="danger">*</span>Data Script
            </p>
            <p>
              <span className="danger">*</span>Function & Procedure Script
            </p>
            <p>
              <span className="danger">*</span>Views & Triggers Script
            </p>
          </section>
        )}
      </div>
      <Messagedialogbox />
    </section>
  );
}

export default ControlPanel;
