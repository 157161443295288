export const SummaryCalculation = (
  objEntItem,
  objAddLess,
  objTaxDetails,
  AutoRoundOff
) => {
  let dItemAmt = 0,
    dAddLessItemAmt = 0,
    BillAmt = 0,
    dSumItemDiscValue = 0,
    dSumCashDiscValue = 0,
    dSumBillDiscValue = 0,
    dAddLessBillDiscValue = 0,
    dTCSTaxAmt = 0,
    dSumItemTaxAmt = 0,
    dSumAddLessTaxAmt = 0;

  let objEntSummary = {
    Qty: 0,
    GoodsValue: 0,
    TotalDiscount: 0,
    TotalTax: 0,
    TotalAddLess: 0,
    TotalTCSTax: 0,
    RoundOff: 0,
    NetAmt: 0,
  };

  if (!objTaxDetails.filter((T) => T.Level === 2).length === 0) {
    let objTCSTax = objTaxDetails.filter((T) => T.Level === 2);
    // iterate over each item in the array
    for (let i = 0; i < objTCSTax.length; i++) {
      dTCSTaxAmt += objTCSTax[i].TaxAmt;
    }
  }
  // iterate over each item in the array
  for (let i = 0; i < objEntItem.length; i++) {
    dItemAmt += objEntItem[i].ItemAmt;
    dSumItemDiscValue += objEntItem[i].ItemDiscValue;
    dSumCashDiscValue += objEntItem[i].CashDiscValue;
    dSumBillDiscValue += objEntItem[i].BillDiscValue;
    dSumItemTaxAmt += objEntItem[i].TaxAmt;
    objEntSummary.Qty += objEntItem[i].Qty;
    objEntSummary.GoodsValue += objEntItem[i].GoodsValue;
  }
  // iterate over each item in the array
  for (let i = 0; i < objAddLess.length; i++) {
    dAddLessItemAmt += objAddLess[i].itemAmt;
    objEntSummary.TotalAddLess += objAddLess[i].taxableAmt;
    dSumAddLessTaxAmt += objAddLess[i].taxAmt;
  }
  BillAmt = dItemAmt + dTCSTaxAmt + dAddLessItemAmt - dAddLessBillDiscValue;

  objEntSummary.TotalDiscount =
    dSumItemDiscValue +
    dSumBillDiscValue +
    dAddLessBillDiscValue +
    dSumCashDiscValue;
  objEntSummary.TotalTax = dSumItemTaxAmt + dSumAddLessTaxAmt;
  objEntSummary.TotalTCSTax = dTCSTaxAmt;
  objEntSummary.RoundOff = fnRoundOff(BillAmt, AutoRoundOff);
  objEntSummary.NetAmt = fnRound(BillAmt, AutoRoundOff);

  return objEntSummary;
};
const fnRoundOff = (dAmt, AutoRoundOff) => {
  let dRoundOff = Number((0).toFixed(2));
  let dRoundWith = AutoRoundOff.split(".")[1].length;
  let dLimit = dRoundWith / 2;
  dRoundOff = dAmt % dRoundWith;
  if (dRoundOff < dLimit) dRoundOff *= -1;
  else dRoundOff = dRoundWith - dRoundOff;
  return dRoundOff === -0 ? 0 : dRoundOff;
};
const fnRound = (dAmt, AutoRoundOff) => {
  return dAmt + fnRoundOff(dAmt, AutoRoundOff);
};

export default SummaryCalculation;
