/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import Excel from "../Image/excel.png";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import { API_FETCH, API_POST, Domain } from "../General/Utility";

function ExportImport(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [strScrenName, setstrScrenName] = useState("Product Master");
  const [strExcelName, setstrExcelName] = useState("");
  const [PartialMsg, setPartialMsg] = useState("");
  const [arrColumns, setarrColumns] = useState([]);
  const [arrEntData, setarrEntData] = useState([]);
  const [isExport, setisExport] = useState(true);
  const [IsTable, setIsTable] = useState(false);
  const [IsImported, setIsImported] = useState(false);
  const [IsRejected, setIsRejected] = useState(false);

  useEffect(() => {
    Load_ExportData();
  }, []);
  const Load_ExportData = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `ExportImport/Export_Master/${Number(props.iScrId)}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setarrEntData(res.EntData);
        if (res.EntData.length > 0) setIsTable(true);
        setarrColumns(res.EntColumns);
        setstrScrenName(res.strScrenName);
        setstrExcelName(res.strExcelName);
      } else Show_Message(dispatch, res.strMessage, "info");
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Export_toExcel = async () => {
    // Get the table element
    const table = document.getElementById("Exptable");
    // Create a new workbook and a new worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(strExcelName);

    // Get header row
    const headers = Array.from(table.querySelectorAll("th")).map(
      (th) => th.innerText
    );

    // Add headers to the worksheet
    worksheet.addRow(headers);

    // Style headers
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = {
        bold: true,
        color: { argb: "FF00B050" },
        size: 12,
        name: "Calibri",
      };
      cell.alignment = { horizontal: "center" };
      cell.border = {
        top: { style: "thin", color: { argb: "FFADD8E6" } },
        bottom: { style: "thin", color: { argb: "FFADD8E6" } },
        left: { style: "thin", color: { argb: "FFADD8E6" } },
        right: { style: "thin", color: { argb: "FFADD8E6" } },
      };
    });

    // Get data rows
    const rows = Array.from(table.querySelectorAll("tbody tr")); // Skip header row
    rows.forEach((row) => {
      const rowData = Array.from(row.querySelectorAll("td")).map(
        (td) => td.innerText
      );
      const tdStyles = Array.from(row.querySelectorAll("td")).map((td) => ({
        textAlign: td.style.textAlign || "left", // Default alignment if not specified
      }));
      const tdDataTypes = Array.from(row.querySelectorAll("td")).map(
        (td) => td.getAttribute("datatype") || "General" // Fallback to 'General' if not specified
      );

      const newRow = worksheet.addRow(rowData);

      // Style cells
      newRow.eachCell((cell, index) => {
        cell.font = { name: "Calibri", size: 11 };
        cell.alignment = { horizontal: tdStyles[index - 1].textAlign }; // Apply alignment from td styles

        // Set number format based on datatype attribute
        switch (tdDataTypes[index - 1]) {
          case "Decimal":
            cell.numFmt = "#,##0.000"; // Decimal format
            break;
          case "Number":
            cell.numFmt = "#,##0"; // Number format without decimals
            break;
          default:
            cell.numFmt = "General"; // Default format
            break;
        }

        cell.border = {
          top: { style: "thin", color: { argb: "FFADD8E6" } },
          bottom: { style: "thin", color: { argb: "FFADD8E6" } },
          left: { style: "thin", color: { argb: "FFADD8E6" } },
          right: { style: "thin", color: { argb: "FFADD8E6" } },
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFFFF" }, // White background
        };
      });
    });

    // Write the file to a Blob
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    // Create a link and trigger the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${strExcelName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const Download_ExcelFormat = async () => {
    try {
      await Pre_Loading(dispatch, true);
      await fetch(`${Domain}ExportImport/Download_ExcelFormat`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_AuthParems.AuthKey}`, // Add the token here
        },
      })
        .then((res) => res.blob())
        .then((res) => {
          const url = window.URL.createObjectURL(res);
          const link = document.createElement("a");
          link.href = url;
          const FileName = `JustBill_Excel.zip`;
          link.setAttribute("download", FileName);
          link.click();
        })
        .catch(async (err) => {
          await Show_Message(dispatch, err.stack, "error");
        });
    } catch (err) {
      await Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const Import_fromExcel = async () => {
    try {
      await Pre_Loading(dispatch, true);
      let ExpImpUrl = "";
      let RejectMessage = "";
      switch (props.iScrId) {
        case 1:
          ExpImpUrl = "ExportImport/ProductImport/";
          RejectMessage = "Product Rejected Please Refer Reson Column";
          break;
        case 2:
          ExpImpUrl = "ExportImport/CustomerImport/";
          RejectMessage = "Customer Rejected Please Refer Reson Column";
          break;
        case 3:
          ExpImpUrl = "ExportImport/SupplierImport/";
          RejectMessage = "Supplier Rejected Please Refer Reson Column";
          break;
        case 50:
          ExpImpUrl = "ExportImport/VendorImport/";
          RejectMessage = "Vendor Rejected Please Refer Reson Column";
          break;
        default:
          break;
      }
      const res = await API_POST(
        ExpImpUrl,
        {
          ObjImportModel: arrEntData,
          strCompanyDB: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        await setIsImported(true);
        await props.Close(true);
        await Show_Message(dispatch, res.strMessage, "success");
      } else if (res.strStatus === "info") {
        await setIsImported(true);
        await setPartialMsg(res.strMessage);
        await Show_Message(dispatch, RejectMessage, "error");
        let EntColumn = arrColumns.map((dt) => {
          if (dt.header === "Reason") {
            dt.isVisible = true;
          }
          return dt;
        });
        await setarrColumns(EntColumn);
        await setarrEntData(res.EntData);
        setIsRejected(true);
      } else {
        await Show_Message(dispatch, res.strMessage, "error");
      }
    } catch (err) {
      await Show_Message(dispatch, err.message, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  useEffect(() => {
    async function AutoDownload() {
      if (IsRejected === true) {
        await Export_toExcel();
      }
    }
    AutoDownload();
  }, [IsRejected]);
  const Clear_Excel = () => {
    setIsTable(false);
    setarrEntData([]);
    setarrColumns([]);
    setPartialMsg("");
    Load_ExportData();
  };
  const Clear_File = () => {
    setIsImported(false);
    setIsTable(false);
    setIsRejected(false);
    setPartialMsg("");
    setarrEntData([]);
  };
  const Select_Excel = () => {
    const defaultBtn = document.getElementById("file-upload");
    defaultBtn.click();
    defaultBtn.addEventListener("change", async function (e) {
      const file = this.files[0];
      if (
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ].includes(file.type)
      ) {
        Show_Message(
          dispatch,
          "Only .xlsx or .xls file format are allowed",
          "info"
        );
      } else {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function () {
          var data = new Uint8Array(reader.result);
          var work_book = XLSX.read(data, { type: "array" });
          var sheet_name = work_book.SheetNames;
          var Work_Sheet = work_book.Sheets[sheet_name[0]];
          var sheet_data = XLSX.utils.sheet_to_json(Work_Sheet, {
            header: 1,
            defval: "",
            blankrows: false,
          });
          if (sheet_data.length > 0 && strExcelName === sheet_name[0]) {
            let EntExcelCol = sheet_data[0];
            let EntExcelDate = sheet_data.filter((dt, Index) => Index !== 0);
            let EntData = [];
            for (let i = 0; i < EntExcelDate.length; i++) {
              let objData = {};
              let RowData = EntExcelDate[i];
              EntExcelCol.forEach((_Col, Index) => {
                arrColumns.forEach((Column) => {
                  if (_Col === Column.header) {
                    let Data = "";
                    switch (Column.dataType) {
                      case "Decimal":
                        Data = Number(Number(RowData[Index]).toFixed(3));
                        break;
                      case "Number":
                        Data = Number(RowData[Index]);
                        break;
                      default:
                        Data = RowData[Index].toString();
                        break;
                    }
                    objData = {
                      ...objData,
                      [Column.importColumn]: Data,
                    };
                  }
                });
              });
              arrColumns.forEach((Column) => {
                if (!Column.isVisible) {
                  let Data = "";
                  switch (Column.dataType) {
                    case "Decimal":
                      Data = Number(Number(Column.defaulVal).toFixed(3));
                      break;
                    case "Number":
                      Data = Number(Column.defaulVal);
                      break;
                    default:
                      Data = Column.defaulVal.toString();
                      break;
                  }
                  objData = {
                    ...objData,
                    [Column.importColumn]: Data,
                  };
                }
              });
              let IsValid = Validate_Values(objData);

              if (IsValid !== true) {
                Show_Message(dispatch, `Field ${IsValid} Not Exists!`, "info");
                return;
              }
              EntData.push(objData);
            }
            setIsTable(true);
            setarrEntData(EntData);
          } else {
            Clear_Excel();
            Show_Message(
              dispatch,
              "Please Select " + strScrenName + " File",
              "info"
            );
          }
        };
      }
    });
  };
  const Validate_Values = (obj) => {
    for (const key in obj) {
      if (obj[key] === undefined) {
        return key; // Return the first key with an undefined value
      }
    }
    return true; // Return null if no undefined values are found
  };
  const TextAlign = (Col) => {
    switch (Col.dataType) {
      case "Decimal":
        return "right";
      case "Number":
        return "center";
      default:
        return "left";
    }
  };
  const tab_click = async (tabindex) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    setisExport(tabindex === 0 ? true : false);
    if (tabindex === 1) {
      setIsTable(false);
      await Clear_File();
    } else await Load_ExportData();
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{strScrenName} </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={() => props.Close(IsImported)}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-0">
          <div className="trans-tab">
            <div className="trans-buttons">
              <label onClick={(e) => tab_click(0)} className="active">
                Export
              </label>
              <label onClick={(e) => tab_click(1)}>Import</label>
              <div id="tab-line"></div>
            </div>
          </div>
          {!isExport && !IsTable && (
            <div id="FileUpload">
              <div className="filewrapper">
                <div className="uploadfile">
                  <img src={Excel} alt="Excel Formate" />
                  <span className="upload__button" onClick={Select_Excel}>
                    Browse File
                  </span>
                  <input
                    type="file"
                    id="file-upload"
                    accept=".xlsx,.xls"
                    hidden
                  />
                </div>
              </div>
            </div>
          )}
          {isExport && !IsTable && (
            <div id="FileUpload">
              <div className="filewrapper">
                <div className="uploadfile">
                  <img src={Excel} alt="Excel Formate" />
                  <span className="upload__button">No Data Found</span>
                </div>
              </div>
            </div>
          )}
          {IsTable && (
            <>
              <p className="danger mt-2 mb-2">{PartialMsg}</p>
              <div className="table-card">
                <table id="Exptable" className="option-list">
                  <thead>
                    <tr>
                      {arrColumns.map((Col, Index) =>
                        Col.isVisible ? <th key={Index}>{Col.header}</th> : ""
                      )}
                    </tr>
                  </thead>
                  {isExport && (
                    <tbody>
                      {arrEntData.map((dt, trIndex) => (
                        <tr key={trIndex}>
                          {arrColumns.map((Col, tdindex) =>
                            Col.isVisible ? (
                              <td
                                key={tdindex}
                                datatype={Col.dataType}
                                style={{ textAlign: TextAlign(Col) }}
                              >
                                {dt[Col.exportColumn]}
                              </td>
                            ) : (
                              ""
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                  {!isExport && (
                    <tbody>
                      {arrEntData.map((dt, trIndex) => (
                        <tr key={trIndex}>
                          {arrColumns.map((Col, tdindex) =>
                            Col.isVisible ? (
                              <td
                                key={tdindex}
                                datatype={Col.dataType}
                                style={{ textAlign: TextAlign(Col) }}
                              >
                                {dt[Col.importColumn]}
                              </td>
                            ) : (
                              ""
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </>
          )}
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {isExport && (
              <button
                className="btn-fabgreen"
                disabled={arrEntData.length === 0 ? true : false}
                onClick={(e) => Export_toExcel()}
              >
                Export Data&nbsp;<i className="bx bx-download"></i>
              </button>
            )}
            {isExport && (
              <button className="btn-fabgreen" onClick={(e) => Clear_Excel()}>
                Clear Data&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            {!isExport && (
              <button
                className="btn-fabgreen"
                disabled={arrEntData.length === 0 ? true : false}
                onClick={(e) => Import_fromExcel()}
              >
                Import Data&nbsp;<i className="bx bx-import"></i>
              </button>
            )}
            {!isExport && (
              <button className="btn-fabgreen" onClick={(e) => Clear_File()}>
                Clear Data&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
            )}
            <button
              className="btn-fabgreen"
              onClick={(e) => Download_ExcelFormat()}
            >
              Excel Format&nbsp;<i className="bx bx-download"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ExportImport;
