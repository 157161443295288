import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_FETCH } from "../General/Utility";
import ReactTable from "../General/ReactTable";
function JournalView(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [ListEntJournal, setListEntJournal] = useState([]);
  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  useEffect(() => {
    Load_JournelEntry(strFromDate, strToDate);
  }, []);
  const Load_JournelEntry = async (FromDate, ToDate) => {
    const res = await API_FETCH(
      "JournalEntry/Load_JournelEntry/" +
        FromDate +
        "/" +
        ToDate +
        "/" +
        Number(_AuthParems.Branch) +
        "/" +
        _AuthParems.DB,
      dispatch
    );
    if (res) {
      setListEntJournal(res.EntJournel);
    }
  };
  const DateOnChange = (Date, DateFor) => {
    if (DateFor === "FromDate") {
      setstrFromDate(Date);
      Load_JournelEntry(Date, strToDate);
    } else {
      setstrToDate(Date);
      Load_JournelEntry(strFromDate, Date);
    }
  };

  const JEColumn = [
    {
      Header: "SNO",
      accessor: "sNo",
      maxWidth: 70,
    },
    {
      Header: "Trans No",
      accessor: "transNo",
      minWidth: 200,
    },
    {
      Header: "Journal Voucher",
      id: "jeId",
      accessor: "jeNo",
      minWidth: 200,
    },
    {
      Header: "Trans Date",
      accessor: "jeDate",
      minWidth: 200,
    },
    {
      Header: "Debit",
      accessor: "dr",
    },
    {
      Header: "Credit",
      accessor: "cr",
    },
  ];

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Journel View</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-3 col-sm-12 mb-1">
              <label>From Date</label>
              <input
                type="date"
                aria-label="First name"
                className="form-control"
                value={strFromDate}
                onChange={(e) => DateOnChange(e.target.value, "FromDate")}
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-1">
              <label>To Date</label>
              <input
                type="date"
                aria-label="First name"
                className="form-control"
                value={strToDate}
                onChange={(e) => DateOnChange(e.target.value, "ToDate")}
              />
            </div>
          </div>
          <ReactTable
            columns={JEColumn}
            data={ListEntJournal}
            minRows={7}
            row_click={() => {}}
            row_doubleclick={(rowInfo) => {
              props.JournalView(rowInfo.jeId);
            }}
            background={true}
            className="full-table"
          />
        </div>
      </div>
    </div>
  );
}
export default JournalView;
