import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Ask_Question,
  Pre_Loading,
  Show_Message,
} from "../General/Messagedialogbox";
import { API_POST } from "../General/Utility";
import { ShowHide_Popup, Update_Addres } from "../features/Transactionstates";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Country_OnChange } from "../Master/MasterTransaction";
function AddressPopup(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _HT = useSelector((store) => store.Transstates.Header);

  const [iAddId, setiAddId] = useState(0);
  const [PartyScrId, setPartyScrId] = useState(0);
  const [iPartyId, setiPartyId] = useState(0);
  const [strPartyName, setstrPartyName] = useState("");
  const [strDoorNo, setstrDoorNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [State, setState] = useState(32);
  const [Country, setCountry] = useState(100);
  const [strMobile, setstrMobile] = useState("");
  const [strPhone, setstrPhone] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strGST, setstrGST] = useState("");
  const [strTINo, setstrTINo] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [isAddress, setisAddress] = useState(false);
  const [isShippAdd, setisShippAdd] = useState(false);
  const [isNewModify, setisNewModify] = useState(false);
  const [arrAddAdress, setarrAddAdress] = useState([]);
  const [arrEntCountry, setarrEntCountry] = useState([]);
  const [arrEntState, setarrEntState] = useState([]);

  useEffect(() => {
    const PartyScrId = props.ScrId ? props.ScrId : _Common.PartyScrId;
    const PartyId = props.PartyId ? props.PartyId : _HT.iPartyId;
    const PartyName = props.PartyName ? props.PartyName : _HT.strPartyName;
    setPartyScrId(PartyScrId);
    setiPartyId(PartyId);
    setstrPartyName(PartyName);
    LoadPartyAddress(PartyScrId, PartyId);
  }, []);

  const LoadPartyAddress = async (PartyScrId, PartyId) => {
    try {
      await Pre_Loading(dispatch, true);
      await setPartyScrId(PartyScrId);
      await setiPartyId(PartyId);
      const trans = {
        PartyId: PartyId,
        PartyScrId: PartyScrId,
        CompanyDB: _AuthParems.DB,
      };
      const res = await API_POST(
        `PartyTrans/Load_PartyAddress/`,
        trans,
        dispatch
      );
      if (res) {
        setarrAddAdress(res.EntAdress);
        setarrEntCountry(res.EntCountry);
        setarrEntState(res.EntState);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Save_Click = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const InsUpdAddressParem = {
        AddId: iAddId,
        ScrId: PartyScrId,
        PartyId: Number(iPartyId),
        PartyName: strPartyName,
        Address1: strDoorNo,
        Address2: strStreet,
        Address3: strArea,
        Address4: strCity,
        Pincode: strPinCode,
        State: State,
        StateName: "",
        Country: Country,
        CountryName: "",
        Mobile: strMobile,
        Phone: strPhone,
        Email: strEmail,
        GSTNo: strGST,
        TINNo: strTINo,
        Remark: strRemark,
        isShippAdd: isShippAdd,
        isAddress: isAddress,
        strDBName: _AuthParems.DB,
        strAddress: "",
        BranchId: Number(_AuthParems.Branch),
      };
      const res = await API_POST(
        `PartyTrans/InsUpd_Address`,
        InsUpdAddressParem,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Save_DefaultAddress = async (add) => {
    try {
      await Pre_Loading(dispatch, true);
      const InsUpdAddressParem = {
        AddId: add.addId,
        ScrId: add.scrId,
        PartyId: add.partyId,
        PartyName: add.partyName,
        Address1: add.address1,
        Address2: add.address2,
        Address3: add.address3,
        Address4: add.address4,
        Pincode: add.pincode,
        State: add.state,
        StateName: add.stateName,
        Country: add.country,
        CountryName: add.countryName,
        Mobile: add.mobile,
        Phone: add.phone,
        Email: add.email,
        GSTNo: add.gstNo,
        TINNo: add.tinNo,
        Remark: add.remark,
        isShippAdd: _HT.AddressType === "Shipp" ? true : add.isShippAdd,
        isAddress: _HT.AddressType === "Address" ? true : add.isAddress,
        strDBName: _AuthParems.DB,
        strAddress: "",
      };
      const res = await API_POST(
        `Transaction/InsUpd_Address`,
        InsUpdAddressParem,
        dispatch
      );
      if (res) {
        await dispatch(
          Update_Addres({
            Address: add.strAddress,
            AddId: add.addId,
          })
        );
        await Popup_Close();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Delete_Address = async (iAddId) => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `Transaction/Delete_Address/${iAddId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        Show_Message(dispatch, res.strMessage, "success");
        Clear_Click();
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    } finally {
      Pre_Loading(dispatch, false);
    }
  };
  const Clear_Click = async () => {
    setiAddId(0);
    setstrPartyName("");
    setstrDoorNo("");
    setstrStreet("");
    setstrArea("");
    setstrCity("");
    setstrPinCode("");
    setState(32);
    setCountry(100);
    setstrMobile("");
    setstrPhone("");
    setstrEmail("");
    setstrGST("");
    setstrTINo("");
    setstrRemark("");
    setisAddress(false);
    setisShippAdd(false);
    setarrAddAdress([]);
    await LoadPartyAddress(PartyScrId, iPartyId);
  };
  const Select_Address = (add) => {
    setiAddId(add.addId);
    setstrPartyName(add.partyName);
    setstrDoorNo(add.address1);
    setstrStreet(add.address2);
    setstrArea(add.address3);
    setstrCity(add.address4);
    setstrPinCode(add.pincode);
    setState(add.state);
    setCountry(add.country);
    setstrMobile(add.mobile);
    setstrPhone(add.phone);
    setstrEmail(add.email);
    setstrGST(add.gstNo);
    setstrTINo(add.tinNo);
    setstrRemark(add.remark);
    setisAddress(add.isAddress);
    setisShippAdd(add.isShippAdd);
    setisNewModify(true);
  };
  const New_Address = () => {
    setisNewModify(true);
    setstrPartyName(props.PartyName);
    setiAddId(0);
    setstrPartyName("");
    setstrDoorNo("");
    setstrStreet("");
    setstrArea("");
    setstrCity("");
    setstrPinCode("");
    setState(32);
    setCountry(100);
    setstrMobile("");
    setstrPhone("");
    setstrEmail("");
    setstrGST("");
    setstrTINo("");
    setstrRemark("");
    setisAddress(false);
    setisShippAdd(false);
    setarrAddAdress([]);
  };
  const Popup_Close = () => {
    if (props.Close) props.Close();
    else dispatch(ShowHide_Popup("IsAddress"));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>
            {_HT.AddressType === "Shipp" ? "Shipping " : "Billing "}Address
          </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={() => Popup_Close()}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2 ">
          {!isNewModify && (
            <div className="add-container">
              {arrAddAdress.map((add, Index) => (
                <div className="add-cart" key={Index}>
                  <div className="add-contact">
                    <h5>{add.partyName}</h5>
                  </div>
                  <h6>
                    <i className="fa-solid fa-location-dot mr-2"></i>
                    {`${add.address1},${add.address2}`}
                  </h6>
                  <h6>{`${add.address3},${add.address4}`}</h6>
                  <h6>{`${add.stateName},${add.countryName}-${add.pincode}`}</h6>{" "}
                  <h6>GSTIN : {add.gstNo}</h6>
                  <h6 className="add-contact">
                    <span>
                      <i className="fa-solid fa-phone mr-1"></i>
                      {add.mobile}
                    </span>
                    <span className="ml-2">
                      <i className="fa-regular fa-envelope mr-1"></i>
                      {add.email}
                    </span>
                  </h6>
                  <div className="btn-add">
                    <span className="lbltooltip">
                      <span
                        className="icon-btn"
                        onClick={(e) => Select_Address(add)}
                      >
                        <i className="fa-regular fa-pen-to-square"></i>
                      </span>
                      <span className="tooltiptext">Edit</span>
                    </span>
                    <span className="lbltooltip">
                      <span className="icon-btn">
                        <i
                          className={
                            _HT.AddressType === "Address"
                              ? add.isAddress
                                ? "fa-solid fa-star"
                                : "fa-regular fa-star"
                              : add.isShippAdd
                              ? "fa-solid fa-star"
                              : "fa-regular fa-star"
                          }
                          onClick={(e) => Save_DefaultAddress(add)}
                        ></i>
                      </span>
                      <span className="tooltiptext">Select Address</span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
          {isNewModify && (
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label>Party Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={strPartyName}
                  onChange={(e) => setstrPartyName(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Party Name"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <label>Door No</label>
                <input
                  type="text"
                  className="form-control"
                  value={strDoorNo}
                  onChange={(e) => setstrDoorNo(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Door No"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <label>Street</label>
                <input
                  type="text"
                  className="form-control"
                  value={strStreet}
                  onChange={(e) => setstrStreet(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Street"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Area</label>
                <input
                  type="text"
                  className="form-control"
                  value={strArea}
                  onChange={(e) => setstrArea(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Area"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={strCity}
                  onChange={(e) => setstrCity(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter City"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Pincode</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Pincode"
                  value={strPinCode}
                  onChange={(e) => setstrPinCode(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  Country <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow9"
                  showoption={_selecrparem.isoptionShow9}
                  placeholder="Select Country"
                  select_value={async (val) => {
                    await setCountry(val);
                    await setarrEntState(
                      await Country_OnChange(val, _AuthParems.DB, dispatch)
                    );
                  }}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={Country}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={arrEntCountry}
                  disabled={false}
                  EmptVal="Country"
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  State <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow10"
                  showoption={_selecrparem.isoptionShow10}
                  placeholder="Select State"
                  select_value={(val) => setState(val)}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={State}
                  displayName="strSName"
                  disvalue="iSId"
                  arrydata={arrEntState}
                  disabled={false}
                  EmptVal="State"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Mobile No</label>
                <input
                  type="number"
                  className="form-control"
                  value={strMobile}
                  onChange={(e) => setstrMobile(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Mobile Number"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Phone No</label>
                <input
                  type="number"
                  className="form-control"
                  value={strPhone}
                  onChange={(e) => setstrPhone(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Phone Number"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>E-Mail</label>
                <input
                  type="text"
                  className="form-control"
                  value={strEmail}
                  onChange={(e) => setstrEmail(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Email"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>GSTN</label>
                <input
                  type="text"
                  className="form-control"
                  value={strGST}
                  onChange={(e) => setstrGST(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter GST Number"
                  maxLength={15}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>TIN</label>
                <input
                  type="text"
                  className="form-control"
                  value={strTINo}
                  onChange={(e) => setstrTINo(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter TIN Number"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <label>Remark</label>
                <input
                  type="text"
                  className="form-control"
                  value={strRemark}
                  onChange={(e) => setstrRemark(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Remark"
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Billing Address</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isAddress}
                    id="flexCheckChecked"
                    onChange={(e) => setisAddress(!isAddress)}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <label>Shipping Address</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isShippAdd}
                    id="flexCheckChecked"
                    onChange={(e) => setisShippAdd(!isShippAdd)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {!isNewModify && (
          <div className="popup-footer">
            <div className="popup-btn-group">
              <button
                className="btn-fabgreen"
                onClick={(e) => setisNewModify(true)}
              >
                New Address&nbsp;
                <i className="fa-solid fa-location-dot">
                  <sub>
                    <i className="fa-solid fa-plus"></i>
                  </sub>
                </i>
              </button>
            </div>
          </div>
        )}
        {isNewModify && (
          <div className="popup-footer">
            <div className="popup-btn-group">
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  Ask_Question(
                    dispatch,
                    Save_Click,
                    "Do You Want to  Save Address?"
                  );
                }}
              >
                Save&nbsp;<i className="fas fa-save"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  Ask_Question(
                    dispatch,
                    Delete_Address,
                    "Do You Want to  Delete Address?",
                    iAddId
                  );
                }}
              >
                Delete&nbsp;<i className="bx bx-trash-alt"></i>
              </button>
              <button className="btn-fabgreen" onClick={(e) => New_Address()}>
                New&nbsp;<i className="fas fa-save"></i>
              </button>
              <button className="btn-fabgreen" onClick={Clear_Click}>
                Clear&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  setisNewModify(false);
                  Clear_Click();
                }}
              >
                Close&nbsp;<i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddressPopup;
