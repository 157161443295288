import React, { useEffect, useState } from "react";
import ExportImport from "./ExportImport";
import UOMMaster from "./UOMMaster";
import Button from "./MasterButton";

import SideMenu from "../General/SideMenu";
import CategoryMaster from "./CategoryMaster";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import $ from "jquery";
import BrandLocationManufacturermaster from "./BrandLocationManufacturermaster";
import MasterList from "./MasterListItem";
import Messagedialogbox, { Ask_Question } from "../General/Messagedialogbox";
import { Buffer } from "buffer";
import { useDispatch, useSelector } from "react-redux";
import { Pre_Loading, Show_Message } from "../General/Messagedialogbox";
import MasterCodeSetting from "./MasterCodeSetting";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";

import MasterProductSearch from "../Transaction/MasterProductSearch";
import AttributeProduct from "./AttributeProduct";
import Compositeproduct from "./Compositeproduct";

function ProductMaster() {
  const dispatch = useDispatch();
  const _select = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const _Common = useSelector((store) => store.Transstates.Common);

  //#region  Variables
  const iScrId = 1;
  const [ManualCode, setManualCode] = useState(true);
  const [IsOpen, setIsOpen] = useState(false);
  const [IsCatOpen, setIsCatOpen] = useState(false);
  const [bIsTcsShow, setbIsTcsShow] = useState(false);
  const [ExpImpOpen, setExpImpOpen] = useState(false);

  const [iAttGrpId, setiAttGrpId] = useState(0);

  const [chkStockMaintainYes, setchkStockMaintainYes] = useState(true);
  const [IsBatchPro, setIsBatchPro] = useState(false);
  const [IsAttPro, setIsAttPro] = useState(false);
  const [IsCompoPro, setIsCompoPro] = useState(false);
  const [iProId, setiProId] = useState(0);
  const [strProId, setstrProId] = useState("");
  const [strAttValId, setstrAttValId] = useState("");
  const [strProCode, setstrProCode] = useState("");
  const [strProName, setstrProName] = useState("");

  const [strHSNCode, setstrHSNCode] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strUnitName, setstrUnitName] = useState("");
  const [strPurchaseUnitValue, setstrPurchaseUnitValue] = useState(0);
  const [strSalesUnitValue, setstrSalesUnitValue] = useState(0);
  const [strSearch, setstrSearch] = useState("");
  const [dCostRate, setdCostRate] = useState(0);
  const [dPriceRate, setdPriceRate] = useState(0);
  const [dDiscount, setdDiscount] = useState(0);
  const [dMinMargin, setdMinMargin] = useState(0);
  const [iProTypeId, setiProTypeId] = useState(1);
  const [iCat1Id, setiCat1Id] = useState(0);
  const [iCat2Id, setiCat2Id] = useState(0);
  const [iCat3Id, setiCat3Id] = useState(0);
  const [iStatusId, setiStatusId] = useState(1);
  const [iProGenreId, setiProGenreId] = useState(1);
  const [iSGSTTaxId, setiSGSTTaxId] = useState(24);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(27);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(18);
  const [iTcsTaxId, setiTcsTaxId] = useState(43);
  const [iTaxTypeId, setiTaxTypeId] = useState(1);
  const [CategoryType, setCategoryType] = useState(1);
  const [strConcatAttNames, setstrConcatAttNames] = useState("");

  const [listCat1, setlistCat1] = useState([]);
  const [listCat2, setlistCat2] = useState([]);
  const [listCat3, setlistCat3] = useState([]);
  const [listProGenre, setlistProGenre] = useState([]);
  const [listStatus, setlistStatus] = useState([]);
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listSGST, setlistSGST] = useState([]);
  const [listCGST, setlistCGST] = useState([]);
  const [listIGST, setlistIGST] = useState([]);
  const [listTaxType, setlistTaxType] = useState([]);

  const [isBML, setisBML] = useState(false);
  const [ibrandid, setibrandid] = useState(0);
  const [imanufacid, setimanufacid] = useState(0);
  const [ilocationid, setilocationid] = useState(0);
  const [strUPC, setstrUPC] = useState("");
  const [strEAN, setstrEAN] = useState("");
  const [strISBN, setstrISBN] = useState("");
  const [BMLtype, setBMLtype] = useState(1);
  const [listBrand, setlistBrand] = useState([]);
  const [listManufacturer, setlistManufacturer] = useState([]);
  const [listlocation, setlistlocation] = useState([]);

  const [listProduct, setlistProduct] = useState([]);
  const [listProType, setlistProType] = useState([]);
  const [listUnit, setlistUnit] = useState([]);
  const [ListTcsTax, setListTcsTax] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);
  // Product Image
  const [strImage, setstrImage] = useState("");
  const [strImageName, setstrImageName] = useState("");
  const [ListAtttribute, setListAtttribute] = useState([]);
  const [ListAttProduct, setListAttProduct] = useState([]);
  const [ImageByteArray, setImageByteArray] = useState([]);
  const [arrProductDT, setarrProductDT] = useState([]);
  const [arrEntCalType, setarrEntCalType] = useState([]);
  const [iDisCal, setiDisCal] = useState(1);
  const [iRateCal, setiRateCal] = useState(1);
  const [bIsShowPrintName, setbIsShowPrintName] = useState(false);
  const [bIsShowHsn, setbIsShowHsn] = useState(false);
  const [bIsShowRemark, setbIsShowRemark] = useState(false);
  const [bIsShowCatg1, setbIsShowCatg1] = useState(false);
  const [bIsShowCatg2, setbIsShowCatg2] = useState(false);
  const [bIsShowCatg3, setbIsShowCatg3] = useState(false);
  const [bIsShowStatus, setbIsShowStatus] = useState(false);
  const [bIsShowProTyp, setbIsShowProTyp] = useState(false);
  const [bIsShowProgen, setbIsShowProgen] = useState(false);
  const [isMoreinfo, setisMoreinfo] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [attOption, setattOption] = useState([
    {
      iAttId: 0,
      strAttribute: "",
      strAttVal: "",
      iAttValId: 0,
      isAttribute: false,
      iAttGrpId: 0,
      arrAttValue: [],
    },
  ]);
  //#endregion

  //#region Load_Event
  useEffect(() => {
    Load_ProductMaster();
  }, []);
  const Load_ProductMaster = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const _LocalParem = await DecryptData(localStorage.jbctrlparams);
      const res = await API_FETCH(
        `ProductMaster/Load_ProductMaster/${_LocalParem.Branch}/${_LocalParem.DB}`,
        dispatch
      );
      if (res) {
        setlistCat1(res.objEntCat1);
        setlistCat2(res.objEntCat2);
        setlistCat3(res.objEntCat3);
        setlistBrand(res.objEntbrand);
        setlistManufacturer(res.objEntmanufacturer);
        setlistlocation(res.objEntlocation);
        setlistProType(res.objEntProType);
        setlistProGenre(res.objEntProGenre);
        setlistStatus(res.objEntStatus);
        setlistCodeFormat(res.objEntCodeFormat);
        setManualCode(res.objEntCodeFormat[0].isManualNo);
        setlistTaxType(res.objEntTaxType);
        setlistSGST(res.objEntSGSTTax);
        setlistCGST(res.objEntCGSTTax);
        setlistIGST(res.objEntIGSTTax);
        setListTcsTax(res.objEntTcs);
        setlistProduct(res.objEntProductList);
        setarrEntCalType(res.EntCalType);
        setstrProCode(res.objEntCodeFormat[0].strCode);
        setdCostRate((0).toFixed(2));
        setdPriceRate((0).toFixed(2));
        setdDiscount((0).toFixed(2));
        setdMinMargin((0).toFixed(2));
        if (res.objUnit !== undefined) {
          setlistUnit(res.objUnit);
          setstrUnitName(res.strUnitName);
          CheckUnitValue(res.objUnit, _LocalParem.DB);
        }
        TabOrderAssign(res.EntProperties);
        setListValidateProperties(res.EntProperties);
        setListAtttribute(res.objEntAtt);

        var objEntField = res.objEntFields;
        setbIsShowPrintName(objEntField[0].bIsVissible);
        setbIsShowHsn(objEntField[1].bIsVissible);
        setbIsShowRemark(objEntField[2].bIsVissible);
        setbIsShowCatg1(objEntField[3].bIsVissible);
        setbIsShowCatg2(objEntField[4].bIsVissible);
        setbIsShowCatg3(objEntField[5].bIsVissible);
        setbIsShowProTyp(objEntField[6].bIsVissible);
        setbIsShowProgen(objEntField[7].bIsVissible);
        setbIsShowStatus(objEntField[8].bIsVissible);
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  //#endregion

  //#region  Click_Event
  const SaveClick = async () => {
    try {
      if (listUnit.length === 0) {
        Show_Message(dispatch, "Please Select Unit", "error");
      } else {
        if (MinMarginValidation()) {
          await Pre_Loading(dispatch, true);
          var Image = document.getElementById("default-btn").value;
          var idxDot = Image.lastIndexOf(".") + 1;
          var ImageType = Image.substr(idxDot, Image.length).toLowerCase();
          if (
            ImageType === "jpg" ||
            ImageType === "jpeg" ||
            ImageType === "png" ||
            Image === ""
          ) {
            let EntProduct = [
              {
                iTitleTag: iScrId,
                iProId: iProId,
                iBranchId: Number(_AuthParems.Branch),
                iNumSeqNo: listCodeFormat[0].iNumSeqNo,
                strPrefix: listCodeFormat[0].strPrefix,
                strProCode: strProCode,
                strProName: strProName,
                strAttValue: "",
                strRemark: strRemarks,
                strPrintName: strPrintName,
                strCommodityCode: strHSNCode,
                iCat1Id: iCat1Id,
                iCat2Id: iCat2Id,
                iCat3Id: iCat3Id,
                iStatusId: iStatusId,
                iProTypeId: iProTypeId,
                iProGenreId: iProGenreId,
                iProDimId: 0,
                iStoDimId: 0,
                iTraDimId: 0,
                dPriceRate: Number(dPriceRate),
                dMinMargin: Number(dMinMargin),
                dCostRate: Number(dCostRate),
                dDiscount: Number(dDiscount),
                iTaxTypeId: Number(iTaxTypeId),
                iSGSTTaxId: Number(iSGSTTaxId),
                iCGSTTaxId: Number(iCGSTTaxId),
                iIGSTTaxId: Number(iIGSTTaxId),
                bQtyDecimal: true,
                strConcatAttNames: strConcatAttNames,
                strAll: "",
                strAttNames: "",
                strCat1Name: "",
                strCat2Name: "",
                strCat3Name: "",
                strFilter: "",
                strFilterColumn: "",
                strMasterProCodeTag: "",
                strProCodeTag: "",
                strProDescription1: "",
                strProDescription2: "",
                strProGenreName: "",
                strProTypeName: "",
                strSearch: "",
                strStatusName: "",
                strUnitName: "",
                ImgData: ImageByteArray,
                strImgType: ImageType,
                strImgName: strImageName,
                dReOrderQty: 50,
                strUPC: strUPC,
                strEAN: strEAN,
                strISBN: strISBN,

                iBrandId: ibrandid,
                iManufacturerId: imanufacid,
                iProLocationId: ilocationid,
                iAttGrpId: iAttGrpId,
                isAttPro: Number(IsAttPro),
                isBatchPro: Number(IsBatchPro),
                isCompoPro: Number(IsCompoPro),
                iStockMaintain: Number(chkStockMaintainYes),
                iDisCal: Number(iDisCal),
                iRateCal: Number(iRateCal),
              },
            ];
            if (ListAttProduct.length > 0) {
              EntProduct = ListAttProduct;
              EntProduct.forEach((att) => {
                att.strImgType = ImageType;
                att.strImgName = strImageName;
                att.ImgData = ImageByteArray;
                att.iTaxTypeId = Number(iTaxTypeId);
                att.iSGSTTaxId = Number(iSGSTTaxId);
                att.iCGSTTaxId = Number(iCGSTTaxId);
                att.iIGSTTaxId = Number(iIGSTTaxId);
                att.iCat1Id = iCat1Id;
                att.iCat2Id = iCat2Id;
                att.iCat3Id = iCat3Id;
                att.iStatusId = iStatusId;
                att.iProTypeId = iProTypeId;
                att.iProGenreId = iProGenreId;
                att.dMinMargin = Number(dMinMargin).toFixed(2);
                att.dDiscount = Number(dDiscount).toFixed(2);
                att.bQtyDecimal = true;
                att.strConcatAttNames = strConcatAttNames;

                att.iBrandId = ibrandid;
                att.iManufacturerId = imanufacid;
                att.iProLocationId = ilocationid;
                att.iAttGrpId = iAttGrpId;
                att.isAttPro = Number(IsAttPro);
                att.isBatchPro = Number(IsBatchPro);
                att.isCompoPro = Number(IsCompoPro);
                att.iStockMaintain = Number(chkStockMaintainYes);
                att.iDisCal = Number(iDisCal);
                att.iRateCal = Number(iRateCal);
              });
            }
            const InserProduct = {
              objEntUOM: listUnit,
              EntAttribute: InsUpd_AttValue(),
              EntProduct: EntProduct,
              iAttPro: EntProduct.length > 1 ? 1 : 0,
              iAttGrpId: iAttGrpId,
              strDBName: _AuthParems.DB,
              iBranchId: Number(_AuthParems.Branch),
              strProId: strProId,
              strAttValId: strAttValId,
              _EntProduct: arrProductDT,
            };
            const res = await API_POST(
              "ProductMaster/InsUpd_Productmaster/",
              InserProduct,
              dispatch
            );
            if (res) {
              await Show_Message(dispatch, res.strMessage, "success");
              await ClearClick();
            }
          } else {
            Show_Message(
              dispatch,
              "Only jpg/jpeg and png files are allowed!",
              "error"
            );
          }
        } else {
          Show_Message(
            dispatch,
            "Price Rate Less Then MinMargin Not Allowed",
            "error"
          );
        }
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ClearClick = async () => {
    setstrPurchaseUnitValue(0);
    setstrSalesUnitValue(0);
    setbIsTcsShow(false);
    setchkStockMaintainYes(true);
    setIsBatchPro(false);
    setIsAttPro(false);
    setIsCompoPro(false);
    setiProId(0);
    setiAttGrpId(0);
    setlistUnit([]);
    setstrProName("");
    setstrHSNCode("");
    setstrPrintName("");
    setstrRemarks("");
    setstrUnitName("");
    setdCostRate((0).toFixed(2));
    setdPriceRate((0).toFixed(2));
    setdDiscount((0).toFixed(2));
    setdMinMargin((0).toFixed(2));
    setiProTypeId(1);
    setiCat1Id(0);
    setiCat2Id(0);
    setiCat3Id(0);
    setiStatusId(1);
    setiProGenreId(1);
    setiSGSTTaxId(24);
    setiCGSTTaxId(27);
    setiIGSTTaxId(18);
    setiTcsTaxId(43);
    setstrSearch("");
    setiTaxTypeId(1);
    setstrConcatAttNames("");
    setstrImage("");
    setImageByteArray([]);
    setarrProductDT([]);
    setarrEntCalType([]);
    setstrImageName("");
    setibrandid(0);
    setimanufacid(0);
    setilocationid(0);
    setstrEAN("");
    setstrISBN("");
    setstrUPC("");
    setiDisCal(1);
    setiRateCal(1);

    // Clear Attribute Values

    clear_attribute();

    $(".img-wrapper").removeClass("active");
    await Load_ProductMaster();
  };
  const clear_attribute = () => {
    setListAttProduct([]);
    setListAtttribute([]);
    setattOption([
      {
        iAttId: 0,
        strAttribute: "",
        strAttVal: "",
        iAttValId: 0,
        isAttribute: false,
        iAttGrpId: 0,
        arrAttValue: [],
      },
    ]);
  };
  const DeleteClick = async () => {
    try {
      await Pre_Loading(dispatch, true);
      const res = await API_POST(
        `ProductMaster/Delete_Product/`,
        {
          strProId: iAttGrpId === 0 ? iProId.toString() : strProId,
          strAttValId: strAttValId,
          iAttGrpId: iAttGrpId,
          iBranchId: Number(_AuthParems.Branch),
          strDBName: _AuthParems.DB,
        },
        dispatch
      );
      if (res) {
        ClearClick();
        Show_Message(dispatch, res.strMessage, "success");
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const ExportImportPopup = () => {
    setExpImpOpen(!ExpImpOpen);
    ClearClick();
  };
  //#endregion

  //#region On_Change_Event
  const ProductName_Onchange = (strtxt) => {
    setstrProName(strtxt);
    if (ListAttProduct.length > 0) {
      let listproduct = ListAttProduct;
      listproduct = listproduct.map((dt) => {
        dt.strProName = strtxt;
        return dt;
      });
      setListAttProduct(listproduct);
    }
  };
  const Product_Search = async (Value) => {
    try {
      setstrSearch(Value);
      const objProSearch = {
        iProId: 0,
        strSearch: Value,
        strDBName: _AuthParems.DB,
      };

      const res = await API_POST(
        `ProductMaster/Search_Product/`,
        objProSearch,
        dispatch
      );
      if (res) {
        setlistProduct(res);
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  //#endregion

  //#region TabSetting
  const [ListTabOrder, setListTabOrder] = useState([
    {
      ProCodeTab: "",
      ProNTab: "",
      HsnTab: "",
      Cat1tap: "",
      Cat2tap: "",
      Cat3tap: "",
      Unittab: "",
      PrintNtab: "",
      Remarktab: "",
      MinMartab: "",
      Disctab: "",
      PurRtab: "",
      SalRtab: "",
      Stutab: "",
      StockMtab: "",
      Decimtab: "",
      Protytab: "",
      Progentab: "",
      Taxtytab: "",
      Cgsttab: "",
      Igsttab: "",
      Sgsttab: "",
      ProductListTab: "",
      ProductFIlterTab: "",
      ProductSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtProductCode":
          TabOrder[0].ProCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtProductName":
          TabOrder[0].ProNTab = EntProperties[i].iTabIndex;
          break;
        case "txtCommodity":
          TabOrder[0].HsnTab = EntProperties[i].iTabIndex;
          break;
        case "cbCategory1":
          TabOrder[0].Cat1tap = EntProperties[i].iTabIndex;
          break;
        case "cbCategory2":
          TabOrder[0].Cat2tap = EntProperties[i].iTabIndex;
          break;
        case "cbCategory3":
          TabOrder[0].Cat3tap = EntProperties[i].iTabIndex;
          break;
        case "txtUnit":
          TabOrder[0].Unittab = EntProperties[i].iTabIndex;
          break;
        case "txtPrintName":
          TabOrder[0].PrintNtab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].Remarktab = EntProperties[i].iTabIndex;
          break;
        case "txtMinMargin":
          TabOrder[0].MinMartab = EntProperties[i].iTabIndex;
          break;
        case "txtDiscount":
          TabOrder[0].Disctab = EntProperties[i].iTabIndex;
          break;
        case "txtCostRate":
          TabOrder[0].PurRtab = EntProperties[i].iTabIndex;
          break;
        case "txtPriceRate":
          TabOrder[0].SalRtab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].Stutab = EntProperties[i].iTabIndex;
          break;
        case "chkStockMaintain":
          TabOrder[0].StockMtab = EntProperties[i].iTabIndex;
          break;
        case "chkQtyYes":
          TabOrder[0].Decimtab = EntProperties[i].iTabIndex;
          break;
        case "cbProType":
          TabOrder[0].Protytab = EntProperties[i].iTabIndex;
          break;
        case "cbProGenre":
          TabOrder[0].Progentab = EntProperties[i].iTabIndex;
          break;
        case "cbTaxType":
          TabOrder[0].Taxtytab = EntProperties[i].iTabIndex;
          break;
        case "cbCgst":
          TabOrder[0].Cgsttab = EntProperties[i].iTabIndex;
          break;
        case "cbIgst":
          TabOrder[0].Igsttab = EntProperties[i].iTabIndex;
          break;
        case "cbSgst":
          TabOrder[0].Sgsttab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].ProductFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].ProductSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbProductName":
          TabOrder[0].ProductListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].ProductListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  //#endregion

  //#region  Validation
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtProductName":
          ErrorMsg = strProName === "" ? false : true;
          break;
        case "txtPrintName":
          ErrorMsg = strPrintName === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "cbCategory1":
          ErrorMsg = Number(iCat1Id) === 0 ? false : true;
          break;
        case "cbCategory2":
          ErrorMsg = Number(iCat2Id) === 0 ? false : true;
          break;
        case "cbCategory3":
          ErrorMsg = Number(iCat3Id) === 0 ? false : true;
          break;
        case "txtCommodity":
          ErrorMsg = strHSNCode === "" ? false : true;
          break;
        case "txtCostRate":
          ErrorMsg = Number(dCostRate) === 0 ? false : true;
          break;
        case "txtMinMargin":
          ErrorMsg = Number(dMinMargin) === 0 ? false : true;
          break;
        case "txtPriceRate":
          ErrorMsg = Number(dPriceRate) === 0 ? false : true;
          break;
        case "txtDiscount":
          ErrorMsg = Number(dDiscount) === 0 ? false : true;
          break;
        case "cbSgst":
          ErrorMsg = Number(iSGSTTaxId) === 0 ? false : true;
          break;
        case "cbIgst":
          ErrorMsg = Number(iIGSTTaxId) === 0 ? false : true;
          break;
        case "cbCgst":
          ErrorMsg = Number(iCGSTTaxId) === 0 ? false : true;
          break;
        case "cbTaxType":
          ErrorMsg = Number(iTaxTypeId) === 0 ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        Show_Message(dispatch, ValidateItem[i].strErrorMsg, "error");

        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  //#endregion

  //#region  General Methods
  const GetUnitDetails = (DefaultUnit, UnitName) => {
    try {
      setIsOpen(!IsOpen);
      setlistUnit(DefaultUnit);
      setstrUnitName(UnitName);
      CheckUnitValue(DefaultUnit);
    } catch (err) {
      Show_Message(dispatch, err, "error");
    }
  };
  const CheckUnitValue = async (UnitList, DB) => {
    var PurcahseValue = UnitList.filter(
      (Data) => Data.bPurchaseDefault === true
    );
    var SalesValue = UnitList.filter((Data) => Data.bSalesDefault === true);
    if (UnitList.length > 1) {
      const res = await API_FETCH(
        `UnitMaster/View_UnitMaster/${PurcahseValue[0].iUnitId}/${
          DB ? DB : _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        setstrPurchaseUnitValue(res.EntUnit.dConvertValue);
      }
    }
    if (SalesValue.length > 0) {
      const res = await API_FETCH(
        `UnitMaster/View_UnitMaster/${SalesValue[0].iUnitId}/${
          DB ? DB : _AuthParems.DB
        }`,
        dispatch
      );
      if (res) {
        setstrSalesUnitValue(res.EntUnit.dConvertValue);
      }
    }
  };
  const MinMarginValidation = () => {
    var MinMarginCheck = false;
    if (iAttGrpId === 0) {
      var PurchaseRate = Number(dCostRate);
      var SalesRate = Number(dPriceRate);
      var MinMargin = Number(dMinMargin);
      if (listUnit.length > 1) {
        PurchaseRate = PurchaseRate / Number(strPurchaseUnitValue);
        SalesRate = SalesRate / Number(strSalesUnitValue);
        MinMargin = PurchaseRate + (PurchaseRate * MinMargin) / 100;
        if (MinMargin <= SalesRate) MinMarginCheck = true;
      } else {
        PurchaseRate = PurchaseRate / Number(strSalesUnitValue);
        SalesRate = SalesRate / Number(strSalesUnitValue);
        MinMargin = PurchaseRate + (PurchaseRate * MinMargin) / 100;
        if (MinMargin <= SalesRate) MinMarginCheck = true;
      }
    } else {
      MinMarginCheck = true;
    }
    return MinMarginCheck;
  };
  const View_Product = async (iProId, iAttGrpId) => {
    try {
      clear_attribute();
      setiProId(iProId);
      setiAttGrpId(iAttGrpId);
      await Pre_Loading(dispatch, true);
      const res = await API_FETCH(
        `ProductMaster/View_Product/${iProId}/${iAttGrpId}/${_AuthParems.Branch}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        let Product = res.EntProduct[0];
        $(".img-wrapper").addClass("active"); // For Image Active
        setlistUnit(res.EntUOM);
        CheckUnitValue(res.EntUOM);
        setstrUnitName(res.strUnitName);
        setstrProCode(Product.strProCode);
        setstrProName(Product.strProName);
        setstrHSNCode(Product.strCommodityCode);
        setstrPrintName(Product.strPrintName);
        setstrRemarks(Product.strRemark);
        setiCat1Id(Product.iCat1Id);
        setiCat2Id(Product.iCat2Id);
        setiCat3Id(Product.iCat3Id);
        setdCostRate(Product.dCostRate.toFixed(2));
        setdPriceRate(Product.dPriceRate.toFixed(2));
        setdMinMargin(Product.dMinMargin.toFixed(2));
        setdDiscount(Product.dDiscount.toFixed(2));
        setchkStockMaintainYes(Product.iStockMaintain);
        setiProGenreId(Product.iProGenreId);
        setiStatusId(Product.iStatusId);
        setiProTypeId(Product.iProTypeId);
        setiSGSTTaxId(Product.iSGSTTaxId);
        setiCGSTTaxId(Product.iCGSTTaxId);
        setiIGSTTaxId(Product.iIGSTTaxId);
        setibrandid(Product.iBrandId);
        setimanufacid(Product.iManufacturerId);
        setilocationid(Product.iProLocationId);
        setImageByteArray(Product.imgData);
        setstrImageName(Product.strImgName);
        setstrUPC(Product.strUPC);
        setstrEAN(Product.strEAN);
        setstrISBN(Product.strISBN);
        setstrConcatAttNames("");
        setIsBatchPro(Boolean(Product.isBatchPro));
        setIsAttPro(Boolean(Product.isAttPro));
        setIsCompoPro(Boolean(Product.isCompoPro));
        if (Product.imgData.length > 0) {
          var imgsrc =
            "data:image/png;base64," +
            Buffer.from(new Uint8Array(Product.imgData)).toString("base64");
          setstrImage(imgsrc);
        } else setstrImage("");
        if (res.EntAttribute.length > 0) {
          setstrProId("");
          setListAttProduct(res.EntProduct);
          let EntAtt = res.EntAttribute;
          let Attribute = [];
          EntAtt.map((item) => {
            const groups = Attribute.find((att) => att.iAttId === item.iAttId);
            if (groups === undefined)
              Attribute.push({
                iAttId: item.iAttId,
                strAttribute: item.strAttribute,
                strAttVal: "",
                iAttValId: 0,
                isAttribute: false,
                arrAttValue: EntAtt.filter((i) => i.iAttId === item.iAttId),
              });
            return item;
          });
          setattOption(Attribute);
        }
        if (res.EntCompoPro.length > 0) {
          setarrProductDT(res.EntCompoPro);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err, "error");
    } finally {
      await Pre_Loading(dispatch, false);
    }
  };
  const CategoryClose = async () => {
    try {
      setIsCatOpen(!IsCatOpen);
      const res = await API_FETCH(
        `ProductMaster/Load_Category/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setlistCat1(res.objEntCat1);
        setlistCat2(res.objEntCat2);
        setlistCat3(res.objEntCat3);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Load_BranchMftloc = async () => {
    try {
      setisBML(!isBML);
      const res = await API_FETCH(
        `ProductMaster/Load_BranMftloc/${BMLtype}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        switch (BMLtype) {
          case 2:
            setlistManufacturer(res.objEntmanufacturer);
            break;
          case 3:
            setlistlocation(res.objEntlocation);
            break;
          default:
            setlistBrand(res.objEntbrand);
            break;
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  //#endregion

  //#region  Image Selection
  const ActivatDeafultBtn = () => {
    const defaultBtn = document.getElementById("default-btn");
    // eslint-disable-next-line no-useless-escape
    let regExp = /[0-9a-zA-Z\^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~\_ ]+$/;
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      const file = this.files[0];
      if (file) {
        const reader = new FileReader();
        const Bytreader = new FileReader();
        reader.readAsDataURL(file);
        Bytreader.readAsArrayBuffer(file);

        reader.onload = function () {
          setstrImage(reader.result);
        };
        Bytreader.onload = function () {
          var fileByteArray = [];
          var array = new Uint8Array(Bytreader.result);
          for (var i = 0; i < array.length; i++) fileByteArray.push(array[i]);
          setImageByteArray(fileByteArray);
        };
        if (this.value) {
          setstrImageName(this.value.match(regExp)[0]);
        }
        $(".img-wrapper").addClass("active");
      }
    });
  };
  //#endregion

  const InsUpd_AttValue = () => {
    let attval = [];
    attOption.forEach((att) => {
      att.arrAttValue.forEach((val) => {
        if (val.iAttValId === 0)
          attval.push({
            iAttGrpId: iAttGrpId,
            iAttId: att.iAttId,
            strAttribute: att.strAttribute,
            strAttVal: val.strAttVal,
            iAttValId: val.iAttValId,
            isAttribute: false,
          });
      });
    });
    return attval;
  };

  return (
    <main className="main-container">
      <SideMenu ScrId={iScrId} />
      <div className="content">
        <MasterList
          ScrId={iScrId}
          strSearchtxt={strSearch}
          tabOrder={ListTabOrder[0].ProductSearchTab}
          Search={(val) => Product_Search(val)}
          arrMasterData={listProduct}
          isImg={true}
          displayName="strProName"
          Parem1="iProId"
          Parem2="iAttGrpId"
          View_Data={(proid, itemgrp) => View_Product(proid, itemgrp)}
          placeholder="Product"
        />
        <div className="main-content">
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <div className="row">
                <div className="col-md-9 col-sm-6">
                  <label>
                    Name<span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Product Name"
                    name="strProName"
                    id="txtProductName"
                    value={strProName}
                    autoComplete="off"
                    onChange={(e) => ProductName_Onchange(e.target.value)}
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].ProNTab}
                    spellCheck={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <label>
                    Code <span className="danger">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      id="txtProductCode"
                      name="strProCode"
                      onKeyDown={(e) => TabIndex(e)}
                      value={strProCode}
                      tabIndex={ListTabOrder[0].ProCodeTab}
                      readOnly={ManualCode}
                      onChange={(e) => setstrProCode(e.target.value)}
                    />
                    <span
                      className="inv-no"
                      onClick={(e) => setIsMasterCode(!IsMasterCode)}
                    >
                      <i className="bx bx-cog"></i>
                    </span>
                  </div>
                </div>
                {bIsShowPrintName && (
                  <div className="col-md-4 col-sm-6">
                    <label>Print Name </label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      tabIndex={ListTabOrder[0].PrintNtab}
                      placeholder=" Print Name"
                      id="txtPrintName"
                      name="strPrintName"
                      onKeyDown={(e) => TabIndex(e)}
                      value={strPrintName}
                      onInput={(e) => setstrPrintName(e.target.value)}
                    />
                  </div>
                )}
                {bIsShowHsn && (
                  <div className="col-md-4 col-sm-6">
                    <label>Commodity/HSN</label>
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Commodity Code"
                      id="txtCommodity"
                      autoComplete="off"
                      name="strHSNCode"
                      onKeyDown={(e) => TabIndex(e)}
                      tabIndex={ListTabOrder[0].HsnTab}
                      value={strHSNCode}
                      onChange={(e) => setstrHSNCode(e.target.value)}
                    />
                  </div>
                )}
                <div className="col-md-4 col-sm-6">
                  <label>Unit Name </label>
                  <div className="inline-createbtn">
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder=" Unit Name"
                      id="txtUnitName"
                      name="strUnitName"
                      autoComplete="off"
                      tabIndex={ListTabOrder[0].Unittab}
                      defaultValue={strUnitName}
                      onClick={(e) => setIsOpen(!IsOpen)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") setIsOpen(!IsOpen);
                        else TabIndex(e);
                      }}
                    />
                    <i
                      className="fa-solid fa-caret-down"
                      style={{
                        position: "absolute",
                        right: "10px",
                        color: "darkgreen",
                        transform: IsOpen ? "rotate(-180deg)" : "rotate(0deg)",
                        fontSize: "15px",
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 p-2">
              <center>
                <div className="img-container">
                  <div className="img-content">
                    <div className="img-wrapper">
                      <div className="image">
                        {strImage !== "" && <img src={strImage} alt="" />}
                      </div>
                      <div className="content">
                        <div id="custom-btn" onClick={ActivatDeafultBtn}>
                          <i className="fas fa-cloud-upload-alt"></i>
                        </div>
                      </div>
                      <div
                        id="cancel-btn"
                        onClick={(e) => {
                          setstrImage("");
                          setImageByteArray([]);
                          setstrImageName("");
                          $(".img-wrapper").removeClass("active");
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </div>
                      <div className="file-name">{strImageName}</div>
                    </div>
                    <input
                      id="default-btn"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      hidden
                    />
                  </div>
                </div>
              </center>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <label>Purchase Rate </label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                id="txtcostRate"
                disabled={iProId > 0 && iAttGrpId > 0 ? true : false}
                name="dCostRate"
                tabIndex={ListTabOrder[0].PurRtab}
                value={dCostRate}
                onKeyDown={(e) => TabIndex(e)}
                onClick={(e) => e.target.select()}
                onChange={(e) => setdCostRate(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value === "") setdCostRate(Number(0).toFixed(2));
                  else {
                    setdCostRate(Number(e.target.value).toFixed(2));
                    if (Number(dPriceRate) === 0)
                      setdPriceRate(Number(e.target.value).toFixed(2));
                  }
                }}
              />
            </div>
            <div className="col-md-3 col-sm-3">
              <label>Sales Rate </label>
              <input
                type="number"
                disabled={iProId > 0 && iAttGrpId > 0 ? true : false}
                aria-label="First name"
                className="form-control"
                id="txtPriceRate"
                name="dPriceRate"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].SalRtab}
                value={dPriceRate}
                onClick={(e) => e.target.select()}
                onChange={(e) => setdPriceRate(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value === "")
                    setdPriceRate(Number(0).toFixed(2));
                  else setdPriceRate(Number(e.target.value).toFixed(2));
                }}
              />
            </div>
            <div className="col-md-3 col-sm-3">
              <label>Minimum Margin % </label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                id="txtMinMargin"
                name="dMinMargin"
                tabIndex={ListTabOrder[0].MinMartab}
                onKeyDown={(e) => TabIndex(e)}
                value={dMinMargin}
                onClick={(e) => e.target.select()}
                onChange={(e) => setdMinMargin(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value === "")
                    setdMinMargin(Number(0).toFixed(2));
                  else setdMinMargin(Number(e.target.value).toFixed(2));
                }}
              />
            </div>
            <div className="col-md-3 col-sm-3">
              <label>Discount % </label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                id="txtDiscRate"
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].Disctab}
                name="dDiscount"
                value={dDiscount}
                onClick={(e) => e.target.select()}
                onChange={(e) => setdDiscount(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value === "") setdDiscount(Number(0).toFixed(2));
                  else setdDiscount(Number(e.target.value).toFixed(2));
                }}
              />
            </div>
            <div className="col-md-3 col-sm-3">
              <label>Tax Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow3"
                showoption={_select.isoptionShow3}
                placeholder="Select Tax Type"
                taborder={ListTabOrder[0].Taxtytab}
                keydown={(e) => TabIndex(e)}
                select_value={(val) => {
                  setiTaxTypeId(val);
                  setbIsTcsShow(Number(val) === 1 ? false : true);
                }}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iTaxTypeId}
                displayName="strTaxLvlType"
                disvalue="iTaxLvlId"
                arrydata={listTaxType}
                EmptVal="Tax Type "
              />
            </div>
            {!bIsTcsShow && (
              <div className="col-md-3 col-sm-3">
                <label>GST Tax %</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow6"
                  showoption={_select.isoptionShow6}
                  placeholder="Select IGST Tax"
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => TaxOnChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iIGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={listIGST}
                  EmptVal="IGST "
                  replace={["IGST", "GST"]}
                />
              </div>
            )}
            {bIsTcsShow && (
              <div className="col-md-3 col-sm-3">
                <label>TCS Tax %</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow6"
                  showoption={_select.isoptionShow6}
                  placeholder="Select TCS Tax"
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => setiTcsTaxId(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iTcsTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={ListTcsTax}
                  EmptVal="TCS "
                />
              </div>
            )}
            <div className="col-md-3 col-sm-4">
              <label>Brand</label>
              <CustomizedSelectoption
                optionparem="isoptionShow7"
                showoption={_select.isoptionShow7}
                placeholder="Select Brand"
                select_value={(val) => setibrandid(val)}
                btnname="Brand"
                btnshow={true}
                show_popup={(e) => {
                  setisBML(!isBML);
                  setBMLtype(1);
                }}
                valueonly={true}
                defaultval={Number(ibrandid)}
                displayName="strbrandname"
                disvalue="ibrandid"
                arrydata={listBrand}
                EmptVal="Brand "
              />
            </div>
            <div className="col-md-3 col-sm-4">
              <label>Manufacturer</label>
              <CustomizedSelectoption
                optionparem="isoptionShow8"
                showoption={_select.isoptionShow8}
                placeholder="Select Manufacturer"
                keydown={(e) => TabIndex(e)}
                select_value={(val) => setimanufacid(val)}
                btnname="Manufacturer"
                btnshow={true}
                show_popup={(e) => {
                  setisBML(!isBML);
                  setBMLtype(2);
                }}
                valueonly={true}
                defaultval={Number(imanufacid)}
                displayName="strMftrName"
                disvalue="iMftrId"
                arrydata={listManufacturer}
                EmptVal="Manufacturer "
              />
            </div>
            <div className="col-md-3 col-sm-4">
              <label>Location</label>
              <CustomizedSelectoption
                optionparem="isoptionShow9"
                showoption={_select.isoptionShow9}
                placeholder="Select Product Location"
                select_value={(val) => setilocationid(val)}
                btnname="Location"
                btnshow={true}
                show_popup={(e) => {
                  setisBML(!isBML);
                  setBMLtype(3);
                }}
                valueonly={true}
                defaultval={Number(ilocationid)}
                displayName="strlocationname"
                disvalue="ilocationid"
                arrydata={listlocation}
                EmptVal="Location "
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-2">
              <label>Stock Maintain</label>
              <br />
              <input
                type="checkbox"
                style={{
                  cursor: "pointer",
                  height: "15px",
                  width: "15px",
                }}
                onKeyDown={(e) => TabIndex(e)}
                tabIndex={ListTabOrder[0].StockMtab}
                checked={chkStockMaintainYes}
                onChange={(e) => setchkStockMaintainYes(!chkStockMaintainYes)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-2">
              <label>Attribute Product</label>
              <br />
              <input
                type="checkbox"
                style={{
                  cursor: "pointer",
                  height: "15px",
                  width: "15px",
                }}
                checked={IsAttPro}
                onChange={(e) => setIsAttPro(!IsAttPro)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-2">
              <label>Batch Product</label>
              <br />
              <input
                type="checkbox"
                style={{
                  cursor: "pointer",
                  height: "15px",
                  width: "15px",
                }}
                checked={IsBatchPro}
                onChange={(e) => setIsBatchPro(!IsBatchPro)}
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-2">
              <label>Compo Product</label>
              <br />
              <input
                type="checkbox"
                style={{
                  cursor: "pointer",
                  height: "15px",
                  width: "15px",
                }}
                checked={IsCompoPro}
                onChange={(e) => setIsCompoPro(!IsCompoPro)}
              />
            </div>
          </div>
          <label className="btn-More">
            <i className="fa-solid fa-circle-plus mr-1"></i>
            <span
              onClick={(e) => {
                setisMoreinfo(!isMoreinfo);
              }}
            >
              Show Addditional Information
            </span>
          </label>
          {isMoreinfo && (
            <div className="row ">
              <div className="col-md-3 col-sm-4 ">
                <label className="d-flex">
                  UPC
                  <span className="labletooltip">
                    <i className="fa-solid fa-question ml-1"></i>
                    <div className="labletooltiptext">
                      Twelve digit Unique Number associated with barcode
                      (Universal Product Code)
                    </div>
                  </span>
                </label>
                <input
                  type="text"
                  maxLength={12}
                  className="form-control"
                  placeholder="Enter UPC Code"
                  autoComplete="off"
                  value={strUPC}
                  disabled={iProId > 0 && iAttGrpId > 0 ? true : false}
                  onChange={(e) => setstrUPC(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-4 ">
                <label className="d-flex">
                  EAN
                  <span className="labletooltip">
                    <i className="fa-solid fa-question ml-1"></i>
                    <div className="labletooltiptext">
                      Thirteen digit Unique Number (International Article
                      Number)
                    </div>
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter EAN Code"
                  autoComplete="off"
                  value={strEAN}
                  disabled={iProId > 0 && iAttGrpId > 0 ? true : false}
                  maxLength={13}
                  onChange={(e) => setstrEAN(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-4 ">
                <label className="d-flex">
                  ISBN
                  <span className="labletooltip">
                    <i className="fa-solid fa-question ml-1"></i>
                    <div className="labletooltiptext">
                      Thirteen digit Unique Commorcial Book Identifier
                      (International Standard Book Number)
                    </div>
                  </span>
                </label>
                <input
                  maxLength={13}
                  type="text"
                  className="form-control"
                  placeholder="Enter ISBN Code"
                  autoComplete="off"
                  value={strISBN}
                  disabled={iProId > 0 && iAttGrpId > 0 ? true : false}
                  onChange={(e) => setstrISBN(e.target.value)}
                />
              </div>
              {bIsShowRemark && (
                <div className="col-md-3 col-sm-6">
                  <label>Remarks </label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Remarks"
                    id="txtRemarks"
                    name="strRemarks"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].Remarktab}
                    value={strRemarks}
                    onChange={(e) => setstrRemarks(e.target.value)}
                  />
                </div>
              )}
              {bIsShowCatg1 && (
                <div className="col-md-3 col-sm-4">
                  <label>Category I</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow10"
                    showoption={_select.isoptionShow10}
                    placeholder="Select Cetegory"
                    taborder={ListTabOrder[0].Cat1tap}
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiCat1Id(val)}
                    btnname="Category 1"
                    btnshow={true}
                    show_popup={(e) => {
                      setIsCatOpen(!IsCatOpen);
                      setCategoryType("1");
                    }}
                    valueonly={true}
                    defaultval={iCat1Id}
                    displayName="strCatName"
                    disvalue="iCatId"
                    arrydata={listCat1}
                    EmptVal="Category 1 "
                  />
                </div>
              )}
              {bIsShowCatg2 && (
                <div className="col-md-3 col-sm-4">
                  <label>Category II</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow11"
                    showoption={_select.isoptionShow11}
                    placeholder="Select Cetegory"
                    taborder={ListTabOrder[0].Cat2tap}
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiCat2Id(val)}
                    btnname="Category 2"
                    btnshow={true}
                    show_popup={(e) => {
                      setIsCatOpen(!IsCatOpen);
                      setCategoryType("2");
                    }}
                    valueonly={true}
                    defaultval={iCat2Id}
                    displayName="strCatName"
                    disvalue="iCatId"
                    arrydata={listCat2}
                    EmptVal="Category 2 "
                  />
                </div>
              )}
              {bIsShowCatg3 && (
                <div className="col-md-3 col-sm-4">
                  <label>Category III</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow12"
                    showoption={_select.isoptionShow12}
                    placeholder="Select Cetegory"
                    taborder={ListTabOrder[0].Cat3tap}
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiCat3Id(val)}
                    btnname="Category 3"
                    btnshow={true}
                    show_popup={(e) => {
                      setIsCatOpen(!IsCatOpen);
                      setCategoryType("3");
                    }}
                    valueonly={true}
                    defaultval={iCat3Id}
                    displayName="strCatName"
                    disvalue="iCatId"
                    arrydata={listCat3}
                    EmptVal="Category 3 "
                  />
                </div>
              )}
              {bIsShowStatus && (
                <div className="col-md-3 col-sm-6">
                  <label>Status</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow13"
                    showoption={_select.isoptionShow13}
                    placeholder="Select Status"
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].Stutab}
                    select_value={(val) => setiStatusId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStatusId}
                    displayName="strStatusName"
                    disvalue="iStatusId"
                    arrydata={listStatus}
                    EmptVal="Status "
                  />
                </div>
              )}
              {bIsShowProTyp && (
                <div className="col-md-3 col-sm-6">
                  <label>Product Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow14"
                    showoption={_select.isoptionShow14}
                    placeholder="Select Product Type"
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].Protytab}
                    select_value={(val) => setiProTypeId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iProTypeId}
                    displayName="strProTypeName"
                    disvalue="iProTypeId"
                    arrydata={listProType}
                    EmptVal="Product Type "
                  />
                </div>
              )}
              {bIsShowProgen && (
                <div className="col-md-3 col-sm-6">
                  <label>Product Genre</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow15"
                    showoption={_select.isoptionShow15}
                    placeholder="Select Product Genre"
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].Progentab}
                    select_value={(val) => setiProGenreId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iProGenreId}
                    displayName="strProGenreName"
                    disvalue="iProGenreId"
                    arrydata={listProGenre}
                    EmptVal="Product Genre "
                  />
                </div>
              )}
            </div>
          )}
          {IsAttPro && (
            <AttributeProduct
              iProId={iProId}
              attOption={attOption}
              iAttGrpId={iAttGrpId}
              ListAttProduct={ListAttProduct}
              ListAtttribute={ListAtttribute}
              strProId={strProId}
              strAttValId={strAttValId}
              iScrId={iScrId}
              setattOption={(e) => setattOption(e)}
              setListAttProduct={(e) => setListAttProduct(e)}
              setstrProId={(e) => setstrProId(e)}
              setstrAttValId={(e) => setstrAttValId(e)}
              iCat1Id={iCat1Id}
              iCat2Id={iCat2Id}
              iCat3Id={iCat3Id}
              iStatusId={iStatusId}
              iProTypeId={iProTypeId}
              iProGenreId={iProGenreId}
              dMinMargin={dMinMargin}
              dDiscount={dDiscount}
              iTaxTypeId={iTaxTypeId}
              iSGSTTaxId={iSGSTTaxId}
              iCGSTTaxId={iCGSTTaxId}
              iIGSTTaxId={iIGSTTaxId}
              chkStockMaintainYes={chkStockMaintainYes}
              IsBatchPro={IsBatchPro}
              strConcatAttNames={strConcatAttNames}
              listCodeFormat={listCodeFormat}
              strProName={strProName}
              strRemarks={strRemarks}
              strPrintName={strPrintName}
              strHSNCode={strHSNCode}
              ibrandid={ibrandid}
              imanufacid={imanufacid}
              ilocationid={ilocationid}
              ImageByteArray={ImageByteArray}
              strImageName={strImageName}
              strUPC={strUPC}
              strEAN={strEAN}
              strISBN={strISBN}
            />
          )}
          {IsCompoPro && (
            <Compositeproduct
              iProId={iProId}
              arrEntProduct={arrProductDT}
              setarrProductDT={(e) => setarrProductDT(e)}
              setiDisCal={setiDisCal}
              iDisCal={iDisCal}
              arrEntCalType={arrEntCalType}
              setiRateCal={setiRateCal}
              iRateCal={iRateCal}
            />
          )}
          {ExpImpOpen && (
            <ExportImport Close={ExportImportPopup} iScrId={iScrId} />
          )}
          {IsOpen && (
            <UOMMaster
              Close={(e) => setIsOpen(!IsOpen)}
              GetDefautUnit={GetUnitDetails}
              ListUnit={listUnit}
              UnitName={strUnitName}
              ProId={iProId}
            />
          )}
          {IsCatOpen && (
            <CategoryMaster
              Close={(e) => CategoryClose()}
              CategType={CategoryType}
            />
          )}
          {isBML && (
            <BrandLocationManufacturermaster
              screenttype={BMLtype}
              Close={(e) => Load_BranchMftloc()}
            />
          )}
          {IsMasterCode && (
            <MasterCodeSetting
              Close={(e) => setIsMasterCode(!IsMasterCode)}
              iScrId={iScrId}
            />
          )}
          <Messagedialogbox />
          {_Common.IsProSearch && (
            <MasterProductSearch
              iScrId={iScrId}
              strScrType="Sales"
              iPartyId={0}
              iTaxRelId={1}
            />
          )}
        </div>
        <Button
          Save={(e) => {
            if (PropertiesValidation())
              if (iProId > 0) {
                Ask_Question(
                  dispatch,
                  SaveClick,
                  "Do You Want to  Update Product?"
                );
              } else {
                Ask_Question(
                  dispatch,
                  SaveClick,
                  "Do You Want to  Save Product?"
                );
              }
          }}
          SaveIndex={ListTabOrder[0].BtnSavtab}
          DeleteIndex={ListTabOrder[0].BtnDeletetab}
          CloseIndex={ListTabOrder[0].BtnClosetab}
          ClearIndex={ListTabOrder[0].BtnCleartab}
          ExportIndex={ListTabOrder[0].BtnExporttab}
          Export={ExportImportPopup}
          onKeyDown={(e) => TabIndex(e)}
          Clear={ClearClick}
          ScrId={iScrId}
          Iid={iProId}
          Delete={(e) => {
            if (iProId === 0) {
              Show_Message(dispatch, "Please Select Product", "info");
            } else
              Ask_Question(
                dispatch,
                DeleteClick,
                "Do You Want to  Delete Product?"
              );
          }}
        />
      </div>
    </main>
  );
}
export default ProductMaster;
