import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBillAdd_Onchange,
  setCountry,
  setShippAdd_Onchange,
  setShippAddress,
  setState,
} from "../features/ManualInvoice";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_Message } from "../General/Messagedialogbox";
import { Country_OnChange } from "../Master/MasterTransaction";

function CustomerInfo(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((state) => state.Invoice.Common);
  const _BillAdd = useSelector((state) => state.Invoice.BillAdd[0]);
  const _ShippAdd = useSelector((state) => state.Invoice.ShipAdd[0]);
  const _Address = props.isBillAdd ? _BillAdd : _ShippAdd;
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _AuthParems = useSelector((state) => state.Common.Admin);

  useEffect(() => {}, [props]);
  const Input_Onchange = (Col, Val) => {
    if (props.isBillAdd)
      dispatch(setBillAdd_Onchange({ Name: Col, value: Val }));
    else dispatch(setShippAdd_Onchange({ Name: Col, value: Val }));
  };
  const copy_Billing_Address = () => {
    const BillAdd = JSON.parse(JSON.stringify(_BillAdd));
    BillAdd.ShipAddId = 0;
    dispatch(setShippAddress([BillAdd]));
  };
  const Select_Country = async (Country) => {
    try {
      dispatch(
        setCountry({
          Country: Country.iCountryId,
          Name: props.isBillAdd ? "Bill" : "Shipp",
          EntState: await Country_OnChange(
            Country.iCountryId,
            _AuthParems.DB,
            dispatch
          ),
        })
      );
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const State_Onchange = (State) => {
    try {
      dispatch(
        setState({
          State: State.iSId,
          Name: props.isBillAdd ? "Bill" : "Shipp",
          TaxRelId: _Common.BSId === State.iSId ? 1 : 2,
        })
      );
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{props.isBillAdd ? "Billing " : "Delivery "} Address</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-1">
          <div className="row">
            {!props.isBillAdd && (
              <p
                className="cpy-billadd"
                onClick={(e) => copy_Billing_Address()}
              >
                <i className="bx bx-copy"></i>&nbsp;
                <span>Copy Billing Address</span>
              </p>
            )}
            <div className="col-sm-12 col-md-6">
              <label>Name</label>
              <input
                type="text"
                spellCheck={false}
                autoComplete="off"
                className="form-control"
                placeholder="enter customer name"
                onChange={(e) => Input_Onchange("CustName", e.target.value)}
                value={_Address.CustName}
                readOnly={props.isBillAdd}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Country <span className="danger">*</span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow10"
                showoption={_selecrparem.isoptionShow10}
                placeholder="Select Country"
                select_value={(val) => Select_Country(val)}
                btnname="Country"
                btnshow={false}
                show_popup={""}
                valueonly={false}
                defaultval={_Address.Country}
                displayName="strCountryName"
                disvalue="iCountryId"
                arrydata={_Common.arrEntCountry}
                disabled={false}
                EmptVal="Country "
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                State <span className="danger">*</span>
              </label>
              <CustomizedSelectoption
                optionparem="isoptionShow11"
                showoption={_selecrparem.isoptionShow11}
                placeholder="Select State"
                select_value={(val) => State_Onchange(val)}
                btnname="State"
                btnshow={false}
                show_popup={""}
                valueonly={false}
                defaultval={_Address.State}
                displayName="strSName"
                disvalue="iSId"
                arrydata={_Common.arrEntState}
                disabled={false}
                EmptVal="State "
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Alias Name</label>
              <input
                type="text"
                spellCheck={false}
                autoComplete="off"
                className="form-control"
                placeholder="enter customer name"
                onChange={(e) => Input_Onchange("AliasName", e.target.value)}
                value={_Address.AliasName}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Door/Office No</label>
              <input
                type="text"
                spellCheck={false}
                autoComplete="off"
                className="form-control"
                placeholder="enter door no"
                onChange={(e) => Input_Onchange("Add1", e.target.value)}
                value={_Address.Add1}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Street</label>
              <input
                type="text"
                spellCheck={false}
                autoComplete="off"
                className="form-control"
                placeholder="enter street"
                onChange={(e) => Input_Onchange("Add2", e.target.value)}
                value={_Address.Add2}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Area/Village</label>
              <input
                type="text"
                spellCheck={false}
                autoComplete="off"
                className="form-control"
                placeholder="enter area/village"
                onChange={(e) => Input_Onchange("Add3", e.target.value)}
                value={_Address.Add3}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>City</label>
              <input
                type="text"
                spellCheck={false}
                autoComplete="off"
                className="form-control"
                placeholder="enter city"
                onChange={(e) => Input_Onchange("Add4", e.target.value)}
                value={_Address.Add4}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <label>Pincode</label>
              <input
                type="text"
                spellCheck={false}
                autoComplete="off"
                className="form-control"
                placeholder="enter pincode"
                onChange={(e) => Input_Onchange("Pincode", e.target.value)}
                value={_Address.Pincode}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <label>Mobile No</label>
              <input
                type="number"
                placeholder="enter mobile mo"
                value={_Address.Mobile}
                onChange={(e) => Input_Onchange("Mobile", e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <label>GST</label>
              <input
                type="text"
                placeholder="enter GST"
                value={_Address.GST}
                onChange={(e) => Input_Onchange("GST", e.target.value)}
                className="form-control"
                maxLength={15}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Email</label>
              <input
                type="text"
                placeholder="enter email"
                value={_Address.Email}
                onChange={(e) => Input_Onchange("Email", e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Remark</label>
              <textarea
                type="text"
                placeholder="enter remark"
                value={_Address.Remarks}
                onChange={(e) => Input_Onchange("Remarks", e.target.value)}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerInfo;
