import React, { useEffect, useState } from "react";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
  refAcc_Party,
  refresh_ParyAcc,
  setAcc_BanKName,
  setAcc_InpuPermission,
  setAcc_Party,
  setAcc_PartyBank,
  setAcc_default,
} from "../features/Accounts";
import { API_FETCH, API_POST, DecryptData } from "../General/Utility";

function PayRecBody(props) {
  const dispatch = useDispatch();
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const _Selectparem = useSelector((state) => state.SelectOption.parems);
  const _AccParem = useSelector((state) => state.Accounts.default);
  const _HTParem = useSelector((state) => state.Accounts.HT);
  const _TabParem = useSelector((state) => state.Accounts.TabOrder);

  const [arrVendore, setarrVendore] = useState([]);
  const [arrAllBank, setarrAllBank] = useState([]);

  useEffect(() => {
    View_AllVendores(_AccParem.iVenTypeId, "");
  }, [_AccParem.iVenTypeId]);

  const View_AllVendores = async (VenType, strtext) => {
    const _LocalParems = await DecryptData(localStorage.jbctrlparams);

    let strcolname = "";
    let ScrId = 0;
    switch (VenType) {
      case 4:
        ScrId = 2;
        strcolname = "CustName";
        break;
      case 5:
        ScrId = 50;
        strcolname = "VenName";
        break;
      case 6:
        ScrId = 100;
        strcolname = "CustName";
        break;
      default:
        ScrId = 3;
        strcolname = "SuppName";
        break;
    }
    const parems = {
      iScrId: ScrId,
      itemId: _AccParem.iVenId,
      iPartyId: VenType,
      strColName: strcolname,
      strSearch: strtext,
      strDBName: _LocalParems.DB,
      iBranchId: Number(_LocalParems.Branch),
    };
    const res = await API_POST(`PartyTrans/Search_Party`, parems, dispatch);
    if (res) {
      setarrVendore(res.EntParty);
    }
  };
  const View_Party = async (iVenId) => {
    const res = await API_FETCH(
      `${props.PartyUrl}${_AccParem.iVenTypeId}/${iVenId}/${Number(
        _AuthParems.Branch
      )}/${_AuthParems.DB}`,
      dispatch
    );
    if (res) {
      dispatch(
        setAcc_Party({
          strVenName: res.objEntParty[0],
          iVenId: Number(iVenId),
        })
      );
    }
  };
  const KeyDown = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === _TabParem.PaymentListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === _TabParem.PaymentListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === _TabParem.PaymentListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === _TabParem.PaymentListTab) {
          $(".ReactTable .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const Input_OnChange = (val, type) => {
    dispatch(
      setAcc_default({
        iScrId: _AccParem.iScrId,
        iBranchId: _AccParem.iBranchId,
        iPayModeId: _AccParem.iPayModeId,
        iVenTypeId: type === "VenType" ? val : _AccParem.iVenTypeId,
        iBankAccId: _AccParem.iBankAccId,
        iPayRecId: _AccParem.iPayRecId,
        strTransNo: _AccParem.strTransNo,
        strVouchNo: type === "VouchNo" ? val : _AccParem.strVouchNo,
        strVouchDate: type === "VouchDate" ? val : _AccParem.strVouchDate,
        iVenId: _AccParem.iVenId,
        strVenName: _AccParem.strVenName,
        strPrintName: type === "PrintName" ? val : _AccParem.strPrintName,
        strChequeNo: type === "ChequeNo" ? val : _AccParem.strChequeNo,
        strChequeDate: type === "ChequeDate" ? val : _AccParem.strChequeDate,
        iBankId: type === "BankAccId" ? val : _AccParem.iBankId,
        strAccHolderName:
          type === "AccHName" ? val : _AccParem.strAccHolderName,
        strBankName: _AccParem.strBankName,
        strAccNo: type === "AccNo" ? val : _AccParem.strAccNo,
        dAmount: type === "dAmount" ? val : _AccParem.dAmount,
        strRemark: type === "Remark" ? val : _AccParem.strRemark,
        strSearch: _AccParem.strSearch,

        isCheque: _AccParem.isCheque,
        isBanAcc: _AccParem.isBanAcc,
        isCancelStatus: _AccParem.isCancelStatus,
      })
    );
  };
  const SearchBank = async (strtext) => {
    const objSearch = {
      strSearch: strtext,
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(`${props.ViewAllBankUrl}`, objSearch, dispatch);
    if (res) {
      setarrAllBank(res.EntBank);
    }
  };
  const PayMode_OnChange = async (value) => {
    switch (value) {
      case 1: // Cash
        dispatch(refresh_ParyAcc());
        dispatch(
          setAcc_InpuPermission({
            isCheque: true,
            isBanAcc: true,
          })
        );
        break;
      case 2: //Cheque / DD
        dispatch(
          setAcc_InpuPermission({
            isCheque: false,
            isBanAcc: false,
          })
        );
        break;
      default:
        dispatch(
          setAcc_InpuPermission({
            isCheque: true,
            isBanAcc: false,
          })
        );
        break;
    }
    if (value !== "1") {
      const res = await API_FETCH(
        `}${props.ViewPartyBankUrl}${_AccParem.iVenId}/${_AccParem.iVenTypeId}/${_AuthParems.DB}`
      );
      if (res) {
        if (res.objPartyBank.length > 0) {
          let objBank = res.objPartyBank[0];
          dispatch(
            setAcc_PartyBank({
              iPayModeId: value,
              strChequeNo: "",
              strAccHolderName: objBank.strAccHolderName,
              iBankId: objBank.iBankNameId,
              strBankName: objBank.strBankName,
              strAccNo: objBank.strAccNo,
            })
          );
        } else {
          dispatch(
            setAcc_PartyBank({
              iPayModeId: value,
              strChequeNo: "",
              strAccHolderName: "",
              iBankId: 0,
              strBankName: "",
              strAccNo: "",
            })
          );
        }
      }
    }
  };
  return (
    <div className="row p-2">
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Voucher no <span className="danger">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Voucher Number"
          defaultValue={_AccParem.strVouchNo}
          tabIndex={_TabParem.VoucherTab}
          onKeyDown={(e) => KeyDown(e)}
          readOnly
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Date <span className="danger">*</span>
        </label>
        <input
          type="date"
          name="strVouchDate"
          className="form-control"
          tabIndex={_TabParem.VouchDateTap}
          onKeyDown={(e) => KeyDown(e)}
          value={_AccParem.strVouchDate}
          onChange={(e) => Input_OnChange(e.target.value, "VouchDate")}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Trans No <span className="danger">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          tabIndex={_TabParem.TransNoTab}
          onKeyDown={(e) => KeyDown(e)}
          defaultValue={_AccParem.strTransNo}
          readOnly
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Vendor Type <span className="danger">*</span>
        </label>
        <CustomizedSelectoption
          optionparem="isoptionShow"
          showoption={_Selectparem.isoptionShow}
          placeholder="Select Vendore Type"
          taborder={_TabParem.VenTypeTab}
          keydown={(e) => KeyDown(e)}
          select_value={(val) => {
            dispatch(refAcc_Party());
            Input_OnChange(val, "VenType");
            View_AllVendores(val, "");
          }}
          btnname="Location"
          btnshow={false}
          show_popup=""
          valueonly={true}
          defaultval={_AccParem.iVenTypeId}
          displayName="strVenTypeName"
          disvalue="iVenTypeId"
          arrydata={_HTParem.arrVenType}
          EmptVal="Vendor Type "
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>
          Vendor Name <span className="danger">*</span>
        </label>
        <CustomizedSelectoption
          optionparem="isoptionShow1"
          showoption={_Selectparem.isoptionShow1}
          placeholder="Select Vendore"
          taborder={_TabParem.VenNameTab}
          keydown={(e) => KeyDown(e)}
          onChange={(val) => View_AllVendores(_AccParem.iVenTypeId, val)}
          select_value={(val) => View_Party(val)}
          btnname="Vendor"
          btnshow={false}
          show_popup=""
          valueonly={true}
          defaultval={_AccParem.iVenId}
          displayName="partyName"
          disvalue="partyId"
          disabled={_AccParem.iVenTypeId === 6 ? true : false}
          arrydata={arrVendore}
          EmptVal="Vendor "
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Print Name</label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          onKeyDown={(e) => KeyDown(e)}
          name="strPrintName"
          placeholder="Enter Print Name"
          tabIndex={_TabParem.PrintNameTab}
          value={_AccParem.strPrintName}
          onChange={(e) => Input_OnChange(e.target.value, "PrintName")}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Payment Mode</label>
        <CustomizedSelectoption
          optionparem="isoptionShow2"
          showoption={_Selectparem.isoptionShow2}
          placeholder="Select Payment Mode"
          taborder={_TabParem.PayModeTab}
          keydown={(e) => KeyDown(e)}
          select_value={(val) => PayMode_OnChange(val)}
          btnname="Payment Mode"
          btnshow={false}
          show_popup=""
          valueonly={true}
          defaultval={Number(_AccParem.iPayModeId)}
          displayName="strPaymentMode"
          disvalue="iPM_Id"
          arrydata={_HTParem.arrPayMode}
          EmptVal="Payment Mode"
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Bank Account</label>
        <CustomizedSelectoption
          optionparem="isoptionShow3"
          showoption={_Selectparem.isoptionShow3}
          placeholder="Select Bank Account"
          taborder={_TabParem.BankAccountTab}
          keydown={(e) => KeyDown(e)}
          select_value={(val) => {
            if (!_AccParem.isBanAcc) {
              Input_OnChange(val, "BankAccId");
            }
          }}
          btnname="Payment Mode"
          btnshow={false}
          show_popup=""
          valueonly={true}
          defaultval={Number(_AccParem.iBankAccId)}
          displayName="bankNames"
          disvalue="bankAccId"
          disabled={_AccParem.isBanAcc ? true : false}
          arrydata={_HTParem.arrBankAcc}
          EmptVal="Bank Account"
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Cheque Number </label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          name="strChequeNo"
          tabIndex={_TabParem.CheckNoTab}
          placeholder="Enter Cheque Number"
          readOnly={_AccParem.isCheque}
          value={_AccParem.strChequeNo}
          onKeyDown={(e) => KeyDown(e)}
          onChange={(e) => Input_OnChange(e.target.value, "ChequeNo")}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Cheque Date</label>
        <input
          type="date"
          className="form-control"
          name="strChequeDate"
          value={_AccParem.strChequeDate}
          readOnly={_AccParem.isCheque}
          tabIndex={_TabParem.CheckDateTab}
          onKeyDown={(e) => KeyDown(e)}
          onChange={(e) => Input_OnChange(e.target.value, "ChequeDate")}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Bank Name </label>
        <CustomizedSelectoption
          optionparem="isoptionShow4"
          showoption={_Selectparem.isoptionShow4}
          placeholder="Select Bank "
          taborder={_TabParem.BankNameTab}
          keydown={(e) => KeyDown(e)}
          onChange={(val) => SearchBank(val)}
          select_value={(val) => {
            dispatch(
              setAcc_BanKName({
                iBankId: val.bnId,
                strBankName: val.bankNames,
              })
            );
          }}
          btnname="Bank"
          btnshow={false}
          show_popup=""
          valueonly={false}
          defaultval={_AccParem.iBankId}
          displayName="bankNames"
          disvalue="bnId"
          disabled={_AccParem.isBanAcc}
          arrydata={arrAllBank}
          EmptVal="Bank"
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Account Holder Name</label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          name="strAccHolderName"
          placeholder="Enter Account Holder Name"
          tabIndex={_TabParem.AccHolTab}
          onKeyDown={(e) => KeyDown(e)}
          value={_AccParem.strAccHolderName}
          onChange={(e) => Input_OnChange(e.target.value, "AccHName")}
          readOnly={_AccParem.isBanAcc}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Account Number</label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          tabIndex={_TabParem.AccNoTab}
          onKeyDown={(e) => KeyDown(e)}
          name="strAccNo"
          placeholder="Enter Account Number"
          value={_AccParem.strAccNo}
          onChange={(e) => Input_OnChange(e.target.value, "AccNo")}
          readOnly={_AccParem.isBanAcc}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Amount</label>
        <input
          type="number"
          aria-label="First name"
          className="form-control"
          onKeyDown={(e) => KeyDown(e)}
          onClick={(e) => e.target.select()}
          tabIndex={_TabParem.AmountTab}
          name="dAmount"
          style={{ textAlign: "left" }}
          value={_AccParem.dAmount}
          onChange={(e) => Input_OnChange(e.target.value, "dAmount")}
        />
      </div>
      <div className="col-md-3 col-sm-6 mt-1">
        <label>Remarks </label>
        <input
          type="text"
          aria-label="First name"
          className="form-control"
          onKeyDown={(e) => KeyDown(e)}
          name="strRemark"
          tabIndex={_TabParem.Remarktab}
          placeholder="Enter Remarks"
          value={_AccParem.strRemark}
          onChange={(e) => Input_OnChange(e.target.value, "Remark")}
        />
      </div>
    </div>
  );
}

export default PayRecBody;
