import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { Show_Message } from "../General/Messagedialogbox";
import Messagedialogbox from "../General/Messagedialogbox";
import { API_FETCH, DecryptData } from "../General/Utility";

function SessionMaster() {
  const _AuthParems = useSelector((state) => state.Common.Admin);

  const [arrSession, setarrSession] = useState([]);
  const [arrSessiontrans, setarrSessiontrans] = useState([]);
  // Session parem
  const [strSessionCode, setstrSessionCode] = useState("");
  const [strOpeningDate, setstrOpeningDate] = useState("");
  const [strOpeningTime, setstrOpeningTime] = useState("");
  const [strClosingDate, setstrClosingDate] = useState("");
  const [strClosingTime, setstrClosingTime] = useState("");
  const [dOpenBal, setdOpenBal] = useState((0).toFixed(2));
  const [dClosingBal, setdClosingBal] = useState((0).toFixed(2));
  const [dSalAmt, setdSalAmt] = useState((0).toFixed(2));
  const [dCashOut, setdCashOut] = useState((0).toFixed(2));
  const [dCashIn, setdCashIn] = useState((0).toFixed(2));
  const [strOpenBy, setstrOpenBy] = useState("");
  const [strClosedBy, setstrClosedBy] = useState("");
  const [strCounterName, setstrCounterName] = useState("");
  const [strStatus, setstrStatus] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    Load_Session();
  }, []);
  const Load_Session = async () => {
    try {
      const _LocalParems = await DecryptData(localStorage.jbctrlparams);

      const res = await API_FETCH(
        `SessionMaster/Load_Session/${Number(_LocalParems.Branch)}/0/${
          _LocalParems.DB
        }`,
        dispatch
      );
      if (res) {
        setarrSession(res.EntTrans);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const View_Session = async (item) => {
    setstrSessionCode(item.strSessionCode);
    setstrOpeningDate(item.openingDate);
    setstrOpeningTime(item.openingTime);
    setstrClosingDate(item.closingDate);
    setstrClosingTime(item.closingTime);
    setdClosingBal(item.closingBal.toFixed(2));
    setdOpenBal(item.openBal.toFixed(2));
    setdSalAmt(item.salAmt.toFixed(2));
    setdCashOut(item.cashOut.toFixed(2));
    setdCashIn(item.cashIn.toFixed(2));
    setstrOpenBy(item.strOpenBy);
    setstrClosedBy(item.strClosedBy);
    setstrStatus(item.strStatus);
    setstrCounterName(item.counterName);
    try {
      const res = await API_FETCH(
        `SessionMaster/View_Sessiontrans/${item.iSessionId}/${item.counterId}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setarrSessiontrans(res.EntTrans);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  return (
    <>
      <div className="pos-dtlist">
        <div className="data-list">
          {arrSession.map((dt, Index) => (
            <button
              key={Index}
              tabIndex={Index}
              onKeyDown={(e) => {
                const arrlength = arrSession.length - 1;
                if (e.key === "ArrowDown") {
                  if (Index === arrlength)
                    $(`.data-list [tabindex=${Index + 1}]`).focus();
                  else $(`.data-list [tabindex=${0}]`).focus();
                }
                if (e.key === "ArrowUp") {
                  if (Index === 0)
                    $(`.data-list [tabindex=${arrlength}]`).focus();
                  else $(`.data-list [tabindex=${Index - 1}]`).focus();
                }
                if (e.key === "Enter") {
                  View_Session(dt);
                }
              }}
            >
              <label onClick={(e) => View_Session(dt)}>
                {dt.strSessionCode}
              </label>
            </button>
          ))}
        </div>
      </div>
      <div className="pos-content">
        <div className="pos_cart">
          <div className="cart-title">
            <h6>{strSessionCode}</h6>
            <div className="poshd-btn-grp">
              <button className="btn">
                <i className="fa-solid fa-pen-nib"></i>Edit
              </button>
              <button className="btn option">
                <i className="fa-solid fa-ellipsis"></i>
              </button>
              <button className="btn">
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
          <div className="cart-content">
            <div className="ct-lbl">
              <label className="ct-txt">
                Counter Name<span>{strCounterName}</span>
              </label>
              <label className="ct-txt">
                Opening Date
                <span>{strOpeningDate + " " + strOpeningTime}</span>
              </label>
              <label className="ct-txt">
                Open By <span>{strOpenBy}</span>
              </label>
              <label className="ct-txt">
                Closing Date
                <span> {strClosingDate + " " + strClosingTime}</span>
              </label>
              <label className="ct-txt">
                Closed By <span> {strClosedBy}</span>
              </label>
              <label className="ct-txt">
                Status
                <span
                  style={{ color: strStatus === "Active" ? "red" : "black" }}
                >
                  {strStatus}
                </span>
              </label>
            </div>
            <div className="ct-lbl">
              <label className="ct-txt">
                Opening Balance
                <span>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(dOpenBal))}
                </span>
              </label>
              <label className="ct-txt">
                Sal Amout
                <span>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(dSalAmt))}
                </span>
              </label>
              <label className="ct-txt">
                Cash In
                <span>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(dCashIn))}
                </span>
              </label>
              <label className="ct-txt">
                Cash Out
                <span>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(dCashOut))}
                </span>
              </label>
              <label className="ct-txt">
                Closing Balance
                <span>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(Number(dClosingBal))}
                </span>
              </label>
            </div>
          </div>
          <div className="cart-tab">
            <label
              onClick={(e) => {
                let line = document.getElementById("subtabline");
                line.style.left = "0%";
              }}
            >
              Overview
            </label>
            <div id="subtabline"></div>
          </div>
        </div>
        <div className="pos-overview">
          <table className="overview-table">
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Trans No</th>
                <th style={{ textAlign: "center" }}>Time</th>
                <th style={{ textAlign: "center" }}>Date</th>
                <th style={{ textAlign: "left" }}>Sales Person</th>
                <th style={{ textAlign: "center" }}>Trans Type</th>
                <th style={{ textAlign: "right" }}>Amout</th>
                <th style={{ textAlign: "left" }}>Description</th>
              </tr>
            </thead>
            <tbody>
              {arrSessiontrans.map((trans, Index) => (
                <tr key={Index}>
                  <td style={{ textAlign: "left" }}>{trans.transNo}</td>
                  <td style={{ textAlign: "center" }}>{trans.transTime}</td>
                  <td style={{ textAlign: "center" }}>{trans.transdate}</td>
                  <td style={{ textAlign: "left" }}>{trans.billedBy}</td>
                  <td style={{ textAlign: "center" }}>{trans.transType}</td>
                  <td style={{ textAlign: "right" }}>
                    {Number(trans.amount).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "left" }}>{trans.remark}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Messagedialogbox />
    </>
  );
}

export default SessionMaster;
