import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ask_Question, Show_Message } from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { API_FETCH, API_POST } from "../General/Utility";
import { ShowHide_Popup } from "../features/Transactionstates";
function PrinterSetting(props) {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.Transstates.Common);
  const _AuthParems = useSelector((state) => state.Common.Admin);
  const [arrTemplate, setarrTemplate] = useState([]);
  const [arrPrinterType, setarrPrinterType] = useState([]);
  const [arrPaperType, setarrPaperType] = useState([]);
  const [iNoOfCopies, setiNoOfCopies] = useState(0);
  const [TempId1, setTempId1] = useState(0);
  const [TempId2, setTempId2] = useState(0);
  const [TempId3, setTempId3] = useState(0);
  const [TempId4, setTempId4] = useState(0);
  const [PrinterTypeId, setPrinterTypeId] = useState(0);
  const [PaperId, setPaperId] = useState(0);
  const [IsbDefault, setIsbDefault] = useState(false);
  const [IsSetbDefault, setIsSetbDefault] = useState(false);
  const [IsDualSetDefault, setIsDualSetDefault] = useState(false);
  const [IsDualDefault, setIsDualDefault] = useState(false);
  const [MultiPrintStyle, setMultiPrintStyle] = useState(false);
  const [iScrId, setiScrId] = useState(0);

  useEffect(() => {
    if (CheckisPrintEligible(_Common.iScrId)) {
      Load_PrinterSetting(_Common.iScrId);
    }
  }, []);
  const Load_PrinterSetting = async (ScrId) => {
    try {
      setiScrId(ScrId);
      const res = await API_FETCH(
        `PrintDesing/Load_PrinterSetting/${ScrId}/${Number(
          _AuthParems.Branch
        )}/${_AuthParems.DB}`,
        dispatch
      );
      if (res) {
        setMultiPrintStyle(res.strSetting === "Yes" ? true : false);
        let EntSetting = res.EntPageSetting[0];
        setiNoOfCopies(EntSetting.iNoofCopies);
        setPaperId(EntSetting.iPaperNameId);
        setarrPrinterType(res.EntPrinterType);
        setarrTemplate(res.EntTemplate);
        setarrPaperType(res.EntPaperType);
        PrinterType_OnChange(EntSetting.iPrintTypeId, ScrId);
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const CheckisPrintEligible = (ScrId) => {
    switch (ScrId) {
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 67:
      case 71:
      case 72:
      case 73:
      case 74:
      case 75:
      case 76:
      case 78:
      case 93:
      case 96:
      case 97:
      case 100:
        return true;
      default:
        return false;
    }
  };
  const Clear_PrinterSetting = () => {
    setTempId1(0);
    setTempId2(0);
    setTempId3(0);
    setTempId4(0);
    setIsbDefault(false);
    setIsDualSetDefault(false);
    setIsDualDefault(false);
    setIsSetbDefault(false);
    Load_PrinterSetting(iScrId);
  };
  const PrinterType_OnChange = async (PrinterTypeid, ScrId) => {
    try {
      setPrinterTypeId(PrinterTypeid);
      if (Number(PrinterTypeid) !== 0) {
        const res = await API_FETCH(
          `PrintDesing/Printer_OnChange/${ScrId}/${PrinterTypeid}/${Number(
            _AuthParems.Branch
          )}/${_AuthParems.DB}`,
          dispatch
        );
        if (res) {
          if (res.Template1.length > 0) {
            setTempId1(res.Template1[0].iPrintTempId);
            setIsbDefault(res.Template1[0].bSetDefault);
          } else setTempId1(0);

          if (res.Template2.length > 0) {
            setTempId2(res.Template2[0].iPrintTempId);
            setIsSetbDefault(res.Template2[0].bIsDefaultTemplate);
          } else setTempId2(0);

          if (res.Template3.length > 0) {
            setTempId3(res.Template3[0].iPrintTempId);
            setIsDualDefault(res.Template3[0].bSetDualDefault);
          } else setTempId3(0);

          if (res.Template4.length > 0) {
            setTempId4(res.Template4[0].iPrintTempId);
            setIsDualSetDefault(res.Template4[0].bIsDualDefault);
          } else setTempId4(0);
        }
      }
    } catch (err) {
      Show_Message(dispatch, err.message, "error");
    }
  };
  const Update_PrinterSetting = async () => {
    const UpdatePrint = {
      iScrId: iScrId,
      iPrinterTypeId: PrinterTypeId,
      iPrintTempId: TempId1,
      iPrintTemp1Id: TempId2,
      iPrintTemp2Id: TempId3,
      iPrintTemp3Id: TempId4,
      PaperId: PaperId,
      iNoofCopies: iNoOfCopies,
      bPreview: true,
      bSetDefault: IsSetbDefault,
      bIsDefault: IsbDefault,
      bSetDualDefault: IsDualSetDefault,
      bIsDualDefault: IsDualDefault,
      iBranchId: Number(_AuthParems.Branch),
      strDBName: _AuthParems.DB,
    };
    const res = await API_POST(
      "PrintDesing/Update_PrinterSetting/",
      UpdatePrint,
      dispatch
    );
    if (res) {
      Clear_PrinterSetting();
      Show_Message(dispatch, res.strMessage, "success");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Print Setting</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsPrintSett"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body p-2">
          <div className="row">
            <div className="col-md-4 col-sm-6 mb-1">
              <label>Paper Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow10"
                showoption={_selecrparem.isoptionShow10}
                placeholder="Select Paper Type"
                select_value={(val) => setPaperId(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={PaperId}
                displayName="strPaperName"
                disvalue="iPaperNameId"
                arrydata={arrPaperType}
                EmptVal="Paper Type"
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-1">
              <label>Printer Type</label>
              <CustomizedSelectoption
                optionparem="isoptionShow11"
                showoption={_selecrparem.isoptionShow11}
                placeholder="Select Printer Type"
                select_value={(val) => PrinterType_OnChange(val, iScrId)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={PrinterTypeId}
                displayName="strPrinterTypeName"
                disvalue="iPrinterTypeId"
                arrydata={arrPrinterType}
                EmptVal="Printer Type"
              />
            </div>
            <div className="col-md-2 col-sm-6 mb-1">
              <label>No of Copies </label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Remarks"
                id="txtRemarks"
                name="strRemarks"
                onClick={(e) => e.target.select()}
                value={iNoOfCopies}
                onChange={(e) => setiNoOfCopies(e.target.value)}
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-1">
              <label>Local State</label>
              <CustomizedSelectoption
                optionparem="isoptionShow12"
                showoption={_selecrparem.isoptionShow12}
                placeholder="Select Template"
                select_value={(val) => setTempId1(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={TempId1}
                displayName="strPrintTempName"
                disvalue="iPrintTempId"
                arrydata={arrTemplate}
                EmptVal="Template Name"
              />
            </div>
            <div className="col-md-2 col-sm-6 mb-1">
              <label>Default</label>
              <br />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={IsSetbDefault}
                  onChange={(e) => setIsSetbDefault(!IsSetbDefault)}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-1">
              <label>Inter State</label>
              <CustomizedSelectoption
                optionparem="isoptionShow13"
                showoption={_selecrparem.isoptionShow13}
                placeholder="Select Template"
                select_value={(val) => setTempId2(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={TempId2}
                displayName="strPrintTempName"
                disvalue="iPrintTempId"
                arrydata={arrTemplate}
                EmptVal="Template Name"
              />
            </div>
            <div className="col-md-2 col-sm-6 mb-1">
              <label>Default</label>
              <br />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={IsbDefault}
                  onChange={(e) => setIsbDefault(!IsbDefault)}
                />
              </div>
            </div>
          </div>
          {MultiPrintStyle && (
            <div className="row">
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Local State</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow14"
                  showoption={_selecrparem.isoptionShow14}
                  placeholder="Select Template"
                  select_value={(val) => setTempId3(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={TempId3}
                  displayName="strPrintTempName"
                  disvalue="iPrintTempId"
                  arrydata={arrTemplate}
                  EmptVal="Template Name"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Default</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={IsDualSetDefault}
                    onChange={(e) => setIsDualSetDefault(!IsDualSetDefault)}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Inter State</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow15"
                  showoption={_selecrparem.isoptionShow15}
                  placeholder="Select Template"
                  select_value={(val) => setTempId4(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={TempId4}
                  displayName="strPrintTempName"
                  disvalue="iPrintTempId"
                  arrydata={arrTemplate}
                  EmptVal="Template Name"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Default</label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={IsDualDefault}
                    onChange={(e) => setIsDualDefault(!IsDualDefault)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="submit"
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(TempId1) === 0)
                  Show_Message(
                    dispatch,
                    "Please Select Local State Template",
                    "error"
                  );
                else if (Number(TempId2) === 0)
                  Show_Message(
                    dispatch,
                    "Please Select Inter State Template",
                    "error"
                  );
                else if (IsbDefault === false)
                  Show_Message(
                    dispatch,
                    "Please Check Default Local State ",
                    "error"
                  );
                else if (IsSetbDefault === false)
                  Show_Message(
                    dispatch,
                    "Please Check Default Inter State ",
                    "error"
                  );
                else {
                  Ask_Question(
                    dispatch,
                    Update_PrinterSetting,
                    "Do You Want to Update Setting?"
                  );
                }
              }}
            >
              Save&nbsp;<i className="fas fa-save"></i>
            </button>
            <button
              type="submit"
              className="btn-fabgreen"
              onClick={Clear_PrinterSetting}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrinterSetting;
